
import ComponentLoader from '@/components/layout/shared/loaders/ComponentLoader.vue';
import { AIWriterType, TextSelection } from '@/components/tiptap/extensions/AiWriter/AiWriter';
import { AiWriterOption } from '@/components/tiptap/types/ai';
import { tones, translateOptions } from '@/constants/SharedConsts';
import logger from '@/other/Logger';
import notificationService from '@/services/NotificationService';
import socketService from '@/services/socket/SocketService';
import { Editor as CoreEditor } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/vue-2';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  computed: {
    AIWriterType() {
      return AIWriterType;
    },
  },
  components: { ComponentLoader, NodeViewWrapper },
})
export default class AiWriterView extends Vue {
  @Prop({
    type: ProsemirrorNode,
    required: true,
  })
  readonly node!: ProsemirrorNode;

  @Prop({
    type: ProsemirrorNode,
    required: true,
  })
  readonly extension!: any;

  @Prop({ type: CoreEditor, required: true })
  readonly editor!: CoreEditor;
  @Prop({ type: Function, required: true }) getPos: () => number;
  @Prop({ type: Function, required: true }) deleteNode: () => void;

  isLoading = false;
  prompt = '';
  previewText: string | null = null;
  option: AiWriterOption = null;
  isConsiderArticle = true;

  get options() {
    switch (this.aiWriterType) {
      case AIWriterType.Translate:
        return translateOptions;
      default:
        return tones;
    }
  }

  get optionsLabel(): string {
    switch (this.aiWriterType) {
      case AIWriterType.Translate:
        return 'Translate';
      default:
        return 'Change tone';
    }
  }

  get aiWriterTypeOptionValue(): string {
    return this.node.attrs.optionValue;
  }

  get selectedArticle() {
    return this.$store.state.articleEditor.article;
  }

  get selection(): TextSelection {
    return this.node.attrs.selection;
  }

  get hasSelection(): boolean {
    return !!this.node.attrs.selection;
  }

  get aiWriterType(): AIWriterType {
    return this.node.attrs.type;
  }

  get showOptionsMenuButton(): boolean {
    const isTranslateType = this.aiWriterType === AIWriterType.Translate;
    const isChangeToneType = this.aiWriterType === AIWriterType.ChangeTone;
    const isCustomPromptType = this.aiWriterType === AIWriterType.CustomPrompt;

    return (this.hasSelection && (isTranslateType || isChangeToneType || isCustomPromptType)) || !this.hasSelection;
  }

  selectOption(option: AiWriterOption) {
    this.option = option;
  }

  handleDiscard() {
    this.deleteNode();

    if (this.hasSelection) {
      this.joinNodes(this.selection.to, true);
    }
  }

  handleEnterKeyDown() {
    if (this.previewText?.length) {
      return this.handleInsert();
    }
    this.generateText();
  }

  handleInsert() {
    const { state, dispatch } = this.editor.view;
    const { tr } = state;

    let from = this.getPos();
    let to = from + this.node.nodeSize;

    if (this.hasSelection) {
      dispatch(tr.insertText(this.previewText, this.selection.from, this.selection.to));
    } else {
      this.editor
        .chain()
        .focus()
        .insertContentAt({ from, to }, this.previewText, { parseOptions: { preserveWhitespace: false } })
        .run();
    }

    this.deleteNode();

    if (this.hasSelection) {
      this.joinNodes(this.selection.from, true, this.previewText.length);
      this.editor.chain().focus();
    }
  }

  serializeOutput = (text: string): string => {
    // Use regex to remove all button and a tags along with their contents
    text = text.replace(/<button\b[^>]*>(.*?)<\/button>/gi, '');
    text = text.replace(/<a\b[^>]*>(.*?)<\/a>/gi, '');

    return text;
  };

  async generateText() {
    if (!this.prompt && !this.hasSelection) {
      return notificationService.warning('Please enter a prompt');
    }

    const article = this.$store.state.articleEditor.article;
    this.isLoading = true;

    try {
      const data = {
        articleId: article.id,
        prompt: this.prompt,
        selectedText: this.selection?.text,
        writerType: this.aiWriterType,
        option: this.option?.name,
        isConsiderArticle: this.isConsiderArticle && !this.hasSelection,
      };
      const text = await socketService.article.ai.generateAiText(data);

      const serializedText = this.serializeOutput(text);
      this.previewText = serializedText || '';
    } catch (err) {
      logger.error(err);
      notificationService.error('Error occurred while generating AI text');
    } finally {
      this.isLoading = false;
    }
  }

  joinNodes(pos: number, onlyParagraphs = false, offset = 0) {
    const joinPos = pos + offset + 1;

    const nextNode = this.editor.state.doc.nodeAt(joinPos);

    // If onlyParagraphs true -> check if next node is paragraph type
    const isJoinNodes = !onlyParagraphs
      ? true
      : nextNode && nextNode.type.name === 'paragraph' && (nextNode?.text?.length || nextNode?.content?.size > 0);

    if (!isJoinNodes) return;

    // Create a transaction to join the nodes
    const transaction = this.editor.state.tr.join(joinPos);

    // Dispatch the transaction to apply the changes
    this.editor.view.dispatch(transaction);
  }

  mounted() {
    switch (this.aiWriterType) {
      case AIWriterType.Translate:
        this.option = Object.values(translateOptions).find((option) => option.value === this.aiWriterTypeOptionValue);
        break;
      case AIWriterType.ChangeTone:
        this.option = Object.values(tones).find((option) => option.value === this.aiWriterTypeOptionValue);
        break;
    }

    if (this.hasSelection && this.aiWriterType && this.aiWriterType !== AIWriterType.CustomPrompt) {
      this.generateText();
    }

    if (this.hasSelection) {
      this.editor.commands.setTextSelection({
        from: this.selection.from,
        to: this.selection.to,
      });
    }
  }
}
