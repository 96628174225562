
import { Component } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import { Group } from '@/models/Group';
import FindUserDialog from '@/components/layout/dialogs/FindUserDialog.vue';
import { User } from '@/models/User';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';

@Component({
  components: {
    FindUserDialog,
    UserProfilePhoto,
  },
})
export default class GroupUserManagementDialog extends VueApp {
  group: Partial<Group> = { title: null };
  showDialog = false;

  get groupTitle() {
    return this.group ? this.group.title : '';
  }

  show(group: Group) {
    this.group = group;
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;
  }

  handleAddUser(users: string[]) {
    const promiseAddUsers = users.map((value: string) => {
      const user = User.parse(JSON.parse(value));
      if (user.id && !this.group.userIds.includes(user.id)) {
        this.group.userIds.push(user.id);
        this.group.userList.push(user);
        return this.$api.groups.addUser(this.group.id, user.id);
      } else {
        this.group.addInvitedEmail(user.email);
        this.group.userList.push(user);
        return this.$api.groups.addInvitedUser(this.group.id, user.email);
      }
    });

    Promise.all(promiseAddUsers)
      .then(() => {
        this.$emit('reload');
      })
      .catch((err: any) => {
        this.$api.handleResponseError(err);
      });
  }

  handleRemoveUser(user: User) {
    const invited = this.group.invitedEmails.findIndex((invitedEmail) => invitedEmail === user.email);
    if (invited < 0) {
      this.$api.groups
        .removeUser(this.group.id, user.id)
        .then(() => {
          this.group.userList = this.group.userList.filter((item) => item.id != user.id);
          this.group.userIds = this.group.userIds.filter((item) => item != user.id);

          this.$emit('reload');
        })
        .catch((res: any) => this.$api.handleResponseError(res));
    } else {
      this.$api.groups
        .removeInvitedUser(this.group.id, user.email)
        .then(() => {
          this.group.userList = this.group.userList.filter((item) => item.email != user.email);
          this.group.invitedEmails = this.group.invitedEmails.filter((item) => item != user.email);

          this.$emit('reload');
        })
        .catch((res: any) => this.$api.handleResponseError(res));
    }
  }
}
