import { AiSocketFeature } from '@/services/socket/features/AiSocketFeature';
import { PdfSocketFeature } from '@/services/socket/features/PdfSocketFeature';
import { SocketBaseServiceProvider } from '@/services/socket/SocketBaseServiceProvider';
import { SocketService } from '@/services/socket/SocketService';

export class ArticleSocketService extends SocketBaseServiceProvider {
  ai: AiSocketFeature;
  pdf: PdfSocketFeature;

  constructor(socket: SocketService) {
    super(socket);

    this.ai = new AiSocketFeature(socket);
    this.pdf = new PdfSocketFeature(socket);
  }
}
