
import VueApp from '@/@types/app/VueApp';
import { Watch, Component } from 'vue-property-decorator';

@Component({
  name: 'VideoPlaybackBar',
})
export default class VideoPlaybackBar extends VueApp {
  speedArray: number[] = [0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0];
  title = 'Playback speed';
  speed = 1.0;
  visible = false;
  resolve: (value: number) => void;
  reject: (reason?: any) => void;

  show(): Promise<number> {
    this.visible = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  close() {
    this.visible = false;
  }

  get article() {
    return this.$store.state.workspaceView.selectedArticle;
  }

  @Watch('speed')
  select() {
    this.resolve(this.speed);
    this.close();
  }

  @Watch('article')
  reset() {
    this.speed = 1.0;
  }
}
