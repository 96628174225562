
import VueApp from '@/@types/app/VueApp';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import { Component, Watch } from 'vue-property-decorator';
import handleOutsideClickDirective from '@/directives/handleOutsideClickDirective';
import NotificationsSidebarNotification from '@/components/layout/sidebars/NotificationsSidebar/NotificationsSidebarNotification.vue';
import NotificationsSidebarFactory from '@/components/layout/sidebars/NotificationsSidebar/NotificationsSidebarFactory.vue';
import store from '@/store';
import { dispatchNotificationsSidebarUpdate } from '@/store/dispatchers/notificationsSidebarDispatchers';
import apiService from '@/services/ApiService';

const UPDATE_INTERVAL = 20000;
@Component({
  components: { NotificationsSidebarFactory, NotificationsSidebarNotification },
  directives: {
    'click-outside': handleOutsideClickDirective(() => {
      if (store.state.notificationsSidebar.isVisible) {
        store.commit('notificationsSidebarSetIsVisible', false);
      }
    }, ['notification-button']),
  },
})
export default class NotificationsSidebar extends VueApp {
  $refs: {
    commentsContainer: VueApp;
  };

  activeTab = 'all';

  get isActiveAll() {
    return this.activeTab === 'all';
  }

  get isExtensionBannerVisible() {
    return this.$store.state.ui.isExtensionBannerVisible;
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get styles() {
    const diff = this.isExtensionBannerVisible && !this.isMobile ? '100' : '60';
    return {
      top: diff + 'px',
      height: `calc(100vh - ${diff}px)`,
    };
  }

  get allNotificationsCount() {
    return this.$store.state.notificationsSidebar.notifications.length;
  }

  async onClickMarkAll() {
    await this.$store.dispatch('notificationsSidebarMarkAllAsRead');
    await apiService.notifications.markAllAsRead();
  }

  onClickClose() {
    this.$store.commit('notificationsSidebarSetIsVisible', false);
  }

  mounted() {
    this.$nextTick(() => {
      window.setTimeout(() => {
        dispatchNotificationsSidebarUpdate();
        window.setInterval(dispatchNotificationsSidebarUpdate, UPDATE_INTERVAL);
      }, 100);
    });
  }

  @Watch('$route.path')
  closeSidebarOnRouteChange() {
    this.$store.commit('notificationsSidebarSetIsVisible', false);
  }
}
