export class Role {
  static ADMIN = 'admin';
  static MODERATOR = 'moderator';
  static USER = 'user';
  static EDITOR = 'editor';
  static VIEWER = 'viewer';

  user: string | null;
  role: string | null;

  constructor(userId: string | null, role: string | null) {
    this.user = userId;
    this.role = role;
  }
}
