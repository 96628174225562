
import MenuMorePopover from '@/components/tiptap/Menu/MenuItems/MenuMorePopover.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';
import TablePopover from '@/components/tiptap/Menu/MenuItems/TablePopover/TablePopover.vue';
import MenuHeadingDropdown from '@/components/tiptap/Menu/MenuItems/MenuHeadingDropdown.vue';
import MenuItalicButton from '@/components/tiptap/Menu/MenuItems/MenuItalicButton.vue';
import MenuBoldButton from '@/components/tiptap/Menu/MenuItems/MenuBoldButton.vue';
import MenuUnderlineButton from '@/components/tiptap/Menu/MenuItems/MenuUnderlineButton.vue';
import MenuAddLinkButton from '@/components/tiptap/Menu/MenuItems/Link/MenuAddLinkButton.vue';
import MenuAddImageButton from '@/components/tiptap/Menu/MenuItems/Image/MenuAddImageButton.vue';
import MenuTextAlignButtons from '@/components/tiptap/Menu/MenuItems/TextAlign/MenuTextAlignButtons.vue';
import MenuStrikeButton from '@/components/tiptap/Menu/MenuItems/MenuStrikeButton.vue';
import MenuHighlightButton from '@/components/tiptap/Menu/MenuItems/MenuHighlightButton.vue';
import MenuColorButton from '@/components/tiptap/Menu/MenuItems/MenuColorButton.vue';
import MenuBulletListButton from '@/components/tiptap/Menu/MenuItems/List/MenuBulletListButton.vue';
import MenuOrderedListButton from '@/components/tiptap/Menu/MenuItems/List/MenuOrderedListButton.vue';
import MenuIndentButton from '@/components/tiptap/Menu/MenuItems/MenuIndentButton.vue';
import MenuOutdentButton from '@/components/tiptap/Menu/MenuItems/MenuOutdentButton.vue';
import MenuTodoListButton from '@/components/tiptap/Menu/MenuItems/List/MenuTodoListButton.vue';
import MenuAddAttachmentButton from '@/components/tiptap/Menu/MenuItems/MenuAddAttachmentButton.vue';
import MenuFontFamilyDropdown from '@/components/tiptap/Menu/MenuItems/MenuFontFamilyDropdown.vue';
import MenuFontSizeDropdown from '@/components/tiptap/Menu/MenuItems/MenuFontSizeDropdown.vue';
import '@/components/tiptap/styles/MenuBar.scss';
import TextAlignPopover from '@/components/tiptap/Menu/MenuItems/TextAlign/TextAlignPopover.vue';
import ListPopover from '@/components/tiptap/Menu/MenuItems/List/ListPopover.vue';

@Component({
  components: {
    MenuMorePopover,
    ListPopover,
    TextAlignPopover,
    MenuFontSizeDropdown,
    MenuFontFamilyDropdown,
    MenuAddAttachmentButton,
    MenuTodoListButton,
    MenuOutdentButton,
    MenuIndentButton,
    MenuOrderedListButton,
    MenuBulletListButton,
    MenuColorButton,
    MenuHighlightButton,
    MenuStrikeButton,
    MenuAddLinkButton,
    MenuItalicButton,
    MenuBoldButton,
    MenuUnderlineButton,
    MenuHeadingDropdown,
    MenuAddImageButton,
    MenuTextAlignButtons,
    TablePopover,
  },
})
export default class MenuBar extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;
}
