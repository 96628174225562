
import { Company } from '@/models/Company';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ArticlePlayer from '@/components/article-player/ArticlePlayer.vue';
import ArticleTransferDialog from '@/components/layout/dialogs/ArticleTransferDialog.vue';
import ArticleVersionsFooter from '@/components/ArticleVersionsFooter/ArticleVersionsFooter.vue';
import { Workspace } from '@/models/Workspace';
import { Article } from '@/models/article/Article';
import { User } from '@/models/User';
import helper from '@/helper';
import ArticlesMergeDialog from '@/components/layout/dialogs/ArticlesMergeDialog.vue';
import EventBus from '@/EventBus';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import { ARTICLE_CREATE, CHECK_PARAGRAPH_PROGRESS, PARAGRAPH_PROGRESS_UPDATE } from '@/events';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import VueApp from '@/@types/app/VueApp';
import EmptyStateCard from '@/components/layout/cards/EmptyStateCard.vue';
import { Logger } from '@/other/Logger';
import EditorNavbar from '@/components/article-editor/EditorNavbar/EditorNavbar.vue';
import { commitSetSelectedArticle } from '@/store/commits/workspaceViewCommits';
import { commitSetArticleMode, commitSetAutoPlay } from '@/store/commits/sharedCommits';
import urlService from '@/services/UrlService';
import { IAutoplay } from '@/models/IAutoplay';
import articlePlayerProvider from '@/services/ui-providers/ArticlePlayerProvider';
import notificationService from '@/services/NotificationService';
import * as routerNames from '@/routerNames';
import { LOGIN, WORKSPACES } from '@/routerNames';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { Paragraph } from '@/models/article/Paragraph';
import ArticleTagsDialog from '@/components/layout/dialogs/ArticleTagsDialog.vue';
import { Mutations } from '@/store/mutations';
import {
  dispatchWorkspaceViewPageMounted,
  dispatchWorkspaceViewSelectedArticleChanged,
} from '@/store/dispatchers/workspaceViewDispatchers';
import VideoReactionMenu from '@/components/article-player/VideoReactionMenu.vue';
import authService from '@/services/AuthService';
import { dispatchDisableOcrMode } from '@/store/dispatchers/uiDispatchers';
import {
  dispatchCloseArticleVersionMode,
  dispatchResetArticleVersionView,
} from '@/store/dispatchers/articleVersionDispatchers';
import { commitSetArticleRecordingReactions } from '@/store/commits/commentsSidebarCommits';
import { RecordingReaction } from '@/@types/RecordingReaction';
import { dispatchUpdateArticleMode } from '@/store/dispatchers/articleDispatchers';
import SubArticleCreateDialog from '@/components/layout/dialogs/SubArticleCreateDialog.vue';
import { commitSetArticleEditorArticle } from '@/store/commits/articleEditorCommits';
import store from '@/store';
import apiService from '@/services/ApiService';
import slugify from 'slugify';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';

const log = new Logger('WorkspaceViewPage');

@Component({
  components: {
    ArticleTransferDialog,
    SubArticleCreateDialog,
    VideoReactionMenu,
    ArticleTagsDialog,
    EmptyStateCard,
    EditorNavbar,
    ArticlesMergeDialog,
    ArticlePlayer,
    ArticleTextEditor: () => import('@/components/article-editor/ArticleTextEditor.vue'),
    ArticleVersionsFooter,
  },
})
export default class WorkspaceViewPage extends VueApp {
  @Prop({ required: true }) readonly id!: string;
  @Prop({ required: true }) readonly workspace: Workspace;
  @Prop(Article) readonly sharedArticle: Article;

  loader: any = null;

  articles: Article[] = [];
  playerHeight = 0;
  reorderDialogVisible = false;
  style = {};
  showNavbar = true;
  lastScrollPosition = 0;
  timeoutContainer: any = null;
  updateCurrentUrlTimeout: number = null;
  INIT_TIMEOUT_DURATION = 1000;
  maxMobileLandscapeHeight = 375;
  currentParagraphProgress = 0;
  availableCompanies: Company[];

  $refs: {
    articleTransferDialog: ArticleTransferDialog;
    articlesMergeDialog: ArticlesMergeDialog;
    subArticleCreateDialog: SubArticleCreateDialog;
    workspaceContainer: HTMLDivElement;
    playerContainer: HTMLDivElement;
  };

  setNavbarWidth() {
    const isMainSidebarExpanded = store.state.ui.isMainSidebarExpanded;
    return this.isMobile ? 'calc(100% - 40px)' : `calc(100% - ${isMainSidebarExpanded ? 570 : 460}px)`;
  }

  get isNotesModePinned() {
    return !!this.$store.state.articleEditor.article?.pinnedNote && !this.$route.params.paragraphSlug;
  }

  get isMobileView() {
    return this.$store.state.isMobile;
  }

  get isOcrMode() {
    return this.$store.state.ui.isOcrModeEnabled;
  }

  get user(): User {
    return this.$store.state.user;
  }

  get isGuest(): boolean {
    return this.$auth.isGuest();
  }

  get selectedArticle() {
    return this.$store.state.workspaceView.selectedArticle;
  }

  get selectedParagraph(): Paragraph {
    return this.$store.state.workspaceView.selectedParagraph;
  }

  get articleMode() {
    return this.$store.state.articleMode;
  }

  get workspaceView() {
    return workspaceService.workspaceView;
  }

  get isMobile() {
    return mobileResponsiveService.isMobileView;
  }

  get userCanManageArticles() {
    return this.workspaceView.workspace.canEditArticles();
  }

  get windowInnerHeight() {
    return mobileResponsiveService.windowInnerHeight;
  }

  get workspaceContainerStyles() {
    const headerNavbarHeight = 100;

    if (this.articleMode === ArticleEditMode.VIDEO) {
      this.style = {
        height:
          this.isMobile && this.windowInnerHeight
            ? `${this.windowInnerHeight - headerNavbarHeight}px`
            : `${this.windowInnerHeight - 200}px`,
      };
    } else if (this.articleMode === ArticleEditMode.NOTES) {
      this.style = {
        height:
          this.isMobile && this.windowInnerHeight
            ? `${this.windowInnerHeight - headerNavbarHeight}px`
            : `calc(100% - 68px)`,
      };
    }
    return this.style;
  }

  get autoPlay() {
    return this.$store.state.autoPlay;
  }

  get isSharedMode() {
    return this.workspaceView.isSharedMode;
  }

  get articleVersionView() {
    return this.$store.state.articleVersionView;
  }

  get nonArchivedArticles() {
    const articles = this.workspaceView.articles;
    let filteredArticles: any = [];
    if (articles.length) {
      filteredArticles = articles.filter((article: Article) => article.isArchived !== true);
    }

    return filteredArticles ? filteredArticles.length : false;
  }

  get personalWorkspaceId() {
    return this.$store.state.personalWorkspace?.id;
  }

  get isAuthorized() {
    return authService.isAuthorized();
  }

  get selectedTextTabSlug() {
    return this.$store.state.articleEditor.selectedTextTab.slug;
  }

  loadSelectedTextTab(mode: ArticleEditMode) {
    const { selectedTextTabSlug, selectedArticle } = this;
    if (!selectedTextTabSlug || !selectedArticle) return;
    const selectedTextTab = this.$store.state.articleEditor.selectedTextTab;

    // check if selected tab is present in article
    const selectedFoundedTextTab = selectedTextTab.tab
      ? selectedArticle.textTabs.find((item) => item.id === selectedTextTab.tab.id)
      : null;

    const foundItem = selectedArticle.textTabs.find((item) => slugify(item.title) === selectedTextTabSlug);
    const pinnedTextTab = selectedArticle.textTabs.find((tab) => tab.id === selectedArticle.pinnedNote);
    const tab = pinnedTextTab ? pinnedTextTab : selectedFoundedTextTab ? selectedFoundedTextTab : foundItem;

    if (!tab) {
      const mode = !selectedArticle.textTabs.length ? ArticleEditMode.VIDEO : ArticleEditMode.NOTES;
      const route = urlService.getArticleViewRoute(selectedArticle, this.workspace, mode);
      return this.$router.push(route);
    }

    if (pinnedTextTab) {
      dispatchUpdateSelectedTextTab(pinnedTextTab);
      const route = urlService.getTextTabViewRoute(slugify(pinnedTextTab.title), selectedArticle, this.workspace);
      return this.$router.push(route);
    }

    if (mode === ArticleEditMode.NOTES) return dispatchUpdateSelectedTextTab(tab);

    return dispatchUpdateSelectedTextTab(null);
  }

  async init() {
    this.loader = this.$loading({ lock: true, text: 'Loading articles' });

    this.timeoutContainer = setTimeout(() => {
      const container = this.$refs.workspaceContainer;
      if (container) {
        container.addEventListener('scroll', this.onScroll);
      }
    }, this.INIT_TIMEOUT_DURATION);

    EventBus.$emit('toggle-sidebar', true);
    const autoPlayArticleId = this.$store.state.autoPlay ? this.$store.state.autoPlay.articleId : null;

    try {
      if (this.workspace) {
        await workspaceService.loadWorkspace(this.workspace);
      } else if (!this.sharedArticle) {
        notificationService.error(`The shared article hasn't been found.`);
        await this.$router.push({ name: this.isAuthorized ? WORKSPACES : LOGIN });
        return;
      } else {
        await dispatchUpdateArticleMode(this.sharedArticle);
      }
      // redirect to workspace page if article archived
      if (this.selectedArticle?.isArchived && autoPlayArticleId === this.selectedArticle.id) {
        commitSetAutoPlay(null);
        await this.$router.push(urlService.getWorkspaceViewRoute(this.workspace));
      }
    } catch (err) {
      log.error(err);
    } finally {
      this.loader.close();
    }

    window.addEventListener('resize', () => {
      this.updatePlayerHeight();
    });
    this.$nextTick(this.updatePlayerHeight);

    EventBus.$on('transfer-article', async (article: Article) => {
      await this.handleTransferArticle(article);
    });

    EventBus.$on('merge-article', async (article: Article) => {
      await this.handleMergeArticle(article);
    });

    EventBus.$on('reorder-articles', () => {
      this.handleReorderArticles();
    });

    EventBus.$on('create-sub-article', async (article: Article) => {
      await this.handleCreateSubArticle(article);
    });

    // Auto scroll to video player
    if (window.innerHeight < this.maxMobileLandscapeHeight && this.isMobile && this.articleMode === 'video') {
      const container = this.$refs.workspaceContainer;
      const scrollOffset = 85;
      container.scrollTo({ top: scrollOffset, behavior: 'smooth' });
    }
  }

  async mounted() {
    await this.init();
    await dispatchWorkspaceViewPageMounted();
  }

  beforeDestroy() {
    clearTimeout(this.timeoutContainer);
    EventBus.$off('reorder-articles');
    EventBus.$off('merge-article');
    EventBus.$off(CHECK_PARAGRAPH_PROGRESS);
    commitSetSelectedArticle(null);
    dispatchResetArticleVersionView();
    window.removeEventListener('resize', () => {
      this.updatePlayerHeight();
    });
    window.clearTimeout(this.updateCurrentUrlTimeout);
    dispatchDisableOcrMode();
  }

  // methods
  handlePlayerReady() {
    this.updatePlayerHeight(true);
  }

  handleParagraphPlaying(data: { paragraph: Paragraph; progress: number; paragraphTime: number }) {
    const { paragraph, progress } = data;

    if (
      (!this.selectedParagraph && paragraph) ||
      (this.selectedParagraph && paragraph && this.selectedParagraph?.id !== paragraph?.id)
    ) {
      workspaceService.setState('selectedParagraph', paragraph);
      if (this.articleMode === ArticleEditMode.VIDEO) {
        window.clearTimeout(this.updateCurrentUrlTimeout);
        this.updateCurrentUrlTimeout = window.setTimeout(() => {
          this.updateCurrentUrl(paragraph);
        }, 500);
      }
    }
    this.currentParagraphProgress = progress;
    EventBus.$emit(PARAGRAPH_PROGRESS_UPDATE, progress * 100);
  }

  async handleTransferArticle(article: Article) {
    try {
      const dialog = this.$refs.articleTransferDialog;
      if (dialog && typeof dialog.show === 'function') {
        await dialog.show(article, this.availableCompanies);
      }
    } catch (err) {
      if (!err) return;
      log.error(err);
    }
  }

  async handleMergeArticle(article: Article) {
    try {
      const workspace = this.workspaceView.workspace;
      const rightArticle = await this.$refs.articlesMergeDialog.show(article);

      commitSetAutoPlay({ workspaceId: workspace.shortId, articleId: rightArticle.shortId });
      await workspaceService.loadWorkspaceById(this.workspaceView.workspace.id);
    } catch (err) {
      if (!err) return;
      log.error(err);
    }
  }

  handleReorderArticles() {
    this.$store.commit(Mutations.SET_REORDER_MODE, true);
    this.reorderDialogVisible = true;
  }

  async handleCreateSubArticle(article: Article) {
    try {
      const workspace = this.workspaceView.workspace;

      const showDialog = async () => {
        if (!this.$refs.subArticleCreateDialog) return;
        try {
          const item: Article = await this.$refs.subArticleCreateDialog.show(article, workspace);

          if (item instanceof Article) {
            const routeParams = item.id ? { id: item.id } : {};
            await this.$router.push({ name: routerNames.ARTICLE_CREATE, params: routeParams });
            if (routerNames.ARTICLE_CREATE !== this.$route.name) return;

            commitSetArticleEditorArticle(item);
            commitSetArticleMode(ArticleEditMode.NOTES);
            store.commit('setCreateArticle', item);
          }
        } catch (err) {
          apiService.handleResponseError(err);
        }
      };

      await showDialog();
    } catch (err) {
      if (!err) return;
      apiService.handleResponseError(err);
    }
  }

  handleVideoDurationDetected(data: any) {
    const { video, duration } = data;
    if (this.workspaceView.selectedArticle) {
      this.workspaceView.selectedArticle.updateVideoDuration(video, duration);
      helper.observerNotify(this.workspaceView.selectedArticle.paragraphs);
    }
  }

  createArticle() {
    this.$store.commit('setWorkspace', this.workspaceView.workspace);
    EventBus.$emit(ARTICLE_CREATE);
  }

  updatePlayerHeight(fixUpdate = false) {
    if (this.$refs.playerContainer) {
      this.playerHeight = (this.$refs.playerContainer as HTMLElement).clientHeight;
    } else if (!fixUpdate) {
      setTimeout(() => {
        if (this && this.$refs.playerContainer) {
          this.updatePlayerHeight(true);
        }
      }, 500);
    }
  }

  async updateCurrentUrl(paragraph: Paragraph) {
    if (!paragraph || this.sharedArticle) return;

    const currentRouteParams = this.$route.params;
    const paragraphSlug = paragraph.getSlug();

    if (
      currentRouteParams.mode === ArticleEditMode.NOTES ||
      (this.isNotesModePinned && !currentRouteParams.paragraphSlug) ||
      !(!currentRouteParams.paragraphSlug || currentRouteParams.paragraphSlug !== paragraphSlug)
    ) {
      return;
    }

    const route = urlService.getParagraphViewRoute(
      currentRouteParams.paragraphSlug ? currentRouteParams.paragraphSlug : paragraphSlug,
      this.selectedArticle,
      this.$store.state.workspace
    );

    await this.$router.push(route);
  }

  onScroll() {
    const container = document.querySelector('.workspace-container') as HTMLElement;
    const navbar = document.querySelector('#player-navbar') as HTMLElement;
    const currentScrollPosition = container.scrollTop || document.documentElement.scrollTop;

    if (container.scrollTop > 140 && this.showNavbar) {
      navbar.style.position = 'fixed';
      navbar.style.width = this.setNavbarWidth();
    } else if (container.scrollTop === 0) {
      navbar.style.width = '100%';
      navbar.style.position = 'absolute';
    }

    if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 65) {
      return;
    }
    this.showNavbar = currentScrollPosition < this.lastScrollPosition;
    this.lastScrollPosition = currentScrollPosition;
  }

  created() {
    const user = store.state.user;

    EventBus.$on(CHECK_PARAGRAPH_PROGRESS, () => {
      EventBus.$emit(PARAGRAPH_PROGRESS_UPDATE, this.currentParagraphProgress * 100);
    });

    this.availableCompanies = store.state.userCompanies.filter((company: Pick<Company, 'id' | 'title'>) => {
      const companyId = typeof user.company === 'object' ? user.company.id : user.company;
      return company.id !== companyId;
    });
  }

  @Watch('id')
  async onWorkspaceIdChanged() {
    // update workspace if route changed
    await workspaceService.loadWorkspaceById(this.id);
  }

  @Watch('selectedArticle', { deep: true })
  async onSelectedArticleChanged(article: Article, oldArticle: Article) {
    if (article && oldArticle && article.id === oldArticle.id) return;
    const container = this.$refs.workspaceContainer;

    if (container) {
      container.scrollTop = 0;
    }

    if (this.selectedArticle) {
      if (this.articleMode) await this.loadSelectedTextTab(this.articleMode);
      if (!this.articleMode || !this.$route.params.paragraphSlug) {
        await dispatchUpdateArticleMode(this.selectedArticle);
      }
      this.$api.articleReactions.get(this.selectedArticle.id).then((res) => {
        const { reactions } = res.data;
        commitSetArticleRecordingReactions(reactions as unknown as RecordingReaction[]);
      });
    }

    if (article && oldArticle && article.id !== oldArticle.id && this.articleVersionView.isShowFooter) {
      await dispatchCloseArticleVersionMode();
    }

    this.showNavbar = true;
    await dispatchWorkspaceViewSelectedArticleChanged();
  }

  @Watch('autoPlay', { deep: true })
  async onAutoPlayChanged(value: IAutoplay) {
    const workspace = this.workspaceView.workspace;
    if (value && (value.workspaceId === workspace.id || value.workspaceId === workspace.shortId)) {
      await workspaceService.checkAutoplay();
    }
  }

  @Watch('articleMode')
  onArticleModeChanged() {
    const videoPlayerRef = articlePlayerProvider.videoPlayer;
    this.workspaceContainerStyles;
    if (videoPlayerRef) {
      videoPlayerRef.stop();
    }
    // timeout for waiting video on the page
    setTimeout(() => {
      this.updatePlayerHeight();
    });
  }

  @Watch('$route')
  onRouteChanged() {
    this.$store.dispatch('commentsSidebarCheckAutoShowComment');
  }
}
