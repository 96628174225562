/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * @deprecated pls dont use this class anymore.
 * @see modelService
 */
export class BaseModel {
  [index: string]: any;

  static fromJson(data: any | unknown = null) {
    const instance = new this();
    instance.load(data);
    return instance;
  }

  load(data: any = null) {
    if (typeof data === 'object') {
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key) && this.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }
      // @ts-ignore
      this.afterLoad(data);
    } else {
      // @ts-ignore
      this.afterLoad({});
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  afterLoad(): void {}

  toObject(): any {
    return JSON.parse(JSON.stringify(this));
  }

  clear(): void {
    for (const key in this) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(key)) {
        // @ts-ignore
        this[key] = null;
      }
    }
  }
}
