/* eslint-disable @typescript-eslint/ban-ts-comment */
import Vue from 'vue';
import Vuex from 'vuex';
import * as Sentry from '@sentry/vue';
import store from '@/store';
import App from './App.vue';
import router from './router';
import Element from 'element-ui';
import EmptyCard from './components/layout/shared/EmptyCard.vue';
import VueMaterial from 'vue-material';
import InlineSvg from 'vue-inline-svg';
import apiService from './services/ApiService';
import authService from './services/AuthService';
import VueApp from '@/@types/app/VueApp';
import logger from '@/other/Logger';
import navigationService from '@/services/NavigationService';
import ElementUI from 'element-ui';

import { workspaceService } from '@/services/workspace/WorkspaceService';
import articleEditService from '@/services/article/ArticleEditService';
import draftService from '@/services/draft/DraftService';
import articleCommentService from '@/services/article/ArticleCommentService';
import extensionService from '@/services/ExtensionService';

import 'element-ui/lib/theme-chalk/index.css';
import './scss/element-ui-customization.scss';
import 'vue-material/dist/vue-material.min.css';

logger.info('Initializing the application');

if (process.env.VUE_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    attachStacktrace: true,
    integrations: [
      Sentry.vueIntegration({
        tracingOptions: {
          trackComponents: true, // Set to true to track all components
          hooks: ['mount', 'update', 'unmount'], // Lifecycle hooks to monitor
          timeout: 2000, // Timeout in milliseconds for tracking
        },
      }),
      Sentry.browserTracingIntegration({
        router: router,
      }),
    ],
    environment: process.env.VUE_APP_SENTRY_ENV || 'development',
    release: process.env.VUE_APP_VERSION,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    tracesSampleRate: 1.0,
    tunnel: `${apiService.baseUrl}/sentry-proxy`,
  });
  logger.info('Sentry enabled');
}

if (!process.env.VUE_APP_PRODUCTION) {
  Vue.config.productionTip = false;
}

navigationService.setRouter(router);

Vue.use(Element, { size: 'small', zIndex: 3000 });
Vue.use(VueMaterial);
Vue.use(Vuex);
Vue.component('inline-svg', InlineSvg);
Vue.component('empty-card', EmptyCard);
Vue.use(ElementUI);

// Add dependency injection of Api service to child components
Vue.mixin({
  beforeCreate() {
    const self = this as VueApp;
    self.$api = apiService;
    self.$auth = authService;
  },
});

// Set store for service to avoid dependency loops
authService.setStore(store);
workspaceService.setStore(store);
articleEditService.setStore(store);
draftService.setStore(store);
articleCommentService.setStore(store);
extensionService.setStore(store);

new Vue({
  store,
  router,
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Snaplore',
  components: { App },
  render: (h) => h(App),
}).$mount('#app');
