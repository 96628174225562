import { Paragraph as TiptapParagraph } from '@tiptap/extension-paragraph';
import uniqKeyAttributeParse from '@/components/tiptap/extensions/UniqKey/uniqKeyAttributeParse';

const Paragraph = TiptapParagraph.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : {}),
      key: {
        default: null,
        parseHTML: uniqKeyAttributeParse,
        renderHTML: (attributes) => ({ key: attributes.key }),
        keepOnSplit: false,
      },
    };
  },
});

export default Paragraph;
