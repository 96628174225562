import apiService from '@/services/ApiService';
import { State } from '@/store/state';
import { ActionContext } from 'vuex';
import {
  commitSetDraftsCount,
  commitSetIsOcrModeEnabled,
  commitSetIsOcrRecognitionFinished,
  commitSetMeetingsCount,
  commitSetPersonalArticlesCount,
} from '@/store/commits/uiCommits';

export const uiActions = {
  async getDraftsCount() {
    try {
      const {
        data: { draftsCount },
      } = await apiService.draft.count();

      commitSetDraftsCount(draftsCount || 0);
    } catch (error) {
      apiService.handleResponseError(error);
    }
  },
  async getMeetingsCount() {
    try {
      const {
        data: { count },
      } = await apiService.meetings.count();

      commitSetMeetingsCount(count || 0);
    } catch (error) {
      apiService.handleResponseError(error);
    }
  },
  async getPersonalArticlesCount({ state }: ActionContext<State, {}>) {
    const personalWorkspaceId = state.user?.personalWorkspaceId;

    if (!personalWorkspaceId) {
      commitSetPersonalArticlesCount(0);
      return;
    }

    try {
      const {
        data: { articlesCount },
      } = await apiService.workspace.getArticlesCount(personalWorkspaceId, true);

      commitSetPersonalArticlesCount(articlesCount);
    } catch (error) {
      apiService.handleResponseError(error);
    }
  },
  disableOcrMode({ state }: ActionContext<State, {}>) {
    if (!state.ui.isOcrModeEnabled) return;
    commitSetIsOcrModeEnabled(false);
    commitSetIsOcrRecognitionFinished(false);
  },
  async enableOcrMode({ state }: ActionContext<State, {}>) {
    if (state.ui.isOcrModeEnabled) return;
    await state.videoPlayerRef.stop();
    commitSetIsOcrModeEnabled(true);
  },
};
