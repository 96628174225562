
import '../../../styles/MenuButton.scss';

import { Component, Prop, Vue } from 'vue-property-decorator';
import VueAwesomeIcon from '@/components/VueAwesomeIcon/VueAwesomeIcon.vue';
import { Editor } from '@tiptap/core';
import { dispatchUpdateArticleComment } from '@/store/dispatchers/articleCommentsDispatchers';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { commitSetCommentsSidebarIsVisible } from '@/store/commits/commentsSidebarCommits';

@Component({
  components: {
    VueAwesomeIcon,
  },
})
export default class EmojiIconButton extends Vue {
  @Prop({ required: true }) readonly icon!: string;
  @Prop({ default: null, required: false }) editor: Editor;
  @Prop({ default: null }) commentId: string;
  @Prop({ default: null }) amount: number;
  @Prop({ default: true }) show: boolean;
  @Prop({ default: false }) isVideoReaction: boolean;

  get articleMode() {
    return this.$store.state.articleMode;
  }

  get videoPlayerRef() {
    if (this.articleMode === ArticleEditMode.VIDEO) {
      return this.$store.state.videoPlayerRef;
    }
    return null;
  }

  async runCommand(): Promise<void> {
    if (this.isVideoReaction) {
      commitSetCommentsSidebarIsVisible(true);
      return;
    }
    this.$emit('close');
    this.editor && !this.commentId ? this.addEmoji() : await this.addReaction();
  }

  addEmoji() {
    this.editor.chain().focus().insertEmoji(this.icon).run();
  }

  async addReaction() {
    await dispatchUpdateArticleComment(this.commentId, -1, null, { content: this.icon, label: this.icon });
  }
}
