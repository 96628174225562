
import { Component, Prop } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import { Article } from '@/models/article/Article';
import { Workspace } from '@/models/Workspace';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { WorkspaceView } from '@/@types/WorkspaceView';
import { Paragraph } from '@/models/article/Paragraph';
import urlService from '@/services/UrlService';
import helper from '@/helper';
import store from '@/store';
import notificationService from '@/services/NotificationService';
import { dispatchOpenSelectedParagraph } from '@/store/dispatchers/workspaceViewDispatchers';
import { WorkspaceOrder } from '@/@types/WorkspaceOrder';

@Component({
  components: {
    SidebarArticleItem: () => import('./SidebarArticleItem.vue'),
    SidebarParagraphItem: () => import('../SidebarParagraphItem/SidebarParagraphItem.vue'),
  },
})
export default class SidebarNestedArticleItem extends VueApp {
  @Prop(Object) article: Article;
  @Prop(Object) workspace!: Workspace;
  @Prop(Object) workspaceView!: WorkspaceView;
  @Prop(String) articleMode?: ArticleEditMode;

  @Prop(Boolean) isMobileView!: boolean;
  @Prop(Boolean) isSharedMode!: boolean;
  @Prop(Boolean) isNestedParagraph!: boolean;
  @Prop(Boolean) showArchivedArticles!: boolean;

  @Prop(Array) expanded: string[];
  @Prop() nestedOrder: WorkspaceOrder[];
  @Prop({ default: 1 }) nested: number;
  @Prop({ default: 0 }) paragraphProgress: number;
  @Prop() recalculateSidebarHeight: () => void;

  handleSelectParagraph(paragraph: Paragraph, article: Article, newTab?: boolean) {
    dispatchOpenSelectedParagraph({ paragraph, article, newTab });

    if (this.isMobileView && !newTab) {
      store.commit('toggleMainSidebar', false);
    }
  }

  handleCopyParagraphUrl(paragraph: Paragraph, article: Article) {
    const workspace = this.workspaceView.workspace;

    if (workspace.slug && article.slug && paragraph.title) {
      const paragraphSlug = paragraph.getSlug();
      helper.copyToClipboard(urlService.getParagraphViewUrl(paragraphSlug, article, workspace));
      notificationService.success('Paragraph link copied to the clipboard');
    } else {
      notificationService.warning('Cannot copy link to this paragraph');
    }
  }

  hasArticle(order: any) {
    const foundArticle = this.workspaceView.articles.filter((article: Article) => {
      return article.id === order.value;
    });
    if (foundArticle.length > 0) {
      return foundArticle[0];
    }
    return null;
  }
}
