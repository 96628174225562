import { AnyJson } from '@/@types/AnyJson';

const keepPropsOnClear = ['debug', 'DEBUG', 'workspacesOrdering'];

export class LocalStorageService {
  clear(keep?: string[]) {
    const keepProps: AnyJson = {};
    const keepKeys = [...keepPropsOnClear, ...(keep || [])];
    keepKeys.forEach((key) => {
      const value = localStorage.getItem(key);

      if (value !== null) {
        keepProps[key] = value;
      }
    });

    localStorage.clear();

    keepKeys.forEach((key) => {
      if (keepProps[key] !== undefined) {
        localStorage.setItem(key, keepProps[key]);
      }
    });
  }
}

const localStorageService = new LocalStorageService();
export default localStorageService;
