
import VueApp from '@/@types/app/VueApp';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SwitchSectionButton extends VueApp {
  @Prop({ type: String }) iconClass: string;
  @Prop({ type: Boolean, default: false }) isActive: boolean;
  @Prop({ type: Boolean, default: true }) showAfterBorder: boolean;
  @Prop(String) title: string;
  @Prop(String) titleNoContent: string;

  handleClick() {
    this.$emit('click');
  }
}
