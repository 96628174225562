
import MentionListItem from '@/components/tiptap/extensions/Mention/MentionListItem.vue';
import { SuggestionEventProps } from '@/components/tiptap/extensions/Mention/SuggestionEventProps';
import { Group } from '@/components/tiptap/extensions/SlashCommand/types';
import { Editor } from '@tiptap/core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: { MentionListItem },
})
export default class SlashList extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ type: Array, required: true }) items: Group[];
  @Prop({ type: Object, required: true }) range: { from: number; to: number };
  @Prop({ type: Function, required: true }) command: Function;

  selectedCommandIndex = 0;
  selectedGroupIndex = 0;

  onKeyDown({ event }: SuggestionEventProps) {
    if (event.key === 'ArrowUp') {
      this.upHandler();
      return true;
    }

    if (event.key === 'ArrowDown') {
      this.downHandler();
      return true;
    }

    if (event.key === 'Enter') {
      return this.enterHandler();
    }

    return false;
  }

  upHandler() {
    if (!this.items.length) {
      return false;
    }

    let newCommandIndex = this.selectedCommandIndex - 1;
    let newGroupIndex = this.selectedGroupIndex;

    if (newCommandIndex < 0) {
      newGroupIndex = this.selectedGroupIndex - 1;
      newCommandIndex = this.items[newGroupIndex]?.commands.length - 1 || 0;
    }

    if (newGroupIndex < 0) {
      newGroupIndex = this.items.length - 1;
      newCommandIndex = this.items[newGroupIndex].commands.length - 1;
    }

    this.selectedGroupIndex = newGroupIndex;
    this.selectedCommandIndex = newCommandIndex;

    return true;
  }

  downHandler() {
    if (!this.items.length) {
      return false;
    }

    const commands = this.items[this.selectedGroupIndex].commands;

    let newCommandIndex = this.selectedCommandIndex + 1;
    let newGroupIndex = this.selectedGroupIndex;

    if (commands.length - 1 < newCommandIndex) {
      newCommandIndex = 0;
      newGroupIndex = this.selectedGroupIndex + 1;
    }

    if (this.items.length - 1 < newGroupIndex) {
      newGroupIndex = 0;
    }

    this.selectedGroupIndex = newGroupIndex;
    this.selectedCommandIndex = newCommandIndex;

    return true;
  }

  enterHandler() {
    if (!this.items.length || this.selectedGroupIndex === -1 || this.selectedCommandIndex === -1) {
      return false;
    }

    this.selectItem(this.selectedGroupIndex, this.selectedCommandIndex);

    return true;
  }

  selectItem(groupIndex: number, commandIndex: number) {
    const command = this.items[groupIndex].commands[commandIndex];

    if (!command) return;

    this.command(command);
  }

  @Watch('items')
  onItemsChanged() {
    this.selectedCommandIndex = 0;
    this.selectedGroupIndex = 0;
  }
}
