import { ArticleComment } from '@/models/article/ArticleComment';
import { ArticleCommentQuote } from '@/@types/ArticleCommentQuote';
import { ArticleCommentVideoReaction } from '@/@types/ArticleCommentVideoReaction';
import { RecordingReaction } from '@/@types/RecordingReaction';

export interface CommentsSidebarState {
  isVisible: boolean;
  comments: ArticleComment[];
  quote?: ArticleCommentQuote;
  scrollToCommentId?: string;
  videoReaction?: ArticleCommentVideoReaction;
  recordingReactions: RecordingReaction[];
  isActiveTimeTag: boolean;
}

export const commentsSidebarState: CommentsSidebarState = {
  quote: undefined,
  isVisible: false,
  comments: [],
  scrollToCommentId: undefined,
  videoReaction: undefined,
  recordingReactions: [],
  isActiveTimeTag: true,
};
