import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios from 'axios';

export class SharedArticleServiceProvider extends ApiBaseServiceProvider {
  scope = 'shared';

  //getting sharedArticle by original article id
  get(articleId: string) {
    return axios.get(this.getBaseUrl() + 'article/' + articleId, this.getRequestConfig());
  }

  getArticleSharedEntities(articleId: string) {
    return axios.get(this.getBaseUrl() + 'article/' + articleId + '/entities/', this.getRequestConfig());
  }

  getSharedArticleToken = (articleId: string, isPrivate = false, isFirstWorkspaceArticle = false) => {
    const url = `${this.getBaseUrl()}article/${articleId}/token?isPrivate=${isPrivate}&isFirstWorkspaceArticle=${isFirstWorkspaceArticle}`;
    return axios.get(url, this.getRequestConfig());
  };

  toggleShared(articleId: string, shared: boolean, isRefreshToken: boolean) {
    return axios.post(
      this.getBaseUrl() + 'article/' + articleId,
      { id: articleId, shared, isRefreshToken },
      this.getRequestConfig()
    );
  }

  addSharedEntities(articleId: string, entities: any[]) {
    return axios.post(this.getBaseUrl() + 'article/' + articleId + '/entities/', { entities }, this.getRequestConfig());
  }

  removeSharedEntity(articleId: string, entityId: string) {
    return axios.delete(this.getBaseUrl() + 'article/' + articleId + '/entity/', {
      ...this.getRequestConfig(),
      data: { entityId },
    });
  }
}
