import { User } from './User';
import AuthService from '../services/AuthService';
import { RolesCollection } from './RolesCollection';
import { SlackIntegration } from '@/models/SlackIntegration';
import { Group } from '@/models/Group';
import { GroupsCollection } from '@/models/GroupsCollection';
import { Role } from '@/models/Role';
import { UserInfo } from './UserInfo';
import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';
import { WorkspaceVisibility } from '@/other/WorkspaceVisibility';
import slugify from 'slugify';

export class Workspace implements Model {
  static getRolesList() {
    return {
      [Role.ADMIN]: 'admin',
      [Role.EDITOR]: 'can edit',
      [Role.VIEWER]: 'can view',
    };
  }

  id: string = null;
  company: string = null;
  title = '';
  shortId = '';
  description = '';
  visibility: WorkspaceVisibility = WorkspaceVisibility.private;
  roles: RolesCollection;
  groups: GroupsCollection;
  order: any[];
  slackIntegration: SlackIntegration;
  createdAt: number;
  lastActivityAt: number;
  user: UserInfo;
  author: Omit<UserInfo, 'id'>;
  isFavorite: boolean;
  isArchived: boolean;

  constructor() {
    this.slackIntegration = new SlackIntegration();
    this.roles = new RolesCollection([]);
    this.groups = new GroupsCollection([]);
  }

  static parse(data: unknown) {
    return modelService.create(Workspace, data);
  }

  get slug() {
    const title = this.title?.indexOf('PW_') == 0 ? 'personal' : this.title;
    return slugify(title);
  }

  stringify() {
    const model = JSON.parse(JSON.stringify(this));
    const roles = this.roles ? JSON.parse(JSON.stringify(this.roles.roles)) : [];
    const groups = this.groups ? JSON.parse(JSON.stringify(this.groups.groups)) : [];

    return JSON.stringify({ ...model, roles, groups });
  }

  afterLoad(data: any): void {
    this.slackIntegration = new SlackIntegration();
    if (data.roles) {
      this.roles = RolesCollection.collect(data.roles);
    }
    if (data.groups) {
      this.groups = GroupsCollection.collect(data.groups);
    }
    if (data.slackIntegration) {
      this.slackIntegration.load(data.slackIntegration);
    }
    if (data.user) {
      typeof data.user === 'string' ? (this.user = data.user) : (this.user = { ...data.user });
    }

    if (data.order) {
      this.order = [...data.order];
    }

    this.createdAt = data.createdAt;
    this.lastActivityAt = data.lastActivityAt;
    this.isFavorite = data.isFavorite;
    this.isArchived = data.isArchived;
    this.author = data.author;
  }

  checkUserRoles(user: User, roles: string[]): boolean {
    return this.roles && this.roles.checkRole(user, roles);
  }

  checkUserGroupRoles(user: User, roles: string[]): boolean {
    return user.groups.some((group: Group) => {
      return this.groups.checkRole(group.id, roles);
    });
  }

  // check permission for edit workspace
  _canEdit(user: User | null = null, roles: string[]): boolean {
    if (!user) {
      return false;
    }
    const isSameCompany =
      typeof user.company === 'string' ? user.company === this.company : user.company.id === this.company;
    const isUserActive = user.companyIds.includes(this.company);
    if (this.visibility === WorkspaceVisibility.personal && isUserActive) {
      return user.personalWorkspaceId === this.id;
    } else {
      return (
        user &&
        isUserActive &&
        isSameCompany &&
        (user.isAdminOrOwner() || this.checkUserRoles(user, roles) || this.checkUserGroupRoles(user, roles))
      );
    }
  }

  canEdit(user: User | null = null): boolean {
    if (user === null) {
      user = AuthService.user;
    }

    const roles = [Role.ADMIN];
    return this._canEdit(user, roles);
  }

  canEditArticles(user: User | null = null): boolean {
    if (user === null) {
      user = AuthService.user;
    }

    const roles = [Role.EDITOR, Role.ADMIN];
    return this._canEdit(user, roles);
  }

  // check permission for delete workspace
  canDelete(user: User | null = null): boolean {
    return this.canEdit(user);
  }

  // check permission for ability to manage workspace
  canManage(user: User | null = null): boolean {
    if (user === null) {
      user = AuthService.user;
    }

    const roles = [Role.ADMIN];
    return user && (user.isAdminOrOwner() || this.checkUserRoles(user, roles) || this.checkUserGroupRoles(user, roles));
  }
}
