
import { Component } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import { Company } from '@/models/Company';
import SwitchSectionButton from '@/components/layout/buttons/SwitchSectionButton/SwitchSectionButton.vue';
import AdminCompanyGeneralSection from '@/components/pages/admin/AdminCompany/sections/AdminCompanyGeneralSection/AdminCompanyGeneralSection.vue';
import AdminCompanyUsersSection from '@/components/pages/admin/AdminCompany/sections/AdminCompanyUsersSection/AdminCompanyUsersSection.vue';
import AdminCompanyBillingSection from '@/components/pages/admin/AdminCompany/sections/AdminCompanyBillingSection/AdminCompanyBillingSection.vue';
import adminCompanyService from '@/services/company/AdminCompanyService';
import { MessageBoxInputData } from 'element-ui/types/message-box';
import notificationService from '@/services/NotificationService';
import { ADMIN_COMPANIES, ADMIN_PANEL } from '@/routerNames';
import { AdminDashboardStatsData } from '@/@types/AdminDashboardStatsData';
import { User } from '@/models/User';
import { AnyJson } from '@/@types/AnyJson';
import { AdminCompanySection } from '@/@types/enums/AdminCompanySection';

@Component({
  computed: {
    AdminCompanySection() {
      return AdminCompanySection;
    },
  },
  components: {
    AdminCompanyBillingSection,
    AdminCompanyUsersSection,
    AdminCompanyGeneralSection,
    SwitchSectionButton,
  },
})
export default class AdminCompany extends VueApp {
  company: Company | null = null;
  companyStatsData: AdminDashboardStatsData | null = null;
  companyUsers: User[] | null = null;
  companyOwner: User | null = null;
  loader: any = null;
  activeSection: AdminCompanySection = AdminCompanySection.General;

  handleSwitchSection(section: AdminCompanySection) {
    this.activeSection = section;
  }

  handleDeleteCompany() {
    this.$prompt(
      'Are you sure that you want to delete company?. Please input your company name.',
      'Delete the company',
      {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
    ).then((data) => {
      const { value } = data as MessageBoxInputData;
      if (value === this.company.title) {
        adminCompanyService.deleteCompany(this.company, () => {
          return this.$router.push({ name: ADMIN_PANEL, params: { page: ADMIN_COMPANIES } });
        });
      } else {
        notificationService.error("Input value doesn't match the company name");
      }
    });
  }

  handleSwitchActiveStatusCompany() {
    adminCompanyService.changeCompanyStatus(this.company, this.load);
  }

  handleBackToCompanies() {
    return this.$router.push({ name: ADMIN_PANEL, params: { page: ADMIN_COMPANIES } });
  }

  async load() {
    this.loader = this.$loading({ lock: true });
    try {
      const routeCompanyId = this.$route.params.id;
      const res = await this.$api.admin.getCompany(routeCompanyId);

      this.company = Company.parse(res.data.company);

      if (this.company) {
        await this.loadCompanyStats();
        await this.loadCompanyOwner();
        await this.loadCompanyUsers();
      }
    } catch (error) {
      this.$api.handleResponseError(error);
    } finally {
      if (this.loader) {
        this.loader.close();
      }
    }
  }

  async loadCompanyStats() {
    try {
      const res = await this.$api.admin.getCompanyStats(this.company.id);
      this.companyStatsData = res.data;
    } catch (error) {
      this.$api.handleResponseError(error);
    }
  }

  async loadCompanyUsers() {
    try {
      const res = await this.$api.admin.getCompanyUsers(this.company.id);
      const users = res.data.users ? res.data.users.companyUsers.map((item: AnyJson) => User.parse(item)) : [];
      const invitedUsers = res.data.users.invitedUsers;
      this.companyUsers = [...users, ...invitedUsers];
    } catch (error) {
      this.$api.handleResponseError(error);
    }
  }

  async loadCompanyOwner() {
    try {
      const res = await this.$api.admin.getCompanyOwner(this.company.id);
      this.companyOwner = res.data.owner;
    } catch (error) {
      this.$api.handleResponseError(error);
    }
  }

  created() {
    this.load();
  }
}
