
import { Component, Prop } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import './GroupProfilePhoto.scss';

@Component({})
export default class UserProfilePhoto extends VueApp {
  @Prop() imageSrc: string;
  @Prop() styleClass: string;
}
