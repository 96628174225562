
import VueApp from '@/@types/app/VueApp';
import FindUserGroupDialog from '@/components/layout/dialogs/FindUserGroupDialog.vue';
import GroupProfilePhoto from '@/components/layout/shared/ProfilePhoto/GroupProfilePhoto.vue';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';
import { COPY_LINK } from '@/events';
import helper from '@/helper';
import { Article } from '@/models/article/Article';
import apiService from '@/services/ApiService';
import sharedArticleService from '@/services/article/SharedArticleService';
import notificationService from '@/services/NotificationService';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: { GroupProfilePhoto, UserProfilePhoto, FindUserGroupDialog },
})
export default class ArticleShareSettings extends VueApp {
  @Prop(String) accessType: string;
  @Prop() article: Article;
  @Prop() visible: boolean;
  @Prop() isAllowEditAndDeleteArticle: boolean;

  sharedUsersGroups: any[] = [];

  get isSharedUsersGroups(): boolean {
    return !!this.sharedUsersGroups.length;
  }

  get sharedLink(): string {
    return this.article.shared ? this.getSharedUrl() : null;
  }

  get isShared(): boolean {
    return !!this.article.shared;
  }

  get isGuest(): boolean {
    return this.$auth.isGuest();
  }

  getSharedUrl() {
    if (!this.isShared) return;
    const baseUrl = location.href.replace(location.hash, '');
    return baseUrl + '#/b/shared/' + this.article.shared.token;
  }

  async handleRemoveEntityFromShared(entityId: string) {
    try {
      await sharedArticleService.handleRemoveEntityFromShared(this.article.id, entityId);
      this.sharedUsersGroups = this.sharedUsersGroups.filter((entity) => entity.id !== entityId);
    } catch {
      notificationService.error('Failed to remove shared user or group from article');
    }
  }

  async handleAddEntityToShared(entities: any[]) {
    try {
      if (!entities.length) return;
      await sharedArticleService.handleAddEntityToShared(this.article.id, entities);
      await this.loadArticleSharedEntities();
    } catch {
      notificationService.error('Failed to load shared users and groups for article');
    }
  }

  async loadArticleSharedEntities() {
    try {
      const sharedUsersGroups = await apiService.sharedArticle.getArticleSharedEntities(this.article.id);
      if (sharedUsersGroups.data) {
        this.sharedUsersGroups = sharedUsersGroups.data;
      }
    } catch {
      notificationService.error('Failed to load shared users and groups for article');
    }
  }

  handleCopyUrl(canCopy = false) {
    if (this.isAllowEditAndDeleteArticle && !canCopy) {
      return;
    } else if (this.isShared) {
      helper.copyToClipboard(this.getSharedUrl());
      notificationService.success('Link to shared article copied to clipboard');
    } else {
      notificationService.warning('Articles is not shared');
    }
  }

  handleCopyLink() {
    this.$emit(COPY_LINK);
  }

  onToggleSharing(isRefreshToken = false) {
    if (isRefreshToken && !this.isShared) {
      return;
    }
    sharedArticleService.handleToggleSharedStatus(this.article.id, this.isShared, isRefreshToken);
  }

  mounted() {
    if (!this.isGuest && this.isAllowEditAndDeleteArticle) this.loadArticleSharedEntities();
  }
}
