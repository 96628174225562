
import '@/components/article-editor/EditorNavbar/AiFeaturesDropdownButton/AiFeaturesDropdownButton.scss';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { SocketActionType } from '@/@types/enums/SocketActionType';
import { SocketMessageType } from '@/@types/enums/SocketMessageType';

// Other
import { SmartContentType } from '@/@types/SmartContentType';

// Components
import SmartContentLoader from '@/components/article-editor/EditorNavbar/SmartContentLoader/SmartContentLoader.vue';
import EventBus from '@/EventBus';
import { ARTICLE_PLAYER_CHANGE_STATE } from '@/events';
import helper from '@/helper';

// Models
import { Article } from '@/models/article/Article';
import { Paragraph } from '@/models/article/Paragraph';
import { ArticlePlayerStates } from '@/models/ArticlePlayerStates';
import logger, { Logger } from '@/other/Logger';
import * as routerNames from '@/routerNames';
import { ARTICLE_EDIT, DRAFT_EDIT } from '@/routerNames';
import apiService from '@/services/ApiService';
import articleEditService from '@/services/article/ArticleEditService';

// Services
import mobileResponsiveService from '@/services/MobileResponsiveService';
import notificationService from '@/services/NotificationService';
import socketService from '@/services/socket/SocketService';
import articlePlayerProvider from '@/services/ui-providers/ArticlePlayerProvider';
import store from '@/store';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';

// Commits and dispatchers
import { dispatchEnableOcrMode, dispatchGetDraftsCount } from '@/store/dispatchers/uiDispatchers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

const promptTypes = [
  { value: 'meeting-notes', label: 'Meeting Notes' },
  { value: 'user-manual', label: 'User Manual' },
  { value: 'policy', label: 'Policy' },
  { value: 'status-report', label: 'Status Report' },
  { value: 'technical-documentation', label: 'Technical Documentation' },
  { value: 'requirements-documentation', label: 'Requirements Documentation' },
];

const log = new Logger('AiFeaturesDropdownButton');

@Component({
  computed: {
    SmartContentType() {
      return SmartContentType;
    },
  },
  components: { SmartContentLoader },
})
export default class AiFeaturesDropdownButton extends Vue {
  @Prop({ required: false, default: false }) isMenuItem: boolean;
  @Prop({ required: false, default: false }) isArticleEditPage: boolean;
  @Prop({ required: false, default: false }) isAllowEditAndDeleteArticle: boolean;
  @Prop({ required: true, default: false }) article: Article;

  isSmartNotesOpen = false;
  notesPrompt = '';
  noteTypes = promptTypes;
  isAiParagraphsLoading = false;
  isAiSmartNotesLoading = false;

  aiParagraphsProgress = 0;
  aiSmartNotesProgress = 0;

  get isArticleCreationPage() {
    return this.$route.name === routerNames.ARTICLE_CREATE;
  }

  get articleEditor() {
    return articleEditService.articleEditor;
  }

  get isMobile() {
    return mobileResponsiveService.isMobileView;
  }

  handleClickRecognizeText() {
    dispatchEnableOcrMode();
  }

  handleSubmitGenerateSmartNotes(e: HTMLFormElement) {
    e.preventDefault();
    return this.handleGenerateSmartNotes();
  }

  async handleGenerateSmartNotes(predefinedPrompt?: string, resume = false) {
    this.aiSmartNotesProgress = 0;

    const articleId = this.article.id;

    await dispatchGetDraftsCount();

    this.isAiSmartNotesLoading = true;

    try {
      const data = {
        articleId,
        predefinedPrompt,
        type: resume ? SocketMessageType.RECONNECT : SocketMessageType.START,
        prompt: !predefinedPrompt ? this.notesPrompt : undefined,
      };

      const { textTabs, draftId } = await socketService.article.ai.generateAiSmartNotes(data, (statusData) => {
        this.aiSmartNotesProgress = statusData.status.progress;
      });

      if (articleId !== this.articleEditor.article.id || !textTabs) return;

      this.notesPrompt = '';

      if (!this.isArticleCreationPage) {
        await this.$router.push({ name: DRAFT_EDIT, params: { mode: ArticleEditMode.NOTES, id: draftId } });
      }

      store.state.articleEditor.article.textTabs = textTabs;

      commitSetArticleMode(ArticleEditMode.NOTES);

      if (textTabs?.length > 1) {
        await dispatchUpdateSelectedTextTab(null);
      }
      await dispatchUpdateSelectedTextTab(textTabs[0]);

      commitSetArticleMode(ArticleEditMode.NOTES);
    } catch (err: any) {
      switch (err?.type) {
        case 'success':
          return;
        case 'warning':
          return notificationService.warning(err.message);
        case 'error': {
          logger.error(err);
          return notificationService.error(err.message);
        }
      }
      log.error(JSON.stringify(err));
    } finally {
      this.aiSmartNotesProgress = 0;

      this.isAiSmartNotesLoading = false;
    }
  }

  async handleGenerateAiParagraphs(resume = false) {
    this.aiParagraphsProgress = 0;

    const articleId = this.article.id;

    await dispatchGetDraftsCount();

    this.isAiParagraphsLoading = true;

    try {
      const res = await socketService.article.ai.generateAiParagraphs(
        articleId,
        resume ? SocketMessageType.RECONNECT : SocketMessageType.START,
        (statusData) => {
          const progress = statusData.status.progress;
          EventBus.$emit('smart-paragraphs-status-update-sidebar', {
            isLoading: true,
            progress,
          });
          this.aiParagraphsProgress = progress;
        }
      );

      if (articleId !== this.articleEditor.article.id) return;

      const paragraphs = JSON.parse(res);

      if (!paragraphs.length) {
        notificationService.warning('Oops! We were’t able to generate paragraphs for this recording.');
        return;
      }

      const parsedParagraphs: Paragraph[] = [];

      // Parsing each paragraph
      for (const paragraph of paragraphs) {
        parsedParagraphs.push(Paragraph.parse(paragraph));
      }

      this.articleEditor.article.paragraphs = parsedParagraphs;

      await dispatchUpdateSelectedTextTab(null);

      switch (this.$route.name) {
        case DRAFT_EDIT: {
          await this.$router.push({ name: DRAFT_EDIT, params: { mode: ArticleEditMode.VIDEO } });
          break;
        }
        case ARTICLE_EDIT: {
          await this.$router.push({ name: ARTICLE_EDIT, params: { mode: ArticleEditMode.VIDEO } });
          break;
        }
      }

      commitSetArticleMode(ArticleEditMode.VIDEO);

      helper.observerNotify(this.articleEditor.article.paragraphs);
      window.setTimeout(() => {
        articlePlayerProvider.videoPlayer.updatePlayerRef();
        articlePlayerProvider.videoPlayer.update();
        EventBus.$emit(ARTICLE_PLAYER_CHANGE_STATE, ArticlePlayerStates.paused);
      }, 0);
    } catch (err: any) {
      switch (err?.type) {
        case 'success':
          return;
        case 'warning':
          return notificationService.warning(err.message);
        case 'error': {
          logger.error(err);
          return notificationService.error(err.message);
        }
      }
    } finally {
      this.isAiParagraphsLoading = false;
      this.aiParagraphsProgress = 0;

      EventBus.$emit('smart-paragraphs-status-update-sidebar', {
        isLoading: false,
        progress: 0,
      });
    }
  }

  handleCancelAiGeneration(type: SmartContentType, emit = true) {
    switch (type) {
      case SmartContentType.notes:
        this.isAiSmartNotesLoading = false;

        socketService.article.ai.stopAiSmartNotesGeneration(this.article.id);
        break;
      case SmartContentType.paragraphs:
        this.isAiParagraphsLoading = false;

        if (emit) {
          EventBus.$emit('smart-paragraphs-status-update-sidebar', {
            isLoading: false,
            progress: 0,
            canceled: true,
          });
        }
        socketService.article.ai.stopAiParagraphsGeneration(this.article.id);
        break;
    }
  }

  private async checkArticleStatusAndReconnection() {
    const [paragraphsRes] = await Promise.all([
      apiService.socket.getArticleStatus(this.article.id, SocketActionType.AI_PARAGRAPHS_GENERATION),
    ]);

    const isParagraphsGenerating = paragraphsRes.data.status === 'success';

    if (!this.isAiParagraphsLoading && isParagraphsGenerating) {
      this.handleGenerateAiParagraphs(true);
      this.isAiParagraphsLoading = false;
    }
  }

  private async checkSmartNotesStatusAndReconnect() {
    const smartNotesRes = await apiService.socket.getArticleStatus(
      this.article.id,
      SocketActionType.AI_NOTES_GENERATION
    );
    const isSmartNotesGenerating = smartNotesRes.data.status === 'success';

    if (!this.isAiSmartNotesLoading && isSmartNotesGenerating) {
      this.handleGenerateSmartNotes(undefined, true);
    }
  }

  async onMainMenuOpen() {
    // Fix for transition element re-rendering
    if (this.isSmartNotesOpen) {
      this.isSmartNotesOpen = false;
      this.isSmartNotesOpen = true;
    }

    if (this.isArticleEditPage) {
      await this.checkArticleStatusAndReconnection();
    }

    if (this.isAllowEditAndDeleteArticle) {
      await this.checkSmartNotesStatusAndReconnect();
    }
  }

  created() {
    EventBus.$on(
      'smart-paragraphs-status-update-menu',
      (statusData: { isLoading: boolean; progress: number; canceled?: boolean }) => {
        if (statusData.canceled) return this.handleCancelAiGeneration(SmartContentType.paragraphs, false);
        this.aiParagraphsProgress = statusData.progress;
        this.isAiParagraphsLoading = statusData.isLoading;
      }
    );
  }
}
