import { Image as TiptapImage } from '@tiptap/extension-image';
import { DEFAULT_IMAGE_WIDTH, DEFAULT_IMAGE_DISPLAY, DEFAULT_IMAGE_URL_REGEX } from '../constants';
import ImageView from '@/components/tiptap/views/ImageView.vue';
import { VueNodeViewRenderer } from '@tiptap/vue-2';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    image: {
      setImage: (options: {
        src: string;
        alt?: string;
        title?: string;
        width?: number;
        display?: string;
      }) => ReturnType;
    };
  }
}

export interface AdditionalImageOptions {
  inline: boolean | string;
  allowBase64: boolean;
  HTMLAttributes: Record<string, any>;
  defaultWidth: number;
  defaultDisplay: string;
  urlPattern: RegExp;
}

const Image = TiptapImage.extend<AdditionalImageOptions>({
  draggable: true,

  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {},
      defaultWidth: DEFAULT_IMAGE_WIDTH,
      defaultDisplay: DEFAULT_IMAGE_DISPLAY,
      urlPattern: DEFAULT_IMAGE_URL_REGEX,
    };
  },

  parseHTML() {
    return [{ tag: 'img[src]' }];
  },

  addAttributes() {
    return {
      src: { default: '' },
      alt: { default: '' },
      title: { default: '' },
      width: { default: this.options.defaultWidth > 0 ? this.options.defaultWidth : DEFAULT_IMAGE_WIDTH },
      height: { default: null },
      display: {
        default: /(inline|block|left|right)/.test(this.options.defaultDisplay)
          ? this.options.defaultDisplay
          : DEFAULT_IMAGE_DISPLAY,
      },
    };
  },

  inline() {
    return true;
  },
  group() {
    return 'inline';
  },
  addNodeView() {
    return VueNodeViewRenderer(ImageView);
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
});

export default Image;
