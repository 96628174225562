import { ImageDisplay } from './utils/image';

export const DEFAULT_IMAGE_URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const DEFAULT_IMAGE_WIDTH = 200;
export const DEFAULT_IMAGE_DISPLAY = ImageDisplay.INLINE;

export enum FontFamily {
  Arial = 'Arial',
  OpenSans = '"Open Sans"',
  Lato = 'Lato',
  Roboto = 'Roboto',
  RobotoCondensed = '"Roboto Condensed"',
  PTSans = 'PT Sans',
  Verdana = 'Verdana',
  TimesNewRoman = 'Times New Roman',
  Montserrat = 'Montserrat',
  Oswald = 'Oswald',
  Raleway = 'Raleway',
}

export const FONTS_LIST = [
  { title: 'Arial', value: FontFamily.Arial },
  { title: 'Lato', value: FontFamily.Lato },
  { title: 'Roboto', value: FontFamily.Roboto },
  { title: 'Roboto Condensed', value: FontFamily.RobotoCondensed },
  { title: 'PT Sans', value: FontFamily.PTSans },
  { title: 'Verdana', value: FontFamily.Verdana },
  { title: 'Times New Roman', value: FontFamily.TimesNewRoman },
  { title: 'Montserrat', value: FontFamily.Montserrat },
  { title: 'Open Sans', value: FontFamily.OpenSans },
  { title: 'Oswald', value: FontFamily.Oswald },
  { title: 'Raleway', value: FontFamily.Raleway },
];

export const FONT_SIZES = [10, 12, 14, 16, 18];
export const DEFAULT_FONT_SIZE = 14;
