
import VueApp from '@/@types/app/VueApp';
import { Component } from 'vue-property-decorator';

@Component
export default class ArticleTagsButton extends VueApp {
  handleClick() {
    this.$emit('click');
  }
}
