
import { Component } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import FindUserDialog from '@/components/layout/dialogs/FindUserDialog.vue';
import { User } from '@/models/User';
import EditableInput from '@/components/layout/inputs/EditableInput.vue';
import GroupUserManagementDialog from '@/components/layout/dialogs/GroupUserManagementDialog.vue';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';

@Component({
  components: {
    FindUserDialog,
    EditableInput,
    GroupUserManagementDialog,
    UserProfilePhoto,
  },
})
export default class CreateGroupDialog extends VueApp {
  showDialog = false;
  groupTitle = '';
  userList: User[] = [];
  mobileWidth = 599;

  show() {
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;
    this.groupTitle = null;
    this.userList = [];
    this.$store.commit('clearUserList');
  }

  createGroup(groupName: string) {
    this.$emit('create-group', groupName, this.userList);
    this.closeDialog();
  }

  checkIsFieldEmpty() {
    return !this.groupTitle;
  }

  handleAddUserIntoList(users: string[]) {
    users.map((value: string) => {
      const user = User.parse(JSON.parse(value));
      this.userList.push(user);
    });
    this.$store.commit('setUsers', this.userList);
  }

  handleRemoveUserFromNewGroup(user: User) {
    this.$store.commit('removeUser', user);
    this.userList = this.userList.filter((userObject) => userObject.id !== user.id);
  }

  get isMobileView() {
    return window.innerWidth <= this.mobileWidth;
  }
}
