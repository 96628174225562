import { State } from '@/store/state';
import { Subtitle } from '@/@types/Subtitle';

export const transcriptionSidebarMutations = {
  transcriptionSidebarSetIsVisible(state: State, isVisible: boolean) {
    state.transcriptionSidebar.isVisible = isVisible;
  },

  transcriptionSidebarSetTranscription(state: State, subtitles: Subtitle[]) {
    state.transcriptionSidebar.transcription = subtitles;
  },
};
