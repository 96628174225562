
import MenuRedoButton from '@/components/tiptap/Menu/MenuItems/History/MenuRedoButton.vue';
import MenuUndoButton from '@/components/tiptap/Menu/MenuItems/History/MenuUndoButton.vue';
import MenuBlockquoteButton from '@/components/tiptap/Menu/MenuItems/MenuBlockquoteButton.vue';
import MenuCodeBlockButton from '@/components/tiptap/Menu/MenuItems/MenuCodeBlockButton.vue';
import MenuHorizontalRuleButton from '@/components/tiptap/Menu/MenuItems/MenuHorizontalRuleButton.vue';
import MenuRemoveFormatButton from '@/components/tiptap/Menu/MenuItems/MenuRemoveFormatButton.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Popover } from 'element-ui';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';

@Component({
  components: {
    MenuUndoButton,
    MenuRedoButton,
    MenuCodeBlockButton,
    MenuRemoveFormatButton,
    MenuHorizontalRuleButton,
    MenuBlockquoteButton,
    MenuButton,
    [Popover.name]: Popover,
  },
})
export default class MenuMorePopover extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  private popoverVisible = false;

  hidePopover(): void {
    this.popoverVisible = false;
  }
}
