<template>
  <div class="container"></div>
</template>

<script>
/* tslint:disable */
import { LOGIN } from '@/routerNames';
import notificationService from '@/services/NotificationService';

export default {
  name: 'LoginSocialFailPage',
  props: ['message'],
  created() {
    notificationService.error(this.message);
    this.$router.push({ name: LOGIN });
  },
};
</script>
