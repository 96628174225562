/* tslint:disable:max-classes-per-file */
import { Range } from './Range';
import { Video } from '../Video';
import { VideoState } from './VideoState';
import { Paragraph } from './Paragraph';

export class PlayMapItem {
  static parse(item: any): PlayMapItem {
    return new PlayMapItem(item.totalTime, item.videoTime, item.videoId);
  }

  totalTime: number;
  videoTime: number;
  videoId: string;

  constructor(totalTime: number, videoTime: number, videoId: string) {
    this.totalTime = Math.round(totalTime);
    this.videoTime = Math.round(videoTime);
    this.videoId = videoId;
  }

  copy(): PlayMapItem {
    return new PlayMapItem(this.totalTime, this.videoTime, this.videoId);
  }
}

export class TrimData {
  playMap: PlayMap;
  duration: number;
  ranges: Range[];

  constructor(playMap: PlayMap, duration: number, ranges: Range[]) {
    this.playMap = playMap;
    this.duration = duration;
    this.ranges = ranges;
  }
}

export class PlayMap {
  static trimVideo(video: any, trim: Range[]): TrimData {
    const pm = new PlayMap([], [video]);
    let duration = 0;

    const ranges = new Range(0, video.duration).cut(trim);

    if (ranges.length) {
      let lastEnd = 0;
      ranges.forEach((range: Range) => {
        pm.data.push(new PlayMapItem(lastEnd, range.start, video.id));
        lastEnd = range.end;
        duration += range.length();
      });
    }

    return new TrimData(pm, duration, ranges);
  }

  static shiftTrimmedKeyframes(keyframes: number[], trimData: TrimData) {
    if (trimData.ranges.length) {
      let last = new Range(0, 0);

      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < trimData.ranges.length; index++) {
        const item = trimData.ranges[index];
        const diff = item.start - last.end;

        // shift keyframes
        if (diff > 0) {
          for (const key in keyframes) {
            if (keyframes[key]) {
              const k = keyframes[key];

              if (k > last.end) {
                // shift keyframe
                keyframes[key] -= Math.round(Math.min(diff, k - last.end));
              }
            }
          }
        }

        last = item;
      }
    }

    return keyframes;
  }

  static fromParagraph(p: Paragraph): PlayMap {
    return new PlayMap(p.pm, p.videos);
  }

  data: any[];
  videos: any[];

  constructor(data: any[], videos: Video[]) {
    this.data = data || [];
    this.videos = videos || [];
  }

  replaceRange(range: Range, video: Video) {
    const diff = video.duration - (range.end - range.start);

    this.split(range.end);
    this.shift(range.end, diff);

    // add video to proper position
    let insertPosition = this.data.length - 1;

    if (this.data.length > 0) {
      for (let index = this.data.length - 1; index >= 0; index--) {
        const item = PlayMapItem.parse(this.data[index]);

        if (item.totalTime <= range.start) {
          insertPosition = index;
          break;
        }
      }
    }
    insertPosition++;

    this.data.splice(insertPosition, 0, new PlayMapItem(range.start, 0, video.id));
    this.normalize();
    this.videos.push(video);
  }

  getVideoDuration(item: { videoId: string }): number {
    let duration = 0;

    if (this.videos.length) {
      for (const video of this.videos) {
        if (video.id === item.videoId) {
          duration = video.duration;
          break;
        }
      }
    }

    return duration;
  }

  split(position: number) {
    const data = [];
    for (const item of this.data) {
      const videoDuration = this.getVideoDuration(item);
      const range = new Range(item.totalTime, item.totalTime + videoDuration);
      data.push(PlayMapItem.parse(item));
      if (range.contain(position)) {
        const b = PlayMapItem.parse(item);
        const diff = position - item.totalTime;

        b.totalTime = position;
        b.videoTime += diff;
        data.push(b);
      }
    }
    this.data = data;
    return this;
  }

  shift(position: number, delta: number) {
    for (const item of this.data) {
      if (item.totalTime >= position) {
        item.totalTime += delta;
      }
    }
    return this;
  }

  copy(range: Range) {
    const copy = [];
    if (this.data.length > 0) {
      for (let index = this.data.length - 1; index >= 0; index--) {
        const item = PlayMapItem.parse(this.data[index]);
        if (range.contain(item.totalTime)) {
          copy.push(PlayMapItem.parse(JSON.parse(JSON.stringify(item))));
        } else if (item.totalTime < range.start) {
          copy.push(new PlayMapItem(range.start, item.videoTime + (range.start - item.totalTime), item.videoId));
          break;
        }
      }
    }

    return new PlayMap(copy.reverse(), JSON.parse(JSON.stringify(this.videos))).shift(0, -range.start).normalize();
  }

  normalize() {
    const normalized: PlayMapItem[] = [];
    const usedVideos: string[] = [];
    let pos = -1;
    if (this.data.length > 0) {
      // cleanup parts
      for (let index = this.data.length - 1; index >= 0; index--) {
        const item = PlayMapItem.parse(this.data[index]);
        if (pos === -1 || pos > item.totalTime) {
          pos = item.totalTime;
          normalized.push(item);
          usedVideos.push(item.videoId);
        }
      }

      this.data = normalized.reverse();

      // stitch parts if the same
      // let removeIndexes =[];
      for (let index = this.data.length - 1; index > 0; index--) {
        const item = PlayMapItem.parse(this.data[index]);
        const state = this.getVideoState(item.totalTime);
        const prevState = this.getVideoState(item.totalTime - 1);

        if (prevState.src === state.src) {
          const diff1 = prevState.videoOffset - prevState.offset;
          const diff2 = state.videoOffset - state.offset;
          if (diff1 === diff2) {
            // videos and offset is the same. Remove current index
            this.data.splice(index, 1);
          }
        }
      }
    }

    // filter videos
    this.videos = this.videos.filter((item: Video) => {
      return usedVideos.indexOf(item.id + '') >= 0;
    });

    return this;
  }

  insertMultiple(data: any[], position: number) {
    const items = data.length ? data.map((item) => PlayMapItem.parse(item)) : [];
    let insertIndex = -1;
    this.data.forEach((item: any, index: number) => {
      if (item.totalTime <= position) {
        insertIndex = index;
      }
    });

    insertIndex++;

    items.forEach((item: PlayMapItem) => {
      item.totalTime += position;
      this.data.splice(insertIndex, 0, item);
      insertIndex++;
    });

    return this;
  }

  getVideoState(ms: number) {
    const state = new VideoState();
    if (this.data.length) {
      for (let i = this.data.length - 1; i >= 0; i--) {
        const item = this.data[i] as PlayMapItem;
        const video = this.getVideo(item.videoId);

        if (video) {
          const videoStartPos: number = item.totalTime;
          const videoInternalOffset: number = item.videoTime;
          const videoEndPos: number = videoStartPos + video.duration - videoInternalOffset;
          if (ms >= videoStartPos && (ms < videoEndPos || (i === this.data.length - 1 && ms <= videoEndPos))) {
            state.src = video.objectUrl ? video.objectUrl : video.url;
            state.offset = videoStartPos;
            state.videoOffset = videoInternalOffset;
            state.time = ms - videoStartPos + videoInternalOffset;
            state.duration = video.duration;
            break;
          }
        }
      }
    }

    return state;
  }

  findNextVideoPosition(ms: number): number {
    let position = -1;
    let last = null;
    if (this.data.length) {
      for (let i = this.data.length - 1; i >= 0; i--) {
        const item = this.data[i] as PlayMapItem;
        const video = this.getVideo(item.videoId);
        if (video) {
          const pos: number = item.totalTime;

          if (pos > ms && (last === null || pos < last)) {
            position = pos;
          }
          last = pos;
        }
      }
    }
    return position;
  }

  getVideo(vid: string): Video | null {
    const res = this.videos.filter((item) => item.id === vid);

    return res && res[0] ? res[0] : null;
  }

  addVideos(videos: any[]) {
    if (videos.length) {
      for (const video of videos) {
        const exists = !!this.getVideo(video.id);

        if (!exists) {
          this.videos.push(Video.fromJson(video));
        }
      }
    }

    return this;
  }
}
