export const COLOR_SET = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#000000',
];

const getRandomElement = <T>(list: T[]) => {
  return list[Math.floor(Math.random() * list.length)];
};

export const getRandomColor = () => {
  return getRandomElement([
    '#958DF1',
    '#f783ac',
    '#F98181',
    '#FBBC88',
    '#FAF594',
    '#ffc107',
    '#ff5722',
    '#70CFF8',
    '#94FADB',
    '#009688',
    '#B9F18D',
  ]);
};
