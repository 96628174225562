import appStore from '@/store';
import { Article } from '@/models/article/Article';

export const dispatchTransferArticle = (article: Article, companyId: string, workspaceId: string) => {
  return appStore.dispatch('transferArticle', { article, companyId, workspaceId });
};

export const dispatchDeleteArticle = (article: Article) => {
  return appStore.dispatch('deleteArticle', article);
};

export const dispatchUpdateArticleMode = (article: Article) => {
  return appStore.dispatch('updateArticleMode', article);
};
