
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TimeTag extends Vue {
  @Prop({ default: () => {} }) readonly command!: () => void;
  @Prop({ required: true }) time: string;

  get isActiveTimeTag(): boolean {
    return this.$store.state.commentsSidebar.isActiveTimeTag;
  }

  onClick() {
    if (this.command) {
      this.command();
    }
  }
}
