
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Popover } from 'element-ui';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { Editor } from '@tiptap/core';
import { ImageDisplay } from '@/components/tiptap/utils/image';

@Component({
  components: {
    MenuButton,
    [Popover.name]: Popover,
  },
})
export default class MenuImageDisplayButton extends Vue {
  @Prop({ required: true }) editor: Editor;

  popoverVisible = false;

  displayCollection = [ImageDisplay.INLINE, ImageDisplay.BREAK_TEXT, ImageDisplay.FLOAT_LEFT, ImageDisplay.FLOAT_RIGHT];
  displayTitles = {
    [ImageDisplay.INLINE]: 'Inline',
    [ImageDisplay.BREAK_TEXT]: 'Break text',
    [ImageDisplay.FLOAT_LEFT]: 'Float left',
    [ImageDisplay.FLOAT_RIGHT]: 'Float right',
  };

  private get currentDisplay() {
    const attributes = this.editor.getAttributes('image');
    return attributes.display || ImageDisplay.INLINE;
  }

  private hidePopover() {
    this.popoverVisible = false;
  }

  updateDisplay(display: string) {
    const attrs = this.editor.getAttributes('image') as { src: string };

    const options = { ...attrs, display };
    this.editor.commands.setImage(options);
  }
}
