
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { TipTapEditorContext } from '@/components/tiptap/types';

@Component({
  components: {
    MenuButton,
  },
})
export default class MenuTextAlignRightButton extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;
  @Prop({ default: () => {} }) command: () => void;

  get isActive() {
    return this.editorContext.state.isActiveTextAlignRight;
  }

  runCommand() {
    this.editor.chain().focus().setTextAlign('right').run();
    this.command();
  }
}
