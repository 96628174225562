import store from '@/store';
import { ArticleComment } from '@/models/article/ArticleComment';
import { ArticleCommentQuote } from '@/@types/ArticleCommentQuote';
import { ArticleCommentVideoReaction } from '@/@types/ArticleCommentVideoReaction';
import { RecordingReaction } from '@/@types/RecordingReaction';

export const commitSetCommentsSidebarIsVisible = (isVisible: boolean) => {
  store.commit('commentsSidebarSetIsVisible', isVisible);
};
export const commitSetCommentsSidebarQuote = (quote: ArticleCommentQuote) => {
  store.commit('commentsSidebarSetQuote', quote);
};
export const commitResetCommentsSidebarQuote = () => {
  store.commit('commentsSidebarSetQuote', undefined);
};
export const commitSetCommentsSidebarVideoReaction = (videoReaction: ArticleCommentVideoReaction) => {
  store.commit('commentsSidebarSetVideoReaction', videoReaction);
};
export const commitResetCommentsSidebarVideoReaction = () => {
  store.commit('commentsSidebarSetVideoReaction', undefined);
};
export const commitSetCommentsSidebarIsActiveTimeTag = (isActiveTimeTag: boolean) => {
  store.commit('commentsSidebarSetIsActiveTimeTag', isActiveTimeTag);
};
export const commitSetCommentsSidebarScrollToCommentId = (commentId: string = undefined) => {
  store.commit('commentsSidebarSetScrollToCommentId', commentId);
};
export const commitSetArticleRecordingReactions = (recordingReactions: RecordingReaction[]) => {
  store.commit('commentsSidebarSetRecordingReactions', recordingReactions);
};
export const commitAddArticleReaction = (reaction: RecordingReaction) => {
  store.commit('commentsSidebarAddRecordingReaction', reaction);
};
export const commitRemoveArticleReaction = (reactionId: string) => {
  store.commit('commentsSidebarRemoveRecordingReaction', reactionId);
};
export const commitSetArticleComments = (comments: ArticleComment[]) => {
  store.commit('commentsSidebarSetComments', comments);
};
export const commitCommentsSidebarAddComment = (comment: ArticleComment) => {
  store.commit('commentsSidebarAddComment', comment);
};
