import helper from '@/helper';
import * as routes from '@/routerNames';
import { Company } from '@/models/Company';
import apiService from '@/services/ApiService';
import navigationService from '@/services/NavigationService';
import { Mutations } from '@/store/mutations';
import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import { User } from '@/models/User';
import { commitSetCompanyUsers } from '@/store/commits/companyCommits';

export const companyActions = {
  async switchCompany({ commit }: ActionContext<State, {}>, companyId: string) {
    const res = await apiService.company.switch(companyId).catch((err) => {
      apiService.handleResponseError(err);
      return null;
    });
    const url = document.location.href;
    const draftId = helper.extractDraftId(url);
    const company = res && res.data && res.data.company ? Company.parse(res.data.company) : null;

    commit(Mutations.SET_COMPANY, company);
    commit('setArticleEditorEditDraftExtension', draftId);

    await navigationService.navigate({
      name: routes.LOGIN,
      params: { token: res.data.token },
    });
  },
  async loadCompanyByHostname({ commit }: ActionContext<State, {}>) {
    const url = document.location.href;
    const res = await apiService.getDomainCompany(helper.extractHostname(url)).catch((err) => {
      apiService.handleResponseError(err);
      return null;
    });

    const company = res?.data?.company ? Company.parse(res.data.company) : null;

    if (company) {
      commit(Mutations.SET_COMPANY, company);
    }
  },
  async loadCompanyUsers() {
    const response = await apiService.company.getUsers();

    if (response?.data?.users) {
      const users = response.data.users.map((user) => User.parse(user));
      commitSetCompanyUsers(users);
    }
  },
};
