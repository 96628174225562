import { Logger } from '@/other/Logger';
import { NavigationGuard } from 'vue-router';

import store from '@/store';
import api from '@/services/ApiService';
import auth from '@/services/AuthService';
import authService from '@/services/AuthService';
import { authRoutes } from '@/constants/RouterConsts';
import {
  HOME,
  LOGIN,
  BROWSE,
  SIGNUP,
  CHECKOUT,
  BROWSE_SHARED,
  PASSWORD_RESET,
  SIGNUP_INVITED,
  BROWSE_TEXT_TAB,
  BROWSE_PARAGRAPH,
  LOGIN_SOCIAL_FAIL,
  PASSWORD_RESET_TOKEN,
  LOGIN_SOCIAL_CALLBACK,
  BROWSE_SHARED_DEPRECATED,
} from '@/routerNames';

const publicRoutes = [
  LOGIN,
  SIGNUP,
  BROWSE,
  CHECKOUT,
  BROWSE_SHARED,
  PASSWORD_RESET,
  SIGNUP_INVITED,
  BROWSE_TEXT_TAB,
  BROWSE_PARAGRAPH,
  LOGIN_SOCIAL_FAIL,
  PASSWORD_RESET_TOKEN,
  LOGIN_SOCIAL_CALLBACK,
  BROWSE_SHARED_DEPRECATED,
];

const log = new Logger('AuthMiddleware');

const AuthMiddleware: NavigationGuard = async (to, from, next) => {
  if (authRoutes.indexOf(to.name) && to.params.token) {
    next();
    return;
  }

  if (!auth.user && api.authToken) {
    try {
      const user = await authService.loadUser();
      if (!user) {
        log.info('Failed to load user');
        authService.logout();
        next('/login');
      }
    } catch (e) {
      log.info('Failed to load user');
      api.authToken = null;
    }
  }

  if (!store.state.company || !auth.user) {
    store.commit('setCompanyLoading', false);
  }

  if (api.authToken && authRoutes.indexOf(to.name) >= 0) {
    next({ name: HOME });
    return;
  }

  if (publicRoutes.indexOf(to.name) >= 0) {
    next();
  } else {
    // check if user authorized
    if (api.authToken) {
      next();
    } else {
      next('/login');
    }
  }
};

export default AuthMiddleware;
