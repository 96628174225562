export enum SearchCategory {
  NONE = 'none',
  ALL = 'all',
  WORKSPACE = 'workspace',
  ARTICLE = 'article',
  DRAFT = 'draft',
  PARAGRAPH = 'paragraph',
  TAG = 'tag',
  DRAFT_NOTES = 'draftNotes',
  ARTICLE_NOTES = 'articleNotes',
  ARTICLE_VIDEO = 'articleVideo',
  DRAFT_VIDEO = 'draftVideo',
}
