
import VueApp from '@/@types/app/VueApp';
import { HOME } from '@/routerNames';
import apiService from '@/services/ApiService';
import notificationService from '@/services/NotificationService';
import { Company } from '@/models/Company';
import { Component } from 'vue-property-decorator';
import { ElLoadingComponent } from 'element-ui/types/loading';
import CompanyLogoInput from '@/components/layout/inputs/CompanyLogoInput/CompanyLogoInput.vue';
import ProfilePhotoInput from '@/components/layout/inputs/ProfilePhotoInput/ProfilePhotoInput.vue';
import { commitSetCompanyLogo } from '@/store/commits/companyCommits';
import { Logger } from '@/other/Logger';

const log = new Logger('CompanyPage');
@Component({
  components: { ProfilePhotoInput, CompanyLogoInput },
})
export default class CompanyPage extends VueApp {
  loader: ElLoadingComponent | null = null;
  company: Company | null = null;

  companyFormData: {
    logo: File | null;
    isDeleted: boolean;
  } = { logo: null, isDeleted: false };

  get companyLogo() {
    return this.$store.state.company.logo;
  }

  async compressImage(file: File, maxSizeKB: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Log the original size before compression
          log.info(`Original image size: ${Math.round(file.size / 1024)}KB`);

          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Maintain aspect ratio while scaling down if needed
          const MAX_WIDTH = 1200;
          if (width > MAX_WIDTH) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          }

          canvas.width = width;
          canvas.height = height;

          // Draw image on canvas
          const ctx = canvas.getContext('2d', { willReadFrequently: true });
          if (!ctx) {
            reject(new Error('Could not get canvas context'));
            return;
          }

          // Clear the canvas with transparent background
          ctx.clearRect(0, 0, width, height);
          ctx.drawImage(img, 0, 0, width, height);

          // Check if image has transparency
          const imageData = ctx.getImageData(0, 0, width, height);
          const hasTransparency = this.hasTransparentPixels(imageData);

          // Try to find the right quality level
          const tryCompress = () => {
            let quality = 0.9;
            let compressedFile: File | null = null;

            while (quality >= 0.1) {
              // Use PNG for transparent images, JPEG for non-transparent
              const mimeType = hasTransparency ? 'image/png' : 'image/jpeg';
              const dataUrl = canvas.toDataURL(mimeType, quality);
              const base64 = dataUrl.split(',')[1];
              const byteSize = Math.round((base64.length * 3) / 4);

              // Convert base64 to Blob
              const byteCharacters = atob(base64);
              const byteArrays = [];
              for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays.push(byteCharacters.charCodeAt(i));
              }
              const blob = new Blob([new Uint8Array(byteArrays)], { type: mimeType });

              // Create new file from blob
              compressedFile = new File([blob], file.name, {
                type: mimeType,
                lastModified: Date.now(),
              });

              if (byteSize <= maxSizeKB * 1024) {
                break;
              }

              quality -= 0.1;
            }

            if (compressedFile) {
              log.info(`Compressed image size: ${Math.round(compressedFile.size / 1024)}KB`);
              resolve(compressedFile);
            } else {
              reject(new Error('Could not compress image to target size'));
            }
          };

          tryCompress();
        };
        img.onerror = reject;
        img.src = e.target?.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  // Helper function to check if image has transparent pixels
  hasTransparentPixels(imageData: ImageData): boolean {
    const data = imageData.data;
    for (let i = 3; i < data.length; i += 4) {
      if (data[i] < 255) {
        return true;
      }
    }
    return false;
  }

  async setCompanyLogo(photo: File): Promise<void> {
    if (!photo) return;

    // Check if file is an image
    if (!photo.type.startsWith('image/')) {
      throw new Error('File must be an image');
    }

    try {
      // Compress image to max 50KB
      const compressedPhoto = await this.compressImage(photo, 50);
      this.companyFormData.logo = compressedPhoto;
      this.companyFormData.isDeleted = false;

      // Log the final size
      log.info(`Compressed image size: ${Math.round(compressedPhoto.size / 1024)}KB`);
    } catch (error) {
      log.info('Error compressing image');
      throw error;
    }
  }

  deleteCompanyLogo() {
    this.companyFormData.logo = null;
    this.companyFormData.isDeleted = true;
  }

  async getNewLogoUrl(logo: File): Promise<string> {
    try {
      const response = await apiService.image.upload(logo);
      return response.data.url;
    } catch (error) {
      log.error('Error uploading new logo');
      throw error;
    }
  }

  async save() {
    this.loader = this.$loading({});
    const { logo, isDeleted } = this.companyFormData;
    let resLogo = !isDeleted ? (!logo ? this.companyLogo : await this.getNewLogoUrl(logo)) : null;

    return this.$api
      .updateCompany({
        title: this.company.title,
        logo: resLogo,
      })
      .then(() => {
        commitSetCompanyLogo(resLogo);
        notificationService.success('Company successfully updated');
      })
      .catch((res) => this.$api.handleResponseError(res))
      .finally(() => {
        this.loader.close();
        this.loader = null;
      });
  }

  async resetCompanySettings() {
    this.companyFormData.logo = null;
    this.companyFormData.isDeleted = true;
    await this.save();
  }

  async load() {
    this.loader = this.$loading({});

    try {
      const isUserActive = await this.$auth.isUserActive();
      if (!isUserActive) return this.$router.push({ name: HOME });

      this.company = await this.$api.company.get().then((res) => {
        return Company.parse(res.data.company);
      });
    } catch (e) {
      apiService.handleResponseError(e);
    } finally {
      this.loader.close();
      this.loader = null;
    }
  }

  async created() {
    await this.load();
  }
}
