import { CommandProps } from '@tiptap/core';
import TipTapHeading, { Level } from '@tiptap/extension-heading';
import { TextSelection } from 'prosemirror-state';
import { Node, Mark } from 'prosemirror-model';
import uniqKeyAttributeParse from '@/components/tiptap/extensions/UniqKey/uniqKeyAttributeParse';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    headingExtended: {
      setHeadingAndClearMarks: (attributes: { level: Level }) => ReturnType;
      removeMarksExceptFontSize: () => ReturnType;
    };
  }
}

export const Heading = TipTapHeading.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : {}),
      key: {
        default: null,
        parseHTML: uniqKeyAttributeParse,
        renderHTML: (attributes) => ({ key: attributes.key }),
        keepOnSplit: false,
      },
    };
  },
  addCommands() {
    return {
      ...this.parent(),
      removeMarksExceptFontSize:
        () =>
        ({ state, tr, dispatch }: CommandProps) => {
          const doc = state.doc;
          const textStyleMark = state.schema.marks.textStyle;
          const selection = state.selection;
          const parent = selection.$from.parent;
          const parentPos = selection.$from.pos - selection.$from.parentOffset;
          const parentSelection = new TextSelection(
            doc.resolve(parentPos),
            doc.resolve(parentPos + parent.nodeSize - 1)
          );
          const marks: { from: number; to: number; mark: Mark }[] = [];

          parent.descendants((node: Node, pos: number) => {
            node.marks
              .filter((mark: Mark) => mark.type === textStyleMark && mark.attrs.fontFamily)
              .forEach((mark: Mark) => {
                marks.push({
                  from: pos,
                  to: node.nodeSize + pos,
                  mark: state.schema.marks.textStyle.create({ fontFamily: mark.attrs.fontFamily }),
                });
              });
          });

          parentSelection.ranges.forEach((range) => {
            tr.removeMark(range.$from.pos, range.$to.pos);
          });

          marks.forEach(({ from, to, mark }) => {
            tr.addMark(from + parentPos, to + parentPos, mark);
          });
          dispatch(tr);
          return true;
        },
      setHeadingAndClearMarks:
        (attributes) =>
        ({ chain }: CommandProps) => {
          return chain().removeMarksExceptFontSize().setHeading(attributes).run();
        },
    };
  },
});

export default Heading;
