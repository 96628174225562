import { Company } from '@/models/Company';
import { NavigationGuard } from 'vue-router';
import store from '@/store';

import { DOMAIN_BLACK_LIST } from '@/constants/DomainConsts';
import apiService from '@/services/ApiService';
import authService from '@/services/AuthService';
import { Logger } from '@/other/Logger';

const log = new Logger('SyncCompanyMiddleware');

const SyncCompanyMiddleware: NavigationGuard = async (to, from, next) => {
  const url = new URL(window.location.href);
  const currentSlug = url.hostname.split('.')[0];

  const company = store.state.company;

  if (DOMAIN_BLACK_LIST.includes(currentSlug) || !company?.domain) {
    next();
    return;
  }

  const user = authService.user;

  if (!company || !user) {
    log.info('No company in store, proceeding to next middleware');
    next();
    return;
  }

  const isCompaniesEqual = company.slug === currentSlug;
  if (isCompaniesEqual) {
    log.info('User company matches store company, proceeding');
    next();
    return;
  }

  let userHasCompany = false;
  let slugCompany = null;

  if (!isCompaniesEqual) {
    try {
      const res = await apiService.company.getBySlug(currentSlug);
      if (res.data.company) {
        userHasCompany = true;
        slugCompany = Company.parse(res.data.company);
      }
    } catch (e) {
      log.info('Failed to fetch company by slug');
    }
  }

  if (!isCompaniesEqual && userHasCompany) {
    log.info(`User has access to the company, switching company ID: ${slugCompany.id}`);
    const switchRes = await apiService.company.switch(slugCompany.id);
    apiService.setAuthToken(switchRes.data.token);
    log.info('Successfully switched company and reloaded user');
    window.location.reload();
    return;
  }

  log.info('Attempting to fetch company slug for redirection');
  try {
    if (company.slug) {
      const newHost = url.hostname.replace(currentSlug, company.slug);
      url.hostname = newHost;
      window.location.href = url.toString();
    }
  } catch (err: any) {
    log.error(`Error fetching company slug or updating URL ${JSON.stringify(err)}`);
  }

  next();
  return;
};

export default SyncCompanyMiddleware;
