import { State } from '@/store/state';
import { UserNotification } from '@/models/UserNotification';

export const notificationsSidebarMutations = {
  notificationsSidebarSetIsVisible(state: State, isVisible: boolean) {
    state.notificationsSidebar.isVisible = isVisible;
  },

  notificationsSidebarToggleVisible(state: State) {
    state.notificationsSidebar.isVisible = !state.notificationsSidebar.isVisible;
  },

  notificationsSidebarSetNotifications(state: State, notifications: UserNotification[]) {
    state.notificationsSidebar.notifications = notifications;
  },

  notificationsSidebarSetLastUpdateTime(state: State, time: number) {
    state.notificationsSidebar.lastUpdateTime = time;
  },
};
