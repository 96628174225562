
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import MenuRemoveImageButton from '@/components/tiptap/Menu/MenuItems/Image/MenuRemoveImageButton.vue';
import MenuEditImageButton from '@/components/tiptap/Menu/MenuItems/Image/MenuEditImageButton.vue';
import MenuImageDisplayButton from '@/components/tiptap/Menu/MenuItems/Image/MenuImageDisplayButton.vue';
import '@/components/tiptap/Menu/MenuBubble/MenuBubble.scss';

@Component({
  components: {
    MenuImageDisplayButton,
    MenuEditImageButton,
    MenuRemoveImageButton,
  },
})
export default class ImageBubbleMenu extends Vue {
  @Prop({ type: Editor, required: true })
  readonly editor!: Editor;
}
