
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserNotification } from '@/models/UserNotification';

@Component({})
export default class ArticlePublishNotificationContent extends Vue {
  @Prop() notification: UserNotification;

  get authorName() {
    return this.notification.getName();
  }

  get articleTitle() {
    return this.notification.payload.articleTitle;
  }
}
