
import { Component } from 'vue-property-decorator';
import EventBus from '@/EventBus';
import { Workspace } from '@/models/Workspace';
import { commitToggleWorkspacesSidebar } from '@/store/commits/uiCommits';
import { Mutations } from '@/store/mutations';
import VueApp from '@/@types/app/VueApp';
import { Logger } from '@/other/Logger';
import { WorkspaceVisibility } from '@/other/WorkspaceVisibility';
import handleOutsideClickDirective from '@/directives/handleOutsideClickDirective';
import urlService from '@/services/UrlService';
import { commitWorkspaceViewReset } from '@/store/commits/workspaceViewCommits';
import { commitSetPersonalWorkspace, commitSetWorkspaces } from '@/store/commits/workspaceCommit';
import { WorkspacesOrdering } from '@/@types/WorkspacesOrdering';
import store from '@/store';
import mobileResponsiveService from '@/services/MobileResponsiveService';

const log = new Logger('WorkspacesSidebar');

@Component({
  directives: {
    'click-outside': handleOutsideClickDirective(() => {
      if (store.state.ui.isWorkspacesSidebar) {
        store.commit('toggleWorkspacesSidebar', false);
      }
    }, ['workspaces-toggle', 'expand-button']),
  },
})
export default class WorkspacesSidebar extends VueApp {
  workspaces: Workspace[] = [];
  search = '';
  workspacesSidebarWidth = 345;
  workspacesSidebarMaxAllowedWidth = 345;

  get workspaceView() {
    return this.$store.state.workspaceView;
  }

  get isExtensionBannerVisible() {
    return this.$store.state.ui.isExtensionBannerVisible;
  }

  get personalWorkspaceId() {
    return this.$store.state.personalWorkspace.id;
  }

  get isMobile() {
    return mobileResponsiveService.isMobileView;
  }

  get filteredWorkspaces() {
    return this.workspaces.filter((workspace: Workspace) => {
      return (
        workspace.title.toLowerCase().match(this.search.toLocaleLowerCase()) &&
        workspace.id !== this.personalWorkspaceId &&
        !workspace.isArchived
      );
    });
  }

  getWorkspaceSidebarStyles() {
    return {
      width: this.workspacesSidebarWidth,
      top: !this.isMobile && this.isExtensionBannerVisible ? '100px' : '60px',
      left: this.$store.state.ui.isMainSidebarExpanded ? '170px' : '60px',
    };
  }

  getWorkspaceListStyle() {
    const heightList = this.isExtensionBannerVisible ? (this.isMobile ? 220 : 250) : 220;
    return {
      // Use small viewport units when in mobile to fix chrome footer overlapping
      height: `calc(100${this.isMobile ? 'svh' : 'vh'} - ${heightList}px)`,
    };
  }

  toggleSidebar() {
    commitToggleWorkspacesSidebar();
  }

  getStarIcon(workspace: Workspace) {
    return workspace.isFavorite ? 'star' : 'star_border';
  }

  getStarClass(workspace: Workspace): any {
    return {
      favorite: workspace.isFavorite,
    };
  }

  toggleFavoriteStatus(workspace: Workspace) {
    workspace.isFavorite = !workspace.isFavorite;
    const data = JSON.parse(JSON.stringify(workspace));
    this.$api.workspace.saveFavorite(data);
    this.workspaces = this.orderWorkspaces(this.workspaces);
  }

  filterOrderFavorite(workspaces: Workspace[]): Workspace[] {
    return workspaces
      .filter((item) => item.isFavorite)
      .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
  }

  handleShowWorkspaces() {
    this.toggleSidebar();

    if (this.isMobile) {
      this.$store.commit('toggleMainSidebar', false);
    }

    this.$router.push({ name: 'Workspaces' });
  }

  handleClickWorkspace(e: PointerEvent | MouseEvent, workspace: Workspace) {
    e.preventDefault();
    const isModifierPressed = e.metaKey || e.ctrlKey || e.button === 1;

    !isModifierPressed && this.toggleSidebar();
    this.openWorkspace(workspace, isModifierPressed);
  }

  openWorkspace(workspace: Workspace, newTab?: boolean) {
    const workspaceView = this.workspaceView;
    if (!workspaceView.workspace || (workspaceView.workspace.id !== workspace.id && !newTab)) {
      commitWorkspaceViewReset(); //clean workspace before load another
    }

    if (newTab) return window.open(urlService.getWorkspaceViewUrl(workspace), '_blank');

    this.$router.push(urlService.getWorkspaceViewRoute(workspace)).catch((e) => {
      log.error(e);
    });
  }

  createDefaultWorkspace() {
    const workspace = new Workspace();
    workspace.title = 'My Private Workspace';
    this.$api.workspace
      .save(workspace)
      .then((res) => {
        commitSetWorkspaces([res.data.workspace]);
        EventBus.$emit('workspace.' + (workspace.id ? 'update' : 'create'), workspace);
      })
      .catch((err) => this.$api.handleResponseError(err));
  }

  mounted() {
    this.$api
      .getWorkspaces()
      .then((res) => {
        const response = res.data.items;

        if (response.length === 0) {
          this.createDefaultWorkspace();
        } else {
          const workspaces = response.map((item: Workspace) => Workspace.parse(item));
          const personalWorkspace = workspaces.find(
            (workspace: Workspace) => workspace.visibility === WorkspaceVisibility.personal
          );
          const workspacesWithOrder = this.orderWorkspaces(workspaces);

          commitSetWorkspaces(workspacesWithOrder);
          commitSetPersonalWorkspace(personalWorkspace);
        }
      })
      .catch((data) => this.$api.handleResponseError(data));
  }

  created() {
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === Mutations.SET_WORKSPACES) {
        this.workspaces = this.orderWorkspaces(state.workspaces);
      }
    });

    EventBus.$on('side-bars-width-changed', (newWidth: number) => {
      if (newWidth >= this.workspacesSidebarMaxAllowedWidth) {
        this.workspacesSidebarWidth = newWidth;
      }
    });

    EventBus.$on('workspace-ordering-changed', (ordering: WorkspacesOrdering) => {
      this.workspaces = this.orderWorkspaces(this.workspaces, ordering);
    });
  }

  orderWorkspaces(workspaces: Workspace[], ordering: WorkspacesOrdering = null): any[] {
    if (!ordering) {
      ordering = JSON.parse(localStorage.getItem('workspacesOrdering')) as WorkspacesOrdering;
    }
    const userId = this.$auth && this.$auth.user && this.$auth.user.id;
    let newWPOrdering: any[] = [];
    if (ordering && ordering.length) {
      const containsCurrentUser = ordering.find((x: any) => x.userId === userId);

      if (containsCurrentUser) {
        const userOrderingIndex = ordering.indexOf(ordering.find((x: any) => x.userId === userId));
        const savedOrdering = ordering[userOrderingIndex];
        if (savedOrdering && savedOrdering.wp && savedOrdering.wp.length) {
          savedOrdering.wp.forEach((wpId: any) => {
            const wp = workspaces.find((x) => x.id == wpId);
            if (wp) {
              newWPOrdering.push(wp);
            }
          });
          workspaces.forEach((x) => {
            if (!newWPOrdering.includes(x)) {
              newWPOrdering.push(x);
            }
          });
        } else {
          newWPOrdering = workspaces;
        }
      } else {
        newWPOrdering = workspaces;
      }
    } else {
      newWPOrdering = workspaces;
    }
    const favoriteWorkspaces = this.filterOrderFavorite(newWPOrdering);
    const nonFavoriteWorkspaces = newWPOrdering.filter((item) => !item.isFavorite);

    return [...favoriteWorkspaces, ...nonFavoriteWorkspaces];
  }

  beforeDestroy() {
    EventBus.$off('side-bars-width-changed');
    EventBus.$off('workspace-ordering-changed');
  }
}
