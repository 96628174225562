import store from '@/store';
import { Workspace } from '@/models/Workspace';

export const commitSetWorkspaceIsArchived = (isArchived: boolean) => {
  store.commit('setWorkspaceIsArchived', isArchived);
};

export const commitSetWorkspaces = (workspaces: Workspace[]) => {
  store.commit('setWorkspaces', workspaces);
};

export const commitSetPersonalWorkspace = (workspace: Workspace) => {
  store.commit('setPersonalWorkspace', workspace);
};
