var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"header"}},[(
      (_vm.isCompanyPaid || _vm.user?.isSuperAdmin()) &&
      !_vm.isBillingPage &&
      _vm.$store.state.ui.isExtensionBannerVisible &&
      !_vm.isMobile
    )?_c('extension-banner'):_vm._e(),_c('md-toolbar',[_c('div',{staticClass:"header-wrapper"},[(_vm.isCompanyPaid && _vm.isMobile)?_c('div',{staticClass:"burger-menu",on:{"click":_vm.toggleMobileSidebar}},[(!_vm.$store.state.ui.isMainSidebar)?_c('span',{staticClass:"burger-menu-icon mobile-sidebar-trigger"}):_c('span',{staticClass:"close-round-icon fa-2x mobile-sidebar-trigger"})]):_vm._e(),_c('div',{class:_vm.isMobile
            ? 'md-layout md-layout-item md-alignment-center-space-around'
            : 'md-layout md-layout-item md-alignment-center-left md-small-size-65'},[_c('div',{staticClass:"header-logo"},[_c('router-link',{attrs:{"to":_vm.user ? '/' : '/login',"tag":"span"}},[_c('Logo',{attrs:{"company":_vm.company}})],1)],1)]),(!_vm.isGuest)?[_c('div',{staticClass:"header__right-section"},[(_vm.isCompanyPaid)?_c('div',{staticClass:"header-search-wrapper",class:_vm.hideLinks ? 'md-xlarge-size-100' : 'md-xlarge-size-50 md-large-size-40 md-medium-size-30'},[(!_vm.isHome)?_c('Search'):_vm._e()],1):_vm._e(),(_vm.isCompanyPaid)?_c('md-menu',{attrs:{"md-size":"medium","md-align-trigger":""}},[_c('md-button',{staticClass:"md-primary md-raised px-4 md-menu-button",attrs:{"md-menu-trigger":"","disabled":_vm.isCompanyDeactivated || _vm.isUserInactiveInCompany,"title":"Create"}},[_c('md-icon',{staticClass:"ml-0 mr-1"},[_vm._v("add")]),_c('span',{staticClass:"md-menu-button-text"},[_vm._v(" Create ")])],1),_c('md-menu-content',[_c('md-menu-item',{on:{"click":_vm.handleCreateArticle}},[_vm._v("Create an article")]),_c('md-menu-item',{on:{"click":_vm.handleAddWorkspace}},[_vm._v("Create a workspace")])],1)],1):_vm._e(),(!!_vm.company)?_c('UserMenu'):_vm._e(),(_vm.isCompanyPaid)?_c('notification-button'):_vm._e()],1),_c('div',{staticClass:"header__mobile-container"},[(_vm.isCompanyPaid)?_c('notification-button'):_vm._e(),(!!_vm.company)?_c('UserMenu'):_vm._e()],1)]:(_vm.isGuest && !_vm.isCompanyLoading)?_c('div',{staticClass:"md-layout-item md-layout md-gutter mx-0 md-alignment-center-space-between md-xlarge-size-85 md-large-size-80 md-small-size-20"},[_c('div',{staticClass:"md-layout md-layout-item md-alignment-space-between-right pr-0"},[_c('router-link',{staticClass:"header-login-link",attrs:{"to":'/login',"tag":"a"}},[_vm._v("Log In")])],1)]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }