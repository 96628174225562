
import '../../../styles/MenuCommentButton.scss';

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Tooltip } from 'element-ui';

@Component({
  components: {
    [Tooltip.name]: Tooltip,
  },
})
export default class MenuCommentButton extends Vue {
  @Prop({ required: true }) readonly icon!: string;

  @Prop({ default: false }) readonly isActive: boolean;

  @Prop({ default: false }) readonly isDisabled!: boolean;

  @Prop({ required: true }) readonly title!: string;

  @Prop({ default: () => {} }) readonly command!: () => void;

  get commandButtonClass(): object {
    return {
      'el-tiptap-editor__menu-button': true,
      'el-tiptap-editor__menu-button--active': this.isActive,
      'el-tiptap-editor__menu-button--readonly': this.isDisabled,
    };
  }

  onClick() {
    if (!this.isDisabled && this.command) this.command();
  }
}
