
import SidebarItem from '@/components/layout/sidebars/MainSidebar/SidebarItem.vue';
import { DRAFTS, HOME, MEETINGS, USER_PROFILE, WORKSPACES } from '@/routerNames';
import {
  dispatchGetDraftsCount,
  dispatchGetMeetingsCount,
  dispatchGetPersonalArticlesCount,
} from '@/store/dispatchers/uiDispatchers';
import { Component } from 'vue-property-decorator';
import EventBus from '@/EventBus';
import {
  commitToggleExpandMainSidebar,
  commitToggleMainSidebar,
  commitToggleWorkspacesSidebar,
} from '@/store/commits/uiCommits';
import VueApp from '@/@types/app/VueApp';
import { Logger } from '@/other/Logger';
import handleOutsideClickDirective from '@/directives/handleOutsideClickDirective';
import urlService from '@/services/UrlService';
import store from '@/store';
import Search from '@/components/layout/inputs/GlobalSearch.vue';
import UserMenu from '@/components/layout/header/UserMenu.vue';
import { User } from '@/models/User';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import articleEditService from '@/services/article/ArticleEditService';

const log = new Logger('MainSidebar');

@Component({
  methods: { commitToggleMainSidebar, commitToggleExpandMainSidebar },
  components: { SidebarItem, UserMenu, Search },
  directives: {
    'click-outside': handleOutsideClickDirective(
      () => {
        if (store.state.ui.isWorkspacesSidebar) {
          store.commit('toggleWorkspacesSidebar', false);
        } else if (store.state.isMobile && store.state.ui.isMainSidebar) {
          store.commit('toggleMainSidebar', false);
        }
      },
      ['workspaces-toggle, burger-menu', 'mobile-sidebar-trigger'],
      ['sidebar', 'workspaces-sidebar']
    ),
  },
})
export default class MainSidebar extends VueApp {
  mainSidebarWidth = 60;
  mainSidebarMaxAllowedWidth = 170;

  get sidebarItems() {
    return [
      {
        name: 'Workspaces',
        icon: 'W',
        condition: this.isWorkspacesSidebar || this.$route.name === WORKSPACES || this.$route.name === WORKSPACES,
        onClick: this.toggleSidebar,
      },
      {
        name: 'Personal',
        icon: 'P',
        count: this.personalArticlesCount,
        condition:
          this.isPersonalWorkspaceArticle || this.$route?.params?.workspaceId === this.personalWorkspaceShortId,
        onClick: this.openPersonalWorkspace,
      },
      {
        name: 'Meetings',
        icon: 'M',
        count: this.meetingsCount,
        condition: this.$route.name === MEETINGS,
        onClick: () => {
          this.navigate(MEETINGS);
          if (this.isMobile) commitToggleMainSidebar();
        },
      },
      {
        name: 'Drafts',
        icon: 'D',
        count: this.draftsCount,
        condition: this.$route.name === DRAFTS,
        onClick: () => this.navigate(DRAFTS),
      },
      {
        name: 'Settings',
        icon: 'S',
        condition: this.$route.path.includes('/settings'),
        onClick: () => this.navigate(USER_PROFILE),
      },
      {
        name: 'Search',
        icon: '',
        iconClass: 'search-icon',
        isMobileOnly: true,
        isMobile: this.isMobile,
        condition: false,
        persistentIcon: true,
        onClick: () => this.navigate(HOME),
      },
    ];
  }

  isActive(item: any) {
    return item.condition;
  }

  get isMainSidebarExpanded(): boolean {
    return this.$store.state.ui.isMainSidebarExpanded;
  }

  get isMainSidebar(): boolean {
    return this.$store.state.ui.isMainSidebar;
  }

  get user(): User {
    return this.$store.state.user;
  }

  get draftsCount(): number {
    return this.$store.state.ui.draftsCount;
  }

  get meetingsCount(): number {
    return this.$store.state.ui.meetingsCount;
  }

  get personalArticlesCount(): number {
    return this.$store.state.ui.personalArticlesCount;
  }

  get isUserInactiveInCompany() {
    return this.$store.state.isUserInactiveInCompany;
  }

  get company() {
    if (this.$store.state.pageCompany) {
      return this.$store.state.pageCompany;
    } else if (this.$store.state.company) {
      return this.$store.state.company;
    }
    return null;
  }

  get isGuest() {
    return this.$auth.isGuest();
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get workspaceView() {
    return this.$store.state.workspaceView;
  }

  get isExtensionBannerVisible() {
    return this.$store.state.ui.isExtensionBannerVisible;
  }

  get personalWorkspaceShortId() {
    return this.$store.state.personalWorkspace ? this.$store.state.personalWorkspace.shortId : '';
  }

  get isWorkspacesSidebar() {
    return this.$store.state.ui.isWorkspacesSidebar;
  }

  get articleEditor() {
    return articleEditService.articleEditor;
  }

  get isPersonalWorkspaceArticle() {
    return (
      (this.$store.state.personalWorkspace?.id === this.articleEditor?.article?.workspace &&
        this.$route.path.includes('/draft/')) ||
      this.$route.path.includes('/article')
    );
  }

  navigate(route: string) {
    this.$router.push({ name: route });
    if (this.isWorkspacesSidebar) this.toggleSidebar();
    if (this.$store.state.isMobile) this.$store.commit('toggleMainSidebar', false);
  }

  async updateCounts() {
    await Promise.all([dispatchGetDraftsCount(), dispatchGetMeetingsCount(), dispatchGetPersonalArticlesCount()]);
  }

  async init() {
    if (!this.isGuest) {
      await this.updateCounts();
    }
  }

  openPersonalWorkspace() {
    const personalWorkspace = this.$store.state.personalWorkspace;

    this.$router.push(urlService.getWorkspaceViewRoute(personalWorkspace)).catch((e) => {
      log.error(e);
    });

    this.isWorkspacesSidebar && this.toggleSidebar();
  }

  getMainSidebarStyles() {
    return {
      width: !this.isMainSidebarExpanded ? this.mainSidebarWidth : this.mainSidebarMaxAllowedWidth,
      top: !this.isMobile && this.isExtensionBannerVisible ? '100px' : '60px',
    };
  }

  toggleSidebar() {
    commitToggleWorkspacesSidebar();
  }

  mounted() {
    this.init();
  }

  beforeDestroy() {
    EventBus.$off('side-bars-width-changed');
    EventBus.$off('workspace-ordering-changed');
  }
}
