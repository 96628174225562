import appStore from '@/store';
import { ArticleVersion } from '@/@types/ArticleVersion';
import { Article } from '@/models/article/Article';

export const dispatchDeleteArticleVersion = () => {
  return appStore.dispatch('deleteArticleVersion');
};
export const dispatchRestoreArticleVersion = () => {
  return appStore.dispatch('restoreArticleVersion');
};
export const dispatchLoadArticleVersion = (articleVersion: ArticleVersion) => {
  return appStore.dispatch('loadArticleVersion', articleVersion);
};
export const dispatchCloseArticleVersionMode = (articleId: string = null) => {
  return appStore.dispatch('closeArticleVersionMode', articleId);
};
export const dispatchResetArticleVersionView = () => {
  return appStore.dispatch('resetArticleVersionView');
};
export const dispatchSetArticleVersionToSidebar = (article: Article) => {
  return appStore.dispatch('setArticleVersionIntoSidebar', article);
};
