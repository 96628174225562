
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { HttpRequestOptions } from 'element-ui/types/upload';
import { Loading } from 'element-ui';
import notificationService from '@/services/NotificationService';
import apiService from '@/services/ApiService';

const FILE_SIZE_LIMIT = 5e7;

@Component({
  components: {
    MenuButton,
  },
})
export default class MenuAddAttachmentButton extends Vue {
  @Prop({ required: true }) editor: Editor;

  fileUploadDialogVisible = false;

  openDialog() {
    this.fileUploadDialogVisible = true;
  }

  async uploadFile(requestOptions: HttpRequestOptions) {
    const { file } = requestOptions;

    const loadingInstance = Loading.service({ target: '.el-tiptap-upload' });
    const fileNameParts = file.name.split('.');
    const ext = fileNameParts.pop();
    const name = fileNameParts.join('.');
    const size = file.size;
    const time = Date.now();

    if (size > FILE_SIZE_LIMIT) {
      notificationService.warning('The maximum allowed file size is 50MB');
      loadingInstance.close();
      return;
    }

    try {
      const response = await apiService.fileAttachment.upload(file);
      const { id, url } = response.data;

      this.editor.chain().focus().insertFileAttachment({ id, url, name, ext, size, time }).run();
      this.fileUploadDialogVisible = false;
    } catch (e) {
      apiService.handleResponseError(e);
    } finally {
      this.$nextTick(() => {
        loadingInstance.close();
      });
    }
  }
}
