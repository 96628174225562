import { CommandProps, Extension } from '@tiptap/core';

export type FontSizeOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (fontSize: number) => ReturnType;
      unsetFontSize: () => ReturnType;
    };
  }
}

// const numberPattern = /\d+/g;

export const FontSize = Extension.create<FontSizeOptions>({
  name: 'fontSize',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: 16, // Set the default font size here
            parseHTML: () => {
              // committed to not remove.
              // const match = element.style.fontSize?.match(numberPattern);
              // const fontSize = parseInt(match && match.length ? match.join('') : '0');
              // return fontSize > 0 && fontSize < 14 ? fontSize : null;
              return {}; // Return empty object here to avoid adding inline style
            },
            renderHTML: () => {
              // committed to not remove.
              // if (!attributes.fontSize) {
              //   return {};
              // }
              //
              // return {
              //   style: `font-size: ${attributes.fontSize}px`, // Add 'px' suffix to the font size value
              // };
              return {}; // Return empty object here to avoid adding inline style
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize: number) =>
        ({ chain }: CommandProps) => {
          return chain().setMark('textStyle', { fontSize }).run();
        },
      unsetFontSize:
        () =>
        ({ chain }: CommandProps) => {
          return chain().setMark('textStyle', { fontSize: null }).removeEmptyTextStyle().run();
        },
    };
  },
});

export default FontSize;
