
import { Component, Prop, Watch } from 'vue-property-decorator';
import ArticleTimeline from './ArticleTimeline.vue';
import helper from '../../helper';
import { Range } from '@/models/article/Range';
import { ParagraphPlaylistController } from '@/controllers/player/ParagraphPlaylistController';
import { PlayMap } from '@/models/article/PlayMap';
import { VideoState } from '@/models/article/VideoState';
import { Article } from '@/models/article/Article';
import { Paragraph } from '@/models/article/Paragraph';
import { Video } from '@/models/Video';
import articlePlayerProvider from '@/services/ui-providers/ArticlePlayerProvider';
import { ARTICLE_CREATE, ARTICLE_EDIT, ARTICLE_VERSION_EDIT, DRAFT_EDIT } from '@/routerNames';
import VueApp from '@/@types/app/VueApp';
import articleEditService from '@/services/article/ArticleEditService';
import { ArticlePlayerStates } from '@/models/ArticlePlayerStates';
import { ARTICLE_PLAYER_CHANGE_STATE } from '@/events';
import EventBus from '@/EventBus';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import { KEYBOARD_PLAYER_CODES } from '@/@types/enums/ArticlePlayerTypes';
import { Logger } from '@/other/Logger';
import VideoPlaybackBar from '../layout/dialogs/VideoPlaybackBar.vue';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { commitSetVideoPlayerRef, commitSetVideoPlayerTimeToSet } from '@/store/commits/sharedCommits';
import authService from '@/services/AuthService';
import OcrModeBar from '@/components/OcrModeBar/OcrModeBar.vue';
import OcrResultsOverlay from '@/components/OcrResultsOverlay/OcrResultsOverlay.vue';
import { dispatchDisableOcrMode } from '@/store/dispatchers/uiDispatchers';
import OcrProgressDialog from '@/components/OcrResultsOverlay/OcrProgressDialog.vue';
import PlayerVolumeControl from '@/components/layout/dialogs/PlayerVolumeControl.vue';
import store from '@/store';
import * as routerNames from '@/routerNames';

const log = new Logger('ArticlePlayer');

let intervalId: number | null = null;
let stateHandlers: any[] = [];
const VERSION_FOOTER_HEIGHT = 81;
const VIDEO_REACTION_MENU_HEIGHT = 55;

@Component({
  components: {
    PlayerVolumeControl,
    OcrProgressDialog,
    OcrResultsOverlay,
    OcrModeBar,
    ArticleTimeline,
    VideoPlaybackBar,
  },
  directives: {
    playbackRate(el, binding) {
      (el as HTMLVideoElement).playbackRate = binding.value;
    },
  },
})
export default class ArticlePlayer extends VueApp {
  @Prop({ type: Object, required: true }) article: Article;
  @Prop({ type: Boolean, required: true }) editMode: boolean;
  @Prop(String) customCursorTitle: string;
  @Prop(Number) customHeight: number;
  @Prop({ type: String, default: null }) className: string;

  controller: ParagraphPlaylistController = null;
  playerState = ArticlePlayerStates.paused;
  playbackState: VideoState = null; // state object of current play time
  videosMap: any = {};
  currentTime = 0;
  paragraphTime = 0;
  videoTime = 0;
  videoHeight = 0;
  wrapperVideoHeight = 0;
  videoError = '';
  fullscreenMode = false;
  trimmingMode = false;
  resetVideo = false;
  preloadRequests: any[] = [];
  articlePlayerStates = ArticlePlayerStates;
  playerNavbarFooter = 70;
  speed = 1.0;
  volume = 50;

  $refs: {
    videoPlaybackBar: VideoPlaybackBar;
    videoWrapper: HTMLDivElement;
    video: HTMLVideoElement;
    timeline: ArticleTimeline;
  };

  get articleMode() {
    return this.$store.state.articleMode;
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get isOcrModeEnabled() {
    return this.$store.state.ui.isOcrModeEnabled;
  }

  get paragraph() {
    const playbackState = this.playbackState;
    let paragraph = null;
    if (playbackState) {
      paragraph = playbackState.paragraph.key;
    }
    return paragraph;
  }

  get articleTime() {
    return this.calculateArticleTime(this.article.paragraphs);
  }

  get isArticleEditPage() {
    return [
      routerNames.ARTICLE_CREATE,
      routerNames.ARTICLE_EDIT,
      routerNames.ARTICLE_VERSION_EDIT,
      routerNames.DRAFT_EDIT,
    ].includes(this.$route.name);
  }

  updateVolume(newVolume: number) {
    this.volume = newVolume;
    this.getVideoRef().volume = newVolume / 100;
    this.getVideoRef().muted = newVolume === 0;
  }

  handleDragEnd() {
    //tempTime created for safari supporting
    const tempTime = this.videoTime;
    this.handleVideoTimeUpdate('any');
    this.videoTime = tempTime;
  }

  getVideoRef() {
    return this.$refs.video;
  }

  handleVideoTimeUpdate(e: any) {
    if (this.articleMode === ArticleEditMode.NOTES || !this.$refs.video) return;

    const { paragraph, videoOffset, offset } = this.playbackState;

    const lastTime = 1 - 1 + this.currentTime;
    const videoTime = this.$refs.video ? Math.round(this.$refs.video.currentTime * 1000) : 0;
    const paragraphPos = paragraph ? this.article.getParagraphPosition(paragraph) : 0;
    const currentTime = videoTime + paragraphPos - videoOffset + offset;

    log.info(`handleVideoTimeUpdate: currentTime=${currentTime}`);

    if (this.getVideoRef()) {
      log.info(`video timeUpdate: ${e.timeStamp} ${this.$refs.video.currentTime * 1000}`);
      log.info(
        `videoTime=${videoTime}, ParagraphPos=${paragraphPos}, currentTime=${currentTime}, Last time=${lastTime}`
      );
    }

    this.updateVideoState(currentTime);
    if (this.controller.onTimeUpdate(currentTime, videoTime) !== false) {
      this.videoTime = videoTime;
      this.currentTime = currentTime;
      this.$emit('timeUpdate', Math.floor(this.currentTime));
    } else {
      log.info(`Time update skipped by controller: ${currentTime}, videoTime=${videoTime}`);
    }
  }

  handleParagraphTimeUpdate(e: { paragraph: Paragraph; progress: number; paragraphTime: number }) {
    const { paragraph, progress, paragraphTime } = e;

    this.paragraphTime = paragraphTime;
    this.$emit('playingParagraph', { paragraph, progress });
  }

  handleVideoPlaying() {
    this.playerState = ArticlePlayerStates.playing;
  }

  handleVideoPaused() {
    this.playerState =
      this.playerState !== ArticlePlayerStates.error ? ArticlePlayerStates.paused : ArticlePlayerStates.error;
  }

  handleVideoCanPlay() {
    // load progress finished
    setTimeout(() => {
      if (helper.isSafari() && this.videoTime > 0) {
        log.info(`canPlay for apple devices`);
        this.getVideoRef().currentTime = this.videoTime / 1000;
      }
      if (this.playerState === ArticlePlayerStates.waiting) {
        this.playerState = ArticlePlayerStates.paused;
      }
    }, 100);
  }

  handleVideoWaiting() {
    this.playerState = ArticlePlayerStates.waiting;
    log.info(`Waiting...`);
  }

  handleVideoError() {
    const videoElement = this.getVideoRef();
    const isPlaying = [ArticlePlayerStates.playing, ArticlePlayerStates.waiting].includes(this.playerState);
    const error = `Video error ${videoElement.error.code}; details: ${videoElement.error.message}`;

    // decoding error was happens randomly on Apple Silicon M1:  PIPELINE_ERROR_DECODE: VDA Error 4
    if (videoElement.error.code === MediaError.MEDIA_ERR_DECODE) {
      const currentTime = this.currentTime;
      this.resetVideo = true;
      this.playerState = ArticlePlayerStates.waiting;

      // reset with timout to be sure that video element was really removed
      window.setTimeout(() => {
        this.resetVideo = false;
        this.$nextTick(() => {
          log.info(`Set time after reloading video: ${this.currentTime}, isPlaying=${isPlaying}`);
          this.updateVideoState(currentTime);
          if (isPlaying) this.play();
        });
      }, 50);
      return;
    } else if (videoElement.error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
      // handling MPEG-4 codec error that mostly occur on Safari
      let safariAgent = navigator.userAgent.indexOf('Safari') > -1;
      if (!safariAgent) return this.showError(error);

      this.$nextTick(async () => {
        const videoElement = this.getVideoRef();
        if (this.playerState !== ArticlePlayerStates.paused && videoElement) {
          videoElement.pause();
          this.playerState = ArticlePlayerStates.paused;
        }

        this.updateVideoState();

        await this.setControllerParagraphs(this.article.getParagraphsList(), false, 0);
        this.currentTime = 0;
      });
      return;
    }
    this.showError(error);
  }

  showError(error: string) {
    log.error(error);
    const playbackState = this.playbackState || new VideoState();
    this.playerState = ArticlePlayerStates.error;
    this.videoError = playbackState.src ? `Cannot load video: <br/>${this.playbackState.src}` : `No video source`;
  }

  handleVideoLoadStart() {
    log.info(`Start loading video`);
    this.videoError = '';
  }

  handleVideoLoadedData() {
    this.$emit('video-loaded-data');
  }

  handleVideoEnded() {
    log.info('Video ended');
    this.playerState = ArticlePlayerStates.paused;
    this.controller.onVideoEnded();
  }

  handleVideoDurationChanged() {
    if (this.getVideoRef()) {
      const duration = this.getVideoRef().duration;
      if (duration > 0 && duration !== Infinity) {
        this.$emit('video-duration-detected', {
          video: this.getVideoRef().src,
          duration: Math.round(duration * 1000),
        });
      }
    }
  }

  handleTimelineClick(data: any) {
    const { time, paragraphTime } = data;
    log.info(`CLICK on TIMELINE time=${time}  paragraphTime=${paragraphTime}`);
    this.paragraphTime = paragraphTime;
    this.setTime(time);
  }

  handleTogglePlayClick() {
    const playPauseButton = document.getElementsByClassName('player-state');
    if (playPauseButton && playPauseButton.length) {
      [...playPauseButton].forEach((button) => {
        button.remove();
      });
    }
    if (this.playerState === ArticlePlayerStates.error) {
      return;
    }
    const isPlayed = this.playerState === ArticlePlayerStates.playing;
    this.togglePlay();

    // show button
    const el = document.createElement('div');
    el.setAttribute('class', 'player-state state-' + (isPlayed ? 'pause' : 'play'));

    this.$refs.videoWrapper.appendChild(el);

    // remove state icon after animation done
    setTimeout(() => {
      el.remove();
    }, 600);
  }

  startTrimming() {
    this.trimmingMode = true;
    this.$refs.timeline.startTrimming();
  }

  cancelTrimming() {
    this.trimmingMode = false;

    this.$refs.timeline.trimRanges = [];
  }

  applyTrimming() {
    articleEditService.pushUndoArticleStack();
    this.trimmingMode = false;
    let range = this.$refs.timeline.trimRanges[0];
    range = new Range(Math.round(range.start), Math.round(range.end));

    this.$refs.timeline.trimRanges = [];
    this.$emit('trim', range);
  }

  get isArticleVersionIsShowFooter(): boolean {
    return this.$store.state.articleVersionView.isShowFooter;
  }

  updateVideoHeight() {
    // height of elements for calculate video height;
    const navbar = 60;
    let thresholdWidth = 1477;

    if (window.innerWidth <= thresholdWidth && !this.isMobile) {
      this.playerNavbarFooter = 110;
    } else {
      this.playerNavbarFooter = 88;
    }
    const isEditPage = [ARTICLE_EDIT, ARTICLE_CREATE, DRAFT_EDIT, ARTICLE_VERSION_EDIT].includes(this.$route.name);

    if (!this.fullscreenMode) {
      this.isCustomHeight(navbar);
      if (this.isArticleVersionIsShowFooter) {
        this.videoHeight -= VERSION_FOOTER_HEIGHT;
      }
      this.wrapperVideoHeight = this.videoHeight;

      if (this.isMobile) {
        const overflowControls = 100;
        const mobilePanels = 120;
        this.videoHeight = window.innerHeight - navbar - mobilePanels;
        this.wrapperVideoHeight = this.videoHeight;
        this.videoHeight -= overflowControls;
      }

      if (isEditPage) {
        const additionalSpace = this.isMobile ? 45 : this.playerNavbarFooter;
        this.videoHeight -= additionalSpace;
        this.wrapperVideoHeight -= additionalSpace;
      } else if (!this.isArticleVersionIsShowFooter && authService.isAuthorized()) {
        this.videoHeight -= VIDEO_REACTION_MENU_HEIGHT;
        this.wrapperVideoHeight -= VIDEO_REACTION_MENU_HEIGHT;
      }
    } else {
      this.videoHeight = window.innerHeight;
      this.wrapperVideoHeight = window.innerHeight;
    }
  }

  isCustomHeight(navbar: number) {
    if (this.customHeight && this.customHeight > 0) {
      this.videoHeight = this.customHeight;
    } else if (this.$refs.video) {
      this.videoHeight = window.innerHeight - navbar - this.playerNavbarFooter;
    }
  }

  updateVideoState(ms: number = null) {
    // get state of current time
    const initialMs = ms;
    const videoElement = this.getVideoRef();

    ms = ms !== null ? ms : this.currentTime;
    log.info(`updateVideoState: ${initialMs} => ${ms}, currentTime=${this.currentTime}`);

    if (ms >= this.article.calculateDuration()) {
      log.info(`Can not update video state: ${ms}, max duration reached`);
      return;
    }

    const videoSourceState = this.getVideoState(ms);
    const playbackState = this.playbackState || new VideoState();
    const isVideoSrcDifferent = !this.isSameUrl(playbackState.src, videoElement ? videoElement.src : '');
    const changeSrc = (videoElement && isVideoSrcDifferent) || playbackState.src !== videoSourceState.src;
    const differentOffset =
      (playbackState.offset !== videoSourceState.offset ||
        playbackState.videoOffset !== videoSourceState.videoOffset) &&
      !mobileResponsiveService.isMobileView;
    if ((changeSrc || differentOffset) && videoElement) {
      try {
        // change source if needed
        if (changeSrc) {
          videoElement.src = videoSourceState.src;
          videoElement.load();
          log.info(`Video player src changed to: ${videoSourceState.src}`);
        }

        // update time
        videoElement.currentTime = videoSourceState.time / 1000;
        log.info(`Video player set video time: ${videoSourceState.time}`);
      } catch (e) {
        log.error(e);
      }

      if (this.playerState === ArticlePlayerStates.playing) {
        log.info('Resume video playing');
        this.play();
      }
    }

    this.playbackState = videoSourceState;
  }

  getVideoState(ms: number) {
    let state = new VideoState();

    if (ms > this.article.duration) {
      ms = this.article.duration;
    }

    const paragraphs = this.article.getParagraphsList();
    const pr = new Range(0, 0);

    for (const p of paragraphs) {
      pr.end = pr.start + p.duration;

      if (pr.contain(ms)) {
        state = PlayMap.fromParagraph(p).getVideoState(ms - pr.start);
        state.paragraph = p;
        break;
      }

      pr.start = pr.end;
    }

    return state;
  }

  // video control methods
  togglePlay() {
    if (this.playerState !== ArticlePlayerStates.playing) {
      this.play();
    } else {
      this.stop();
    }
  }

  play() {
    if (this.controller.onPlay() !== false) {
      dispatchDisableOcrMode();
      log.info('Starting play video...');

      const videoElement = this.getVideoRef();

      if (videoElement && videoElement.src) {
        videoElement
          .play()
          .then(() => {
            log.info(`Started play video: ${videoElement.src}`);
          })
          .catch((err: any) => {
            log.info(`Cannot play video [${videoElement.src}]: ${err}`);
          });
      } else {
        log.info('Cannot play video. No video element or src is missing.');
      }
    }
  }

  stop() {
    if (this.controller) {
      this.controller.onStop();
    }
    const videoRef = this.getVideoRef();

    if (videoRef) {
      videoRef.pause();
    }

    log.info('Video stopped');
    return this.addStateHandler([ArticlePlayerStates.paused, ArticlePlayerStates.error], 1000);
  }

  playArticle() {
    dispatchDisableOcrMode();
    this.setControllerParagraphs(this.article.getParagraphsList(), true);
  }

  playParagraph(item: Paragraph) {
    dispatchDisableOcrMode();
    this.setControllerParagraphs(this.article.getParagraphsList(), true, this.article.getParagraphPosition(item));
  }

  setControllerParagraphs(paragraphs: Paragraph[], play = false, savePosition: number | boolean = false) {
    return new Promise((resolve) => {
      this.controller = new ParagraphPlaylistController(this, paragraphs, play, savePosition);

      setTimeout(resolve, 0);
    });
  }

  setTime(ms: number) {
    // set paragraphs from whole article
    this.setControllerParagraphs(this.article.getParagraphsList(), false, true);
    this.setTimeInternal(ms);
    this.controller.onUserChangedTime(ms);
    this.$emit('timeUpdate', Math.floor(this.currentTime));
  }

  setTimeInternal(ms: number) {
    log.info(`Set time Internal: ${ms}`);

    if (ms >= this.article.duration && this.article.duration > 1) {
      ms = this.article.duration - 1;
      log.info(`time is bigger then article duration. Fix ms = ${ms}`);
    }

    this.currentTime = ms;
    this.updateVideoState();

    // force update video time
    const videoRef = this.getVideoRef();
    if (!videoRef) {
      // @todo
      log.info('TODO video element not available anymore');
      return;
    }
    this.videoTime = this.playbackState.time;
    videoRef.currentTime = this.playbackState.time / 1000;

    log.info(`Playback state: ${JSON.stringify(this.playbackState)}`);
    log.info(`Update video.currentTime: ${this.getVideoRef().currentTime} => ${this.playbackState.time / 1000}`);
  }

  addStateHandler(state: string | string[], maxTime = 0) {
    log.info(`STATE ${state} HANDLER ADDED`);

    return new Promise((resolve, reject) => {
      stateHandlers.push({
        state,
        resolve,
        reject,
        time: new Date().getTime(),
        limit: maxTime,
      });
    });
  }

  updateStateHandlers() {
    if (stateHandlers.length) {
      log.info(`Update state handlers: ${this.playerState}`);

      const time = new Date().getTime();
      // execute expired handlers
      stateHandlers = stateHandlers.filter((item) => {
        if (item.limit > 0 && time - item.time > item.limit) {
          item.resolve();
          return false;
        }
        return true;
      });

      // filter items by state
      const items = stateHandlers.filter((item) => {
        return typeof item.state === 'object'
          ? item.state.indexOf(this.playerState) >= 0
          : item.state === this.playerState;
      });

      if (items.length) {
        // resolve each item and remove from list;
        for (const item of items) {
          item.resolve();
          const index = stateHandlers.indexOf(item);
          if (index >= 0) {
            stateHandlers.splice(index, 1);
          }
        }
      }
    }
  }

  emitControllerEvent(event: any, data: any) {
    this.$emit(event, data);
  }

  updateVideosMap() {
    this.videosMap = {};
    // for (let video of this.article.videos) {
    //   this.videosMap[video.id] = JSON.parse(JSON.stringify(video));
    // }
  }

  clearVideosPreload() {
    if (this.preloadRequests.length) {
      for (const req of this.preloadRequests) {
        req.abort();
      }
      this.preloadRequests = [];
    }
  }

  preloadVideos() {
    for (const key in this.article.videos) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.article.videos.hasOwnProperty(key)) {
        this.preloadVideo(this.article.videos[key]);
      }
    }
  }

  preloadVideo(item: Video) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const req = new XMLHttpRequest();
    req.open('GET', item.url, true);
    req.responseType = 'blob';

    req.onload = function () {
      // Onload is triggered even on 404
      // so we need to check the status code
      if (this.status === 200 || this.status === 304) {
        const videoBlob = this.response;
        // Video is now downloaded
        // and we can set it as source on the video element
        item.objectUrl = URL.createObjectURL(videoBlob);
        if (self.videosMap && typeof self.videosMap[item.id] !== 'undefined') {
          self.videosMap[item.id].objectUrl = item.objectUrl + '';
        }
        log.info(`Video ${item.url} cached`);
      }
    };
    req.onerror = () => {
      // Error
    };

    req.send();
    this.preloadRequests.push(req);
  }

  togglePlayback() {
    this.$refs.videoPlaybackBar
      .show()
      .then((speed: number) => {
        this.speed = speed;
      })
      .catch(() => {
        log.error(new Error('Playback speed bar was rejected'));
      });
  }

  toggleFullscreen() {
    if (!this.fullscreenMode) {
      let element = this.$el;
      if (helper.isIOSMobileDevice()) {
        element = this.$refs.video;
      }
      helper.openFullscreen(element);
      setTimeout(() => {
        this.fullscreenMode = true;
      }, 0);
    } else {
      helper.closeFullscreen();
    }
  }

  onFullScreenChange() {
    const doc = document as Document & {
      webkitFullscreenElement: unknown;
      mozFullScreenElement: unknown;
      msFullscreenElement: unknown;
    };
    this.fullscreenMode =
      (doc.fullscreenElement && doc.fullscreenElement !== null) ||
      (doc.webkitFullscreenElement && doc.webkitFullscreenElement !== null) ||
      (doc.mozFullScreenElement && doc.mozFullScreenElement !== null) ||
      (doc.msFullscreenElement && doc.msFullscreenElement !== null);

    log.info(`Fullscreen mode changed: ${!!this.fullscreenMode}`);
    this.updateVideoHeight();
  }

  isModalOpened() {
    const modals = document.getElementsByClassName('md-dialog');
    return modals.length > 0;
  }

  isParagraphEditing() {
    const inputs = document.getElementsByClassName('paragraph-edit-input');
    return inputs.length > 0;
  }

  isSameUrl(url: string, url2: string) {
    const a = url.includes('?') ? url.split('?')[0] : url;
    const b = url2.includes('?') ? url2.split('?')[0] : url2;

    return a === b;
  }

  timeForward() {
    const time = this.currentTime + 3000;
    this.setTime(time);
  }

  timeBackward() {
    let time = this.currentTime - 1000;
    if (time <= 0) {
      time = 1;
    }
    this.setTime(time);
  }

  onKeyDown(e: KeyboardEvent) {
    const eventTarget = e.target as HTMLElement;
    if ((eventTarget != null && eventTarget.nodeName === 'INPUT') || eventTarget?.classList.contains('ProseMirror')) {
      return;
    }

    const body = document.body;
    if (!body.classList.contains('el-popup-parent--hidden') && !this.isModalOpened() && !this.isParagraphEditing()) {
      switch (e.code) {
        case KEYBOARD_PLAYER_CODES.SPACE_KEY:
          e.preventDefault();
          this.handleTogglePlayClick();
          break;
        case KEYBOARD_PLAYER_CODES.ARROW_RIGHT_KEY:
          e.preventDefault();
          this.timeForward();
          break;
        case KEYBOARD_PLAYER_CODES.ARROW_LEFT_KEY:
          e.preventDefault();
          this.timeBackward();
          break;
        default:
          return;
      }
    }
  }

  async init(currentTime = 0) {
    const videoElement = this.getVideoRef();
    const storedVolume = JSON.parse(localStorage.getItem('articlePlayerVolume'));
    if (this.playerState !== ArticlePlayerStates.paused && videoElement) {
      videoElement.pause();
      this.playerState = ArticlePlayerStates.paused;
    }

    if (videoElement) {
      const volumeToUse = typeof storedVolume === 'number' ? storedVolume : this.volume;
      this.updateVolume(volumeToUse > 100 || volumeToUse < 0 ? this.volume : volumeToUse);
    }

    this.clearVideosPreload();
    this.preloadVideos();
    this.updateVideosMap();
    this.updateVideoState();
    this.updateVideoHeight();

    await this.setControllerParagraphs(this.article.getParagraphsList(), false, currentTime);
    this.currentTime = currentTime;
  }

  update(saveTime = false) {
    log.info('Update player');
    let time = this.currentTime;
    if (!saveTime) {
      const ranges = this.article.getRanges();
      if (ranges && ranges[0]) {
        time = ranges[0].start;
      }
    }
    const videoPlayerTimeToSet = store.state.videoPlayerTimeToSet;
    this.init(videoPlayerTimeToSet && this.isArticleEditPage ? videoPlayerTimeToSet : saveTime ? time : 0);
    if (videoPlayerTimeToSet) commitSetVideoPlayerTimeToSet(null);
    this.$forceUpdate();

    this.$nextTick(() => {
      this.updateTimeline();
    });
  }

  updateTimeline() {
    return this.$refs.timeline && this.$refs.timeline.update();
  }

  updatePlayerRef() {
    articlePlayerProvider.videoPlayer = this;
  }

  onChangePlayerState(playerState: string) {
    const playerEvent = playerState === ArticlePlayerStates.playing ? 'play' : 'stop';
    this[playerEvent]();
  }

  mounted() {
    EventBus.$on(ARTICLE_PLAYER_CHANGE_STATE, this.onChangePlayerState);
    this.$refs.video.removeEventListener('webkitendfullscreen', this.onFullScreenChange);
    this.$refs.video.addEventListener('webkitendfullscreen', this.onFullScreenChange, false);
    this.setVideoPlayerRef();
  }

  created() {
    // without this.$nextTick videoElement will be showed corrupted
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.updateVideoHeight();
      });
      this.init();
    });
    intervalId = window.setInterval(() => {
      this.updateStateHandlers();
    }, 100);

    // register fullscreen events
    document.addEventListener('fullscreenchange', this.onFullScreenChange, false);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenChange, false);
    document.addEventListener('mozfullscreenchange', this.onFullScreenChange, false);
    // register hotKeys controller
    document.addEventListener('keydown', this.onKeyDown);

    this.$emit('ready');
    this.updatePlayerRef();

    if (this.article) {
      this.setArticle();
    }
    dispatchDisableOcrMode();
  }

  beforeDestroy() {
    log.info('Before destroy player');
    if (intervalId !== null) {
      clearInterval(intervalId);
    }

    commitSetVideoPlayerRef(null);

    EventBus.$off(ARTICLE_PLAYER_CHANGE_STATE);

    // remove global event listeners
    window.removeEventListener('resize', this.updateVideoHeight);
    document.removeEventListener('fullscreenchange', this.onFullScreenChange);
    document.removeEventListener('webkitfullscreenchange', this.onFullScreenChange);
    document.removeEventListener('mozfullscreenchange', this.onFullScreenChange);
    this.$refs.video.removeEventListener('webkitendfullscreen', this.onFullScreenChange);

    // remove control by hotKeys
    document.removeEventListener('keydown', this.onKeyDown);
  }

  calculateArticleTime(paragraphs: any) {
    let time = 0;
    paragraphs.forEach((paragraph: any) => {
      time += paragraph.duration;
      if (paragraph.items.length > 0) {
        time += this.calculateArticleTime(paragraph.items);
      }
    });
    return time;
  }

  msToTime(ms = 0) {
    return helper.msToTime(ms);
  }

  setArticle() {
    this.$store.commit('setArticleEditorArticle', this.article);
  }

  setVideoPlayerRef() {
    const articlePlayer = articlePlayerProvider.videoPlayer;
    commitSetVideoPlayerRef(articlePlayer);
  }

  @Watch('volume')
  onVolumeStateChanged(newVolume: number) {
    localStorage.setItem('articlePlayerVolume', JSON.stringify(newVolume));
  }

  @Watch('playerState')
  onPlayerStateChanged(state: ArticlePlayerStates) {
    this.updateStateHandlers();
    this.$emit('stateChanged', state);
  }

  @Watch('customHeight')
  onCustomHeightChanged() {
    this.updateVideoHeight();
  }

  @Watch('article')
  onArticleChanged() {
    log.info(`Article changed: ${JSON.stringify(this.article)}`);
    this.init();
    this.update();
    this.setArticle();
    this.speed = 1.0;

    if (store.state.videoPlayerTimeToSet) commitSetVideoPlayerTimeToSet(null);
    dispatchDisableOcrMode();
  }
}
