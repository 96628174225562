
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FloatingMenu as TiptapFloatingMenu } from '@tiptap/vue-2';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';

@Component({
  components: {
    TiptapFloatingMenu,
  },
})
export default class FloatingMenu extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;
}
