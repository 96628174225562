import { Plugin } from 'prosemirror-state';
import { Mention as TiptapMention } from '@tiptap/extension-mention';
import suggestion from '@/components/tiptap/extensions/Mention/suggestion';
import mentionDetailsPlugin from '@/components/tiptap/extensions/Mention/plugins/MentionDetailsPlugin';
import Suggestion from '@tiptap/suggestion';

const _mention = TiptapMention.extend({
  addProseMirrorPlugins() {
    return [Suggestion({ editor: this.editor, ...this.options.suggestion }) as unknown as Plugin, mentionDetailsPlugin];
  },
});

const Mention = _mention.configure({
  HTMLAttributes: {
    class: 'tiptap-mention',
  },
  renderLabel({ node }) {
    return `${node.attrs.label ?? node.attrs.id}`;
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  suggestion,
});

export default Mention;
