
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';
import TimeTag from '../TimeTag/TimeTag.vue';
import helper from '@/helper';
import { commitSetCommentsSidebarIsActiveTimeTag } from '@/store/commits/commentsSidebarCommits';

@Component({
  components: {
    TimeTag,
  },
})
export default class TimeTagButton extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  get videoReaction() {
    return this.$store.state.commentsSidebar.videoReaction;
  }

  get currentVideoTime(): number | null {
    return this.videoReaction ? this.videoReaction.currentVideoTime : null;
  }

  get time() {
    if (this.currentVideoTime === 0) return '00:00';
    return this.currentVideoTime ? this.handleTime(this.currentVideoTime) : '';
  }

  get isActiveTimeTag(): boolean {
    return this.$store.state.commentsSidebar.isActiveTimeTag;
  }

  handleTime(time: number) {
    return helper.msToTime(time);
  }

  toggleTimeTag() {
    commitSetCommentsSidebarIsActiveTimeTag(!this.isActiveTimeTag);
  }
}
