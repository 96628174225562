import { ArticleVideoEditorState } from '@/components/article-editor/types/ArticleVideoEditorState';
import confirmationService from '@/services/ConfirmationService';
import extensionService from '@/services/ExtensionService';
import { MessageBox } from 'element-ui';
import store from '@/store';
import { Logger } from '@/other/Logger';
import { PostMessage } from '@/other/PostMessage';
import { ExtensionMessageType } from '@/@types/enums/ExtensionMessageType';

const log = new Logger('ArticleAddRecordingService');

export class ArticleAddRecordingService {
  async handleRecordVideoForArticle() {
    const resolve = async (): Promise<void> => {
      store.commit('setArticleEditorState', ArticleVideoEditorState.inserting);
      store.commit('setArticleEditorSelectedParagraph', null);
      await this.startRecording();
    };

    if (store.state.articleEditor.article.paragraphs.length) {
      confirmationService
        .confirm('Are you sure that you want to add video?', 'Are you sure?', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'info',
        })
        .then(resolve)
        .catch((err: Error) => {
          log.error(err);
        });
    } else {
      await resolve();
    }
  }

  protected async startRecording(): Promise<void> {
    // check for new extension
    const isExtensionInstalled = !!store.state.extension.id;
    if (isExtensionInstalled) {
      extensionService.sendMessage(
        new PostMessage(ExtensionMessageType.RECORD_VIDEO_FOR_TAB, { article: store.state.articleEditor.article })
      );
      return;
    }

    await MessageBox.alert(
      `You need to install snaplore extension for start recording your screen.` +
        `<a href='https://chrome.google.com/webstore/detail/snaplore/${process.env.VUE_APP_CHROME_EXTENSION_ID}' target='_blank'>Install extension</a>`,
      'Snaplore extension required',
      {
        dangerouslyUseHTMLString: true,
        confirmButtonText: 'Ok',
      }
    );
  }
}

const articleAddRecordingService = new ArticleAddRecordingService();
export default articleAddRecordingService;
