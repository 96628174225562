import VueApp from '@/@types/app/VueApp';
import { MessageBox } from 'element-ui';
import moment from 'moment';
import helper from '@/helper';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';

class ConfirmationService extends VueApp {
  showRemovePopup(text: string, deletedItem: string | null, customStyleClass: string) {
    // @todo replace createElement with native method and get rid of extending VueApp here
    const h = this.$createElement;

    return MessageBox.confirm('', '', {
      message: h('p', null, [
        h('span', null, 'Are you sure you want to delete '),
        h('span', { style: 'color: #355ff6' }, `"${this.sliceMessageText(text, 40)}"`),
        h('span', null, ` ${deletedItem ? deletedItem : ''}?`),
      ]),
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      type: 'info',
      customClass: customStyleClass,
    });
  }

  showDiscardDraftPopup(text: string, discardItem: string, customStyleClass: string) {
    const h = this.$createElement;

    return MessageBox.confirm('', '', {
      message: h('p', null, [
        h('span', null, 'Are you sure you want to discard '),
        h('span', { style: 'color: #355ff6' }, `"${this.sliceMessageText(text, 40)}"`),
        h('span', null, ` ${discardItem}?`),
      ]),
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      type: 'info',
      customClass: customStyleClass,
    });
  }

  showArchiveWorkspacePopup(
    textAction: string,
    workspaceTitle: string,
    archivedItem: string,
    customStyleClass: string
  ) {
    const h = this.$createElement;

    return MessageBox.confirm('', '', {
      message: h('p', null, [
        h('span', null, `Are you sure you want to ${textAction} `),
        h('span', { style: 'color: #355ff6' }, `"${this.sliceMessageText(workspaceTitle, 40)}"`),
        h('span', null, ` ${archivedItem}?`),
      ]),
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      type: 'info',
      customClass: customStyleClass,
    });
  }

  showRestorePopup(text: string, customStyleClass: string) {
    const h = this.$createElement;

    return MessageBox.confirm('', '', {
      message: h('p', null, [
        h('span', null, 'Are you sure you want to restore '),
        h('span', { style: 'color: #2E5BFF' }, text),
        h('span', null, ` Version history?`),
      ]),
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      type: 'info',
      customClass: customStyleClass,
    });
  }

  showDeleteArticleVersionConfirmation(text: string) {
    const h = this.$createElement;

    return MessageBox.confirm('', '', {
      message: h('p', null, [
        h('span', null, 'Are you sure you want to delete '),
        h('span', { style: 'color: #2E5BFF' }, text),
        h('span', null, ` Version history?`),
      ]),
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      type: 'info',
      customClass: 'delete-article-version',
    });
  }

  showCommentRemovePopup(author: string, data: Date, content: string, profileImage: string, customStyleClass: string) {
    const h = this.$createElement;
    return MessageBox.confirm('', 'Delete this message permanently?', {
      message: h('div', { class: 'comment-card__wrapper is-popup' }, [
        h(UserProfilePhoto, { props: { imageSrc: profileImage } }),
        h('div', { class: 'comment-card' }, [
          h('div', null, [
            h('span', { class: 'comment-card__author' }, `${author}  `),
            h('span', { class: 'comment-card__createdAt' }, `${this.convertUnixToDate(data)}`),
          ]),
          h('div', {
            class: `comment-card__content ${helper.isJustReaction(content) ? 'reaction' : ''}`,
            domProps: { innerHTML: this.sliceMessageText(content, 40) },
          }),
        ]),
      ]),
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      type: 'info',
      customClass: customStyleClass,
    });
  }

  showCancelSubscriptionPopup() {
    return MessageBox.confirm(
      'Are you sure you want to cancel your subscription? You will lose access to paid features.',
      '',
      {
        confirmButtonText: 'Cancel subscription',
        cancelButtonText: 'Keep',
        type: 'info',
        customClass: 'cancel-subscription',
        lockScroll: true,
      }
    );
  }

  convertUnixToDate(date: Date) {
    if (!date) {
      return '-';
    }
    return moment(date).format('MMM DD, YYYY, hh:mm a');
  }

  sliceMessageText(str: string, length: number): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT);
    const nodesToRemove: Node[] = [];
    let charCount = 0;
    let ellipsisAdded = false;

    while (walker.nextNode()) {
      const currentNode = walker.currentNode;
      const nodeTextLength = currentNode.textContent.length;

      if (charCount + nodeTextLength > length && !ellipsisAdded) {
        currentNode.textContent = currentNode.textContent.substring(0, length - charCount) + '...';
        ellipsisAdded = true;
        break;
      }

      charCount += nodeTextLength;

      if (charCount >= length) {
        nodesToRemove.push(currentNode);
      }
    }

    while (walker.nextNode()) {
      nodesToRemove.push(walker.currentNode);
    }

    nodesToRemove.forEach((node) => {
      const parent = node.parentNode;
      if (parent) {
        parent.removeChild(node);
        // remove the parent if it is empty
        if (!parent.textContent.trim()) {
          if (parent.parentNode) {
            parent.parentNode.removeChild(parent);
          }
        }
      }
    });

    return doc.body.innerHTML;
  }

  confirm = MessageBox.confirm;
}

const confirmationService = new ConfirmationService();
export default confirmationService;
