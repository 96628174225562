
import VueApp from '@/@types/app/VueApp';
import { Component, Prop } from 'vue-property-decorator';
import { TextTab } from '@/models/article/TextTab';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { Article } from '@/models/article/Article';

@Component
export default class ArticleTextTabButton extends VueApp {
  loader: ElLoadingComponent = null;

  @Prop({ type: Boolean, default: false }) isActive: boolean;
  @Prop({ type: Array, required: true }) textTabs: TextTab[];
  @Prop({ type: Article, required: true }) article: Article;

  isDropdownOpen = false;

  get selectedTextTab() {
    return this.$store.state.articleEditor.selectedTextTab.tab;
  }

  get textTabsCount() {
    return this.textTabs.length;
  }

  isTextTabActive(tab: TextTab) {
    return this.selectedTextTab && this.selectedTextTab.id === tab.id;
  }

  isTextTabPinned(tab: TextTab) {
    if (!tab.id || !this.article.pinnedNote) return false;
    return this.article.pinnedNote === tab.id;
  }

  trimmedTitle(title: string) {
    const charLimit = 13;
    if (title.length > charLimit) {
      return title.slice(0, charLimit) + '...';
    }

    return title;
  }

  handleClick(tab: TextTab) {
    this.$emit('click', tab);
  }
}
