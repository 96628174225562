import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios from 'axios';
import { AnyJson } from '@/@types/AnyJson';

export class VideoUploadServiceProvider extends ApiBaseServiceProvider {
  scope = 'video-upload';

  getVideoUploadUrl() {
    return axios.get<{ url: string; id: string }>(`${this.getBaseUrl()}url`, this.getRequestConfig());
  }

  getVideoAcceptStatus(id: string) {
    return axios.get<{ id: string; status: string; error?: string; video?: AnyJson }>(
      `${this.getBaseUrl()}status/${id}`,
      this.getRequestConfig()
    );
  }

  acceptVideo(parts: string[]) {
    return axios.post<{ id: string }>(`${this.getBaseUrl()}accept`, { parts }, this.getRequestConfig());
  }
}
