import { User } from '@/models/User';
import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import store from '@/store';
import tippy from 'tippy.js';
import { VueRenderer } from '@tiptap/vue-2';
import MentionDetailsView from '@/components/tiptap/extensions/Mention/MentionDetailsView.vue';
import { dispatchLoadArticleComments } from '@/store/dispatchers/articleCommentsDispatchers';
import {
  commitSetCommentsSidebarIsVisible,
  commitSetCommentsSidebarScrollToCommentId,
} from '@/store/commits/commentsSidebarCommits';

export const commentsSidebarActions = {
  async commentsSidebarCheckAutoShowComment() {
    if (document.location.href.includes('?showComment=')) {
      const parts = document.location.href.split('?showComment=');
      const commentId = parts[1];

      if (commentId) {
        // show sidebar and scroll to specific comment
        document.location.href = parts[0];

        // show sidebar with a timeout to avoid issue wuth autoclosing on route change
        window.setTimeout(async () => {
          await dispatchLoadArticleComments();
          commitSetCommentsSidebarScrollToCommentId(commentId);
          commitSetCommentsSidebarIsVisible(true);
        }, 300);
      }
    }
  },
  async commentsSidebarShowMentionDetails(_: ActionContext<State, any>, element: HTMLElement) {
    const userId = element.dataset['id'];
    const user = userId ? store.state.companyUsers.find((user: User) => user.id === userId) : undefined;
    if (!user) return;

    const component = new VueRenderer(MentionDetailsView, {
      parent: null,
      propsData: { user },
    });

    let isDestroying = false;
    const instance = tippy(element, {
      appendTo: () => document.body,
      content: component.element,
      showOnCreate: true,
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
      onHide: (instance) => {
        if (!isDestroying) {
          isDestroying = true;
          instance.destroy();
          component.destroy();
        }
      },
    });
    instance.show();
  },
};
