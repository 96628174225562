
import { Component, Prop, Watch } from 'vue-property-decorator';
import EventBus from '@/EventBus';
import * as routerNames from '@/routerNames';
import { TRIGGER_SEARCH_INPUT } from '@/events';
import VueApp from '@/@types/app/VueApp';
import { Company } from '@/models/Company';

@Component
export default class Logo extends VueApp {
  @Prop({ required: false }) company: Company;
  snaploreLink = `https://snaplore.com`;
  loading = true;

  get sharedCompanyLogo() {
    return this.$store.state.sharedCompanyLogo;
  }

  get isCompanyLoading() {
    return this.$store.state.isCompanyLoading;
  }

  get isCompanyPaid() {
    return this.$store.state.company?.isPaid;
  }

  get isSharedPage() {
    return [routerNames.BROWSE_SHARED, routerNames.BROWSE_SHARED_DEPRECATED].includes(this.$route.name);
  }

  get isAuthPage() {
    const currentRoute = this.$route.name;
    return (
      currentRoute === routerNames.SIGNUP ||
      currentRoute === routerNames.SIGNUP_INVITED ||
      currentRoute === routerNames.LOGIN_SOCIAL_CALLBACK ||
      currentRoute === routerNames.LOGIN_SOCIAL_FAIL ||
      currentRoute === routerNames.LOGIN ||
      currentRoute === routerNames.PASSWORD_RESET ||
      currentRoute === routerNames.PASSWORD_RESET_TOKEN
    );
  }

  get isHomePage() {
    return this.$route.name === routerNames.HOME;
  }

  redirectToSnaplorePage() {
    if (!this.isCompanyPaid) return;
    if ([routerNames.LOGIN, routerNames.SIGNUP].includes(this.$route.name)) {
      window.open(this.snaploreLink, '_blank');
    }
  }

  @Watch('isHomePage')
  onHomePageOpened(isHome: boolean) {
    if (isHome) {
      EventBus.$emit(TRIGGER_SEARCH_INPUT);
    }
  }
}
