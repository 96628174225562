import { SocketMessageType } from '@/@types/enums/SocketMessageType';
import logger from '@/other/Logger';
import { SocketBaseServiceProvider } from '@/services/socket/SocketBaseServiceProvider';

export class ArticlePdfExportSocketAction extends SocketBaseServiceProvider {
  private pdfGenerationSocket: WebSocket | null = null;

  exportArticlePdf = async (articleId: string, textTabId?: string): Promise<void> => {
    let pdfViewWindow: Window | null = null;

    try {
      pdfViewWindow = window.open('', '_blank');
      if (pdfViewWindow) {
        pdfViewWindow.document.write(
          '<html><head><title>Generating PDF...</title></head><body><p>Generating PDF...</p></body></html>'
        );
      }

      const pdfGenerationPromise = new Promise<string>((resolve, reject) => {
        this.pdfGenerationSocket = new WebSocket(`${this.getSocketBaseUrl()}/generate-pdf?token=${this.token()}`);

        // WebSocket event listeners
        this.pdfGenerationSocket.onopen = () => {
          this.pdfGenerationSocket?.send(JSON.stringify({ type: SocketMessageType.START, articleId, textTabId }));
        };

        this.pdfGenerationSocket.onmessage = (event) => {
          if (event.data === SocketMessageType.PING) {
            const sendPong = () => {
              if (this.pdfGenerationSocket?.readyState === WebSocket.OPEN) {
                try {
                  this.pdfGenerationSocket.send(SocketMessageType.PONG);
                } catch (error) {
                  logger.error(`Failed to send pong: ${error}`);
                  setTimeout(sendPong, 2000); // Retry after 1 second
                }
              }
            };
            sendPong();
            return;
          }
          const message = JSON.parse(event.data);
          if (message.type === SocketMessageType.RESULT) {
            pdfViewWindow.location.href = message.url;
            this.pdfGenerationSocket.close(3001);
            resolve(message.url as string);
          }
        };

        this.pdfGenerationSocket.onclose = (event) => {
          const { code } = event;
          const handler = this.messageTypeHandler[code];
          if (!handler) return;

          reject({ type: handler.type, message: event.reason });
        };

        this.pdfGenerationSocket.onerror = (error) => {
          reject({ type: error.type, message: 'Failed to generate PDF from article' });
        };
      });

      await pdfGenerationPromise;
    } catch (err) {
      if (pdfViewWindow) pdfViewWindow.close();
      throw err;
    }
  };
}
