
import { Component, Watch } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import articleEditService from '@/services/article/ArticleEditService';
import EventBus from '@/EventBus';
import { SHOW_ARTICLE_TAGS_DIALOG } from '@/events';
import { Mutations } from '@/store/mutations';

const TAGS_NOT_ADDED = 'Tags not added';
@Component
export default class ArticleTagsDialog extends VueApp {
  showDialog = false;
  isEditPage = false;
  tags: string[] = [];

  $refs: {
    tagInput: any;
  };

  get articleTags() {
    return articleEditService.articleEditor.article?.tags;
  }

  get title() {
    return this.isEditPage ? 'Add Tags' : 'Article Tags';
  }

  get placeholder() {
    return this.tags.length ? '' : 'Tags';
  }

  get tagsNotAdded() {
    return !this.articleTags?.length && !this.isEditPage;
  }

  handleSave() {
    this.checkChips();
    this.showDialog = false;
  }

  async checkChips() {
    if (this.$refs.tagInput) {
      const inputValue = this.$refs.tagInput.inputValue.trim();
      this.$store.commit(Mutations.SET_ARTICLE_EDITOR_ARTICLE_TAGS, this.tags);
      if (inputValue.length > 0) {
        this.$refs.tagInput.inputValue = '';
      }

      await articleEditService.saveAsDraft();
    }
  }

  handleClose() {
    this.showDialog = false;
    this.isEditPage = false;
  }

  mounted() {
    EventBus.$off(SHOW_ARTICLE_TAGS_DIALOG);
    EventBus.$on(SHOW_ARTICLE_TAGS_DIALOG, (isEditPage: boolean) => {
      this.isEditPage = isEditPage;
      this.showDialog = true;
    });
  }

  @Watch('article.workspace', { deep: true })
  onWorkspaceChanged() {
    const workspace = articleEditService.articleWorkspace;
    if (workspace) {
      articleEditService.setState('workspace', workspace);
    }
  }

  @Watch('showDialog')
  onloadTags() {
    if (this.showDialog) {
      this.tags = this.tagsNotAdded ? [TAGS_NOT_ADDED] : [...this.articleTags];
    }
  }
}
