
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dialog, Form, FormItem, Input, Checkbox, Button } from 'element-ui';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';
import LinkDialog from '@/components/tiptap/Menu/MenuItems/Link/LinkDialog/LinkDialog.vue';

@Component({
  components: {
    LinkDialog,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    MenuButton,
  },
})
export default class AddLinkCommandButton extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  isVisible = false;

  get isActive() {
    return this.editorContext.state.isActiveLink;
  }

  addLink(data: { href: string; openInNewTab?: boolean }) {
    const { href, openInNewTab } = data;
    const target = openInNewTab ? '_blank' : null;

    this.editor.chain().focus().setLink({ href, target }).run();
    this.closeAddLinkDialog();
  }

  private openAddLinkDialog() {
    this.isVisible = true;
  }

  private closeAddLinkDialog() {
    this.isVisible = false;
  }
}
