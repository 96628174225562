
import { Component, Prop } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';

@Component({})
export default class UserImg extends VueApp {
  @Prop() classKey: number;
  @Prop() moreUsersCounter: number;
  @Prop() userLimit: number;
  @Prop() imageSrc: string;

  get imageUrl() {
    return this.imageSrc;
  }

  get checkLimitElements() {
    return this.userLimit - this.classKey > 1;
  }
}
