import Router, { RawLocation } from 'vue-router';

export class NavigationService {
  private router: Router;

  setRouter(router: any) {
    this.router = router;
  }

  navigate(route: RawLocation) {
    return this.router.push(route);
  }

  getCurrentPath() {
    return this.router.currentRoute.path;
  }
}

const navigationService = new NavigationService();
export default navigationService;
