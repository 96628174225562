import MeetingsPage from '@/components/pages/meetings/MeetingsPage.vue';
import BillingHistoryPage from '@/components/pages/settings/BillingHistoryPage/BillingHistoryPage.vue';
import CompanyNotPaid from '@/components/pages/views/CompanyNotPaid.vue';
import Vue from 'vue';
import Router from 'vue-router';
import { Route } from 'vue-router/types/router';
import * as routerNames from '@/routerNames';

import AuthMiddleware from '@/middlewares/AuthMiddleware';
import AuthTokenMiddleware from '@/middlewares/AuthTokenMiddleware';
import AdminMiddleware from '@/middlewares/AdminMiddleware';
import PaidCompanyMiddleware from '@/middlewares/PaidCompanyMiddleware';
import SyncCompanyMiddleware from '@/middlewares/SyncCompanyMiddleware';

import CheckoutPage from '@/components/pages/billing/CheckoutPage/CheckoutPage.vue';
import PlansPage from '@/components/pages/billing/PlansPage/PlansPage.vue';
import HomePage from '@/components/pages/HomePage/HomePage.vue';
import LoginSocialCallbackPage from '@/components/pages/auth/LoginSocialCallbackPage.vue';
import UserProfilePage from '@/components/pages/settings/UserProfilePage/UserProfilePage.vue';
import LoginSocialFailPage from '@/components/pages/auth/LoginSocialFailPage.vue';
import AdminPanel from '@/components/pages/admin/AdminPanel.vue';
import WorkspaceViewPage from '@/components/pages/workspace/WorkspaceViewPage/WorkspaceViewPage.vue';
import AuthLayout from '@/components/pages/auth/AuthLayout.vue';
import WorkspacesPage from '@/components/pages/workspace/WorkspacesPage.vue';
import WorkspaceEditPage from '@/components/pages/workspace/WorkspaceEditPage/WorkspaceEditPage.vue';
import DraftsPage from '@/components/pages/draft/DraftsPage.vue';
import DraftEditPage from '@/components/pages/draft/DraftEditPage.vue';
import CompanyPage from '@/components/pages/settings/CompanyPage.vue';
import ArticleEditPage from '@/components/pages/article/ArticleEditPage.vue';
import UserManagement from '@/components/pages/settings/UserManagementPage/UserManagementPage.vue';
import BrowsePage from '@/components/pages/views/BrowsePage.vue';
import CompanyGroupsPage from '@/components/pages/settings/CompanyGroupsPage.vue';
import CompanyGroupPage from '@/components/pages/settings/CompanyGroupPage.vue';
import BillingPlanDetailsPage from '@/components/pages/settings/BillingPlanDetailsPage/BillingPlanDetailsPage.vue';
import CompanyDeactivated from '@/components/pages/views/CompanyDeactivated/CompanyDeactivated.vue';
import ArticleVersionEditPage from '@/components/pages/article/ArticleVersionEditPage.vue';
import UserDeactivatedInCompany from '@/components/pages/views/UserDeactivatedInCompany.vue';
import BrowseOldPage from '@/components/pages/views/BrowseOldPage.vue';
import CompanyNotPaidForUser from '@/components/pages/views/CompanyNotPaidForUser.vue';

export const routes = [
  {
    path: '/',
    name: routerNames.HOME,
    component: HomePage,
  },
  // @deprecated route pls use next route
  {
    path: '/browseShared/sharedArticle/:sharedToken',
    name: routerNames.BROWSE_SHARED_DEPRECATED,
    component: BrowsePage,
    props: true,
  },
  {
    path: '/(browse|b)/shared/:sharedToken/:accessType(private)?',
    name: routerNames.BROWSE_SHARED,
    component: BrowsePage,
    props: true,
  },
  {
    path: '/b/:workspaceId/:workspaceSlug/:articleId?/:articleSlug?/:mode(notes|video)?',
    name: routerNames.BROWSE,
    component: BrowsePage,
    props: true,
  },
  {
    path: '/b/:workspaceId/:workspaceSlug/:articleId/:articleSlug/:paragraphSlug',
    name: routerNames.BROWSE_PARAGRAPH,
    component: BrowsePage,
    props: true,
  },
  {
    // @deprecated route
    path: '/browse/:workspaceSlug/:articleSlug?/:paragraphSlug?',
    name: routerNames.BROWSE_OLD,
    component: BrowseOldPage,
    props: true,
  },
  {
    path: '/workspaces',
    name: routerNames.WORKSPACES,
    component: WorkspacesPage,
  },
  {
    path: '/workspace/new',
    name: routerNames.WORKSPACE_CREATE,
    component: WorkspaceEditPage,
  },
  {
    path: '/workspace/:id/edit',
    name: routerNames.WORKSPACE_EDIT,
    component: WorkspaceEditPage,
  },
  {
    path: '/workspace/:id',
    name: routerNames.WORKSPACE_VIEW,
    component: WorkspaceViewPage,
    props: true,
  },
  {
    path: '/article/:id/edit/:mode(notes|video)?',
    name: routerNames.ARTICLE_EDIT,
    component: ArticleEditPage,
  },
  {
    path: '/b/:workspaceId/:workspaceSlug/:articleId?/:articleSlug?/:mode(notes|video)?/:textTabSlug',
    name: routerNames.BROWSE_TEXT_TAB,
    component: BrowsePage,
    props: true,
  },
  {
    path: '/article/:id/v/:version/edit',
    name: routerNames.ARTICLE_VERSION_EDIT,
    component: ArticleVersionEditPage,
  },
  {
    path: '/article/new/:id?',
    name: routerNames.ARTICLE_CREATE,
    component: DraftEditPage,
  },
  {
    path: '/drafts',
    name: routerNames.DRAFTS,
    component: DraftsPage,
  },
  {
    path: '/draft/:id/:mode(notes|video)?',
    name: routerNames.DRAFT_EDIT,
    component: DraftEditPage,
  },
  {
    path: '/settings/profile',
    name: routerNames.USER_PROFILE,
    component: UserProfilePage,
  },
  {
    path: '/settings/company',
    name: routerNames.COMPANY,
    component: CompanyPage,
  },
  {
    path: '/settings/users',
    name: routerNames.USER_MANAGEMENT,
    component: UserManagement,
  },
  {
    path: '/settings/groups',
    name: routerNames.COMPANY_GROUPS,
    component: CompanyGroupsPage,
  },
  {
    path: '/settings/groups/:groupId',
    name: routerNames.COMPANY_GROUP,
    component: CompanyGroupPage,
    props: true,
  },
  {
    path: '/settings/billing/plan-details',
    name: routerNames.BILLING_PLAN_DETAILS,
    component: BillingPlanDetailsPage,
    props: true,
  },
  {
    path: '/settings/billing/history',
    name: routerNames.BILLING_HISTORY,
    component: BillingHistoryPage,
    props: true,
  },
  {
    path: '/signup/:token',
    name: routerNames.SIGNUP_INVITED,
    component: AuthLayout,
    props: true,
  },
  {
    path: '/signup',
    name: routerNames.SIGNUP,
    component: AuthLayout,
  },
  {
    path: '/login/social/:token',
    name: routerNames.LOGIN_SOCIAL_CALLBACK,
    component: LoginSocialCallbackPage,
    props: true,
  },
  {
    path: '/login/social-fail/:message',
    name: routerNames.LOGIN_SOCIAL_FAIL,
    component: LoginSocialFailPage,
    props: true,
  },
  {
    path: '/login/:token?/:articleId?/:companyId?/:destination?',
    name: routerNames.LOGIN,
    component: AuthLayout,
    props: true,
  },
  {
    path: '/password-reset/:token',
    name: routerNames.PASSWORD_RESET_TOKEN,
    component: AuthLayout,
    props: true,
  },
  {
    path: '/password-reset',
    name: routerNames.PASSWORD_RESET,
    component: AuthLayout,
  },
  {
    path: '/admin/:page?/:id?',
    name: routerNames.ADMIN_PANEL,
    component: AdminPanel,
    props: true,
  },
  {
    path: '/inactive',
    name: routerNames.INACTIVE_COMPANY,
    component: CompanyDeactivated,
  },
  {
    path: '/user-deactivated',
    name: routerNames.USER_DEACTIVATED,
    component: UserDeactivatedInCompany,
  },
  // Billing
  {
    path: '/checkout',
    name: routerNames.CHECKOUT,
    component: CheckoutPage,
  },
  {
    path: '/subscription/:update?',
    name: routerNames.BILLING_PLANS,
    component: PlansPage,
    props: true,
  },
  {
    path: '/not-paid',
    name: routerNames.COMPANY_NOT_PAID,
    component: CompanyNotPaid,
  },
  {
    path: '/temporarily-deactivated',
    name: routerNames.COMPANY_NOT_PAID_FOR_USER,
    component: CompanyNotPaidForUser,
  },
  // Snaplore bot meetings
  {
    path: '/meetings',
    name: routerNames.MEETINGS,
    component: MeetingsPage,
  },
];

const router = new Router({ routes });

const originalPush = Router.prototype.push as any;
Router.prototype.push = function push(location: Route): Promise<Route> {
  return originalPush.call(this, location).catch(() => {});
};

Vue.use(Router);

// authentication guard
router.beforeEach(AuthTokenMiddleware);
router.beforeEach(AuthMiddleware);

// company guard
router.beforeEach(PaidCompanyMiddleware);
router.beforeEach(SyncCompanyMiddleware);

router.beforeEach(AdminMiddleware);

export default router;
