import { BaseModel } from '@/models/BaseModel';

export class SlackIntegration extends BaseModel {
  webhookUrl = '';
  accessToken = '';
  channel = '';
  teamName = '';

  constructor() {
    super();
  }
}
