
import '@/components/tiptap/styles/DropdownButton.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Tooltip } from 'element-ui';

@Component({
  components: {
    [Tooltip.name]: Tooltip,
  },
})
export default class MenuButton extends Vue {
  @Prop({ required: false }) readonly selected: string;

  @Prop({ required: false }) readonly icon: string;

  @Prop({ default: false }) readonly isActive: boolean;

  @Prop({ default: false }) readonly isVisible: boolean;

  @Prop({ default: false }) readonly customClass: string;

  @Prop({ required: true }) readonly content!: string;

  @Prop({ default: () => {} }) readonly command!: () => void;

  get commandButtonClass(): object {
    return {
      'el-tiptap-editor__menu-button': true,
      'el-tiptap-editor__menu-button--active': this.isActive,
    };
  }

  get dropdownButtonStyle() {
    if (this.customClass === 'fontFamily-dropdown') {
      return { fontFamily: this.selected };
    }
    return {};
  }

  onClick() {
    if (this.command) this.command();
  }
}
