import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import { UserNotification } from '@/models/UserNotification';
import apiService from '@/services/ApiService';

const isHasNew = (notifications: UserNotification[]) => {
  return !!notifications.find((notification) => notification.isNew);
};

export const notificationsSidebarActions = {
  async notificationsSidebarUpdate(context: ActionContext<State, any>) {
    try {
      if (!context.state.user) return;
      const response = await apiService.notifications.get(context.state.notificationsSidebar.lastUpdateTime);
      const newNotifications = response.data?.notifications?.map(UserNotification.parse) || [];
      const notifications = [...newNotifications, ...context.state.notificationsSidebar.notifications];

      context.commit('notificationsSidebarSetLastUpdateTime', Date.now());
      context.commit('notificationsSidebarSetNotifications', notifications);
      context.commit('setIsNewNotifications', isHasNew(notifications));
    } catch (err) {
      // Sometimes BE notification service become unavailable for a moment and there Axios timeout error
      // Silently ignore errors
    }
  },

  async notificationsSidebarMarkRead(context: ActionContext<State, any>, id: string) {
    const notifications = context.state.notificationsSidebar.notifications.map((item) => {
      if (item.id === id) {
        item.isNew = false;
      }

      return item;
    });

    context.commit('notificationsSidebarSetNotifications', notifications);
    context.commit('setIsNewNotifications', isHasNew(notifications));
  },

  async notificationsSidebarMarkAllAsRead(context: ActionContext<State, any>) {
    const notifications = context.state.notificationsSidebar.notifications.map((item) => {
      item.isNew = false;
      return item;
    });
    context.commit('notificationsSidebarSetNotifications', notifications);
    context.commit('setIsNewNotifications', false);
  },

  notificationsSidebarReset(context: ActionContext<State, any>) {
    context.commit('notificationsSidebarSetLastUpdateTime', null);
    context.commit('notificationsSidebarSetNotifications', []);
    context.commit('setIsNewNotifications', false);
  },
};
