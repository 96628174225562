
import { Component, Prop } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';

@Component({})
export default class EditableInput extends VueApp {
  @Prop() text: string;
  @Prop({ default: null }) item: Record<string, any>;

  enteredText = '';

  isTitleFieldEditable = false;
  isValid = true;

  checkDataUpdated() {
    return this.enteredText !== this.text;
  }

  checkFieldValid() {
    return !!this.enteredText;
  }

  showConfirmEditTitleButtons() {
    this.isTitleFieldEditable = true;
  }

  hideConfirmEditButtons() {
    this.isValid = true;
    this.isTitleFieldEditable = false;
  }

  cancelEditTitleField() {
    if (this.text !== this.enteredText && !!this.enteredText) {
      this.enteredText = this.text;
    }
    this.isTitleFieldEditable = false;
    this.isValid = true;
  }

  saveInputData() {
    if (!this.checkDataUpdated()) {
      this.hideConfirmEditButtons();
      return;
    }
    if (!this.checkFieldValid()) {
      this.isValid = false;
      return;
    }

    this.$emit('updateField', this.item, this.enteredText);
    this.hideConfirmEditButtons();
  }

  mounted() {
    this.enteredText = this.text;
  }
}
