
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';

@Component({
  components: {
    MenuButton,
  },
})
export default class RemoveImageCommandButton extends Vue {
  @Prop({ required: true }) editor: Editor;

  private removeImage() {
    this.editor.view.dispatch(this.editor.state.tr.deleteSelection());
  }
}
