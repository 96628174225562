
import { HOME } from '@/routerNames';
import { Component, Prop } from 'vue-property-decorator';
import FindUserDialog from '@/components/layout/dialogs/FindUserDialog.vue';
import { Group } from '@/models/Group';
import { User } from '@/models/User';
import VueApp from '@/@types/app/VueApp';

@Component({
  components: {
    FindUserDialog,
  },
})
export default class CompanyGroupPage extends VueApp {
  @Prop(String) groupId: string;
  group: Group = null;
  users: User[] = [];
  invitedUsers: User[] = [];
  loader: any = null;
  findUserDialogVisible = false;

  handleAddUser(users: string[]) {
    this.findUserDialogVisible = false;

    const promiseAddUsers = users.map((value: string) => {
      const user = User.parse(JSON.parse(value));
      if (user.id && !this.group.userIds.includes(user.id)) {
        this.group.userIds.push(user.id);
        return this.$api.groups.addUser(this.group.id, user.id);
      } else {
        this.group.addInvitedEmail(user.email);
        return this.$api.groups.addInvitedUser(this.group.id, user.email);
      }
    });

    Promise.all(promiseAddUsers)
      .then(() => {
        this.load();
      })
      .catch((err: any) => {
        this.$api.handleResponseError(err);
      });
  }

  handleRemoveUser(user: User, invited = false) {
    if (!invited) {
      this.$api.groups
        .removeUser(this.group.id, user.id)
        .then(() => {
          this.users = this.users.filter((item) => item.id != user.id);
          this.group.userIds = this.group.userIds.filter((item) => item != user.id);
        })
        .catch((res: any) => this.$api.handleResponseError(res));
    } else {
      this.$api.groups
        .removeInvitedUser(this.group.id, user.email)
        .then(() => {
          this.invitedUsers = this.invitedUsers.filter((item) => item.email != user.email);
          this.group.invitedEmails = this.group.invitedEmails.filter((item) => item != user.email);
        })
        .catch((res: any) => this.$api.handleResponseError(res));
    }
  }

  async load() {
    this.loader = this.$loading({});
    let group: Group = null;

    const isUserActive = await this.$auth.isUserActive();
    if (!isUserActive) {
      return this.$router.push({ name: HOME });
    }

    await this.$api.groups
      .get(this.groupId)
      .then((res) => {
        group = res.data.group ? Group.parse(res.data.group) : null;
        this.group = group;
      })
      .catch(this.$api.handleResponseError);
    await this.$api.groups
      .getUsers(this.groupId)
      .then((res) => {
        if (res.data.users) {
          const users: User[] = [];
          res.data.users.forEach((item: any) => {
            const user = User.parse(item);
            users.push(user);
          });
          users.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.users = users;
        }
      })
      .catch(this.$api.handleResponseError);

    await this.$api.groups
      .getInvitedUsers(this.groupId)
      .then((res) => {
        if (res.data.invitations) {
          const invitedUsers: User[] = res.data.invitations.map((item: any) => User.parse(item));
          invitedUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.invitedUsers = invitedUsers;
        }
      })
      .catch(this.$api.handleResponseError);

    this.loader.close();
    this.loader = null;
  }

  created() {
    this.load();
  }
}
