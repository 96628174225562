
import Component from 'vue-class-component';
import VueApp from '@/@types/app/VueApp';

@Component({})
export default class SearchInput extends VueApp {
  searchGroupQuery = '';

  searchGroups() {
    this.$emit('showSearchedGroups', this.searchGroupQuery);
  }
}
