
import VueApp from '@/@types/app/VueApp';
import { Component, Prop } from 'vue-property-decorator';
import UploadButton from '@/components/screen-recorder/UploadButton.vue';

@Component({
  components: { UploadButton },
})
export default class EmptyStateCard extends VueApp {
  @Prop({ type: String, required: true }) headerTitle: string;
  @Prop({ type: String }) buttonTitle: string;
  @Prop({ type: String, required: false }) secondButtonTitle: string;
  @Prop({ type: Boolean, default: false }) showButton: boolean;
  @Prop({ type: Boolean, default: false }) isTable: boolean;
  @Prop({ type: Boolean, default: false }) disabled: boolean;

  $refs: {
    uploadButton: any;
  };

  create() {
    this.$emit('create');
  }

  chooseFile() {
    this.$refs.uploadButton.chooseFile();
  }
}
