export enum ScheduledChangeActionEnum {
  Cancel = 'cancel',
  Pause = 'pause',
  Resume = 'resume',
}

export type ScheduledChangeAction = 'cancel' | 'pause' | 'resume';

export type ScheduledChange = {
  action: ScheduledChangeAction;
  effectiveAt: string;
  resumeAt: string;
};
