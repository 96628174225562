import Vue from 'vue';
import Vuex from 'vuex';

// states
import {
  State,
  appState,
  userState,
  companyState,
  sharedState,
  workspaceState,
  articleEditorState,
  dialogsState,
  newUserState,
  uiState,
  extensionState,
  commentsSidebarState,
  notificationsSidebarState,
  transcriptionSidebarState,
} from '@/store/state';

// mutations
import {
  userMutations,
  companyMutations,
  sharedMutations,
  workspaceMutations,
  articleEditorMutations,
  articleVersionMutations,
  dialogsMutations,
  appMutations,
  newGroupUserMutations,
  uiMutations,
  extensionMutations,
  commentsSidebarMutations,
  notificationsSidebarMutations,
  transcriptionSidebarMutations,
} from '@/store/mutations/index';

// actions
import {
  companyActions,
  appActions,
  articleActions,
  workspaceViewActions,
  articleEditorActions,
  articleCommentActions,
  articleVersionActions,
  uiActions,
} from '@/store/actions';
import { commentsSidebarActions } from '@/store/actions/commentsSidebarActions';
import { transcriptionSidebarActions } from '@/store/actions/transcriptionSidebarActions';

import { articleReactionsActions } from '@/store/actions/articleReactionsActions';
import { notificationsSidebarActions } from '@/store/actions/notificationsSidebarActions';

const state: State = {
  ...appState,
  ...userState,
  ...companyState,
  ...sharedState,
  ...workspaceState,
  ...dialogsState,
  ...newUserState,
  ui: uiState,
  extension: extensionState,
  articleEditor: articleEditorState,
  commentsSidebar: commentsSidebarState,
  notificationsSidebar: notificationsSidebarState,
  transcriptionSidebar: transcriptionSidebarState,
};

const mutations = {
  ...appMutations,
  ...userMutations,
  ...companyMutations,
  ...sharedMutations,
  ...workspaceMutations,
  ...articleEditorMutations,
  ...articleVersionMutations,
  ...dialogsMutations,
  ...newGroupUserMutations,
  ...uiMutations,
  ...commentsSidebarMutations,
  ...notificationsSidebarMutations,
  ...transcriptionSidebarMutations,
  ...extensionMutations,
};

const actions = {
  ...appActions,
  ...companyActions,
  ...articleActions,
  ...articleEditorActions,
  ...workspaceViewActions,
  ...articleCommentActions,
  ...articleVersionActions,
  ...articleReactionsActions,
  ...uiActions,
  ...commentsSidebarActions,
  ...notificationsSidebarActions,
  ...transcriptionSidebarActions,
};

export type Store = {
  state: State;
  mutations: typeof mutations;
  actions: typeof actions;
};

Vue.use(Vuex);
const appStore = new Vuex.Store<State>({
  state,
  mutations,
  actions,
});
export type AppStore = typeof appStore;
export default appStore;
