
import VueApp from '@/@types/app/VueApp';
import IUserCreateDialog from '@/@types/interfaces/IUserCreateDialog';
import { User } from '@/models/User';
import { Company } from '@/models/Company';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { Component } from 'vue-property-decorator';
import notificationService from '@/services/NotificationService';

@Component
export default class UserCreateDialog extends VueApp implements IUserCreateDialog {
  loader: ElLoadingComponent = null;
  showDialog = false;
  visible = false;
  user: User = new User();
  userRoles: any = Company.getRolesList();
  password = '';
  passwordConfirm = '';
  resolve: any = null;
  reject: any = null;

  async handleSave() {
    try {
      this.loader = this.$loading({
        lock: true,
      });

      const invalidForm = this.validateForm();
      if (!invalidForm) {
        return;
      }

      const response = await this.$api.company.createUser({
        ...this.user,
        password: this.password,
      });

      this.visible = false;
      this.beforeClose(User.parse(response.data.user));
      notificationService.success('User has been successfully created');
    } catch (error) {
      this.$api.handleResponseError(error);
    } finally {
      this.loader.close();
      this.loader = null;
    }
  }

  beforeClose(user: any = null) {
    this.resolve(user ? User.parse(user) : null);
    this.showDialog = false;
  }

  show(user: any = null): Promise<User> {
    this.showDialog = true;
    this.user = user ? User.parse(user) : new User();
    this.password = '';
    this.passwordConfirm = '';
    if (!this.user.role) this.user.role = 'user';

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      this.visible = true;
    });
  }

  validateForm() {
    // validate passwords
    if (this.password && this.passwordConfirm !== this.password) {
      notificationService.error('Passwords do not match');
      return false;
    }

    //validate first, last names
    if (this.user.firstName.trim().length < 2) {
      notificationService.error('First name can no be lesser than 2 words!');
      return false;
    }

    //validate first, last names
    if (this.user.lastName.trim().length < 2) {
      notificationService.error('Last name can no be lesser than 2 words!');
      return false;
    }

    return true;
  }
}
