import { User } from '@/models/User';
import { State } from '@/store/state';

export const newGroupUserMutations = {
  setUsers(state: State, users: User[]): void {
    state.addedUser = [...state.addedUser, ...users];
  },

  clearUserList(state: State): void {
    state.addedUser = [];
  },

  removeUser(state: State, user: User): void {
    state.addedUser = state.addedUser.filter((addedUser: User) => addedUser.id !== user.id);
  },
};
