export class Tooltip {
  setTitleTooltip(position: any, width: number, title: string) {
    const tooltips = document.getElementsByClassName('sidebar-tooltip');

    if (tooltips.length === 0) {
      const tip = document.createElement('div');
      const body = document.getElementsByTagName('body');
      tip.classList.add('md-tooltip');
      tip.classList.add('md-theme-default');
      tip.classList.add('sidebar-tooltip');
      tip.style.width = 'fit-content';
      tip.style.position = 'absolute';
      tip.style.top = position.top + 'px';
      tip.style.left = position.left + 'px';
      tip.innerHTML = title;
      body[0].append(tip);
    }
  }

  clearTooltips() {
    const tooltips = document.getElementsByClassName('sidebar-tooltip');
    [...tooltips].map((tip: any) => {
      tip.remove();
    });
  }
  handleFocusIn(e: any, title: string) {
    if (title.length < 20) {
      return;
    }
    const element = e.target.classList.contains('tooltip-title') ? e.target : e.target.closest('.tooltip-title');
    const elBounding = element.getBoundingClientRect();
    const sidebarWidth = document.getElementById('sidebar').offsetWidth - 10;
    const tooltipHeight = 22;
    const titleMargin = 6;
    const position = {
      top: elBounding.top - tooltipHeight - titleMargin,
      left: elBounding.left - 43,
    };
    this.setTitleTooltip(position, sidebarWidth - position.left, title);
  }
}

const tooltip = new Tooltip();

export default tooltip;
