import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios, { AxiosPromise } from 'axios';
import { AdminDashboardStatsData } from '@/@types/AdminDashboardStatsData';
import { User } from '@/models/User';

export class AdminServiceProvider extends ApiBaseServiceProvider {
  scope = 'admin';

  getCompany(id: string) {
    return axios.get(`${this.getBaseUrl()}company/${id}`, this.getRequestConfig());
  }

  getCompanyOwner(id: string): AxiosPromise<{ owner: User }> {
    return axios.get(`${this.getBaseUrl()}company/owner/${id}`, this.getRequestConfig());
  }

  getCompanyUsers(id: string): AxiosPromise<{ users: { companyUsers: User[]; invitedUsers: any } }> {
    return axios.get(`${this.getBaseUrl()}company/users/${id}`, this.getRequestConfig());
  }

  deleteCompanyUser(companyId: string, userId: string) {
    return axios.delete(`${this.getBaseUrl()}user/company`, {
      ...this.getRequestConfig(),
      data: { companyId, userId },
    });
  }

  changeUserCompanyStatus(companyId: string, id: string, userStatus: string) {
    return axios.post(
      `${this.getBaseUrl()}company/user/status/${id}`,
      {
        companyId,
        userStatus,
      },
      this.getRequestConfig()
    );
  }

  getCompanies(queryParams: {
    q?: string;
    page?: number;
    isBilling?: boolean;
    isAiEnabled?: boolean;
    isActivated?: boolean;
  }) {
    const { q = '', page = 1, isBilling, isAiEnabled, isActivated } = queryParams;

    const searchQuery = q.length ? q : '0';
    const urlParams = new URLSearchParams({
      page: page.toString(),
      ...(isBilling !== undefined && { isBilling: isBilling ? '1' : '0' }),
      ...(isAiEnabled !== undefined && { ai: isAiEnabled ? '1' : '0' }),
      ...(isActivated !== undefined && { activated: isActivated ? '1' : '0' }),
    }).toString();

    const url = `${this.getBaseUrl()}companies/search/${searchQuery}?${urlParams}`;
    return axios.get(url, this.getRequestConfig());
  }

  getStats(): AxiosPromise<AdminDashboardStatsData> {
    return axios.get(`${this.getBaseUrl()}companies/stats`, this.getRequestConfig());
  }

  getCompanyStats(id: string): AxiosPromise<AdminDashboardStatsData> {
    return axios.get(`${this.getBaseUrl()}company/stats/${id}`, this.getRequestConfig());
  }

  loginAsCompany(id: string) {
    return axios.get(`${this.getBaseUrl()}company/${id}/login`, this.getRequestConfig());
  }

  createDomainForCompany(id: string) {
    return axios.get(`${this.getBaseUrl()}company/${id}/create-domain`, this.getRequestConfig());
  }

  getJobs(queryParams: { q?: string; page?: number }) {
    const { q = '', page = 1 } = queryParams;
    const query = q.length ? q : '0';
    const url = `${this.getBaseUrl()}jobs/search/${query}?page=${page}`;
    return axios.get(url, { ...this.getRequestConfig() });
  }

  getJobsCount(): AxiosPromise<{ count: number }> {
    return axios.get(`${this.getBaseUrl()}jobs/count`, { ...this.getRequestConfig() });
  }

  deleteDoneJobs() {
    return axios.delete(`${this.getBaseUrl()}jobs/done`, this.getRequestConfig());
  }

  deleteJob(id: string) {
    return axios.delete(`${this.getBaseUrl()}jobs/${id}`, this.getRequestConfig());
  }

  resetJob(id: string) {
    return axios.patch(`${this.getBaseUrl()}jobs/${id}/reset`, {}, this.getRequestConfig());
  }

  cleanVideos() {
    return axios.get(`${this.getBaseUrl()}clean-videos-job`, this.getRequestConfig());
  }
}
