
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkspaceVisibility } from '@/other/WorkspaceVisibility';

@Component({})
export default class WorkspaceVisibilityInfo extends Vue {
  @Prop() visibility: WorkspaceVisibility;

  get isShowIcon() {
    return this.visibility !== WorkspaceVisibility.personal;
  }

  get isPrivate() {
    return this.visibility === WorkspaceVisibility.private;
  }

  get isPublic() {
    return this.visibility === WorkspaceVisibility.public;
  }

  get isOrganization() {
    return this.visibility === WorkspaceVisibility.organization;
  }
}
