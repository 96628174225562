class Helper {
  symbols = {
    '&': `&amp;`,
    '<': `&lt;`,
    '>': `&gt;`,
  };

  deepEach(root: any, cb: any) {
    const items = root.items ? root.items : root.paragraphs ? root.paragraphs : [];

    cb(root);
    if (items.length) {
      for (const key in items) {
        this.deepEach(items[key], cb);
      }
    }
  }

  getItemsKey(parent: any) {
    // eslint-disable-next-line no-prototype-builtins
    return parent.hasOwnProperty('items')
      ? 'items'
      : // eslint-disable-next-line no-prototype-builtins
      parent.hasOwnProperty('paragraphs')
      ? 'paragraphs'
      : null;
  }

  findAllByKey(obj: any, keyToFind: string): any {
    return Object.entries(obj).reduce(
      (acc, [key, value]) =>
        key === keyToFind
          ? acc.concat(value)
          : typeof value === 'object'
          ? acc.concat(this.findAllByKey(value, keyToFind))
          : acc,
      []
    );
  }

  msToTime(ms: number, displayHours = 'auto') {
    let seconds: any = Math.round(ms / 1000);
    let hours: any = Math.floor(seconds / 3600);
    let minutes: any = Math.floor((seconds - hours * 3600) / 60);
    seconds = seconds - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return (displayHours === 'auto' && hours === '00' ? '' : hours + ':') + minutes + ':' + seconds;
  }

  // noinspection JSMethodCanBeStatic
  copyToClipboard(str: string) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  // noinspection JSMethodCanBeStatic
  openFullscreen(el: any | HTMLElement): Promise<any> | null {
    if (el.requestFullscreen) {
      return el.requestFullscreen();
    } else if (el.mozRequestFullScreen) {
      return el.mozRequestFullScreen(); // Firefox
    } else if (el.webkitEnterFullScreen) {
      return el.webkitEnterFullScreen(); // MAC Safari
    } else if (el.webkitRequestFullscreen) {
      return el.webkitRequestFullscreen(); //mobile devices safari
    }
    return null;
  }

  // noinspection JSMethodCanBeStatic
  closeFullscreen() {
    const doc = document as any;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullScreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
  }

  // noinspection JSMethodCanBeStatic
  extractHostname(url: string) {
    let hostname;
    // find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    // find & remove port number
    hostname = hostname.split(':')[0];
    // find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  extractDraftId(url: string) {
    return url.split('/')[url.split('/').length - 1].split('?')[0];
  }

  observerNotify(obj: any) {
    if (obj && obj.__ob__) {
      obj.__ob__.dep.notify();
    }
  }

  searchForSelectedArticleParent(selectedArticle: HTMLElement) {
    while ([...selectedArticle.parentElement.classList].indexOf('workspace-sidebar_article-list') === -1) {
      selectedArticle = selectedArticle.parentElement;
    }

    return this.getOffsetTopNode(selectedArticle);
  }

  getOffsetTopNode(node: any) {
    return node.offsetTop;
  }

  stripHtml(htmlString: string) {
    return htmlString
      .replace(/(<([^>]+)>)/gi, ' ')
      .split(' ')
      .filter((word) => word.length && word.trim())
      .join(' ');
  }

  replaceByRegExp(text: string) {
    const regExpr = /\$|\^|\*|\(|\)|\+|\{|\}|\[|\]|\?|\||\\|\./g;
    let match: RegExpExecArray | null;
    const matches = [];
    const str = text.split('');
    while ((match = regExpr.exec(str.join(''))) !== null) {
      matches.push(match);
    }
    if (regExpr.test(text)) {
      for (let y = 0; y < matches.length; y++) {
        str.splice(matches[y].index + y, 0, `\\`);
      }
      text = str.join('');
    }
    return text;
  }

  searchAllInText(text: string, query: string) {
    let q = query;
    Object.entries(this.symbols).forEach(([key, value]) => {
      q = q.replaceAll(key, value);
    });
    q = this.replaceByRegExp(q);
    q = q.replaceAll(' ', '\\s*');
    const textRegexp = new RegExp(`${q}(?![_a-zA-Z]+>|>)`, 'gi');
    const textRegexpForSymbols = new RegExp(`</?.*?>`, 'gi');
    const matches = [];
    const matchesWithoutSymbols = [];
    let match: RegExpExecArray | null;
    let matchWS: RegExpExecArray | null;
    let newText = text;
    let iteration = 0;
    while ((matchWS = textRegexpForSymbols.exec(text)) !== null && iteration < 30) {
      iteration++;
      matchesWithoutSymbols.push(matchWS);
    }

    matchesWithoutSymbols.forEach((elements: any) => {
      let space = '';
      let i = 0;
      while (i < elements[0].length) {
        space += ' ';
        i++;
      }
      newText = newText.replaceAll(elements[0], space);
    });

    if (!q.includes('&amp;')) {
      newText = newText.replaceAll('&amp;', '     ');
    }
    if (!q.includes('&lt;')) {
      newText = newText.replaceAll('&lt;', '    ');
    }
    if (!q.includes('&gt;')) {
      newText = newText.replaceAll('&gt;', '    ');
    }

    while ((match = textRegexp.exec(newText)) !== null) {
      matches.push(match);
    }

    return matches;
  }

  searchAllInTextWithoutTags(text: string, query: string, limit = 0): RegExpExecArray[] {
    query = this.replaceByRegExp(query);
    const textRegexp = new RegExp(`${query}`, 'gi');
    const matches: RegExpExecArray[] = [];
    let match: RegExpExecArray | null;
    while ((match = textRegexp.exec(text)) !== null) {
      matches.push(match);
      if (limit > 0 && matches.length >= limit) {
        break;
      }
    }

    return matches;
  }

  replaceTextFromTo(text: string, replacement: string, start: number, length: number) {
    const betweenReplace = start + length;
    return text.substring(0, start) + replacement + text.substring(betweenReplace);
  }

  trimContentText(matchedObject: any, text: string) {
    return `${text.substr(matchedObject.index, 35)}...`;
  }

  //#end

  //#region favicon changes
  checkWindowTheme() {
    const favIcon = document.querySelector('#favicon') as HTMLAnchorElement;
    if (this.isDarkTheme() && favIcon.href !== '/snap-logo-white.svg') {
      favIcon.href = '/snap-logo-white.svg';
    } else {
      favIcon.href = '/snap-logo.png';
    }
  }

  textHandler(text: string) {
    return text.toString().toLowerCase().replace(/\s/g, '');
  }

  isDarkTheme() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  //#end

  isIOSMobileDevice() {
    return navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad');
  }

  isSafari() {
    const browsersString = navigator.userAgent.toLowerCase();
    return !(browsersString.indexOf('safari') != -1 && browsersString.indexOf('chrome') > -1);
  }

  isJustReaction(content: string) {
    return content.includes(`<p><span data-type='emoji'`) && content.length === 76;
  }
}

export default new Helper();
