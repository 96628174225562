import { Plugin } from 'prosemirror-state';
import suggestion from '@/components/tiptap/extensions/SlashCommand/suggestion';
import Suggestion from '@tiptap/suggestion';
import { Extension } from '@tiptap/core';
import GROUPS from '@/components/tiptap/extensions/SlashCommand/groups';

const extensionName = 'slashCommand';

const _slash = Extension.create({
  name: extensionName,

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        char: '/',
        allowSpaces: true,
        startOfLine: true,
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const isParagraph = $from.parent.type.name === 'paragraph';
          const isStartOfNode = $from.parent.textContent?.charAt(0) === '/';
          const isInColumn = this.editor.isActive('column');

          const afterContent = $from.parent.textContent?.substring($from.parent.textContent?.indexOf('/'));
          const isValidAfterContent = !afterContent?.endsWith('  ');

          return (
            ((isParagraph && isStartOfNode) || (isInColumn && isParagraph && isStartOfNode)) && isValidAfterContent
          );
        },
        command: ({ editor, props }) => {
          const { view, state } = editor;
          const { $head, $from } = view.state.selection;

          const end = $from.pos;
          const from = $head?.nodeBefore
            ? end - ($head.nodeBefore.text?.substring($head.nodeBefore.text?.indexOf('/')).length ?? 0)
            : $from.start();

          const tr = state.tr.deleteRange(from, end);
          view.dispatch(tr);

          props.action(editor);
          view.focus();
        },
        items: ({ query }: { query: string }) => {
          const withFilteredCommands = GROUPS.map((group) => ({
            ...group,
            commands: group.commands
              .filter((item) => {
                const labelNormalized = item.label.toLowerCase().trim();
                const queryNormalized = query.toLowerCase().trim();

                if (item.aliases) {
                  const aliases = item.aliases.map((alias) => alias.toLowerCase().trim());
                  return labelNormalized.includes(queryNormalized) || aliases.includes(queryNormalized);
                }

                return labelNormalized.includes(queryNormalized);
              })
              .filter((command) => (command.shouldBeHidden ? !command.shouldBeHidden(this.editor) : true)),
          }));

          const withoutEmptyGroups = withFilteredCommands.filter((group) => {
            if (group.commands.length > 0) {
              return true;
            }

            return false;
          });
          return withoutEmptyGroups;
        },
      }) as unknown as Plugin,
    ];
  },
});

const Slash = _slash.configure({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  suggestion,
});

export default Slash;
