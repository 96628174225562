import { Editor } from '@tiptap/core';
import { nanoid } from 'nanoid';
import { Logger } from '@/other/Logger';

const log = new Logger('TipTap:editor');

export class TipTapEditorService {
  protected editorsMap: { [key: string]: Editor } = {};

  registerEditor = (key: string, editor: Editor) => {
    log.info(`Editor registered #${key}`);
    this.editorsMap[key] = editor;
    return editor;
  };

  generateKey = () => nanoid(10);

  destroyEditor = (editorKey: string) => {
    this.editorsMap[editorKey].destroy();
    log.info(`editor destroyed #${editorKey}`);
  };

  get = (key: string) => this.editorsMap[key];
}

const tipTapEditorService = new TipTapEditorService();
export default tipTapEditorService;
