import apiService from '@/services/ApiService';
import { Editor } from '@tiptap/vue-2';
import { Plugin, PluginKey } from 'prosemirror-state';

const allowedImageTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];

const FileHandlerPlugin = (editor: Editor) =>
  new Plugin({
    key: new PluginKey('FileHandler'),
    props: {
      handleDOMEvents: {
        dragover(view, event) {
          // Prevent the default behavior to allow drop
          event.preventDefault();
          return true;
        },
        drop(view, event) {
          event.preventDefault();
          // Extract the dropped files
          const files = event.dataTransfer.files;
          // Check if any files were dropped
          if (files.length > 0) {
            // Iterate through each file
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              // Check if the file is allowed
              if (allowedImageTypes.includes(file.type)) {
                // Create a FileReader to read the image file
                const reader = new FileReader();
                const { clientX, clientY } = event;

                // Convert the drop coordinates to a document position
                const pos = view.posAtCoords({ left: clientX, top: clientY }).pos;

                reader.onload = async () => {
                  // Upload image and get URL
                  const response = await apiService.image.upload(file);
                  const src = response.data.url;

                  editor.chain().focus().insertContentAt(pos, { type: 'image', attrs: { src } }).run();
                };
                reader.readAsDataURL(file);
              }
            }
          }
          return true;
        },
        paste(view, event) {
          const items = event.clipboardData.items;
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.kind === 'file') {
              const file = item.getAsFile();
              if (file && allowedImageTypes.includes(file.type)) {
                const reader = new FileReader();

                // Get the current position of the selection
                const pos = view.state.selection.anchor;

                reader.onload = async () => {
                  // Upload image and get URL
                  const response = await apiService.image.upload(file);
                  const src = response.data.url;

                  editor.chain().focus().insertContentAt(pos, { type: 'image', attrs: { src } }).run();
                };
                reader.readAsDataURL(file);
                // Prevent the default paste behavior for files
                event.preventDefault();
                return true;
              }
            }
          }
          return false;
        },
      },
    },
  });

export default FileHandlerPlugin;
