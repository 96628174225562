
import { Component, Prop } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';

import './ProgressBar.scss';
import Loader from '@/components/Loader/Loader.vue';

@Component({
  components: { Loader },
})
export default class ProgressBar extends VueApp {
  @Prop() uploadProgress: number;
  @Prop() isProcessing: boolean;

  get progress() {
    return this.uploadProgress;
  }
}
