import { GlobalSearchServiceProvider } from '@/services/api-providers/GlobalSearchServiceProvider';
import { MeetingsServiceProvider } from '@/services/api-providers/MeetingsServiceProvider';
import { SocketServiceProvider } from '@/services/api-providers/SocketServiceProvider';
import axios from 'axios';
import notificationService from '@/services/NotificationService';
import navigationService from '@/services/NavigationService';
import extensionService from '@/services/ExtensionService';
import authService from './AuthService';
import { WorkspaceServiceProvider } from './api-providers/WorkspaceServiceProvider';
import { BrowseServiceProvider } from './api-providers/BrowseServiceProvider';
import { TagsServiceProvider } from './api-providers/TagsServiceProvider';
import { ArticleServiceProvider } from './api-providers/ArticleServiceProvider';
import { CommentServiceProvider } from '@/services/api-providers/CommentServiceProvider';
import { DraftArticleServiceProvider } from './api-providers/DraftArticleServiceProvider';
import { CompanyServiceProvider } from './api-providers/CompanyServiceProvider';
import { AdminServiceProvider } from './api-providers/AdminServiceProvider';
import { GroupsServiceProvider } from '@/services/api-providers/GroupsServiceProvider';
import { SharedArticleServiceProvider } from '@/services/api-providers/SharedArticleServiceProvider';
import { LoginResponse, SignupResponse } from '@/@types/responses/AuthResponses';
import { Logger } from '@/other/Logger';
import { Error404, Error422, Error403, Error400, ApiError } from '@/@types/Errors';
import { CompanyUserStatus } from '@/@types/enums/CompanyUserStatus';
import * as routerNames from '@/routerNames';
import { ImageServiceProvider } from '@/services/api-providers/ImageServiceProvider';
import { VideoUploadServiceProvider } from '@/services/api-providers/VideoUploadServiceProvider';
import { FileAttachmentServiceProvider } from '@/services/api-providers/FileAttachmentServiceProvider';
import { GetCurrentUserResponse } from '@/@types/responses/UserResponses';
import { CompanyResponse } from '@/@types/responses/CompanyResponses';
import { AnyJson } from '@/@types/AnyJson';
import { ArticleReactionsServiceProvider } from '@/services/api-providers/ArticleReactionsServiceProvider';
import { NotificationsServiceProvider } from '@/services/api-providers/NotificationsServiceProvider';
import Cookies from 'js-cookie';
import { BotConfig } from '@/models/forms/BotConfig';
import { BillingServiceProvider } from './api-providers/BillingServiceProvider';

const log = new Logger('ApiService');

export class ApiService {
  authToken: string | null = null;
  baseUrl = process.env.VUE_APP_SERVER_URL;
  company = new CompanyServiceProvider(this);
  workspace = new WorkspaceServiceProvider(this);
  browse = new BrowseServiceProvider(this);
  article = new ArticleServiceProvider(this);
  comment = new CommentServiceProvider(this);
  sharedArticle = new SharedArticleServiceProvider(this);
  draft = new DraftArticleServiceProvider(this);
  tags = new TagsServiceProvider(this);
  groups = new GroupsServiceProvider(this);
  admin = new AdminServiceProvider(this);
  image = new ImageServiceProvider(this);
  videoUpload = new VideoUploadServiceProvider(this);
  fileAttachment = new FileAttachmentServiceProvider(this);
  articleReactions = new ArticleReactionsServiceProvider(this);
  notifications = new NotificationsServiceProvider(this);
  socket = new SocketServiceProvider(this);
  billing = new BillingServiceProvider(this);
  globalSearch = new GlobalSearchServiceProvider(this);
  meetings = new MeetingsServiceProvider(this);

  constructor() {
    this.authToken = this.getTokenFromCookies();
  }

  getTokenFromCookies() {
    return Cookies.get('SecureAuthToken');
  }

  private getDomain(): string {
    const hostParts = window.location.hostname.split('.');
    return hostParts.length > 1 ? `.${hostParts.slice(-2).join('.')}` : window.location.hostname;
  }

  setAuthToken(token: string | null) {
    this.authToken = token;
    const domain = this.getDomain();

    if (token) {
      Cookies.set('SecureAuthToken', token, { domain, path: '/' });
    } else {
      // Remove cookie for specific domain
      Cookies.remove('SecureAuthToken', { path: '/' });

      // Remove cookie for all subdomains
      Cookies.remove('SecureAuthToken', { domain, path: '/' });

      // Remove secure cookies if present
      Cookies.remove('SecureAuthToken', { domain, path: '/', secure: true });
    }
  }

  getToken() {
    return Cookies.get('SecureAuthToken');
  }

  getRequestConfig() {
    return this.authToken
      ? {
          headers: { Authorization: 'bearer ' + this.getToken() },
        }
      : {};
  }

  redirect(url: string): void {
    document.location.href = this.baseUrl + '/' + url;
  }

  login(email: string, password: string) {
    return axios.post<LoginResponse>(this.baseUrl + '/login', { email, password });
  }

  signup(data: any) {
    return axios.post<SignupResponse>(this.baseUrl + '/signup', data);
  }

  signupInvited(key: string, data: any) {
    return axios.post(this.baseUrl + '/invited/' + key, data);
  }

  getInvitationInfo(key: string) {
    return axios.get(this.baseUrl + '/invited/' + key, this.getRequestConfig());
  }

  resetPassword(email: string) {
    return axios.post(this.baseUrl + '/password-reset', {
      email,
    });
  }

  resetPasswordToken(token: string, password: string) {
    return axios.post(this.baseUrl + '/password-reset-token', {
      token,
      password,
    });
  }

  getUser() {
    return axios.get<GetCurrentUserResponse>(this.baseUrl + '/user/me', this.getRequestConfig());
  }

  updateUser(data: any) {
    return axios.put(this.baseUrl + '/user/me', data, this.getRequestConfig());
  }

  // COMPANY
  getCompanyById(id: string) {
    return axios.get(this.baseUrl + '/company/' + id + '/info', this.getRequestConfig());
  }

  updateCompany(data: { title: string; logo: string }) {
    return axios.put(this.baseUrl + '/company', data, this.getRequestConfig());
  }

  getCompanyInvitedUsers() {
    return axios.get<{ items: AnyJson[] }>(this.baseUrl + '/company/users/invited', this.getRequestConfig());
  }

  changeCompanyRole(userId: any, role: any) {
    return axios.put(
      this.baseUrl + '/company/user/' + userId + '/role',
      {
        role,
      },
      this.getRequestConfig()
    );
  }

  removeCompanyUser(id: any) {
    return axios.delete(this.baseUrl + '/company/user/' + id, this.getRequestConfig());
  }

  changeUserStatus(userId: string, status: CompanyUserStatus) {
    return axios.put(
      this.baseUrl + '/company/user/' + userId + '/status',
      {
        status,
      },
      this.getRequestConfig()
    );
  }

  removeInvitation(id: any) {
    return axios.delete(this.baseUrl + '/company/invite/' + id, this.getRequestConfig());
  }

  // ARTICLES
  getArticles() {
    return axios.get(this.baseUrl + '/article', this.getRequestConfig());
  }

  deleteArticle(id: any) {
    return axios.delete(this.baseUrl + '/article/' + id, this.getRequestConfig());
  }

  handleArchiveArticle(articleIds: any, isArchived: boolean) {
    return axios.put(this.baseUrl + '/article/archived', { articleIds, isArchived }, this.getRequestConfig());
  }

  // WORKSPACE
  getWorkspaces(withUsers = false) {
    return axios.get(this.baseUrl + '/workspace' + (withUsers ? '?withUsers=1' : ''), this.getRequestConfig());
  }

  searchUsers(q: string, invited = false) {
    return axios.get(
      this.baseUrl + '/user/search/' + encodeURIComponent(q) + (invited ? '?invited=1' : ''),
      this.getRequestConfig()
    );
  }

  getDomainCompany(domain: string) {
    return axios.get<{ company: CompanyResponse }>(
      this.baseUrl + '/domain/' + encodeURIComponent(domain),
      this.getRequestConfig()
    );
  }

  disableSlackIntegration(workspaceId: string) {
    return axios.get(this.baseUrl + '/integration/slack/' + workspaceId + '/disconnect', this.getRequestConfig());
  }

  // Bot endpoints
  createBotConfig(config: BotConfig) {
    return axios.post(this.baseUrl + '/bot-config', { config }, this.getRequestConfig());
  }

  getBotConfig(userId: string) {
    return axios.get<{ config: BotConfig | undefined }>(
      this.baseUrl + '/bot-config/' + userId,
      this.getRequestConfig()
    );
  }

  // helper function
  handleResponseError(res: any) {
    let message = 'Connection error';

    if (res.response) {
      if (res.response.data.company) {
        return navigationService.navigate({ name: routerNames.INACTIVE_COMPANY });
      }
      if (res.response.status === 401) {
        authService.logout();
        extensionService.logOutUser();
        return navigationService.navigate({ name: routerNames.LOGIN });
      }

      if (res.response.data && res.response.data.error) {
        message = res.response.data.error;
      } else if (res.response.data && res.response.data.errors) {
        const keys = Object.keys(res.response.data.errors);
        message = res.response.data.errors[keys[0]];
      } else {
        message = 'Request error: #' + res.response.status + ' ' + res.response.statusText;
      }

      this.errorStatusHandler(res.response.status, res.response.data, message);
    } else if (res.message) {
      message = res.message;
      log.error(message);
    } else {
      log.error(res);
    }
    notificationService.error(message);
  }

  errorStatusHandler(status: number, data: any, message: string) {
    let error;
    switch (status) {
      case 400:
        error = new Error400(message);
        break;
      case 403:
        error = new Error403(message);
        if (data.userInactiveInCompany) {
          if (data.user.companyIds.length) {
            authService.logout();
            extensionService.logOutUser();
            return navigationService.navigate({ name: routerNames.LOGIN });
          }
          return navigationService.navigate({ name: routerNames.USER_DEACTIVATED });
        }
        break;
      case 404:
        error = new Error404(message);
        break;
      case 422:
        error = new Error422(message);
        break;
      default:
        error = new ApiError(message);
    }
    if (error) {
      log.error(error);
    }
  }
}

const apiService = new ApiService();
export default apiService;
