import { ArticlePdfExportSocketAction } from '@/services/socket/actions/ArticlePdfExportSocketAction';
import { SocketBaseServiceProvider } from '@/services/socket/SocketBaseServiceProvider';
import { SocketService } from '@/services/socket/SocketService';

export class PdfSocketFeature extends SocketBaseServiceProvider {
  private articlePdfExportAction: ArticlePdfExportSocketAction;

  constructor(socket: SocketService) {
    super(socket);
    this.articlePdfExportAction = new ArticlePdfExportSocketAction(socket);
  }

  exportArticlePdf = async (articleId: string, textTabId?: string): Promise<void> => {
    return this.articlePdfExportAction.exportArticlePdf(articleId, textTabId);
  };
}
