
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui';
import { Editor } from '@tiptap/core';
import DropdownButton from '@/components/tiptap/Menu/DropdownButton.vue';
import { TipTapEditorContext } from '@/components/tiptap/types/TipTapEditorContext';
import { Level } from '@tiptap/extension-heading';

@Component({
  components: {
    DropdownButton,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
})
export default class MenuHeadingDropdown extends Vue {
  @Prop({ required: true }) editorContext: TipTapEditorContext;
  @Prop({ required: true }) editor: Editor;

  NORMAL_TEXT = 'Normal text';
  headingLevel = 0;
  isVisible = false;

  private get level() {
    return 5;
  }

  get selectedTitle() {
    return this.headingLevel < 1 ? `${this.NORMAL_TEXT}` : `Heading ${this.headingLevel}`;
  }

  isHeadingActive(level?: number): boolean {
    const map = [
      this.editorContext.state.isActiveHeading1,
      this.editorContext.state.isActiveHeading2,
      this.editorContext.state.isActiveHeading3,
      this.editorContext.state.isActiveHeading4,
      this.editorContext.state.isActiveHeading5,
    ];
    return level ? map[level - 1] : this.editorContext.state.isActiveHeading;
  }

  handleVisibleChange(visible: boolean) {
    this.isVisible = visible;
  }

  updateHeadingLevel(level: Level) {
    level > 0
      ? this.editor.chain().focus().setHeadingAndClearMarks({ level }).run()
      : this.editor.chain().focus().setParagraph().run();
    this.headingLevel = level;
  }

  @Watch('editorContext.state')
  onChangeHeading() {
    if (this.editor.isActive('paragraph')) {
      this.headingLevel = 0;
    } else if (this.editorContext.state.isActiveHeading1) {
      this.headingLevel = 1;
    } else if (this.editorContext.state.isActiveHeading2) {
      this.headingLevel = 2;
    } else if (this.editorContext.state.isActiveHeading3) {
      this.headingLevel = 3;
    } else if (this.editorContext.state.isActiveHeading4) {
      this.headingLevel = 4;
    } else if (this.editorContext.state.isActiveHeading5) {
      this.headingLevel = 5;
    }
  }
}
