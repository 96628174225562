import { Store } from 'vuex';
import { State } from '@/store/state';
import { Paragraph } from '@/models/article/Paragraph';
import { dispatchCreateNewComment } from '@/store/dispatchers/articleCommentsDispatchers';
import {
  commitSetCommentsSidebarIsActiveTimeTag,
  commitSetCommentsSidebarIsVisible,
  commitSetCommentsSidebarVideoReaction,
} from '@/store/commits/commentsSidebarCommits';
import { ArticleComment } from '@/models/article/ArticleComment';
import { RecordingReaction } from '@/@types/RecordingReaction';
import articleEditService from '@/services/article/ArticleEditService';
import { Article } from '@/models/article/Article';
import notificationService from '@/services/NotificationService';
import { ArticleCommentVideoReaction } from '@/@types/ArticleCommentVideoReaction';
import { User } from '@/models/User';
import { dispatchCreateArticleReaction } from '@/store/dispatchers/articleReactionsDispatchers';

export class ArticleCommentService {
  public store: Store<State>;

  setStore = (store: Store<State>) => {
    this.store = store;
  };

  get videoPlayerRef() {
    return this.store.state.videoPlayerRef;
  }

  get selectedParagraph(): Paragraph {
    return this.store.state.workspaceView.selectedParagraph;
  }

  get selectedArticle(): Article {
    return this.store.state.workspaceView.selectedArticle;
  }

  async handleAddReactionOnVideo(emoji: string) {
    const { paragraphTime, currentTime } = this.videoPlayerRef;
    const articleId = this.selectedArticle.id;
    const paragraphId = this.selectedParagraph.id;
    const recordingReaction: RecordingReaction = {
      articleId,
      paragraphId,
      commentId: null,
      time: paragraphTime,
      icon: emoji,
    };
    const comment = new ArticleComment();
    comment.content = `<p>${emoji}</p>`;
    if (paragraphId) {
      comment.timestamp = currentTime;
      comment.videoReaction = {
        articleId,
        paragraphId,
        currentParagraphTime: paragraphTime,
        currentVideoTime: currentTime,
        icon: emoji,
      };
    }

    const articleComment = await dispatchCreateNewComment(comment);

    recordingReaction.commentId = articleComment?.id;

    await this.setCommentsSidebarVideoReaction(null, recordingReaction);

    if (!this.selectedParagraph.id) {
      this.showNotificationRepublishTheArticle(
        'To make the reaction appear on the timeline, please republish the recording'
      );
    }
  }

  async handleAddCommentOnVideo() {
    await articleEditService.stopVideo();
    const { paragraphTime, currentTime } = this.videoPlayerRef;
    const articleId = this.selectedArticle?.id;
    const paragraphId = this.selectedParagraph?.id;
    const commentReaction = {
      articleId,
      paragraphId,
      currentParagraphTime: paragraphTime,
      currentVideoTime: currentTime,
      icon: 'comment',
    };

    await this.setCommentsSidebarVideoReaction(commentReaction, null);

    commitSetCommentsSidebarIsVisible(true);
  }

  hasUniqParagraphKey() {
    const html = this.selectedArticle.text;
    const firstTag = html.split('>')[0];

    return firstTag.includes('key=');
  }

  async setCommentsSidebarVideoReaction(
    commentReaction: ArticleCommentVideoReaction,
    recordingReaction: RecordingReaction
  ) {
    const paragraphId = this.selectedParagraph.id;
    if (!paragraphId) {
      // videoReaction state couldn't be undefined to focus input
      const emptyVideoReaction = {
        articleId: null,
        paragraphId: null,
        currentParagraphTime: null,
        currentVideoTime: null,
        icon: null,
      } as ArticleCommentVideoReaction;
      return commitSetCommentsSidebarVideoReaction(emptyVideoReaction);
    } else if (commentReaction) {
      commitSetCommentsSidebarVideoReaction(commentReaction);
      commitSetCommentsSidebarIsActiveTimeTag(true);
      return;
    } else if (recordingReaction) {
      await dispatchCreateArticleReaction(recordingReaction);
    }
  }

  showNotificationRepublishTheArticle(message: string, duration = 6000) {
    notificationService.warning(message, duration);
  }

  getAuthorName(comment: ArticleComment) {
    const user: User = this.store.state.companyUsers.find((user: User) => user.id === comment.userId) ?? null;
    return user?.name ?? comment.author;
  }
}

const articleCommentService = new ArticleCommentService();
export default articleCommentService;
