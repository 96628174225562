import { Extension } from '@tiptap/core';

export const OnPressEnter = Extension.create({
  name: 'OnPressEnter',
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const selection = editor.state.selection;
        const marks = selection.$head.nodeBefore?.marks || [];
        const setMarks = () => {
          if (marks.length) {
            const tr = editor.view.state.tr.setStoredMarks(marks);
            editor.view.dispatch(tr);
          }
        };

        const isSuccess = editor.chain().splitListItem('listItem').run();
        if (!isSuccess) {
          setTimeout(setMarks, 0);
          return false;
        }

        setMarks();
        return true;
      },
    };
  },
});

export default OnPressEnter;
