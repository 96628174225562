import store from '@/store';

class BillingService {
  redirectToCheckout(transactionId: string) {
    const company = store.state.company;
    let baseUrl;
    if (company?.domain) {
      const billingHostname = process.env.VUE_APP_BILLING_HOSTNAME.trim();
      baseUrl = `https://${billingHostname}`;
    } else {
      baseUrl = `http://localhost:${process.env.VUE_APP_PORT}`;
    }

    window.location.href = `${baseUrl}/#/checkout?transactionId=${transactionId}`;
  }
}

const billingService = new BillingService();
export default billingService;
