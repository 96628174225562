export type ArticleVersionsDialogState = {
  isVisible: boolean;
  articleId?: string;
};

export interface DialogsState {
  articleVersionsDialog: ArticleVersionsDialogState;
}

export const dialogsState: DialogsState = {
  articleVersionsDialog: { isVisible: false, articleId: undefined },
};
