import { State } from '@/store/state';

export const userMutations = {
  setUser(state: State, user: any) {
    state.user = user;
  },
  setUserCompanies(state: State, companies: any) {
    state.userCompanies = companies;
  },
  setUserInactiveInCompany(state: State, status: boolean) {
    state.isUserInactiveInCompany = status;
  },
};
