
import VueApp from '@/@types/app/VueApp';
import { Component, Watch } from 'vue-property-decorator';
import SidebarArticleItem from '../SidebarArticleItem/SidebarArticleItem.vue';
import WorkspaceToolbar from './WorkspaceToolbar.vue';
import { Article } from '@/models/article/Article';
import EventBus from '@/EventBus';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import articleEditService from '@/services/article/ArticleEditService';
import ArticlesReorder from '../ArticlesReorder.vue';
import { commitSetArticleIdToExpand, commitSetArticleMode } from '@/store/commits/sharedCommits';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import '@/scss/sidebar-menu.scss';
import './WorkspaceSidebar.scss';
import { dispatchGetArticleCommentsCount } from '@/store/dispatchers/articleCommentsDispatchers';
import { CHECK_PARAGRAPH_PROGRESS, PARAGRAPH_PROGRESS_UPDATE } from '@/events';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import { Workspace } from '@/models/Workspace';
import { WorkspaceView } from '@/@types/WorkspaceView';
import { WorkspaceOrder } from '@/@types/WorkspaceOrder';

@Component({
  components: {
    SidebarArticleItem,
    ArticlesReorder,
    WorkspaceToolbar,
  },
})
export default class WorkspaceSidebar extends VueApp {
  article: Article;
  hasArticles = -1;
  toggle: false;
  paragraphProgress = 0;
  nestedArticlesData: {
    workspace: Workspace;
    workspaceView: WorkspaceView;
    showArchivedArticles: boolean;
    articleMode: ArticleEditMode;
    isMobileView: boolean;
  };

  get workspace(): Workspace {
    return this.$store.state.workspaceView.workspace;
  }

  get workspaceView(): WorkspaceView {
    return workspaceService.workspaceView;
  }

  get workspaceOrder(): WorkspaceOrder[] {
    if (this.workspaceView.workspace) {
      return this.workspaceView.workspace.order;
    }
    return null;
  }

  get showArchivedArticles(): boolean {
    return this.workspaceView.showArchivedArticles;
  }

  get articleMode(): ArticleEditMode {
    return this.$store.state.articleMode;
  }

  get isMobileView(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get isReorderMode(): boolean {
    return this.$store.state.isReorderMode;
  }

  get articleEditor() {
    return this.$store.state.articleEditor;
  }

  get isWorkspaceArchived(): boolean {
    const workspace = this.$store.state.workspaceView.workspace ?? null;
    if (workspace) {
      return !!workspace.isArchived;
    }
    return null;
  }

  get isArticleVersionIsShowFooter(): boolean {
    return this.$store.state.articleVersionView.isShowFooter;
  }

  handleSave() {
    EventBus.$emit('save-reorder-articles');
  }

  handleCancel() {
    EventBus.$emit('close-reorder-articles');
  }

  findArticleInOrder(order: any) {
    const foundArticle = this.$store.state.workspaceView.articles.filter((article: Article) => {
      return article.id === order.value;
    });
    if (foundArticle.length > 0) {
      return foundArticle[0];
    }
    return null;
  }

  getTotalHeight() {
    requestAnimationFrame(() => {
      const list = this.$refs.articlesList as any;

      if (list) {
        const litsHeight = list.$el.scrollHeight;
        const minDraggableAreaHeight = litsHeight + 83; // height of: workspaceName 64px, lable 'article' 19px
        EventBus.$emit('min-draggable-area-height-changed', minDraggableAreaHeight);
      }
    });
  }

  onExpandSelectedArticles(article?: Article) {
    if (this.workspaceView) {
      const rootArticle = article || this.workspaceView.selectedArticle;
      if (!this.workspaceView.workspace) return;
      const workspaceOrder =
        this.workspaceView && this.workspaceView.workspace && this.workspaceView.workspace.order
          ? this.workspaceView.workspace.order
          : null;
      if (workspaceOrder && rootArticle) {
        this.workspaceView.expandedArticleIds = articleEditService.findArticlesToExpand(workspaceOrder, rootArticle.id);
      }
    }
  }

  setNestedArticlesData() {
    this.nestedArticlesData = {
      workspace: this.workspace,
      workspaceView: this.workspaceView,
      showArchivedArticles: this.showArchivedArticles,
      articleMode: this.articleMode,
      isMobileView: this.isMobileView,
    };
  }

  toggleArticleMode(mode: ArticleEditMode) {
    commitSetArticleMode(mode);
  }

  handleParagraphProgressChange(progress = 0) {
    this.paragraphProgress = progress;
  }

  created() {
    this.setNestedArticlesData();
    EventBus.$on(PARAGRAPH_PROGRESS_UPDATE, this.handleParagraphProgressChange);
    EventBus.$emit(CHECK_PARAGRAPH_PROGRESS);
  }

  mounted() {
    this.onExpandSelectedArticles();
  }

  updated() {
    this.setNestedArticlesData();
  }

  beforeDestroy() {
    EventBus.$off(PARAGRAPH_PROGRESS_UPDATE, this.handleParagraphProgressChange);
  }

  handleDataForSidebarItem() {}

  @Watch('workspaceView.articles', { deep: false })
  onArticlesChanged(articles: Article[]) {
    this.hasArticles = articles.length;
    this.onExpandSelectedArticles();
    // timeout to ensure all already rendered
    this.getTotalHeight();
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.getTotalHeight();
    EventBus.$emit('scroll-to-article');
  }

  @Watch('workspaceView.selectedArticle')
  onSelectedArticleChanged(article: Article) {
    const articleIdToExpand = this.$store.state.articleIdToExpand;
    if (article) {
      /**
       * need set timeout for searching article in the same workspace and
       * emitting event after updating the selectedArticle property
       */
      setTimeout(() => {
        EventBus.$emit('scroll-to-article');
      }, 10);
      if (articleIdToExpand === article.shortId || articleIdToExpand === article.id) {
        this.onExpandSelectedArticles(article);
        commitSetArticleIdToExpand(null);
      }
      dispatchGetArticleCommentsCount();
    }
  }
}
