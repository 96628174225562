
import ArticlePublishNotificationContent from '@/components/layout/sidebars/NotificationsSidebar/notifications/ArticlePublishNotificationContent.vue';
import { Component, Vue } from 'vue-property-decorator';
import NotificationsSidebarNotification from '@/components/layout/sidebars/NotificationsSidebar/NotificationsSidebarNotification.vue';
import ArticleMentionNotificationContent from '@/components/layout/sidebars/NotificationsSidebar/notifications/ArticleMentionNotificationContent.vue';
import CommentMentionNotificationContent from '@/components/layout/sidebars/NotificationsSidebar/notifications/CommentMentionNotificationContent.vue';
import ArticleSharedNotificationContent from '@/components/layout/sidebars/NotificationsSidebar/notifications/ArticleSharedNotificationContent.vue';
import { UserNotification } from '@/models/UserNotification';
import { dispatchNotificationsSidebarMarkRead } from '@/store/dispatchers/notificationsSidebarDispatchers';
import urlService from '@/services/UrlService';
import { Article } from '@/models/article/Article';
import { Workspace } from '@/models/Workspace';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import apiService from '@/services/ApiService';
import EmptyNotificationsSidebar from '@/components/layout/sidebars/NotificationsSidebar/EmptyNotificationsSidebar.vue';
import { UserNotificationType } from '@/@types/UserNotification';

@Component({
  components: { EmptyNotificationsSidebar, NotificationsSidebarNotification },
})
export default class NotificationsSidebarFactory extends Vue {
  get notifications() {
    return this.$store.state.notificationsSidebar.notifications;
  }

  async onClick(item: UserNotification) {
    if (item.isNew) {
      await dispatchNotificationsSidebarMarkRead(item.id);
      await apiService.notifications.markReadById(item.id);
    }
    this.$store.commit('notificationsSidebarSetIsVisible', false);

    const payload = item.payload;
    const article = Article.fromJson({
      title: payload.articleTitle,
      shortId: payload.articleShortId,
      id: payload.articleId,
    });
    const workspace = Workspace.parse({ title: payload.workspaceTitle, shortId: payload.workspaceShortId });

    let url = '';

    switch (item.type) {
      case UserNotificationType.ArticleMention: {
        url = urlService.getArticleViewUrl(article, workspace, ArticleEditMode.NOTES);
        break;
      }
      case UserNotificationType.ArticlePublish: {
        url = urlService.getArticleViewUrl(article, workspace);
        break;
      }
      case UserNotificationType.CommentMention: {
        if (item.payload.isDraft) {
          url = `${urlService.getDraftViewUrl(article)}?showComment=${payload.commentId}`;
        } else {
          url = `${urlService.getArticleViewUrl(article, workspace)}?showComment=${payload.commentId}`;
        }
        break;
      }
      case UserNotificationType.ArticleShared: {
        const baseUrl = location.href.replace(location.hash, '');
        url = `${baseUrl}#/b/shared/${payload.sharedToken}/private`;
        break;
      }
    }

    document.location.href = url;
  }

  getNotificationContentComponent(type: UserNotificationType) {
    const map = {
      [UserNotificationType.ArticleMention]: ArticleMentionNotificationContent,
      [UserNotificationType.ArticlePublish]: ArticlePublishNotificationContent,
      [UserNotificationType.CommentMention]: CommentMentionNotificationContent,
      [UserNotificationType.ArticleShared]: ArticleSharedNotificationContent,
    };

    return map[type];
  }
}
