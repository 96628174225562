import { User } from './User';
import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';
import { CompanyRole } from '@/@types/CompanyRole';
import { Business, Subscription } from '@/@types/billing';
import { SubscriptionType } from '@/@types/enums/SubscriptionType';
import { PAID_STATUSES } from '@/constants/BillingConsts';

export class Company implements Model {
  static parse(data: unknown): Company {
    return modelService.create(Company, data);
  }

  static getRolesList() {
    return {
      admin: 'Administrator',
      moderator: 'User Manager',
      user: 'Team Member',
    };
  }

  id: string | null = null;
  title = '';
  slug = '';
  roles: CompanyRole[] | null = null;
  user: string | null = null;
  logo: string | null = null;
  logoPreview: string | null = null;
  domain = '';
  createdAt = 0;
  isActivated: boolean | null = false;
  isAiEnabled: boolean | null = false;
  isBilling = false;
  paddle: {
    status: SubscriptionType | null;
    subscriptionId: Subscription['id'] | null;
    businessId: Business['id'] | null;
  } = null;

  afterLoad(): void {}

  canEdit(user: User) {
    return this.user === user.id || user.role === 'owner' || user.role === 'admin' || user.role === 'moderator';
  }

  get isPaid() {
    return !this.isBilling ? true : !this.paddle.status ? false : PAID_STATUSES.includes(this.paddle.status);
  }

  getDomainAddress() {
    const defaultDomain: string = process.env.VUE_APP_URL;
    return this.domain ? 'https://' + this.domain : defaultDomain;
  }

  setUserStatus(userID: string, status: string) {
    const roles: CompanyRole[] = this.roles;

    const items = roles.filter((item: CompanyRole) => item.user === userID);
    items.forEach((e: any) => (e.status = status));
  }

  getUserStatus(userID: string) {
    const roles: CompanyRole[] = this.roles;
    let status = null;

    const item = roles.filter((role: CompanyRole) => role.user === userID)[0];

    if (item) {
      status = item.status;
    }

    return status;
  }
}
