import Debugger from 'debug';

export class DefaultController {
  static debuggersMap: any = {};

  vp: any;
  name: string;

  constructor(videoPlayer: any) {
    this.name = 'DefaultController';
    this.vp = videoPlayer;
  }

  onTimeUpdate(globalTime: number, videoTime = 0) {
    this.log('Time update ' + globalTime + ' ' + videoTime);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onUserChangedTime(ms: number) {
    return;
  }

  onPlay() {
    return;
  }

  onStop() {
    return;
  }

  onVideoEnded(tt: number) {
    const videoEl = this.vp.$refs.video;
    const playbackState = this.vp.playbackState;
    const ms = playbackState.offset + Math.max(Math.round(videoEl.currentTime * 1000), this.vp.playbackState.duration);
    // video ended so we need to start next part if possible
    this.log('Video ended vt=' + videoEl.currentTime * 1000 + '\tTotalTime= ' + tt);

    if (ms < this.vp.article.duration) {
      this.log('Continue play from ' + (ms + 1));
      this.vp.setTimeInternal(ms + 1);
      this.vp.play();
    } else {
      // fix actual duration of the article
      this.vp.$set(this.vp.article, 'duration', tt);
    }
  }

  emitEvent(name: string, data: any = null) {
    this.vp.emitControllerEvent(name, data);
  }

  log(...args: any[]) {
    if (!DefaultController.debuggersMap[this.name]) {
      DefaultController.debuggersMap[this.name] = Debugger(this.name);
    }
    const debug = DefaultController.debuggersMap[this.name];

    debug(...args);
  }
}
