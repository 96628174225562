import uniqKeyAttributeParse from '@/components/tiptap/extensions/UniqKey/uniqKeyAttributeParse';
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight';

const CodeBlock = CodeBlockLowlight.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : {}),
      key: {
        default: null,
        parseHTML: uniqKeyAttributeParse,
        renderHTML: (attributes) => ({ key: attributes.key }),
        keepOnSplit: false,
      },
    };
  },
});

export default CodeBlock;
