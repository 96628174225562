import { Company } from '@/models/Company';
import { Workspace } from '@/models/Workspace';
import axios from 'axios';
import { AnyJson } from '@/@types/AnyJson';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import { CompanyResponse, CreateCompanyResponse } from '@/@types/responses/CompanyResponses';

export class CompanyServiceProvider extends ApiBaseServiceProvider {
  scope = 'company';

  switch(id: string) {
    return axios.get<{ token: string; company: CompanyResponse }>(
      `${this.getBaseUrl()}switch/${id}`,
      this.getRequestConfig()
    );
  }

  createUser(user: any) {
    return axios.post(this.getBaseUrl() + 'user', user, this.getRequestConfig());
  }

  inviteUser(data: any) {
    return axios.post<{ success: boolean }>(this.getBaseUrl() + 'invite', data, this.getRequestConfig());
  }

  get() {
    return axios.get(this.getBaseUrl(), this.getRequestConfig());
  }

  getWorkspaces(companyId: string) {
    return axios.get<{ workspaces: Pick<Workspace, 'id' | 'title'>[] }>(
      this.getBaseUrl() + `workspaces/editable/${companyId}`,
      this.getRequestConfig()
    );
  }

  getPaidStatus() {
    return axios.get<Company['paddle']>(this.getBaseUrl() + 'paid-status', this.getRequestConfig());
  }

  getBySlug(slug: string) {
    return axios.get(this.getBaseUrl() + slug + '/slug', this.getRequestConfig());
  }

  slug(id: string) {
    return axios.get<{ slug: string }>(this.getBaseUrl() + 'slug/' + id, this.getRequestConfig());
  }

  create(company: { title: string }) {
    return axios.post<CreateCompanyResponse>(this.getBaseUrl(), company, this.getRequestConfig());
  }

  delete(id: string) {
    return axios.delete(this.getBaseUrl('admin') + this.scope + '/' + id, this.getRequestConfig());
  }

  changeStatus(id: string, status: any) {
    return axios.post(this.getBaseUrl('admin') + this.scope + '/' + id + '/status', status, this.getRequestConfig());
  }

  changeBillingStatus(id: string, status: { status: boolean }) {
    return axios.post<{ status: boolean }>(
      this.getBaseUrl('admin') + this.scope + '/' + id + '/status/billing',
      status,
      this.getRequestConfig()
    );
  }

  changeAiStatus(id: string, status: any) {
    return axios.post(this.getBaseUrl('admin') + this.scope + '/' + id + '/status/ai', status, this.getRequestConfig());
  }

  getCompanyStatus() {
    return axios.get(this.getBaseUrl() + 'status', this.getRequestConfig());
  }

  getUsers() {
    return axios.get<{ users: AnyJson[] }>(`${this.getBaseUrl()}users`, this.getRequestConfig());
  }
}
