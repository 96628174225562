import axios from 'axios';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import { AnyJson } from '@/@types/AnyJson';

export class GroupsServiceProvider extends ApiBaseServiceProvider {
  scope = 'group';

  all() {
    return axios.get<{ groups: AnyJson[] }>(this.getBaseUrl(), this.getRequestConfig());
  }

  get(id: string) {
    return axios.get(this.getBaseUrl() + id, this.getRequestConfig());
  }

  update(id: string, data: any) {
    return axios.put(this.getBaseUrl() + id, data, this.getRequestConfig());
  }

  delete(id: string) {
    return axios.delete(this.getBaseUrl() + id, this.getRequestConfig());
  }

  create(title: string) {
    return axios.post(this.getBaseUrl(), { title }, this.getRequestConfig());
  }

  find(q: string) {
    return axios.get(this.getBaseUrl() + 'search/' + encodeURIComponent(q), this.getRequestConfig());
  }

  getUsers(groupId: string) {
    return axios.get(this.getBaseUrl() + groupId + '/users', this.getRequestConfig());
  }

  getInvitedUsers(groupId: string) {
    return axios.get(this.getBaseUrl() + groupId + '/invited-users', this.getRequestConfig());
  }

  addUser(groupId: string, userId: string) {
    return axios.post(
      this.getBaseUrl() + groupId + '/user',
      {
        userId,
      },
      this.getRequestConfig()
    );
  }

  addInvitedUser(groupId: string, email: string) {
    return axios.post(
      this.getBaseUrl() + groupId + '/invited-user',
      {
        email: email,
      },
      this.getRequestConfig()
    );
  }

  removeUser(groupId: string, userId: string) {
    return axios.delete(this.getBaseUrl() + groupId + '/user/' + userId, this.getRequestConfig());
  }

  removeInvitedUser(groupId: string, email: string) {
    return axios.delete(this.getBaseUrl() + groupId + '/invited-user/' + email, this.getRequestConfig());
  }
}
