
import VueApp from '@/@types/app/VueApp';
import { Component } from 'vue-property-decorator';
import ArticleShareSettings from '@/components/article-editor/ArticleShareSettings/ArticleShareSettings.vue';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';
import {
  dispatchCloseArticleVersionMode,
  dispatchDeleteArticleVersion,
  dispatchRestoreArticleVersion,
} from '@/store/dispatchers/articleVersionDispatchers';

@Component({
  components: {
    ArticleShareSettings,
    UserProfilePhoto,
  },
})
export default class ArticleVersionsFooter extends VueApp {
  get articleMode() {
    return this.$store.state.articleMode;
  }

  get workspaceView() {
    return this.$store.state.workspaceView;
  }

  get articleVersionView() {
    return this.$store.state.articleVersionView;
  }

  async handleBackToTheLastVersion() {
    const { articleId } = this.articleVersionView;
    await dispatchCloseArticleVersionMode(articleId);
  }

  async handleRestoreArticle() {
    await dispatchRestoreArticleVersion();
  }

  async handleClickDelete() {
    await dispatchDeleteArticleVersion();
  }
}
