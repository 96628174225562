
import VueApp from '@/@types/app/VueApp';
import { Component, Watch } from 'vue-property-decorator';
import EventBus from '@/EventBus';
import { User } from '@/models/User';
import { ArticleComment } from '@/models/article/ArticleComment';
import {
  commitResetCommentsSidebarQuote,
  commitResetCommentsSidebarVideoReaction,
  commitSetCommentsSidebarIsVisible,
  commitSetCommentsSidebarScrollToCommentId,
} from '@/store/commits/commentsSidebarCommits';
import EmptyCommentCard from '@/components/layout/cards/EmptyCommentCard/EmptyCommentCard.vue';
import CommentCard from '@/components/layout/cards/CommentCard.vue';
import CommentsInput from '@/components/layout/inputs/CommentsInput/CommentsInput.vue';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import { dispatchCommentsSidebarShowMentionDetails } from '@/store/dispatchers/commentsSidebarDispatchers';
import handleOutsideClickDirective from '@/directives/handleOutsideClickDirective';
import '@/scss/material/sidebar/comments-sidebar.scss';

const SCROLL_TO_COMMENT_TIMEOUT = 3000;

@Component({
  components: {
    CommentsInput,
    CommentCard,
    EmptyCommentCard,
  },
  directives: {
    'click-outside': handleOutsideClickDirective(() => {
      commitSetCommentsSidebarIsVisible(false);
    }, [
      'comments-toggle',
      'send-icon',
      'el-button--',
      'delete',
      'cancel',
      'el-message-box',
      'comment-card',
      'tiptap-emoji',
      'add-comment-button',
      'tiptap-time-tag__icon_button',
      'tiptap-mention-list',
      'tiptap-mention-details',
    ]),
  },
})
export default class CommentsSidebar extends VueApp {
  commentsSidebarWidth = 442;
  commentsSidebarMaxAllowedWidth = 442;
  scrollToCommentTimeout: number = null;

  isVisible = false;

  $refs: {
    commentsContainer: VueApp;
  };

  get isActive(): boolean {
    return this.$store.state.commentsSidebar.isVisible;
  }

  get isExtensionBannerVisible() {
    return this.$store.state.ui.isExtensionBannerVisible;
  }

  get isAddQuote() {
    return !!this.$store.state.commentsSidebar.quote;
  }

  get isVideoReaction(): boolean {
    return !!this.$store.state.commentsSidebar.videoReaction;
  }

  get commentsCount(): number {
    return workspaceService.workspaceView.commentsCount;
  }

  get allComments(): ArticleComment[] {
    return this.$store.state.commentsSidebar.comments;
  }

  get articleComments(): ArticleComment[] {
    return this.allComments
      .filter((comment: ArticleComment) => !comment.parentId)
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  }

  get user(): User {
    return this.$store.state.user;
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get commentsSidebarStyles() {
    return {
      top: !this.isMobile && this.isExtensionBannerVisible ? '100px' : '60px',
    };
  }

  repliesByParentId(commentId: string): ArticleComment[] | [] {
    return this.allComments.filter(({ parentId }) => parentId && parentId === commentId);
  }

  closeSidebar(): void {
    commitSetCommentsSidebarIsVisible(false);
  }

  scrollToComment() {
    const container = this.$refs.commentsContainer?.$el as HTMLDivElement;
    const { scrollToCommentId } = this.$store.state.commentsSidebar;
    if (!scrollToCommentId || !container) return;

    const commentElement = container.querySelector<HTMLDivElement>('div[data-comment-id="' + scrollToCommentId + '"]');
    if (commentElement) {
      const parentElement = commentElement.parentElement;
      const offsetTop = parentElement.classList.contains('comment-card__wrapper')
        ? commentElement.offsetTop + parentElement.offsetTop
        : commentElement.offsetTop;

      container.scrollTo(0, offsetTop - container.offsetHeight / 2 + commentElement.offsetHeight);
      window.clearTimeout(this.scrollToCommentTimeout);
      this.scrollToCommentTimeout = window.setTimeout(() => {
        commitSetCommentsSidebarScrollToCommentId(undefined);
      }, SCROLL_TO_COMMENT_TIMEOUT);
    }
  }

  handleClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target && target.className === 'tiptap-mention') {
      dispatchCommentsSidebarShowMentionDetails(target);
    }
    return false;
  }

  updateAnimation() {
    window.requestAnimationFrame(() => {
      this.isVisible = this.isActive;
    });
  }

  created() {
    // @todo roman pls move to separate const. as we agreed before...
    EventBus.$on('side-bars-width-changed', (newWidth: number) => {
      if (newWidth >= this.commentsSidebarMaxAllowedWidth) {
        this.commentsSidebarWidth = newWidth;
      }
    });
  }

  mounted() {
    this.scrollToComment();
    this.$refs.commentsContainer.$el.addEventListener('click', this.handleClick);

    if (this.isActive) {
      this.updateAnimation();
    }
  }

  destroyed() {
    commitSetCommentsSidebarScrollToCommentId(undefined);
    commitResetCommentsSidebarQuote();
    commitResetCommentsSidebarVideoReaction();
  }

  @Watch('$route.path')
  closeSidebarOnRouteChange() {
    commitSetCommentsSidebarIsVisible(false);
  }

  @Watch('allComments')
  onCommentsChanged() {
    this.scrollToComment();
  }

  @Watch('isActive')
  onIsActiveChanged() {
    this.updateAnimation();
  }

  @Watch('$store.state.commentsSidebar.scrollToCommentId')
  onScrollToCommentIdChanged(value: string) {
    if (value) {
      this.scrollToComment();
    }
  }
}
