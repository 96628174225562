
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dialog, Form, FormItem, Input, Checkbox, Button } from 'element-ui';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { Editor } from '@tiptap/core';
import LinkDialog from '@/components/tiptap/Menu/MenuItems/Link/LinkDialog/LinkDialog.vue';

@Component({
  components: {
    LinkDialog,
    MenuButton,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
})
export default class EditLinkCommandButton extends Vue {
  @Prop({ required: true }) editor: Editor;

  href = '';
  target = '';
  isVisible = false;

  private updateLinkAttrs(data: { href: string; openInNewTab?: boolean }) {
    const { href, openInNewTab } = data;
    this.editor.chain().focus().unsetLink();
    this.editor
      .chain()
      .focus()
      .setLink({ href, target: openInNewTab ? '_blank' : null })
      .run();
    this.closeEditLinkDialog();
  }

  private openEditLinkDialog() {
    const { href, target } = this.editor.getAttributes('link');
    this.href = href.toString();
    this.target = target;
    this.isVisible = true;
  }

  private closeEditLinkDialog() {
    this.isVisible = false;
  }
}
