import store from '../store';
import { Mutations } from '@/store/mutations';

const MOBILE_VIEW_WIDTH = 992;

export class MobileResponsiveService {
  get isMobileView(): boolean {
    return store.state.isMobile;
  }

  get windowInnerHeight(): number {
    return store.state.innerHeight;
  }

  setMobileViewProperties() {
    this.setIsMobileView(window.innerWidth);
    this.setWindowInnerHeight(window.innerHeight);
  }

  protected setIsMobileView(innerWidth: number) {
    const isMobileView = innerWidth <= MOBILE_VIEW_WIDTH;
    store.commit(Mutations.SET_IS_MOBILE, isMobileView);
  }

  protected setWindowInnerHeight(height: number) {
    store.commit(Mutations.SET_INNER_HEIGHT, height);
  }
}
const mobileResponsiveService = new MobileResponsiveService();
export default mobileResponsiveService;
