import { commitSetDraftsCount } from '@/store/commits/uiCommits';
import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import confirmationService from '@/services/ConfirmationService';
import { DeletedItemType } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemType';
import { DeletedItemStyleClass } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemStyleClass';
import { Loading } from 'element-ui';
import apiService from '@/services/ApiService';
import { Article } from '@/models/article/Article';
import articleEditService from '@/services/article/ArticleEditService';
import urlService from '@/services/UrlService';
import navigationService from '@/services/NavigationService';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { commitSetSelectedArticle, commitSetWorkspaceViewArticles } from '@/store/commits/workspaceViewCommits';
import {
  dispatchGetDraftsCount,
  dispatchGetMeetingsCount,
  dispatchGetPersonalArticlesCount,
} from '@/store/dispatchers/uiDispatchers';
import workspaceService from '@/services/workspace/WorkspaceService';
import store from '@/store';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';

export const articleActions = {
  async deleteDraftArticle({ state }: ActionContext<State, {}>, draftId: string) {
    try {
      await apiService.draft.delete(draftId);
      commitSetDraftsCount(state.ui.draftsCount - 1);
      await Promise.all([dispatchGetDraftsCount(), dispatchGetMeetingsCount()]);
    } catch (err) {
      apiService.handleResponseError(err);
    }
  },
  async transferArticle(
    { state }: ActionContext<State, {}>,
    { article, companyId, workspaceId }: { article: Article; companyId: string; workspaceId: string }
  ) {
    const loader = Loading.service({ lock: true, text: 'The article is being transferred' });

    try {
      const res = await apiService.article.transfer(article.id, { companyId, workspaceId });

      const updatedOrder = workspaceService.removeArticleFromOrder(article.id, state.workspace.order);

      const success = res.data.success;

      if (success) {
        await apiService.workspace.updateArticlesOrder(state.workspace.id, updatedOrder);

        await dispatchGetDraftsCount();
        await dispatchGetPersonalArticlesCount();
        await workspaceService.loadWorkspaceById(state.workspace.id);
        await navigationService.navigate(urlService.getWorkspaceViewRoute(state.workspace));

        const articles = state.workspaceView.articles;

        commitSetWorkspaceViewArticles(articles);

        const firstArticle = articles[0];
        if (!firstArticle) return commitSetSelectedArticle(null);
        if (firstArticle) {
          commitSetArticleMode(firstArticle.isVideoArticle() ? ArticleEditMode.VIDEO : ArticleEditMode.NOTES);
          commitSetSelectedArticle(firstArticle);
        }
      }
    } catch (err: any) {
      apiService.handleResponseError(err);
    } finally {
      loader.close();
    }
  },
  async deleteArticle({ state }: ActionContext<State, {}>, article: Article) {
    const deleteArticleIds = articleEditService.findNestedArticles(state.workspace.order, article.id);
    if (!deleteArticleIds || !deleteArticleIds.length) return;
    const result = await confirmationService
      .showRemovePopup(article.title, DeletedItemType.ARTICLE, DeletedItemStyleClass.ARTICLE)
      .catch(() => 'cancel');

    if (result !== 'confirm') return;

    const loader = Loading.service({ lock: true, text: 'The article is being deleted' });

    const promisesDelete = deleteArticleIds.map((articleId) => apiService.deleteArticle(articleId));
    const updatedOrder = workspaceService.removeArticleFromOrder(article.id, state.workspace.order);
    try {
      await Promise.all(promisesDelete);
      await apiService.workspace.updateArticlesOrder(state.workspace.id, updatedOrder);

      await Promise.all([dispatchGetDraftsCount(), dispatchGetMeetingsCount(), dispatchGetPersonalArticlesCount()]);
      await workspaceService.loadWorkspaceById(state.workspace.id);
      await navigationService.navigate(urlService.getWorkspaceViewRoute(state.workspace));

      if (state.workspaceView.articles) {
        commitSetWorkspaceViewArticles(state.workspaceView.articles);
        const firstArticleId = updatedOrder.length
          ? updatedOrder[0]?.value ?? null
          : state.workspaceView.articles[0]?.id ?? null;
        const articlesCopy = [...state.workspaceView.articles];
        const article = articlesCopy.find((article) => article.id === firstArticleId);
        if (!article) return commitSetSelectedArticle(null);
        commitSetArticleMode(article.isVideoArticle() ? ArticleEditMode.VIDEO : ArticleEditMode.NOTES);
        commitSetSelectedArticle(article);
      }
    } catch (err: any) {
      apiService.handleResponseError(err);
    } finally {
      loader.close();
    }
  },
  updateArticleMode({ state }: ActionContext<State, {}>, article: Article) {
    const firstTextTab = article.textTabs[0];
    if (article.pinnedNote) {
      const selectedTextTab = store.state.articleEditor.selectedTextTab;
      const pinnedTextTab = article.textTabs.find((tab) => tab.id === article.pinnedNote);
      const tab = pinnedTextTab ? pinnedTextTab : firstTextTab;
      dispatchUpdateSelectedTextTab(!selectedTextTab.slug ? tab : selectedTextTab.tab);
      return commitSetArticleMode(ArticleEditMode.NOTES);
    }
    if (article.isVideoArticle() && state.articleMode !== ArticleEditMode.NOTES) {
      return commitSetArticleMode(ArticleEditMode.VIDEO);
    } else {
      if (!store.state.articleEditor.selectedTextTab.slug) dispatchUpdateSelectedTextTab(firstTextTab);
      return commitSetArticleMode(ArticleEditMode.NOTES);
    }
  },
};
