import authService from '@/services/AuthService';
import store from '@/store';
import { Store } from 'vuex';
import { State } from '@/store/state';
import { PostMessage } from '@/other/PostMessage';
import { Article } from '@/models/article/Article';
import { ExtensionMessageType } from '@/@types/enums/ExtensionMessageType';
import { Logger } from '@/other/Logger';

const log = new Logger('App:ExtensionService');

type PostMessageListener = (message: PostMessage) => void;

class ExtensionService {
  store: Store<State>;

  protected onMessageListeners: PostMessageListener[] = [];

  constructor() {
    window.addEventListener('message', this.onExtensionMessageReceived.bind(this));
  }

  setStore = (store: Store<State>) => {
    this.store = store;
  };

  logInUser(token: string) {
    const companyDomain = this.store.state.company?.domain || null;
    const user = authService.user ? authService.user.toObject() : null;

    if (!user) return;
    const data = {
      user,
      token,
      companyDomain,
    };
    const messageData = new PostMessage(ExtensionMessageType.SET_LOGGED_USER_APP, data);
    this.sendMessage(messageData);
  }

  logOutUser() {
    this.sendMessage(new PostMessage(ExtensionMessageType.SET_LOGOUT_APP, null));
  }

  sendMessage = (message: PostMessage) => {
    window.postMessage(message, '*');
  };

  openRecordingWindow() {
    const extension = store.state.extension;

    if (extension.id) {
      new PostMessage(ExtensionMessageType.START_RECORDING_FROM_APP).send();
    } else {
      alert('EXTENSION MISSING TODO');
    }
  }

  openRecordingsPage() {
    const extension = store.state.extension;

    if (extension.baseUrl) {
      window.open(`${extension.baseUrl}settings.html`);
    } else {
      //@todo MV3 implement
      alert('EXTENSION MISSING TODO');
    }
  }

  /**
   * Send notification to extension to update recording information in extension
   * @param originalArticleId
   * @param article
   */
  notifyArticlePublished(originalArticleId: string, article: Article) {
    this.sendMessage(
      new PostMessage(ExtensionMessageType.ARTICLE_PUBLISHED, {
        articleId: originalArticleId,
        article,
      })
    );
  }

  addMessageListener(listener: PostMessageListener) {
    this.onMessageListeners.push(listener);
    log.info('Extension message listener added');
  }

  removeMessageListener(listener: PostMessageListener) {
    this.onMessageListeners = this.onMessageListeners.filter((item) => item !== listener);
  }

  private onExtensionMessageReceived(event: MessageEvent) {
    if (!event.data) return;

    const postMessage = PostMessage.parse(event.data);
    if (postMessage.environment !== process.env.VUE_APP_APP_ENV) return;
    if (postMessage.isSourceFrontend()) return;
    log.info(`Message <<< ${JSON.stringify(postMessage)}`);

    this.onMessageListeners.forEach((listener) => listener(postMessage));
  }

  updateExtensionInformation() {
    new PostMessage(ExtensionMessageType.GET_EXTENSION_INFORMATION).send();
  }
}

const extensionService = new ExtensionService();
export default extensionService;
