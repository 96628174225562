
import { Component, Prop, Watch } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import Search from '../inputs/GlobalSearch.vue';
import EventBus from '@/EventBus';
import UserMenu from './UserMenu.vue';
import Logo from '../shared/Logo.vue';
import { ARTICLE_CREATE, TRIGGER_SEARCH_INPUT } from '@/events';
import { BILLING_PLANS, CHECKOUT, HOME, INACTIVE_COMPANY } from '@/routerNames';
import ExtensionBanner from '@/components/layout/header/ExtensionBanner.vue';
import { User } from '@/models/User';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import { Mutations } from '@/store/mutations';
import { commitToggleMainSidebar } from '@/store/commits/uiCommits';
import NotificationButton from '@/components/layout/header/NotificationButton/NotificationButton.vue';

const EXTENSION_BANNER_DELAY = 5000;

let handleFocusOut: any;
@Component({
  components: {
    NotificationButton,
    UserMenu,
    Search,
    Logo,
    ExtensionBanner,
  },
  directives: {
    'focus-out': {
      bind: (el: any, binding: any, vnode: any) => {
        handleFocusOut = (event: any) => {
          const isClickedInside = event.target.classList.contains('userMenu');
          const isMobileMenuOpen = vnode.context.isMenuOpened;
          const isButtonTriggers = event.target.classList.contains('user-menu-trigger');

          if (isButtonTriggers) {
            return;
          }

          if (isMobileMenuOpen && !isClickedInside) {
            vnode.context.toggleUserMenu();
          }
        };
        if (vnode.context.isMobile) {
          document.body.addEventListener('click', handleFocusOut);
        }
      },
      unbind: () => {
        document.body.removeEventListener('click', handleFocusOut);
      },
    },
  },
})
export default class Header extends VueApp {
  @Prop({ type: Boolean, default: false }) isSidebar: boolean;

  hideLinks = false;
  isMenuOpened = false;
  windowWidth: number;

  get user(): User {
    return this.$store.state.user;
  }

  get isUserInactiveInCompany() {
    return this.$store.state.isUserInactiveInCompany;
  }

  get company() {
    if (this.$store.state.pageCompany) {
      return this.$store.state.pageCompany;
    } else if (this.$store.state.company) {
      return this.$store.state.company;
    }
    return null;
  }

  get isCompanyLoading() {
    return this.$store.state.isCompanyLoading;
  }

  get isBillingPage() {
    return this.$route.name === CHECKOUT || this.$route.name === BILLING_PLANS;
  }

  get isCompanyPaid() {
    return this.company?.isPaid;
  }

  get isHome() {
    return this.$route.name === HOME;
  }

  get isGuest() {
    return this.$auth.isGuest();
  }

  get isCompanyDeactivated(): boolean {
    return !this.$store.state.company.isActivated;
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get isExtensionEnabled(): boolean {
    return this.$store.state.extension.isEnabled;
  }

  async checkCompanyActivatedStatus() {
    try {
      const company = await this.$api.company.getCompanyStatus();
      if (company && !company.data.isActivated) {
        this.$store.commit(Mutations.SET_COMPANY_STATUS, company.data.isActivated);
        return false;
      }
      return true;
    } catch (err: any) {
      if (err.response.data && err.response.data.company) {
        this.$store.commit(Mutations.SET_COMPANY_STATUS, err.response.data.company.isActivated);
      }
      this.$api.handleResponseError(err);
      return false;
    }
  }

  async handleCreateArticle() {
    const companyDeactivated = !(await this.checkCompanyActivatedStatus());
    if (companyDeactivated) {
      return this.$router.push({ name: INACTIVE_COMPANY });
    }
    EventBus.$emit(ARTICLE_CREATE);
  }

  async handleAddWorkspace() {
    const companyDeactivated = !(await this.checkCompanyActivatedStatus());
    if (companyDeactivated) {
      return this.$router.push({ name: INACTIVE_COMPANY });
    }
    this.$store.commit('setIsWorkspaceCreateDialogVisible', true);
  }

  async updateExtensionBanner() {
    this.$store.commit('setIsExtensionBannerVisible', !this.$store.state.extension.id && this.user);
  }

  //responsive handlers
  toggleUserMenu() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  handleWindowResize() {
    this.windowWidth = window.innerWidth;
  }

  toggleMobileSidebar() {
    commitToggleMainSidebar();
  }

  created() {
    setTimeout(() => this.updateExtensionBanner(), EXTENSION_BANNER_DELAY);
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  }

  mounted() {
    EventBus.$on(TRIGGER_SEARCH_INPUT, (trigger: boolean) => {
      this.hideLinks = trigger;
    });
  }

  beforeDestroy() {
    EventBus.$off(TRIGGER_SEARCH_INPUT);
  }

  @Watch('isExtensionEnabled')
  onExtensionStatusChanged(value: boolean) {
    if (value) {
      this.updateExtensionBanner();
    }
  }
}
