import apiService from '@/services/ApiService';
import VueApp from '@/@types/app/VueApp';
import { Workspace } from '@/models/Workspace';
import { commitSetWorkspaces } from '@/store/commits/workspaceCommit';

class GlobalEventHandler {
  app: VueApp;
  constructor() {
    this.app = null;
  }

  setApp(app: VueApp) {
    this.app = app;
  }

  register(eventBus: any) {
    eventBus.$on('workspace-delete', this.updateWorkspaces);
    eventBus.$on('workspace.create', this.updateWorkspaces);
    eventBus.$on('workspace.update', this.updateWorkspaces);
  }

  clear(eventBus: any) {
    eventBus.$off('workspace-delete', this.updateWorkspaces);
    eventBus.$off('workspace.create', this.updateWorkspaces);
    eventBus.$off('workspace.update', this.updateWorkspaces);
  }

  updateWorkspaces = (workspace?: Workspace, resolve?: any) => {
    apiService
      .getWorkspaces()
      .then((resp: any) => {
        const workspaces = resp.data.items.map((item: Workspace) => Workspace.parse(item));

        commitSetWorkspaces(workspaces);

        if (resolve) {
          //for edited workspace to redirect
          resolve(resp.data.items.find((item: Workspace) => workspace.id === item.id));
        }
      })
      .catch((err: any) => {
        this.app.$api.handleResponseError(err);
      });
  };
}

const instance = new GlobalEventHandler();

export default instance;
