const handleOutsideClickDirective = (
  callback: Function,
  ignoreClasses: string[] = [],
  ignoreIds: string[] = [],
  rootComponentRef = ''
) => {
  let handleOutsideClick: (Event: MouseEvent) => void;
  return {
    bind: (el: HTMLElement, binding: any, vnode: any) => {
      handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const rootComponent = rootComponentRef ? vnode.context.$refs[rootComponentRef] : null;

        if (rootComponent && rootComponent.contains(target)) {
          return;
        }

        // Check if the click target or any of its parents match the ignore conditions
        let currentElement: HTMLElement | null = target;
        while (currentElement) {
          if (
            el === currentElement ||
            el.contains(currentElement) ||
            ignoreClasses.some((ignoredClass) => currentElement?.classList?.contains(ignoredClass)) ||
            ignoreIds.some((ignoredId) => currentElement?.id === ignoredId)
          ) {
            return;
          }
          currentElement = currentElement.parentElement;
        }

        callback();
      };
      document.body.addEventListener('click', handleOutsideClick);
    },
    unbind: () => {
      document.body.removeEventListener('click', handleOutsideClick);
    },
  };
};

export default handleOutsideClickDirective;
