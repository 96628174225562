
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkspaceVisibility } from '@/other/WorkspaceVisibility';

@Component({})
export default class WorkspaceTopbarIcon extends Vue {
  @Prop({ type: String }) type: WorkspaceVisibility;

  get isPublic() {
    return this.type === WorkspaceVisibility.public;
  }

  get isOrganization() {
    return this.type === WorkspaceVisibility.organization;
  }
}
