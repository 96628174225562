
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Popover } from 'element-ui';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';
import CreateTablePopover from './CreateTablePopover.vue';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';

@Component({
  components: {
    MenuButton,
    CreateTablePopover,
    [Popover.name]: Popover,
  },
})
export default class TablePopover extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  private popoverVisible = false;

  private get isTableActive() {
    return this.editorContext.state.isActiveTable;
  }

  private get enableMergeCells() {
    return this.editorContext.state.isCanMergeCells;
  }

  private get enableSplitCell() {
    return this.editorContext.state.isCanSplitCell;
  }

  hidePopover(): void {
    this.popoverVisible = false;
  }

  createTable({ row, col }: { row: number; col: number }) {
    this.editor.chain().focus().insertTable({ rows: row, cols: col, withHeaderRow: true }).run();

    this.hidePopover();
  }

  addColumnBefore() {
    this.editor.chain().focus().addColumnBefore().run();
  }

  addColumnAfter() {
    this.editor.chain().focus().addColumnAfter().run();
  }

  addRowBefore() {
    this.editor.chain().focus().addRowBefore().run();
  }

  addRowAfter() {
    this.editor.chain().focus().addRowAfter().run();
  }

  mergeCells() {
    this.editor.chain().focus().mergeCells().run();
  }

  splitCell() {
    this.editor.chain().focus().splitCell().run();
  }

  deleteColumn() {
    this.editor.chain().focus().deleteColumn().run();
  }

  deleteRow() {
    this.editor.chain().focus().deleteRow().run();
  }

  deleteTable() {
    this.editor.chain().focus().deleteTable().run();
  }
}
