
import VueApp from '@/@types/app/VueApp';
import { Component, Prop } from 'vue-property-decorator';
import { TextTab } from '@/models/article/TextTab';
import articleEditService from '@/services/article/ArticleEditService';
import { commitSetArticleEditorTextTabs } from '@/store/commits/articleEditorCommits';
import confirmationService from '@/services/ConfirmationService';
import { DeletedItemStyleClass } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemStyleClass';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { ArticleEditMode } from '@/@types/ArticleEditMode';

@Component
export default class ArticleTextTabButton extends VueApp {
  loader: ElLoadingComponent = null;

  @Prop({ type: Boolean, default: false }) isActive: boolean;
  @Prop({ type: Boolean, default: false }) isAllowPin: boolean;
  @Prop({ type: Boolean, default: false }) isPinned: boolean;
  @Prop({ type: Boolean, default: false }) isEditMode: boolean;
  @Prop({ type: Boolean, default: false }) isReordering: boolean;
  @Prop({ type: TextTab, required: true }) textTab: TextTab;
  @Prop(String) title: string;

  isRenaming = false;
  changedTitle = '';

  get articleEditMode() {
    return this.$store.state.articleMode;
  }

  get trimmedTitle() {
    const charLimit = this.isPinned ? 10 : 13;
    if (this.title.length > charLimit) {
      return this.title.slice(0, charLimit) + '...';
    }

    return this.title;
  }

  handleClick() {
    this.$emit('click');
  }

  async handleClickSaveRename() {
    this.loader = this.$loading({
      lock: true,
      text: 'Renaming note',
    });
    const currentTabs = this.$store.state.articleEditor.article.textTabs;

    if (!this.changedTitle.length) this.changedTitle = 'Note';

    const tab = currentTabs.find((item) => item.id === this.textTab.id);

    if (tab) tab.title = this.changedTitle;

    try {
      await articleEditService.saveAsDraft();
    } finally {
      this.loader.close();
      this.loader = null;
      this.isRenaming = false;
    }
  }

  handleStartRenaming() {
    this.changedTitle = this.textTab.title;
    this.isRenaming = true;
  }

  handleClickPin() {
    if (!this.isAllowPin) return;
    this.$emit('pin', !this.isPinned);
  }

  async handleClickDelete() {
    const result = await confirmationService
      .showRemovePopup(this.textTab.title, '', DeletedItemStyleClass.ARTICLE)
      .catch(() => 'cancel');

    if (result !== 'confirm') return;

    this.loader = this.$loading({
      lock: true,
      text: 'Deleting note',
    });

    const textTabs = articleEditService.articleEditor.article.textTabs;
    const updatedTextTabsList = textTabs.filter((item) => item.id !== this.textTab.id);
    commitSetArticleEditorTextTabs(updatedTextTabsList);

    await dispatchUpdateSelectedTextTab(
      updatedTextTabsList.length && this.articleEditMode === ArticleEditMode.NOTES ? updatedTextTabsList[0] : null
    );

    if (!updatedTextTabsList.length) commitSetArticleMode(ArticleEditMode.VIDEO);

    try {
      await articleEditService.saveAsDraft();
    } finally {
      this.loader.close();
      this.loader = null;
    }
  }
}
