import { State } from '@/store/state';

export const uiMutations = {
  setDraftsCount(state: State, count: number) {
    state.ui.draftsCount = count;
  },
  setMeetingsCount(state: State, count: number) {
    state.ui.meetingsCount = count;
  },
  setPersonalArticlesCount(state: State, count: number) {
    state.ui.personalArticlesCount = count;
  },
  toggleWorkspacesSidebar(state: State, value: boolean): void {
    state.ui.isWorkspacesSidebar = value;
  },
  toggleMainSidebar(state: State, value: boolean): void {
    state.ui.isMainSidebar = value;
  },
  toggleExpandMainSidebar(state: State, value: boolean): void {
    state.ui.isMainSidebarExpanded = value;
  },
  toggleArticleVersionsSidebar(state: State, value: boolean): void {
    state.ui.isArticleVersionsSidebar = value;
  },
  setSidebarWidth(state: State, value: number): void {
    state.ui.sidebarWidth = value;
  },
  setIsOcrModeEnabled(state: State, value: boolean): void {
    state.ui.isOcrModeEnabled = value;
  },
  setIsOcrRecognitionFinished(state: State, value: boolean): void {
    state.ui.isOcrRecognitionFinished = value;
  },
  setIsExtensionBannerVisible(state: State, value: boolean): void {
    state.ui.isExtensionBannerVisible = value;
  },
  setIsWorkspaceCreateDialogVisible(state: State, value: boolean): void {
    state.ui.isWorkspaceCreateDialogVisible = value;
  },
  setIsNewNotifications(state: State, value: boolean): void {
    state.ui.isNewNotifications = value;
  },
};
