
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Article } from '@/models/article/Article';
import { dispatchOpenArticleEditor } from '@/store/dispatchers/articleEditorDispatchers';
import { dispatchDeleteArticle } from '@/store/dispatchers/articleDispatchers';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import InlineIcon from '@/components/layout/shared/InlineIcon.vue';
import * as routerNames from '@/routerNames';

@Component({
  components: { InlineIcon },
})
export default class MoreButton extends Vue {
  @Prop({ required: true, type: Object }) article!: Article;

  get workspaceView() {
    return this.$store.state.workspaceView;
  }

  get allowEditDeleteArticle() {
    return this.workspaceView.workspace ? this.workspaceView.workspace.canEditArticles() : false;
  }

  get isExportAllowed() {
    return (
      this.article.id &&
      !this.isArticleEditPage &&
      this.article?.textTabs?.length &&
      this.article?.textTabs[0].text !== '' &&
      this.article?.textTabs[0].text !== '<p></p>'
    );
  }

  get isMobile() {
    return mobileResponsiveService.isMobileView;
  }

  get isArticleEditPage() {
    return [
      routerNames.ARTICLE_CREATE,
      routerNames.ARTICLE_EDIT,
      routerNames.ARTICLE_VERSION_EDIT,
      routerNames.DRAFT_EDIT,
    ].includes(this.$route.name);
  }

  handleEditArticle(article: Article) {
    dispatchOpenArticleEditor(article);
  }

  handleDuplicateArticle() {
    this.$emit('duplicate-article');
  }

  handleMergeArticle() {
    this.$emit('merge-article');
  }

  handleReorderArticles() {
    this.$emit('reorder-articles');
  }

  handleCreateSubArticle() {
    this.$emit('create-sub-article');
  }

  handleCopyArticleUrl() {
    this.$emit('copy-article-url');
  }

  toggleArchiveArticle() {
    this.$emit('toggle-archive');
  }

  handlePrintArticle() {
    this.$emit('print-article');
  }

  handleRemoveArticle(article: Article) {
    dispatchDeleteArticle(article);
  }
}
