
import { Component } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import { HOME } from '@/routerNames';

@Component({})
export default class BrowseOldPage extends VueApp {
  mounted() {
    this.$router.push({ name: HOME });
  }
}
