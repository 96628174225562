
import VueApp from '@/@types/app/VueApp';
import { Component, Prop } from 'vue-property-decorator';
import { User } from '@/models/User';
import { Group } from '@/models/Group';
import mobileResponsiveService from '@/services/MobileResponsiveService';

@Component
export default class FindUserDialog extends VueApp {
  @Prop() visible: boolean;
  @Prop({ type: Boolean, default: false }) invited: boolean;
  @Prop({ type: Object, default: null }) group: Group;
  @Prop({ type: Array, default: null }) workspaceUsers: User[];
  @Prop() disableAddButton: boolean;

  loading = false;
  suggestList: any[] = [];
  addedUsers: string[] = [];
  savedUsers: User[] = [];

  $refs: {
    searchQuery: any;
  };

  get isAddButtonAvailable() {
    return !!this.disableAddButton;
  }

  async remoteMethod(query: string) {
    this.loading = true;
    this.suggestList = [];
    if (query) {
      try {
        const searchUsers = await this.$api.searchUsers(query, this.invited);
        if (searchUsers.data.items.length) {
          this.suggestList = searchUsers.data.items.map((item: any) => {
            const user = User.parse(item);
            const userLabel = user.getName() + ' (' + user.email + ')';
            let userInfo = this.setSmallerLabel(userLabel, 35);
            if (mobileResponsiveService.isMobileView) {
              userInfo = this.setSmallerLabel(userInfo);
            }
            if (!this.group && !this.workspaceUsers) {
              if (!this.checkSavedUsers(user.id)) {
                return {
                  value: JSON.stringify(user),
                  label: userInfo,
                };
              }

              return;
            }

            const foundUserInSet = this.group ? !this.hasGroupUser(item.groupIds) : !this.hasWorkspaceUser(user.id);
            if (userInfo.toLowerCase().indexOf(query.toLowerCase()) > -1 && foundUserInSet) {
              return {
                value: JSON.stringify(user),
                label: userInfo,
              };
            }
          });
        }
        this.removeSuggestedUser();
      } catch (err) {
        this.$api.handleResponseError(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  setSmallerLabel(label: string, symbolCount = 23): string {
    return label.length > symbolCount ? label.slice(0, symbolCount) + '...' : label;
  }

  hasGroupUser(groupIds: string[]): boolean {
    if (typeof groupIds === 'undefined') {
      return false;
    }
    return groupIds.indexOf(this.group.id) > -1;
  }

  hasWorkspaceUser(userId: string): boolean {
    return this.workspaceUsers.findIndex((user: User) => user.id === userId) !== -1;
  }

  updateSelect() {
    this.$nextTick(() => {
      this.removeSuggestedUser();
      this.$refs.searchQuery.query = '';
      this.remoteMethod(' ');
    });
  }

  checkSavedUsers(userId: string) {
    const existedUser = this.$store.state.addedUser.filter((user) => user.id === userId);
    return !!existedUser.length;
  }

  handleAdd() {
    this.$emit('select', this.addedUsers);
    this.addedUsers = [];
    this.removeUndefinedSuggested();
  }

  handleClose() {
    this.$nextTick(() => {
      this.addedUsers = [];
      this.$emit('update:visible', false);
    });
  }

  removeSuggestedUser() {
    this.removeUndefinedSuggested();
    if (this.addedUsers.length > 0) {
      this.addedUsers.forEach((addedUser) => {
        const foundIndex = this.suggestList.findIndex((suggestUser) => suggestUser.value === addedUser);

        if (foundIndex >= 0) {
          this.suggestList.splice(foundIndex, 1);
        }
      });
    }
  }

  removeUndefinedSuggested() {
    this.suggestList = this.suggestList.filter((item: any) => typeof item !== 'undefined');
  }
}
