import apiService from '@/services/ApiService';
import { ArticleSocketService } from '@/services/socket/services/ArticleSocketService';

export class SocketService {
  websocketProtocol = process.env.VUE_APP_HOST === 'localhost' ? 'ws' : 'wss';
  websocketOrigin = apiService.baseUrl.replace(/^https?:\/\//, '');

  article = new ArticleSocketService(this);
}
const socketService = new SocketService();
export default socketService;
