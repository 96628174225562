
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';

@Component({
  components: {
    MenuButton,
  },
})
export default class MenuRemoveLinkButton extends Vue {
  @Prop({ type: Editor, required: true })
  readonly editor: Editor;

  runCommand() {
    this.editor.chain().focus().unsetLink().run();
  }
}
