
import ComponentLoader from '@/components/layout/shared/loaders/ComponentLoader.vue';
import FileHandlerPlugin from '@/components/tiptap/plugins/FileHandlerPlugin';
import SelectLinkPlugin from '@/components/tiptap/plugins/SelectLinkPlugin';
import apiService from '@/services/ApiService';
import notificationService from '@/services/NotificationService';
import MenuBar from './Menu/MenuBar.vue';
import MenuBubble from './Menu/MenuBubble/MenuBubble.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Editor, EditorContent } from '@tiptap/vue-2';
import tipTapEditorService from '@/components/tiptap/TipTapEditorService';
import { TipTapEditorContext, tipTapEmptyState, TipTapSelection } from '@/components/tiptap/types';
import EditorContentPlugin from '@/components/tiptap/plugins/EditorContentPlugin';
import FloatingMenu from '@/components/tiptap/Menu/FloatingMenu/FloatingMenu.vue';
import EventBus from '@/EventBus';
import { Logger } from '@/other/Logger';
import { lowlight } from 'lowlight/lib/common';
import { ARTICLE_EDITOR_FORCE_FLUSH } from '@/events';

import { cleanFormattedTextAction } from '@/components/tiptap/actions/cleanFormattedTextAction';

import { extensions as commonExtensions } from '@/components/tiptap/shared/extensions';
import CharacterCount from '@tiptap/extension-character-count';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@/components/tiptap/extensions/TableCell';
import TableHeader from '@/components/tiptap/extensions/TableHeader';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import DropCursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import Image from '@/components/tiptap/extensions/Image';
import FileAttachment from '@/components/tiptap/extensions/FileAttachment';
import Heading from '@/components/tiptap/extensions/Heading';
import Paragraph from '@/components/tiptap/extensions/Paragraph';
import CodeBlock from '@/components/tiptap/extensions/CodeBlock';
import History from '@tiptap/extension-history';
import SlashCommand from '@/components/tiptap/extensions/SlashCommand';
import AiWriter from '@/components/tiptap/extensions/AiWriter/AiWriter';
import AiImage from '@/components/tiptap/extensions/AiImage/AiImage';
import ImageUpload from '@/components/tiptap/extensions/ImageUpload/ImageUpload';
import Columns from '@/components/tiptap/extensions/MultiColumn/Columns';
import Column from '@/components/tiptap/extensions/MultiColumn/Column';
import Document from '@/components/tiptap/extensions/Document';
import UniqKey from '@/components/tiptap/extensions/UniqKey/UniqKey';
import uniqKeyService from '@/components/tiptap/extensions/UniqKey/UniqKeyService';
import OnPressEnter from '@/components/tiptap/extensions/OnPressEnter';
import { FloatingMenu as FloatingMenuExtension } from '@tiptap/extension-floating-menu';
import Mention from '@/components/tiptap/extensions/Mention';

import { HocuspocusProvider, onStatusParameters } from '@hocuspocus/provider';
import authService from '@/services/AuthService';
import { getRandomColor } from '@/components/tiptap/utils/color';
import * as Y from 'yjs';

import 'codemirror/lib/codemirror.css'; // import base style
import 'codemirror/mode/xml/xml.js'; // language
import 'codemirror/addon/selection/active-line.js'; // require active-line.js
import 'codemirror/addon/edit/closetag.js';
import './styles/Editor.scss';

const log = new Logger('TipTap');

const CONTEXT_UPDATE_TIMEOUT = 100; // used for debounce and reduce quantity of re-rendering child components
const EMIT_UPDATE_TIMEOUT = 250; // reduce calls getHtml() as it's hard operation

const COLLABORATION_ENABLED =
  process.env.VUE_APP_COLLABORATION_ENABLED === 'true' || process.env.VUE_APP_COLLABORATION_ENABLED === 'undefined';

const EDITOR_CHAR_LIMIT = 30000;

@Component({
  components: { ComponentLoader, EditorContent, MenuBar, MenuBubble, FloatingMenu },
})
export default class TipTap extends Vue {
  @Prop() content: string;
  @Prop() documentId: string;
  @Prop({ type: Boolean, default: true }) isEditable: boolean;
  @Prop({ type: Boolean, default: false }) isCollaborationMode: boolean;

  editorKey = tipTapEditorService.generateKey();
  charactersCount = 0;
  updateContextTimeout: ReturnType<typeof setTimeout> = null;
  emitUpdateTimeout: ReturnType<typeof setTimeout> = null;

  provider: HocuspocusProvider = null;
  status = 'connecting';
  isSynced = false;

  editorContext: TipTapEditorContext = {
    state: tipTapEmptyState(),
    selection: null,
  };

  get editor() {
    return tipTapEditorService.get(this.editorKey);
  }

  get isCollaborationEnabled() {
    return this.isEditable && this.isCollaborationMode && this.documentId && COLLABORATION_ENABLED;
  }

  created() {
    uniqKeyService.clear();

    const extensions = [
      ...commonExtensions,
      Document,
      Paragraph,
      BubbleMenu,
      DropCursor,
      Gapcursor,
      Table.configure({ resizable: true }),
      TableRow,
      TableCell,
      TableHeader,
      Image,
      ImageUpload,
      FileAttachment,
      OnPressEnter,
      SlashCommand,
      Heading,
      UniqKey,
      Columns,
      Column,
      FloatingMenuExtension.configure({ element: document.querySelector('.menu') }),
      CodeBlock.configure({ lowlight }),
      Mention,
      AiWriter,
      AiImage,
      CharacterCount.configure({ limit: EDITOR_CHAR_LIMIT, mode: 'nodeSize' }),
    ];

    if (this.isCollaborationEnabled) {
      this.provider = this.createProvider();
      extensions.push(Collaboration.configure({ document: this.provider.document }));
      extensions.push(
        CollaborationCursor.configure({
          provider: this.provider,
          user: {
            name: authService.user.name,
            color: getRandomColor(),
          },
        })
      );
    } else {
      extensions.push(History);
    }

    let editor: Editor;

    try {
      const content = this.content + (!this.isEditable ? '<p></p>' : '');
      editor = new Editor({
        extensions,
        // empty paragraph needed in view mode to fix issue with adding comment for last paragraph
        content,
        editable: this.isEditable,
        onUpdate: this.onUpdate.bind(this),
        onSelectionUpdate: this.onSelectionUpdate.bind(this),
        editorProps: {
          transformPastedHTML(html) {
            return cleanFormattedTextAction(html);
          },
        },
      });
    } catch (e) {
      log.error(e);
    }

    editor.registerPlugin(SelectLinkPlugin);
    editor.registerPlugin(EditorContentPlugin);
    editor.registerPlugin(FileHandlerPlugin(editor));
    this.charactersCount = editor.storage.characterCount.characters();

    tipTapEditorService.registerEditor(this.editorKey, editor);
    EventBus.$on(ARTICLE_EDITOR_FORCE_FLUSH, this.handleForceFlushChanges);

    log.info(
      `Editor ${this.editorKey} created. Collaboration mode ${this.isCollaborationEnabled ? 'ENABLED' : 'DISABLED'}`
    );
  }

  beforeDestroy() {
    EventBus.$off(ARTICLE_EDITOR_FORCE_FLUSH, this.handleForceFlushChanges);
    if (this.provider) this.provider.destroy();
    tipTapEditorService.destroyEditor(this.editorKey);
  }

  createProvider() {
    log.info(`Collaboration mode. connecting to ${process.env.VUE_APP_COLLABORATION_SERVER_URL}`);
    const provider = new HocuspocusProvider({
      url: process.env.VUE_APP_COLLABORATION_SERVER_URL,
      token: apiService.getToken() || '',
      name: this.documentId,
      document: new Y.Doc(),
    });
    provider.on('open', () => {
      log.info('Collaboration socket opened');
    });
    provider.on('status', (event: onStatusParameters) => {
      const editor = this.editor;
      const usersCount = editor ? editor.storage.collaborationCursor.users.length : 0;
      log.info(usersCount);
      if (this.status !== event.status) {
        this.status = event.status;
        log.info(`Connection status changed: ${event.status}`);
      }
    });
    provider.on('authenticationFailed', () => {
      notificationService.warning('Online editing is not possible. Try again later');
      this.isSynced = true;
    });

    provider.on('close', () => {
      log.error('Collaboration provider connection failed or closed.');
      this.isSynced = true;
    });

    provider.on('synced', () => {
      if (!this.isSynced) {
        this.isSynced = true;
        this.editor.chain().setContent(this.content).run();
      }
    });
    return provider;
  }

  updateContext() {
    const editor = this.editor;

    this.editorContext.selection = editor.state.selection.toJSON() as TipTapSelection;
    const activeTextStyles = editor.getAttributes('textStyle');
    this.editorContext.state = {
      isActiveBold: editor.isActive('bold'),
      isActiveBlockquote: editor.isActive('blockquote'),
      isActiveFontFamily: !!activeTextStyles.fontFamily,
      activeFontFamily: activeTextStyles.fontFamily || '',
      isActiveItalic: editor.isActive('italic'),
      isActiveImage: editor.isActive('image'),
      isActiveImageUpload: editor.isActive('imageUpload'),
      isActiveStrike: editor.isActive('strike'),
      isActiveLink: editor.isActive('link'),
      isActiveUnderline: editor.isActive('underline'),
      isActiveHeading: editor.isActive('heading'),
      isActiveHeading1: editor.isActive('heading', { level: 1 }),
      isActiveHeading2: editor.isActive('heading', { level: 2 }),
      isActiveHeading3: editor.isActive('heading', { level: 3 }),
      isActiveHeading4: editor.isActive('heading', { level: 4 }),
      isActiveHeading5: editor.isActive('heading', { level: 5 }),
      isActiveTextAlignLeft: editor.isActive({ textAlign: 'left' }),
      isActiveTextAlignCenter: editor.isActive({ textAlign: 'center' }),
      isActiveTextAlignRight: editor.isActive({ textAlign: 'right' }),
      isActiveTextAlignJustify: editor.isActive({ textAlign: 'justify' }),
      isActiveBulletList: editor.isActive('bulletList'),
      isActiveOrderedList: editor.isActive('orderedList'),
      isActiveTodoList: editor.isActive('taskList'),
      isActiveCodeBlock: editor.isActive('codeblock'),
      isActiveHighlight: editor.isActive('highlight'),
      isActiveTextStyle: editor.isActive('textStyle'),
      isActiveTable: editor.isActive('table'),
      isActiveColumns: editor.isActive('columns'),
      isActiveAiWriter: editor.isActive('aiWriter'),
      isActiveAiImage: editor.isActive('aiImage'),
      isCanMergeCells: editor.can().mergeCells(),
      isCanSplitCell: editor.can().splitCell(),
    };
  }

  requestUpdateContext() {
    window.clearTimeout(this.updateContextTimeout);
    this.updateContextTimeout = window.setTimeout(() => {
      this.updateContext();
    }, CONTEXT_UPDATE_TIMEOUT) as unknown as ReturnType<typeof setTimeout>;
  }

  emitUpdateEvent(delay = 0) {
    window.clearTimeout(this.emitUpdateTimeout);

    if (this.isCollaborationEnabled && !this.isSynced) {
      return;
    }

    this.emitUpdateTimeout = window.setTimeout(() => {
      const html = this.editor.getHTML();
      log.info(`Emit update${delay > 0 ? ` with delay ${delay}` : ''}`);
      this.$emit('update', html);
    }, delay) as unknown as ReturnType<typeof setTimeout>;
  }

  onUpdate() {
    this.charactersCount = this.editor.storage.characterCount.characters();
    this.requestUpdateContext();
    this.emitUpdateEvent(EMIT_UPDATE_TIMEOUT);
  }

  onSelectionUpdate() {
    this.requestUpdateContext();
  }

  handleForceFlushChanges() {
    log.info('Force flush event received');

    this.emitUpdateEvent(0);
  }

  @Watch('isEditable')
  onIsEditableChange() {
    if (this.editor) this.editor.setEditable(this.isEditable);
  }

  @Watch('content')
  updateContent() {
    if (this.isEditable) return;
    const editor = this.editor;
    editor.commands.setContent(this.content);
  }
}
