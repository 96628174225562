import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { Workspace } from '@/models/Workspace';
import { Article } from '@/models/article/Article';
import logger from '@/other/Logger';
import * as routes from '@/routerNames';
import axios from 'axios';
import articleModeService from '@/services/article/ArticleModeService';
import workspaceService from '@/services/workspace/WorkspaceService';
import slugify from 'slugify';
import { Company } from '@/models/Company';

export class UrlService {
  getPrivatelySharedArticleUrl = (token: string) => {
    const baseUrl = location.href.replace(location.hash, '');
    return `${baseUrl}#/b/shared/${token}/private`;
  };

  getArticleViewRoute = (
    article: Article,
    workspace: Workspace,
    mode: ArticleEditMode | null = ArticleEditMode.VIDEO
  ) => {
    return {
      name: routes.BROWSE,
      params: {
        workspaceId: workspace.shortId,
        workspaceSlug: workspaceService.slugifyTitle(workspace.title),
        articleId: article.shortId,
        articleSlug: article.slug,
        mode: mode ? this.modeToUrl(mode) : undefined,
      },
    };
  };

  getArticleViewUrl = (article: Article, workspace: Workspace, mode?: ArticleEditMode) => {
    const baseUrl = location.href.replace(location.hash, '');
    const articleMode = mode ? mode : articleModeService.getArticleMode(article);
    return `${baseUrl}#/b/${workspace.shortId}/${workspace.slug}/${article.shortId}/${article.slug}${
      articleMode ? `/${articleMode}` : ''
    }`;
  };

  getParagraphViewRoute = (paragraphSlug: string, article: Article, workspace: Workspace) => {
    return {
      name: routes.BROWSE_PARAGRAPH,
      params: {
        workspaceId: workspace.shortId,
        workspaceSlug: workspaceService.slugifyTitle(workspace.title),
        articleId: article.shortId,
        articleSlug: article.slug,
        paragraphSlug: paragraphSlug,
      },
    };
  };

  getParagraphViewUrl = (paragraphSlug: string, article: Article, workspace: Workspace) => {
    const baseUrl = location.href.replace(location.hash, '');
    return `${baseUrl}#/b/${workspace.shortId}/${workspace.slug}/${article.shortId}/${article.slug}/${paragraphSlug}`;
  };

  getTextTabViewRoute = (textTabSlug: string, article: Article, workspace: Workspace) => {
    return {
      name: routes.BROWSE_TEXT_TAB,
      params: {
        workspaceId: workspace.shortId,
        workspaceSlug: workspace.slug ? workspace.slug : slugify(workspace.title),
        articleId: article.shortId,
        articleSlug: article.slug,
        mode: ArticleEditMode.NOTES,
        textTabSlug: textTabSlug,
      },
    };
  };

  getWorkspaceViewUrl = (workspace: Workspace) => {
    const baseUrl = location.href.replace(location.hash, '');
    return `${baseUrl}#/b/${workspace.shortId}/${workspace.slug}`;
  };

  getAdminCompanyViewUrl = (company: Company) => {
    const baseUrl = location.href.replace(location.hash, '');
    return `${baseUrl}#/admin/company/${company.id}`;
  };

  getWorkspaceViewRoute = (workspace: Workspace) => {
    if (!workspace?.shortId || !workspace?.slug) {
      logger.error('getWorkspaceViewRoute: shortId or slug is missing');
      return { name: routes.BROWSE };
    }
    return { name: routes.BROWSE, params: { workspaceId: workspace.shortId, workspaceSlug: workspace.slug } };
  };

  getDraftViewRoute = (draft: any, mode?: ArticleEditMode) => {
    return { name: routes.DRAFT_EDIT, params: { id: draft?.id ? draft?.id : draft._id, mode } };
  };

  getDraftViewUrl = (draft: Article, mode?: ArticleEditMode) => {
    const isOriginal = draft.original;
    const baseUrl = location.href.replace(location.hash, '');
    return `${baseUrl}#/${isOriginal ? 'article' : 'draft'}/${isOriginal ? draft.original : draft.id}${
      isOriginal ? `/edit/${mode}` : ''
    }`;
  };

  private modeToUrl = (mode: ArticleEditMode) => {
    return mode === ArticleEditMode.VIDEO ? ArticleEditMode.VIDEO : ArticleEditMode.NOTES;
  };

  checkDomain = async (domain: string) => {
    const attempts = 20; // Number of attempts
    const baseDelay = 6000; // Base delay in milliseconds (3 seconds)
    let delay = baseDelay;

    for (let i = 0; i < attempts; i++) {
      try {
        await axios.get(domain, { timeout: 5000, headers: { credentials: 'omit' } });

        // Successful response doesn't guarantee availability
        return true;
      } catch (error) {
        // Handle errors from the request
        if (axios.isAxiosError(error)) {
          delay = Math.min(delay * 2, 80000);
        } else {
          // Handle non-Axios errors
          return false;
        }
      }

      // Wait before next attempt
      await new Promise((resolve) => setTimeout(resolve, delay));
    }

    // All attempts failed
    return false;
  };
}

const urlService = new UrlService();
export default urlService;
