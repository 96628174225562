import { Article } from '@/models/article/Article';
import { Paragraph } from '@/models/article/Paragraph';
import { Workspace } from '@/models/Workspace';
import { TextTab } from '@/models/article/TextTab';

import { ArticleVideoEditorState } from '@/components/article-editor/types/ArticleVideoEditorState';

export interface ArticleEditorState {
  article: Article;
  state: ArticleVideoEditorState;
  playerState: string;
  currentTime: number;
  selectedParagraph: Paragraph | null;
  selectedTextTab: {
    slug: string | null;
    tab: TextTab | null;
  };
  workspaces: Workspace[];
  workspace: Workspace | null;
  tags: string[];
  displayFileUploadInput: boolean;
  fileUploadInput: unknown | null;
  editDraftExtension?: string;
  isVideoProcessing: boolean;
  isAutosaveEnabled: boolean;
  isPublishingDisabled: boolean;
  articleHash: string; // used for draft discard
}

export const articleEditorState: ArticleEditorState = {
  article: null,
  state: ArticleVideoEditorState.default,
  playerState: 'paused',
  currentTime: 0,
  selectedParagraph: null,
  selectedTextTab: {
    slug: null,
    tab: null,
  },
  workspaces: [],
  workspace: null,
  tags: [],
  displayFileUploadInput: true,
  fileUploadInput: null,
  editDraftExtension: undefined,
  isVideoProcessing: false,
  isAutosaveEnabled: true,
  isPublishingDisabled: false,
  articleHash: null,
};
