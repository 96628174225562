import { PaymentMethodDetails } from '@/@types/billing/subscription/PaymentMethodDetails';
import axios, { AxiosResponse } from 'axios';
import { Subscription, Price } from '@/@types/billing';
import { BillingHistoryItem } from '@/@types/billing/BillingHistoryItem';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';

export class BillingServiceProvider extends ApiBaseServiceProvider {
  scope = 'billing';

  url = (path: string) => this.getBaseUrl() + path;

  get = <T>(path: string) => axios.get<T>(this.url(path), this.getRequestConfig());

  post = <T, D = undefined>(path: string, data?: D) =>
    axios.post<T, AxiosResponse<T>, D>(this.url(path), data, this.getRequestConfig());

  getUserSubscriptions = () => this.get<Subscription[]>('subscriptions');

  getPaymentMethod = (subscriptionId: string) => {
    return this.get<PaymentMethodDetails | null>(`subscriptions/${subscriptionId}/payment-method`);
  };

  updatePaymentMethod = (subscriptionId: string) => {
    return this.get<{ transactionId: string }>(`subscriptions/${subscriptionId}/update-payment`);
  };

  getPrices = () => this.get<Price[]>('prices');

  getBillingHistory = () => this.get<BillingHistoryItem[]>('history');

  getBillingHistoryItem = (transactionId: string) => this.get<BillingHistoryItem>(`history/${transactionId}`);

  getTransactionInvoice = (transactionId: string) => this.get<{ url: string }>(`invoice/${transactionId}`);

  createTransaction = (data: { priceId: Price['id'] }) =>
    this.post<{ transactionId: string }, { priceId: Price['id'] }>('transactions/create', data);

  pauseSubscription = (data: { subscriptionId: Subscription['id'] }) =>
    this.post<Subscription, { subscriptionId: Subscription['id'] }>('subscriptions/pause', data);

  changePlan = (data: { subscriptionId: Subscription['id']; priceId: Price['id'] }) =>
    this.post<Subscription, { subscriptionId: Subscription['id'] }>('subscriptions/update', data);
}
