/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Model } from '@/models/Model';

export class ModelService {
  create<T extends Model>(type: { new (): T }, jsonData: unknown): T {
    const instance = new type();

    this.load(instance, typeof jsonData === 'object' && jsonData !== null ? jsonData : {});

    return instance;
  }

  public load(instance: Model, jsonData: any): void {
    const instanceFields = Object.getOwnPropertyNames(instance);

    if (typeof jsonData === 'object') {
      instanceFields.forEach((fieldName) => {
        if (typeof instance[fieldName] !== 'function' && jsonData[fieldName]) {
          // @ts-ignore
          instance[fieldName] = jsonData[fieldName];
        }
      });
    }

    instance.afterLoad(jsonData);
  }

  generateKey() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 16; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}

const modelService = new ModelService();
export default modelService;
