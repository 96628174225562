
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Popover } from 'element-ui';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import MenuBulletListButton from '@/components/tiptap/Menu/MenuItems/List/MenuBulletListButton.vue';
import MenuOrderedListButton from '@/components/tiptap/Menu/MenuItems/List/MenuOrderedListButton.vue';
import MenuTodoListButton from '@/components/tiptap/Menu/MenuItems/List/MenuTodoListButton.vue';

@Component({
  components: {
    MenuTodoListButton,
    MenuOrderedListButton,
    MenuBulletListButton,
    MenuButton,
    [Popover.name]: Popover,
  },
})
export default class ListPopover extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  private popoverVisible = false;

  hidePopover(): void {
    this.popoverVisible = false;
  }
}
