import store from '@/store';

export const commitSetDraftsCount = (count: number) => {
  store.commit('setDraftsCount', count);
};
export const commitSetMeetingsCount = (count: number) => {
  store.commit('setMeetingsCount', count);
};
export const commitSetPersonalArticlesCount = (count: number) => {
  store.commit('setPersonalArticlesCount', count);
};
export const commitToggleWorkspacesSidebar = () => {
  const isWorkspacesSidebar = store.state.ui.isWorkspacesSidebar;

  store.commit('toggleWorkspacesSidebar', !isWorkspacesSidebar);
};
export const commitToggleArticleVersionsSidebar = () => {
  const isArticleVersionsSidebar = store.state.ui.isArticleVersionsSidebar;

  store.commit('toggleArticleVersionsSidebar', !isArticleVersionsSidebar);
};
export const commitSetSidebarWidth = (width: number) => {
  store.commit('setSidebarWidth', width);
};
export const commitSetIsOcrModeEnabled = (isVisible: boolean) => {
  store.commit('setIsOcrModeEnabled', isVisible);
};
export const commitSetIsOcrRecognitionFinished = (isFinished: boolean) => {
  store.commit('setIsOcrRecognitionFinished', isFinished);
};

export const commitToggleMainSidebar = () => {
  const isMainSidebar = store.state.ui.isMainSidebar;

  store.commit('toggleMainSidebar', !isMainSidebar);
};

export const commitToggleExpandMainSidebar = () => {
  const isMainSidebarExpanded = store.state.ui.isMainSidebarExpanded;

  store.commit('toggleExpandMainSidebar', !isMainSidebarExpanded);
};
