import { Range } from './Range';
import helper from '../../helper';
import slugify from 'slugify';
import { PlayMapItem } from './PlayMap';
import { Video } from '../Video';
import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';

export class Paragraph implements Model {
  static createFromKeyframes(keyframes: number[], max: number, offset = 0) {
    const data = [];

    if (keyframes && keyframes.length) {
      let pos = offset;
      for (const key in keyframes) {
        const item = keyframes[key];
        const i = parseInt(key) + 1;
        const duration = keyframes[i] ? keyframes[i] - item : max - item;

        if (duration > 0) {
          data.push(
            Paragraph.parse({
              title: 'Paragraph #' + i,
              position: pos,
              duration,
            })
          );
          pos += duration;
        }
      }
    }
    return data;
  }

  static parse(data: any) {
    return Paragraph.fromJson(data) as Paragraph;
  }

  static fromJson(data: unknown): Paragraph {
    return modelService.create(Paragraph, data);
  }

  id: string | null = null;
  title = '';
  position = 0;
  duration = 0;
  items: Paragraph[] = [];
  key: any = null;
  tags: string[] = [];
  pm: PlayMapItem[] = [];
  videos: Video[] = [];
  isExpanded = true;

  constructor() {
    this.key = modelService.generateKey();
  }

  afterLoad(data: any) {
    this.id = data.id ? data.id : data._id;
    this.duration = Math.round(this.duration);
    this.items = [];
    if (data.items && data.items.length) {
      for (const key in data.items) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.items.hasOwnProperty(key)) {
          this.items.push(Paragraph.parse(data.items[key]));
        }
      }
    }

    this.tags = [];
    if (data.tags) {
      this.tags = data.tags.map((item: string) => {
        return item + '';
      });
    }

    this.videos = [];
    if (data.videos) {
      this.videos = data.videos.map((item: any) => {
        return Video.fromJson(item);
      });
    }

    this.pm = [];
    if (data.pm) {
      this.pm = data.pm.map((item: any) => {
        return PlayMapItem.parse(item);
      });
    }

    this.isExpanded = data.isExpanded;
  }

  getRange() {
    return new Range(this.position, this.position + this.duration);
  }

  getChildrenRanges() {
    const ranges: Range[] = [];
    if (this.items && this.items.length) {
      helper.deepEach(this, (item: any) => {
        if (item === this) {
          return;
        }
        const p = Paragraph.fromJson(item);
        ranges.push(p.getRange());
      });
    }

    return ranges;
  }

  getAllRanges() {
    return [this.getRange(), ...this.getChildrenRanges()];
  }

  hasItems() {
    return this.items && this.items.length;
  }

  getSlug() {
    return slugify(this.title);
  }
}
