import { Group } from '@/models/Group';

export class GroupsCollection {
  static collect(groups: any[]) {
    const data: any[] = [];

    if (groups.length) {
      groups.forEach((item) => {
        data.push(Group.parse(item));
      });
    }

    return new GroupsCollection(data);
  }

  groups: any[] = [];

  constructor(groups: any[]) {
    this.groups = groups;
  }

  checkRole(groupId: string, role: string | string[]): boolean {
    const groupRole = this.getGroupRole(groupId);
    const groups = role.length ? role : [role + ''];

    return groups.indexOf(groupRole) >= 0;
  }

  getGroupRole(groupId: string): string {
    let role = null;
    if (this.groups && this.groups.length) {
      const items = this.groups.filter((item: Group) => {
        return item.id === groupId;
      });

      if (items.length && items[0]) {
        role = items[0].role;
      }
    }

    return role;
  }
}
