import { ApiBaseServiceProvider } from '@/services/api-providers/ApiBaseServiceProvider';
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';

export class FileAttachmentServiceProvider extends ApiBaseServiceProvider {
  scope = 'file-attachment';

  upload(file: File, progressCallback?: (progress: AxiosProgressEvent) => void) {
    const formData = new FormData();
    formData.append('file', file);

    const config = this.getRequestConfig() as AxiosRequestConfig;

    if (typeof progressCallback === 'function') {
      config.onUploadProgress = progressCallback;
    }

    return axios.post<{ id: string; url: string; fileName: string }>(this.getBaseUrl() + 'upload', formData, config);
  }
}
