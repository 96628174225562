import { MeetingStatus } from '@/@types/MeetingStatus';
import { MeetingGetAllFilters } from '@/@types/requests/MeetingsRequests';
import {
  GetAllMeetingsResponse,
  GetMeetingsCountResponse,
  GetMeetingsFilterDataResponse,
} from '@/@types/responses/MeetingsResponses';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios from 'axios';

export class MeetingsServiceProvider extends ApiBaseServiceProvider {
  scope = 'meetings';

  count = () => {
    return axios.get<GetMeetingsCountResponse>(this.getBaseUrl() + 'count', this.getRequestConfig());
  };

  getAll(filters: MeetingGetAllFilters) {
    const params = new URLSearchParams();
    params.append('page', filters.page.toString());
    if (filters.organizer) params.append('organizer', filters.organizer);
    if (filters.workspace) params.append('workspace', filters.workspace);
    if (filters.q) params.append('q', filters.q);
    if (filters.status) params.append('status', filters.status);

    const url = `${this.getBaseUrl()}?${params.toString()}`;
    return axios.get<GetAllMeetingsResponse>(url, this.getRequestConfig());
  }

  getFiltersData() {
    return axios.get<GetMeetingsFilterDataResponse>(this.getBaseUrl() + 'filters', this.getRequestConfig());
  }

  delete(meetingId: string, status: MeetingStatus, recurrence?: string[] | null, all = true) {
    const params = new URLSearchParams();
    params.append('status', status);
    params.append('all', all ? '1' : '0');

    const url = `${this.getBaseUrl()}${meetingId}?${params.toString()}`;
    return axios.post<{ newStartDate?: string }>(url, { recurrence }, this.getRequestConfig());
  }
}
