import { State } from '@/store/state';
import { Article } from '@/models/article/Article';
import modelService from '@/services/ModelService';
import { ArticleVideoEditorState } from '@/components/article-editor/types/ArticleVideoEditorState';
import { Paragraph } from '@/models/article/Paragraph';
import { TextTab } from '@/models/article/TextTab';

export const articleEditorMutations = {
  // @todo get rid of this mutation
  setArticleEditor(state: any, payload: any) {
    state.articleEditor[payload[0]] = payload[1];
  },
  setArticleEditorState(state: State, value: ArticleVideoEditorState) {
    state.articleEditor.state = value;
  },

  setArticleEditorArticle(state: State, article: Article) {
    state.articleEditor.article = article;
  },

  setArticleEditorSelectedParagraph(state: State, paragraph: Paragraph) {
    state.articleEditor.selectedParagraph = paragraph;
  },

  setArticleEditorSelectedTextTab(state: State, textTab: TextTab) {
    state.articleEditor.selectedTextTab.tab = textTab;
  },

  setArticleEditorSelectedTextTabSlug(state: State, slug: string) {
    state.articleEditor.selectedTextTab.slug = slug;
  },
  // setArticleEditorSelectedArticle(state: State, article: Article) {
  //   state.articleEditor. = article;
  // },

  setArticleEditorArticleProps(state: State, props: Partial<Article>) {
    modelService.load(state.articleEditor.article, props);
  },

  setArticleEditorArticleText(state: State, text: string) {
    state.articleEditor.article.text = text;
  },

  setArticleEditorArticleParagraphs(state: State, paragraphs: Paragraph[]) {
    state.articleEditor.article.paragraphs = paragraphs;
  },

  setArticleEditorArticleDuration(state: State, duration: number) {
    state.articleEditor.article.duration = duration;
  },

  setArticleEditorTextTabs(state: State, tabs: TextTab[]) {
    state.articleEditor.article.textTabs = tabs;
  },

  setArticleEditorIsAutosaveEnabled(state: State, isEnabled: boolean) {
    state.articleEditor.isAutosaveEnabled = isEnabled;
  },

  setArticleEditorIsPublishingDisabled(state: State, isDisabled: boolean) {
    state.articleEditor.isPublishingDisabled = isDisabled;
  },

  setArticleEditorArticleHash(state: State, hash: string) {
    state.articleEditor.articleHash = hash;
  },

  setArticleEditorIsVideoProcessing(state: State, value: boolean) {
    state.articleEditor.isVideoProcessing = value;
  },

  setArticleEditorArticleTags(state: State, tags: string[]) {
    state.articleEditor.article.tags = tags;
  },

  setArticleEditorEditDraftExtension(state: State, draftId: string) {
    state.articleEditor.editDraftExtension = draftId;
  },
};
