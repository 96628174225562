
import { Component, Prop, Vue } from 'vue-property-decorator';
import Ocr from 'text-recognition';
import logger from '@/other/Logger';
import OcrProgressDialog from '@/components/OcrResultsOverlay/OcrProgressDialog.vue';
import { LoadingStatus } from 'text-recognition/src/Ocr';
import { dispatchDisableOcrMode } from '@/store/dispatchers/uiDispatchers';
import { commitSetIsOcrRecognitionFinished } from '@/store/commits/uiCommits';

type Size = { width: number; height: number };

const RESIZE_TIMEOUT = 100;
let ocrInstance: Ocr = null;

@Component({
  components: {
    OcrProgressDialog,
  },
})
export default class OcrResultsOverlay extends Vue {
  @Prop({ type: Boolean, default: false }) isVisible: boolean;
  @Prop({ type: HTMLVideoElement, required: true }) videoElement: HTMLVideoElement;

  onResizeTimeout = 0;
  progress = 0;

  $refs: {
    results: HTMLDivElement;
    dialog: OcrProgressDialog;
  };

  mounted() {
    this.recognize();

    this.$refs.dialog.show();
    window.addEventListener('resize', this.onResize);
  }

  beforeDestroy() {
    this.$refs.dialog.close();
    this.terminate();

    window.removeEventListener('resize', this.onResize);
    window.clearTimeout(this.onResizeTimeout);
  }

  async getBlobFromVideo(): Promise<Blob> {
    const video = this.videoElement;
    const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0);

    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob(resolve);
      } catch (e) {
        reject(e);
      }
    });
  }

  fitRectIntoBounds(rect: Size, bounds: Size) {
    const rectRatio = rect.width / rect.height;
    const boundsRatio = bounds.width / bounds.height;

    const newDimensions: Size = { width: 0, height: 0 };

    // Rect is more landscape than bounds - fit to width
    if (rectRatio > boundsRatio) {
      newDimensions.width = bounds.width;
      newDimensions.height = rect.height * (bounds.width / rect.width);
    }
    // Rect is more portrait than bounds - fit to height
    else {
      newDimensions.width = rect.width * (bounds.height / rect.height);
      newDimensions.height = bounds.height;
    }

    return newDimensions;
  }

  updateContainerStyles() {
    const video = this.videoElement;
    const container = this.$el as HTMLDivElement;
    const videoSize = { width: video.videoWidth, height: video.videoHeight };
    const realVideoSize = this.fitRectIntoBounds(videoSize, { width: video.offsetWidth, height: video.offsetHeight });
    const scale = realVideoSize.height / video.videoHeight;
    const topOffset = (video.offsetHeight - realVideoSize.height) / 2;
    const leftOffset = (video.offsetWidth - realVideoSize.width) / 2;

    container.style.width = `${video.videoWidth}px`;
    container.style.height = `${video.videoHeight}px`;
    container.style.top = `${topOffset}px`;
    container.style.left = `${leftOffset}px`;
    container.style.transform = `scale(${scale})`;
    container.style.transformOrigin = `left top`;
  }

  async recognize() {
    const blob: Blob = await this.getBlobFromVideo();
    this.updateContainerStyles();

    const ocr = new Ocr({ languages: ['eng', 'ukr', 'rus'], lineClassName: 'ocr-line', wordClassName: 'ocr-word' });
    ocrInstance = ocr;

    ocr.addSelectionListener();
    ocr.addProgressListener(this.onRecognitionProgress.bind(this));

    await ocr.parseImage(blob); // image reference, base64, url etc.
    await ocr.appendHtml(this.$refs.results, 1);
    await commitSetIsOcrRecognitionFinished(true);
  }

  terminate() {
    if (ocrInstance) {
      ocrInstance.removeSelectionListener();
      ocrInstance.destroy();
    }
    ocrInstance = null;
  }

  onRecognitionProgress(e: { status: LoadingStatus; progress: number; workerId?: string; jobId?: string }) {
    logger.info(JSON.stringify(e));
    if (e.progress && e.status === LoadingStatus.RECOGNIZING) {
      const progress = Math.round(e.progress * 100);

      if (e.progress === 1) {
        this.$refs.dialog.close();
      }

      this.progress = progress;
    }
  }

  onResize() {
    window.clearTimeout(this.onResizeTimeout);
    this.onResizeTimeout = window.setTimeout(() => {
      window.requestAnimationFrame(this.updateContainerStyles);
    }, RESIZE_TIMEOUT);
  }

  onClickCancel() {
    this.$refs.dialog.close();
    this.terminate();
    dispatchDisableOcrMode();
  }
}
