
import EventBus from '@/EventBus';
import { Company } from '@/models/Company';
import { Component, Prop } from 'vue-property-decorator';
import { COMPANY_CREATE, TOGGLE_MENU } from '@/events';
import VueApp from '@/@types/app/VueApp';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import adminCompanyService from '@/services/company/AdminCompanyService';
import { HEADER_HEIGHT } from '@/constants/SharedConsts';
import { CompanyUserStatus } from '@/@types/enums/CompanyUserStatus';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';

@Component({
  name: 'UserMenu',
  components: {
    UserProfilePhoto,
  },
})
export default class UserMenu extends VueApp {
  @Prop({ type: Boolean, default: false }) isMenuOpened: boolean;
  @Prop({ type: Number }) draftsCount: number;
  @Prop({ type: Number }) personalArticlesCount: number;

  get user() {
    return this.$store.state.user;
  }

  get userName() {
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  get isCompanyPaid() {
    return this.$store.state.company?.isPaid;
  }

  get userCompanies() {
    return this.user ? this.$store.state.userCompanies : [];
  }

  get userActiveCompanies() {
    const companies: Company[] = this.$store.state.userCompanies;
    const inactiveCompanies: Company[] = [];
    let activeCompanies: Company[] = [];
    companies.forEach((company: Company) => {
      const isInactiveUserInCompany = company.roles?.some(
        (item) => item.status === CompanyUserStatus.DEACTIVATED && item.user === this.user.id
      );
      if (isInactiveUserInCompany) {
        inactiveCompanies.push(company);
      }
    });

    if (inactiveCompanies.length) {
      companies.forEach((company: Company) => {
        inactiveCompanies.forEach((item: Company) => {
          if (company.id !== item.id) {
            activeCompanies.push(company);
          }
        });
      });
    } else {
      activeCompanies = companies;
    }
    return activeCompanies;
  }

  get isUserInactiveInCompany() {
    return this.$store.state.isUserInactiveInCompany;
  }

  get company(): Company | null {
    return this.$store.state.company;
  }

  get companyId(): string {
    return typeof this.company === 'object' ? this.company.id : this.company;
  }

  get getMenuMobileStyles() {
    return {
      opened: this.isMenuOpened,
    };
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get heightMobileMenu(): string {
    const windowInnerHeight = mobileResponsiveService.windowInnerHeight;
    let res: number | string = 'none';
    if (windowInnerHeight) {
      res = windowInnerHeight - HEADER_HEIGHT + 'px';
    }
    return res;
  }

  get canEditCompany() {
    return (
      this.company &&
      typeof this.company.canEdit === 'function' &&
      this.company.canEdit(this.user) &&
      !this.isUserInactiveInCompany
    );
  }

  get getRouteForCompanySettings(): string {
    if (this.user.role === 'moderator') {
      return 'UserManagementPage';
    } else {
      return 'Company';
    }
  }

  navigate(route: any) {
    this.onCloseMenu();
    this.$router.push(route);
  }

  handleLogout() {
    this.$auth.logout();
    document.location.href = `${process.env.VUE_APP_URL}/#/login`;
  }

  switchCompany(e: PointerEvent, company: Company) {
    e.preventDefault();
    const isModifierPressed = e.metaKey || e.ctrlKey || e.button === 1;
    if (!this.company) return;

    if (company.id !== this.companyId) {
      adminCompanyService.switchCompany(company, isModifierPressed);
    }
  }

  onCloseMenu() {
    this.$emit(TOGGLE_MENU);
  }

  handleCreateCompany() {
    this.onCloseMenu();
    EventBus.$emit(COMPANY_CREATE);
  }
}
