
import { Component, Watch } from 'vue-property-decorator';
import EventBus from '@/EventBus';
import { Workspace } from '@/models/Workspace';
import { Company } from '@/models/Company';
import VueApp from '@/@types/app/VueApp';
import notificationService from '@/services/NotificationService';
import urlService from '@/services/UrlService';
import WorkspaceVisibilityInfo from '@/components/layout/shared/WorkspaceVisibilityInfo/WorkspaceVisibilityInfo.vue';

@Component({
  components: { WorkspaceVisibilityInfo },
})
export default class WorkspaceCreateDialog extends VueApp {
  showDialog = false;
  workspace: Workspace = null;
  company: Company = null;

  get isWorkspaceCreateDialogVisible() {
    return this.$store.state.ui.isWorkspaceCreateDialogVisible;
  }

  getCompany(): Company | null {
    if (this.$store.state.pageCompany) {
      return this.$store.state.pageCompany;
    } else if (this.$store.state.company) {
      return this.$store.state.company;
    }
    return null;
  }

  onClickClose() {
    this.$store.commit('setIsWorkspaceCreateDialogVisible', false);
  }

  show() {
    this.workspace = new Workspace();
    this.company = this.getCompany();
    this.showDialog = true;
  }

  close() {
    this.showDialog = false;
    this.workspace = null;
  }

  saveWorkspace() {
    // prepare data to be submitted
    const data = JSON.parse(JSON.stringify(this.workspace));
    delete data.roles;
    if (!this.workspace.title.trim().length) {
      notificationService.warning('Title cannot be empty!');
      return;
    }
    // save workspace
    this.$api.workspace
      .save(data)
      .then((res) => {
        this.workspace = Workspace.parse(res.data.workspace);
        // emmit global event
        EventBus.$emit('workspace.' + (this.workspace.id ? 'update' : 'create'), this.workspace);
        this.$router.push(urlService.getWorkspaceViewRoute(this.workspace));
      })
      .catch((res: any) => {
        this.$api.handleResponseError(res);
      })
      .finally(() => {
        this.onClickClose();
      });
  }

  @Watch('isWorkspaceCreateDialogVisible')
  onIsWorkspaceCreateDialogVisibleChanged(value: boolean) {
    if (value) {
      this.show();
    } else {
      this.close();
    }
  }
}
