var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('player-timeline',{ref:"timeline",staticClass:"article-timeline",attrs:{"is-edit-page":_vm.isArticleEditPage,"value":_vm.position,"max":_vm.duration,"breakpoints":_vm.breakpoints},on:{"cursorPositionChange":_vm.cursorPositionChange,"change":_vm.handleChange,"ready":_vm.onPlayerTimelineReady,"dragStart":function($event){return _vm.proxy('dragStart')},"dragEnd":function($event){return _vm.proxy('dragEnd')}},scopedSlots:_vm._u([(_vm.ready)?{key:"contentMiddle",fn:function(){return [_c('div',{staticClass:"breakpoints"},_vm._l((_vm.breakpoints),function(item,index){return _c('div',{key:index,staticClass:"breakpoint",class:{
          done: _vm.position >= item,
          hideTooltip: _vm.position === item,
          cutted: _vm.isCuttedBreakpoints(item),
        },style:({ left: _vm.msToPercentage(item) + '%' }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleClickOnBreakpoint(item)},"mousemove":function($event){return _vm.handleFocusInBreakpoint(item, $event)},"mousedown":function($event){$event.preventDefault();return _vm.handleDragBreakpoint(item, $event)}}})}),0),(!_vm.isArticleEditPage)?_c('ArticleTimelineReactions',{attrs:{"article":_vm.article,"paragraph-offset-map":_vm.paragraphsOffsetMap,"ms-to-percentage":_vm.msToPercentage}}):_vm._e(),(_vm.trimRanges.length && _vm.allowEdit)?_c('div',{staticClass:"trim-ranges"},_vm._l((_vm.trimRanges),function(range,index){return _c('div',{key:range.key,staticClass:"trim-range"},[_c('div',{staticClass:"trim-cursor timeline-active-el",style:({ left: _vm.msToPercentage(range.start) + '%' }),on:{"mousedown":function($event){$event.preventDefault();return _vm.handleDragTrim(index, 'start', $event)},"click":function($event){$event.preventDefault();}}},[(_vm.allowEdit)?_c('span',{staticClass:"cutted-time"},[_vm._v(_vm._s(_vm.msToTime(_vm.trimRanges[0].start)))]):_vm._e()]),_c('div',{staticClass:"trim-line timeline-active-el",style:({
            left: _vm.msToPercentage(range.start) + '%',
            width: _vm.getTrimRangeWidth(range) + '%',
          })}),_c('div',{staticClass:"trim-cursor trim-cursor--right timeline-active-el",style:({ left: _vm.msToPercentage(range.end) + '%' }),on:{"mousedown":function($event){$event.preventDefault();return _vm.handleDragTrim(index, 'end', $event)},"click":function($event){$event.preventDefault();}}},[(_vm.allowEdit)?_c('span',{staticClass:"cutted-time"},[_vm._v(_vm._s(_vm.msToTime(_vm.trimRanges[0].end)))]):_vm._e()])])}),0):_vm._e()]},proxy:true}:null,(_vm.ready)?{key:"contentKnob",fn:function(){return [_c('div',{class:_vm.cursorTitleClass},[_vm._v(_vm._s(_vm.cursorTitle))])]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }