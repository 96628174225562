export enum SocketMessageType {
  RESULT = 'result',
  UPDATE = 'update',
  START = 'start',
  CANCEL = 'cancel',
  RECONNECT = 'reconnect',

  PONG = 'pong',
  PING = 'ping',
}
