
import Banner from './Banner.vue';
import Logo from '../../layout/shared/Logo.vue';
import { Component } from 'vue-property-decorator';
import * as routerNames from '@/routerNames';
import VueApp from '@/@types/app/VueApp';

@Component({
  components: {
    Banner,
    Logo,
  },
})
export default class AuthLayout extends VueApp {
  logoKey = 1;

  get form() {
    this.logoKey++;

    switch (this.$route.name) {
      case routerNames.LOGIN:
        return () => import('./LoginForm.vue');
      case routerNames.SIGNUP:
        return () => import('./SignUpForm/SignUpForm.vue');
      case routerNames.SIGNUP_INVITED:
        return () => import('./SignUpInvitedForm.vue');
      case routerNames.PASSWORD_RESET:
        return () => import('./PasswordReset.vue');
      case routerNames.PASSWORD_RESET_TOKEN:
        return () => import('./PasswordResetToken.vue');
      default:
        return null;
    }
  }
}
