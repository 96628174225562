import { companyActions } from '@/store/actions/companyActions';
import extensionService from '@/services/ExtensionService';
import apiService from '@/services/ApiService';
import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import { PostMessage } from '@/other/PostMessage';
import { ExtensionMessageType } from '@/@types/enums/ExtensionMessageType';

export const appActions = {
  async initializeApp(context: ActionContext<State, {}>) {
    await companyActions.loadCompanyByHostname(context);

    apiService.authToken && extensionService.logInUser(apiService.authToken);

    extensionService.addMessageListener((message) => {
      context.dispatch('postMessageReceived', message);
    });
    extensionService.updateExtensionInformation();

    context.commit('setAppState', 'ready');
  },
  async postMessageReceived(context: ActionContext<State, {}>, message: PostMessage) {
    if (message.message === ExtensionMessageType.EXTENSION_INFORMATION) {
      context.commit('setExtension', {
        id: message.payload.id,
        baseUrl: message.payload.baseUrl,
        isAvailable: true,
        isEnabled: true,
        version: message.payload.version,
      });
    }
  },
};
