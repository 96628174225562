
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Popover } from 'element-ui';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { NodeSelection } from 'prosemirror-state';
import { ResizeObserver } from '@juggle/resize-observer';
import { clamp } from '@/components/tiptap/utils/shared';
import { ImageDisplay, resolveImg } from '@/components/tiptap/utils/image';
import { Editor } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/vue-2';
import ImageBubbleMenu from '@/components/tiptap/Menu/MenuBubble/ImageBubbleMenu.vue';

const enum ResizeDirection {
  TOP_LEFT = 'tl',
  TOP_RIGHT = 'tr',
  BOTTOM_LEFT = 'bl',
  BOTTOM_RIGHT = 'br',
}

const MIN_SIZE = 20;
const MAX_SIZE = 100000;

@Component({
  components: {
    ImageBubbleMenu,
    NodeViewWrapper,
    [Popover.name]: Popover,
  },
})
export default class ImageView extends Vue {
  @Prop({
    type: ProsemirrorNode,
    required: true,
  })
  readonly node!: ProsemirrorNode;

  @Prop({ type: Editor, required: true })
  readonly editor!: Editor;

  @Prop({
    type: Function,
    required: true,
  })
  readonly getPos!: Function;

  @Prop({ type: Function, required: true })
  readonly updateAttributes!: Function;

  @Prop({
    type: Boolean,
    required: true,
  })
  readonly selected!: boolean;

  maxSize = {
    width: MAX_SIZE,
    height: MAX_SIZE,
  };

  originalSize = {
    width: 0,
    height: 0,
  };

  resizeOb = new ResizeObserver(() => {
    this.getMaxSize();
  });

  resizeDirections = [
    ResizeDirection.TOP_LEFT,
    ResizeDirection.TOP_RIGHT,
    ResizeDirection.BOTTOM_LEFT,
    ResizeDirection.BOTTOM_RIGHT,
  ];

  resizing = false;

  resizerState = {
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    dir: '',
  };

  private get src(): string {
    return this.node.attrs.src;
  }

  private get width(): number {
    return this.node.attrs.width;
  }

  private get height(): number {
    return this.node.attrs.height;
  }

  private get display(): ImageDisplay {
    return this.node.attrs.display;
  }

  private get imageViewClass() {
    return ['image-view', `image-view--${this.display}`];
  }

  private async created() {
    const result = await resolveImg(this.src);

    if (!result.complete) {
      result.width = MIN_SIZE;
      result.height = MIN_SIZE;
    }

    this.originalSize = {
      width: result.width,
      height: result.height,
    };
  }

  private mounted() {
    this.resizeOb.observe(this.editor.view.dom);
  }

  private beforeDestroy() {
    this.resizeOb.disconnect();
  }

  // https://github.com/scrumpy/tiptap/issues/361#issuecomment-540299541
  private selectImage() {
    const { state } = this.editor;
    let { tr } = state;
    const selection = NodeSelection.create(state.doc, this.getPos());
    tr = tr.setSelection(selection);
    this.editor.view.dispatch(tr);
  }

  /* invoked when window or editor resize */
  private getMaxSize() {
    const { width } = getComputedStyle(this.editor.view.dom);
    this.maxSize.width = parseInt(width, 10);
  }

  /* on resizer handler mousedown
   * record the position where the event is triggered and resize direction
   * calculate the initial width and height of the image
   */
  private onMouseDown(e: MouseEvent, dir: ResizeDirection): void {
    e.preventDefault();
    e.stopPropagation();

    this.resizerState.x = e.clientX;
    this.resizerState.y = e.clientY;

    const originalWidth = this.originalSize.width;
    const originalHeight = this.originalSize.height;
    const aspectRatio = originalWidth / originalHeight;

    let { width, height } = this.node.attrs;
    const maxWidth = this.maxSize.width;

    if (width && !height) {
      width = width > maxWidth ? maxWidth : width;
      height = Math.round(width / aspectRatio);
    } else if (height && !width) {
      width = Math.round(height * aspectRatio);
      width = width > maxWidth ? maxWidth : width;
    } else if (!width && !height) {
      width = originalWidth > maxWidth ? maxWidth : originalWidth;
      height = Math.round(width / aspectRatio);
    } else {
      width = width > maxWidth ? maxWidth : width;
    }

    this.resizerState.w = width;
    this.resizerState.h = height;
    this.resizerState.dir = dir;

    this.resizing = true;

    this.onEvents();
  }

  private onMouseMove(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
    if (!this.resizing) return;

    const { x, y, w, h, dir } = this.resizerState;

    const dx = (e.clientX - x) * (/l/.test(dir) ? -1 : 1);
    const dy = (e.clientY - y) * (/t/.test(dir) ? -1 : 1);

    this.updateAttributes({
      width: clamp(w + dx, MIN_SIZE, this.maxSize.width),
      height: Math.max(h + dy, MIN_SIZE),
    });
  }

  private onMouseUp(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
    if (!this.resizing) return;

    this.resizing = false;

    this.resizerState = {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      dir: '',
    };

    this.offEvents();
    this.selectImage();
  }

  private onEvents(): void {
    document.addEventListener('mousemove', this.onMouseMove, true);
    document.addEventListener('mouseup', this.onMouseUp, true);
  }

  private offEvents(): void {
    document.removeEventListener('mousemove', this.onMouseMove, true);
    document.removeEventListener('mouseup', this.onMouseUp, true);
  }
}
