import Focus from '@tiptap/extension-focus';
import Placeholder from '@tiptap/extension-placeholder';
import TipTapText from '@tiptap/extension-text';
import Color from '@tiptap/extension-color';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Highlight from '@/components/tiptap/extensions/Highlight';
import Italic from '@tiptap/extension-italic';
import Indent from '@/components/tiptap/extensions/Indent';
import Bold from '@tiptap/extension-bold';
import BlockQuote from '@tiptap/extension-blockquote';
import BulletList from '@tiptap/extension-bullet-list';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import FontFamily from '@tiptap/extension-font-family';
import FontSize from '@/components/tiptap/extensions/FontSize';
import ListItem from '@tiptap/extension-list-item';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import { CustomOrderedList } from '@/components/tiptap/extensions/OrderList';
import { Extensions } from '@tiptap/core';

export const extensions: Extensions = [
  TipTapText,
  Color,
  Focus.configure({
    className: 'has-focus',
    mode: 'all',
  }),
  Placeholder.configure({
    emptyEditorClass: 'is-editor-empty',
  }),
  HorizontalRule,
  Italic,
  Indent,
  Bold,
  FontFamily.configure({ types: ['textStyle'] }),
  FontSize,
  Highlight.configure({ multicolor: true }),
  BlockQuote,
  BulletList,
  Strike,
  Underline,
  Link.configure({ openOnClick: false }),
  ListItem,
  CustomOrderedList,
  TextAlign.configure({ types: ['heading', 'paragraph'] }),
  TextStyle,
  TaskList,
  TaskItem.configure({
    HTMLAttributes: {
      class: 'todo-item',
    },
  }),
];
