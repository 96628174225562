
import Component from 'vue-class-component';
import VueApp from '@/@types/app/VueApp';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class CreateGroupButton extends VueApp {
  @Prop() action: Function;
  @Prop() materialIconTitle: string;
}
