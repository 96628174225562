
import { dispatchNotificationsSidebarUpdate } from '@/store/dispatchers/notificationsSidebarDispatchers';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class NotificationButton extends Vue {
  onClick() {
    this.$store.commit('notificationsSidebarToggleVisible');
    dispatchNotificationsSidebarUpdate();
  }
}
