
import VueApp from '@/@types/app/VueApp';
import { CompanyWithStat, StatData } from '@/@types/CompanyWithStat';
import EmptyStateCard from '@/components/layout/cards/EmptyStateCard.vue';
import MyTable from '@/components/layout/MyTable/MyTable.vue';
import MyTableCell from '@/components/layout/MyTable/MyTableCell.vue';
import InlineIcon from '@/components/layout/shared/InlineIcon.vue';
import { Company } from '@/models/Company';
import { Logger } from '@/other/Logger';
import { LOGIN } from '@/routerNames';
import adminCompanyService from '@/services/company/AdminCompanyService';
import notificationService from '@/services/NotificationService';
import urlService from '@/services/UrlService';
import { MessageBoxInputData } from 'element-ui/types/message-box';
import { debounce } from 'lodash';
import { Component, Watch } from 'vue-property-decorator';

const log = new Logger('AdminCompanies');

@Component({
  name: 'AdminCompanies',
  computed: {
    Company() {
      return Company;
    },
  },
  components: { InlineIcon, EmptyStateCard, MyTable, MyTableCell },
})
export default class AdminCompanies extends VueApp {
  companies: Pick<Company, 'id' | 'title' | 'isAiEnabled' | 'isActivated' | 'isBilling'>[] = [];

  tableColumns = [
    { label: 'NAME | DOMAIN', sortBy: ['title'] },
    { label: 'USERS', sortBy: ['stat', 'usersCount'] },
    { label: 'WORKSPACES' },
    // { label: 'STORAGE' },
    { label: 'BILLING' },
    { label: 'AI', sortBy: ['isAiEnabled'] },
    { label: 'STATUS', sortBy: ['isActivated'] },
    { label: '' },
  ];

  loader: any = null;

  searchQuery = '';
  searchBillingStatus: boolean | string = '';
  searchAiStatus: boolean | string = '';
  searchCompanyStatus: boolean | string = '';

  // Infinite scroll variables
  isLoadingMoreCompanies = true;
  isPageLoading = false;
  currentPage = 1;
  PAGE_LIMIT = 22;

  get getCompanies() {
    return this.companies;
  }

  getCompanyStatus(company: Company): string {
    return company.isActivated ? 'Active' : 'Deactivated';
  }

  getCompanyAiStatus(company: Company): string {
    return company.isAiEnabled ? 'Yes' : 'No';
  }

  getCompanyBillingStatus(company: Company): string {
    return company.isBilling ? 'Yes' : 'No';
  }

  getCompanyStorageSize(company: CompanyWithStat): string {
    const companyStorageSizeInBytes = company.stat.storageSize || 0;
    let size: number;

    if (companyStorageSizeInBytes < 1024 ** 3) {
      // Convert to MB with max 1 decimal place
      size = companyStorageSizeInBytes / 1024 ** 2;
      return size.toFixed(1) + ' MB';
    } else {
      // Convert to GB without decimal places
      size = companyStorageSizeInBytes / 1024 ** 3;
      return size.toFixed(0) + ' GB';
    }
  }

  getCompanyUsersCount(company: CompanyWithStat): number {
    return company.stat.usersCount || 0;
  }

  getCompanyWorkspacesCount(company: CompanyWithStat): number {
    return company.stat.workspacesCount || 0;
  }

  handleOpenAdminCompany({ e, item }: { e: PointerEvent; item: Company }) {
    e.preventDefault();
    e.stopPropagation();
    const company = item;

    const isModifierPressed = e.metaKey || e.ctrlKey || e.button === 1;

    if (isModifierPressed) return window.open(urlService.getAdminCompanyViewUrl(company), '_blank');

    return this.$router.push({ params: { page: 'company', id: `${company.id}` } });
  }

  async load() {
    this.loader = this.$loading({ lock: true });

    this.currentPage = 1;
    this.isLoadingMoreCompanies = true;
    this.$api.admin
      .getCompanies({
        q: this.searchQuery.length ? this.searchQuery : undefined,
        isAiEnabled: typeof this.searchAiStatus === 'boolean' ? this.searchAiStatus : undefined,
        isBilling: typeof this.searchBillingStatus === 'boolean' ? this.searchBillingStatus : undefined,
        isActivated: typeof this.searchCompanyStatus === 'boolean' ? this.searchCompanyStatus : undefined,
      })
      .then((res) => {
        let companies = res.data.companies;
        companies = companies.map((item: { company: Company; stat: StatData }) => {
          return { ...Company.parse(item.company), stat: item.stat };
        });
        this.companies = companies;
      })
      .catch((res) => {
        this.$api.handleResponseError(res);
      })
      .finally(() => {
        this.loader?.close();
        this.loader = null;
      });
  }

  createCompanyDomain(company: Company) {
    const baseDomain = process.env.VUE_APP_SERVER_URL.indexOf('snaplore.com') >= 0 ? 'snaplore.com' : 'testinghub.xyz';
    this.$confirm(
      'Domain `' + company.slug + '.' + baseDomain + '` will be created for that company. Are you sure?',
      'Create domain confirmation',
      {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
    )
      .then(() => {
        const loader = this.$loading({});
        this.$api.admin
          .createDomainForCompany(company.id)
          .then((res) => {
            company.domain = res.data.domain;
          })
          .catch((res) => {
            this.$api.handleResponseError(res);
          })
          .finally(() => {
            loader.close();
          });
      })
      .catch((err) => {
        log.error(err);
      });
  }

  getDomainAddress(company: Company) {
    const defaultDomain: string = process.env.VUE_APP_URL;
    return company.domain ? 'https://' + company.domain : defaultDomain;
  }

  handleLoginCompany(company: Company) {
    this.$api.admin
      .loginAsCompany(company.id)
      .then((res) => {
        if (res.data.token) {
          const currentDomain = document.location.origin;
          const targetDomain = this.getDomainAddress(company);

          if (currentDomain !== targetDomain) {
            window.open(`${targetDomain}/#/login/${res.data.token}`, '_blank').focus();
          } else {
            this.$router.push({
              name: LOGIN,
              params: { token: res.data.token },
            });
          }
        }
      })
      .catch((res) => this.$api.handleResponseError(res));
  }

  handleChangeCompanyStatus(company: Company) {
    adminCompanyService.changeCompanyStatus(company, this.load);
  }

  handleChangeCompanyBilling(company: Company): void {
    adminCompanyService.changeCompanyBilling(company, (status) => {
      this.companies = this.companies.map((c) => (c.id === company.id ? { ...c, isBilling: status } : c));
    });
  }

  handleRemoveCompany(company: Company) {
    this.$prompt(
      'Are you sure that you want to delete company?. Please input your company name.',
      'Delete the company',
      {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
    ).then((data) => {
      const { value } = data as MessageBoxInputData;
      if (value === company.title) {
        this.confirmRemoveCompany(company);
      } else {
        notificationService.error("Input value doesn't match the company name");
      }
    });
  }

  confirmRemoveCompany(company: Company) {
    adminCompanyService.deleteCompany(company, this.load);
  }

  async scrollFetchCompanies(page = 1) {
    try {
      const res = await this.$api.admin.getCompanies({
        q: this.searchQuery.length ? this.searchQuery : undefined,
        page,
        isAiEnabled: typeof this.searchAiStatus === 'boolean' ? this.searchAiStatus : undefined,
        isBilling: typeof this.searchBillingStatus === 'boolean' ? this.searchBillingStatus : undefined,
        isActivated: typeof this.searchCompanyStatus === 'boolean' ? this.searchCompanyStatus : undefined,
      });

      let companies = res.data.companies;
      companies = companies.map((item: { company: Company; stat: StatData }) => {
        return { ...Company.parse(item.company), stat: item.stat };
      });
      return companies;
    } catch (err) {
      this.$api.handleResponseError(err);
    }
  }

  async handleScroll(event: any) {
    const target = event.target as HTMLElement;
    const distanceToBottom = target.scrollHeight - (target.scrollTop + target.clientHeight);

    const threshold = 200;

    if (distanceToBottom > threshold || !this.isLoadingMoreCompanies) return;

    this.isLoadingMoreCompanies = false;
    this.currentPage += 1;

    try {
      this.isPageLoading = true;
      const companies = await this.scrollFetchCompanies(this.currentPage);

      if (companies) {
        this.companies = [...this.companies, ...companies];
        this.isLoadingMoreCompanies = companies.length >= this.PAGE_LIMIT;
      } else {
        this.isLoadingMoreCompanies = false;
      }
    } finally {
      this.isPageLoading = false;
    }
  }

  handleSearchInput = debounce(async () => {
    this.currentPage = 1;

    await this.load();
  }, 500);

  created() {
    this.load();
  }

  @Watch('searchCompanyStatus')
  @Watch('searchBillingStatus')
  @Watch('searchAiStatus')
  onSearchChanged() {
    this.load();
  }
}
