<template>
  <div class="container"></div>
</template>

<script>
/* tslint:disable */
import { LOGIN } from '@/routerNames';

export default {
  name: 'LoginSocialCallbackPage',
  props: ['token'],
  data() {
    return {};
  },
  methods: {
    loginWithToken(token) {
      this.$router.push({ name: LOGIN, params: { token } });
    },
  },
  created() {
    this.loginWithToken(this.token);
  },
};
</script>
