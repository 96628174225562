import { Paragraph } from './Paragraph';

export class ParagraphSearchResult {
  static notFound() {
    const item = new ParagraphSearchResult();
    item.result = false;
    return item;
  }
  paragraph: Paragraph | null;
  parent: any;
  list: Paragraph[];
  key: any;
  private result = false;

  constructor(
    paragraph: Paragraph | null = null,
    parent: any = null,
    list: Paragraph[] | null = null,
    key: any = null
  ) {
    this.paragraph = paragraph;
    this.parent = parent;
    this.list = list !== null ? list : [];
    this.key = key;
    this.result = true;
  }

  hasResult() {
    return this.result;
  }
}
