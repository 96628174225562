
import VueApp from '@/@types/app/VueApp';
import { Component, Watch } from 'vue-property-decorator';
import {
  commitSetCommentsSidebarIsVisible,
  commitSetCommentsSidebarScrollToCommentId,
} from '@/store/commits/commentsSidebarCommits';
import { ArticleComment } from '@/models/article/ArticleComment';
import getTextNodeOffset from '@/components/tiptap/utils/getTextNodeOffset';

type CommentIcon = { commentId: string; icon: string; top: number; left: number; isComment?: boolean };
const ICON_SHIFT = 12;

@Component({})
export default class TipTapCommentsIconsSidebar extends VueApp {
  protected containerSelector = '#article-text-editor';
  commentIcons: CommentIcon[] = [];

  update() {
    const container = document.querySelector<HTMLElement>(this.containerSelector);
    const comments = this.$store.state.commentsSidebar.comments.filter((comment) => {
      return comment?.quote?.paragraphKey;
    });

    const commentIcons: CommentIcon[] = this.removeDeletedIcons(comments);

    comments.forEach((comment) => {
      const commentId = comment.id;
      if (!comment?.quote?.icon) return;

      const isExisting = commentIcons.find((icon) => icon.commentId === commentId);
      if (isExisting) return;

      const element = container.querySelector<HTMLElement>('[key="' + comment.quote.paragraphKey + '"]');
      if (!element) return;

      const elementClone = element.cloneNode(true) as HTMLElement;
      elementClone.style.visibility = 'hidden';
      element.parentNode.appendChild(elementClone);

      const textElement = elementClone.tagName === 'PRE' ? elementClone.firstElementChild : elementClone;
      const offsetTop = this.getRelativeOffsetTop(element, container);
      const textIndex = this.getParagraphTextIndex(comment, element);
      const textNodeOffsetTop = getTextNodeOffset(textElement, textIndex, comment.quote.paragraphText.length).top;
      const textOffset = textNodeOffsetTop - elementClone.getBoundingClientRect().top;
      const isCommentIcon = comment?.quote?.icon === 'comment';
      const top = offsetTop + textOffset;

      elementClone.parentNode.removeChild(elementClone);

      commentIcons.push({
        commentId,
        top: top,
        left: this.getLeftOffset(top, commentIcons),
        icon: comment.quote?.icon || '',
        isComment: isCommentIcon,
      });
    });

    this.commentIcons = commentIcons;
  }

  removeDeletedIcons(comments: ArticleComment[]) {
    return this.commentIcons.filter((icon) => {
      return !!comments.find((comment) => comment.id === icon.commentId);
    });
  }

  getRelativeOffsetTop(element: HTMLElement, container: HTMLElement) {
    let offsetTop = 0;
    let node = element;
    while (node !== container) {
      offsetTop += node.offsetTop;
      node = node.offsetParent as HTMLElement;
    }

    return offsetTop;
  }

  getParagraphTextIndex(comment: ArticleComment, node: HTMLElement) {
    const baseIndex = node.textContent.indexOf(comment.quote.paragraphText);
    const isExists = baseIndex >= 0;
    let index = -1;

    if (isExists) {
      if (comment.quote.paragraphTextBefore && comment.quote.paragraphTextOffset) {
        index = node.textContent.indexOf(
          comment.quote.paragraphTextBefore + comment.quote.paragraphText,
          comment.quote.paragraphTextOffset - comment.quote.paragraphTextBefore.length
        );
        if (index >= 0) index += comment.quote.paragraphTextBefore.length;
      } else if (index < 0 && comment.quote.paragraphTextBefore) {
        index = node.textContent.indexOf(comment.quote.paragraphTextBefore + comment.quote.paragraphText, 0);
      }
    }
    if (index < 0) index = baseIndex;

    return index;
  }

  getLeftOffset(top: number, commentsIcons: CommentIcon[]): number {
    return (
      commentsIcons
        .map((item) => item.top)
        .reduce((prev, item) => {
          return prev + (item === top ? 1 : 0);
        }, 0) * ICON_SHIFT
    );
  }

  onClickComment(commentId: string) {
    commitSetCommentsSidebarScrollToCommentId(commentId);
    commitSetCommentsSidebarIsVisible(true);
  }

  mounted() {
    this.$nextTick(() => {
      this.update();
    });
  }

  @Watch('$store.state.commentsSidebar.comments')
  onCommentsChanged() {
    this.update();
  }

  @Watch('$store.state.workspaceView.selectedArticle')
  onSelectedArticleChanged() {
    this.commentIcons = [];
  }
}
