
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Popover } from 'element-ui';
import { Editor } from '@tiptap/core';
import EmojiIconButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/EmojiIconButton.vue';
import MenuCommentButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/MenuCommentButton.vue';

const EMOJIS = ['👍', '😀', '😉', '😧', '😎', '😢', '😡', '🔥', '❤️'];

@Component({
  components: {
    MenuCommentButton,
    EmojiIconButton,
    Popover,
  },
})
export default class EmojiButton extends Vue {
  @Prop({ default: null }) editor: Editor;
  @Prop({ default: null }) commentId: string;

  emojis = EMOJIS;
  popoverVisible = false;

  get popupPosition(): string {
    return this.editor ? 'bottom' : 'top';
  }

  closePopover(): void {
    this.popoverVisible = false;
  }
}
