
import { Component } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';

@Component
export default class AdminSidebar extends VueApp {
  isActive(page: string) {
    return this.$route.params.page === page;
  }

  navigate(page: string) {
    this.$router.push({ name: this.$route.name, params: { page } });
  }
}
