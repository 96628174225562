
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import MenuRemoveLinkButton from '@/components/tiptap/Menu/MenuItems/Link/MenuRemoveLinkButton.vue';
import MenuOpenLinkButton from '@/components/tiptap/Menu/MenuItems/Link/MenuOpenLinkButton.vue';
import MenuEditLinkButton from '@/components/tiptap/Menu/MenuItems/Link/MenuEditLinkButton.vue';
import '@/components/tiptap/Menu/MenuBubble/MenuBubble.scss';

@Component({
  components: {
    MenuEditLinkButton,
    MenuOpenLinkButton,
    MenuRemoveLinkButton,
  },
})
export default class LinkBubbleMenu extends Vue {
  @Prop({ required: true }) editor: Editor;

  private get linkAttrs() {
    return this.editor.getAttributes('link') as { href: string; target: string };
  }
}
