
import { Component, Emit, Vue } from 'vue-property-decorator';
import { Popover } from 'element-ui';

const INIT_GRID_SIZE = 5;
const MAX_GRID_SIZE = 10;
const DEFAULT_SELECTED_GRID_SIZE = 2;

interface GridSize {
  row: number;
  col: number;
}

@Component({
  components: {
    [Popover.name]: Popover,
  },
})
export default class CreateTablePopover extends Vue {
  private popoverVisible = false;

  tableGridSize: GridSize = {
    row: INIT_GRID_SIZE,
    col: INIT_GRID_SIZE,
  };

  selectedTableGridSize: GridSize = {
    row: DEFAULT_SELECTED_GRID_SIZE,
    col: DEFAULT_SELECTED_GRID_SIZE,
  };

  selectTableGridSize(row: number, col: number): void {
    if (row === this.tableGridSize.row) {
      this.tableGridSize.row = Math.min(row + 1, MAX_GRID_SIZE);
    }

    if (col === this.tableGridSize.col) {
      this.tableGridSize.col = Math.min(col + 1, MAX_GRID_SIZE);
    }

    this.selectedTableGridSize.row = row;
    this.selectedTableGridSize.col = col;
  }

  @Emit('createTable')
  onMouseDown(row: number, col: number): GridSize {
    this.popoverVisible = false;

    return { row, col };
  }

  resetTableGridSize(): void {
    this.tableGridSize = {
      row: INIT_GRID_SIZE,
      col: INIT_GRID_SIZE,
    };

    this.selectedTableGridSize = {
      row: DEFAULT_SELECTED_GRID_SIZE,
      col: DEFAULT_SELECTED_GRID_SIZE,
    };
  }
}
