<template>
  <div class="card">
    <div class="card-header" v-if="enableHeader !== false">
      <div class="actions">
        <slot name="actions"></slot>
      </div>
      <h4 class="card-category" v-if="!!category">{{ category }}</h4>
      <h2 class="card-title" v-if="!!title">{{ title }}</h2>
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/* tslint:disable */
export default {
  name: 'EmptyCard',
  props: ['title', 'category', 'enableHeader'],
};
</script>
