
import { Article } from '@/models/article/Article';
import { Watch, Component } from 'vue-property-decorator';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import notificationService from '@/services/NotificationService';
import VueApp from '@/@types/app/VueApp';
import apiService from '@/services/ApiService';

@Component({})
export default class ArticlesMergeDialog extends VueApp {
  visible = false;
  left: string | null = null;
  right: string | null = null;
  resolve: any = null;
  reject: any = null;

  get workspaceView() {
    return workspaceService.workspaceView;
  }

  get articlesWithoutLeft() {
    return this.articles.filter((article) => article.id !== this.left);
  }

  get articles() {
    const filteredArticles = this.workspaceView.articles.filter((article: Article) => article.isArchived !== true);
    return filteredArticles ? filteredArticles : [];
  }

  show(article: Article): Promise<Article> {
    this.left = article.id + '';
    this.right = '';
    if (this.articles[0].id !== article.id) {
      this.right = this.articles[0].id + '';
    } else if (this.articles.length > 1) {
      this.right = this.articles[1].id + '';
    }
    this.visible = true;

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  close() {
    if (typeof this.reject === 'function') {
      this.reject();
    }
    this.visible = false;
  }

  async save() {
    if (!this.right) {
      notificationService.error('Target article not selected');
      return;
    }
    if (this.right === this.left) {
      notificationService.error('You cannot merge article into itself');
      return;
    }
    const leftArticle = this.articles.filter((item: Article) => item.id === this.left)[0];
    const rightArticle = this.articles.filter((item: Article) => item.id === this.right)[0];

    const loader = this.$loading({
      lock: true,
      text: 'Merging Articles',
    });

    try {
      await this.$api.article.merge(this.left, this.right);
      const newOrder = workspaceService.onChangeOrder(this.workspaceView.workspace.order, leftArticle.id);

      await apiService.workspace.updateArticlesOrder(this.workspaceView.workspace.id, newOrder);
      try {
        await workspaceService.loadWorkspaceById(this.workspaceView.workspace.id);
      } catch (error) {
        this.$api.handleResponseError(error);
      }
      this.visible = false;
      this.resolve(rightArticle);
    } catch (err) {
      this.$api.handleResponseError(err);
    } finally {
      loader.close();
    }
  }

  @Watch('left')
  onLeftChanged(value: string) {
    if (value === this.right) {
      this.right = '';
    }
  }
}
