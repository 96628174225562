
import { Component, Prop } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import './ProfilePhotoInput.scss';
import notificationService from '@/services/NotificationService';

const IMAGE_SIZE_LIMIT = 3000000;

@Component({})
export default class ProfilePhotoInput extends VueApp {
  @Prop({ default: '' }) imageSrc: string;

  $refs: {
    personalPhotoUploadInput: any;
  };

  get imageUrl() {
    return this.$store.state.user?.profileImagePreview ?? null;
  }

  set imageUrl(url) {
    this.$store.state.user.profileImagePreview = url;
  }

  getImageSizeInMb() {
    return `${IMAGE_SIZE_LIMIT / 1000000} MB`;
  }

  uploadPhoto(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    const photoFiles = e.dataTransfer.files[0];
    const fileFormat = ['image/png', 'image/jpeg'];
    if (photoFiles) {
      if (fileFormat.indexOf(photoFiles.type) < 0) {
        notificationService.error(`Not allowed file format`);
        return;
      }
      if (photoFiles.size > IMAGE_SIZE_LIMIT) {
        notificationService.error(`File size should be less than ${this.getImageSizeInMb()}`);
        return;
      }
      this.imageUrl = URL.createObjectURL(photoFiles);
      this.$emit('set-profile-image', photoFiles);
    }
  }

  showSelectPhotoDialog() {
    this.$refs.personalPhotoUploadInput.click();
  }

  selectImage() {
    const personalPhotoInput = this.$refs.personalPhotoUploadInput;
    if (personalPhotoInput.files && personalPhotoInput.files[0]) {
      if (personalPhotoInput.files[0].size > IMAGE_SIZE_LIMIT) {
        notificationService.error(`File size should be less than ${this.getImageSizeInMb()}`);
        personalPhotoInput.value = null;
        return;
      }
      this.imageUrl = URL.createObjectURL(personalPhotoInput.files[0]);
      this.$emit('set-profile-image', this.$refs.personalPhotoUploadInput.files[0]);
    }
  }

  deletePersonalPhoto() {
    this.imageUrl = null;
    this.$refs.personalPhotoUploadInput.files = null;
    this.$emit('delete-profile-image');
  }

  mounted() {
    this.imageUrl = this.imageSrc;
  }
}
