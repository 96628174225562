
import MenuBulletListButton from '@/components/tiptap/Menu/MenuItems/List/MenuBulletListButton.vue';
import MenuOrderedListButton from '@/components/tiptap/Menu/MenuItems/List/MenuOrderedListButton.vue';
import MenuColorButton from '@/components/tiptap/Menu/MenuItems/MenuColorButton.vue';
import MenuHeadingDropdown from '@/components/tiptap/Menu/MenuItems/MenuHeadingDropdown.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AllSelection, TextSelection } from 'prosemirror-state';
import { Editor } from '@tiptap/core';
import { BubbleMenu } from '@tiptap/vue-2';
import { TipTapEditorContext } from '@/components/tiptap/types';
import LinkBubbleMenu from './LinkBubbleMenu.vue';
import TextCommentBubbleMenu from '@/components/tiptap/Menu/MenuBubble/TextCommentBubbleMenu.vue';
import MenuBoldButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/BoldButton.vue';
import MenuItalicButton from '@/components/tiptap/Menu/MenuItems/MenuItalicButton.vue';
import MenuUnderlineButton from '@/components/tiptap/Menu/MenuItems/MenuUnderlineButton.vue';
import MenuStrikeButton from '@/components/tiptap/Menu/MenuItems/MenuStrikeButton.vue';
import MenuAddLinkButton from '@/components/tiptap/Menu/MenuItems/Link/MenuAddLinkButton.vue';
import MenuRemoveLinkButton from '@/components/tiptap/Menu/MenuItems/Link/MenuRemoveLinkButton.vue';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import ImageBubbleMenu from '@/components/tiptap/Menu/MenuBubble/ImageBubbleMenu.vue';
import { UpdateBubbleMenuPositionPlugin } from '@/components/tiptap/plugins/UpdateBubbleMenuPositionPlugin';
import MenuAiFeaturesDropdownButton from '@/components/tiptap/Menu/MenuItems/AiFeatures/MenuAiFeaturesDropdownButton.vue';

const enum MenuType {
  NONE = 'none',
  DEFAULT = 'default',
  LINK = 'link',
}

@Component({
  components: {
    MenuBulletListButton,
    MenuOrderedListButton,
    MenuColorButton,
    MenuHeadingDropdown,
    MenuAiFeaturesDropdownButton,
    TextCommentBubbleMenu,
    ImageBubbleMenu,
    MenuButton,
    MenuRemoveLinkButton,
    MenuAddLinkButton,
    MenuStrikeButton,
    MenuUnderlineButton,
    MenuItalicButton,
    MenuBoldButton,
    BubbleMenu,
    LinkBubbleMenu,
  },
})
export default class MenuBubble extends Vue {
  @Prop({ required: true }) isEditable: boolean;
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  activeMenu: MenuType = MenuType.NONE;
  isLinkBack = false;

  get tippyOptions() {
    return {
      maxWidth: 500,
      hideOnClick: true,
      plugins: [UpdateBubbleMenuPositionPlugin({ editorContext: this.editorContext })],
    };
  }
  get isDefaultMenuActive() {
    return this.activeMenu === MenuType.DEFAULT;
  }

  get isLinkMenuActive() {
    return this.activeMenu === MenuType.LINK;
  }

  get isActiveMenu(): boolean {
    return !!this.activeMenu;
  }

  private get isLinkSelection(): boolean {
    return this.editorContext.state.isActiveLink;
  }

  shouldShow() {
    //set null to not display old bubble menu type
    //TODO: if bubble menu is not displayed, the menu is not updated
    // this.setMenuType(null);
    const isEmpty = this.editor.state.selection.empty;

    return (
      !this.editorContext.state.isActiveImage &&
      !this.editorContext.state.isActiveAiWriter &&
      !this.editorContext.state.isActiveAiImage &&
      !this.editorContext.state.isActiveImageUpload &&
      !isEmpty
    );
  }

  linkBack() {
    this.setMenuType(MenuType.DEFAULT);
    this.isLinkBack = true;
  }

  @Watch('editorContext.selection')
  onSelectionChange() {
    if (this.isLinkSelection) {
      if (!this.isLinkBack) {
        this.setMenuType(MenuType.LINK);
      }
    } else {
      this.activeMenu = this.getCurrentMenuType();
      this.isLinkBack = false;
    }
  }

  setMenuType(type: MenuType) {
    this.activeMenu = type;
  }

  getCurrentMenuType(): MenuType {
    if (this.isLinkSelection) return MenuType.LINK;
    if (this.editor.state.selection instanceof TextSelection || this.editor.state.selection instanceof AllSelection) {
      return MenuType.DEFAULT;
    }
    return MenuType.NONE;
  }
}
