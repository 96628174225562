import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { VueRenderer } from '@tiptap/vue-2';
import tippy, { Instance } from 'tippy.js';
import MentionDetailsView from '@/components/tiptap/extensions/Mention/MentionDetailsView.vue';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import store from '@/store';
import logger from '@/other/Logger';

type State = { isVisible: boolean };

class MentionDetailsPlugin {
  popup: Instance[];
  component: VueRenderer;
  node: ProsemirrorNode;
  element: HTMLElement;

  constructor() {
    this.update();
  }

  update() {
    if (!this.element) return;
    const getReferenceClientRect = () => this.element.getBoundingClientRect();
    this.popup[0].setProps({ getReferenceClientRect });
  }

  show(userId: string, node: ProsemirrorNode, element: HTMLElement) {
    const user = store.state.companyUsers.find((user) => user.id === userId);

    if (!user) {
      logger.error('Mention details can not be shown: user not found #' + userId);
      return;
    }

    const component = new VueRenderer(MentionDetailsView, {
      parent: null,
      propsData: { user },
    });

    const getReferenceClientRect = () => element.getBoundingClientRect();

    this.component = component;
    this.element = element;
    this.node = node;
    this.popup = tippy('body', {
      getReferenceClientRect,
      appendTo: () => document.body,
      content: component.element,
      showOnCreate: true,
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
    });
  }

  destroy() {
    if (this.popup && this.popup[0]) {
      this.popup[0].destroy();
    }

    if (this.component) {
      this.component.destroy();
    }
  }
}

let viewInstance: MentionDetailsPlugin = null;

const mentionDetailsPlugin = new Plugin<State>({
  key: new PluginKey('MentionDetails'),
  view() {
    viewInstance = new MentionDetailsPlugin();
    return viewInstance;
  },
  state: {
    init() {
      return { isVisible: false };
    },
    apply(tr, state) {
      return state;
    },
  },
  props: {
    handleClickOn(view: EditorView, pos: number, node: ProsemirrorNode, nodePos: number, event: MouseEvent) {
      if (node.type.name === 'mention') {
        const target = event.target as HTMLElement;
        const userId = target.dataset['id'];
        viewInstance.show(userId, node, target);
      }
      return true;
    },
  },
});

export default mentionDetailsPlugin;
