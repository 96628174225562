
import { Component, Prop, Watch } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import './CompanyLogoInput.scss';
import notificationService from '@/services/NotificationService';

const LOGO_SIZE_LIMIT = 1024 * 1024; // Logo size limit in bytes (1 MB)

@Component({})
export default class CompanyLogoInput extends VueApp {
  @Prop({ default: '' }) logoSrc: string;

  reset = false;

  $refs: {
    companyLogoUploadInput: any;
  };

  get logo() {
    return this.$store.state.company.logoPreview;
  }

  set logo(img) {
    this.$store.state.company.logoPreview = img;
  }

  uploadLogo(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    const photoFiles = e.dataTransfer.files[0];
    const fileFormat = ['image/png', 'image/jpeg'];
    if (photoFiles) {
      if (fileFormat.indexOf(photoFiles.type) < 0) {
        notificationService.error(`Not allowed file format`);
        return;
      }
      if (photoFiles.size > LOGO_SIZE_LIMIT) {
        notificationService.error(`File size should be less than 1MB`);
        return;
      }
      this.logo = URL.createObjectURL(photoFiles);
      this.$emit('set-company-logo', photoFiles);
    }
  }

  showSelectLogoDialog() {
    this.$refs.companyLogoUploadInput.click();
  }

  selectLogo() {
    const companyLogoInput = this.$refs.companyLogoUploadInput;
    if (companyLogoInput.files && companyLogoInput.files[0]) {
      if (companyLogoInput.files[0].size > LOGO_SIZE_LIMIT) {
        notificationService.error(`File size should be less than 1 MB`);
        companyLogoInput.value = null;
        return;
      }
      this.logo = URL.createObjectURL(companyLogoInput.files[0]);
      this.$emit('set-company-logo', this.$refs.companyLogoUploadInput.files[0]);
    }
  }

  deleteCompanyLogo() {
    this.logo = null;
    this.reset = true;
    this.$nextTick(() => {
      this.reset = false;
    });
    this.$refs.companyLogoUploadInput.files = null;
    this.$emit('delete-company-logo');
  }

  mounted() {
    this.logo = this.logoSrc;
  }
  @Watch('logoSrc')
  handleLogoChange(val: string) {
    if (!val) {
      this.deleteCompanyLogo();
    }
  }
}
