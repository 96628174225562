import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import articleEditService from '@/services/article/ArticleEditService';
import notificationService from '@/services/NotificationService';
import authService from '@/services/AuthService';
import apiService from '@/services/ApiService';
import { Article } from '@/models/article/Article';
import router from '@/router';
import { Loading } from 'element-ui';
import urlService from '@/services/UrlService';
import { dispatchAutosaveArticle, dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { Logger } from '@/other/Logger';
import {
  commitSetArticleEditorArticle,
  commitSetArticleEditorIsAutosaveEnabled,
  commitSetArticleEditorSelectedTab,
} from '@/store/commits/articleEditorCommits';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import navigationService from '@/services/NavigationService';
import { ARTICLE_EDIT } from '@/routerNames';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { dispatchGetDraftsCount, dispatchGetPersonalArticlesCount } from '@/store/dispatchers/uiDispatchers';
import extensionService from '@/services/ExtensionService';
import articleModeService from '@/services/article/ArticleModeService';
import slugify from 'slugify';
import { TextTab } from '@/models/article/TextTab';

const log = new Logger('Actions');

let isOngoingSaveRequest = false;
let saveRequestsCount = 0;

export const articleEditorActions = {
  async articleEditorAutosaveArticle({ state }: ActionContext<State, {}>) {
    log.info('articleEditorAutosaveArticle ' + state.articleEditor.isAutosaveEnabled);
    if (!state.articleEditor.isAutosaveEnabled) return;

    const userId = state.user.id;

    if (isOngoingSaveRequest) {
      saveRequestsCount += 1;
      return;
    }
    isOngoingSaveRequest = true;
    saveRequestsCount = 0;

    await articleEditService
      .save({ updatedByUser: userId, isPublish: false })
      .catch((err) => {
        apiService.handleResponseError(err);
      })
      .finally(async () => {
        isOngoingSaveRequest = false;

        if (saveRequestsCount > 0) {
          await dispatchAutosaveArticle();
        } else {
          await dispatchGetDraftsCount();
        }
      });
  },
  async articleEditorSaveArticle(
    { state }: ActionContext<State, {}>,
    { isPublish, isNotification }: { isPublish: boolean; isNotification: boolean }
  ) {
    log.info('articleEditorSaveArticle');
    const userId = authService.user.id;
    const workspace = state.articleEditor.workspace;

    commitSetArticleEditorIsAutosaveEnabled(false);
    const loadingInstance = Loading.service({ text: `${isPublish ? 'Publishing' : 'Saving'} article` });

    try {
      const originalArticleId = state.articleEditor.article.id;
      const result = await articleEditService.save({ updatedByUser: userId, isPublish, isNotification });
      const article = Article.fromJson(result.data.article);

      if (originalArticleId !== article.id) {
        extensionService.notifyArticlePublished(originalArticleId, article);
      }

      await dispatchGetDraftsCount();
      await dispatchGetPersonalArticlesCount();

      if (isPublish) {
        notificationService.success('The article has been successfully published!');

        const mode = articleModeService.getArticleMode(article, state.articleMode);
        if (articleEditService.articleEditor.article.textTabs.length) {
          const firstTextTab = articleEditService.articleEditor.article.textTabs[0];
          await dispatchUpdateSelectedTextTab(firstTextTab);

          await router.push(urlService.getArticleViewRoute(article, workspace, ArticleEditMode.NOTES));
        } else {
          await dispatchUpdateSelectedTextTab(null);
          await router.push(urlService.getArticleViewRoute(article, workspace, mode));
        }
      }
    } catch (err) {
      apiService.handleResponseError(err);
    } finally {
      loadingInstance.close();
      commitSetArticleEditorIsAutosaveEnabled(true);
    }
  },

  async openArticleEditor(
    { state }: ActionContext<State, {}>,
    { articleId, mode }: { articleId: string; mode: ArticleEditMode }
  ) {
    const loader = Loading.service({
      lock: true,
      text: 'Loading Article',
    });

    try {
      const res = await apiService.article.get(articleId, true);
      const article = Article.fromJson(res.data.article);
      commitSetArticleEditorArticle(article);
      commitSetArticleMode(state.articleMode);

      await navigationService.navigate({ name: ARTICLE_EDIT, params: { id: article.original || article.id, mode } });
    } catch (err) {
      apiService.handleResponseError(err);
    }

    if (loader) {
      loader.close();
    }
  },
  async articleEditorUpdateSelectedTextTab(_: ActionContext<State, {}>, textTab: TextTab | null) {
    const slug = !textTab ? null : slugify(textTab.title);
    commitSetArticleEditorSelectedTab({ slug, textTab });
  },
};
