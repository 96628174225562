import appStore from '@/store';
import { ArticleComment } from '@/models/article/ArticleComment';

export const dispatchGetArticleCommentsCount = () => {
  return appStore.dispatch('getArticleCommentsCount');
};
export const dispatchLoadArticleComments = () => {
  return appStore.dispatch('loadArticleComments');
};

export const dispatchUpdateArticleComment = (
  commentId: string,
  timestamp: number | null = -1,
  content: string | null = null,
  reaction: { content: string; label: string } | null = null
) => {
  return appStore.dispatch('updateArticleComment', { commentId, timestamp, content, reaction });
};

export const dispatchCreateNewComment = (comment: ArticleComment) => {
  return appStore.dispatch('createNewComment', comment);
};

export const dispatchDeleteArticleComment = (comment: ArticleComment) => {
  return appStore.dispatch('deleteArticleComment', { comment });
};
