
import { Component, Prop } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import { Company } from '@/models/Company';

@Component
export default class AdminCompanyGeneralSection extends VueApp {
  @Prop({ type: Object, required: true }) company: Company;
  loader: any = null;
}
