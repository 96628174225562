
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Popover } from 'element-ui';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import MenuTextAlignButtons from '@/components/tiptap/Menu/MenuItems/TextAlign/MenuTextAlignButtons.vue';
import MenuIndentButton from '@/components/tiptap/Menu/MenuItems/MenuIndentButton.vue';
import MenuOutdentButton from '@/components/tiptap/Menu/MenuItems/MenuOutdentButton.vue';
import DropdownButton from '@/components/tiptap/Menu/DropdownButton.vue';

@Component({
  components: {
    DropdownButton,
    MenuOutdentButton,
    MenuIndentButton,
    MenuTextAlignButtons,
    MenuButton,
    [Popover.name]: Popover,
  },
})
export default class TextAlignPopover extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  private popoverVisible = false;

  hidePopover(): void {
    this.popoverVisible = false;
  }
}
