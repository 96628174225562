
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types/TipTapEditorContext';
import { FontFamily, FONTS_LIST } from '@/components/tiptap/constants';
import DropdownButton from '@/components/tiptap/Menu/DropdownButton.vue';

@Component({
  components: {
    DropdownButton,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
})
export default class MenuFontFamilyDropdown extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  fontFamily = this.getFontTitle(FontFamily.Arial);
  isVisible = false;

  private get fontList() {
    return FONTS_LIST;
  }

  get isWideDropdown() {
    return (
      this.fontFamily === this.getFontTitle(FontFamily.RobotoCondensed) ||
      this.fontFamily === this.getFontTitle(FontFamily.TimesNewRoman)
    );
  }

  isActive(font?: string): boolean {
    if (font) {
      return font === this.editorContext.state.activeFontFamily;
    }

    return this.editorContext.state.isActiveFontFamily;
  }

  updateFontFamily(font: any) {
    this.editor.chain().focus().setFontFamily(font.value).run();
    this.fontFamily = font.title;
  }

  handleVisibleChange(visible: boolean) {
    this.isVisible = visible;
  }

  getFontTitle(font: FontFamily) {
    const item = FONTS_LIST.find(({ value }) => value === font);

    return item ? item.title : FONTS_LIST[0].title;
  }

  updateFontFamilyFromState() {
    const state = this.editorContext.state;

    if (state.isActiveFontFamily) {
      const fontFamily = state.activeFontFamily;

      this.fontFamily = this.getFontTitle(fontFamily as FontFamily);
    }
  }

  @Watch('editorContext.state')
  onEditorStateChanged() {
    this.updateFontFamilyFromState();
  }
}
