export interface UiState {
  draftsCount: number;
  meetingsCount: number;
  personalArticlesCount: number;
  isMainSidebar: boolean;
  isMainSidebarExpanded: boolean;
  isWorkspacesSidebar: boolean;
  isArticleVersionsSidebar: boolean;
  isOcrModeEnabled: boolean;
  isOcrRecognitionFinished: boolean;
  isExtensionBannerVisible: boolean;
  isWorkspaceCreateDialogVisible: boolean;
  isNewNotifications: boolean;
  sidebarWidth: number;
}

export const uiState: UiState = {
  draftsCount: 0,
  meetingsCount: 0,
  personalArticlesCount: 0,
  isMainSidebar: true,
  isMainSidebarExpanded: true,
  isNewNotifications: false,
  isWorkspacesSidebar: false,
  isArticleVersionsSidebar: false,
  isOcrModeEnabled: false,
  isOcrRecognitionFinished: false,
  sidebarWidth: 0,
  isExtensionBannerVisible: false,
  isWorkspaceCreateDialogVisible: false,
};
