<template>
  <div class="my-table-pagination">
    <!-- Previous button -->
    <md-button
      class="my-table-pagination__button my-table-pagination__button--prev"
      :disabled="currentPage <= 1"
      @click="updatePage(currentPage - 1)"
    >
      <md-icon>chevron_left</md-icon>
    </md-button>

    <!-- Page selector -->
    <md-field class="my-table-pagination__selector-field">
      <md-select
        :disabled="totalPages === 0"
        v-model="selectedPage"
        @md-selected="updatePage"
        class="my-table-pagination__selector"
      >
        <md-option v-for="page in totalPages" :key="page" :value="page">
          {{ page }}
        </md-option>
      </md-select>
    </md-field>

    <span class="my-table-pagination__info">of {{ totalPages }} pages</span>

    <!-- Next button -->
    <md-button
      class="my-table-pagination__button my-table-pagination__button--next"
      :disabled="currentPage >= totalPages"
      @click="updatePage(currentPage + 1)"
    >
      <md-icon>chevron_right</md-icon>
    </md-button>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import './MyTablePagination.scss';

const props = defineProps({
  totalPages: { type: Number, required: true },
  currentPage: { type: Number, required: true },
});

const emit = defineEmits(['updatePage']);

const selectedPage = ref(props.currentPage);

// Keep `selectedPage` in sync with `currentPage`
watch(
  () => props.currentPage,
  (newPage) => {
    selectedPage.value = newPage;
  }
);

const updatePage = (page) => {
  if (page >= 1 && page <= props.totalPages && page !== props.currentPage) {
    emit('updatePage', page);
  }
};
</script>
