import { Company } from '@/models/Company';
import store from '@/store';
import { User } from '@/models/User';

export const commitSetCompanyUsers = (users: User[]) => {
  store.commit('setCompanyUsers', users);
};

export const commitSetCompanyLogo = (logo: string) => {
  store.commit('setCompanyLogo', logo);
};

export const commitSetCompanyPaddle = (paddle: Company['paddle']) => {
  store.commit('setCompanyPaddle', paddle);
};
