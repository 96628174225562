import appStore from '@/store';
import { Article } from '@/models/article/Article';
import { Paragraph } from '@/models/article/Paragraph';

export const dispatchWorkspaceViewSelectFirstArticle = () => {
  return appStore.dispatch('workspaceViewSelectFirstArticle');
};

export const dispatchWorkspaceViewPageMounted = () => {
  return appStore.dispatch('workspaceViewPageMounted');
};

export const dispatchWorkspaceViewWorkspaceLoaded = () => {
  return appStore.dispatch('workspaceViewWorkspaceLoaded');
};

export const dispatchWorkspaceViewSelectedArticleChanged = () => {
  return appStore.dispatch('workspaceViewSelectedArticleChanged');
};

export const dispatchOpenSelectedArticle = ({ article, newTab = false }: { article: Article; newTab?: boolean }) => {
  return appStore.dispatch('openSelectedArticle', { article, newTab });
};

export const dispatchOpenSelectedParagraph = ({
  paragraph,
  article,
  newTab,
}: {
  paragraph: Paragraph;
  article: Article;
  newTab?: boolean;
}) => {
  return appStore.dispatch('openSelectedParagraph', { paragraph, article, newTab });
};
