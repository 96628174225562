
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';
import MenuCommentButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/MenuCommentButton.vue';

@Component({
  components: {
    MenuCommentButton,
  },
})
export default class BulletListButton extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  get isActive() {
    return this.editorContext.state.isActiveBulletList;
  }

  runCommand() {
    this.editor.chain().focus().toggleBulletList().run();
  }
}
