
import VueApp from '@/@types/app/VueApp';
import { Component } from 'vue-property-decorator';
import { Article } from '@/models/article/Article';
import { ARTICLE_EDIT } from '@/routerNames';
import ArticleEditor from '@/components/article-editor/ArticleEditor/ArticleEditor.vue';
import { commitSetArticleEditorArticle } from '@/store/commits/articleEditorCommits';

@Component({
  components: {
    ArticleEditor,
  },
})
export default class ArticleVersionEditPage extends VueApp {
  loader: any = null;
  article: Article = null;

  get workspaceView() {
    return this.$store.state.workspaceView;
  }

  async init() {
    const articleId = this.$route.params.id;
    const version = parseInt(this.$route.params.version);

    this.loader = this.$loading({
      lock: true,
      text: 'Loading Article Version',
    });

    try {
      const response = await this.$api.article.getVersion(this.workspaceView.workspace.id, articleId, version);
      const article = Article.fromJson(response.data.article);
      article.id = response.data.article.articleId;

      commitSetArticleEditorArticle(article);
      this.article = article;
    } catch (error) {
      this.$api.handleResponseError(error);
      this.$router.push({ name: ARTICLE_EDIT, params: { id: articleId } });
    } finally {
      if (this.loader) {
        this.loader.close();
        this.loader = null;
      }
    }
  }

  created() {
    this.init();
  }

  beforeDestroy() {
    if (this.loader) {
      this.loader.close();
    }
  }
}
