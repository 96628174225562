
import { PERSONAL_WORKSPACE_TITLE } from '@/constants/SharedConsts';
import { Article } from '@/models/article/Article';
import { Company } from '@/models/Company';
import { Workspace } from '@/models/Workspace';
import store from '@/store';
import { dispatchTransferArticle } from '@/store/dispatchers/articleDispatchers';
import { Loading } from 'element-ui';
import { Component } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';

@Component({
  data() {
    return {
      availableWorkspaces: [] as Pick<Workspace, 'id' | 'title'>[],
      selectedWorkspace: null as Pick<Workspace, 'id' | 'title'> | null,
      availableCompanies: [] as Company[],
      selectedCompany: null as Company | null,
    };
  },
})
export default class ArticleTransferDialog extends VueApp {
  visible = false;
  article: Article;
  resolve: any = null;
  reject: any = null;

  workspaceTitle = (title: string) => (title?.indexOf('PW_') == 0 ? PERSONAL_WORKSPACE_TITLE : title);

  async transfer() {
    const article = store.state.articleEditor.article;
    const companyId = this.$data.selectedCompany;
    const workspaceId = this.$data.selectedWorkspace;
    await dispatchTransferArticle(article, companyId, workspaceId);

    this.visible = false;
    this.resolve();
  }

  async loadAvailableWorkspaces(companyId: string): Promise<void> {
    const loader = Loading.service({ lock: true });

    try {
      const res = await this.$api.company.getWorkspaces(companyId);
      const workspaces = res.data.workspaces;

      this.$data.availableWorkspaces = workspaces;
      this.$data.selectedWorkspace = workspaces[0]?.id;
    } catch (error) {
      this.$api.handleResponseError(error);
    } finally {
      loader.close();
    }
  }

  async show(article: Article, companies: Company[]): Promise<Article> {
    const loader = Loading.service({ lock: true });

    try {
      this.article = article;
      const user = store.state.user;

      this.$data.availableCompanies = companies.filter((company: Company) => {
        const companyId = typeof user.company === 'object' ? user.company.id : user.company;
        return company.id !== companyId;
      });

      this.$data.selectedCompany = this.$data.availableCompanies[0].id;

      await this.loadAvailableWorkspaces(this.$data.selectedCompany);

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    } finally {
      loader.close();
    }
  }

  close() {
    this.resolve();
    this.visible = false;
  }
}
