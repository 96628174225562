
import VueApp from '@/@types/app/VueApp';
import { Component, Prop } from 'vue-property-decorator';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';
import CommentTipTap from '@/components/tiptap/CommentTipTap.vue';
import { dispatchCreateNewComment } from '@/store/dispatchers/articleCommentsDispatchers';
import { ArticleComment } from '@/models/article/ArticleComment';
import { RecordingReaction } from '@/@types/RecordingReaction';
import { ArticleCommentVideoReaction } from '@/@types/ArticleCommentVideoReaction';
import { User } from '@/models/User';
import { dispatchCreateArticleReaction } from '@/store/dispatchers/articleReactionsDispatchers';

@Component({
  components: {
    CommentTipTap,
    UserProfilePhoto,
  },
})
export default class CommentsInput extends VueApp {
  @Prop({ type: String, default: null }) parentId: string;
  @Prop({ type: String, default: null }) profileImage: string;
  @Prop({ type: Boolean, default: false, required: false }) isReply: boolean;
  @Prop({ type: Object, default: null, required: false }) replyUser: User;
  @Prop({ type: Boolean, default: false, required: false }) isShowFocused: boolean;
  commentContent = '';

  get quote() {
    return this.$store.state.commentsSidebar.quote;
  }

  get quoteText() {
    return this.quote?.text;
  }

  get videoReaction(): ArticleCommentVideoReaction {
    return this.$store.state.commentsSidebar.videoReaction;
  }

  get isTimeTagActive(): boolean {
    return this.$store.state.commentsSidebar.isActiveTimeTag;
  }

  async createComment(content: string): Promise<void> {
    const comment = new ArticleComment();
    comment.parentId = this.parentId;
    comment.content = content;

    if (this.quoteText) {
      comment.quote = { ...this.quote, icon: 'comment' };
    }

    if (this.videoReaction && this.isTimeTagActive) {
      comment.videoReaction = this.videoReaction;
      comment.timestamp = this.videoReaction.currentVideoTime;
    } else if (!this.isTimeTagActive) {
      comment.timestamp = null;
    }

    const newComment = await dispatchCreateNewComment(comment);

    if (newComment && this.videoReaction && this.isTimeTagActive) {
      const recordingReaction: RecordingReaction = {
        articleId: this.videoReaction.articleId,
        paragraphId: this.videoReaction.paragraphId,
        commentId: newComment?.id,
        icon: 'comment',
        time: this.videoReaction?.currentParagraphTime,
      };
      await dispatchCreateArticleReaction(recordingReaction);
    }

    this.isEditorFocused(false);
  }

  isEditorFocused(isEditMode: boolean): void {
    this.$emit('is-editor-focused', isEditMode);
  }

  mounted() {
    setTimeout(() => {
      this.isEditorFocused(true);
    }, 500);
  }
}
