import slugify from 'slugify';
import helper from './../../helper';
import modelService from '@/services/ModelService';
import { Paragraph } from './Paragraph';
import { Range } from './Range';
import { ParagraphSearchResult } from './ParagraphSearchResult';
import { Video } from '../Video';
import { Model } from '@/models/Model';
import { User } from '@/models/User';
import { Workspace } from '@/models/Workspace';
import { SharedArticle } from '@/models/SharedArticle';
import { UserInfo } from '@/models/UserInfo';
import { TextTab } from '@/models/article/TextTab';

export class Article implements Model {
  id: string = null;
  title = '';
  shortId = '';
  workspace: string | Workspace = null;
  parentArticle: string | null;
  duration = 0;
  paragraphs: Paragraph[] = [];
  tags: string[] = [];
  videos: Video[] = [];
  draft = false; // this field is used only for draft article
  textTabs: TextTab[];
  text = '';
  original: string | null = null; // original id of draft version
  createdAt: number;
  updatedAt: number;
  user: UserInfo;
  userRef: UserInfo;
  updatedByUser: User;
  author: string;
  isArchived: boolean;
  isHasText: boolean; // this field is readonly and updated on backend
  pinnedNote?: string | null;
  shared?: SharedArticle;
  sharedUserGroup?: string | null;
  acceptSessionId?: string | null = null;
  isNotUploadedVideo?: boolean = false;

  static fromJson(jsonData: unknown) {
    return modelService.create(Article, jsonData);
  }

  get slug() {
    return slugify(this.title);
  }

  afterLoad(data: any): void {
    this.paragraphs = [];
    this.videos = [];

    if (data && data.paragraphs && data.paragraphs.length) {
      for (const key in data.paragraphs) {
        this.paragraphs.push(Paragraph.parse(data.paragraphs[key]));
      }
    }

    this.draft = data && !!data.draft;
    this.textTabs = data.textTabs;
    this.original = data && data.original ? data.original : null;
    this.createdAt = data ? data.createdAt : 0;
    this.updatedAt = data ? data.updatedAt : 0;
    this.user = data.user;
    this.userRef = data.userRef;
    this.updatedByUser = data.updatedByUser;
    this.shared = data.shared;
    this.author = data.author;
    this.isArchived = data.isArchived;
    this.isHasText = !!data.isHasText;
    this.acceptSessionId = data && data.acceptSessionId ? data.acceptSessionId : null;
    this.sharedUserGroup = data && data.sharedUserGroup ? data.sharedUserGroup : null;
  }

  removeParagraph(item: Paragraph, keepChild = false): boolean {
    const result = this.findParagraph(item);
    const { paragraph, key, parent } = result;
    let { list } = result;

    if (result.hasResult() && list && list.length) {
      const listKey = helper.getItemsKey(parent);
      const index = parseInt(key);

      if (keepChild && item.items.length) {
        // move child paragraphs up and remove needed paragraph
        const left = index > 0 ? list.splice(0, index) : [];
        list.splice(0, 1);
        list = [...left, ...(paragraph ? paragraph.items : []), ...list] as Paragraph[];
        parent[listKey] = list;
      } else {
        if (index >= 0) {
          list.splice(index, 1);
          this.duration = this.calculateDuration();
          return true;
        }
      }
    }
    return false;
  }

  eachParagraph(cb: any) {
    helper.deepEach(this, (paragraph: Paragraph | Article) => {
      if (paragraph === this) {
        return;
      }
      cb(paragraph);
    });
  }

  findParagraphByMs(ms: number) {
    let target: Paragraph | Article = null;
    let offset = 0;
    helper.deepEach(this, (paragraph: Paragraph | Article) => {
      if (target || paragraph === this) {
        return;
      }
      const range = new Range(offset, offset + paragraph.duration);

      if (range.contain(ms)) {
        target = paragraph;
      }
      offset += paragraph.duration;
    });

    return target as Paragraph;
  }

  findParagraph(p: Paragraph): ParagraphSearchResult {
    let item = ParagraphSearchResult.notFound();
    if (p && this.paragraphs.length) {
      item = this.findParagraphIn(p, this);
    }

    return item;
  }

  findParagraphIn(p: any, parent: any): ParagraphSearchResult {
    const itemsKey = helper.getItemsKey(parent);
    const arr = parent[itemsKey || 0];
    if (itemsKey && arr && arr.length) {
      for (const key in arr) {
        // eslint-disable-next-line no-prototype-builtins
        if (arr.hasOwnProperty(key)) {
          if (arr[key] === p || arr[key].key === p.key) {
            return new ParagraphSearchResult(arr[key], parent, arr, key);
          } else {
            const rec = this.findParagraphIn(p, arr[key]);

            if (rec.hasResult()) {
              return rec;
            }
          }
        }
      }
    }
    return ParagraphSearchResult.notFound();
  }

  findVideo(id: string) {
    let video = null;

    if (this.videos.length) {
      for (const key in this.videos) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.videos.hasOwnProperty(key) && this.videos[key].id === id) {
          video = this.videos[key];
          break;
        }
      }
    }

    return video;
  }

  getParagraphsList() {
    const list: Paragraph[] = [];
    helper.deepEach(this, (paragraph: Paragraph | Article) => {
      if (paragraph === this) {
        return;
      }
      list.push(paragraph as Paragraph);
    });

    return list;
  }

  getParagraphsOffsetMap() {
    const paragraphs = this.getParagraphsList();
    const map = new Map<string, number>();
    let totalOffset = 0;
    for (const paragraph of paragraphs) {
      map.set(paragraph.id || paragraph.key, totalOffset);
      totalOffset += paragraph.duration;
    }

    return map;
  }

  getParagraphPosition(item: Paragraph) {
    const paragraphs = this.getParagraphsList();
    let pos = 0;
    for (const p of paragraphs) {
      const paragraphIdMatch = p.id && item && item.id && p.id === item.id;
      const paragraphKeyMatch = p.key && item && item.key && p.key === item.key;

      if (paragraphIdMatch || paragraphKeyMatch) {
        return pos;
      }

      pos += p.duration;
    }

    return -1;
  }

  getRanges() {
    const ranges: Range[] = [];

    helper.deepEach(this, (paragraph: any) => {
      if (paragraph === this) {
        return;
      }
      ranges.push(paragraph.getRange());
    });

    return ranges;
  }

  // calculateVideoDuration() {
  //   let duration = 0;
  //
  //   if (this.playMap && this.playMap.length) {
  //     const item = this.playMap[this.playMap.length - 1];
  //     const video = this.findVideo(item.videoId);
  //     duration = item.totalTime + (video ? video.duration : 0) - item.videoTime;
  //   }
  //
  //   return duration;
  // }

  calculateDuration() {
    let duration = 0;

    helper.deepEach(this, (paragraph: Paragraph | Article) => {
      if (paragraph === this || paragraph.duration < 0) {
        return;
      }

      duration += paragraph.duration;
    });

    return duration;
  }

  updateVideoDuration(videoUrl: string, duration: number) {
    this.eachParagraph((item: Paragraph) => {
      if (item.videos.length) {
        item.videos.forEach((video: Video) => {
          if (video.url === videoUrl && video.duration !== duration) {
            video.duration = duration;
          }
        });
      }
    });
  }

  /**
   * Remove zero-duration paragraphs
   */
  clearfixParagraphs() {
    const zeroParagraphs: Paragraph[] = [];
    this.eachParagraph((item: Paragraph) => {
      if (item.duration <= 0) {
        zeroParagraphs.push(item);
      }
    });

    zeroParagraphs.forEach((item: Paragraph) => {
      this.removeParagraph(item, false);
    });
  }

  isTextArticle() {
    return !(this.duration && this.paragraphs);
  }

  isVideoArticle() {
    return !!(this?.duration && this?.paragraphs);
  }

  isSharedArticle() {
    return !!this.shared || !!this.sharedUserGroup;
  }

  hasText() {
    return !!this?.textTabs?.length;
  }

  hasParagraphs() {
    return !!this?.paragraphs.length;
  }
  // debug methods
  // debugParagraphs() {
  //   const data: any[] = [];
  //   this.getParagraphsList().forEach((item) => {
  //     data.push({
  //       from: item.position,
  //       to: item.position + item.duration,
  //       duration: item.duration,
  //       title: item.title,
  //     });
  //   });
  //
  //   // tslint:disable-next-line
  //   console.table(data);
  // }
  //
  // debugPlayMap() {
  //   const data: any[] = [];
  //   for (const key in this.playMap) {
  //     // eslint-disable-next-line no-prototype-builtins
  //     if (this.playMap.hasOwnProperty(key)) {
  //       const item = this.playMap[key];
  //       data[parseInt(key)] = JSON.parse(JSON.stringify(item));
  //     }
  //   }
  //
  //   // tslint:disable-next-line
  //   console.table(data);
  // }
}
