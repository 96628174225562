
import VueApp from '@/@types/app/VueApp';
import EventBus from '@/EventBus';
import { ARTICLE_CREATE_UPDATE_ARTICLE } from '@/events';
import * as routerNames from '@/routerNames';
import notificationService from '@/services/NotificationService';
import { Prop, Component } from 'vue-property-decorator';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { Logger } from '@/other/Logger';
import articleEditService from '@/services/article/ArticleEditService';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { ScreenRecorderSaveT } from '@/components/screen-recorder/types';
import { ArticleVideoEditorState } from '@/components/article-editor/types/ArticleVideoEditorState';
import { VideoUploadService } from '@/services/VideoUploadService';
import { commitSetArticleEditorArticle } from '@/store/commits/articleEditorCommits';
import { Article } from '@/models/article/Article';

const log = new Logger('UploadButton');

@Component
export default class UploadButton extends VueApp {
  @Prop({ type: Boolean, default: false }) isArticleCreate: boolean;
  @Prop({ type: Function, default: () => true }) validate: () => boolean;
  @Prop({ required: false }) article: Article;

  $refs: {
    uploadButton: HTMLElement;
    videoUpload: HTMLElement;
  };
  isResetFileChoose = false;

  get articleEditorState() {
    return this.$store.state.articleEditor.state;
  }

  chooseFile() {
    if (this.isArticleCreate && !this.validate()) return;

    const $el = this.$refs.videoUpload;

    $el.addEventListener('change', this.onFileChange);
    $el.click();
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (!files.length) return;
    this.isArticleCreate && this.articleCreateDialogUpdateArticle();

    this.uploadVideoFile(files[0]);
  }

  async uploadVideoFile(file: File) {
    const startTime = Date.now();
    const loadingText = 'Uploading video...';
    const loading = this.$loading({ lock: true, text: loadingText }) as ElLoadingComponent & { text: string };
    const chunkSize = 50e6; // 50 mb
    const parts = VideoUploadService.splitVideoToChunks(file, chunkSize);

    const videoUploadService = new VideoUploadService(parts, { useFakeProgress: true });
    videoUploadService.onError(() => {
      this.isResetFileChoose = true;
      setTimeout(() => {
        this.isResetFileChoose = false;
      }, 10);
      loading.close();
      videoUploadService.destroy();

      notificationService.error(
        'Failed to upload video. Please contact the support team if such an issue occurs again.'
      );
    });
    videoUploadService.onProgress((progress) => {
      loading.text = progress === 100 ? 'Processing video...' : `${loadingText} ${Math.floor(progress)}%`;
    });
    videoUploadService.onFinish((video) => {
      loading.close();
      videoUploadService.destroy();

      log.info(`Uploading finished in ${(Date.now() - startTime) / 1000}s`);
      this.saveRecordedVideo({ video, keyframes: [] });
      this.$emit('saved');
    });

    videoUploadService.start();
  }

  async saveRecordedVideo({ video, keyframes }: ScreenRecorderSaveT) {
    const currentState = articleEditService.articleEditor.state;

    this.$store.commit('setArticleEditorState', ArticleVideoEditorState.recorded);

    if (this.isArticleCreate && currentState !== ArticleVideoEditorState.inserting && this.article) {
      commitSetArticleEditorArticle(this.article);
    }

    await articleEditService.handleRecordedVideo({ video, keyframes });

    if (currentState !== ArticleVideoEditorState.inserting) {
      await articleEditService.saveAsDraft();

      if (this.isArticleCreate) {
        commitSetArticleMode(ArticleEditMode.VIDEO);
        const routeParams = this.article.id ? { id: this.article.id } : {};
        await this.$router.push({ name: routerNames.ARTICLE_CREATE, params: routeParams });
      }
    }
  }

  articleCreateDialogUpdateArticle() {
    if (![ArticleVideoEditorState.recording, ArticleVideoEditorState.inserting].includes(this.articleEditorState)) {
      EventBus.$emit(ARTICLE_CREATE_UPDATE_ARTICLE);
    }
  }
}
