
import VueApp from '@/@types/app/VueApp';

import EventBus from '@/EventBus';
import { ARTICLE_CREATE_HIDE, ARTICLE_CREATE_UPDATE_ARTICLE } from '@/events';
import { Article } from '@/models/article/Article';
import { TextTab } from '@/models/article/TextTab';
import { Workspace } from '@/models/Workspace';
import articleEditService from '@/services/article/ArticleEditService';
import authService from '@/services/AuthService';
import notificationService from '@/services/NotificationService';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import { commitSetArticleEditorArticle } from '@/store/commits/articleEditorCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { dispatchGetDraftsCount } from '@/store/dispatchers/uiDispatchers';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  name: 'SubArticleCreateDialog.vue',
})
export default class SubArticleCreate extends VueApp {
  parentArticle: Article = null;
  parentWorkspace: Workspace = null;
  subArticle: Article = null;
  showDialog = false;
  resolve: ((value?: Article | PromiseLike<Article>) => void) | null = null;
  reject: ((reason?: any) => void) | null = null;
  initValidate = false;
  searchQuery = '';
  searchedWorkspaces: Workspace[] = [];

  $refs: {
    tagInput: any;
  };

  get workspaces(): Workspace[] {
    return this.$store.state.workspaces;
  }

  handleParentWorkspaceTitle(workspace: Workspace): string {
    return workspaceService.getWorkspaceTitle(workspace);
  }

  checkChips() {
    const inputValue = this.$refs.tagInput.inputValue.trim();
    if (inputValue.length > 0) {
      this.subArticle.tags.push(inputValue);
      this.$refs.tagInput.inputValue = '';
    }
  }

  initSubArticle(parentArticle: Article) {
    this.subArticle.workspace = parentArticle.workspace;
    this.subArticle.parentArticle = parentArticle.id;
  }

  show(article: Article, workspace: Workspace) {
    this.parentArticle = Article.fromJson(article);
    this.subArticle = new Article();
    this.parentWorkspace = workspace;
    this.searchQuery = this.handleParentWorkspaceTitle(workspace);

    this.initSubArticle(this.parentArticle);
    return new Promise<Article>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
      this.showDialog = true;
    });
  }

  isValid(key: 'workspace' | 'title') {
    return !this.initValidate || this.parentArticle[key];
  }

  validate() {
    this.initValidate = true;
    return this.isValid('title') && this.isValid('workspace');
  }

  async saveTextArticle() {
    this.initValidate = true;
    if (this.isValid('title') && this.isValid('workspace')) {
      if (!this.subArticle.title.trim().length) {
        notificationService.warning('Title cannot be empty!');
        return;
      }
      this.checkChips();

      this.subArticle.textTabs = [new TextTab()];

      commitSetArticleEditorArticle(this.subArticle);

      const userId = authService.user.id;
      const res = await articleEditService.save({ updatedByUser: userId, isPublish: false, skipEditor: true });
      this.subArticle.id = res.data.article.id;

      this.resolve(this.subArticle);
      this.showDialog = false;

      await dispatchGetDraftsCount();

      dispatchUpdateSelectedTextTab(this.subArticle.textTabs[0]);
    }
  }

  hideCreateArticle() {
    this.showDialog = false;
    this.subArticle = null;
    this.parentArticle = null;
    this.parentWorkspace = null;
  }

  mounted() {
    EventBus.$on(ARTICLE_CREATE_UPDATE_ARTICLE, () => {
      commitSetArticleEditorArticle(this.subArticle);
    });

    EventBus.$on(ARTICLE_CREATE_HIDE, this.hideCreateArticle);
  }

  beforeDestroy() {
    EventBus.$off(ARTICLE_CREATE_UPDATE_ARTICLE);
    EventBus.$off(ARTICLE_CREATE_HIDE);
  }

  @Watch('showDialog')
  onDialogClose(value: boolean) {
    if (!value) {
      this.initValidate = false;
    }
  }
}
