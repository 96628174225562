import { CompanyState } from '@/store/state/CompanyState';
import { Company } from '@/models/Company';
import { Mutations } from '@/store/mutations';
import { User } from '@/models/User';

export const companyMutations = {
  [Mutations.SET_COMPANY](state: CompanyState, company: Company) {
    state.company = company;
  },
  [Mutations.SET_PAGE_COMPANY](state: CompanyState, company: Company) {
    state.pageCompany = company;
  },
  [Mutations.SET_COMPANY_STATUS](state: CompanyState, isActivated: boolean) {
    state.company.isActivated = isActivated;
  },
  [Mutations.SET_COMPANY_LOGO](state: CompanyState, logo: string | null) {
    state.company.logo = logo;
  },
  setCompanyPaddle(state: CompanyState, paddle: Company['paddle']) {
    state.company.paddle = paddle;
  },
  setCompanyUsers(state: CompanyState, users: User[]) {
    state.companyUsers = users;
  },
  setCompanyLoading(state: CompanyState, value: boolean) {
    state.isCompanyLoading = value;
  },
};
