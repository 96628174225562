import {
  LOGIN,
  LOGIN_SOCIAL_CALLBACK,
  LOGIN_SOCIAL_FAIL,
  PASSWORD_RESET,
  PASSWORD_RESET_TOKEN,
  SIGNUP,
  SIGNUP_INVITED,
} from '@/routerNames';

export const authRoutes = [
  SIGNUP,
  LOGIN,
  LOGIN_SOCIAL_CALLBACK,
  LOGIN_SOCIAL_FAIL,
  PASSWORD_RESET,
  PASSWORD_RESET_TOKEN,
  SIGNUP_INVITED,
];
