import apiService from '../ApiService';
import navigationService from '@/services/NavigationService';
import notificationService from '@/services/NotificationService';
import { Company } from '@/models/Company';
import { LOGIN } from '@/routerNames';
import { Loading } from 'element-ui';
import localStorageService from '@/services/LocalStorageService';

export class AdminCompanyService {
  deleteCompany(company: Company, callback: () => void) {
    const loader = Loading.service({
      lock: true,
      text: 'Deleting your company',
    });
    const req = apiService.company.delete(company.id);
    req
      .then(() => {
        notificationService.success('Company was successfully deleted');
        callback();
      })
      .catch((err: any) => {
        apiService.handleResponseError(err);
      })
      .finally(() => {
        loader.close();
      });
  }

  changeCompanyStatus(company: Company, callback: () => void) {
    const loader = Loading.service({
      lock: true,
      text: 'Changing status of current company',
    });
    const changedStatus = { status: !company.isActivated };
    const req = apiService.company.changeStatus(company.id, changedStatus);
    req
      .then(() => {
        notificationService.success(`Company status was successfully changed`);
        callback();
      })
      .catch((err: any) => {
        apiService.handleResponseError(err);
      })
      .finally(() => {
        loader.close();
      });
  }

  changeCompanyBilling(company: Company, callback: (status: boolean) => void) {
    const loader = Loading.service({
      lock: true,
      text: 'Changing billing status',
    });
    const newBillingStatus = { status: !company.isBilling };
    const req = apiService.company.changeBillingStatus(company.id, newBillingStatus);
    req
      .then((res) => {
        notificationService.success(`Company billing status was successfully changed`);
        callback(res.data.status);
      })
      .catch((err: any) => {
        apiService.handleResponseError(err);
      })
      .finally(() => {
        loader.close();
      });
  }

  changeCompanyAiStatus(company: Company, isAiEnabled: boolean) {
    const changedStatus = { status: isAiEnabled };
    const req = apiService.company.changeAiStatus(company.id, changedStatus);
    req
      .then(() => {
        notificationService.success(`Company AI status was successfully changed`);
      })
      .catch((err: any) => {
        apiService.handleResponseError(err);
      });
  }

  async switchCompany(company: Company, newTab?: boolean) {
    try {
      const res = await apiService.company.switch(company.id);

      const currentDomain = document.location.origin;
      const companySwitch = Company.parse(res.data.company);
      const targetDomain = companySwitch.domain ? 'https://' + companySwitch.domain : process.env.VUE_APP_URL;
      const targetCompanyUrl = targetDomain + '/#/login/' + res.data.token;

      if (newTab) return window.open(targetCompanyUrl, '_blank');

      if (currentDomain !== targetDomain) {
        localStorageService.clear();
        document.location.href = targetCompanyUrl;
      } else {
        navigationService.navigate({
          name: LOGIN,
          params: { token: res.data.token },
        });
      }
    } catch (res) {
      apiService.handleResponseError(res);
    }
  }
}

export const adminCompanyService = new AdminCompanyService();
export default adminCompanyService;
