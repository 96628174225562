
import VueApp from '@/@types/app/VueApp';
import { SocketMessageType } from '@/@types/enums/SocketMessageType';
import SmartContentLoader from '@/components/article-editor/EditorNavbar/SmartContentLoader/SmartContentLoader.vue';
import TranscriptionSubtitle from '@/components/layout/sidebars/TranscriptionSidebar/TranscriptionSubtitle/TranscriptionSubtitle.vue';
import handleOutsideClickDirective from '@/directives/handleOutsideClickDirective';
import logger from '@/other/Logger';
import * as routerNames from '@/routerNames';
import apiService from '@/services/ApiService';
import articleEditService from '@/services/article/ArticleEditService';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import notificationService from '@/services/NotificationService';
import socketService from '@/services/socket/SocketService';
import store from '@/store';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  components: { SmartContentLoader, TranscriptionSubtitle },
  directives: {
    'click-outside': handleOutsideClickDirective(
      () => {
        if (store.state.transcriptionSidebar.isVisible) {
          store.commit('transcriptionSidebarSetIsVisible', false);
        }
      },
      ['transcription-view-button'],
      ['transcription-view-button', 'transcription-generate-button', 'transcription-generation-loader'],
      'transcription-sidebar'
    ),
  },
})
export default class TranscriptionSidebar extends VueApp {
  loading = false;

  isTranscriptionGenerating = false;
  transcriptionGenerationProgress = 0;

  get isExtensionBannerVisible() {
    return this.$store.state.ui.isExtensionBannerVisible;
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get isArticleEditPage() {
    return [
      routerNames.ARTICLE_CREATE,
      routerNames.ARTICLE_EDIT,
      routerNames.ARTICLE_VERSION_EDIT,
      routerNames.DRAFT_EDIT,
    ].includes(this.$route.name);
  }

  get transcription() {
    return this.$store.state.transcriptionSidebar.transcription;
  }

  get styles() {
    const diff = this.isExtensionBannerVisible && !this.isMobile ? '100' : '60';
    return {
      top: diff + 'px',
      height: `calc(100vh - ${diff}px)`,
    };
  }

  onClickClose() {
    this.$store.commit('transcriptionSidebarSetIsVisible', false);
  }

  async onStartGeneration() {
    this.isTranscriptionGenerating = true;
    this.transcriptionGenerationProgress = 0;

    const article = articleEditService.articleEditor.article;

    try {
      const transcription = await socketService.article.ai.generateAiTranscription(
        article.id,
        SocketMessageType.START,
        (statusData) => {
          this.transcriptionGenerationProgress = statusData.status.progress;
        }
      );

      if (article.id === articleEditService.articleEditor.article.id && this.isArticleEditPage) {
        this.$store.commit('transcriptionSidebarSetTranscription', JSON.parse(transcription));
      }
    } catch (err: any) {
      switch (err?.type) {
        case 'success':
          return;
        case 'warning':
          return notificationService.warning(err.message);
        case 'error': {
          logger.error(err);
          return notificationService.error(err.message);
        }
      }
    } finally {
      this.isTranscriptionGenerating = false;
      this.transcriptionGenerationProgress = 0;
    }
  }

  onCancelGenerating() {
    this.isTranscriptionGenerating = false;
    this.transcriptionGenerationProgress = 0;

    socketService.article.ai.stopAiTranscriptionGeneration();
  }

  async loadTranscription() {
    if (this.isTranscriptionGenerating) return;
    this.loading = true;
    const article = articleEditService.articleEditor.article;

    if (!this.isArticleEditPage || !article) return;

    this.$store.commit('transcriptionSidebarSetTranscription', []);

    try {
      const resSubtitles = await apiService.article.getTranscription(article.id);
      const subtitles = resSubtitles.data.transcription;

      if (subtitles.length) {
        const parsedSubtitles = JSON.parse(subtitles);
        this.$store.commit('transcriptionSidebarSetTranscription', parsedSubtitles);
      } else {
        this.$store.commit('transcriptionSidebarSetTranscription', []);
      }
    } catch (err) {
      logger.error('Failed to load transcription for the article');
      this.$store.commit('transcriptionSidebarSetTranscription', []);
    } finally {
      this.loading = false;
    }
  }

  @Watch('$route.path', { immediate: true })
  closeSidebarOnRouteChange() {
    this.$store.commit('transcriptionSidebarSetTranscription', []);
    this.$store.commit('transcriptionSidebarSetIsVisible', false);
    this.onCancelGenerating();
  }

  @Watch('$store.state.transcriptionSidebar.isVisible')
  onVisibleChange(isVisible: boolean) {
    if (isVisible) {
      this.loadTranscription();
    }
  }
}
