import appStore from '@/store';

export const dispatchNotificationsSidebarUpdate = () => {
  return appStore.dispatch('notificationsSidebarUpdate');
};
export const dispatchNotificationsSidebarMarkRead = (id: string) => {
  return appStore.dispatch('notificationsSidebarMarkRead', id);
};
export const dispatchNotificationsSidebarReset = () => {
  return appStore.dispatch('notificationsSidebarReset');
};
