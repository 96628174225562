
import GroupProfilePhoto from '@/components/layout/shared/ProfilePhoto/GroupProfilePhoto.vue';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { User } from '@/models/User';
import { Group } from '@/models/Group';
import VueApp from '@/@types/app/VueApp';

@Component({
  components: { GroupProfilePhoto, UserProfilePhoto },
})
export default class FindUserGroupDialog extends VueApp {
  @Prop() visible: boolean;
  @Prop({ type: Boolean, default: false }) invited: boolean;
  @Prop({ type: Object, default: null }) group: Group;
  @Prop({ type: Array, default: null }) workspaceUsers: User[];
  @Prop({ type: Array, default: null }) workspaceGroups: Group[];

  loading = false;
  suggestList: any[] = [];
  foundData: string[] = [];

  $refs: {
    searchQuery: any;
  };

  clearText(text: string): string {
    return text.trim().toLowerCase();
  }

  async remoteMethod(query: string) {
    this.loading = true;
    this.suggestList = [];
    if (query) {
      try {
        // Search users
        const searchUsers = await this.$api.searchUsers(query, this.invited);
        if (searchUsers.data.items.length) {
          this.suggestList = searchUsers.data.items.map((item: any) => {
            const user = User.parse(item);
            const userName = user.getName();
            let userInfo = this.setSmallerLabel(userName, 35);
            if (!this.group && !this.workspaceUsers) {
              if (!this.checkSavedUsers(user.id)) {
                return {
                  value: JSON.stringify({ type: 'user', id: user.id }),
                  label: userInfo,
                  type: 'user',
                  email: user.email,
                  profileImage: user.profileImage,
                };
              }
              return;
            }
            const foundUserInSet = this.group ? !this.hasGroupUser(item.groupIds) : !this.hasWorkspaceUser(user.id);
            if (userInfo.toLowerCase().indexOf(query.toLowerCase()) > -1 && foundUserInSet) {
              return {
                value: JSON.stringify(user),
                label: userInfo,
              };
            }
          });
        }
        // Search groups
        const clearQuery = this.clearText(query);
        if (clearQuery) {
          const groupRes = await this.$api.groups.find(clearQuery);
          this.suggestList = this.suggestList.concat(
            groupRes.data.items.map((item: any) => {
              const group = Group.parse(item);
              const groupTitle = group.title;
              const isGroupInSet = this.clearText(groupTitle).includes(clearQuery) && !this.hasWorkspaceGroup(group.id);
              if (isGroupInSet) {
                return {
                  value: JSON.stringify({ type: 'group', id: group.id }),
                  members: group.userIds.length,
                  type: 'group',
                  label: groupTitle,
                };
              }
            })
          );
        }
        this.removeSuggested();
      } catch (err) {
        this.$api.handleResponseError(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  setSmallerLabel(label: string, symbolCount = 23): string {
    return label.length > symbolCount ? label.slice(0, symbolCount) + '...' : label;
  }

  hasGroupUser(groupIds: string[]): boolean {
    if (typeof groupIds === 'undefined') {
      return false;
    }
    return groupIds.indexOf(this.group.id) > -1;
  }

  hasWorkspaceUser(userId: string): boolean {
    return this.workspaceUsers && this.workspaceUsers.findIndex((user: User) => user.id === userId) !== -1;
  }

  hasWorkspaceGroup(groupId: string): boolean {
    return this.workspaceGroups && this.workspaceGroups.findIndex((group: Group) => group.id === groupId) !== -1;
  }

  updateSelect() {
    this.$nextTick(() => {
      this.removeSuggested();
      this.$refs.searchQuery.query = '';
      this.remoteMethod(' ');
    });
  }

  checkSavedUsers(userId: string) {
    const existedUser = this.$store.state.addedUser.filter((user) => user.id === userId);
    return !!existedUser.length;
  }

  handleAdd() {
    this.$emit('select', this.foundData);
    this.foundData = [];
    this.removeUndefinedSuggested();
  }

  handleClose() {
    this.$nextTick(() => {
      this.foundData = [];
      this.$emit('update:visible', false);
    });
  }

  removeSuggested() {
    this.removeUndefinedSuggested();
    if (this.foundData.length > 0) {
      this.foundData.forEach((addedItem) => {
        const foundIndex = this.suggestList.findIndex((suggestItem) => suggestItem.value === addedItem);
        if (foundIndex >= 0) {
          this.suggestList.splice(foundIndex, 1);
        }
      });
    }
  }

  removeUndefinedSuggested() {
    this.suggestList = this.suggestList.filter((item: any) => typeof item !== 'undefined');
  }

  mounted() {
    window.addEventListener('resize', this.handleResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    // Fix issue when suggestion list stuck on some position when user resized window
    const searchQueryRef = this.$refs.searchQuery;
    setTimeout(() => {
      if (searchQueryRef) searchQueryRef.handleResize();
    }, 100);
  }

  @Watch('suggestList', { immediate: true, deep: true })
  onSuggestListChange(value: any[]) {
    if (value.length) {
      const searchQueryRef = this.$refs.searchQuery;
      setTimeout(() => {
        if (searchQueryRef) searchQueryRef.handleResize();
      }, 100);
    }
  }
}
