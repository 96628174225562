
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models/User';

@Component({})
export default class MentionListItem extends Vue {
  @Prop({ type: User, required: true }) item!: User;
  @Prop({ type: Boolean, default: false }) isSelected: boolean;

  onClick() {
    this.$emit('click', this.item);
  }
}
