import { SocketMessageType } from '@/@types/enums/SocketMessageType';
import logger from '@/other/Logger';
import { SocketBaseServiceProvider } from '@/services/socket/SocketBaseServiceProvider';

export type IArticleAiTextSocketArgs = {
  articleId: string;
  prompt?: string;
  option?: string;
  writerType?: string;
  selectedText?: string;
  predefinedPrompt?: string;
  saveArticle?: boolean;
  isConsiderArticle?: boolean;
};

export class ArticleAiTextSocketAction extends SocketBaseServiceProvider {
  private aiTextGenerationSocket: WebSocket | null = null;
  generateAiText = async ({
    articleId,
    prompt,
    option,
    writerType,
    selectedText,
    predefinedPrompt,
    saveArticle = false,
    isConsiderArticle = false,
  }: IArticleAiTextSocketArgs): Promise<string> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const generateAiTextPromise = new Promise<string | void>((resolve, reject) => {
        this.aiTextGenerationSocket = new WebSocket(`${this.getSocketBaseUrl()}/ai-text?token=${this.token()}`);
        // WebSocket event listeners
        this.aiTextGenerationSocket.onopen = () => {
          if (this.aiTextGenerationSocket?.readyState === WebSocket.OPEN) {
            this.aiTextGenerationSocket?.send(
              JSON.stringify({
                type: SocketMessageType.START,
                articleId,
                prompt,
                option,
                predefinedPrompt,
                saveArticle,
                isConsiderArticle,
                writerType,
                selectedText,
              })
            );
          }
        };
        this.aiTextGenerationSocket.onmessage = (event) => {
          if (event.data === SocketMessageType.PING) {
            const sendPong = () => {
              if (this.aiTextGenerationSocket?.readyState === WebSocket.OPEN) {
                try {
                  this.aiTextGenerationSocket.send(SocketMessageType.PONG);
                } catch (error) {
                  logger.error(`Failed to send pong: ${error}`);
                  setTimeout(sendPong, 2000);
                }
              }
            };
            sendPong();
            return;
          }
          const message = JSON.parse(event.data);
          switch (message.type) {
            case SocketMessageType.RESULT:
              this.aiTextGenerationSocket.close(3001);
              resolve(message.text);
              break;
          }
        };
        this.aiTextGenerationSocket.onclose = (event) => {
          const { code } = event;
          const handler = this.messageTypeHandler[code];
          if (!handler) return;

          reject({ type: handler.type, message: event.reason });
        };
        this.aiTextGenerationSocket.onerror = (error) => {
          reject({ type: error.type, message: 'Failed to generate AI text' });
        };
      });
      return (await generateAiTextPromise) || '';
    } catch (err) {
      throw err;
    }
  };
}
