
import VueApp from '@/@types/app/VueApp';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AdminDashboardStatItem as AdminDashboardStatItemType } from '@/@types/AdminDashboardStatItem';

@Component
export default class AdminDashboardStatItem extends VueApp {
  @Prop({ type: Object, required: true }) item: AdminDashboardStatItemType;
}
