import { SocketMessageType } from '@/@types/enums/SocketMessageType';
import logger from '@/other/Logger';
import { SocketBaseServiceProvider } from '@/services/socket/SocketBaseServiceProvider';

export class ArticleAiTranscriptionSocketAction extends SocketBaseServiceProvider {
  private aiTranscriptionGenerationSocket: WebSocket | null = null;

  generateAiTranscription = async (
    articleId: string,
    type = SocketMessageType.START,
    statusCallback: (status: any) => void
  ): Promise<string> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const generateAiTranscriptionPromise = new Promise<string | void>((resolve, reject) => {
        this.aiTranscriptionGenerationSocket = new WebSocket(
          `${this.getSocketBaseUrl()}/ai-transcription?token=${this.token()}`
        );
        // WebSocket event listeners
        this.aiTranscriptionGenerationSocket.onopen = () => {
          this.aiTranscriptionGenerationSocket?.send(JSON.stringify({ type, articleId }));
        };
        this.aiTranscriptionGenerationSocket.onmessage = (event) => {
          if (event.data === SocketMessageType.PING) {
            const sendPong = () => {
              if (this.aiTranscriptionGenerationSocket?.readyState === WebSocket.OPEN) {
                try {
                  this.aiTranscriptionGenerationSocket.send(SocketMessageType.PONG);
                } catch (error) {
                  logger.error(`Failed to send pong: ${error}`);
                  setTimeout(sendPong, 2000);
                }
              }
            };
            sendPong();
            return;
          }
          const message = JSON.parse(event.data);
          switch (message.type) {
            case SocketMessageType.RESULT:
              this.aiTranscriptionGenerationSocket.close(3001);
              resolve(message.transcription);
              break;
            case SocketMessageType.UPDATE:
              statusCallback(message);
              break;
          }
        };
        this.aiTranscriptionGenerationSocket.onclose = (event) => {
          const { code } = event;
          const handler = this.messageTypeHandler[code];
          if (!handler) return;

          reject({ type: handler.type, message: event.reason });
        };
        this.aiTranscriptionGenerationSocket.onerror = (error) => {
          reject({ type: error.type, message: 'Failed to generate AI transcription' });
        };
      });
      return (await generateAiTranscriptionPromise) || '';
    } catch (err) {
      throw err;
    }
  };

  stopAiTranscriptionGeneration() {
    this.aiTranscriptionGenerationSocket?.close(3001, 'Generation canceled');
    return;
  }
}
