import socketService, { SocketService } from '@/services/socket/SocketService';
import Vue from 'vue';
import { AppStore } from '@/store';
import apiService, { ApiService } from '@/services/ApiService';
import authService, { AuthService } from '@/services/AuthService';

export default abstract class VueApp extends Vue {
  public $store: AppStore;
  public $api: ApiService = apiService;
  public $socket: SocketService = socketService;
  public $auth: AuthService = authService;
}
