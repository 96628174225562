import axios from 'axios';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import { GetNotificationsResponse } from '@/@types/responses/UserNotificationResponses';

export class NotificationsServiceProvider extends ApiBaseServiceProvider {
  scope = 'notifications';

  get(time?: number) {
    return axios.get<GetNotificationsResponse>(
      this.getBaseUrl().slice(0, -1) + (time ? `?t=${Math.round(time / 1000)}` : ''),
      this.getRequestConfig()
    );
  }

  markReadById(notificationId: string) {
    return axios.post(`${this.getBaseUrl() + notificationId}/read`, {}, this.getRequestConfig());
  }

  markAllAsRead() {
    return axios.post(`${this.getBaseUrl()}read-all`, {}, this.getRequestConfig());
  }
}
