import { Notification } from 'element-ui';
import './../scss/notification.scss';

export class NotificationService {
  success(message: string) {
    Notification.success({
      title: '',
      message,
      customClass: 'success',
    });
  }

  warning(message: string, duration = 4500) {
    Notification.warning({
      title: '',
      message,
      customClass: 'warning',
      duration,
    });
  }

  error(message: string) {
    Notification.error({
      title: '',
      message,
      customClass: 'error',
    });
  }
}

const notificationService = new NotificationService();
export default notificationService;
