
import ComponentLoader from '@/components/layout/shared/loaders/ComponentLoader.vue';
import socketService from '@/services/socket/SocketService';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { NodeViewWrapper } from '@tiptap/vue-2';
import { Editor as CoreEditor } from '@tiptap/core';
import { AiImageStyleOption } from '@/components/tiptap/types/ai';
import notificationService from '@/services/NotificationService';
import apiService from '@/services/ApiService';
import { imageStyles } from '@/constants/SharedConsts';

@Component({
  methods: {
    imageStyles() {
      return imageStyles;
    },
  },
  components: { ComponentLoader, NodeViewWrapper },
})
export default class AiImageView extends Vue {
  @Prop({
    type: ProsemirrorNode,
    required: true,
  })
  readonly node!: ProsemirrorNode;

  @Prop({ type: CoreEditor, required: true })
  readonly editor!: CoreEditor;
  @Prop({ type: Function, required: true }) getPos: () => number;
  @Prop({ type: Function, required: true }) deleteNode: () => void;

  isLoading = false;
  loaderTitle = '';
  prompt = '';
  previewImage: string | null = null;
  style: AiImageStyleOption = null;

  selectImageStyle(style: AiImageStyleOption) {
    this.style = style;
  }

  handleDiscard() {
    this.deleteNode();
  }

  async handleInsert() {
    try {
      this.loaderTitle = 'Inserting image into editor';
      this.isLoading = true;

      const imageBase64 = this.previewImage.split(',')[1]; // Remove the prefix "data:image/png;base64,"
      const binaryData = atob(imageBase64); // Decode the base64 string to binary data
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: 'image/png' }); // Create a Blob object
      const file = new File([blob], 'ai_image.png', { type: 'image/png' });

      let src = '';

      try {
        const response = await apiService.image.upload(file);
        src = response.data.url;
      } catch (err) {
        apiService.handleResponseError(err);
      }

      this.editor.chain().focus().setImage({ src }).deleteRange({ from: this.getPos(), to: this.getPos() }).run();
      if (this.node) this.deleteNode();
    } catch (err) {
      apiService.handleResponseError(err);
    } finally {
      this.isLoading = false;
    }
  }

  async generateImage() {
    if (!this.prompt) {
      return notificationService.warning('Please enter a prompt');
    }

    if (this.prompt.length > 1000) {
      return notificationService.warning('Image prompt should be less than 1000 characters');
    }

    this.loaderTitle = 'AI is now doing its job!';
    this.isLoading = true;

    try {
      const imageBase64String = await socketService.article.ai.generateAiImage(this.prompt, this.style?.name);

      const image = `data:image/png;base64,${imageBase64String}`;

      if (!image.length) {
        this.isLoading = false;

        return;
      }

      this.previewImage = image;
    } catch (err) {
      apiService.handleResponseError(err);
    } finally {
      this.isLoading = false;
    }
  }
}
