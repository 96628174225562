
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Button, Popover, Input } from 'element-ui';
import { Editor } from '@tiptap/core';
import { COLOR_SET } from '@/components/tiptap/utils/color';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { TipTapEditorContext } from '@/components/tiptap/types';

@Component({
  components: {
    MenuButton,
    [Button.name]: Button,
    [Popover.name]: Popover,
    [Input.name]: Input,
  },
})
export default class MenuColorButton extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  private color = '';
  private popoverVisible = false;

  get colorSet() {
    return COLOR_SET;
  }

  get isActive() {
    return this.editorContext.state.isActiveTextStyle;
  }

  isColorSelected(color: string) {
    return this.editor.isActive('textStyle', { color });
  }

  removeColor() {
    this.popoverVisible = false;
    this.editor.chain().focus().unsetColor().run();
  }

  confirmColor(color: string) {
    this.popoverVisible = false;
    this.editor.chain().focus().setColor(color).run();
  }
}
