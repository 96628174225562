
import { TweenLite } from 'gsap';
import VueApp from '@/@types/app/VueApp';
import { Component, Prop, Watch } from 'vue-property-decorator';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import helper from '@/helper';

export const TIMELINE_ANIMATION_DURATION = 0.35;

@Component
export default class PlayerTimeline extends VueApp {
  @Prop() value: number;
  @Prop() max: number;
  @Prop({ default: true }) knobEnabled: boolean;
  @Prop({ type: Boolean, default: false }) isEditPage: boolean;

  window: {
    width: number | string;
    height: number | string;
  };
  currentPosition = 0;
  forcePosition: number | boolean = 0;
  dragging = {
    active: false,
    currentX: 0,
    initialX: 0,
    initialPos: 0,
    xOffset: 0,
  };

  skipNextTimelineClick = false;
  mouseDownTarget: any = null;

  get lineScaleX() {
    return this.currentPosition / 100;
  }

  get position() {
    return this.getPosition();
  }

  get isMobile() {
    return mobileResponsiveService.isMobileView;
  }

  get sidebarWidth(): number {
    return this.$store.state.ui.sidebarWidth;
  }

  knobTranslateX() {
    const width = this.getTimelineWidth();
    const position = this.currentPosition;
    return Math.round(((position * width) / 100) * 10) / 10;
  }

  handleClickTarget(e: MouseEvent) {
    this.mouseDownTarget = e.target;
  }

  handleClickFix(e: any) {
    if (
      e.target.classList.contains('timeline-active-el') ||
      (this.mouseDownTarget && this.mouseDownTarget.classList.contains('timeline-active-el'))
    ) {
      this.skipNextTimelineClick = true;
    }
  }

  handleClickOnTimeline(e: any) {
    // click on active element not allowed
    if (this.skipNextTimelineClick) {
      this.skipNextTimelineClick = false;
      return false;
    }
    if (e.target.classList.contains('timeline-active-el')) {
      return false;
    }

    let childOffset = 0;
    if (e.target !== this.$refs.timeline) {
      const targetRect = e.target.getBoundingClientRect() as DOMRect;
      const timeline = this.$refs.timeline as HTMLElement;
      const timelineRect = timeline.getBoundingClientRect() as DOMRect;
      childOffset = targetRect.x - timelineRect.x;
    }

    const localOffset = e.offsetX + childOffset;
    const clickOffset = this.pxToSeconds(localOffset);

    this.$emit('change', clickOffset);
  }

  handleDragStart(e: any) {
    this.dragging.xOffset = 0;
    this.dragging.currentX = 0;

    if (e.type === 'touchstart') {
      this.dragging.initialX = e.touches[0].clientX - this.dragging.xOffset;
    } else {
      this.dragging.initialX = e.clientX - this.dragging.xOffset;
    }
    this.dragging.initialPos = this.position as number;
    this.dragging.currentX = this.dragging.initialX;
    this.forcePosition = this.position;

    document.addEventListener('touchmove', this.handleDrag);
    document.addEventListener('mousemove', this.handleDrag);
    document.addEventListener('mouseup', this.handleDragEnd);
    document.addEventListener('touchend', this.handleDragEnd);

    this.dragging.active = true;
    this.$emit('dragStart');
  }

  handleDragEnd() {
    this.dragging.initialX = this.dragging.currentX;
    document.removeEventListener('touchmove', this.handleDrag);
    document.removeEventListener('mousemove', this.handleDrag);
    document.removeEventListener('mouseup', this.handleDragEnd);
    document.removeEventListener('touchend', this.handleDragEnd);

    const pos = parseInt(String(this.forcePosition));
    this.dragging.active = false;
    this.forcePosition = false;
    this.$emit('change', pos);

    if (this.isMobile) {
      this.$emit('dragEnd');
    }
  }

  handleDrag(e: any) {
    if (this.dragging.active) {
      if (e.type === 'touchmove') {
        this.dragging.currentX = e.touches[0].clientX;
      } else {
        this.dragging.currentX = e.clientX;
      }

      this.dragging.xOffset = this.dragging.currentX - this.dragging.initialX;
      this.forcePosition = this.dragging.initialPos + this.pxToSeconds(this.dragging.xOffset, false);

      if (this.forcePosition < 0) {
        this.forcePosition = 0;
      } else if (this.forcePosition >= this.max) {
        this.forcePosition = this.max - 1;
      }

      this.updatePosition();
      this.$emit('change', this.forcePosition);
    }
  }

  updatePosition() {
    this.currentPosition = this.msToPercentage(this.position);

    window.requestAnimationFrame(this.applyCssStyles);
  }

  getPosition() {
    return (this.forcePosition ? this.forcePosition : this.value) as number;
  }

  getTimelineWidth() {
    return this.$el ? this.$el.clientWidth : 0;
  }

  pxToSeconds(value: number, validate = true) {
    let s = Math.round((value / this.getTimelineWidth()) * this.max);
    if (validate) {
      if (s < 0) {
        s = 0;
      } else if (s > this.max) {
        return this.max;
      }
    }

    return s;
  }

  msToPercentage(ms: number) {
    let value = ms / this.max;
    if (value > 1) {
      value = 1;
    } else if (value < 0) {
      value = 0;
    }
    return value * 100;
  }

  msToTime(ms: number) {
    return helper.msToTime(ms);
  }

  applyCssStyles() {
    const animationDuration = this.dragging.active ? 0 : TIMELINE_ANIMATION_DURATION;

    if (this.$refs.knob) {
      TweenLite.to(this.$refs.knob, animationDuration, {
        x: this.knobTranslateX(),
        y: 0,
      });
      TweenLite.to(this.$refs.doneLine, animationDuration, {
        scaleX: this.lineScaleX,
      });
    }
  }

  handleWindowResize() {
    this.updatePosition();
  }

  // watch:    {
  @Watch('value')
  onValueChange() {
    this.updatePosition();
  }

  @Watch('max')
  onMaxChange() {
    this.updatePosition();
  }

  @Watch('currentPosition')
  onCurrentPositionChange(val: number) {
    this.$emit('cursorPositionChange', val);
  }

  @Watch('sidebarWidth')
  onSidebarWidthChanged() {
    this.updatePosition();
  }

  mounted() {
    this.updatePosition();
    this.$emit('ready');
  }

  created() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  }

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
}
