
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import { TipTapEditorContext } from '@/components/tiptap/types';
import ItalicButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/ItalicButton.vue';
import BoldButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/BoldButton.vue';
import UnderlineButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/UnderlineButton.vue';
import BulletListButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/BulletListButton.vue';
import EmojiButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/EmojiButton.vue';
import BlockquoteButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/BlockquoteButton.vue';
import TimeTagButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/TimeTagButton.vue';

@Component({
  components: {
    TimeTagButton,
    BulletListButton,
    ItalicButton,
    BoldButton,
    UnderlineButton,
    EmojiButton,
    BlockquoteButton,
  },
})
export default class CommentMenuBar extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  save() {
    this.$emit('save');
  }
}
