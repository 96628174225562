import { render, staticRenderFns } from "./OcrModeBar.vue?vue&type=template&id=849d1a4a&"
import script from "./OcrModeBar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./OcrModeBar.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./OcrModeBar.vue?vue&type=style&index=0&id=849d1a4a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports