/**
 * WARNING! pls use `camel-case` values for custom event events
 */

export const PARAGRAPH_PROGRESS_UPDATE = 'PARAGRAPH_PROGRESS_UPDATE';
export const CHECK_PARAGRAPH_PROGRESS = 'CHECK_PARAGRAPH_PROGRESS';

export const APP_REFRESH = 'APP_REFRESH';
export const APP_SAVE_RECORDER_VIDEO = 'APP_SAVE_RECORDER_VIDEO';
export const APP_START_RECORDING_PROMISE_RESOLVED = 'APP_START_RECORDING_PROMISE_RESOLVED';

export const ARTICLE_CREATE_UPDATE_ARTICLE = 'ARTICLE_CREATE_UPDATE_ARTICLE';
export const ARTICLE_CREATE_HIDE = 'ARTICLE_CREATE_HIDE';

export const TRIGGER_SEARCH_INPUT = 'TRIGGER_SEARCH_INPUT';

export const ARTICLE_CREATE = 'ARTICLE_CREATE';
export const ARTICLE_EDITOR_FORCE_FLUSH = 'ARTICLE_EDITOR_FORCE_FLUSH';

export const COMPANY_CREATE = 'company-create';

export const COPY_LINK = 'copy-link';

export const ARTICLE_PLAYER_CHANGE_STATE = 'ARTICLE_PLAYER_CHANGE_STATE';

export const SCROLL_TO_ARTICLE = 'scroll-to-article';
export const SHOW_ARTICLE_TAGS_DIALOG = 'show-article-tags-dialog';

export const OPEN_PERSONAL = 'open-personal';
export const TOGGLE_MENU = 'toggle-menu';
