import { UserNotification } from '@/models/UserNotification';

export interface NotificationsSidebarState {
  lastUpdateTime: number;
  isVisible: boolean;
  notifications: UserNotification[];
}

export const notificationsSidebarState: NotificationsSidebarState = {
  isVisible: false,
  notifications: [],
  lastUpdateTime: 0,
};
