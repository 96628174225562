
import urlService from '@/services/UrlService';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Workspace } from '@/models/Workspace';
import { Company } from '@/models/Company';
import WorkspaceViewPage from '@/components/pages/workspace/WorkspaceViewPage/WorkspaceViewPage.vue';
import { Article } from '@/models/article/Article';
import { BROWSE_SHARED, HOME, INACTIVE_COMPANY, LOGIN } from '@/routerNames';
import { Mutations } from '@/store/mutations';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import VueApp from '@/@types/app/VueApp';
import { Logger } from '@/other/Logger';
import navigationService from '@/services/NavigationService';
import { AxiosError } from 'axios';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import {
  commitSetSelectedArticle,
  commitSetWorkspaceViewCommentsCount,
  commitWorkspaceViewReset,
} from '@/store/commits/workspaceViewCommits';
import {
  commitSetArticleIdToExpand,
  commitSetArticleMode,
  commitSetAutoPlay,
  commitSetSharedArticleCompanyLogo,
} from '@/store/commits/sharedCommits';
import notificationService from '@/services/NotificationService';
import { CompanyResponse } from '@/@types/responses/CompanyResponses';
import store from '@/store';
import apiService from '@/services/ApiService';
import { ArticleComment } from '@/models/article/ArticleComment';
import { commitSetArticleComments } from '@/store/commits/commentsSidebarCommits';

const log = new Logger('BrowsePage');
@Component({
  components: {
    WorkspaceViewPage,
  },
})
export default class BrowsePage extends VueApp {
  @Prop(String) workspaceId: string;
  @Prop(String) articleId: string;
  @Prop(String) textTabSlug: string;
  @Prop(String) paragraphSlug: string;
  @Prop(String) sharedToken: string;
  @Prop(String) accessType: string;
  @Prop(String) mode: string;

  viewMode = ArticleEditMode.VIDEO;
  loader: any = null;
  workspace: Workspace | null = null;
  article: Article | null = null;
  sharedArticle: Article | null = null;
  canViewComments = false;

  isLoaded = false;

  get selectedArticle() {
    return this.$store.state.workspaceView.selectedArticle;
  }

  get uniqueId() {
    return this.workspace ? this.workspace.id : null;
  }

  async init() {
    this.loader = this.$loading({ lock: true });

    commitWorkspaceViewReset();
    commitSetArticleMode(undefined);
    this.viewMode = this.mode === ArticleEditMode.NOTES ? ArticleEditMode.NOTES : ArticleEditMode.VIDEO;
    this.workspace = null;

    try {
      if (this.sharedToken) {
        await this.initSharedWorkspace();
        this.loader = null;
        this.$nextTick(() => (this.isLoaded = true));
        return;
      }

      workspaceService.setState('isSharedMode', false);
      const isGuest = this.$auth.isGuest();

      let response;
      try {
        response = await this.$api.browse.getWorkspace(this.workspaceId);
      } catch (workspaceError) {
        if (!isGuest) {
          try {
            const id = this.articleId || this.workspaceId;
            const sharedResponse = await this.$api.sharedArticle.getSharedArticleToken(id, true, !this.articleId);

            if (sharedResponse.data?.token) {
              await navigationService.navigate({
                name: BROWSE_SHARED,
                params: {
                  sharedToken: sharedResponse.data?.token,
                  accessType: 'private',
                },
              });
              window.history.replaceState({}, '', urlService.getPrivatelySharedArticleUrl(sharedResponse.data.token));
              return;
            }
          } catch {
            throw workspaceError;
          }
        }
      }

      // Setup workspace
      if (response?.data?.workspace) {
        this.workspace = Workspace.parse(response?.data?.workspace);
      }

      const userCompany = !isGuest ? (this.$auth.user.company as Company) : null;

      if (!isGuest) {
        workspaceService.setLastWorkspaceToStorage(this.workspace.id);
      }

      // user get access to the public workspace
      if (isGuest || this.workspace.company !== userCompany.id) {
        try {
          const companyResponse = await this.$api.getCompanyById(this.workspace.company);
          if (companyResponse.data.company) {
            const company = Company.parse(companyResponse.data.company);
            this.$store.commit(Mutations.SET_PAGE_COMPANY, company);
            store.commit('setCompanyLoading', false);
          }
        } catch (error) {
          this.$api.handleResponseError(error);
        } finally {
          this.loader = null;
        }
      }

      this.openArticle();
    } catch (err: unknown) {
      if (err instanceof Error) {
        if (err.message === 'Guest access denied') {
          notificationService.error(`You don't have access to this workspace`);
        }
      }
      await this.handleInitializationError(err);
    } finally {
      this.loader = null;
    }

    this.$nextTick(() => (this.isLoaded = true));
  }

  async loadSharedArticleCompanyLogo(articleId: string) {
    try {
      const res = await apiService.browse.getSharedArticleCompanyLogo(articleId);

      return res.data.logo;
    } catch (err) {
      return null;
    }
  }

  private async initSharedWorkspace() {
    try {
      let res;
      if (this.accessType && this.accessType === 'private') {
        if (this.$auth.isGuest()) return;
        res = await this.$api.browse.getPrivatelySharedArticle(this.sharedToken);
      } else {
        res = await this.$api.browse.getSharedArticle(this.sharedToken);
      }

      this.sharedArticle = Article.fromJson({
        ...res.data.article,
        ...(this.accessType !== 'private' ? { shared: { token: this.sharedToken } } : {}),
      }) as Article;

      const articleId = this.sharedArticle.id;

      this.canViewComments = !this.$auth.isGuest();

      if (this.canViewComments) {
        try {
          const response = await apiService.comment.getAll(articleId);
          const parsedComments = response.data.comments.length
            ? response.data.comments.map(({ comment, profileImage }) =>
                ArticleComment.parse({
                  ...comment,
                  profileImage,
                })
              )
            : [];
          commitSetArticleComments(parsedComments);
          commitSetWorkspaceViewCommentsCount(parsedComments.length);
        } catch (error) {
          apiService.handleResponseError(error);
        }
      }

      commitSetSelectedArticle(this.sharedArticle);
      workspaceService.setState('articles', [this.sharedArticle]);
      workspaceService.setState('isSharedMode', true);

      // If there paragraphs, and no pinned notes, remove selected text tab
      const { paragraphs, textTabs, pinnedNote } = this.sharedArticle;
      const firstTextTab = textTabs?.length ? textTabs[0] : null;
      const shouldRemoveSelectedTextTab = paragraphs?.length && textTabs?.length && !pinnedNote;

      window.setTimeout(async () => {
        await dispatchUpdateSelectedTextTab(shouldRemoveSelectedTextTab ? null : firstTextTab);
      }, 0);

      const logo = await this.loadSharedArticleCompanyLogo(this.sharedArticle.id);

      commitSetSharedArticleCompanyLogo(logo);

      store.commit('setCompanyLoading', false);
    } catch (e) {
      const error = e as AxiosError<{ error: string; company?: CompanyResponse }>;
      if (error.response && error.response.data.company) {
        this.$store.commit(Mutations.SET_COMPANY_STATUS, error.response.data.company.isActivated);
        return navigationService.navigate({ name: INACTIVE_COMPANY });
      }
      if (error && error.response && [404, 422].includes(error.response.status)) {
        this.$api.handleResponseError(error);
        if (this.$auth.isAuthorized()) {
          return this.$router.push({ name: HOME });
        } else {
          return this.$router.push({ name: LOGIN });
        }
      }
    }
  }

  private handleInitializationError(e: unknown) {
    const error = e as AxiosError<{ error: string; company?: CompanyResponse }>;
    if (error && error.response) {
      if (error.response.data && error.response.data.company) {
        this.$store.commit(Mutations.SET_COMPANY_STATUS, error.response.data.company.isActivated);
        navigationService.navigate({ name: INACTIVE_COMPANY });
        return;
      }
      if (error.response.status === 404) {
        log.error(error);
        if (this.$auth.isAuthorized()) {
          return this.$router.push({ name: HOME });
        } else {
          return this.$router.push({ name: LOGIN });
        }
      }
      if (error.response.status === 422 && this.$auth.isAuthorized()) {
        notificationService.error(error.response.data.error);
        return this.$router.push({ name: HOME });
      }
    }
    this.$api.handleResponseError(error);
  }

  openArticle() {
    if (this.articleId) {
      commitSetAutoPlay({
        articleId: this.articleId,
        workspaceId: this.workspaceId,
        paragraphSlug: this.paragraphSlug || undefined,
      });

      // Open first text tab that matches textTabSlug
      if (this.textTabSlug) store.commit('setArticleEditorSelectedTextTabSlug', this.textTabSlug);

      commitSetArticleIdToExpand(this.articleId);
      commitSetArticleMode(this.viewMode);
    } else {
      commitSetAutoPlay({ workspaceId: this.workspaceId });
    }
  }

  mounted() {
    this.init();
  }

  beforeDestroy() {
    this.$store.commit(Mutations.SET_PAGE_COMPANY, null);
  }

  @Watch('workspaceId')
  onWorkspaceIdChanged() {
    this.isLoaded = false;
    this.init();
  }

  @Watch('articleId')
  onArticleIdChanged(value: string, oldValue: string) {
    if ((value && typeof oldValue === 'undefined') || value !== oldValue) {
      this.openArticle();
    }
  }

  @Watch('mode')
  onModeChanged() {
    this.viewMode = this.$route.params.mode === ArticleEditMode.NOTES ? ArticleEditMode.NOTES : ArticleEditMode.VIDEO;
    commitSetArticleMode(this.viewMode);
  }
}
