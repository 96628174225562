
import { cleanFormattedTextAction } from '@/components/tiptap/actions/cleanFormattedTextAction';
import SelectLinkPlugin from '@/components/tiptap/plugins/SelectLinkPlugin';
import CharacterCount from '@tiptap/extension-character-count';
import MenuBubble from './Menu/MenuBubble/MenuBubble.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Editor, EditorContent } from '@tiptap/vue-2';
import { Editor as EditorCore } from '@tiptap/core';
import tipTapEditorService from '@/components/tiptap/TipTapEditorService';
import EditorContentPlugin from '@/components/tiptap/plugins/EditorContentPlugin';
import { commentTipTapEmptyState, CommentTipTapEditorContext, TipTapSelection } from '@/components/tiptap/types';
import CommentMenuBar from '@/components/tiptap/Menu/CommentMenuBar.vue';
import TipTapDocument from '@tiptap/extension-document';
import { extensions } from '@/components/tiptap/shared/extensions';
import { KEYBOARD_PLAYER_CODES } from '@/@types/enums/ArticlePlayerTypes';
import Emoji from '@/components/tiptap/extensions/Emoji';
import Paragraph from '@tiptap/extension-paragraph';
import { ArticleComment } from '@/models/article/ArticleComment';
import {
  commitSetCommentsSidebarIsActiveTimeTag,
  commitSetCommentsSidebarVideoReaction,
} from '@/store/commits/commentsSidebarCommits';
import Mention from '@/components/tiptap/extensions/Mention';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { User } from '@/models/User';

import 'codemirror/lib/codemirror.css'; // import base style
import 'codemirror/mode/xml/xml.js'; // language
import 'codemirror/addon/selection/active-line.js'; // require active-line.js
import 'codemirror/addon/edit/closetag.js';
import './styles/Editor.scss';

const IS_EDITOR_FOCUSED = 'is-editor-focused';
const VALIDATION_HIGHLIGHT_TIMEOUT = 1000;
const BLUR_TIMEOUT = 100;

@Component({ components: { EditorContent, CommentMenuBar, MenuBubble } })
export default class CommentTipTap extends Vue {
  @Prop({ type: String, required: false }) content: string;
  @Prop({ type: String, required: false }) quote: string;
  @Prop({ type: Boolean, default: false }) isMenuBar: boolean;
  @Prop({ type: Boolean, default: false }) setIsFocused: boolean;
  @Prop({ default: null }) comment: ArticleComment;
  @Prop({ type: Object, default: null, required: false }) replyUser: User;

  editorKey = tipTapEditorService.generateKey();
  charactersCount = 0;
  validationTimeout: number;
  isEditMode = false;
  isValidationFail = false;
  blurTimeout = 0;

  editorContext: CommentTipTapEditorContext = {
    state: commentTipTapEmptyState(),
    selection: null,
  };

  get editor() {
    return tipTapEditorService.get(this.editorKey);
  }

  get editorMode() {
    return this.isEditMode || this.isMenuBar;
  }

  get videoReaction() {
    return this.$store.state.commentsSidebar.videoReaction;
  }

  get videoPlayerRef() {
    return this.$store.state.videoPlayerRef;
  }

  get articleMode(): ArticleEditMode {
    return this.$store.state.articleMode;
  }

  hideMenuBar(editor: EditorCore) {
    editor.commands.blur();
  }

  onFocus() {
    window.clearTimeout(this.blurTimeout);
    this.isEditMode = true;
    this.$emit(IS_EDITOR_FOCUSED, true);
  }

  onBlur() {
    this.blurTimeout = window.setTimeout(() => {
      this.isEditMode = false;
    }, BLUR_TIMEOUT);
  }

  created() {
    const editor = new Editor({
      extensions: [
        ...extensions,
        TipTapDocument,
        Emoji,
        Paragraph,
        Mention,
        CharacterCount.configure({ limit: 15000 }),
      ],
      content: this.content,
      editable: true,
      onUpdate: this.onUpdate.bind(this),
      onSelectionUpdate: this.onSelectionUpdate.bind(this),
      editorProps: {
        transformPastedHTML(html) {
          return cleanFormattedTextAction(html);
        },
      },
    });
    editor.registerPlugin(SelectLinkPlugin);
    editor.registerPlugin(EditorContentPlugin);
    this.charactersCount = editor.storage.characterCount.characters();

    tipTapEditorService.registerEditor(this.editorKey, editor);
  }

  updateContext() {
    const editor = this.editor;
    this.editorContext.selection = editor.state.selection.toJSON() as TipTapSelection;
    this.editorContext.state = {
      isActiveBold: editor.isActive('bold'),
      isActiveItalic: editor.isActive('italic'),
      isActiveStrike: editor.isActive('strike'),
      isActiveLink: editor.isActive('link'),
      isActiveUnderline: editor.isActive('underline'),
      isActiveTextAlignLeft: editor.isActive({ textAlign: 'left' }),
      isActiveBulletList: editor.isActive('bulletList'),
      isActiveOrderedList: editor.isActive('orderedList'),
      isActiveTodoList: editor.isActive('taskList'),
      isActiveTextStyle: editor.isActive('textStyle'),
      isActiveHighlight: editor.isActive('highlight'),
      isActiveBlockquote: editor.isActive('blockquote'),
    };
  }

  requestUpdateContext() {
    this.updateContext();
  }

  emitSaveEvent() {
    const html = this.editor.getHTML();
    const text = this.editor.getText();
    const emptyStringRegex = /^\s*$/;

    if (text.match(emptyStringRegex)) {
      this.isValidationFail = true;
      this.validationTimeout = window.setTimeout(() => {
        this.isValidationFail = false;
      }, VALIDATION_HIGHLIGHT_TIMEOUT);
      return;
    }

    this.isValidationFail = false;
    window.clearTimeout(this.validationTimeout);

    this.$emit('save', html);
    this.editor.commands.setContent('');
    this.hideMenuBar(this.editor);
  }

  onUpdate() {
    this.charactersCount = this.editor.storage.characterCount.characters();
    this.requestUpdateContext();
  }

  onSelectionUpdate() {
    this.requestUpdateContext();
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.editorMode && event.shiftKey && event.code === KEYBOARD_PLAYER_CODES.ENTER_KEY) this.emitSaveEvent();
  }

  mounted() {
    document.addEventListener('keyup', this.onKeyDown);
    this.editor.on('focus', this.onFocus);
    this.editor.on('blur', this.onBlur);
    this.setIsFocused && this.editor.commands.focus();
    if (this.replyUser) {
      this.insertReplyMention();
    }
  }

  insertReplyMention() {
    const props = { id: this.replyUser.id, label: this.replyUser.name };
    this.editor
      .chain()
      .focus()
      .insertContentAt(1, [
        {
          type: 'mention',
          attrs: props,
        },
        {
          type: 'text',
          text: ' ',
        },
      ])
      .run();
  }

  beforeDestroy() {
    document.removeEventListener('keyup', this.onKeyDown);
    tipTapEditorService.destroyEditor(this.editorKey);
  }

  @Watch('isEditMode')
  onEditModeUpdated() {
    if (!this.isEditMode) return;

    const videoReaction = { ...this.videoReaction };
    const isVideoMode = this.articleMode === ArticleEditMode.VIDEO;

    if (!isVideoMode) {
      videoReaction.currentVideoTime = null;
      commitSetCommentsSidebarIsActiveTimeTag(false);
    }

    if (this.comment) {
      videoReaction.currentVideoTime = this.comment.timestamp;
      commitSetCommentsSidebarIsActiveTimeTag(true);
    } else {
      videoReaction.currentVideoTime = null;
    }

    if (!this.comment && !this.content && videoReaction.paragraphId && isVideoMode) {
      videoReaction.currentVideoTime = this.videoPlayerRef.currentTime;
    }

    commitSetCommentsSidebarVideoReaction(videoReaction);
  }
}
