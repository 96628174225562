import { Company } from './Company';
import { User } from '@/models/User';
import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';

export class Group implements Model {
  [index: string]: any;

  id: string = null;
  title: string = null;
  role: string = null;
  company: string | Company = null;
  userIds: string[] = [];
  invitedEmails: string[] = [];
  userList: User[] = [];
  createdAt: number = null;

  static parse(data: unknown): Group {
    return modelService.create(Group, data);
  }

  constructor(title: string = null) {
    this.title = title;
  }

  afterLoad() {}

  addInvitedEmail = (email: string) => {
    if (!this.invitedEmails) {
      this.invitedEmails = [];
    }

    if (!this.invitedEmails.includes(email)) {
      this.invitedEmails.push(email);
      return true;
    }
    return false;
  };
}
