
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';
import { Popover } from 'element-ui';
import { ArticleComment } from '@/models/article/ArticleComment';
import {
  commitSetCommentsSidebarIsVisible,
  commitSetCommentsSidebarScrollToCommentId,
} from '@/store/commits/commentsSidebarCommits';
import { RecordingReaction } from '@/@types/RecordingReaction';
import TimeTag from '@/components/tiptap/Menu/MenuItems/TimeTag/TimeTag.vue';
import helper from '@/helper';
import articleCommentService from '@/services/article/ArticleCommentService';

@Component({
  components: { TimeTag, UserProfilePhoto, Popover },
})
export default class ArticleTimelineReaction extends Vue {
  @Prop({ required: true }) reaction: RecordingReaction;

  get articleComments(): ArticleComment[] {
    return this.$store.state.commentsSidebar.comments;
  }

  get comment(): ArticleComment {
    return this.articleComments?.find((comment: ArticleComment) => comment.id === this.reaction.commentId);
  }

  get commentTimestamp() {
    if (this.comment.timestamp) {
      return helper.msToTime(this.comment.timestamp);
    }
    return null;
  }

  get commentContent(): string {
    const html = this.comment.content;
    return helper.stripHtml(html);
  }

  get authorName() {
    return articleCommentService.getAuthorName(this.comment);
  }

  onClick() {
    commitSetCommentsSidebarScrollToCommentId(this.reaction.commentId);
    commitSetCommentsSidebarIsVisible(true);
  }
}
