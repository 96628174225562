import store from '@/store';
import { IAutoplay } from '@/models/IAutoplay';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import ArticlePlayer from '@/components/article-player/ArticlePlayer.vue';

export const commitSetAutoPlay = (autoplay: IAutoplay) => {
  store.commit('setAutoPlay', autoplay);
};

export const commitSetVideoPlayerTimeToSet = (ms: number | null) => {
  store.commit('setVideoPlayerTimeToSet', ms);
};

export const commitSetArticleIdToExpand = (articleId: string) => {
  store.commit('setArticleIdToExpand', articleId);
};

export const commitSetArticleMode = (mode?: ArticleEditMode) => {
  store.commit('setArticleMode', mode);
};

export const commitSetVideoPlayerRef = (videoPlayerRef: ArticlePlayer) => {
  store.commit('setVideoPlayerRef', videoPlayerRef);
};

export const commitSetSharedArticleCompanyLogo = (logoUrl: string) => {
  store.commit('setSharedArticleCompanyLogo', logoUrl);
};
