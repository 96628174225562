
import { Component, Prop, Watch } from 'vue-property-decorator';
import * as routerNames from '@/routerNames';
import EventBus from '@/EventBus';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import VueApp from '@/@types/app/VueApp';
import WorkspaceSidebar from '@/components/layout/sidebars/WorkspaceSidebar/WorkspaceSidebar.vue';
import ArticleSidebar from '@/components/layout/sidebars/ArticleSidebar.vue';
import SettingsSidebar from '@/components/layout/sidebars/SettingsSidebar.vue';
import AdminSidebar from '@/components/layout/sidebars/AdminSidebar.vue';

let handleFocusOut: any;
@Component({
  components: { AdminSidebar, SettingsSidebar, ArticleSidebar, WorkspaceSidebar },
  directives: {
    'focus-out': {
      bind: (el: any, binding: any, vnode: any) => {
        handleFocusOut = (event: any) => {
          event.stopPropagation();
          const isMobileSidebarOpen = vnode.context.isMobileSidebarOpen;
          const isClickedInside = event.target.closest('#sidebar');
          const isButtonTriggers =
            event.target.classList.contains('burger-menu') || event.target.classList.contains('mobile-sidebar-trigger');

          if (isButtonTriggers) {
            return;
          }

          if (isMobileSidebarOpen && !isClickedInside) {
            vnode.context.toggleSidebarMobile();
          }
        };
        if (vnode.context.isMobile) {
          document.body.addEventListener('click', handleFocusOut);
        }
      },
      unbind: () => {
        document.body.removeEventListener('click', handleFocusOut);
      },
    },
  },
})
export default class Sidebar extends VueApp {
  @Prop() isMobileSidebarOpen: boolean;

  activeSidebar: 'settings' | 'workspace' | 'article' | 'admin' = null;

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  toggleSidebarMobile() {
    EventBus.$emit('toggle-mobile-sidebar');
  }

  updateActiveSidebar() {
    switch (this.$route.name) {
      case routerNames.WORKSPACE_VIEW:
      case routerNames.BROWSE:
      case routerNames.BROWSE_TEXT_TAB:
      case routerNames.BROWSE_PARAGRAPH:
      case routerNames.BROWSE_SHARED:
      case routerNames.BROWSE_SHARED_DEPRECATED:
        this.activeSidebar = 'workspace';
        break;
      case routerNames.ARTICLE_EDIT:
      case routerNames.ARTICLE_CREATE:
      case routerNames.ARTICLE_VERSION_EDIT:
      case routerNames.DRAFT_EDIT:
        this.activeSidebar = 'article';
        break;
      case routerNames.SETTINGS:
      case routerNames.COMPANY:
      case routerNames.COMPANY_GROUP:
      case routerNames.COMPANY_GROUPS:
      case routerNames.USER_PROFILE:
      case routerNames.USER_MANAGEMENT:
      case routerNames.BILLING_PLAN_DETAILS:
      case routerNames.BILLING_HISTORY:
      case routerNames.BILLING_PLANS:
        this.activeSidebar = 'settings';
        break;
      case routerNames.ADMIN_PANEL:
        this.activeSidebar = 'admin';
        break;
      default: {
        this.activeSidebar = null;
      }
    }
  }

  mounted() {
    this.updateActiveSidebar();
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.updateActiveSidebar();
  }
}
