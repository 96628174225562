import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from 'prosemirror-model';

export type EmojiOptions = {
  HTMLAttributes: Record<string, any>;
  renderIcon: (node: ProseMirrorNode) => string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    emoji: {
      insertEmoji: (icon: string) => ReturnType;
    };
  }
}

const Emoji = Node.create<EmojiOptions>({
  name: 'emoji',

  addOptions() {
    return {
      HTMLAttributes: {},
      renderIcon(node) {
        return `${node.attrs.icon}`;
      },
    };
  },

  group: 'inline',
  inline: true,
  selectable: true,
  draggable: false,
  atom: true,

  addAttributes() {
    return {
      icon: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-icon'),
        renderHTML: (attributes) => (attributes.icon ? { 'data-icon': attributes.icon } : {}),
      },
    };
  },

  parseHTML() {
    return [{ tag: `span[data-type="${this.name}"]` }];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        {
          'data-type': this.name,
          class: 'tiptap-emoji',
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      node.attrs.icon,
    ];
  },

  renderText({ node }) {
    return this.options.renderIcon(node);
  },

  addCommands() {
    return {
      insertEmoji:
        (icon) =>
        ({ commands }) => {
          return commands.insertContent({ type: this.name, attrs: { icon } });
        },
    };
  },
});

export default Emoji;
