
import VueApp from '@/@types/app/VueApp';
import ArticleEditor from '@/components/article-editor/ArticleEditor/ArticleEditor.vue';
import UploadRecordStatus from '@/components/layout/shared/UploadRecordStatus/UploadRecordStatus.vue';
import { Article } from '@/models/article/Article';
import { Draft } from '@/models/article/Draft';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { ARTICLE_CREATE, DRAFTS, HOME, LOGIN } from '@/routerNames';
import draftService from '@/services/draft/DraftService';
import { dispatchLoadArticleComments } from '@/store/dispatchers/articleCommentsDispatchers';
import { dispatchLoadCompanyUsers } from '@/store/dispatchers/companyDispatchers';
import { Component, Watch } from 'vue-property-decorator';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { commitSetArticleEditorArticle } from '@/store/commits/articleEditorCommits';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';

@Component({
  components: {
    ArticleEditor,
    UploadRecordStatus,
  },
})
export default class DraftEditPage extends VueApp {
  loader: ElLoadingComponent = null;
  articleId: string = null;
  article: Article = null;

  get isVideoProcessing() {
    return this.$store.state.articleEditor.isVideoProcessing;
  }

  get articleEditMode() {
    return this.$store.state.articleMode;
  }

  loadDraftArticle(id: string) {
    this.$nextTick(() => {
      this.loader = this.$loading({
        lock: true,
        text: 'Loading Article',
      });
    });
    this.$api.draft
      .get(id)
      .then((res) => {
        this.article = Draft.fromJson(res.data.draft);
        const isExtension = !!this.$store.state.extension.id;
        if ((this.article.acceptSessionId || this.article.isNotUploadedVideo) && isExtension) {
          this.$store.commit('setArticleEditorIsVideoProcessing', true);
        }
        commitSetArticleEditorArticle(this.article);
        this.updateEditMode();
        const articleTextTabs = this.article.textTabs;
        const firstTextTab = this.article.textTabs[0];
        const selectedTextTab = this.$store.state.articleEditor.selectedTextTab.tab;
        const selectedTextTabExists =
          !this.article.textTabs.length || !selectedTextTab
            ? false
            : this.article.textTabs.some((tab) => tab.id === selectedTextTab.id);

        if (articleTextTabs.length && !selectedTextTabExists) {
          if (this.articleEditMode === ArticleEditMode.NOTES) {
            dispatchUpdateSelectedTextTab(firstTextTab);
          } else {
            dispatchUpdateSelectedTextTab(null);
          }
        }
        dispatchLoadArticleComments();
        draftService.loadDraftHash(this.article.id);

        this.$store.dispatch('commentsSidebarCheckAutoShowComment');
      })
      .catch((data) => {
        this.$api.handleResponseError(data);
        this.$router.push({ name: DRAFTS });
      })
      .then(() => {
        this.closeLoader();
      });
  }

  closeLoader() {
    if (this.loader) {
      this.loader.close();
      this.loader = null;
    }
  }

  created() {
    const {
      name: routeName,
      params: { id: articleId },
    } = this.$route;
    const isGuest = this.$auth.isGuest();
    // Get the current state of the article creation process from the store
    const createArticleState = this.$store.state.createArticle;
    this.$store.commit('toggleExpandMainSidebar', false);

    if (!this.$store.state.companyUsers.length) {
      dispatchLoadCompanyUsers();
    }

    // Determine if a new article should be loaded
    const isCreatingNewArticle = articleId && routeName === ARTICLE_CREATE && !createArticleState;

    // Redirect to Home page if there's no articleId in article creation page
    if (!articleId && routeName === ARTICLE_CREATE) {
      this.$router.push({ name: isGuest ? LOGIN : HOME });
      return;
    }

    if (isCreatingNewArticle) {
      this.loadDraftArticle(articleId);
      return;
    }

    if (articleId) {
      this.loadDraftArticle(articleId);
      return;
    }

    if (createArticleState) {
      // If there is a prefilled article in the state, use it and then clear the state
      this.article = Draft.fromJson(createArticleState);
      this.$store.commit('setCreateArticle', null);
    } else {
      this.article = new Draft();
    }
  }

  beforeDestroy() {
    this.$store.commit('setArticleEditorIsVideoProcessing', false);
    this.closeLoader();
  }

  updateEditMode() {
    let mode = ArticleEditMode.VIDEO;
    if (this.article && this.article.isTextArticle()) {
      mode = ArticleEditMode.NOTES;
    }
    if (this.$route.params.mode) {
      mode = this.$route.params.mode === ArticleEditMode.NOTES ? ArticleEditMode.NOTES : ArticleEditMode.VIDEO;
    }
    commitSetArticleMode(mode);
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    dispatchUpdateSelectedTextTab(null);

    this.updateEditMode();
  }
}
