import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';
import { AnyJson } from '@/@types/AnyJson';

export class TextTab implements Model {
  id: string | null = null;
  title = '';
  text = '';
  key: any = null;

  constructor(title = 'Note') {
    this.title = title;
    this.key = modelService.generateKey();
  }

  static parse(jsonData: AnyJson) {
    return modelService.create(TextTab, jsonData);
  }

  afterLoad(data: AnyJson) {
    this.id = data.id || data._id;
    this.title = data.title;
    this.text = data.text;
  }
}
