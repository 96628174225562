
import { Component, Vue } from 'vue-property-decorator';
import { Popover } from 'element-ui';
import MenuCommentButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/MenuCommentButton.vue';
import EmojiIcon from '@/components/tiptap/Menu/MenuItems/Emoji/EmojiIcon.vue';

const EMOJIS = ['👍', '😀', '😉', '😧', '😎', '😢', '😡', '🔥', '❤️'];

@Component({
  components: {
    EmojiIcon,
    MenuCommentButton,
    Popover,
  },
})
export default class EmojiButton extends Vue {
  emojis = EMOJIS;

  onClick(icon: string) {
    this.$emit('click', icon);
  }
}
