
import VueApp from '@/@types/app/VueApp';
import EventBus from '@/EventBus';
import { Workspace } from '@/models/Workspace';
import { Component, Watch } from 'vue-property-decorator';
import EmptyStateCard from '@/components/layout/cards/EmptyStateCard.vue';
import { WORKSPACE_EDIT } from '@/routerNames';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import { VISIBILITY } from '@/models/Visibility';
import moment from 'moment';
import urlService from '@/services/UrlService';
import { WorkspaceVisibility } from '@/other/WorkspaceVisibility';
import { AnyJson } from '@/@types/AnyJson';

@Component({
  components: {
    EmptyStateCard,
  },
})
export default class WorkspacesPage extends VueApp {
  workspaces: Workspace[] = [];
  searchQuery = '';
  searchPrivacy = '';
  searchUser = '';
  showDialog = false;
  mobileFilter = {
    searchQuery: '',
    privacy: '',
    createdBy: '',
  };
  currentSort = 'createdAt';
  currentSortOrder = 'desc';

  filteredWorkspaces: Workspace[] = [];

  get displayWorkspacePrivacyOptions(): string[] {
    return Object.values(VISIBILITY);
  }

  get displayUserOptions(): string[] {
    return this.uniqueUsersHandler(this.workspaces);
  }

  openFilterDialog() {
    this.showDialog = true;
  }

  closeFilterDialog() {
    this.showDialog = false;
  }

  cancelFilter() {
    this.mobileFilter = {
      searchQuery: '',
      privacy: '',
      createdBy: '',
    };
    this.mobileSortHandler();
  }

  mobileSortHandler() {
    this.searchQuery = this.mobileFilter.searchQuery;
    this.searchPrivacy = this.mobileFilter.privacy;
    this.searchUser = this.mobileFilter.createdBy;
    this.closeFilterDialog();
  }

  uniqueUsersHandler(workspaces: Workspace[]) {
    const workspaceCreators = workspaces.map((workspace: Workspace) => {
      const user = workspace.author ? workspace.author : workspace.user;
      let author = '-';

      if (user && user.firstName) {
        author = `${user.firstName} ${user.lastName}`;
      }
      return author;
    });
    return [...new Set(workspaceCreators)];
  }

  allowEdit(workspace: Workspace) {
    if (workspace) {
      return workspace.canEdit();
    }
    return false;
  }

  hasWorkspaces(workspaces: Workspace[]): boolean {
    return (
      workspaces.length > 1 || (workspaces.length === 1 && workspaces[0].visibility !== WorkspaceVisibility.personal)
    );
  }

  getWorkspaceAuthor(workspace: Workspace) {
    const user = workspace.author ? workspace.author : workspace.user;

    if (user && user.firstName) {
      const { firstName } = user;
      let { lastName } = user;
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

      return `${firstName} ${lastName}`;
    }
    return '-';
  }

  async toggleFavoriteStatus(workspace: Workspace) {
    workspace.isFavorite = !workspace.isFavorite;

    const data = JSON.parse(JSON.stringify(workspace));
    await this.$api.workspace.saveFavorite(data);
    this.workspaces = this.orderWorkspaces(this.workspaces);
  }

  orderWorkspaces(workspaces: Workspace[]) {
    const favoriteWorkspaces = workspaces.sort((a, b) => {
      if (a.createdAt === undefined || b.createdAt === undefined) {
        return -1;
      }
      return a.createdAt > b.createdAt ? 1 : -1;
    });

    return [...favoriteWorkspaces];
  }

  convertUnixToTime(date: number) {
    if (date === undefined) {
      return '-';
    }
    return moment(date).format('DD/MM/YYYY');
  }

  filterWorkspaces() {
    this.filteredWorkspaces = workspaceService
      .filteredWorkspaces(this.workspaces, this.searchQuery, this.searchPrivacy, this.searchUser)
      .sort((a: Workspace) => (a.isArchived ? 1 : -1));
  }

  customSort(workspaces: Workspace[]) {
    return workspaces.sort((a: AnyJson, b: AnyJson) => {
      const sortBy = this.currentSort;
      if (this.currentSortOrder === 'desc') {
        return a[sortBy] - b[sortBy];
      }

      return b[sortBy] - a[sortBy];
    });
  }

  changeSortField(field: string) {
    this.currentSort = field;
  }

  handleClickWorkspace(e: PointerEvent | MouseEvent, workspace: Workspace) {
    e.preventDefault();
    e.stopPropagation();

    const isModifierPressed = e.metaKey || e.ctrlKey || e.button === 1;

    if (isModifierPressed) {
      return window.open(urlService.getWorkspaceViewUrl(workspace), '_blank');
    }

    this.$router.push(urlService.getWorkspaceViewRoute(workspace));
  }

  handleEditWorkspace(workspace: Workspace) {
    this.$router.push({ name: WORKSPACE_EDIT, params: { id: workspace.id } });
  }

  async handleToggleWorkspaceArchive(workspace: Workspace) {
    const updatedWorkspace = await workspaceService.toggleWorkspaceArchive(workspace, workspace.isArchived);
    if (updatedWorkspace) {
      workspace.isArchived = updatedWorkspace.isArchived;
    }
  }

  handleCreateWorkspace() {
    this.$store.commit('setIsWorkspaceCreateDialogVisible', true);
  }

  handleDeleteWorkspace(workspace: Workspace) {
    workspaceService.deleteWorkspace(workspace, this.removeWorkspace);
  }

  removeWorkspace(workspace: Workspace) {
    const index = this.workspaces.indexOf(workspace);
    if (index >= 0) {
      this.workspaces.splice(index, 1);
    }
    EventBus.$emit('workspace-delete', workspace);
  }

  onWorkspacesLoaded(res: Workspace[]) {
    this.workspaces = this.orderWorkspaces(res);
    this.filterWorkspaces();
  }

  capitalizeText(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  mounted() {
    this.init();
  }

  init() {
    workspaceService.fetchWorkspaces(this.onWorkspacesLoaded);
    workspaceService.setState('workspace', null);
  }

  @Watch('searchUser')
  @Watch('searchPrivacy')
  @Watch('searchQuery')
  onSearchChanged() {
    this.filterWorkspaces();
    this.filteredWorkspaces = this.customSort(this.filteredWorkspaces);
  }
}
