import modelService from '@/services/ModelService';

export class Range {
  start = 0;
  end = 0;
  key: string | null = null;

  constructor(start = 0, end = 0) {
    this.start = start;
    this.end = end;
    this.key = modelService.generateKey();
  }

  length(): number {
    return this.end - this.start;
  }

  contain(ms: number): boolean {
    return this.start <= ms && ms < this.end;
  }

  overlapWith(range: Range): boolean {
    // tslint:disable-next-line:max-line-length
    return (
      Math.max(range.end, this.end) - Math.min(range.start, this.start) <
      this.end - this.start + (range.end - range.start)
    );
  }

  cut(ranges: Range[]): Range[] {
    const data = [new Range(this.start, this.end)];

    ranges.forEach((trimRange: Range) => {
      for (const index in data) {
        if (data[index]) {
          const item = data[index];
          if (item.overlapWith(trimRange)) {
            // cut range
            const res = [];

            // right overlap
            if (item.start < trimRange.start) {
              res.push(new Range(item.start, Math.min(item.end, trimRange.start)));
            }
            // left overlap
            if (item.end > trimRange.end) {
              res.push(new Range(Math.max(item.start, trimRange.end), item.end));
            }

            data.splice(parseInt(index), 1);
            if (res[0]) {
              data.splice(parseInt(index), 0, res[0]);
            }
            if (res[1]) {
              data.splice(parseInt(index) + 1, 0, res[1]);
            }
          }
        }
      }
    });

    return data;
  }

  shift(delta: number): Range {
    this.start += delta;
    this.end += delta;

    return this;
  }

  toObject(): unknown {
    const o = JSON.parse(JSON.stringify(this));
    delete o.key;
    return o;
  }

  toString(): string {
    return this.start + ', ' + this.end;
  }
}
