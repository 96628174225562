
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { Editor } from '@tiptap/core';

@Component({
  components: {
    MenuButton,
  },
})
export default class MenuOpenLinkButton extends Vue {
  @Prop({ required: true }) editor: Editor;

  openLink() {
    const { href } = this.editor.getAttributes('link');

    if (href) {
      // prevent attack
      const newTab = window.open();
      if (newTab) {
        newTab.opener = null;
        newTab.location.href = href;
      }
    }
  }
}
