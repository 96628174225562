export const HOME = 'Home';
export const BROWSE = 'Browse';
export const BROWSE_PARAGRAPH = 'BrowseParagraph';
export const BROWSE_OLD = 'BrowseOld';
export const BROWSE_SHARED = 'BrowseShared';
export const BROWSE_SHARED_DEPRECATED = 'BrowseShared_Deprecated';
export const BROWSE_TEXT_TAB = 'BrowseTextTab';
export const WORKSPACES = 'Workspaces';
export const WORKSPACE_CREATE = 'WorkspaceCreateDialog.vue';
export const WORKSPACE_EDIT = 'WorkspaceEdit';
export const WORKSPACE_VIEW = 'WorkspaceView';
export const ARTICLE_EDIT = 'ArticleEdit';
export const ARTICLE_CREATE = 'ArticleCreate';
export const ARTICLE_VERSION_EDIT = 'ArticleVersionEdit';
export const DRAFTS = 'Drafts';
export const DRAFT_EDIT = 'DraftEdit';
export const SETTINGS = 'Settings';
export const USER_PROFILE = 'UserProfile';
export const COMPANY = 'Company';
export const COMPANY_GROUP = 'CompanyGroup';
export const COMPANY_GROUPS = 'CompanyGroups';
export const USER_MANAGEMENT = 'UserManagementPage';
export const USER_DEACTIVATED = 'UserDeactivated';
export const SIGNUP_INVITED = 'SignupInvited';
export const SIGNUP = 'Signup';
export const LOGIN_SOCIAL_CALLBACK = 'LoginSocialCallback';
export const LOGIN_SOCIAL_FAIL = 'LoginSocialFail';
export const LOGIN = 'Login';
export const PASSWORD_RESET = 'PasswordReset';
export const PASSWORD_RESET_TOKEN = 'PasswordResetToken';
export const ADMIN_PANEL = 'AdminPanel';
export const INACTIVE_COMPANY = 'InactiveCompany';
export const BILLING_PLAN_DETAILS = 'BillingPlanDetails';
export const BILLING_HISTORY = 'BillingHistory';
export const BILLING_PLANS = 'BillingPlans';
export const COMPANY_NOT_PAID = 'CompanyNotPaid';
export const COMPANY_NOT_PAID_FOR_USER = 'CompanyNotPaidForUser';
export const CHECKOUT = 'Checkout';
export const ADMIN_COMPANIES = 'companies';
export const MEETINGS = 'Meetings';
