
import VueApp from '@/@types/app/VueApp';
import { Component, Prop } from 'vue-property-decorator';
import mobileResponsiveService from '@/services/MobileResponsiveService';

@Component({})
export default class OcrProgressDialog extends VueApp {
  @Prop({ type: Number, default: 0 }) progress: number;

  title = `We are in the process of${this.isMobile ? '\r\n' : ''} detecting the text.\r\nIt will take some time.`;
  visible = false;
  resolve: (value: number) => void;
  reject: (reason?: any) => void;

  get isMobile() {
    return mobileResponsiveService.isMobileView;
  }

  show(): Promise<number> {
    this.visible = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  close() {
    this.visible = false;
  }

  onClickCancel() {
    this.$emit('cancel');
  }
}
