import { IAutoplay } from '@/models/IAutoplay';
import { State } from '@/store/state';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { Video } from '@/models/Video';
import { Article } from '@/models/article/Article';
import { SearchByTextProperty } from '@/models/SearchByText';
import ArticlePlayer from '@/components/article-player/ArticlePlayer.vue';

export const sharedMutations = {
  setRecordedVideo(state: State, value: Video) {
    state.recordedVideo = value;
  },
  setAutoPlay(state: State, value: IAutoplay | null) {
    state.autoPlay = value;
  },
  setVideoPlayerTimeToSet(state: State, value: number | null) {
    state.videoPlayerTimeToSet = value;
  },
  setCreateArticle(state: State, value: Article) {
    state.createArticle = value;
  },
  setArticleMode(state: State, mode?: ArticleEditMode) {
    state.articleMode = mode;
  },
  setArticleIdToExpand(state: State, articleId: string) {
    state.articleIdToExpand = articleId;
  },
  setArticleTextPropsToFind(state: State, value: SearchByTextProperty[]) {
    state.articleTextPropsToFind = value;
  },
  setIsMobile(state: State, value: boolean) {
    state.isMobile = value;
  },
  setInnerHeight(state: State, value: number) {
    state.innerHeight = value;
  },
  setVideoPlayerRef(state: State, value: ArticlePlayer) {
    state.videoPlayerRef = value;
  },
  setSharedArticleCompanyLogo(state: State, value: string | null) {
    state.sharedCompanyLogo = value;
  },
};
