function getTextNodeOffset(parentElement: Element, index: number, length = 1) {
  if (typeof parentElement === 'undefined') {
    throw new TypeError('Parent element is required.');
  }
  if (typeof index === 'undefined') {
    throw new TypeError('Index is required.');
  }

  const text = parentElement.textContent;
  const offsetPosition = { left: 0, top: 0 };

  parentElement.textContent = '';

  const textNodeBefore = document.createTextNode(text.slice(0, index));
  const textNode = document.createTextNode(text.slice(index, index + length));

  parentElement.appendChild(textNodeBefore);
  parentElement.appendChild(textNode);

  const range = document.createRange();
  range.selectNodeContents(textNode);
  const rect = range.getBoundingClientRect();
  offsetPosition.left = rect.left;
  offsetPosition.top = rect.top;

  return offsetPosition;
}

export default getTextNodeOffset;
