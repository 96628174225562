import { State } from '@/store/state';
import { ArticleComment } from '@/models/article/ArticleComment';
import { ArticleCommentQuote } from '@/@types/ArticleCommentQuote';
import { RecordingReaction } from '@/@types/RecordingReaction';
import { ArticleCommentVideoReaction } from '@/@types/ArticleCommentVideoReaction';

export const commentsSidebarMutations = {
  commentsSidebarSetIsVisible(state: State, isVisible: boolean) {
    state.commentsSidebar.isVisible = isVisible;
  },

  commentsSidebarSetScrollToCommentId(state: State, commentId?: string) {
    state.commentsSidebar.scrollToCommentId = commentId;
  },

  commentsSidebarSetQuote(state: State, value?: ArticleCommentQuote) {
    state.commentsSidebar.quote = value;
  },

  commentsSidebarSetComments(state: State, comments: ArticleComment[]) {
    state.commentsSidebar.comments = comments;
  },

  commentsSidebarAddComment(state: State, comment: ArticleComment) {
    state.commentsSidebar.comments = [comment, ...state.commentsSidebar.comments];
  },

  commentsSidebarSetRecordingReactions(state: State, recordingReactions: RecordingReaction[]) {
    state.commentsSidebar.recordingReactions = recordingReactions;
  },

  commentsSidebarAddRecordingReaction(state: State, reaction: RecordingReaction) {
    state.commentsSidebar.recordingReactions = [reaction, ...state.commentsSidebar.recordingReactions];
  },

  commentsSidebarRemoveRecordingReaction(state: State, reactionId: string) {
    state.commentsSidebar.recordingReactions = state.commentsSidebar.recordingReactions.filter(
      (reaction) => reaction.id !== reactionId
    );
  },

  commentsSidebarSetVideoReaction(state: State, videoReaction: ArticleCommentVideoReaction) {
    state.commentsSidebar.videoReaction = videoReaction;
  },

  commentsSidebarSetIsActiveTimeTag(state: State, isActiveTimeTag: boolean) {
    state.commentsSidebar.isActiveTimeTag = isActiveTimeTag;
  },
};
