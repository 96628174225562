
import VueApp from '@/@types/app/VueApp';
import { Component, Prop, Watch } from 'vue-property-decorator';
import SlVueTree, { ISlTreeNode } from 'sl-vue-tree';
import { Article } from '@/models/article/Article';
import notificationService from '@/services/NotificationService';
import articleEditService from '@/services/article/ArticleEditService';
import { Paragraph } from '@/models/article/Paragraph';
import helper from '@/helper';
import tooltip from '@/services/ui-providers/Tooltip';
import EventBus from '@/EventBus';
import { ARTICLE_PLAYER_CHANGE_STATE } from '@/events';
import { ArticlePlayerStates } from '@/models/ArticlePlayerStates';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { ARTICLE_EDIT, DRAFT_EDIT } from '@/routerNames';

const TIMEOUT = 10;

@Component({
  components: {
    SlVueTree,
  },
})
export default class ArticleParagraphTree extends VueApp {
  @Prop() article: Article;
  @Prop() articleEditorState: string;
  @Prop() selectedParagraph: Paragraph;

  $refs: {
    tree: SlVueTree<Paragraph>;
  };

  tree: any = [];
  originalTree: any = null;
  inputFocusTimeoutId: number | null = null;
  paragraphEditTitle = '';

  get isVideoProcessing() {
    return this.$store.state.articleEditor.isVideoProcessing;
  }

  removeTooltips() {
    tooltip.clearTooltips();
  }

  closeEditNodes(nodes: any) {
    nodes.forEach((node: any) => {
      node.data.isEdit = false;
      if (node.children.length > 0) {
        this.closeEditNodes(node.children);
      }
    });
  }

  closeEditParagraphInput() {
    helper.observerNotify(this.article.paragraphs);
    articleEditService.updatePlayer();
  }

  triggerParagraphEvent(eventName: 'playParagraph' | 'mergeParagraph' | 'removeParagraph', item: any) {
    const { paragraph } = this.article.findParagraph(item);

    if (paragraph) {
      commitSetArticleMode(ArticleEditMode.VIDEO);
      dispatchUpdateSelectedTextTab(null);
      switch (this.$route.name) {
        case DRAFT_EDIT: {
          this.$router.push({ name: DRAFT_EDIT, params: { mode: ArticleEditMode.VIDEO } });
          break;
        }
        case ARTICLE_EDIT: {
          this.$router.push({ name: ARTICLE_EDIT, params: { mode: ArticleEditMode.VIDEO } });
          break;
        }
      }
      window.setTimeout(() => {
        switch (eventName) {
          case 'playParagraph':
            articleEditService.handlePlayParagraph(paragraph);
            break;
          case 'mergeParagraph':
            articleEditService.handleMergeParagraph(paragraph);
            break;
          case 'removeParagraph':
            articleEditService.handleRemoveParagraph(paragraph);
            break;
        }
      }, 0);
    }
  }

  handleTitleFocus(e: any, title: string) {
    tooltip.handleFocusIn(e, title);
  }

  handleChangeParagraphTitle(item: any) {
    if (this.paragraphEditTitle.trim().length) {
      const paragraph = this.findArticleParagraph(this.article.paragraphs, item.key);
      if (paragraph.title === this.paragraphEditTitle) {
        this.closeEditParagraphInput();
      }
      paragraph.title = this.paragraphEditTitle;
      articleEditService.pushUndoArticleStack();
      helper.observerNotify(this.article.paragraphs);
      articleEditService.updatePlayer();
    } else {
      notificationService.warning('Title is required');
    }
  }

  handleChangeIsExpanded(node: ISlTreeNode<Paragraph>) {
    const paragraph = this.findArticleParagraph(this.article.paragraphs, node.data.key);
    paragraph.isExpanded = !node.isExpanded;
  }

  handleNodeClick(node: ISlTreeNode<Paragraph>, event: PointerEvent) {
    const target = event.target as HTMLElement;

    if (target.closest('.sl-vue-tree-sidebar') || target.closest('.md-menu')) {
      this.updateSelectedNode();
      return;
    }

    const tree = this.$refs.tree;
    tree.traverse((_node, model) => {
      // fix un-select on click same node
      model.isSelected = (tree as any).comparePaths(_node.path, node.path) === 0;
    });

    this.triggerParagraphEvent('playParagraph', node.data);
  }

  handleEditParagraph(item: Paragraph) {
    const nodeInTree = this.findTreeNode(this.tree, item.key);
    const isParagraphEditEnabled = nodeInTree.data.isEdit;
    this.paragraphEditTitle = item.title;
    this.closeEditNodes(this.tree);
    nodeInTree.data.isEdit = !isParagraphEditEnabled;

    if (!isParagraphEditEnabled) {
      window.clearTimeout(this.inputFocusTimeoutId);
      this.inputFocusTimeoutId = window.setTimeout(() => {
        this.focusInput();
      }, 0);
    }

    window.setTimeout(() => {
      EventBus.$emit(ARTICLE_PLAYER_CHANGE_STATE, ArticlePlayerStates.paused);
    }, TIMEOUT);
  }

  handleChangeTree(node: any, pos: any) {
    const nodeLevels = 1;
    const tree = this.$refs.tree;

    if (pos.node.children.length && pos.placement === 'inside') {
      const nodeKey = pos.node.children[pos.node.children.length - 1].data.key;
      // remove dropped node from first child position and set it to the end.
      const selectedNode = tree.getSelected();
      tree.remove([selectedNode[0].path]);
      pos.node = this.findTreeNode(tree.nodes, nodeKey);

      //reload previous state if !pos
      if (!pos.node) {
        notificationService.warning('You can not move paragraph in this way');
        this.tree = articleEditService.generateTree(this.originalTree);
        return;
      }

      pos.placement = 'after';
      tree.insert(pos, node[0]);
    }

    if (nodeLevels > 3) {
      // max level detected
      notificationService.warning('Only two levels allowed');

      // reload previous state
      this.tree = articleEditService.generateTree(this.originalTree);
    } else {
      const paragraphs = JSON.parse(JSON.stringify(this.treeToArray(this.tree)));

      if (paragraphs.length) {
        for (const key in paragraphs) {
          paragraphs[key] = Paragraph.parse(paragraphs[key]);
        }
      }
      articleEditService.handleUpdateParagraphs(paragraphs);
    }
  }

  findArticleParagraph(paragraphs: Paragraph[], key: string) {
    let paragraph = paragraphs.find((item) => item.key === key);
    if (paragraph) return paragraph;

    paragraphs.forEach((item) => {
      if (item.items.length > 0) {
        const nestedParagraph = this.findArticleParagraph(item.items, key);
        if (nestedParagraph) {
          paragraph = nestedParagraph;
        }
      }
    });

    return paragraph;
  }

  findTreeNode(nodes: any, key: string) {
    let node = nodes.find((item: any) => item.data.key === key);
    if (node) {
      return node;
    } else {
      for (const item of nodes) {
        if (item.children.length > 0) {
          node = this.findTreeNode(item.children, key);
          if (node) {
            return node;
          }
        }
      }
    }
    return node;
  }

  focusInput() {
    const input = document.getElementsByClassName('paragraph-edit-input');
    (input[0] as HTMLElement).focus();
  }

  treeToArray(items: any) {
    const data = [];
    if (items && items.length) {
      for (const key in items) {
        const item = items[key].data;
        item.items = items[key].children ? this.treeToArray(items[key].children) : [];
        data.push(item);
      }
    }
    return data;
  }

  updateSelectedNode() {
    const selectedParagraph = this.selectedParagraph;
    const tree = this.$refs.tree;
    if (tree) {
      tree.traverse((node, model) => {
        model.isSelected = selectedParagraph?.key === model.data.key;
      });

      this.tree.__ob__.dep.notify();
    }
  }

  created() {
    this.tree = articleEditService.generateTree();
  }

  @Watch('article.paragraphs', { deep: true })
  handleChangeParagraphs(value: Paragraph[]) {
    this.tree = articleEditService.generateTree(value);
  }

  @Watch('selectedParagraph', { deep: true })
  handleChangeSelectedParagraph() {
    this.updateSelectedNode();
  }
}
