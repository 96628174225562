
import VueApp from '@/@types/app/VueApp';
import moment from 'moment';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import draftService from '@/services/draft/DraftService';
import { Article } from '@/models/article/Article';
import { Component, Watch } from 'vue-property-decorator';
import { ARTICLE_EDIT, DRAFT_EDIT } from '@/routerNames';
import { Workspace } from '@/models/Workspace';
import { UserInfo } from '@/models/UserInfo';
import EmptyStateCard from '@/components/layout/cards/EmptyStateCard.vue';
import { Mutations } from '@/store/mutations';
import { commitSetArticleEditorArticle } from '@/store/commits/articleEditorCommits';
import MyTable from '@/components/layout/MyTable/MyTable.vue';
import MyTableCell from '@/components/layout/MyTable/MyTableCell.vue';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import urlService from '@/services/UrlService';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';

@Component({
  components: {
    MyTableCell,
    MyTable,
    EmptyStateCard,
  },
})
export default class DraftsPage extends VueApp {
  tableColumns = [
    { label: 'TITLE' },
    { label: 'WORKSPACE' },
    { label: 'CREATED BY' },
    { label: 'CREATION DATE', sortBy: ['createdAt'] },
    { label: 'TYPE' },
    { label: '' },
  ];
  articles: Article[] = [];
  currentSort = 'createdAt';
  currentSortOrder = 'asc';
  searchQuery = '';
  searchWorkspace = '';
  searchUser = '';
  searchType = '';
  isInitialFilter = false;
  showDialog = false;
  mobileFilter = {
    searchQuery: '',
    workspace: '',
    createdBy: '',
    type: '',
  };

  filteredArticles: Article[] = [];

  customSortByDate(value: any) {
    return value.sort((a: any, b: any) => {
      const sortBy = this.currentSort;

      if (this.currentSortOrder === 'desc') {
        return a[sortBy] - b[sortBy];
      }

      return b[sortBy] - a[sortBy];
    });
  }

  get user() {
    return this.$store.state.user;
  }

  get displayWorkspacesOptions(): string[] {
    return this.uniqueWorkspacesHandler(this.articles);
  }

  get displayUserOptions(): string[] {
    return this.uniqueUsersHandler(this.articles);
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  getWorkspaceTitle(workspace: Workspace | string) {
    let workspaceTitle = workspace instanceof Workspace && this.handleWorkspaceTitle(workspace);
    workspaceTitle = workspaceTitle ? workspaceTitle : '-';

    return workspaceTitle;
  }

  openFilterDialog() {
    this.showDialog = true;
  }

  closeFilterDialog() {
    this.showDialog = false;
  }

  cancelFilter() {
    this.mobileFilter = {
      searchQuery: '',
      workspace: '',
      createdBy: '',
      type: '',
    };

    this.mobileSortHandler();
  }

  mobileSortHandler() {
    this.searchQuery = this.mobileFilter.searchQuery;
    this.searchWorkspace = this.mobileFilter.workspace;
    this.searchUser = this.mobileFilter.createdBy;
    this.searchType = this.mobileFilter.type;

    this.closeFilterDialog();
  }

  authorNameHandler(user: UserInfo, author: string) {
    if (user && user.firstName) {
      return `${user.firstName} ${user.lastName}`;
    } else if (author) {
      return author;
    } else {
      return '-'; // for legacy articles that don't have user
    }
  }

  convertUnixToDate(date: number | undefined) {
    if (!date) {
      return '-';
    }
    return moment(date).format('DD/MM/YYYY');
  }

  hasArticles(articles: Article[]): boolean {
    return !!(articles && articles.length);
  }

  handleWorkspaceTitle(workspace: Workspace) {
    return workspaceService.getWorkspaceTitle(workspace);
  }

  filterArticles() {
    const articles = this.articles;
    this.filteredArticles =
      articles.filter((article: Article) => {
        const workspace =
          article.workspace instanceof Workspace ? this.handleWorkspaceTitle(article.workspace) : article.workspace;
        const source = this.textHandler(article.title.concat(workspace));

        let searchResult = source.includes(this.textHandler(this.searchQuery));
        searchResult = this.searchWorkspaceLength(workspace, searchResult);
        searchResult = this.searchUserLength(searchResult, article);
        searchResult = this.searchTypeLength(searchResult, article);
        return searchResult;
      }) ?? [];

    if (!this.filteredArticles.length && this.isInitialFilter) {
      this.isInitialFilter = false;
      this.searchUser = '';
      this.mobileFilter.createdBy = '';
    }
  }

  filterArticlesByCurrentUser(articles: Article[]) {
    return articles.filter((article) => article.userRef && article.userRef.id === this.user.id) ?? [];
  }

  searchWorkspaceLength(workspace: any, searchResult: boolean) {
    if (this.searchWorkspace.length && searchResult) {
      if (workspace) {
        searchResult =
          this.textHandler(this.searchWorkspace) === this.textHandler(workspace) ||
          workspace.visibility === this.textHandler(this.searchWorkspace);
      } else {
        searchResult = this.searchWorkspace === '-';
      }
    }
    return searchResult;
  }

  searchUserLength(searchResult: boolean, article: Article) {
    if (this.searchUser.length && searchResult) {
      if (!!article.userRef && article.userRef.firstName) {
        searchResult =
          this.textHandler(this.searchUser) ===
          this.textHandler(`${article.userRef.firstName} ${article.userRef.lastName}`);
      } else {
        searchResult = this.searchUser === '-';
      }
    }
    return searchResult;
  }

  searchTypeLength(searchResult: boolean, article: Article) {
    if (!!this.searchType.length && searchResult) {
      if (this.searchType === 'paragraphs&text') {
        searchResult = !!article.paragraphs.length && !!article.isHasText;
      } else if (this.searchType === 'text') {
        searchResult = !!article.isHasText;
      } else if (this.searchType === 'paragraphs') {
        searchResult = !!article.paragraphs.length;
      }
    }
    return searchResult;
  }

  textHandler(text: string) {
    return text.toString().toLowerCase().replace(/\s/g, '');
  }

  uniqueWorkspacesHandler(articles: Article[]) {
    const workspaceTitles = articles.map((article: Article) => {
      return this.getWorkspaceTitle(article.workspace);
    });
    return [...new Set(workspaceTitles)];
  }

  uniqueUsersHandler(articles: Article[]) {
    const workspaceTitles = articles.map(({ userRef, author }: Article) => {
      return this.authorNameHandler(userRef, author);
    });

    return [...new Set(workspaceTitles)];
  }

  handleEdit({ e, item }: { e: PointerEvent | MouseEvent; item: Article }) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isMobile) return;
    const isModifierPressed = e.metaKey || e.ctrlKey || e.button === 1;

    !isModifierPressed && commitSetArticleEditorArticle(item);

    const mode = item.isTextArticle() ? ArticleEditMode.NOTES : ArticleEditMode.VIDEO;

    if (isModifierPressed) return window.open(urlService.getDraftViewUrl(item, mode), '_blank');

    if (mode === ArticleEditMode.NOTES) {
      dispatchUpdateSelectedTextTab(item.textTabs[0]);
    } else {
      dispatchUpdateSelectedTextTab(null);
    }

    if (item.original)
      return this.$router.push({
        name: ARTICLE_EDIT,
        params: { id: item.original, mode },
      });

    this.$router.push({ name: DRAFT_EDIT, params: { id: item.id } });
  }

  async handleRemove(draft: Article) {
    try {
      await draftService.showDeleteConfirmationPopup(draft.title);
    } catch (e) {
      return;
    }

    const loader = this.$loading({
      lock: true,
      text: 'Deleting draft',
    });

    await this.$store.dispatch('deleteDraftArticle', draft.id);
    this.articles = this.articles.filter((item) => draft.id !== item.id);
    this.filterArticles();
    loader.close();
  }

  async loadDraftArticles(isInitial = false) {
    this.isInitialFilter = isInitial;

    const loader = this.$loading({
      lock: true,
      text: 'Loading drafts',
    });

    try {
      const res = await this.$api.draft.getAll();
      const drafts = res.data.articles ? res.data.articles.map((item) => Article.fromJson(item)) : [];
      const workspaces = this.$store.state.workspaces;

      drafts.forEach((article: Article) => {
        article.createdAt = article.createdAt ? article.createdAt : 0;
        const workspace = workspaces.find(({ id }: Workspace) => article.workspace === id);
        if (workspace) {
          article.workspace = workspace;
        }
      });
      drafts.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

      const filteredDrafts = this.filterArticlesByCurrentUser(drafts);

      if (filteredDrafts.length && isInitial) {
        this.searchUser = this.user.getName();
        this.mobileFilter.createdBy = this.user.getName();
      }

      this.articles = drafts;
      this.filterArticles();
    } catch (error: any) {
      if (error.response && error.response.data.company) {
        this.$store.commit(Mutations.SET_COMPANY_STATUS, error.response.data.company.isActivated);
      }
      this.$api.handleResponseError(error);
    } finally {
      loader.close();
    }
  }

  mounted() {
    this.loadDraftArticles(true);
  }

  @Watch('$store.state.workspaces')
  onWorkspacesChanged() {
    this.loadDraftArticles();
  }

  @Watch('searchQuery')
  @Watch('searchUser')
  @Watch('searchType')
  @Watch('searchWorkspace')
  onSearchChanged() {
    this.filterArticles();
    this.filteredArticles = this.customSortByDate(this.filteredArticles);
  }
}
