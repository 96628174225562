
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Dialog, Form, FormItem, Input, Checkbox } from 'element-ui';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';

@Component({
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    MenuButton,
  },
})
export default class AddLinkCommandButton extends Vue {
  @Prop({ required: true }) readonly title!: string;

  @Prop({ type: Boolean, default: false }) isUpdateLink: boolean;

  @Prop({ type: String, required: false, default: '' })
  readonly href: string;

  @Prop({ type: String, required: false, default: '' })
  readonly target: string;

  @Prop({ type: Boolean, default: false }) isVisible: boolean;

  linkAttrs: { href: string; openInNewTab: boolean } = { href: '', openInNewTab: true };

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.$emit('click', this.linkAttrs);
    }
  }

  handleClick() {
    this.$emit('click', this.linkAttrs);
  }

  handleClose() {
    this.$emit('close', !this.isVisible);
  }

  @Watch('isVisible', { immediate: true })
  onDialogVisibleChange(value: boolean) {
    if (value) {
      this.linkAttrs.href = this.href;
      if (this.isUpdateLink) {
        this.linkAttrs.openInNewTab = this.target === '_blank';
      } else {
        this.linkAttrs.openInNewTab = true;
      }
      window.addEventListener('keypress', this.handleKeyboardEvent);
    } else {
      window.removeEventListener('keypress', this.handleKeyboardEvent);
    }
  }
}
