
import VueApp from '@/@types/app/VueApp';
import { ArticleEditMode } from '@/@types/ArticleEditMode';

// Components
import DiscardDraftMenuButton from '@/components/article-editor/ArticleEditor/DiscardDraftMenuButton.vue';
import ArticleShareSettings from '@/components/article-editor/ArticleShareSettings/ArticleShareSettings.vue';
import AiFeaturesDropdownButton from '@/components/article-editor/EditorNavbar/AiFeaturesDropdownButton/AiFeaturesDropdownButton.vue';
import ArticleVideoModeButton from '@/components/article-editor/EditorNavbar/ArticleVideoModeButton/ArticleVideoModeButton.vue';
import ArticleShareButton from '@/components/article-editor/EditorNavbar/ArticleShareButton.vue';
import ArticleTagsButton from '@/components/article-editor/EditorNavbar/ArticleTagsButton.vue';
import ArticleTextTabButton from '@/components/article-editor/EditorNavbar/ArticleTextTabButton/ArticleTextTabButton.vue';
import ArticleTextTabButtonCompact from '@/components/article-editor/EditorNavbar/ArticleTextTabButton/ArticleTextTabButtonCompact.vue';
import ArticleTranscriptionViewButton from '@/components/article-editor/EditorNavbar/ArticleTranscriptionViewButton/ArticleTranscriptionViewButton.vue';
import InlineIcon from '@/components/layout/shared/InlineIcon.vue';
import EventBus from '@/EventBus';
import { SHOW_ARTICLE_TAGS_DIALOG } from '@/events';
import { Article } from '@/models/article/Article';
import { Paragraph } from '@/models/article/Paragraph';
import { TextTab } from '@/models/article/TextTab';
import { User } from '@/models/User';

// Models
import { Workspace } from '@/models/Workspace';
import { generateHash } from '@/other/hash';
import logger from '@/other/Logger';
import * as routerNames from '@/routerNames';
import { ARTICLE_CREATE } from '@/routerNames';
import articleCommentService from '@/services/article/ArticleCommentService';
import articleEditService from '@/services/article/ArticleEditService';
import articleService from '@/services/article/ArticleService';
import draftService from '@/services/draft/DraftService';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import urlService from '@/services/UrlService';

// Services
import { workspaceService } from '@/services/workspace/WorkspaceService';
import store from '@/store';
import { commitSetArticleEditorTextTabs } from '@/store/commits/articleEditorCommits';
import { commitSetArticleVersionViewArticleId } from '@/store/commits/articleVersionCommits';
import {
  commitSetCommentsSidebarIsActiveTimeTag,
  commitSetCommentsSidebarIsVisible,
} from '@/store/commits/commentsSidebarCommits';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';

// Commits and dispatchers
import { commitToggleArticleVersionsSidebar } from '@/store/commits/uiCommits';
import { commitSetWorkspaces } from '@/store/commits/workspaceCommit';
import { dispatchDeleteArticle } from '@/store/dispatchers/articleDispatchers';
import { dispatchOpenArticleEditor, dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';

// Other imports
import { ElLoadingComponent } from 'element-ui/types/loading';
import moment from 'moment';
import slugify from 'slugify';
import { Component, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';

@Component({
  methods: {
    ARTICLE_CREATE() {
      return ARTICLE_CREATE;
    },
  },
  components: {
    DiscardDraftMenuButton,
    ArticleTranscriptionViewButton,
    AiFeaturesDropdownButton,
    ArticleTextTabButtonCompact,
    ArticleTextTabButton,
    InlineIcon,
    ArticleVideoModeButton,
    ArticleTagsButton,
    ArticleShareSettings,
    ArticleShareButton,
    draggable,
  },
})
export default class EditorNavbar extends VueApp {
  loader: ElLoadingComponent = null;

  @Prop({ type: Boolean, default: true }) showVideoModeButton: boolean;
  @Prop({ type: Boolean, default: false }) isArticleEditor: boolean;
  @Prop({ type: Boolean, default: true }) showShareButton: boolean;
  @Prop({ type: Boolean, default: true }) showEditButton: boolean;
  @Prop({ type: Boolean, default: false }) isGuest: boolean;
  @Prop({ type: Object, required: true }) article: Article;
  @Prop({ type: Boolean, required: true }) showTransferButton: boolean;
  @Prop({ type: Object }) workspace: Workspace;
  @Prop() paragraph: Paragraph;

  ArticleEditMode = ArticleEditMode;
  isTextTabsReordering = false;
  TEXT_TAB_LIMIT = 3;

  $refs: {
    textTabButtons: any;
  };

  get shouldApplyMargin(): boolean {
    return this.isEditPage && !this.isVideoMode;
  }

  get currentRouteName() {
    return this.$route.name;
  }

  get lastTimeAutosaved() {
    const article = this.article;
    if (!article) return '';

    const updatedAt = article.updatedAt;
    const createdAt = article.createdAt;
    if (!updatedAt && !createdAt) return ''; // Return empty string if both updatedAt and createdAt are not available

    const date = new Date(updatedAt || createdAt); // Use updatedAt if available, otherwise use createdAt
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }

  // 1065 - not expanded
  // 1200 - expanded

  get textTabs() {
    return this.article.textTabs;
  }

  set textTabs(value) {
    commitSetArticleEditorTextTabs(value);
  }

  get selectedTextTab() {
    return this.$store.state.articleEditor.selectedTextTab.tab;
  }

  get isAllowedToCreateTextTabs() {
    return this.article && this.article.textTabs && this.article.textTabs.length < this.TEXT_TAB_LIMIT;
  }

  get isVideoMode() {
    return this.$store.state.articleMode === ArticleEditMode.VIDEO;
  }

  get isExportAllowed() {
    return this.article.id && !this.isEditPage && this.article.hasText();
  }

  get isUser(): boolean {
    return !!this.$store.state.user;
  }

  get commentsCount(): number {
    return workspaceService.workspaceView.commentsCount;
  }

  get articleMode() {
    return this.$store.state.articleMode;
  }

  get workspaces() {
    return this.$store.state.workspaces;
  }

  get workspaceView() {
    return workspaceService.workspaceView;
  }

  get isArticleEditPage() {
    return [
      routerNames.ARTICLE_CREATE,
      routerNames.ARTICLE_EDIT,
      routerNames.ARTICLE_VERSION_EDIT,
      routerNames.DRAFT_EDIT,
    ].includes(this.$route.name);
  }

  get articleVersionView() {
    return this.$store.state.articleVersionView;
  }

  get isShowEditArticle() {
    if (this.articleVersionView.isShowFooter) {
      return false;
    }
    return this.isAllowEditAndDeleteArticle && !this.isEditPage && !this.isMobile;
  }

  get isAllowEditAndDeleteArticle() {
    if (this.isSharedMode) {
      return false;
    }

    if (this.workspace) {
      return this.showEditButton && this.workspace.canEditArticles();
    }
    return true;
  }

  get isAllowLoadVersions() {
    return this.$store.state.user && this.$store.state.user.isSuperAdmin();
  }

  get isOpenedArticleVersion() {
    const isArticleVersionEditRoute = this.$route.name === routerNames.ARTICLE_VERSION_EDIT;

    return isArticleVersionEditRoute && this.isAllowLoadVersions;
  }

  get isEditPage() {
    return this.isArticleEditPage;
  }

  get isReorderMode() {
    return this.$store.state.isReorderMode;
  }

  get updatedInfo(): string {
    const { user, updatedByUser, updatedAt, author } = this.article;
    const resultUser = User.parse(updatedByUser || user);
    if (resultUser.firstName) {
      return `${resultUser.getName()} on ${this.convertUnixToDate(updatedAt)}`;
    }
    return `${author ?? 'User'} on ${this.convertUnixToDate(updatedAt)}`;
  }

  get createdInfo(): string {
    const { user, createdAt, author } = this.article;
    const resultUser = User.parse(user);
    if (resultUser.firstName) {
      return `${resultUser.getName()} on ${this.convertUnixToDate(createdAt)}`;
    }
    return `${author ?? 'User'} on ${this.convertUnixToDate(createdAt)}`;
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get isSharedMode() {
    return this.workspaceView.isSharedMode;
  }

  get isShared(): boolean {
    return this.article && !!this.article.shared;
  }

  get isArticleChanged() {
    if (!this.isEditPage) return false;

    const { title, textTabs, paragraphs, tags, workspace } = this.article;
    const filteredDraft = { title, textTabs, paragraphs, tags, workspace };

    const currentHash = generateHash(JSON.stringify(filteredDraft));

    if (!store.state.articleEditor.articleHash) return false;

    return currentHash !== store.state.articleEditor.articleHash;
  }

  changeArticleMode(mode: ArticleEditMode) {
    return articleService.toggleArticleMode(mode, this.$route);
  }

  isTextTabPinned(tab: TextTab) {
    if (!tab.id || !this.article.pinnedNote) return false;
    return this.article.pinnedNote === tab.id;
  }

  isTextTabActive(currentTextTab: TextTab) {
    return this.selectedTextTab && this.selectedTextTab.id === currentTextTab.id;
  }

  isAnyTextTabRenaming(): boolean {
    if (this.$refs.textTabButtons) {
      const renamingStatuses = this.$refs.textTabButtons.map((el: any) => el.isRenaming);
      return renamingStatuses.includes(true);
    }
    return false;
  }

  async openTextTab(tab: TextTab) {
    await this.changeArticleMode(ArticleEditMode.NOTES);
    store.commit('setArticleEditorSelectedTextTab', tab);

    if (!this.isEditPage) {
      const route = urlService.getTextTabViewRoute(
        slugify(tab.title),
        this.article,
        store.state.workspaceView.workspace
      );

      await this.$router.push(route);
    }
  }

  openArticleCommentsSidebar() {
    if (this.articleMode === ArticleEditMode.VIDEO) {
      articleCommentService.handleAddCommentOnVideo();
      commitSetCommentsSidebarIsActiveTimeTag(true);
    }
    commitSetCommentsSidebarIsVisible(true);
  }

  convertUnixToDate(date: number | undefined) {
    if (date === undefined) {
      return '-';
    }
    return moment(date).format('DD MMMM YYYY');
  }

  handleShowTags() {
    EventBus.$emit(SHOW_ARTICLE_TAGS_DIALOG, this.isEditPage);
  }

  handleEditArticle() {
    dispatchOpenArticleEditor(this.article, this.articleMode);
  }

  async downloadArticleRecording() {
    try {
      await articleService.downloadArticleVideo(this.article);
    } catch (err) {
      logger.error(err);
    }
  }

  handleTransferArticle() {
    const article = store.state.articleEditor.article;
    EventBus.$emit('transfer-article', article);
  }

  handleRemoveArticle() {
    if (!this.isArticleEditor) {
      if (this.article.draft) {
        draftService.deleteDraft(this.article, { cb: this.redirectToDraftsPage });
      } else {
        dispatchDeleteArticle(this.$store.state.articleEditor.article);
      }
    } else {
      this.$emit('handleRemoveArticle');
    }
  }

  async handleCreateTextTab() {
    try {
      this.loader = this.$loading({
        lock: true,
        text: 'Creating text tab',
      });

      const textTab = new TextTab();

      const currentTabs = this.$store.state.articleEditor.article.textTabs;
      let newTextTabs = Array.isArray(currentTabs) ? [...currentTabs, textTab] : [textTab];

      commitSetArticleEditorTextTabs(newTextTabs);
      await articleEditService.saveAsDraft();
      await this.changeArticleMode(ArticleEditMode.NOTES);

      await dispatchUpdateSelectedTextTab(this.textTabs[this.textTabs.length - 1]);
      commitSetArticleMode(ArticleEditMode.NOTES);
    } finally {
      this.loader.close();
      this.loader = null;
    }
  }

  async handleDuplicateArticle() {
    this.loader = this.$loading({ text: 'Duplicating article' });

    const article = this.article;

    try {
      await articleService.duplicateArticle(article.id);
    } catch (err) {
      this.$api.handleResponseError(err);
    } finally {
      this.loader.close();
    }
  }

  async handleExportPdfArticle() {
    this.loader = this.$loading({ text: 'Generating PDF...' });
    try {
      const selectedTextTab = this.$store.state.articleEditor.selectedTextTab.tab;
      const textTabId = selectedTextTab ? selectedTextTab.id : undefined;
      await articleService.exportArticleToPdf(this.article.id, textTabId);
    } catch (error) {
      this.$api.handleResponseError(error);
    } finally {
      this.loader.close();
    }
  }

  handleShowVersions() {
    commitSetArticleVersionViewArticleId(this.workspaceView.selectedArticle.id);
    commitToggleArticleVersionsSidebar();
  }

  async toggleArticlePinNotes(id: string) {
    this.article.pinnedNote = this.article.pinnedNote === id ? null : id;

    await articleEditService.saveAsDraft();
  }

  redirectToDraftsPage() {
    this.$router.push({ name: routerNames.DRAFTS });
  }

  mounted() {
    if (!this.isGuest) {
      this.$api
        .getWorkspaces()
        .then((res) => {
          const workspaces = res.data.items.map((item: Workspace) => Workspace.parse(item));

          commitSetWorkspaces(workspaces);
        })
        .catch((data) => this.$api.handleResponseError(data));
    }
  }
}
