
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Paginate from 'vuejs-paginate';
import MyTablePagination from '@/components/layout/MyTable/MyTablePagination/MyTablePagination.vue';

type ItemType = { id: string | number } & any;
@Component({ components: { MyTablePagination, Paginate } })
export default class MyTable extends Vue {
  @Prop() data: ItemType[];
  // Use string[] to be able to access nested object properties
  @Prop() columns: { label?: string; sortBy?: string[] }[];
  @Prop({ type: Boolean, default: false }) pagination: boolean;
  @Prop({ type: Number, default: 1 }) paginationVersion: number;
  @Prop({ type: Number, default: 0 }) currentPagPage: number;
  @Prop({ type: Number, default: 0 }) totalPagPages: number;
  @Prop({ type: String, default: 'client' }) sortingMode!: 'client' | 'server';

  sortedData: ItemType[] = [];
  paginatedData: ItemType[] = [];
  sortBy?: string[] = undefined;
  sortDirection = 1;
  page = 1;
  pageSize = 20;

  get totalPages() {
    const total = this.sortedData.length / this.pageSize;
    const totalCeil = Math.floor(total);
    const isCeil = total === totalCeil;

    return totalCeil + (!isCeil ? 1 : 0);
  }

  emitUpdatePage(p: number) {
    this.$emit('updatePage', p);
  }

  onClickSortField(sortBy?: string[]) {
    if (!sortBy) return;

    if (sortBy !== this.sortBy) {
      this.sortDirection = 1;
    } else {
      this.sortDirection = this.sortDirection === 1 ? -1 : 1;
    }

    this.sortBy = sortBy;

    if (this.sortingMode === 'client') {
      this.updateSortedData();
    } else {
      this.$emit('sort-changed', { sortBy, sortDirection: this.sortDirection });
    }
  }

  onClickOnRow(e: PointerEvent | MouseEvent, item: ItemType) {
    e.preventDefault();
    this.$emit('on-click-row', { e, item });
  }

  isSortActive(field: string[]) {
    return field === this.sortBy;
  }

  dynamicSort(property: string[]) {
    const sortDirection = this.sortDirection;
    return function (a: ItemType, b: ItemType) {
      let aValue = a;
      let bValue = b;
      for (let prop of property) {
        aValue = aValue[prop];
        bValue = bValue[prop];
      }
      const result = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      return result * sortDirection;
    };
  }

  updateSortedData() {
    if (this.sortBy && this.sortingMode === 'client') {
      this.sortedData = this.data.map((item) => item).sort(this.dynamicSort(this.sortBy));
    } else {
      this.sortedData = this.data;
    }

    this.updatePaginatedData();
  }

  updatePaginatedData() {
    if (!this.pagination) {
      this.paginatedData = this.sortedData;
      return;
    }

    if (this.page >= this.totalPages) {
      this.page = this.totalPages > 1 ? this.totalPages : 1;
    }

    const start = (this.page - 1) * this.pageSize;
    this.paginatedData = this.sortedData.slice(start, start + this.pageSize);
  }

  updatePagination(page: number) {
    this.page = page;
    this.updatePaginatedData();
  }

  mounted() {
    this.updateSortedData();
  }

  @Watch('data')
  onDataChanged() {
    this.updateSortedData();
  }
}
