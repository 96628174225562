
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu, Input } from 'element-ui';
import { Editor } from '@tiptap/core';
import DropdownButton from '@/components/tiptap/Menu/DropdownButton.vue';
import { TipTapEditorContext } from '@/components/tiptap/types/TipTapEditorContext';
import { DEFAULT_FONT_SIZE, FONT_SIZES } from '@/components/tiptap/constants';

const ARROW_UP = 'arrow-up';
const ARROW_DOWN = 'arrow-down';

@Component({
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Input.name]: Input,
    DropdownButton,
  },
})
export default class MenuFontSizeDropdown extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  isInputFocused = false;
  isVisible = false;
  fontSize = DEFAULT_FONT_SIZE;
  tableFontSize = DEFAULT_FONT_SIZE;

  $refs!: {
    dropdown: Dropdown;
    input: Input;
  };

  private get fontSizes() {
    return FONT_SIZES;
  }

  get icon() {
    return this.isVisible ? ARROW_UP : ARROW_DOWN;
  }

  isActive(fontSize?: number): boolean {
    if (fontSize) {
      return this.editor.isActive('fontStyle', { fontSize });
    }
    return this.editor.isActive('fontStyle', { fontSize: null });
  }

  handleVisibleChange(isVisible: boolean) {
    this.isVisible = isVisible;
  }

  handleFocusInput() {
    this.isInputFocused = true;
  }

  handleBlurInput() {
    this.isInputFocused = false;
    const size = this.fontSize;
    if (size > 0) {
      this.setFontSize(size);
    }
  }

  handleKeyPress(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.handleBlurInput();
      const dropdown = this.$refs.dropdown as unknown as {
        hide: () => {};
      };
      dropdown.hide();
    }
  }

  setFontSize(size: number) {
    this.editor.chain().focus().setFontSize(size).run();
    this.fontSize = size;
  }

  updateCurrentFont() {
    const isTableActive = this.editor.isActive('table');
    const { schema, selection, storedMarks } = this.editor.view.state;
    const marks = selection.$head.marks();
    const textStyleMark = marks.find((mark) => mark.type === schema.marks.textStyle);
    if (textStyleMark && textStyleMark.attrs.fontSize) {
      this.fontSize = textStyleMark.attrs.fontSize;
      this.tableFontSize = this.fontSize;
    } else if (storedMarks) {
      this.fontSize = storedMarks[0].attrs.fontSize;
    } else {
      this.fontSize = DEFAULT_FONT_SIZE;
    }

    if (!this.fontSize) {
      this.fontSize = DEFAULT_FONT_SIZE;
    }

    if (isTableActive && !this.isInputFocused) {
      this.fontSize = this.tableFontSize;
      this.setFontSize(this.fontSize);
    }
  }

  onClickShow() {
    const dropdown = this.$refs.dropdown as Dropdown & { handleClick: () => void };
    this.updateCurrentFont();
    dropdown.handleClick();
  }

  mounted() {
    this.fontSize = DEFAULT_FONT_SIZE;
  }

  @Watch('editorContext.state')
  onChangeHeading() {
    this.updateCurrentFont();
  }
}
