export default class ValidateService {
  component: any;
  componentCb: any;

  constructor(component: any, componentCb: () => void) {
    this.component = component;
    this.componentCb = componentCb;
  }

  getValidationClass(fieldName: string) {
    const field = this.component.$v.form[fieldName];

    if (field) {
      return {
        'md-invalid': field.$invalid && field.$dirty,
      };
    }
  }
  validateForm() {
    this.component.$v.$touch();
    if (!this.component.$v.$invalid) {
      this.componentCb();
    }
  }
}
