import axios from 'axios';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import { AnyJson } from '@/@types/AnyJson';

export class WorkspaceGroupRolesServiceProvider extends ApiBaseServiceProvider {
  scope = 'role/group';

  all(workspaceId: string) {
    return axios.get<{ roles: AnyJson[] }>(this.getBaseUrl(workspaceId), this.getRequestConfig());
  }

  // update(id: string, data: any) {
  //   return axios.put(
  //     this.getBaseUrl() + id,
  //     data,
  //     this.getRequestConfig(),
  //   );
  // }
  //

  delete(workspaceId: string, groupId: string) {
    return axios.delete(this.getBaseUrl(workspaceId) + groupId, this.getRequestConfig());
  }

  create(workspaceId: string, groupId: string, role: string) {
    return axios.post(
      this.getBaseUrl(workspaceId),
      {
        groupId,
        role,
      },
      this.getRequestConfig()
    );
  }

  getBaseUrl(id: string) {
    return super.getBaseUrl(`workspace/${id}/${this.scope}`);
  }
}
