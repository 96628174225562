import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios from 'axios';
import { GetArticlesResponse } from '@/@types/responses/ArticleResponses';
import { GetDraftArticleResponse, GetDraftsCountResponse } from '@/@types/responses/DraftArticlesResponses';
import { Article } from '@/models/article/Article';
import { SaveOptions } from '@/@types/SaveOptions';

export class DraftArticleServiceProvider extends ApiBaseServiceProvider {
  scope = 'draft';

  get = (id: string) => {
    return axios.get<GetDraftArticleResponse>(this.getBaseUrl() + id, this.getRequestConfig());
  };

  getAll = () => {
    return axios.get<GetArticlesResponse>(this.getBaseUrl(), this.getRequestConfig());
  };

  count = () => {
    return axios.get<GetDraftsCountResponse>(this.getBaseUrl() + 'count', this.getRequestConfig());
  };

  save = (article: Article, options: SaveOptions) => {
    const { updatedByUser, isPublish = false, isNotification = false } = options;

    return axios.post<{ article: any }>(
      this.getBaseUrl('article') + article.id + '/draft',
      Object.assign({}, article, { updatedByUser, isPublish, isNotification }),
      this.getRequestConfig()
    );
  };

  delete = (id: string) => {
    return axios.delete(this.getBaseUrl() + id, this.getRequestConfig());
  };

  getDiscardHash = (id: string) => {
    return axios.get<{ hash: string }>(this.getBaseUrl() + id + '/discard-hash', this.getRequestConfig());
  };

  discard = (id: string) => {
    return axios.post(this.getBaseUrl() + id + '/discard', {}, this.getRequestConfig());
  };
}
