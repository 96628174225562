import store from '@/store';
import { Article } from '@/models/article/Article';
import { Paragraph } from '@/models/article/Paragraph';
import { TextTab } from '@/models/article/TextTab';

export const commitSetArticleEditorArticle = (article: Article) => {
  store.commit('setArticleEditorArticle', article);
};
export const commitSetArticleEditorArticleProps = (props: Partial<Article>) => {
  store.commit('setArticleEditorArticleProps', props);
};

export const commitSetArticleEditorArticleParagraphs = (paragraphs: Paragraph[]) => {
  store.commit('setArticleEditorArticleParagraphs', paragraphs);
};

export const commitSetArticleEditorArticleDuration = (duration: number) => {
  store.commit('setArticleEditorArticleDuration', duration);
};

export const commitSetArticleEditorTextTabs = (tabs: TextTab[]) => {
  store.commit('setArticleEditorTextTabs', tabs);
};

export const commitSetArticleEditorSelectedTab = ({
  slug = null,
  textTab = null,
}: {
  slug: string | null;
  textTab: TextTab | null;
}) => {
  store.commit('setArticleEditorSelectedTextTabSlug', slug);
  store.commit('setArticleEditorSelectedTextTab', textTab);
};

export const commitSetArticleEditorIsAutosaveEnabled = (value: boolean) => {
  store.commit('setArticleEditorIsAutosaveEnabled', value);
};

export const commitSetArticleEditorIsPublishingDisabled = (value: boolean) => {
  store.commit('setArticleEditorIsPublishingDisabled', value);
};

export const commitSetArticleEditorArticleHash = (value: string) => {
  store.commit('setArticleEditorArticleHash', value);
};
