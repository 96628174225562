import apiService from '@/services/ApiService';
import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import { Article } from '@/models/article/Article';
import { commitSetSelectedArticle } from '@/store/commits/workspaceViewCommits';
import { dispatchLoadCompanyUsers } from '@/store/dispatchers/companyDispatchers';
import { dispatchLoadArticleComments } from '@/store/dispatchers/articleCommentsDispatchers';
import authService from '@/services/AuthService';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { wait } from '@/other/wait';
import urlService from '@/services/UrlService';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import router from '@/router';
import { Paragraph } from '@/models/article/Paragraph';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';

export const workspaceViewActions = {
  async workspaceViewSelectFirstArticle({ state }: ActionContext<State, {}>) {
    const order = state.workspaceView.workspace.order;
    const articles = state.workspaceView.articles.filter((article: Article) => article.isArchived !== true);
    //@todo add type for order
    const nonArchivedOrder: any[] = [];
    let selectedArticle: Article = articles ? articles[0] : null;

    if (order) {
      order.forEach((item: any) => {
        for (const article of articles) {
          if (item.value === article.id) {
            nonArchivedOrder.push(item);
            break;
          }
        }
      });
    }
    if (nonArchivedOrder?.[0]?.value) {
      selectedArticle = articles.find((a: Article) => a.id === nonArchivedOrder[0].value);
    }

    if (selectedArticle?.id) {
      const resNotes = await apiService.article.loadNotes(selectedArticle.id);
      selectedArticle.textTabs = resNotes.data.notes;
    }

    commitSetSelectedArticle(selectedArticle);
    if (selectedArticle) {
      const pinnedNote = selectedArticle.pinnedNote;
      const pinnedTextTab = selectedArticle.textTabs.find((tab) => tab.id === pinnedNote);
      const tab = pinnedTextTab ? pinnedTextTab : selectedArticle.textTabs[0];
      dispatchUpdateSelectedTextTab(selectedArticle.textTabs && !selectedArticle.isVideoArticle() ? tab : null);
    }
  },

  async workspaceViewPageMounted({ state }: ActionContext<State, {}>) {
    const companyUsers = state.companyUsers;

    if (!companyUsers.length && authService.isAuthorized()) {
      await dispatchLoadCompanyUsers();
    }
  },

  async workspaceViewWorkspaceLoaded({ dispatch }: ActionContext<State, {}>) {
    await dispatch('commentsSidebarCheckAutoShowComment');
  },

  async workspaceViewSelectedArticleChanged({ state }: ActionContext<State, any>) {
    const selectedArticle = state.workspaceView.selectedArticle;
    if (!selectedArticle) return;
    if (selectedArticle?.id && authService.isAuthorized()) {
      await dispatchLoadArticleComments();
    }

    const mode = state.articleMode;
    if (mode === ArticleEditMode.NOTES) {
      if (!selectedArticle?.hasText() && selectedArticle.isVideoArticle()) {
        commitSetArticleMode(ArticleEditMode.VIDEO);
      }
    }
  },

  async openSelectedArticle(
    { state }: ActionContext<State, {}>,
    { article, newTab }: { article: Article; newTab?: boolean }
  ) {
    const resNotes = await apiService.article.loadNotes(article.id);
    article.textTabs = resNotes.data.notes;

    const mode = article.pinnedNote
      ? ArticleEditMode.NOTES
      : article.isVideoArticle() || !article.isTextArticle()
      ? ArticleEditMode.VIDEO
      : ArticleEditMode.NOTES;

    if (article.id !== state.workspaceView.selectedArticle.id && !newTab) {
      const firstTextTab = article.textTabs[0];
      if (firstTextTab && !article.pinnedNote) {
        await dispatchUpdateSelectedTextTab(firstTextTab);
      } else if (article.textTabs.length && article.pinnedNote) {
        const foundItem = article.textTabs.find((item) => item.id === article.pinnedNote);
        await dispatchUpdateSelectedTextTab(foundItem);
      }

      if (!firstTextTab) await dispatchUpdateSelectedTextTab(null);

      commitSetSelectedArticle(article);
    }

    if (!state.workspaceView.isSharedMode) {
      if (newTab) {
        // Open the link in a new tab
        window.open(urlService.getArticleViewUrl(article, state.workspaceView.workspace, mode), '_blank');
      } else {
        await router.push(urlService.getArticleViewRoute(article, state.workspaceView.workspace, mode));
        await wait(100); // wait as route changed and article may contain a few paragraphs with the same name
      }
    }

    !newTab && workspaceService.playArticle(article, false);
  },

  async openSelectedParagraph(
    { state }: ActionContext<State, {}>,
    { paragraph, article, newTab }: { paragraph: Paragraph; article: Article; newTab?: boolean }
  ) {
    const isAnotherArticle = article.id !== state.workspaceView.selectedArticle.id;
    if (isAnotherArticle && !newTab) commitSetSelectedArticle(article);
    await dispatchUpdateSelectedTextTab(null);

    if (!state.workspaceView.isSharedMode) {
      if (newTab) {
        // Open the link in a new tab
        window.open(
          urlService.getParagraphViewUrl(paragraph.getSlug(), article, state.workspaceView.workspace),
          '_blank'
        );
        await wait(100);
      } else {
        await router.push(
          urlService.getParagraphViewRoute(paragraph.getSlug(), article, state.workspaceView.workspace)
        );
        await wait(100); // wait as route changed and article may contain a few paragraphs with the same name
      }
    }
    await dispatchUpdateSelectedTextTab(null);

    !newTab && workspaceService.playParagraph({ paragraph, article }, !isAnotherArticle);
  },
};
