import apiService from '@/services/ApiService';
import { Article } from '@/models/article/Article';
import articleEditService from '@/services/article/ArticleEditService';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import notificationService from '@/services/NotificationService';
import { commitSetSelectedArticle } from '@/store/commits/workspaceViewCommits';

export class SharedArticleService {
  handleToggleSharedStatus(id: string, shared: boolean, isRefreshToken: boolean, withNotification = true) {
    apiService.sharedArticle
      .toggleShared(id, shared, isRefreshToken)
      .then((res) => {
        const article = Article.fromJson({ ...articleEditService.articleEditor.article, shared: res.data.sharedData });
        this.updateArticleSharedState(article);
        if (withNotification) {
          if (res.data.sharedData) {
            notificationService.success('Shared link was generated successfully');
          } else {
            notificationService.success('Shared link was deleted successfully');
          }
        }
      })
      .catch((err) => {
        apiService.handleResponseError(err);
      });
  }

  async handleRemoveEntityFromShared(articleId: string, entityId: string) {
    try {
      const res = await apiService.sharedArticle.removeSharedEntity(articleId, entityId);
      const article = Article.fromJson({
        ...articleEditService.articleEditor.article,
        sharedUserGroup: res.data.sharedUserGroupId,
      });
      notificationService.success('The user/group has been successfully deleted from the list');
      this.updateArticleSharedState(article);
    } catch {
      notificationService.error('Failed to remove shared user or group from article');
    }
  }

  async handleAddEntityToShared(articleId: string, entities: any[]) {
    try {
      const res = await apiService.sharedArticle.addSharedEntities(articleId, entities);
      const article = Article.fromJson({
        ...articleEditService.articleEditor.article,
        sharedUserGroup: res.data.sharedUserGroupId,
      });
      this.updateArticleSharedState(article);
      notificationService.success('The article is successfully shared with user/group');
    } catch {
      notificationService.error('Failed to add shared user or group to article');
    }
  }

  protected updateArticleSharedState(article: Article) {
    const articles = workspaceService.workspaceView.articles;
    articles.map((el: Article) => {
      if (el.id === article.id) {
        el.shared = article.shared;
        el.sharedUserGroup = article.sharedUserGroup;
      }
      return el;
    });
    commitSetSelectedArticle(article);
    workspaceService.setState('articles', articles);
  }
}

const sharedArticleService = new SharedArticleService();
export default sharedArticleService;
