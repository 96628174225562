
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserNotification } from '@/models/UserNotification';

@Component({})
export default class NotificationsSidebarNotification extends Vue {
  @Prop() notification: UserNotification;

  get timeFormatted() {
    return moment.unix(this.notification.time).format('MMM DD, YYYY');
  }

  get imageStyles() {
    const img = this.notification.user?.img;
    if (img) {
      return { 'background-image': `url("${img}")` };
    }
    return undefined;
  }
}
