import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';
import { JobStatus } from '@/@types/enums/JobStatus';

export class Job implements Model {
  static parse(data: unknown): Job {
    return modelService.create(Job, data);
  }

  id = '';
  name = '';
  error = '';
  log = '';
  workerName? = '';
  status: JobStatus = JobStatus.NEW;
  data: any = {};
  limit = 0;
  attempts = 0;
  lastAttemptAt = 0;
  nextAttemptAt = 0;
  updatedAt = 0;
  createdAt = 0;

  afterLoad() {}
}
