
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SuggestionEventProps } from '@/components/tiptap/extensions/Mention/SuggestionEventProps';
import MentionListItem from '@/components/tiptap/extensions/Mention/MentionListItem.vue';
import { User } from '@/models/User';
import logger from '@/other/Logger';

@Component({
  components: { MentionListItem },
})
export default class MentionList extends Vue {
  @Prop({ type: Array, required: true }) items: User[];
  @Prop({ type: Function, required: true }) command: Function;
  selectedIndex = 0;

  onKeyDown({ event }: SuggestionEventProps) {
    if (event.key === 'ArrowUp') {
      this.upHandler();
      return true;
    }

    if (event.key === 'ArrowDown') {
      this.downHandler();
      return true;
    }

    if (event.key === 'Enter') {
      this.enterHandler();
      return true;
    }

    return false;
  }

  upHandler() {
    this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length;
  }

  downHandler() {
    this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
  }

  enterHandler() {
    if (this.items.length && this.items[this.selectedIndex]) {
      this.selectItem(this.items[this.selectedIndex]);
    }
  }

  selectItem(user: User) {
    try {
      this.command({ id: user.id, label: user.name });
    } catch (e) {
      if ((e as DOMException).message?.includes('collapseToEnd')) return;

      logger.error(e);
    }
  }

  @Watch('items')
  onItemsChanged() {
    this.selectedIndex = 0;
  }
}
