import apiService from '@/services/ApiService';
import { Workspace } from '@/models/Workspace';
import { Article } from '@/models/article/Article';
import { Paragraph } from '@/models/article/Paragraph';
import { SharedArticle } from '@/models/SharedArticle';
import { WORKSPACE_EDIT, WORKSPACES } from '@/routerNames';
import EventBus from '@/EventBus';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import navigationService from '@/services/NavigationService';
import notificationService from '@/services/NotificationService';
import confirmationService from '@/services/ConfirmationService';
import { DeletedItemType } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemType';
import { DeletedItemStyleClass } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemStyleClass';
import { Logger } from '@/other/Logger';
import { PERSONAL_WORKSPACE_TITLE } from '@/constants/SharedConsts';
import { WorkspaceVisibility } from '@/other/WorkspaceVisibility';
import { Mutations } from '@/store/mutations';
import { Store } from 'vuex';
import { State } from '@/store/state';
import { Loading } from 'element-ui';
import { commitSetSelectedArticle } from '@/store/commits/workspaceViewCommits';
import { commitSetArticleMode, commitSetAutoPlay, commitSetVideoPlayerTimeToSet } from '@/store/commits/sharedCommits';
import authService from '@/services/AuthService';
import ArticlePlayer from '@/components/article-player/ArticlePlayer.vue';
import articlePlayerProvider from '@/services/ui-providers/ArticlePlayerProvider';
import {
  dispatchWorkspaceViewSelectFirstArticle,
  dispatchWorkspaceViewWorkspaceLoaded,
} from '@/store/dispatchers/workspaceViewDispatchers';
import { dispatchGetDraftsCount, dispatchGetPersonalArticlesCount } from '@/store/dispatchers/uiDispatchers';
import { commitSetWorkspaceIsArchived } from '@/store/commits/workspaceCommit';
import helper from '@/helper';
import { wait } from '@/other/wait';
import { WorkspaceView } from '@/@types/WorkspaceView';
import { WorkspacesOrdering } from '@/@types/WorkspacesOrdering';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import router from '@/router';
import slugify from 'slugify';

const log = new Logger('WorkspaceService');

export class WorkspaceService {
  store: Store<State>;

  setStore = (store: Store<State>) => {
    this.store = store;
  };

  get workspaceView() {
    return this.store.state.workspaceView;
  }

  get personalWorkspaceId() {
    return this.store.state.user.personalWorkspaceId;
  }

  getVideoPlayerRef() {
    return articlePlayerProvider.videoPlayer as ArticlePlayer;
  }

  // update any property in workspacesView from store
  setState(property: string, value: any) {
    this.store.commit('setWorkspaceView', [property, value]);
  }

  setIsLoading(value: boolean) {
    this.store.commit(Mutations.SET_WORKSPACE_VIEW_IS_LOADING, value);
  }

  // this function will set workspace to the top of the workspaces list. P.S. i don't like this function at all
  setLastWorkspaceToStorage(wpId: string) {
    const userId = authService.user.id;
    let ordering = JSON.parse(localStorage.getItem('workspacesOrdering')) as WorkspacesOrdering;

    if (!ordering) {
      ordering = [];
    }
    const containsCurrentUser =
      ordering.find(function (x) {
        return x.userId === userId;
      }) !== undefined;

    if (!containsCurrentUser) {
      ordering.push({ userId, wp: [wpId] });
    } else {
      const userIndex = ordering.indexOf(
        ordering.find(function (x) {
          return x.userId === userId;
        })
      );
      const wpIndex = ordering[userIndex].wp.indexOf(wpId);
      if (wpIndex > -1) {
        ordering[userIndex].wp.splice(wpIndex, 1);
      }
      ordering[userIndex].wp.unshift(wpId);
    }

    localStorage.setItem('workspacesOrdering', JSON.stringify(ordering));
    EventBus.$emit('workspace-ordering-changed', ordering);
  }

  fetchWorkspaces(callback: (result: Workspace[]) => void) {
    const loader = Loading.service({
      lock: true,
      text: 'Loading workspaces',
    });
    apiService
      .getWorkspaces(true)
      .then((res) => {
        const workspaces = res.data.items ? res.data.items.map((item: any) => Workspace.parse(item)) : [];
        callback(workspaces);
      })
      .catch((data: any) => {
        if (data.response && data.response.data.company) {
          this.store.commit(Mutations.SET_COMPANY_STATUS, data.response.data.company.isActivated);
        }
        apiService.handleResponseError(data);
      })
      .finally(() => {
        loader.close();
      });
  }

  async deleteWorkspace(workspace: Workspace, callback?: (w?: Workspace) => void) {
    try {
      await confirmationService.showRemovePopup(
        workspace.title,
        DeletedItemType.WORKSPACE,
        DeletedItemStyleClass.WORKSPACE
      );
      try {
        await apiService.workspace.delete(workspace.id);

        notificationService.success('Workspace was successfully deleted');

        // emmit global event
        EventBus.$emit('workspace-delete', workspace);

        await dispatchGetDraftsCount();
        await dispatchGetPersonalArticlesCount();

        if (callback) {
          callback(workspace);
        }
      } catch (error) {
        apiService.handleResponseError(error);
      }
    } catch (error) {
      if (error === 'cancel') {
        return;
      }
      log.error(error);
    }
  }

  async toggleWorkspaceArchive(workspace: Workspace, isArchived: boolean) {
    const textAction = isArchived ? 'unarchive' : 'archive';
    const customClass = isArchived ? 'unarchive-workspace' : 'archive-workspace';
    const result = await confirmationService
      .showArchiveWorkspacePopup(textAction, workspace.title, 'workspace', customClass)
      .catch(() => 'cancel');

    if (result !== 'confirm') return;
    try {
      const res = await apiService.workspace.setWorkspaceIsArchived(workspace.id, !isArchived);
      const updatedWorkspace = Workspace.parse(res.data.workspace);

      EventBus.$emit('workspace.update');

      if (this.workspaceView.workspace) {
        commitSetWorkspaceIsArchived(updatedWorkspace.isArchived);
      }

      return updatedWorkspace;
    } catch (err) {
      apiService.handleResponseError(err);
    }
  }

  async loadWorkspaceById(id: string) {
    const loader = Loading.service({
      lock: true,
      text: 'Loading Workspace',
    });
    try {
      const res = await apiService.workspace.get(id);
      const workspace = Workspace.parse(res.data.workspace);
      await this.loadWorkspace(workspace);
    } catch (data) {
      apiService.handleResponseError(data);
      loader.close();
      await navigationService.navigate({ name: WORKSPACES });
    } finally {
      loader.close();
    }
  }

  async loadWorkspace(workspace: Workspace) {
    // // @TODO remove in future
    this.store.commit('setWorkspace', workspace);
    this.setState('workspace', workspace);
    try {
      await this.loadArticles();
      await wait(1000);
      await this.checkAutoplay();
      await dispatchWorkspaceViewWorkspaceLoaded();
    } catch (error) {
      log.error(error);
    }
  }

  async loadArticles() {
    try {
      const res = await apiService.workspace.getArticles(this.workspaceView.workspace.id, true);

      const sharedArticles: SharedArticle[] = res.data.sharedArticles.map((item: any) => SharedArticle.fromJson(item));
      const articles: Article[] = res.data.articles.map((item: any) => {
        const article = Article.fromJson(item);
        article.shared = sharedArticles.find((shared) => shared.articleId === item.id);
        return article;
      });

      this.setState('articles', articles);

      if (articles.length <= 0) {
        return;
      }

      this.checkWorkspaceOrder(this.workspaceView);
    } catch (err) {
      apiService.handleResponseError(err);
    } finally {
      this.setIsLoading(false);
    }
  }

  checkWorkspaceOrder(workspaceView: WorkspaceView) {
    const { articles, workspace } = workspaceView;

    const order = (workspace && workspace.order) || [];

    if (articles && articles.length > 0 && order) {
      if (!order) {
        return;
      } else if (!order.length) {
        articles.forEach((article: Article) =>
          order.push({
            value: article.id,
            items: [],
          })
        );
      } else {
        articles.map((article: Article) => {
          const hasArticle = this.checkArticleOrder(order, article.id);
          if (!hasArticle) {
            order.push({
              value: article.id,
              items: [],
            });
          }
        });
      }

      //check for old order
      const resultOrder = order.filter((orderItem: any) => {
        return typeof orderItem !== 'string';
      });

      this.store.commit('setWorkspaceOrder', resultOrder);
    }
  }

  getParentArticle(item: any, id: string) {
    let parentArticleId: string;
    if (item.value === id) {
      parentArticleId = item.value;
      return parentArticleId;
    }

    if (item.items && item.items.length) {
      item.items.forEach((element: any) => {
        if (element.value === id) {
          parentArticleId = item.value;
          return parentArticleId;
        }
        if (!parentArticleId) {
          parentArticleId = this.getParentArticle(element, id);
        }
      });
      if (parentArticleId) {
        return parentArticleId;
      }
    }

    if (parentArticleId) {
      return parentArticleId;
    }
  }

  checkArticleOrder(order: any, id: string): any {
    if (order === null) return null;

    let found = order.find((item: any) => item.value === id);
    if (!found) {
      for (let i = 0; i < order.length; i++) {
        if (order[i].items && order[i].items.length) {
          found = this.checkArticleOrder(order[i].items, id);
          if (found) {
            break;
          }
        } else {
          found = null;
        }
      }
    }
    return found;
  }

  removeItemFromTree(data: any, id: string) {
    const treeResult: any = [];
    data.forEach((el: any) => {
      this.workspaceView.articles.forEach((article: Article) => {
        if (el.value === article.id) {
          if (el.value !== id) {
            treeResult.push({
              value: el.value,
              items: el.items && el.items.length ? this.removeItemFromTree(el.items, id) : [],
            });
          }
        }
      });
    });
    return treeResult;
  }

  async saveArticlesOrder(order: any) {
    const loader = Loading.service({
      lock: true,
      text: 'Updating articles order',
    });
    try {
      await apiService.workspace.updateArticlesOrder(this.workspaceView.workspace.id, order);
      // reload articles
      await this.loadArticles();
      await dispatchWorkspaceViewSelectFirstArticle();
    } catch (err) {
      apiService.handleResponseError(err);
      loader.close();
    } finally {
      loader.close();
    }
  }

  removeArticleFromOrder(articleId: string, order: any[]) {
    for (let i = 0; i < order.length; i++) {
      // if array item has value, then remove it
      if (order[i].value === articleId) {
        order.splice(i, 1);
      } else if (typeof order[i].items !== 'undefined') {
        // if object doesn't have desired value but has items, call this function
        // on the children array
        this.removeArticleFromOrder(articleId, order[i].items);
      }
    }
    return order;
  }

  onChangeOrder(data: any, articleId: string) {
    const treeResult: any = [];
    data.map((el: any) => {
      if (articleId !== el.value) {
        treeResult.push({
          value: el.value,
          items: el.items && el.items.length ? this.onChangeOrder(el.items, articleId) : [],
        });
      }
    });
    return treeResult;
  }

  async checkAutoplay() {
    if (!this.store.state.autoPlay) return;

    const { workspaceId, articleId, paragraphSlug } = this.store.state.autoPlay;
    const article =
      this.workspaceView.articles.find((article) => article.id === articleId || article.shortId === articleId) ?? null;

    if (article) {
      const resNotes = await apiService.article.loadNotes(article.id);
      article.textTabs = resNotes.data.notes;

      commitSetSelectedArticle(article);

      if (!this.fixArticleMode(article)) {
        const item = this.getArticleParagraph(article, paragraphSlug);
        const paragraph = Paragraph.parse(item);
        await this.setParagraphToPlay(paragraph, article);
      }
    } else if (workspaceId) {
      await dispatchWorkspaceViewSelectFirstArticle();
    } else {
      commitSetAutoPlay(null);
    }
  }

  async setParagraphToPlay(paragraph: Paragraph | null, article: Article) {
    if (paragraph) {
      await wait(1000);
      this.playParagraph({ paragraph, article }, false);
    } else {
      this.playArticle(article, false);
    }
  }

  getArticleParagraph(article: Article, paragraphSlug: string): Paragraph | null {
    let paragraph: Paragraph | null = null;

    article.eachParagraph((item: Paragraph) => {
      if (paragraph) return;
      if (item.getSlug() === paragraphSlug) {
        paragraph = item;
      }
    });
    return paragraph;
  }

  editWorkspace() {
    navigationService.navigate({
      name: WORKSPACE_EDIT,
      params: { id: this.workspaceView.workspace.id },
    });
  }

  playArticle(article: Article, play = true) {
    this.onCloseMobileSidebar();
    const videoPlayerRef = this.getVideoPlayerRef();

    (videoPlayerRef ? this.getVideoPlayerRef().stop() : Promise.resolve()).then(() => {
      if (play) {
        this.getVideoPlayerRef().playArticle();
      }
    });
  }

  playParagraph(data: { article: Article; paragraph: Paragraph }, play = true) {
    this.onCloseMobileSidebar();
    const { article, paragraph } = data;
    const videoPlayerRef = this.getVideoPlayerRef();

    if (videoPlayerRef) {
      videoPlayerRef.stop();

      if (play) {
        videoPlayerRef.playParagraph(paragraph);
      } else {
        if (this.store.state.videoPlayerTimeToSet) {
          videoPlayerRef.setTime(this.store.state.videoPlayerTimeToSet);
          commitSetVideoPlayerTimeToSet(null);
          return;
        }
        videoPlayerRef.setTime(article.getParagraphPosition(paragraph));
      }
    }
  }

  getWorkspaceTitle(workspace: Workspace, emptyTitle = ''): string {
    let title = emptyTitle;

    if (workspace) {
      if (workspace.visibility !== WorkspaceVisibility.personal) {
        title = workspace.title;
      } else {
        title = PERSONAL_WORKSPACE_TITLE;
      }
    }

    return title;
  }

  onCloseMobileSidebar() {
    if (mobileResponsiveService.isMobileView) {
      EventBus.$emit('close-mobile-sidebar');
    }
  }

  filteredWorkspaces(allWorkspaces: Workspace[], searchQuery: string, searchPrivacy: string, searchUser: string) {
    let filteredWorkspaces = allWorkspaces.filter((workspace: Workspace) => {
      const source = helper.textHandler(workspace.title.concat(workspace.visibility));
      const searchResult = source.includes(helper.textHandler(searchQuery));

      if (searchResult) {
        return workspace;
      }
    });

    if (searchPrivacy && searchPrivacy.length) {
      filteredWorkspaces = this.filterByPrivacy(filteredWorkspaces, searchPrivacy);
    }

    if (searchUser && searchUser.length) {
      filteredWorkspaces = this.filterByUser(filteredWorkspaces, searchUser);
    }

    return filteredWorkspaces;
  }

  filterByPrivacy(workspaces: Workspace[], searchPrivacy: string) {
    return workspaces.filter((workspace: Workspace) => {
      let searchResult;

      if (searchPrivacy.length) {
        searchResult =
          helper.textHandler(searchPrivacy) === helper.textHandler(workspace.visibility) ||
          helper.textHandler(searchPrivacy) === 'archived';
      } else {
        searchResult = true;
      }

      if (searchResult) {
        if (searchPrivacy === 'archived') {
          return workspace.isArchived ?? workspace;
        } else if (searchPrivacy === '') {
          return workspace;
        } else {
          return !workspace.isArchived ?? workspace;
        }
      }
    });
  }

  filterByUser(workspaces: Workspace[], searchUser: string) {
    return workspaces.filter((workspace: Workspace) => {
      let searchResult;
      if (searchUser.length) {
        const user = workspace.author ? workspace.author : workspace.user;
        if (!!user && user.firstName) {
          searchResult = helper.textHandler(searchUser) === helper.textHandler(`${user.firstName} ${user.lastName}`);
        } else {
          searchResult = searchUser === '-';
        }
      } else {
        searchResult = true;
      }
      if (searchResult) {
        return workspace;
      }
    });
  }

  slugifyTitle(workspaceTitle: string) {
    const title = workspaceTitle?.indexOf('PW_') == 0 ? 'personal' : workspaceTitle;
    return slugify(title);
  }

  private fixArticleMode(article: Article) {
    const routeMode = router.currentRoute.params.mode;
    const isParagraphSlug = !!router.currentRoute.params.paragraphSlug;
    const isRouteMode = !!routeMode;
    const articleMode = this.store.state.articleMode;
    const isVideMode = articleMode === ArticleEditMode.VIDEO;

    if (isRouteMode || !isVideMode) return false;

    if ((article.pinnedNote && !isParagraphSlug) || (!article.isVideoArticle() && article.isHasText)) {
      commitSetArticleMode(ArticleEditMode.NOTES);
      return true;
    }

    return false;
  }
}

export const workspaceService = new WorkspaceService();
export default workspaceService;
