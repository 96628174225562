import apiService from './ApiService';
import extensionService from '@/services/ExtensionService';
import { User } from '@/models/User';
import { Company } from '@/models/Company';
import { AnyJson } from '@/@types/AnyJson';
import { CompanyUserStatus } from '@/@types/enums/CompanyUserStatus';
import { CompanyRole } from '@/@types/CompanyRole';
import { Logger } from '@/other/Logger';
import { Mutations } from '@/store/mutations';
import { Store } from 'vuex';
import { CompanyResponse } from '@/@types/responses/CompanyResponses';
import { UserResponse } from '@/@types/responses/UserResponses';
import localStorageService from '@/services/LocalStorageService';

const log = new Logger('AuthService');

export class AuthService {
  protected store: Store<any>;

  setStore(store: Store<any>) {
    this.store = store;
  }

  get user(): User {
    return this.store.state.user;
  }

  setUser(data: UserResponse): User {
    const user = User.parse(data);
    this.store.commit(Mutations.SET_USER, user);
    return user;
  }

  setCompany(data: CompanyResponse) {
    const company = Company.parse(data);
    this.store.commit(Mutations.SET_COMPANY, company);
    this.store.commit('setCompanyLoading', false);
    extensionService.logInUser(apiService.authToken);
  }

  isGuest(): boolean {
    return !(this.user && this.user.email);
  }

  isAuthorized(): boolean {
    return !!(this.user && this.user.id && apiService.authToken);
  }

  logout(): void {
    extensionService.logOutUser();
    apiService.setAuthToken(null);
    this.store.commit(Mutations.SET_USER, null);
    this.store.commit(Mutations.SET_COMPANY, null);
    this.store.commit(Mutations.SET_USER_COMPANIES, null);
    localStorageService.clear();
  }

  async isUserActive(): Promise<boolean> {
    try {
      const user = await this.loadUser();
      const company = user.company as Company;
      const roles: CompanyRole[] = Object.values(company.roles);
      if (company.user === user.id) {
        return true;
      }
      const activeUser: CompanyRole[] = roles.filter((item: CompanyRole) => {
        if (!item.status) return item;
        if (item.user === user.id && item.status !== CompanyUserStatus.DEACTIVATED) {
          return item;
        }
      });

      return !!activeUser.length;
    } catch (error) {
      log.error(error);
    }
  }

  async loadUser(): Promise<User> {
    try {
      const res = await apiService.getUser();
      const user = this.setUser(res.data.user);
      this.setCompany(res.data.user.company);
      this.store.commit(
        'setUserCompanies',
        res.data.companies.map((item: AnyJson) => Company.parse(item))
      );

      return user;
    } catch (err) {
      apiService.handleResponseError(err);
    }
  }
}

const authService = new AuthService();
export default authService;
