import store from '@/store';
import { Article } from '@/models/article/Article';

export const commitSetSelectedArticle = (article: Article) => {
  store.commit('setWorkspaceViewSelectedArticle', article);
};

export const commitSetWorkspaceViewArticles = (articles: Article[]) => {
  store.commit('setWorkspaceViewArticles', articles);
};

export const commitWorkspaceViewReset = () => {
  store.commit('resetWorkspaceView');
};

export const commitSetWorkspaceViewCommentsCount = (count = 0) => {
  store.commit('setWorkspaceView', ['commentsCount', count]);
};
