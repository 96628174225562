import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';
import { UserNotificationType } from '@/@types/UserNotification';

type UserNotificationPayload = {
  articleTitle?: string;
  articleShortId?: string;
  articleId?: string;
  workspaceTitle?: string;
  workspaceShortId?: string;
  commentId?: string;
  sharedToken?: string;
  isDraft?: boolean;
};

export class UserNotification implements Model {
  id: string | null = null;
  user: { firstName: string; lastName: string; img?: string } | null = null;
  type: UserNotificationType = UserNotificationType.ArticleMention;
  time: number;
  payload: UserNotificationPayload = {};
  isNew = false;

  static parse(jsonData: unknown): UserNotification {
    return modelService.create(UserNotification, jsonData);
  }

  afterLoad(): void {}

  getName(): string {
    return this.user ? (this.user.firstName + ' ' + this.user.lastName).trim() : '';
  }
}
