/* eslint-disable @typescript-eslint/no-explicit-any */
import { Workspace } from '@/models/Workspace';
import { Article } from '@/models/article/Article';
import { State } from '@/store/state';

export const workspaceMutations = {
  setWorkspace(state: State, workspace: Workspace): void {
    state.workspace = workspace;
  },
  setWorkspaceIsArchived(state: State, isArchived: boolean) {
    state.workspaceView.workspace.isArchived = isArchived;
  },
  setPersonalWorkspace(state: State, workspace: Workspace | null): void {
    state.personalWorkspace = Workspace.parse(JSON.parse(JSON.stringify(workspace)));
  },
  setWorkspaces(state: State, workspaces: Workspace[]): void {
    state.workspaces =
      workspaces && workspaces.length
        ? workspaces.map((workspace) => {
            return Workspace.parse(JSON.parse(workspace.stringify()));
          })
        : [];
  },
  setWorkspaceView(state: any, payload: any): void {
    state.workspaceView[payload[0]] = payload[1];
  },
  setWorkspaceViewArticles(state: State, articles: Article[]) {
    state.workspaceView.articles = articles;
  },
  setWorkspaceViewSelectedArticle(state: State, article: Article) {
    state.workspaceView.selectedArticle = article;
  },
  setWorkspaceViewIsSharedMode(state: State, value: boolean) {
    state.workspaceView.isSharedMode = value;
  },
  setWorkspaceViewIsLoading(state: State, value: boolean) {
    state.workspaceView.isLoading = value;
  },
  setWorkspaceOrder(state: State, payload: any): void {
    state.workspaceView.workspace.order = payload;
  },

  resetWorkspaceView(state: State) {
    state.workspaceView.isLoading = false;
    state.workspaceView.isSharedMode = false;
    state.workspaceView.workspace = null;
    state.workspaceView.articles = [];
    state.workspaceView.selectedParagraph = null;
    state.workspaceView.selectedArticle = null;
    state.workspaceView.paragraphProgress = 0;
  },

  setReorderMode(state: any, value: boolean): void {
    state.isReorderMode = value;
  },
};
