import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { Article } from '@/models/article/Article';
import router from '@/router';
import {
  ARTICLE_CREATE,
  ARTICLE_EDIT,
  BROWSE,
  BROWSE_PARAGRAPH,
  BROWSE_SHARED,
  BROWSE_TEXT_TAB,
  DRAFT_EDIT,
} from '@/routerNames';
import apiService from '@/services/ApiService';
import articleEditService from '@/services/article/ArticleEditService';
import socketService from '@/services/socket/SocketService';
import store from '@/store';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { dispatchGetPersonalArticlesCount } from '@/store/dispatchers/uiDispatchers';
import { dispatchOpenSelectedArticle } from '@/store/dispatchers/workspaceViewDispatchers';
import { Loading } from 'element-ui';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { Route } from 'vue-router/types/router';
import ffmpegService from '../FFmpegService';

export class ArticleService {
  getSelectedArticleId(): string {
    if (store.state.workspaceView.selectedArticle) {
      return store.state.workspaceView.selectedArticle.id;
    }

    const article = articleEditService.articleEditor.article;
    return article.original || article.id;
  }

  async duplicateArticle(articleId: string): Promise<void> {
    const { workspaceView } = store.state;
    const res = await apiService.article.duplicate(articleId);
    const { article, workspaceOrder } = res.data;

    const duplicatedArticle = Article.fromJson(article);

    workspaceView.articles.push(duplicatedArticle);
    workspaceView.workspace.order = workspaceOrder;
    await dispatchGetPersonalArticlesCount();
    await dispatchOpenSelectedArticle({ article: duplicatedArticle });
  }

  async exportArticleToPdf(articleId: string, textTabId?: string) {
    await socketService.article.pdf.exportArticlePdf(articleId, textTabId);
  }

  async toggleArticleMode(_mode: ArticleEditMode, route: Route) {
    const mode = _mode === ArticleEditMode.NOTES ? ArticleEditMode.NOTES : ArticleEditMode.VIDEO;
    switch (route.name) {
      case DRAFT_EDIT: {
        await dispatchUpdateSelectedTextTab(null);
        await router.push({ name: DRAFT_EDIT, params: { mode } });
        break;
      }
      case ARTICLE_EDIT: {
        await dispatchUpdateSelectedTextTab(null);
        await router.push({ name: ARTICLE_EDIT, params: { mode } });
        break;
      }
      case BROWSE:
      case BROWSE_TEXT_TAB:
      case BROWSE_PARAGRAPH: {
        const isTextMode = _mode === ArticleEditMode.NOTES;
        const params: { mode: ArticleEditMode; paragraphSlug?: string; textTabSlug?: string } = {
          ...route.params,
          mode,
        };

        if (isTextMode) {
          delete params.paragraphSlug;
        } else {
          delete params.textTabSlug;
          await dispatchUpdateSelectedTextTab(null);
        }

        await router.push({ name: isTextMode ? BROWSE : route.name, params });

        break;
      }
      case ARTICLE_CREATE:
      case BROWSE_SHARED: {
        await dispatchUpdateSelectedTextTab(null);
        commitSetArticleMode(mode);
        break;
      }
      default: {
        throw new Error(`can not toggle article mode on this route: ${route.name}`);
      }
    }
  }

  async downloadArticleVideo(article: Article) {
    const loader = Loading.service({
      lock: true,
      text: 'Preparing videos',
    }) as ElLoadingComponent & { text: string };

    let urls: string[] = [];

    try {
      const videoUrlsRes = await apiService.article.getVideoUrls(article.id);
      urls = videoUrlsRes.data.urls;
    } catch (err: unknown) {
      await apiService.handleResponseError(err);
      loader.close();
    }

    if (!urls.length) {
      loader.close();
      throw new Error('No videos found for this route');
    }

    let blob: any = null;

    try {
      // Download videos and merge videos into single video
      blob = await ffmpegService.mergeVideos(urls, (p) => {
        const percent = p.percent.toFixed(0);
        loader.text = `${p.stage} ${p.currentFile}/${p.totalFiles}: ${percent}%`;
      });
    } catch (err: unknown) {
      loader.close();
      await apiService.handleResponseError(err);
    }

    if (!blob) {
      loader.close();
      throw new Error('No blob found');
    }

    try {
      // Download merged video
      const a = document.createElement('a');
      document.body.appendChild(a);
      const blobUrl = window.URL.createObjectURL(blob);
      a.href = blobUrl;
      a.download = `${article.slug}.mp4`;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      }, 0);
    } catch (err: unknown) {
      await apiService.handleResponseError(err);
    } finally {
      loader.close();
    }
  }
}

const articleService = new ArticleService();
export default articleService;
