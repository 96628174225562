import { Plugin, PluginKey } from 'prosemirror-state';

const EditorContentPlugin = new Plugin({
  key: new PluginKey('EditorContent'),
  props: {
    attributes: {
      spellcheck: 'false',
      translate: 'no',
    },
  },
});

export default EditorContentPlugin;
