import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios from 'axios';

export class TagsServiceProvider extends ApiBaseServiceProvider {
  scope = 'tags';

  find(q: any) {
    return axios.get(this.getBaseUrl() + q, this.getRequestConfig());
  }
}
