import { SocketMessageType } from '@/@types/enums/SocketMessageType';
import { SocketBaseServiceProvider } from '@/services/socket/SocketBaseServiceProvider';

export class ArticleAiImageSocketAction extends SocketBaseServiceProvider {
  private aiImageGenerationSocket: WebSocket | null = null;
  generateAiImage = async (prompt: string, style?: string): Promise<string> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const generateAiImagePromise = new Promise<'' | void>((resolve, reject) => {
        this.aiImageGenerationSocket = new WebSocket(`${this.getSocketBaseUrl()}/ai-image?token=${this.token()}`);
        // WebSocket event listeners
        this.aiImageGenerationSocket.onopen = () => {
          this.aiImageGenerationSocket?.send(JSON.stringify({ type: SocketMessageType.START, prompt, style }));
        };
        this.aiImageGenerationSocket.onmessage = (event) => {
          if (event.data === SocketMessageType.PING) return this.aiImageGenerationSocket?.send(SocketMessageType.PONG);
          const message = JSON.parse(event.data);
          switch (message.type) {
            case SocketMessageType.RESULT:
              this.aiImageGenerationSocket.close(3001);
              resolve(message.image);
              break;
          }
        };
        this.aiImageGenerationSocket.onclose = (event) => {
          const { code } = event;
          const handler = this.messageTypeHandler[code];
          if (!handler) return;

          reject({ type: handler.type, message: event.reason });
        };
        this.aiImageGenerationSocket.onerror = (error) => {
          reject({ type: error.type, message: 'Failed to generate AI image' });
        };
      });
      return (await generateAiImagePromise) || '';
    } catch (err) {
      throw err;
    }
  };
}
