import { TableHeader as TiptapTableHeader } from '@tiptap/extension-table-header';

const TableHeader = TiptapTableHeader.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : {}),
      style: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('colwidth');
        },
        renderHTML: (attributes) => {
          return {
            colspan: attributes.colspan,
            rowspan: attributes.rowspan,
            colwidth: attributes.colwidth,
            style: attributes.style ? `width: ${attributes.style}px` : null,
          };
        },
      },
    };
  },
});

export default TableHeader;
