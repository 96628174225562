import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios from 'axios';

export class BrowseServiceProvider extends ApiBaseServiceProvider {
  scope = 'browse';

  getWorkspace(shortId: string) {
    return axios.get(this.getBaseUrl() + shortId, this.getRequestConfig());
  }

  getSharedArticle(token: string) {
    return axios.get(`${this.getBaseUrl()}shared/${token}`, this.getRequestConfig());
  }

  getPrivatelySharedArticle(token: string) {
    return axios.get(`${this.getBaseUrl()}shared/${token}/private`, this.getRequestConfig());
  }

  getSharedArticleCompanyLogo(articleId: string) {
    return axios.get<{ logo: string | null }>(
      `${this.getBaseUrl()}shared/company/logo/${articleId}`,
      this.getRequestConfig()
    );
  }
}
