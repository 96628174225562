import { AiImageStyleOption, AiWriterOption } from '@/components/tiptap/types/ai';

export const HEADER_HEIGHT = 60;
export const SEARCH_TEXT_HIGHLIGHT_COLOR = '#FFBF00E5';
export const PERSONAL_WORKSPACE_TITLE = 'Personal';

// TipTap AI constants

export const tones: AiWriterOption[] = [
  { name: 'academic', label: 'Academic', value: 'academic' },
  { name: 'business', label: 'Business', value: 'business' },
  { name: 'casual', label: 'Casual', value: 'casual' },
  { name: 'childfriendly', label: 'Childfriendly', value: 'childfriendly' },
  { name: 'conversational', label: 'Conversational', value: 'conversational' },
  { name: 'emotional', label: 'Emotional', value: 'emotional' },
  { name: 'humorous', label: 'Humorous', value: 'humorous' },
  { name: 'informative', label: 'Informative', value: 'informative' },
  { name: 'inspirational', label: 'Inspirational', value: 'inspirational' },
  { name: 'memeify', label: 'Memeify', value: 'meme' },
  { name: 'narrative', label: 'Narrative', value: 'narrative' },
  { name: 'objective', label: 'Objective', value: 'objective' },
  { name: 'persuasive', label: 'Persuasive', value: 'persuasive' },
  { name: 'poetic', label: 'Poetic', value: 'poetic' },
];

export const imageStyles: AiImageStyleOption[] = [
  { name: 'photorealistic', label: 'Photorealistic', value: 'photorealistic' },
  { name: 'digital-art', label: 'Digital art', value: 'digital_art' },
  { name: 'comic-book', label: 'Comic book', value: 'comic_book' },
  { name: 'neon-punk', label: 'Neon punk', value: 'neon_punk' },
  { name: 'isometric', label: 'Isometric', value: 'isometric' },
  { name: 'line-art', label: 'Line art', value: 'line_art' },
  { name: '3d-model', label: '3D model', value: '3d_model' },
];

export const translateOptions: AiWriterOption[] = [
  { name: 'arabic', label: 'Arabic', value: 'ar' },
  { name: 'chinese', label: 'Chinese', value: 'zh' },
  { name: 'english', label: 'English', value: 'en' },
  { name: 'french', label: 'French', value: 'fr' },
  { name: 'german', label: 'German', value: 'de' },
  { name: 'greek', label: 'Greek', value: 'el' },
  { name: 'italian', label: 'Italian', value: 'it' },
  { name: 'japanese', label: 'Japanese', value: 'ja' },
  { name: 'korean', label: 'Korean', value: 'ko' },
  { name: 'spanish', label: 'Spanish', value: 'es' },
  { name: 'ukrainian', label: 'Ukrainian', value: 'uk' },
];
