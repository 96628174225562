var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"toggle-mode-button",class:{
    active: _vm.isActive,
    'with-add-icon': !_vm.isHasContent,
  }},[_c('md-button',{staticClass:"toggle-mode-button__button",class:{
      active: _vm.isActive,
      'show-after-border': !_vm.isEditPage && !_vm.article.textTabs.length,
      'show-before-border': !_vm.isEditPage,
    },on:{"click":_vm.handleClick}},[_c('span',{staticClass:"toggle-mode-button__icon-wrapper"},[_c('span',{staticClass:"toggle-mode-button__icon",class:{
          [_vm.iconClass]: true,
          'is-active': _vm.isActive,
          'with-add-icon': !_vm.isHasContent,
        }})]),_c('span',{staticClass:"toggle-mode-button__title"},[_vm._v(" "+_vm._s(_vm.isHasContent ? _vm.title : _vm.titleNoContent)+" ")])]),(_vm.isEditPage && _vm.isArticleHasVideo)?[_c('md-menu',{attrs:{"md-align-trigger":"","md-direction":"bottom-start","md-offset-y":44,"md-offset-x":-160}},[_c('md-button',{staticClass:"more-button not-draggable",attrs:{"md-menu-trigger":""}},[_c('span',{staticClass:"more-vert"})]),_c('md-menu-content',{staticClass:"selection-menu article-mode-button-menu"},[_c('md-menu-item',{on:{"click":_vm.handleClickDelete}},[_c('span',{staticClass:"selection-menu-icon article-mode-button-menu-icon delete"}),_c('div',[_vm._v("Delete")])])],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }