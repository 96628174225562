
import VueApp from '@/@types/app/VueApp';
import { Component, Watch } from 'vue-property-decorator';
import { HOME } from '@/routerNames';
import { User } from '@/models/User';
import { Company } from '@/models/Company';
import './CompanyDeactivated.scss';

@Component({})
export default class CompanyDeactivated extends VueApp {
  get company(): Company {
    return this.$store.state.company;
  }

  get user(): User {
    return this.$store.state.user;
  }

  get isCompanyActivated() {
    return this.company.isActivated;
  }

  created() {
    if (this.isCompanyActivated) {
      this.$router.push({ name: HOME });
    }
  }

  @Watch('company')
  onCompanyActivated() {
    if (this.company && this.isCompanyActivated) {
      this.$router.push({ name: HOME });
    }
  }
}
