import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { SaveOptions } from '@/@types/SaveOptions';
import { SmartParagraph } from '@/@types/SmartParagraph';
import { ArticleVideoEditorState } from '@/components/article-editor/types/ArticleVideoEditorState';
import { ScreenRecorderSaveT } from '@/components/screen-recorder/types';
import EventBus from '@/EventBus';
import helper from '@/helper';
import { Article } from '@/models/article/Article';
import { Paragraph } from '@/models/article/Paragraph';
import { PlayMap, PlayMapItem } from '@/models/article/PlayMap';
import { Range } from '@/models/article/Range';
import { Workspace } from '@/models/Workspace';
import { Logger } from '@/other/Logger';
import apiService from '@/services/ApiService';
import authService from '@/services/AuthService';
import confirmationService from '@/services/ConfirmationService';
import draftService from '@/services/draft/DraftService';
import notificationService from '@/services/NotificationService';
import articlePlayerProvider from '@/services/ui-providers/ArticlePlayerProvider';
import {
  commitSetArticleEditorArticle,
  commitSetArticleEditorArticleProps,
} from '@/store/commits/articleEditorCommits';
import { dispatchGetDraftsCount } from '@/store/dispatchers/uiDispatchers';
import { Mutations } from '@/store/mutations';
import { State } from '@/store/state';
import { ArticleEditorState } from '@/store/state/ArticleEditorState';
import { Store } from 'vuex';
import { workspaceService } from '../workspace/WorkspaceService';

const log = new Logger('ArticleEditService');

export class ArticleEditService {
  public store: Store<State>;

  setStore = (store: Store<State>) => {
    this.store = store;
  };

  get articleEditor(): ArticleEditorState {
    return this.store.state.articleEditor;
  }

  get articleWorkspace(): Workspace | null {
    const id = this.articleEditor.article && this.articleEditor.article.workspace;
    return this.articleEditor.workspaces.find((item: any) => item.id === id) ?? null;
  }

  get videoPlayerRef() {
    return this.store.state.videoPlayerRef;
  }

  updatePlayer() {
    articlePlayerProvider.videoPlayer.update(true);
  }

  async stopVideo() {
    return articlePlayerProvider.videoPlayer.stop();
  }

  // set position of the video playback
  setPosition(position: number) {
    articlePlayerProvider.videoPlayer.setTime(position);
  }

  setState(property: string, value: any) {
    this.store.commit(Mutations.SET_ARTICLE_EDITOR, [property, value]);
  }

  // use this field for reset input
  handleVideoTimeUpdate(ms: number) {
    this.setState('currentTime', ms);
    // @TODO refactor to player register logic fully
    articlePlayerProvider.videoPlayer.currentTime = ms;
  }

  handlePlayerState(state: any) {
    this.setState('playerState', state);
  }

  handleCancelRecording() {
    this.store.commit('setArticleEditorState', ArticleVideoEditorState.cancel);
  }

  handlePlayParagraph(item: Paragraph): void {
    workspaceService.onCloseMobileSidebar();
    articlePlayerProvider.videoPlayer.playParagraph(item);
  }

  handleParagraphPlaying({ paragraph }: { paragraph: Paragraph; progress: number }): void {
    this.store.commit('setArticleEditorSelectedParagraph', paragraph);
  }

  handleUpdateParagraphs(paragraphs: any): void {
    this.pushUndoArticleStack();
    this.articleEditor.article.paragraphs = paragraphs;

    window.setTimeout(() => {
      articlePlayerProvider.videoPlayer.update(true);
    }, 0);
  }

  handlePlayedRange(range: Range): void {
    // controller started play new range
    const paragraph = this.articleEditor.article.findParagraphByMs(range.start);
    if (paragraph !== this.articleEditor.selectedParagraph) {
      this.store.commit('setArticleEditorSelectedParagraph', paragraph);
    }
  }

  handleSelectParagraph(paragraph: Paragraph, autoplay = false) {
    if (autoplay) {
      if (this.articleEditor.selectedParagraph !== paragraph) {
        this.store.commit('setArticleEditorSelectedParagraph', paragraph);
      }
      this.handlePlayParagraph(paragraph);
    } else {
      this.stopVideo().then(() => {
        this.articleEditor.selectedParagraph = paragraph;
        this.setPosition(this.articleEditor.selectedParagraph ? this.articleEditor.selectedParagraph.position : 0);
      });
    }
  }

  checkParagraphOverlapping(left: Paragraph, right: Paragraph, diff: number): number {
    const tempSpace = 200;
    if (diff < 0 && left.duration - Math.abs(diff - tempSpace) <= 0) {
      diff += tempSpace;
    }
    if (diff > 0 && Math.abs(diff + tempSpace) >= right.duration) {
      diff -= tempSpace;
    }

    return diff;
  }

  handleMoveParagraph(data: any) {
    const paragraph: Paragraph = data.paragraph;
    const list = this.articleEditor.article.getParagraphsList();
    const index = list.indexOf(paragraph);
    let diff: number = data.diff;
    if (index <= 0 || diff === 0) {
      return false;
    }

    this.pushUndoArticleStack();
    const left = list[index - 1];
    const right = paragraph;

    diff = this.checkParagraphOverlapping(left, right, diff);

    const pm = PlayMap.fromParagraph(left);
    const pm2 = PlayMap.fromParagraph(right);

    if (diff > 0) {
      // paragraph moved to the right
      // need to join to previous
      const range = new Range(0, diff);
      const part = pm2.copy(range);
      const tmp = pm2.copy(new Range(range.end, right.duration));
      pm2.data = tmp.data;
      pm2.videos = tmp.videos;

      pm.insertMultiple(part.data, left.duration).addVideos(part.videos);
    } else {
      // paragraph moved to the left
      diff = Math.abs(diff);
      const range = new Range(left.duration - diff, left.duration);
      const part = pm.copy(range);
      const tmp = pm.copy(new Range(0, range.start));
      pm.data = tmp.data;
      pm.videos = tmp.videos;

      part.insertMultiple(pm2.data, diff).addVideos(pm2.videos);
      pm2.data = part.data;
      pm2.videos = part.videos;
      diff = -diff;
    }

    // normalize play maps
    pm.normalize();
    pm2.normalize();

    // assign to paragraphs
    left.pm = pm.data;
    left.videos = pm.videos;
    right.pm = pm2.data;
    right.videos = pm2.videos;
    left.duration += diff;
    right.duration -= diff;

    helper.observerNotify(this.articleEditor.article.paragraphs);

    window.setTimeout(() => {
      articlePlayerProvider.videoPlayer.update(true);
    }, 0);
  }

  handleMergeParagraph(item: Paragraph) {
    const list = this.articleEditor.article.getParagraphsList();
    const index = list.indexOf(item);
    if (index >= 1) {
      const prev = list[index - 1];

      this.mergeParagraphs(prev, item);
    } else {
      notificationService.warning('This paragraph can not be joined to previous');
    }
  }

  mergeParagraphs(left: Paragraph, right: Paragraph) {
    if (right.items.length) {
      notificationService.warning('Paragraph "' + right.title + '" cannot be joined because has a sub-paragraphs');
      return false;
    }

    this.pushUndoArticleStack();

    const pm = PlayMap.fromParagraph(left);
    const pm2 = PlayMap.fromParagraph(right);
    pm.insertMultiple(pm2.data, left.duration - 1).addVideos(pm2.videos);

    left.pm = pm.data;
    left.videos = pm.videos;
    left.duration = left.duration + right.duration;

    this.articleEditor.article.removeParagraph(right);
    helper.observerNotify(this.articleEditor.article.paragraphs);
    window.setTimeout(() => {
      articlePlayerProvider.videoPlayer?.update(true);
    }, 0);
  }

  handleRemoveParagraph(item: Paragraph) {
    this.store.commit('setArticleEditorSelectedParagraph', item);
    confirmationService
      .confirm(
        "You're about to remove '" + item.title + "'and its part of the recording. You may consider merging instead.",
        'Are you sure?',
        {
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
      .then(() => {
        this.pushUndoArticleStack();

        this.store.commit('setArticleEditorSelectedParagraph', null);
        this.articleEditor.article.removeParagraph(item);
        helper.observerNotify(this.articleEditor.article.paragraphs);

        window.setTimeout(() => {
          articlePlayerProvider.videoPlayer?.update(true);
        }, 0);
      })
      .catch((err) => {
        log.error(err);
      });
  }

  async handleRecordedVideo(data: ScreenRecorderSaveT): Promise<void> {
    const video = data.video;
    let keyframes = data.keyframes;

    keyframes.push(0);
    keyframes = keyframes
      .filter((value: any, index: number, self: any) => {
        return self.indexOf(value) === index;
      })
      .sort((a: number, b: number) => a - b);

    // video destination algorithm
    if (
      [ArticleVideoEditorState.recording, ArticleVideoEditorState.recorded, ArticleVideoEditorState.inserting].includes(
        this.articleEditor.state
      )
    ) {
      // current article duration
      const pos = this.articleEditor.article.calculateDuration();
      this.insertVideoAtPosition(video, pos, keyframes);
    }

    // move back to normal state
    this.store.commit('setArticleEditorState', ArticleVideoEditorState.default);
  }

  insertVideoAtPosition(video: any, position: number, keyframes: any[]) {
    if (!keyframes) {
      keyframes = [];
    }
    const duration = video.duration;

    const paragraphs = Paragraph.createFromKeyframes(keyframes, duration, position);

    if (paragraphs.length) {
      let prevEnd = 0;
      for (const p of paragraphs) {
        const pm = new PlayMap([new PlayMapItem(0, prevEnd, video.id)], [video]);
        p.pm = pm.data;
        p.videos = pm.videos;
        this.articleEditor.article.paragraphs.push(p);
        prevEnd += p.duration;
      }
    }
    this.articleEditor.article.duration = this.articleEditor.article.calculateDuration();

    window.setTimeout(() => {
      if (articlePlayerProvider.videoPlayer) articlePlayerProvider.videoPlayer.update();
    }, 0);
  }

  async handleInsertParagraph({
    withNotifications = true,
    smartParagraph,
    saveArticle = true,
    updatePlayer = true,
  }: {
    withNotifications?: boolean;
    smartParagraph?: SmartParagraph;
    saveArticle?: boolean;
    updatePlayer?: boolean;
  } = {}) {
    const time = Math.floor(smartParagraph?.start * 1000 || this.articleEditor.currentTime);
    return this.stopVideo()
      .then(() => {
        // validate new paragraph position
        let allowAdd = true;
        const map = this.articleEditor.article.getParagraphsOffsetMap();
        let delta = this.articleEditor.article.duration / 100;
        if (delta > 400) {
          delta = 400;
        }
        for (const key of map.keys()) {
          const pos = map.get(key);
          if (Math.abs(pos - time) <= delta) {
            allowAdd = false;
            break;
          }
        }

        if (!allowAdd) {
          if (withNotifications) {
            notificationService.warning('You can not add paragraph at this position. Too close to another paragraph.');
          }
          return false;
        }

        if (saveArticle) {
          this.pushUndoArticleStack();
        }

        const paragraph = Paragraph.parse(new Paragraph());
        paragraph.title = smartParagraph?.title || this.prepareNewParagraphTitle(this.articleEditor.article);

        return this.insertParagraph(paragraph.title, paragraph.tags, time, false, updatePlayer);
      })
      .catch((error: any) => apiService.handleResponseError(error));
  }

  prepareNewParagraphTitle(article: Article) {
    const paragraphs = article.getParagraphsList();
    let max = 0;

    for (const paragraph of paragraphs) {
      const result = paragraph.title.match(/.*#(\d+)/i);

      if (result) {
        const paragraphNumber = parseInt(result[1]);
        if (paragraphNumber > max) {
          max = paragraphNumber;
        }
      }
    }

    return `Paragraph #${max + 1}`;
  }

  insertParagraph(title: string, tags: string[], ms: number, withNotifications = true, updatePlayer = true) {
    const p = this.articleEditor.article.findParagraphByMs(ms);
    const result = this.articleEditor.article.findParagraph(p);
    const key = (result && result.key) || null;
    let { parent, list } = result.hasResult() ? result : { parent: null, list: null };

    if (!parent) {
      parent = this.articleEditor.article;
      list = this.articleEditor.article.paragraphs;
    }
    const listKey = helper.getItemsKey(parent);

    if (listKey) {
      // insert new paragraph after key
      const paragraphs = [];
      let added = false;
      const newP = Paragraph.parse({
        title,
        tags,
        duration: 0,
      });
      if (list.length) {
        for (const i in list) {
          if (parseInt(i) >= parseInt(key) && !added) {
            const oldP = list[key];
            const position = this.articleEditor.article.getParagraphPosition(oldP);

            newP.duration = position + oldP.duration - ms;
            oldP.duration = ms - position;

            const playMap = PlayMap.fromParagraph(oldP);
            const newPm = playMap.copy(new Range(oldP.duration, oldP.duration + newP.duration));
            const oldPm = playMap.copy(new Range(0, oldP.duration));

            newP.pm = newPm.data;
            newP.videos = newPm.videos;
            oldP.pm = oldPm.data;
            oldP.videos = oldPm.videos;

            paragraphs.push(oldP);
            if (!oldP.items.length) {
              // add next if no child
              paragraphs.push(newP);
            } else {
              // paragraph has child
              oldP.items.splice(0, 0, newP);
            }

            added = true;
          } else {
            paragraphs.push(list[i]);
          }
        }
      }
      if (!added) {
        if (withNotifications) notificationService.warning('Paragraph cannot be added at this position');
        return false;
      }
      // update paragraphs of parent object
      parent[listKey] = paragraphs;
      helper.observerNotify(this.articleEditor.article.paragraphs);
      this.articleEditor.selectedParagraph = this.articleEditor.article.findParagraphByMs(ms);
      if (updatePlayer) {
        window.setTimeout(() => {
          articlePlayerProvider.videoPlayer.update(true);
          this.setPosition(ms);
        }, 0);
      }
    } else {
      if (withNotifications) notificationService.error('Paragraph can not be added');
    }
  }

  handleTrim(range: Range): void {
    let pos = 0;
    this.articleEditor.article.eachParagraph((p: Paragraph) => {
      const pr = new Range(pos, pos + p.duration);
      pos += p.duration;

      if (range.overlapWith(pr)) {
        // trim paragraph if ranges overlap detected
        const pm = PlayMap.fromParagraph(p);
        const playMap = new PlayMap([], pm.videos);
        let playMapPos = 0;
        let r = null;
        let cp = null;
        if (pr.start < range.start) {
          r = new Range(pr.start, range.start).shift(-pr.start);
          cp = pm.copy(r);
          playMap.insertMultiple(cp.data, playMapPos);
          playMapPos = r.length();
        }
        if (pr.end > range.end) {
          r = new Range(range.end, pr.end).shift(-pr.start);
          cp = pm.copy(r);
          playMap.insertMultiple(cp.data, playMapPos);
          playMapPos += r.length();
        }

        p.pm = playMap.data;
        p.duration = playMapPos;
      }
    });

    // trigger update
    this.articleEditor.article.duration = this.articleEditor.article.calculateDuration();
    this.articleEditor.article.clearfixParagraphs();
    helper.observerNotify(this.articleEditor.article.paragraphs);

    window.setTimeout(() => {
      articlePlayerProvider.videoPlayer
        .setControllerParagraphs(this.articleEditor.article.getParagraphsList(), false, range.start)
        .then(() => {
          articlePlayerProvider.videoPlayer.updateTimeline().then(null);
        });

      articlePlayerProvider.videoPlayer
        .setControllerParagraphs(this.articleEditor.article.getParagraphsList(), false, range.start)
        .then(() => {
          articlePlayerProvider.videoPlayer.updateTimeline().then(null);
        });
    }, 0);
  }

  handleVideoDurationDetected(data: any) {
    const { video, duration } = data;
    this.articleEditor.article.updateVideoDuration(video, duration);
    helper.observerNotify(this.articleEditor.article.paragraphs);
  }

  findNestedArticles(order: any, articleId: string) {
    let rootArticle = null;
    let articlesForDelete: string[] = [];

    for (const obj of order) {
      rootArticle = this.getRootArticle(obj, articleId);
      if (rootArticle) {
        articlesForDelete.push(rootArticle.value);

        if (rootArticle.items && rootArticle.items.length > 0) {
          articlesForDelete = helper.findAllByKey(rootArticle, 'value');
        }
        break;
      }
    }
    return articlesForDelete;
  }

  getRootArticle(order: any, articleId: string): any {
    if (order.value === articleId) {
      return order;
    }
    if (order.items) {
      for (const item of order.items) {
        const check = this.getRootArticle(item, articleId);
        if (check) {
          return check;
        }
      }
    }
    return null;
  }

  generateTree(data: any = null): any {
    const tree = [];
    let items = data || [];
    if (data === null && this.articleEditor && this.articleEditor.article) {
      items = this.articleEditor.article.paragraphs || [];
    }
    if (items && items.length) {
      for (const key in items) {
        // eslint-disable-next-line no-prototype-builtins
        if (items.hasOwnProperty(key)) {
          tree.push({
            title: items[key].title,
            isLeaf: false,
            isExpanded: items[key].isExpanded,
            isSelected:
              this.articleEditor.selectedParagraph && this.articleEditor.selectedParagraph.key === items[key].key,
            data: { ...Paragraph.parse(items[key]), isEdit: false },
            children: items[key].items ? this.generateTree(items[key].items) : [],
          });
        }
      }
    }
    return tree;
  }

  save(options: SaveOptions) {
    const {
      updatedByUser,
      isPublish = false,
      isKeepDraft = false,
      isNotification = false,
      skipEditor = false,
    } = options;

    const axiosPromise =
      this.articleEditor.article?.draft && !this.articleEditor.article.original
        ? apiService.draft.save(this.articleEditor.article, { updatedByUser, isPublish, isNotification })
        : apiService.article.save(this.articleEditor.article, {
            updatedByUser,
            isPublish,
            isKeepDraft,
            isNotification,
          });

    axiosPromise.then((response) => {
      const article = Article.fromJson(response.data.article);
      const props: Partial<Article> = {
        id: article.id,
        draft: article.draft,
        slug: article.slug,
        original: article.original,
        paragraphs: article.paragraphs,
        textTabs: article.textTabs,
        createdAt: article.createdAt,
        updatedAt: article.updatedAt,
        updatedByUser: article.updatedByUser,
        user: article.user,
      };

      if (!skipEditor) commitSetArticleEditorArticleProps(props);

      if (!isPublish) {
        draftService.loadDraftHash(this.articleEditor.article.id);
      }

      return response;
    });

    return axiosPromise;
  }

  pushUndoArticleStack() {
    const savedArticle = Article.fromJson(JSON.parse(JSON.stringify(this.articleEditor.article))) as Article;
    EventBus.$emit('on-paragraphs-changes', savedArticle);
  }

  async saveAsDraft(skipEditor = false) {
    const userId = authService.user.id;
    try {
      const res = await this.save({ updatedByUser: userId, isPublish: false, skipEditor });
      if (skipEditor) return;

      const draftArticle = Article.fromJson(res.data.article);

      commitSetArticleEditorArticle(draftArticle);

      if (this.store.state.articleMode === ArticleEditMode.VIDEO) {
        window.setTimeout(() => {
          articlePlayerProvider.videoPlayer?.update(true);
        }, 0);
      }

      await dispatchGetDraftsCount();
    } catch (err) {
      apiService.handleResponseError(err);
    }
  }

  getPathToRootArticle(order: any, id: any) {
    let path: any;
    const item: any = { value: order.value };

    if (!order || typeof order !== 'object') return;

    if (order.value === id) return [item];

    (order.items || []).some((child: any) => (path = this.getPathToRootArticle(child, id)));
    return path && [item, ...path];
  }

  findArticlesToExpand(order: any, id: any) {
    let result: any = null;
    order.forEach((el: any) => {
      const tempPath = this.getPathToRootArticle(el, id);
      if (tempPath) {
        result = [...tempPath];
      }
    });
    return (result && result.map((el: any) => el.value)) || [];
  }
}

const articleEditService = new ArticleEditService();
export default articleEditService;
