
import VueApp from '@/@types/app/VueApp';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import * as routerNames from '@/routerNames';
import articleEditService from '@/services/article/ArticleEditService';
import articleService from '@/services/article/ArticleService';
import articlePlayerProvider from '@/services/ui-providers/ArticlePlayerProvider';
import { commitSetVideoPlayerTimeToSet } from '@/store/commits/sharedCommits';
import { Component, Prop } from 'vue-property-decorator';
import { Subtitle } from '@/@types/Subtitle';

@Component
export default class TranscriptionSubtitle extends VueApp {
  @Prop({ type: Object }) subtitle!: Subtitle;

  get articleEditor() {
    return articleEditService.articleEditor;
  }

  get videoPlayerRef() {
    return articlePlayerProvider.videoPlayer;
  }

  get isArticleEditPage() {
    return [
      routerNames.ARTICLE_CREATE,
      routerNames.ARTICLE_EDIT,
      routerNames.ARTICLE_VERSION_EDIT,
      routerNames.DRAFT_EDIT,
    ].includes(this.$route.name);
  }

  formatTime(start: number, end: number): string {
    const format = (milliseconds: number): string => {
      const roundedMillis = Math.round(milliseconds); // Round to the nearest millisecond
      const totalSeconds = Math.floor(roundedMillis / 1000);
      const hours = Math.floor(totalSeconds / 3600)
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((totalSeconds % 3600) / 60)
        .toString()
        .padStart(2, '0');
      const seconds = (totalSeconds % 60).toString().padStart(2, '0');

      if (hours === '00') {
        return `${minutes}:${seconds}`;
      } else {
        return `${hours}:${minutes}:${seconds}`;
      }
    };

    return `${format(start * 1000)} - ${format(end * 1000)}`;
  }

  async onTimeClick(start: number) {
    const millis = start * 1000;

    const article = this.articleEditor.article;
    if (!this.isArticleEditPage || !article || !article.paragraphs.length) return;

    this.$store.commit('transcriptionSidebarSetIsVisible', false);

    await articleService.toggleArticleMode(ArticleEditMode.VIDEO, this.$route);

    if (this.videoPlayerRef) {
      this.videoPlayerRef.setTime(millis);
    }

    commitSetVideoPlayerTimeToSet(millis);
    this.videoPlayerRef.update(true);
  }

  getAriaLabel(): string {
    const timeRange = this.formatTime(this.subtitle.start, this.subtitle.end);
    return `Jump to time ${timeRange}`;
  }
}
