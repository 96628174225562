import { DefaultController } from './DefaultController';
import { Range } from '@/models/article/Range';
import { Paragraph } from '@/models/article/Paragraph';
import { PlayMap } from '@/models/article/PlayMap';
import { Store } from 'vuex';

export class ParagraphPlaylistController extends DefaultController {
  paragraphs: Paragraph[];
  // index: number;
  skipTimeUpdate: boolean;
  store: Store<any>;

  /**
   *
   * @param videoPlayer
   * @param {Paragraph[]} paragraphs
   * @param {boolean} autoplay
   * @param {boolean} savePosition => true|false or number for set new position
   */
  constructor(videoPlayer: any, paragraphs: Paragraph[] = [], autoplay = true, savePosition: boolean | number = false) {
    super(videoPlayer);
    this.name = 'PPC';
    this.vp = videoPlayer;
    this.paragraphs = paragraphs;
    // this.index          = 0;
    this.skipTimeUpdate = true; // need to prevent time update for some cases
    this.store = this.vp.$store;

    window.setTimeout(() => {
      // need to prepare correct paragraph
      this.run(autoplay, savePosition);
    }, 0);
  }

  run(autoplay: boolean, savePosition: boolean | number = false) {
    let index = 0;
    this.skipTimeUpdate = false;

    if (savePosition) {
      index = this.getParagraphIndexByMs(this.vp.currentTime);
      if (index < 0) {
        index = 0;
      }
    }

    if (this.paragraphs[index]) {
      let pos = !savePosition ? this.vp.article.getParagraphPosition(this.paragraphs[index]) : this.vp.currentTime;
      if (typeof savePosition === 'number') {
        pos = savePosition;
      }
      this.vp.setTimeInternal(pos);
      if (autoplay) {
        this.vp.play();
      }
    }
  }

  // external methods which triggered from video player
  onTimeUpdate(ms: number, videoTime: number) {
    if (this.skipTimeUpdate) {
      this.log('Time update ' + ms + ' skipped');
      return false;
    } else {
      this.log('Time update ' + ms + ', vt:' + videoTime);
    }
    const index = this.getParagraphIndexByMs(ms);
    const currentParagraph = this.paragraphs[index];

    if (!currentParagraph) {
      if (ms >= this.vp.article.duration) {
        this.handleEndOfPlaylist();
        this.log('Playlist ended');
        const lastIndex = this.paragraphs.length - 1;
        const lastParagraph = this.paragraphs[lastIndex];
        if (lastIndex >= 0 && lastParagraph) {
          this.vp.currentTime = this.vp.article.getParagraphPosition(lastParagraph) + lastParagraph.duration - 1;
          this.log('Force set cursor position of last paragraph: ' + this.vp.currentTime);
        }
      }
      return false;
    }

    const pos = this.vp.article.getParagraphPosition(currentParagraph);
    const range = new Range(pos, pos + currentParagraph.duration);
    this.log('Playing paragraph ' + index + ' "' + currentParagraph.title + '" [' + range + ']');
    // current time not inside range
    if (!range.contain(ms)) {
      const nextIndex = index + 1;

      if (!this.paragraphs[nextIndex]) {
        // end on playlist detected

        this.handleEndOfPlaylist(true);
      } else {
        this.log('Move to next paragraph: ' + nextIndex);
        // this.index = nextIndex;
        // set video position to next range
        // this.vp.stop();
        this.vp.setTimeInternal(this.vp.article.getParagraphPosition(this.paragraphs[nextIndex]));
        // this.vp.play();
        this.emitParagraphStartedEvent(currentParagraph);
        return false;
      }
    }

    return true;
  }

  onPlay() {
    const index = this.getParagraphIndexByMs(this.vp.currentTime);
    const currentParagraph = this.paragraphs[index];
    if (!this.paragraphs || !this.paragraphs.length) {
      return false;
    } else if (index === this.paragraphs.length - 1) {
      const time = this.vp.currentTime;
      const end = this.vp.article.getParagraphPosition(currentParagraph) + currentParagraph.duration;

      if (time >= end - 300) {
        this.log('Start playing from the first paragraph');
        this.vp.setTimeInternal(this.vp.article.getParagraphPosition(this.paragraphs[0]));
        this.vp.play();

        return false;
      }
    }
  }

  onVideoEnded() {
    const ms = this.vp.currentTime;
    const currentParagraph = this.getParagraphByMs(ms);
    const start = this.vp.article.getParagraphPosition(currentParagraph);
    // let end              = start + currentParagraph.duration;

    const pTime = ms - start;
    // let nextParagraphTime = currentParagraph.findNextPlayMapPoint(pTime);
    const pm = PlayMap.fromParagraph(currentParagraph);
    const nextPoint = pm.findNextVideoPosition(pTime);
    if (nextPoint >= 0) {
      this.log('Video ended. Move to next play map point');
      this.vp.setTimeInternal(nextPoint);
      this.vp.play();
    } else {
      const nextIndex = this.findNextIndex(currentParagraph);
      if (this.paragraphs[nextIndex]) {
        this.log('Video ended. Move to next paragraph');
        // need switch to next range
        const pos = this.vp.article.getParagraphPosition(this.paragraphs[nextIndex]);
        // this.index = nextIndex;

        this.log('Switch to next range: ' + nextIndex);

        this.vp.setTimeInternal(pos);
        this.vp.play();
        this.emitParagraphStartedEvent(this.paragraphs[nextIndex]);
      } else {
        this.log('Video ended.');
        this.handleEndOfPlaylist(true);
      }
    }
  }

  findNextIndex(currentParagraph: Paragraph) {
    let nextIndex = this.paragraphs.indexOf(currentParagraph) + 1;

    while (nextIndex < this.paragraphs.length) {
      if (this.paragraphs[nextIndex].videos.length) {
        return nextIndex;
      }

      nextIndex++;
    }

    return nextIndex;
  }

  handleEndOfPlaylist(triggerEvent = false) {
    this.vp.stop().then(() => {
      this.skipTimeUpdate = true;

      if (triggerEvent) {
        this.log('Handled End Of Playlist');

        // fix end position
        if (this.paragraphs) {
          const lastPar = this.paragraphs[this.paragraphs.length - 1];
          let pos = this.vp.article.getParagraphPosition(lastPar) + lastPar.duration - 1;
          if (pos < 0) {
            pos = 0;
          }
          this.vp.setTimeInternal(pos);
        }
        this.emitEvent('playlistEnded');
      }
      setTimeout(() => {
        this.skipTimeUpdate = false;
      }, 250);
    });
  }

  emitParagraphStartedEvent(p: Paragraph) {
    this.emitEvent('startedPlayRange', p);
  }

  getParagraphIndexByMs(ms: number) {
    let offset = 0;

    for (const p of this.paragraphs) {
      const range = new Range(offset, offset + p.duration);
      if (range.contain(ms)) {
        return this.paragraphs.indexOf(p);
      }
      offset += p.duration;
    }

    return -1;
  }

  getParagraphByMs(ms: number) {
    let offset = 0;

    for (const p of this.paragraphs) {
      const range = new Range(offset, offset + p.duration);
      if (range.contain(ms)) {
        return p;
      }
      offset += p.duration;
    }

    return null;
  }
}
