import logger from '@/other/Logger';
import { createFFmpeg, FFmpeg } from '@ffmpeg/ffmpeg';

interface FFmpegServiceConfig {
  debug?: boolean;
}

interface DownloadProgress {
  loaded: number;
  total: number;
  percent: number;
  fileIndex?: number;
  totalFiles?: number;
}

interface MergeProgress extends DownloadProgress {
  stage: 'Downloading' | 'Processing';
  currentFile: number;
  totalFiles: number;
}

type ProgressCallback = (progress: DownloadProgress) => void;
type MergeProgressCallback = (progress: MergeProgress) => void;

export class FFmpegService {
  private ffmpeg: FFmpeg | null = null;
  private readonly debug: boolean;
  // Mutex flag to ensure that only one ffmpeg command runs at a time.
  private ffmpegBusy = false;

  constructor(config: FFmpegServiceConfig = {}) {
    this.debug = config.debug ?? !!localStorage.getItem('DEBUG');
  }

  public async ensureFFmpegLoaded(): Promise<FFmpeg> {
    if (!this.ffmpeg) {
      this.ffmpeg = createFFmpeg({
        log: this.debug,
        corePath: 'https://unpkg.com/@ffmpeg/core-st/dist/ffmpeg-core.js',
        mainName: 'main',
      });
    }

    if (!this.ffmpeg.isLoaded()) {
      try {
        await this.ffmpeg.load();
      } catch (error) {
        throw new Error(`Failed to load FFmpeg: ${this.getErrorMessage(error)}`);
      }
    }

    return this.ffmpeg;
  }

  // Pre-clean any leftover artifacts in the FFmpeg FS.
  private cleanupArtifacts(ffmpeg: FFmpeg): void {
    try {
      const files = ffmpeg.FS('readdir', '/');
      for (const file of files) {
        if (file !== '.' && file !== '..') {
          if (file === 'manifest.txt' || file === 'output.mp4' || (file.startsWith('video') && file.endsWith('.mp4'))) {
            try {
              ffmpeg.FS('unlink', file);
            } catch (e) {
              logger.error(`Failed to pre-clean file ${file}: ${this.getErrorMessage(e)}`);
            }
          }
        }
      }
    } catch (e) {
      logger.error('Failed to list FS for cleanup: ' + this.getErrorMessage(e));
    }
  }

  // Queue ffmpeg commands to prevent concurrent execution.
  private async runFFmpegCommand(ffmpeg: FFmpeg, args: string[]): Promise<void> {
    while (this.ffmpegBusy) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }
    this.ffmpegBusy = true;
    try {
      await ffmpeg.run(...args);
    } finally {
      this.ffmpegBusy = false;
    }
  }

  private async downloadVideo(
    url: string,
    onProgress?: ProgressCallback,
    fileIndex?: number,
    totalFiles?: number
  ): Promise<Uint8Array> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentLength = Number(response.headers.get('content-length'));
      const reader = response.body?.getReader();

      if (!reader) {
        throw new Error('ReadableStream not supported');
      }

      const chunks: Uint8Array[] = [];
      let receivedLength = 0;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        chunks.push(value);
        receivedLength += value.length;

        if (onProgress && contentLength) {
          onProgress({
            loaded: receivedLength,
            total: contentLength,
            percent: (receivedLength / contentLength) * 100,
            fileIndex,
            totalFiles,
          });
        }
      }

      const result = new Uint8Array(receivedLength);
      let position = 0;
      for (const chunk of chunks) {
        result.set(chunk, position);
        position += chunk.length;
      }

      return result;
    } catch (error) {
      throw new Error(`Failed to download video from ${url}: ${this.getErrorMessage(error)}`);
    }
  }

  private cleanup(fileNames: string[], ffmpeg: FFmpeg): void {
    for (const fileName of fileNames) {
      try {
        ffmpeg.FS('unlink', fileName);
      } catch (error) {
        logger.error(`Failed to cleanup file ${fileName}: ${this.getErrorMessage(error)}`);
      }
    }
  }

  private async createManifest(
    urls: string[],
    ffmpeg: FFmpeg,
    onProgress?: MergeProgressCallback
  ): Promise<{ manifestContent: string; filesToCleanup: string[] }> {
    const filesToCleanup: string[] = [];
    let manifestContent = '';

    for (let i = 0; i < urls.length; i++) {
      const fileName = `video${i}.mp4`;
      const videoData = await this.downloadVideo(
        urls[i],
        (progress) => {
          if (onProgress) {
            onProgress({
              ...progress,
              stage: 'Downloading',
              currentFile: i + 1,
              totalFiles: urls.length,
            });
          }
        },
        i + 1,
        urls.length
      );
      ffmpeg.FS('writeFile', fileName, videoData);
      filesToCleanup.push(fileName);
      // Format each line with quotes.
      manifestContent += `file '${fileName}'\n`;
    }

    return { manifestContent, filesToCleanup };
  }

  public async mergeVideos(urls: string[], onProgress?: MergeProgressCallback): Promise<Blob> {
    if (!urls.length) {
      throw new Error('No videos provided for merging');
    }

    const ffmpeg = await this.ensureFFmpegLoaded();
    // Pre-clean any leftover files from previous runs.
    this.cleanupArtifacts(ffmpeg);
    const filesToCleanup: string[] = [];

    try {
      const { manifestContent, filesToCleanup: videoFiles } = await this.createManifest(urls, ffmpeg, onProgress);
      filesToCleanup.push(...videoFiles);

      const manifestName = 'manifest.txt';
      ffmpeg.FS('writeFile', manifestName, manifestContent);
      filesToCleanup.push(manifestName);

      if (onProgress) {
        onProgress({
          loaded: 0,
          total: 100,
          percent: 0,
          stage: 'Processing',
          currentFile: urls.length,
          totalFiles: urls.length,
        });
      }

      const outputName = 'output.mp4';
      // First, try to merge with stream copy.
      await this.runFFmpegCommand(ffmpeg, ['-f', 'concat', '-safe', '0', '-i', manifestName, '-c', 'copy', outputName]);

      let data: Uint8Array;
      try {
        data = ffmpeg.FS('readFile', outputName);
      } catch (readError) {
        logger.error('Output file not found after stream copy merge. Trying fallback re-encoding...');
        // Fallback: try re-encoding.
        await this.runFFmpegCommand(ffmpeg, [
          '-f',
          'concat',
          '-safe',
          '0',
          '-i',
          manifestName,
          '-c:v',
          'libx264',
          '-preset',
          'veryfast',
          '-crf',
          '23',
          '-c:a',
          'aac',
          '-strict',
          '-2',
          outputName,
        ]);
        try {
          data = ffmpeg.FS('readFile', outputName);
        } catch (fallbackError) {
          throw new Error(
            'No blob found: output file does not exist after fallback. ' + this.getErrorMessage(fallbackError)
          );
        }
      }

      filesToCleanup.push(outputName);

      if (onProgress) {
        onProgress({
          loaded: 100,
          total: 100,
          percent: 100,
          stage: 'Processing',
          currentFile: urls.length,
          totalFiles: urls.length,
        });
      }

      return new Blob([data.buffer], { type: 'video/mp4' });
    } catch (error) {
      throw new Error(`Failed to merge videos: ${this.getErrorMessage(error)}`);
    } finally {
      this.cleanup(filesToCleanup, ffmpeg);
      // Terminate the ffmpeg instance to force a clean state for subsequent merges.
      this.terminate();
    }
  }

  public terminate(): void {
    if (this.ffmpeg) {
      try {
        this.ffmpeg.exit();
        this.ffmpeg = null;
      } catch (error) {
        logger.error(`Error terminating FFmpeg: ${this.getErrorMessage(error)}`);
      }
    }
  }

  private getErrorMessage(error: unknown): string {
    if (error instanceof Error) {
      return error.message;
    }
    try {
      return JSON.stringify(error);
    } catch {
      return String(error);
    }
  }
}

const ffmpegService = new FFmpegService();
export default ffmpegService;
