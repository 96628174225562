
import logger from '@/other/Logger';
import { Component, Prop, Watch } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import { Company } from '@/models/Company';
import AdminDashboardStatItem from '@/components/pages/admin/AdminDashboardStatItem/AdminDashboardStatItem.vue';
import { AdminDashboardStatsData } from '@/@types/AdminDashboardStatsData';
import { User } from '@/models/User';
import adminCompanyService from '@/services/company/AdminCompanyService';

@Component({
  components: { AdminDashboardStatItem },
})
export default class AdminCompanyGeneralSection extends VueApp {
  @Prop({ type: Object, required: true }) company: Company;
  @Prop({ type: Object, required: false }) companyStatsData: AdminDashboardStatsData;
  @Prop({ type: Object, required: false }) companyOwner: User;

  isAiEnabled = false;

  dashboardItems = [
    { type: 'users', category: 'Total Users', icon: 'users', count: 0 },
    { type: 'workspaces', category: 'Total Workspaces', icon: 'workspaces', count: 0 },
    { type: 'articles', category: 'Total Articles', icon: 'articles', count: 0 },
    { type: 'storage', category: 'Storage usage', icon: 'storage', count: '' },
  ];

  getCompanyStorageSize(initialSize: number): string {
    let size: number;

    if (initialSize < 1024 ** 3) {
      // Convert to MB with max 1 decimal place
      size = initialSize / 1024 ** 2;
      return size.toFixed(1) + ' MB';
    } else {
      // Convert to GB without decimal places
      size = initialSize / 1024 ** 3;
      return size.toFixed(0) + ' GB';
    }
  }

  handleAiStatusChange() {
    adminCompanyService.changeCompanyAiStatus(this.company, this.isAiEnabled);
    this.company.isAiEnabled = this.isAiEnabled;
  }

  init() {
    if (!this.companyStatsData) {
      logger.info('companyStatsData is null or undefined');
      return;
    }

    this.dashboardItems.forEach((item) => {
      const value = this.companyStatsData[item.type as keyof AdminDashboardStatsData];
      item.count = value ?? 0; // Default to 0 if undefined

      if (item.type === 'storage') {
        item.count = this.getCompanyStorageSize(value ?? 0);
      }
    });

    this.isAiEnabled = this.company.isAiEnabled;
  }

  created() {
    this.init();
  }

  @Watch('companyStatsData')
  onCompanyStatsDataChanged() {
    this.init();
  }
}
