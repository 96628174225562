import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import SlashList from '@/components/tiptap/extensions/SlashCommand/SlashList.vue';
import { SuggestionEventProps } from '@/components/tiptap/extensions/Mention/SuggestionEventProps';

export default {
  render: () => {
    let component: VueRenderer;
    let popup: any;

    return {
      onStart: (props: SuggestionEventProps) => {
        component = new VueRenderer(SlashList, {
          parent: this,
          propsData: props,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props: SuggestionEventProps) {
        component.updateProps(props);

        if (!props.clientRect) return;

        popup[0].setProps({ getReferenceClientRect: props.clientRect });
      },

      onKeyDown(props: SuggestionEventProps) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return (component.ref as SlashList)?.onKeyDown(props);
      },

      onExit() {
        if (popup[0]) {
          popup[0].destroy();
        }
        if (component) {
          component.destroy();
        }
      },
    };
  },
};
