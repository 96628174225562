import appStore from '@/store';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { Article } from '@/models/article/Article';
import { TextTab } from '@/models/article/TextTab';

export const dispatchOpenArticleEditor = (article: Article, mode?: ArticleEditMode) => {
  const _mode = mode || (article.isTextArticle() ? ArticleEditMode.NOTES : ArticleEditMode.VIDEO);
  return appStore.dispatch('openArticleEditor', { articleId: article.id, mode: _mode });
};

export const dispatchAutosaveArticle = () => {
  return appStore.dispatch('articleEditorAutosaveArticle');
};

export const dispatchSaveArticle = (isPublish: boolean, isNotification: boolean) => {
  return appStore.dispatch('articleEditorSaveArticle', { isPublish, isNotification });
};

export const dispatchUpdateSelectedTextTab = (textTab: TextTab | null) => {
  return appStore.dispatch('articleEditorUpdateSelectedTextTab', textTab);
};
