import axios from 'axios';

import { SocketActionType } from '@/@types/enums/SocketActionType';
import { SocketArticleStatusResponse } from '@/@types/responses/SocketStatusResponse';
import { ApiBaseServiceProvider } from '@/services/api-providers/ApiBaseServiceProvider';

export class SocketServiceProvider extends ApiBaseServiceProvider {
  scope = 'socket';

  getArticleStatus(articleId: string, type: SocketActionType) {
    return axios.get<SocketArticleStatusResponse>(
      this.getBaseUrl() + `status/article/${type}/${articleId}`,
      this.getRequestConfig()
    );
  }
}
