
import './MenuAiFeaturesDropdownButton.scss';
import { AIWriterType, TextSelection } from '@/components/tiptap/extensions/AiWriter/AiWriter';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { TipTapEditorContext } from '@/components/tiptap/types';
import { tones, translateOptions } from '@/constants/SharedConsts';
import { Editor } from '@tiptap/core';
import { ElLoadingComponent } from 'element-ui/types/loading';

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  methods: {
    toneOptions() {
      return tones;
    },
    translateOptions() {
      return translateOptions;
    },
  },
  components: {
    MenuButton,
  },
  computed: {
    AIWriterType() {
      return AIWriterType;
    },
  },
})
export default class MenuAiFeaturesDropdownButton extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;

  loader: ElLoadingComponent = null;

  async handleClick(operationType: AIWriterType, value?: string) {
    const { view, state } = this.editor;
    const { from, to, $from } = view.state.selection;

    const selectionText = state.doc.textBetween(from, to, '');

    // If it's a paragraph depth, split paragraph
    if ($from.depth === 1 && $from.node().type.name === 'paragraph') {
      view.dispatch(view.state.tr.split(to));
    }

    const selection: TextSelection = {
      from,
      to,
      text: selectionText,
    };

    return this.editor.chain().focus().setAiWriter({ selection, type: operationType, optionValue: value }).run();
  }
}
