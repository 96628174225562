var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.article && _vm.article.id)?_c('MdListItem',{class:_vm.article.isArchived ? 'archived' : 'unarchived'},[_c('div',{staticClass:"article-item-container",class:{
      active: _vm.workspaceView.selectedArticle && _vm.workspaceView.selectedArticle.id === _vm.article.id,
      archived: _vm.article.isArchived,
      unarchived: !_vm.article.isArchived,
    },on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _setup.handleClick.apply(null, arguments)},"mousedown":function($event){if('button' in $event && $event.button !== 1)return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _setup.handleClick.apply(null, arguments)},"contextmenu":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _setup.handleClick.apply(null, arguments)}}},[_c('div',{staticClass:"article-item-container_content",style:({
        paddingLeft: `${_vm.nested * 20}px`,
      })},[(_setup.isNestedParagraph)?_c('div',{staticClass:"toggle-icon",style:({
          display: !_setup.isArticleParagraph && !_setup.isActiveNestedOrder && !_vm.showArchivedArticles ? 'none' : '',
        }),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _setup.toggleExpanded(_vm.article)}}},[(_setup.expanded.includes(_vm.article.id))?_c('span',{staticClass:"arrow-down"}):_c('span',{staticClass:"arrow-right"})]):_vm._e(),(_setup.isShowExtraSpace)?[_c('div',{staticStyle:{"min-width":"34px"}})]:_vm._e(),_c('MdButton',{staticClass:"article-title-button tooltip-title",attrs:{"md-ripple":false},on:{"mouseover":function($event){$event.preventDefault();!_setup.isTitleFocused && _setup.handleTitleFocus($event, _vm.article.title)},"mouseleave":function($event){$event.preventDefault();return _setup.removeTooltips.apply(null, arguments)}}},[(_vm.article && _vm.article.title)?_c('span',{staticClass:"text-ellipsis sidebar-article-title"},[_vm._v(_vm._s(_vm.article.title))]):_vm._e(),_c('div',{staticClass:"icon-container"},[(_vm.article.isSharedArticle())?_c('span',{staticClass:"unlock-icon"}):_vm._e(),(!_vm.article.isTextArticle())?_c('span',{staticClass:"video-icon"}):_vm._e()])])],2),_c('div',{staticClass:"article-item-options"},[_c(_setup.MoreButton,{attrs:{"article":_vm.article},on:{"duplicate-article":_setup.handleDuplicateArticle,"merge-article":_setup.handleMergeArticle,"reorder-articles":_setup.handleReorderArticles,"copy-article-url":_setup.handleCopyArticleUrl,"toggle-archive":_setup.handleToggleArchiveArticle,"create-sub-article":_setup.createSubArticle,"print-article":_setup.handleExportPdfArticle}})],1)]),_c(_setup.SidebarNestedArticleItem,{attrs:{"article":_vm.article,"workspace":_vm.workspace,"workspaceView":_vm.workspaceView,"showArchivedArticles":_vm.showArchivedArticles,"articleMode":_vm.articleMode,"isMobileView":_vm.isMobileView,"isSharedMode":_vm.workspaceView.isSharedMode,"isNestedParagraph":_setup.isNestedParagraph,"expanded":_setup.expanded,"nestedOrder":_vm.nestedOrder,"nested":_vm.nested,"paragraphProgress":_vm.paragraphProgress,"recalculateSidebarHeight":_vm.recalculateSidebarHeight}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }