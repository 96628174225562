import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { Article } from '@/models/article/Article';

export class ArticleModeService {
  getArticleMode(article: Article, requestedMode?: ArticleEditMode) {
    const isNotes = article.hasText();
    const isVideo = article.isVideoArticle();

    if (!isNotes && !isVideo) return ArticleEditMode.NOTES;

    if (requestedMode === ArticleEditMode.NOTES && isNotes) {
      return ArticleEditMode.NOTES;
    }
    if (requestedMode === ArticleEditMode.VIDEO && isVideo) {
      return ArticleEditMode.VIDEO;
    }

    return isNotes && isVideo ? ArticleEditMode.VIDEO : isNotes ? ArticleEditMode.NOTES : ArticleEditMode.VIDEO;
  }
}

const articleModeService = new ArticleModeService();
export default articleModeService;
