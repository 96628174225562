var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isSearchHistoryLoading)?_c('div',{staticClass:"suggestion__loading"},[_c(_setup.InlineIcon,{staticClass:"loader-spin",attrs:{"name":"loader"}})],1):_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchHistory?.questions?.length),expression:"searchHistory?.questions?.length"}],staticClass:"suggestion__list"},[_c('li',{staticClass:"suggestion__list__section-title"},[_vm._v("Recently asked")]),_c('li',{staticClass:"suggestion__wrapper--divider"}),_vm._l((_vm.searchHistory.questions),function(item,index){return _c('li',{key:index,staticClass:"list__item-wrapper",attrs:{"role":"option"},on:{"click":function($event){return _setup.handleSelect(item)}}},[_c('div',{staticClass:"list__item m-1"},[_c('p',{staticClass:"list__item-info mr-5"},[(
            item.category === _setup.SearchCategory.DRAFT_NOTES ||
            item.category === _setup.SearchCategory.ARTICLE_NOTES ||
            item.category === _setup.SearchCategory.TAG ||
            item.category === _setup.SearchCategory.ARTICLE_VIDEO ||
            item.category === _setup.SearchCategory.DRAFT_VIDEO
          )?_c('span',{staticClass:"list__item-name"},[_vm._v(" "+_vm._s(item.trimmedText)+" ")]):_c('span',{staticClass:"list__item-name"},[_vm._v(_vm._s(item.title))]),(item.parentArticle)?_c('span',{staticClass:"list__item-parent global-search__article-parent"},[_vm._v(" "+_vm._s(item.parentArticle)+" ")]):_vm._e(),(item.parentWorkspace)?_c('span',{staticClass:"list__item-parent"},[_vm._v(" "+_vm._s(item.parentWorkspace)+" ")]):_vm._e()]),(item.category === _setup.SearchCategory.WORKSPACE)?_c('div',{staticClass:"global-search__search-category global-search__workspace"},[_vm._v(" workspace ")]):(item.category === _setup.SearchCategory.ARTICLE)?_c('div',{staticClass:"global-search__search-category global-search__article"},[_vm._v(" article ")]):(item.category === _setup.SearchCategory.DRAFT || item.category === _setup.SearchCategory.DRAFT_NOTES)?_c('div',{staticClass:"global-search__search-category global-search__draft"},[_vm._v(" draft ")]):(item.category === _setup.SearchCategory.PARAGRAPH)?_c('div',{staticClass:"global-search__search-category global-search__paragraph"},[_vm._v(" paragraph ")]):(item.category === _setup.SearchCategory.ARTICLE_NOTES)?_c('div',{staticClass:"global-search__search-category global-search__text"},[_vm._v(" text ")]):(item.category === _setup.SearchCategory.TAG)?_c('div',{staticClass:"global-search__search-category global-search__tag"},[_vm._v(" tag ")]):(item.category === _setup.SearchCategory.ARTICLE_VIDEO)?_c('div',{staticClass:"global-search__search-category global-search__video"},[_vm._v(" said at "+_vm._s(item.saidAt)+" ")]):(item.category === _setup.SearchCategory.DRAFT_VIDEO)?_c('div',{staticClass:"global-search__search-category global-search__video-draft"},[_vm._v(" draft "+_vm._s(item.saidAt)+" ")]):_vm._e()])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }