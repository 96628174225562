
import VueApp from '@/@types/app/VueApp';
import store from '@/store';
import { Component } from 'vue-property-decorator';

@Component
export default class ArticleTranscriptionViewButton extends VueApp {
  handleOpenTranscription() {
    store.commit('transcriptionSidebarSetIsVisible', true);
  }
}
