
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dialog, MessageBox, Popover } from 'element-ui';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { Editor } from '@tiptap/core';

interface ExtendedPopover extends Popover {
  showPopper: boolean;
}

@Component({
  components: {
    [Dialog.name]: Dialog,
    [Popover.name]: Popover,
    MenuButton,
  },
})
export default class MenuAddImageButton extends Vue {
  @Prop({ required: true }) editor: Editor;

  async openUrlPrompt() {
    const extension = this.editor.extensionManager.extensions.find(({ name }) => name === 'image');
    const inputPattern = extension ? extension.options.urlPattern : /.*/;

    const result = await MessageBox.prompt('', 'Insert image', {
      confirmButtonText: 'insert',
      cancelButtonText: 'Cancel',
      inputPlaceholder: 'Url of image',
      inputPattern,
      inputErrorMessage: 'Please enter the correct url',
      roundButton: true,
    }).catch(() => null);
    if (result && result.value) {
      const { value: src } = result;
      this.editor.chain().focus().setImage({ src }).run();
    }
  }

  handleImageUpload() {
    // Access the popoverMenu instance through $refs and cast it to ExtendedPopover
    const popoverMenu = this.$refs.popoverRef as ExtendedPopover;

    // Close the popoverMenu by setting showPopper to false
    popoverMenu.showPopper = false;
    return this.editor.chain().focus().setImageUpload().run();
  }
}
