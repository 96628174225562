
import { Component } from 'vue-property-decorator';
import { Company } from '@/models/Company';
import { ElLoadingComponent } from 'element-ui/types/loading';
import notificationService from '@/services/NotificationService';
import VueApp from '@/@types/app/VueApp';
import logger from '@/other/Logger';
import { AxiosError } from 'axios';

@Component({})
export default class UserInviteDialog extends VueApp {
  loader: ElLoadingComponent = null;
  showDialog = false;
  inviteForm = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    sendEmail: true,
  };
  userRoles = Company.getRolesList();

  get user() {
    return this.$store.state.user;
  }

  show() {
    this.resetInviteForm();
    this.showDialog = true;
  }

  resetInviteForm() {
    this.inviteForm = {
      firstName: '',
      lastName: '',
      email: '',
      role: 'user',
      sendEmail: true,
    };
  }

  async handleClickInvite() {
    this.loader = this.$loading({});
    const inviteForm = {
      ...this.inviteForm,
      user: this.user,
    };
    try {
      const res = await this.$api.company.inviteUser(inviteForm);
      if (res.data && res.data.success) {
        this.$emit('invited');
      }
      notificationService.success('The user has been successfully invited');
    } catch (err) {
      const error = err as AxiosError<{ warning?: string }>;
      if (error.response.data.warning) {
        notificationService.warning(error.response.data.warning);
        this.loader.close();
        this.loader = null;
        return;
      }
      this.$api.handleResponseError(err);
      logger.error(new Error(err as string));
    }

    this.loader.close();
    this.loader = null;
    this.showDialog = false;
  }
}
