
import '@/scss/material/settings/user-profile.scss';
import './UserProfilePage.scss';
import { dispatchGetMeetingsCount } from '@/store/dispatchers/uiDispatchers';

import { Component } from 'vue-property-decorator';

import { CompanyUserStatus } from '@/@types/enums/CompanyUserStatus';
import { Company } from '@/models/Company';
import { User } from '@/models/User';
import VueApp from '@/@types/app/VueApp';
import { UserProfileForm } from '@/models/forms/UserProfileForm';
import notificationService from '@/services/NotificationService';

import ProfilePhotoInput from '@/components/layout/inputs/ProfilePhotoInput/ProfilePhotoInput.vue';
import moment from 'moment';
import { BotConfig } from '@/models/forms/BotConfig';

@Component({
  components: {
    ProfilePhotoInput,
  },
})
export default class UserProfilePage extends VueApp {
  loader: any = null;
  user: User = new User();
  userDataForm: UserProfileForm;

  botCompany: Company = new Company();
  botNameSuffix = `'s Snaplore AI`;
  botConfig: BotConfig | null = null;

  get newUserImageUrl() {
    return this.userDataForm.userProfileImage;
  }

  get userProfileImage() {
    return this.$store.state.user?.profileImage ?? null;
  }

  get userBirthDay(): string | null {
    if (this.userDataForm.birthDay) {
      return moment(new Date(this.userDataForm.birthDay)).format('YYYY-MM-DD');
    }
    return null;
  }

  set userBirthDay(value) {
    if (value) {
      this.userDataForm.birthDay = new Date(value);
      return;
    }
    this.userDataForm.birthDay = null;
  }

  get userActiveCompanies() {
    const companies: Company[] = this.$store.state.userCompanies;
    const inactiveCompanies: Company[] = [];
    let activeCompanies: Company[] = [];
    companies.forEach((company: Company) => {
      const isInactiveUserInCompany = company.roles?.some(
        (item) => item.status === CompanyUserStatus.DEACTIVATED && item.user === this.user.id
      );
      if (isInactiveUserInCompany) {
        inactiveCompanies.push(company);
      }
    });

    if (inactiveCompanies.length) {
      companies.forEach((company: Company) => {
        inactiveCompanies.forEach((item: Company) => {
          if (company.id !== item.id) {
            activeCompanies.push(company);
          }
        });
      });
    } else {
      activeCompanies = companies;
    }
    return activeCompanies;
  }

  isValid(key: 'name') {
    return this.botConfig && this.botConfig[key];
  }

  selectBotCompany(index: number) {
    const companies: Company[] = this.userActiveCompanies;
    const selectedCompany = companies[index];
    this.botConfig.companyId = selectedCompany.id;
  }

  async setProfileImage(photo: File) {
    this.userDataForm.isUserProfileImageDeleted = false;
    if (photo) {
      const uploadedPhoto = (await this.getBase64(photo)) as string;
      this.userDataForm.userProfileImage = uploadedPhoto.replace(/^data:image\/[a-z]+;base64,/, '');
    }
  }

  deleteProfileImage() {
    this.userDataForm.isUserProfileImageDeleted = true;
  }

  async saveBotConfig() {
    try {
      await this.$api.createBotConfig(this.botConfig);
    } catch (error) {
      this.$api.handleResponseError(error);
    }
  }

  async getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async loadBotConfig() {
    try {
      const res = await this.$api.getBotConfig(this.user.id);
      this.botConfig = res.data.config;

      if (!this.botConfig) {
        const company = this.userActiveCompanies[0];
        this.botCompany = company;
        this.botConfig = {
          name: this.user.firstName,
          userId: this.user.id,
          companyId: company.id,
          companySlug: company.slug,
        };
      } else {
        const company = this.userActiveCompanies.find((item) => item.id === this.botConfig.companyId);
        this.botCompany = company;
      }
    } catch (res) {
      this.$api.handleResponseError(res);
    }
  }

  async save() {
    if (this.userDataForm.passwordOld || this.userDataForm.passwordNew) {
      // validate password
      if (this.userDataForm.passwordConfirm !== this.userDataForm.passwordNew) {
        notificationService.error('Passwords do not match');
        return;
      }
    }

    if (!this.isValid('name')) {
      notificationService.error('The title is required');
      return;
    }

    const loader = this.$loading({});

    await this.saveBotConfig();

    await dispatchGetMeetingsCount();

    this.$api
      .updateUser(this.userDataForm)
      .then((res) => {
        this.userDataForm = null;
        notificationService.success('Your profile was successfully updated!');
        // do not lose user company data.
        if (this.user && this.user.company && !res.data.user.company) {
          res.data.user.company = this.user.company;
        }
        // do not lose user role data.
        if (this.user && this.user.role && !res.data.user.role) {
          res.data.user.role = this.user.role;
        }
        this.user = this.$auth.setUser(res.data.user);
        this.userDataForm = res.data.user;
        this.$store.state.user.profileImagePreview = this.user.profileImage;
      })
      .catch((res) => {
        this.$api.handleResponseError(res);
      })
      .finally(() => {
        loader.close();
      });
  }

  async load() {
    const loader = this.$loading({});

    try {
      const res = await this.$api.getUser();
      this.user = User.parse(res.data.user);
      this.userDataForm = res.data.user as unknown as UserProfileForm;

      await this.loadBotConfig();
    } catch (res) {
      this.$api.handleResponseError(res);
    } finally {
      loader.close();
    }
  }

  created() {
    this.load();
  }
}
