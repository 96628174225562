import { Extension } from '@tiptap/core';
import { createIndentCommand, IndentProps } from '../utils/indent';

type IndentOptions = {
  types: string[];
  indentLevels: number[];
  defaultIndentLevel: number;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    indent: {
      indent: () => ReturnType;
      outdent: () => ReturnType;
    };
  }
}

const Indent = Extension.create<IndentOptions>({
  name: 'indent',

  addOptions() {
    return {
      types: ['heading', 'paragraph'],
      indentLevels: [0, 30, 60, 90, 120, 150, 180, 210],
      defaultIndentLevel: 0,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            default: this.options.defaultIndentLevel,
            renderHTML: (attributes) => {
              const hasIndent = attributes.indent && attributes.indent > 0;

              return hasIndent
                ? {
                    style: `margin-left: ${attributes.indent}px`,
                  }
                : {};
            },
            parseHTML: (element) => {
              return parseInt(element.style.marginLeft) || this.options.defaultIndentLevel;
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      indent: () => createIndentCommand(IndentProps.more),
      outdent: () => createIndentCommand(IndentProps.less),
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => editor.commands.indent(),
      'Shift-Tab': ({ editor }) => editor.commands.outdent(),
    };
  },
});

export default Indent;
