import { Group } from './types';

export const GROUPS: Group[] = [
  {
    name: 'ai',
    title: 'AI',
    commands: [
      {
        name: 'aiWriter',
        label: 'AI writer',
        iconName: 'sparkles',
        shouldBeHidden: (editor) =>
          editor.isActive('table') || editor.isActive('columns') || editor.isActive('blockquote'),
        action: (editor) => editor.chain().focus().setAiWriter().run(),
      },
      {
        name: 'aiImage',
        label: 'AI image',
        iconName: 'sparkles',
        shouldBeHidden: (editor) =>
          editor.isActive('table') || editor.isActive('columns') || editor.isActive('blockquote'),
        action: (editor) => editor.chain().focus().setAiImage().run(),
      },
    ],
  },
  {
    name: 'format',
    title: 'FORMAT',
    commands: [
      {
        name: 'heading1',
        label: 'Heading 1',
        iconName: 'heading-1',
        aliases: ['h1'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run();
        },
      },
      {
        name: 'heading2',
        label: 'Heading 2',
        iconName: 'heading-2',
        aliases: ['h2'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run();
        },
      },
      {
        name: 'heading3',
        label: 'Heading 3',
        iconName: 'heading-3',
        aliases: ['h3'],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 3 }).run();
        },
      },
      {
        name: 'bulletList',
        label: 'Bullet List',
        iconName: 'bullet-list',
        aliases: ['ul'],
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run();
        },
      },
      {
        name: 'numberedList',
        label: 'Numbered List',
        iconName: 'ordered-list',
        aliases: ['ol'],
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run();
        },
      },
      {
        name: 'taskList',
        label: 'Task List',
        iconName: 'todo-list',
        aliases: ['todo'],
        action: (editor) => {
          editor.chain().focus().toggleTaskList().run();
        },
      },
      {
        name: 'blockquote',
        label: 'Blockquote',
        iconName: 'quote',
        action: (editor) => {
          editor.chain().focus().setBlockquote().run();
        },
      },
      {
        name: 'codeBlock',
        label: 'Code Block',
        iconName: 'square-code',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().setCodeBlock().run();
        },
      },
    ],
  },
  {
    name: 'insert',
    title: 'INSERT',
    commands: [
      {
        name: 'table',
        label: 'Table',
        iconName: 'table',
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => {
          editor.chain().focus().insertTable({ rows: 3, cols: 3 }).run();
        },
      },
      {
        name: 'image',
        label: 'Image',
        iconName: 'image',
        aliases: ['img'],
        shouldBeHidden: (editor) =>
          editor.isActive('table') || editor.isActive('columns') || editor.isActive('blockquote'),
        action: (editor) => {
          editor.chain().focus().setImageUpload().run();
        },
      },
      {
        name: 'columns',
        label: 'Columns',
        iconName: 'columns',
        aliases: ['cols'],
        shouldBeHidden: (editor) => editor.isActive('table') || editor.isActive('columns'),
        action: (editor) => {
          editor
            .chain()
            .focus()
            .setColumns()
            .focus(editor.state.selection.head - 1)
            .run();
        },
      },
      {
        name: 'horizontalRule',
        label: 'Horizontal Rule',
        iconName: 'minus',
        aliases: ['hr'],
        action: (editor) => {
          editor.chain().focus().setHorizontalRule().run();
        },
      },
    ],
  },
];

export default GROUPS;
