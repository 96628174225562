import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';
import { AnyJson } from '@/@types/AnyJson';
import { ArticleCommentQuote } from '@/@types/ArticleCommentQuote';
import { ArticleCommentVideoReaction } from '@/@types/ArticleCommentVideoReaction';

export class ArticleComment implements Model {
  id: string;
  parentId: string;
  userId: string;
  author: string;
  articleId: string;
  workspaceId: string;
  content: string;
  reaction: {
    label: string;
    content: string;
    userIds: string[];
  }[];
  timestamp: number;
  quote?: ArticleCommentQuote;
  videoReaction: ArticleCommentVideoReaction;
  createdAt: Date;
  profileImage: string;

  static parse(jsonData: AnyJson) {
    return modelService.create(ArticleComment, jsonData);
  }

  afterLoad(data: AnyJson) {
    this.id = data.id || data._id;
    this.parentId = data.parentId;
    this.userId = data.userId;
    this.articleId = data.articleId;
    this.workspaceId = data.workspaceId;
    this.timestamp = data.timestamp;
    this.content = data.content;
    this.reaction = data.reaction;
    this.createdAt = data.createdAt;
    this.author = data.author;
    this.quote = data.quote;
    this.videoReaction = data.videoReaction;
    this.profileImage = data.profileImage || '';
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }
}
