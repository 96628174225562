import { SubscriptionType } from '@/@types/enums/SubscriptionType';
import { Company } from '@/models/Company';
import apiService from '@/services/ApiService';
import store from '@/store';
import { commitSetCompanyPaddle } from '@/store/commits/companyCommits';
import { CheckoutEventNames, PaddleEventData } from '@paddle/paddle-js';
import { Loading } from 'element-ui';
import VueRouter from 'vue-router';

class PaddleEventHandler {
  public paddleEventHandlers: Partial<Record<string, (data: PaddleEventData, router: VueRouter) => void>>;

  constructor() {
    this.paddleEventHandlers = {
      [CheckoutEventNames.CHECKOUT_COMPLETED]: this.handleCheckoutCompleted,
    };
  }

  async handleCheckoutCompleted() {
    const loader = Loading.service({
      lock: true,
      text: 'Updating company',
    });

    let paddleRes: Company['paddle'];
    const company = store.state.company;

    const checkPaymentStatus = async () => {
      const resPaddleStatus = await apiService.company.getPaidStatus();
      paddleRes = resPaddleStatus.data ?? null;
    };

    // Start polling every 3 seconds
    const intervalId = setInterval(async () => {
      await checkPaymentStatus();

      if (paddleRes?.status === SubscriptionType.ACTIVE || paddleRes?.status === SubscriptionType.TRIALING) {
        clearInterval(intervalId);

        commitSetCompanyPaddle(paddleRes);
        const baseUrl = company?.domain ? company.getDomainAddress() : `http://localhost:${process.env.VUE_APP_PORT}`;

        window.location.href = `${baseUrl}/#/`;

        loader.close();
      }
    }, 3000);
  }
}

const paddleEventHandler = new PaddleEventHandler();
export default paddleEventHandler.paddleEventHandlers;
