import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import store from '@/store';
import MentionList from './MentionList.vue';
import { SuggestionEventProps } from '@/components/tiptap/extensions/Mention/SuggestionEventProps';
import { User } from '@/models/User';

export default {
  items: ({ query }: { query: string }) => {
    const companyUsers = store.state.companyUsers;
    const q = query.toLowerCase();

    return companyUsers
      .filter((user) => {
        const name = user.name.toLowerCase();
        const email = user.email.toLowerCase();
        return name.includes(q) || email.includes(q);
      })
      .map((user) => User.parse(JSON.parse(JSON.stringify(user))));
  },

  render: () => {
    let component: VueRenderer;
    let popup: any;

    return {
      onStart: (props: SuggestionEventProps) => {
        component = new VueRenderer(MentionList, {
          parent: this,
          propsData: props,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props: SuggestionEventProps) {
        component.updateProps(props);

        if (!props.clientRect) return;

        popup[0].setProps({ getReferenceClientRect: props.clientRect });
      },

      onKeyDown(props: SuggestionEventProps) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return (component.ref as MentionList)?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
