
import VueApp from '@/@types/app/VueApp';
import { Component } from 'vue-property-decorator';
import EmojiIcons from '@/components/tiptap/Menu/MenuItems/Emoji/EmojiIcons.vue';
import articleCommentService from '@/services/article/ArticleCommentService';

@Component({
  components: {
    EmojiIcons,
  },
})
export default class VideoReactionMenu extends VueApp {
  async onClickEmoji(icon: string) {
    await articleCommentService.handleAddReactionOnVideo(icon);
  }

  async onClickComment() {
    await articleCommentService.handleAddCommentOnVideo();
  }
}
