import { AiChatMessage } from '@/@types/AiChatMessage';
import { AnyJson } from '@/@types/AnyJson';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios from 'axios';

export class GlobalSearchServiceProvider extends ApiBaseServiceProvider {
  scope = 'search';

  search(q: string, page: number, searchCategory: string, skip: number) {
    let searchUrl = `${this.getBaseUrl()}global/${encodeURIComponent(q)}?category=${searchCategory}`;
    if (page) searchUrl += `&page=${page}`;
    if (skip) searchUrl += `&skip=${skip}`;
    return axios.get<{ data: AnyJson[] }>(searchUrl, this.getRequestConfig());
  }

  aiSearch(q: string, messages?: AiChatMessage[]) {
    const searchUrl = `${this.getBaseUrl()}ai/${encodeURIComponent(q)}`;
    return axios.post<AiChatMessage>(searchUrl, { messages }, this.getRequestConfig());
  }

  getSearchHistory() {
    return axios.get<{ history?: Record<string, any> }>(`${this.getBaseUrl()}history`, this.getRequestConfig());
  }

  updateSearchHistory(question: any, historyId: string) {
    return axios.put(`${this.getBaseUrl()}history/${historyId}`, { question }, this.getRequestConfig());
  }
  createSearchHistory(question: any) {
    return axios.post(`${this.getBaseUrl()}history`, { question }, this.getRequestConfig());
  }
}
