
import { COMPANY_CREATE } from '@/events';
import EventBus from '@/EventBus';
import VueApp from '@/@types/app/VueApp';
import { Component, Watch } from 'vue-property-decorator';
import auth from '@/services/AuthService';
import * as routerNames from '@/routerNames';

@Component({})
export default class UserDeactivated extends VueApp {
  get user() {
    return this.$store.state.user;
  }

  get isUserInactiveInCompany() {
    return this.$store.state.isUserInactiveInCompany;
  }

  handleCreateCompany() {
    EventBus.$emit(COMPANY_CREATE);
  }

  get hasUserActiveCompany() {
    const user = this.$store.state.user;
    return user.companyIds.length ? true : false;
  }

  async created() {
    await auth.loadUser();
    if (this.isUserInactiveInCompany && this.hasUserActiveCompany) {
      return this.$router.push({ name: routerNames.LOGIN });
    }
    if (this.hasUserActiveCompany) {
      return this.$router.push({ name: routerNames.HOME });
    }
  }

  @Watch('hasUserActiveCompany')
  redirectToHomePage() {
    if (this.hasUserActiveCompany) {
      this.$router.push({ name: routerNames.HOME });
    }
  }
}
