import { Video } from '@/models/Video';
import { Article } from '@/models/article/Article';
import { SearchByTextProperty } from '@/models/SearchByText';
import { IAutoplay } from '@/models/IAutoplay';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import ArticlePlayer from '@/components/article-player/ArticlePlayer.vue';

export interface SharedState {
  recordedVideo: Video | null;
  createArticle: Article | null;
  autoPlay: IAutoplay | null;
  videoPlayerTimeToSet: null | number;
  articleMode?: ArticleEditMode;
  videoPlayerRef: ArticlePlayer;
  articleTextPropsToFind: SearchByTextProperty[];
  articleIdToExpand?: string;
  isMobile: boolean;
  innerHeight: number;
  sharedCompanyLogo: string | null;
}

export const sharedState: SharedState = {
  recordedVideo: null, // recorded video outside the ArticleEdit page
  createArticle: null, // perilled article for ArticleCreatePage
  autoPlay: null, // autoplay article or paragraph on workspace page
  videoPlayerTimeToSet: null,
  articleMode: undefined,
  videoPlayerRef: undefined,
  articleTextPropsToFind: [],
  articleIdToExpand: undefined,
  isMobile: false,
  innerHeight: null,
  sharedCompanyLogo: null,
};
