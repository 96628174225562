import { SocketMessageType } from '@/@types/enums/SocketMessageType';
import { TextTab } from '@/models/article/TextTab';
import { ArticleAiImageSocketAction } from '@/services/socket/actions/ArticleAiImageSocketAction';
import { ArticleAiParagraphsSocketAction } from '@/services/socket/actions/ArticleAiParagraphsSocketAction';
import {
  ArticleAiSmartNotesSocketAction,
  IArticleAiSmartNotesSocketArgs,
} from '@/services/socket/actions/ArticleAiSmartNotesSocketAction';
import {
  ArticleAiTextSocketAction,
  IArticleAiTextSocketArgs,
} from '@/services/socket/actions/ArticleAiTextSocketAction';
import { ArticleAiTranscriptionSocketAction } from '@/services/socket/actions/ArticleAiTranscriptionSocketAction';
import { SocketBaseServiceProvider } from '@/services/socket/SocketBaseServiceProvider';
import { SocketService } from '@/services/socket/SocketService';

export class AiSocketFeature extends SocketBaseServiceProvider {
  private imageAction: ArticleAiImageSocketAction;
  private paragraphsAction: ArticleAiParagraphsSocketAction;
  private smartNotesAction: ArticleAiSmartNotesSocketAction;
  private textAction: ArticleAiTextSocketAction;
  private transcriptionAction: ArticleAiTranscriptionSocketAction;

  constructor(socket: SocketService) {
    super(socket);
    this.imageAction = new ArticleAiImageSocketAction(socket);
    this.textAction = new ArticleAiTextSocketAction(socket);
    this.smartNotesAction = new ArticleAiSmartNotesSocketAction(socket);
    this.paragraphsAction = new ArticleAiParagraphsSocketAction(socket);
    this.transcriptionAction = new ArticleAiTranscriptionSocketAction(socket);
  }

  generateAiImage = async (prompt: string, style?: string): Promise<string> => {
    return this.imageAction.generateAiImage(prompt, style);
  };

  generateAiParagraphs = async (
    articleId: string,
    type: SocketMessageType,
    statusCallback: (status: any) => void
  ): Promise<string> => {
    return this.paragraphsAction.generateAiParagraphs(articleId, type, statusCallback);
  };

  stopAiParagraphsGeneration = (articleId: string): void => {
    return this.paragraphsAction.stopAiParagraphsGeneration(articleId);
  };

  generateAiSmartNotes = async (
    props: IArticleAiSmartNotesSocketArgs,
    statusCallback: (status: any) => void
  ): Promise<{ text: string; textTabs?: TextTab[]; draftId?: string }> => {
    return this.smartNotesAction.generateAiSmartNotes(props, statusCallback);
  };

  stopAiSmartNotesGeneration = (articleId: string): void => {
    return this.smartNotesAction.stopAiSmartNotesGeneration(articleId);
  };

  generateAiText = async (props: IArticleAiTextSocketArgs): Promise<string> => {
    return this.textAction.generateAiText(props);
  };

  generateAiTranscription = async (
    articleId: string,
    type: SocketMessageType.START,
    statusCallback: (status: any) => void
  ): Promise<string> => {
    return this.transcriptionAction.generateAiTranscription(articleId, type, statusCallback);
  };

  stopAiTranscriptionGeneration = (): void => {
    return this.transcriptionAction.stopAiTranscriptionGeneration();
  };
}
