
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models/User';
import { CompanyUserStatus } from '@/@types/enums/CompanyUserStatus';
import store from '@/store';

@Component({})
export default class MentionDetailsView extends Vue {
  @Prop({ required: true }) user: User;

  get isUserDeactivated() {
    const company = store.state.company;
    return this.user && company && company.getUserStatus(this.user.id) === CompanyUserStatus.DEACTIVATED;
  }
}
