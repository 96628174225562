
import logger from '@/other/Logger';
import { Component, Prop, Watch } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import { Company } from '@/models/Company';
import EmptyStateCard from '@/components/layout/cards/EmptyStateCard.vue';
import MyTableCell from '@/components/layout/MyTable/MyTableCell.vue';
import MyTable from '@/components/layout/MyTable/MyTable.vue';
import { User } from '@/models/User';
import apiService from '@/services/ApiService';
import notificationService from '@/services/NotificationService';
import { CompanyUserStatus } from '@/@types/enums/CompanyUserStatus';
import store from '@/store';

@Component({
  computed: {
    CompanyUserStatus() {
      return CompanyUserStatus;
    },
  },
  components: { MyTable, MyTableCell, EmptyStateCard },
})
export default class AdminCompanyGeneralSection extends VueApp {
  @Prop({ type: Object, required: true }) company: Company;
  @Prop({ type: Array, required: true }) users: User[];

  tableColumns = [
    { label: 'FIRST NAME' },
    { label: 'LAST NAME' },
    { label: 'EMAIL' },
    { label: 'STATUS' },
    { label: 'LAST TIME LOGGED IN' },
    { label: '' },
  ];

  filteredUsers: User[] = [];

  loader: any = null;
  searchQuery = '';
  searchUserStatus = '';
  isInitialFilter = false;

  getHumanLastTimeLoggedIn = (item: User): string => {
    if (!item.lastTimeLoggedIn) return '';

    const date = new Date(item.lastTimeLoggedIn);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);

    const formattedDate = `${day}.${month}.${date.getFullYear()}, ${date.getHours()}:${
      (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    }`;

    return formattedDate;
  };

  isSuperAdmin = (item: User): boolean => {
    const currentUser = store.state.user;
    return currentUser.id === item.id;
  };

  textHandler(text: string) {
    return text.toString().toLowerCase().replace(/\s/g, '');
  }

  searchUserStatusLength(searchResult: boolean, user: User): boolean {
    const { searchUserStatus, getUserCompanyStatus } = this;

    if (searchUserStatus && searchResult) {
      const userCompanyStatus = getUserCompanyStatus(user);
      searchResult = searchUserStatus === userCompanyStatus;
    }

    return searchResult;
  }

  filterUsers() {
    const users = this.users;

    this.filteredUsers =
      users.filter((user: User) => {
        const source = this.textHandler(user.firstName.concat(user.lastName).concat(user.email));

        let searchResult = source.includes(this.textHandler(this.searchQuery));
        searchResult = this.searchUserStatusLength(searchResult, user);
        return searchResult;
      }) ?? [];

    if (!this.filteredUsers.length && this.isInitialFilter) {
      this.isInitialFilter = false;
    }
  }

  isUserActive = (user: User) => !!user.groups;

  getUserCompanyStatus(user: any) {
    let status;
    const userStatus = this.company.getUserStatus(user.id);
    const enumCompanyUserStatus = Object.values(CompanyUserStatus);

    if (enumCompanyUserStatus.includes(userStatus)) {
      status = userStatus;
    } else {
      if (this.isUserActive(user)) {
        status = CompanyUserStatus.ACTIVE;
      } else {
        status = CompanyUserStatus.INVITED;
      }
    }
    return status;
  }

  // Move getUserStatus to here because in some cases this.company.getUserStatus do not work
  getUserStatus(user: User, company: Company) {
    let status;
    const userStatus = company.getUserStatus(user.id);
    const enumCompanyUserStatus = Object.values(CompanyUserStatus);

    if (enumCompanyUserStatus.includes(userStatus)) {
      status = userStatus;
    } else {
      if (this.isUserActive(user)) {
        status = CompanyUserStatus.ACTIVE;
      } else {
        status = CompanyUserStatus.INVITED;
      }
    }
    return status;
  }

  async handleChangeUserCompanyStatus(user: User) {
    try {
      let status = this.getUserStatus(user, this.company);
      if (status === CompanyUserStatus.ACTIVE) {
        status = CompanyUserStatus.DEACTIVATED;
      } else if (status === CompanyUserStatus.DEACTIVATED) {
        status = CompanyUserStatus.ACTIVE;
      }

      await apiService.admin.changeUserCompanyStatus(this.company.id, user.id, status);

      const changedUser: any = this.filteredUsers.find((item) => item.id === user.id);
      changedUser.status = status;

      this.$emit('users-updated');

      this.filterUsers();

      if (status === CompanyUserStatus.ACTIVE) {
        notificationService.success(`The user is activated successfully`);
      } else if (status === CompanyUserStatus.DEACTIVATED) {
        notificationService.success('The user is deactivated successfully');
      }
    } catch (err) {
      logger.error(err);
      notificationService.error('Failed to change user status in company');
    }
  }

  async handleRemoveUserFromCompany(user: any) {
    try {
      const isInvitedUser = Object.prototype.hasOwnProperty.call(user, 'accepted');
      await apiService.admin.deleteCompanyUser(this.company.id, isInvitedUser ? user._id : user.id);
      this.filteredUsers = this.filteredUsers.filter((item: any) =>
        isInvitedUser ? item._id !== user._id : item.id !== user.id
      );

      this.$emit('users-updated');

      notificationService.success('User successfully removed');
    } catch (err) {
      logger.error(err);
      notificationService.error('Failed to remove user from company');
    }
  }

  created() {
    this.filterUsers();
  }

  @Watch('searchQuery')
  @Watch('searchUserStatus')
  onSearchChanged() {
    this.filterUsers();
  }
}
