
import VueApp from '@/@types/app/VueApp';
import UploadButton from '@/components/screen-recorder/UploadButton.vue';
import EventBus from '@/EventBus';
import {
  APP_SAVE_RECORDER_VIDEO,
  APP_START_RECORDING_PROMISE_RESOLVED,
  ARTICLE_CREATE_HIDE,
  ARTICLE_CREATE_UPDATE_ARTICLE,
} from '@/events';
import { Article } from '@/models/article/Article';
import { TextTab } from '@/models/article/TextTab';
import { Workspace } from '@/models/Workspace';
import articleEditService from '@/services/article/ArticleEditService';
import extensionService from '@/services/ExtensionService';
import notificationService from '@/services/NotificationService';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import { commitSetArticleEditorArticle } from '@/store/commits/articleEditorCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  name: 'ArticleCreateDialog.vue',
  components: {
    UploadButton,
  },
})
export default class ArticleCreate extends VueApp {
  article: Article = null;
  showDialog = false;
  dialogStep = '';
  articleType = 'video';
  resolve: any = null;
  reject: any = null;
  initValidate = false;
  recording = false;
  searchQuery = '';
  searchedWorkspaces: Workspace[] = [];
  selectedWorkspace = false;

  $refs: {
    tagInput: any;
  };

  get workspaces(): Workspace[] {
    return this.$store.state.workspaces;
  }

  get personalWorkspaceId(): string {
    return this.$store.state.user.personalWorkspaceId;
  }

  handleWorkspaceTitle(workspace: Workspace): string {
    return workspaceService.getWorkspaceTitle(workspace);
  }

  get accessibleWorkspaces() {
    const workspaces = [
      this.workspaces.find(
        (workspace: Workspace) => workspace.canEditArticles() && workspace.id === this.personalWorkspaceId
      ),
      ...this.workspaces.filter(
        (workspace: Workspace) =>
          workspace.canEditArticles() && workspace.id !== this.personalWorkspaceId && !workspace.isArchived
      ),
    ];

    return workspaces.length ? workspaces : [];
  }

  checkChips() {
    const inputValue = this.$refs.tagInput.inputValue.trim();
    if (inputValue.length > 0) {
      this.article.tags.push(inputValue);
      this.$refs.tagInput.inputValue = '';
    }
  }

  onFilterWorkspaces() {
    if (this.selectedWorkspace) {
      this.selectedWorkspace = false;
    } else {
      this.searchedWorkspaces = this.accessibleWorkspaces.filter(
        (el: any) => el.title.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
      );
      this.article.workspace = null;
    }
  }

  onOpenWorkspace() {
    this.searchedWorkspaces = this.accessibleWorkspaces;
    //for setting custom dropdown width
    this.$nextTick(() => {
      const dropDownSelect = document.querySelector(
        '.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default'
      ) as HTMLElement;
      dropDownSelect.style.width = '775px';
      dropDownSelect.style.maxWidth = '775px';
    });
  }

  openRecordingWindow() {
    extensionService.openRecordingWindow();
    this.showDialog = false;
  }

  onSelectWorkspace(workspace: Workspace) {
    this.searchQuery = this.handleWorkspaceTitle(workspace);
    this.selectedWorkspace = this.article.workspace !== workspace.id;
    this.article.workspace = workspace.id;
  }

  init() {
    this.recording = false;
    this.articleType = 'video';
    this.dialogStep = 'first';
  }

  handleTypeChange(type: string) {
    this.articleType = type;
    this.dialogStep = 'second';
  }

  show(article: Article) {
    this.article = Article.fromJson(article);
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
      this.init();
      this.showDialog = true;
    });
  }

  isValid(key: 'workspace' | 'title') {
    return !this.initValidate || this.article[key];
  }

  validate() {
    this.initValidate = true;
    return this.isValid('title') && this.isValid('workspace');
  }

  cancelRecording() {
    this.showDialog = false;
    this.recording = false;
    articleEditService.handleCancelRecording();
  }

  recordingPromiseResolved() {
    this.recording = true;
  }

  async saveTextArticle() {
    this.initValidate = true;
    if (this.isValid('title') && this.isValid('workspace')) {
      if (!this.article.title.trim().length) {
        notificationService.warning('Title cannot be empty!');
        return;
      }
      this.checkChips();

      if (this.articleType === 'text') {
        this.article.textTabs = [new TextTab()];
      }

      this.showDialog = false;

      commitSetArticleEditorArticle(this.article);
      await articleEditService.saveAsDraft();

      this.resolve(this.article);

      await dispatchUpdateSelectedTextTab(this.article.textTabs[0]);
    }
  }

  closeDialogs() {
    this.showDialog = false;
    this.recording = false;
  }

  hideCreateArticle() {
    this.showDialog = false;
    this.article = null;
  }

  mounted() {
    EventBus.$on(ARTICLE_CREATE_UPDATE_ARTICLE, () => {
      commitSetArticleEditorArticle(this.article);
    });

    EventBus.$on(APP_SAVE_RECORDER_VIDEO, () => {
      this.recording = false;
    });

    EventBus.$on(APP_START_RECORDING_PROMISE_RESOLVED, this.recordingPromiseResolved);

    EventBus.$on(ARTICLE_CREATE_HIDE, this.hideCreateArticle);
  }

  beforeDestroy() {
    EventBus.$off(ARTICLE_CREATE_UPDATE_ARTICLE);
    EventBus.$off(APP_SAVE_RECORDER_VIDEO);
    EventBus.$off(APP_START_RECORDING_PROMISE_RESOLVED);
    EventBus.$off(ARTICLE_CREATE_HIDE);
  }

  @Watch('showDialog')
  onDialogClose(value: boolean) {
    if (!value) {
      this.searchQuery = '';
      this.searchedWorkspaces = [];
      this.initValidate = false;
    }
  }

  @Watch('dialogStep')
  onDialogChangeStep(value: string) {
    if (value === 'second' && this.article.workspace) {
      this.selectedWorkspace = true;
      const currentWorkspace = this.workspaces.find(
        (workspace: Workspace) => workspace.id === this.article.workspace && !workspace.isArchived
      );
      this.searchQuery = this.handleWorkspaceTitle(currentWorkspace);
    }
  }
}
