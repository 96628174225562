
import VueApp from '@/@types/app/VueApp';
import helper from '@/helper';
import urlService from '@/services/UrlService';
import notificationService from '@/services/NotificationService';
import { Paragraph } from '@/models/article/Paragraph';
import { Article } from '@/models/article/Article';
import { Component, Prop } from 'vue-property-decorator';
import { Workspace } from '@/models/Workspace';
import ArticleShareSettings from '@/components/article-editor/ArticleShareSettings/ArticleShareSettings.vue';

@Component({ components: { ArticleShareSettings } })
export default class ArticleShareButton extends VueApp {
  @Prop({ type: Workspace }) workspace: Workspace;
  @Prop({ type: Article }) article: Article;
  @Prop({ type: Paragraph }) paragraph: Paragraph;
  @Prop({ default: false }) isArticleEditPage: boolean;
  @Prop({ default: false }) isShowShareSettings: boolean;

  $refs: {
    articleSharing: ArticleShareSettings;
  };

  get isSharedMode() {
    return this.$store.state.workspaceView.isSharedMode;
  }

  get isShared(): boolean {
    return this.article && !!this.article.shared;
  }

  get isArticleShareable() {
    return this.$store.state.articleEditor.article?.isSharedArticle();
  }

  get workspaces() {
    return this.$store.state.workspaces;
  }

  handleCopyUrl() {
    if (this.isArticleEditPage) {
      this.handleCopyArticleUrl();
    } else {
      this.handleCopyParagraphUrl();
    }
  }

  handleCopyPrivateUrl() {
    if (this.$refs.articleSharing) {
      this.$refs.articleSharing.handleCopyUrl();
    }
  }

  handleCopyArticleUrl() {
    if (this.workspace.shortId && this.article.shortId) {
      helper.copyToClipboard(urlService.getArticleViewUrl(this.article, this.workspace));
      notificationService.success('Article link copied to clipboard');
    } else {
      notificationService.warning('Cannot copy link to this article');
    }
  }

  handleCopyParagraphUrl() {
    if (this.isSharedMode) {
      helper.copyToClipboard(location.href);
      notificationService.success('Article link copied to clipboard');
    } else if (this.workspace && this.article) {
      const copyLink = this.paragraph
        ? urlService.getParagraphViewUrl(this.paragraph.getSlug(), this.article, this.workspace)
        : urlService.getArticleViewUrl(this.article, this.workspace, this.$store.state.articleMode);

      helper.copyToClipboard(copyLink);
      notificationService.success('Link has been copied to the clipboard');
    } else {
      notificationService.warning('Cannot copy link');
    }
  }
}
