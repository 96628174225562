import axios, { AxiosPromise } from 'axios';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import { ArticleComment } from '@/models/article/ArticleComment';
import { ArticleCommentJson } from '@/@types/responses/ArticleCommentsResponses';

export class CommentServiceProvider extends ApiBaseServiceProvider {
  scope = 'comment';

  getById = (
    articleId: string,
    commentId: string
  ): AxiosPromise<{ comment: ArticleCommentJson; profileImage: string }> => {
    return axios.get(`${this.getBaseUrl() + articleId}/${commentId}`, this.getRequestConfig());
  };

  getAll = (
    articleId: string
  ): AxiosPromise<{ comments: { comment: ArticleCommentJson; profileImage: string }[] | [] }> => {
    return axios.get(`${this.getBaseUrl() + articleId}`, this.getRequestConfig());
  };

  create = (
    articleId: string,
    newComment: Pick<ArticleComment, 'content' | 'parentId' | 'workspaceId'>,
    isEditing = false
  ): AxiosPromise<{ articleComment: ArticleCommentJson; profileImage: string }> => {
    return axios.post(`${this.getBaseUrl() + articleId}`, { newComment, isEditing }, this.getRequestConfig());
  };

  update = (
    articleId: string,
    commentId: string,
    updatedContent: { timestamp: number | null; content: string; reaction: { label: string; content: string } },
    isEditing = false
  ): AxiosPromise<{ articleComment: ArticleCommentJson }> => {
    return axios.post(
      `${this.getBaseUrl() + articleId}/${commentId}`,
      { updatedContent, isEditing },
      this.getRequestConfig()
    );
  };

  delete = (articleId: string, commentId: string): AxiosPromise => {
    return axios.delete(`${this.getBaseUrl() + articleId}/${commentId}`, this.getRequestConfig());
  };

  count = (articleId: string): AxiosPromise<{ commentsCount: number }> => {
    return axios.get(`${this.getBaseUrl() + articleId}/count`, this.getRequestConfig());
  };
}
