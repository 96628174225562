
import VueApp from '@/@types/app/VueApp';
import { Component } from 'vue-property-decorator';

@Component
export default class ExtensionBanner extends VueApp {
  get extensionUrl() {
    return `https://chrome.google.com/webstore/detail/snaplore/${process.env.VUE_APP_CHROME_EXTENSION_ID}`;
  }

  hideBanner() {
    this.$store.commit('setIsExtensionBannerVisible', false);
  }
}
