
import { Component, Prop } from 'vue-property-decorator';
import UserImg from './UserImg.vue';
import VueApp from '@/@types/app/VueApp';
import { User } from '@/models/User';

@Component({
  components: {
    UserImg,
  },
})
export default class UsersImgContainer extends VueApp {
  @Prop() userList: User[];
  userLimit = 5;

  get users() {
    return this.userList.slice(0, this.userLimit);
  }

  get moreUsers() {
    return this.userList.length - this.userLimit > 0 ? this.userList.length - this.userLimit + 1 : 0;
  }
}
