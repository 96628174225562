import { Workspace } from '@/models/Workspace';
import { ArticleVersionView } from '@/@types/ArticleVersionView';
import { WorkspaceView } from '@/@types/WorkspaceView';

export interface WorkspaceState {
  workspace: Workspace | null; // last or current workspace for create article
  personalWorkspace: Workspace | null;
  workspaces: Workspace[]; // workspaces for display in top menu
  workspaceView: WorkspaceView;
  articleVersionView: ArticleVersionView;
  isReorderMode: boolean;
}

export const workspaceState: WorkspaceState = {
  workspace: null,
  personalWorkspace: null,
  workspaces: [],
  workspaceView: {
    workspace: null,
    articles: [],
    selectedArticle: null,
    commentsCount: 0,
    selectedParagraph: null,
    paragraphProgress: 0,
    showArchivedArticles: false,
    isSharedMode: false,
    isLoading: false,
  },
  articleVersionView: {
    isShowFooter: false,
    articleId: null,
    version: null,
    author: null,
    time: null,
    profileImage: null,
  },
  isReorderMode: false,
};
