
import VueApp from '@/@types/app/VueApp';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import ArticlePlayer from '@/components/article-player/ArticlePlayer.vue';
import CommentsInput from '@/components/layout/inputs/CommentsInput/CommentsInput.vue';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';
import CommentTipTap from '@/components/tiptap/CommentTipTap.vue';
import MenuEmojiButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/EmojiButton.vue';
import EmojiIconButton from '@/components/tiptap/Menu/MenuItems/CommentMenuButtons/EmojiIconButton.vue';
import TimeTag from '@/components/tiptap/Menu/MenuItems/TimeTag/TimeTag.vue';
import helper from '@/helper';
import { ArticleComment } from '@/models/article/ArticleComment';
import { User } from '@/models/User';
import articleCommentService from '@/services/article/ArticleCommentService';
import articleEditService from '@/services/article/ArticleEditService';
import articleService from '@/services/article/ArticleService';
import {
  commitSetCommentsSidebarIsActiveTimeTag,
  commitSetCommentsSidebarIsVisible,
} from '@/store/commits/commentsSidebarCommits';
import {
  dispatchDeleteArticleComment,
  dispatchUpdateArticleComment,
} from '@/store/dispatchers/articleCommentsDispatchers';
import moment, { Moment } from 'moment';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    TimeTag,
    EmojiIconButton,
    MenuEmojiButton,
    CommentsInput,
    UserProfilePhoto,
    CommentTipTap,
    CommentCard: () => import('./CommentCard.vue'),
  },
})
export default class CommentCard extends VueApp {
  @Prop({ type: Array, default: (): [] => [] }) replies: ArticleComment[];
  @Prop() comment: ArticleComment;
  @Prop() user: User;

  isEditMode = false;
  isReply = false;

  get isArticleHasVideo() {
    return articleEditService.articleEditor.article.isVideoArticle();
  }

  get isHighlighted() {
    return this.$store.state.commentsSidebar.scrollToCommentId === this.comment.id;
  }

  get canEdit() {
    const workspace = this.$store.state.workspaceView.workspace
      ? this.$store.state.workspaceView.workspace
      : articleEditService.articleEditor.workspace;
    return workspace?.canEdit(this.user);
  }

  get canDeleteAndEditComment(): boolean {
    return this.user.id === this.comment.userId || this.canEdit;
  }

  get commentAuthor(): string {
    return this.comment
      ? articleCommentService.getAuthorName(this.comment)
      : `${this.user.firstName} ${this.user.lastName}`;
  }

  get hasReplies(): boolean {
    return (this.replies.length && !this.comment.parentId) || this.isReply;
  }

  get commentTimestamp() {
    if (this.comment.timestamp || this.comment.timestamp === 0) {
      return helper.msToTime(this.comment.timestamp);
    }
    return null;
  }

  get videoPlayerRef(): ArticlePlayer {
    return this.$store.state.videoPlayerRef;
  }

  get isActiveTimeTag(): boolean {
    return this.$store.state.commentsSidebar.isActiveTimeTag;
  }

  get replyUser() {
    return this.$store.state.companyUsers.find((user) => user.id === this.comment.userId);
  }

  playVideo(time: number) {
    if (this.isArticleHasVideo) {
      articleService.toggleArticleMode(ArticleEditMode.VIDEO, this.$route);
    }
    commitSetCommentsSidebarIsVisible(false);
    articleEditService.setPosition(time);
    this.videoPlayerRef.$refs.video.play();
  }

  convertUnixToDate(date: Date): Moment | string {
    if (!date) {
      return '-';
    }
    return moment(date).format('MMM DD, YYYY, hh:mm a');
  }

  toggleEditMode(isEditMode = false): void {
    this.isEditMode = isEditMode;
  }

  toggleReplyMode(isReplySaved: boolean): void {
    this.isReply = isReplySaved;
    if (this.isReply) {
      commitSetCommentsSidebarIsActiveTimeTag(false);
    }
  }

  async deleteComment() {
    await dispatchDeleteArticleComment(this.comment);
  }

  async saveComment(content: string) {
    const timestamp = this.isActiveTimeTag ? this.comment.timestamp : null;
    this.toggleEditMode();
    await dispatchUpdateArticleComment(this.comment.id, timestamp, content);
  }
}
