
import VueApp from '@/@types/app/VueApp';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { SocketMessageType } from '@/@types/enums/SocketMessageType';
import { SmartContentType } from '@/@types/SmartContentType';
import PublishArticleButton from '@/components/article-editor/ArticleEditor/PublishArticleButton/PublishArticleButton.vue';
import SmartContentLoader from '@/components/article-editor/EditorNavbar/SmartContentLoader/SmartContentLoader.vue';
import ArticleParagraphTree from '@/components/layout/sidebars/ArticleParagraphTree.vue';
import EventBus from '@/EventBus';
import { ARTICLE_PLAYER_CHANGE_STATE } from '@/events';
import helper from '@/helper';
import { Article } from '@/models/article/Article';
import { Paragraph } from '@/models/article/Paragraph';
import { ArticlePlayerStates } from '@/models/ArticlePlayerStates';
import { Workspace } from '@/models/Workspace';
import { Logger } from '@/other/Logger';
import { ARTICLE_EDIT, DRAFT_EDIT } from '@/routerNames';
import articleEditService from '@/services/article/ArticleEditService';
import notificationService from '@/services/NotificationService';
import socketService from '@/services/socket/SocketService';
import articlePlayerProvider from '@/services/ui-providers/ArticlePlayerProvider';
import urlService from '@/services/UrlService';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { dispatchGetDraftsCount } from '@/store/dispatchers/uiDispatchers';
import { Component, Watch } from 'vue-property-decorator';

const TIMEOUT = 250;

const log = new Logger('ArticleSidebar');

@Component({
  computed: {
    SmartContentType() {
      return SmartContentType;
    },
  },
  components: {
    PublishArticleButton,
    SmartContentLoader,
    ArticleParagraphTree,
  },
})
export default class ArticleSidebar extends VueApp {
  loader: any = null;
  searchQuery = '';
  searchedWorkspaces: Workspace[] = [];
  skipOnSearch = true;
  timeout: number = null;

  isAiParagraphsLoading = false;
  aiParagraphsProgress = 0;

  $refs: {
    articleTitleField: any;
  };

  get isVideoProcessing() {
    return this.$store.state.articleEditor.isVideoProcessing;
  }

  get workspaces(): Workspace[] {
    return this.$store.state.workspaces;
  }

  get articleEditor() {
    return this.$store.state.articleEditor;
  }

  get article(): Article {
    return this.articleEditor.article;
  }

  get workspace(): Workspace {
    return this.articleEditor.workspace as Workspace;
  }

  get accessibleWorkspaces(): Workspace[] {
    const personalWorkspace: Workspace = this.$store.state.personalWorkspace;
    const workspaces: Workspace[] = this.workspaces.filter(
      (workspace: Workspace) => workspace.canEditArticles() && workspace.id !== personalWorkspace.id
    );

    return workspaces.length ? [personalWorkspace, ...workspaces] : [personalWorkspace];
  }

  getWorkspaceTitle(workspace: Workspace, emptyTitle = ''): string {
    return workspaceService.getWorkspaceTitle(workspace, emptyTitle);
  }

  saveTitleAsDraft() {
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(async () => {
      if (this.articleEditor.isAutosaveEnabled) {
        await articleEditService.saveAsDraft();
      }
    }, TIMEOUT);
  }

  onOpenWorkspace() {
    this.searchedWorkspaces = this.accessibleWorkspaces;
    //for setting custom dropdown width
    this.$nextTick(() => {
      const fieldWidth = this.$refs.articleTitleField.$el.offsetWidth;
      const dropDownSelect = document.querySelector(
        '.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default'
      ) as HTMLElement;
      dropDownSelect.style.width = `${fieldWidth}px`;
      dropDownSelect.style.maxWidth = `${fieldWidth}px`;
    });
  }

  onSelectWorkspace(workspace: Workspace) {
    this.searchQuery = this.getWorkspaceTitle(workspace);
    this.article.workspace = workspace.id;
  }

  navigateToWorkspace() {
    if (this.workspace) {
      this.$router.push(urlService.getWorkspaceViewRoute(this.workspace));
    }
  }

  closeEditNodes(nodes: any) {
    nodes.forEach((node: any) => {
      node.data.isEdit = false;
      if (node.children.length > 0) {
        this.closeEditNodes(node.children);
      }
    });
    window.setTimeout(() => {
      EventBus.$emit(ARTICLE_PLAYER_CHANGE_STATE, ArticlePlayerStates.paused);
    }, 10);
  }

  findArticleParagraph(nodes: any, key: string) {
    let node = nodes.find((item: any) => item.key === key);
    if (node) {
      return node;
    }

    nodes.forEach((item: any) => {
      if (item.items.length > 0) {
        const nestedNode = this.findArticleParagraph(item.items, key);
        if (nestedNode) {
          node = nestedNode;
        }
      }
    });

    return node;
  }

  isValid(key: 'workspace' | 'title') {
    return this.article && this.article[key];
  }

  mounted() {
    this.setWorkspaceTitle();
  }

  setWorkspaceTitle() {
    if (this.article) {
      const workspace = this.accessibleWorkspaces.find((item: Workspace) => item.id === this.article.workspace);
      this.searchQuery = this.getWorkspaceTitle(workspace);
    }
  }

  async handleGenerateAiParagraphs(resume = false) {
    this.aiParagraphsProgress = 0;
    const articleId = this.article.id;

    this.isAiParagraphsLoading = true;

    await dispatchGetDraftsCount();

    try {
      const res = await socketService.article.ai.generateAiParagraphs(
        articleId,
        resume ? SocketMessageType.RECONNECT : SocketMessageType.START,
        (statusData) => {
          const progress = statusData.status.progress;
          EventBus.$emit('smart-paragraphs-status-update-menu', {
            isLoading: true,
            progress,
          });
          this.aiParagraphsProgress = progress;
        }
      );

      if (articleId !== this.articleEditor.article.id) return;

      const paragraphs = JSON.parse(res);

      if (!paragraphs.length) {
        notificationService.warning('Oops! We were’t able to generate paragraphs for this recording.');
        return;
      }

      const parsedParagraphs: Paragraph[] = [];

      // Parsing each paragraph
      for (const paragraph of paragraphs) {
        parsedParagraphs.push(Paragraph.parse(paragraph));
      }

      this.articleEditor.article.paragraphs = parsedParagraphs;

      await dispatchUpdateSelectedTextTab(null);

      switch (this.$route.name) {
        case DRAFT_EDIT: {
          await this.$router.push({ name: DRAFT_EDIT, params: { mode: ArticleEditMode.VIDEO } });
          break;
        }
        case ARTICLE_EDIT: {
          await this.$router.push({ name: ARTICLE_EDIT, params: { mode: ArticleEditMode.VIDEO } });
          break;
        }
      }

      commitSetArticleMode(ArticleEditMode.VIDEO);

      helper.observerNotify(this.articleEditor.article.paragraphs);
      window.setTimeout(() => {
        articlePlayerProvider.videoPlayer.updatePlayerRef();
        articlePlayerProvider.videoPlayer.update();
        EventBus.$emit(ARTICLE_PLAYER_CHANGE_STATE, ArticlePlayerStates.paused);
      }, 0);
    } catch (err: any) {
      switch (err?.type) {
        case 'success':
          return;
        case 'warning':
          return notificationService.warning(err.message);
        case 'error': {
          log.error(err);
          return notificationService.error(err.message);
        }
      }
    } finally {
      this.isAiParagraphsLoading = false;

      EventBus.$emit('smart-paragraphs-status-update-menu', {
        isLoading: false,
        progress: 0,
      });
    }
  }

  handleCancelAiGeneration(emit = true) {
    this.isAiParagraphsLoading = false;
    if (emit) {
      EventBus.$emit('smart-paragraphs-status-update-menu', {
        isLoading: false,
        progress: 0,
        canceled: true,
      });
    }
    socketService.article.ai.stopAiParagraphsGeneration(this.article.id);
  }

  created() {
    EventBus.$on(
      'smart-paragraphs-status-update-sidebar',
      (statusData: { isLoading: boolean; progress: number; canceled?: boolean }) => {
        if (statusData.canceled) return this.handleCancelAiGeneration(false);
        this.aiParagraphsProgress = statusData.progress;
        this.isAiParagraphsLoading = statusData.isLoading;
      }
    );
  }

  @Watch('article')
  onArticleChanged(value: Article | null) {
    if (value) {
      this.setWorkspaceTitle();
    }

    articleEditService.generateTree();
  }

  @Watch('article.workspace')
  onWorkspaceChanged() {
    const workspace = articleEditService.articleWorkspace;
    if (workspace) {
      this.searchQuery = this.getWorkspaceTitle(workspace);
      this.skipOnSearch = true;
    }

    if (workspace) articleEditService.setState('workspace', workspace);

    //prevent save article when video not uploaded to avoid conflict with extension
    if (this.article.isNotUploadedVideo) return;
    if (workspace) articleEditService.saveAsDraft();
  }

  @Watch('searchQuery')
  onSearchChanged() {
    if (this.skipOnSearch) {
      this.skipOnSearch = false;
    }
  }
}
