export enum ExtensionMessageType {
  GET_EXTENSION_INFORMATION = 'GET_EXTENSION_INFORMATION',
  EXTENSION_INFORMATION = 'EXTENSION_INFORMATION',

  SET_LOGGED_USER_APP = 'SET_LOGGED_USER_APP',
  SET_LOGOUT_APP = 'SET_LOGOUT_APP',

  RECORD_VIDEO_FOR_TAB = 'RECORD_VIDEO_FOR_TAB',
  ARTICLE_PUBLISHED = 'ARTICLE_PUBLISHED',
  START_RECORDING_FROM_APP = 'START_RECORDING_FROM_APP',

  RECORDING_UPLOAD_PROGRESS = 'RECORDING_UPLOAD_PROGRESS',
  RECORDING_UPLOAD_FINISHED = 'RECORDING_UPLOAD_FINISHED',
  RECORDING_CANCELED = 'RECORDING_CANCELED',

  ARTICLE_RECORDING_UPLOAD_FINISHED = 'ARTICLE_RECORDING_UPLOAD_FINISHED',
  ARTICLE_RECORDING_UPLOAD_PROGRESS = 'ARTICLE_RECORDING_UPLOAD_PROGRESS',
  ARTICLE_RECORDING_UPLOAD_FAILED = 'ARTICLE_RECORDING_UPLOAD_FAILED',
}
