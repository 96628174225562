import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';

export class SharedArticle implements Model {
  articleId: string | null = null;
  token: string | null = null;

  static fromJson(jsonData: unknown) {
    return modelService.create(SharedArticle, jsonData);
  }

  afterLoad(data: any): void {
    this.articleId = data.articleId;
    this.token = data.token;
  }
}
