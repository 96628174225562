import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios from 'axios';
import { ArticleReactionResponse, GetArticleReactionsResponse } from '@/@types/responses/ArticleResponses';
import { RecordingReaction } from '@/@types/RecordingReaction';

export class ArticleReactionsServiceProvider extends ApiBaseServiceProvider {
  scope = 'article';

  get = (id: string) => {
    return axios.get<GetArticleReactionsResponse>(this.getBaseUrl() + id + '/reactions', this.getRequestConfig());
  };

  create = (reaction: RecordingReaction) => {
    const id = reaction.articleId;
    return axios.post<ArticleReactionResponse>(this.getBaseUrl() + id + '/reaction', reaction, this.getRequestConfig());
  };

  async getByCommentId(commentId: string, articleId: string) {
    return axios.get<GetArticleReactionsResponse>(
      `${this.getBaseUrl() + articleId}/reactions/find?commentId=${commentId}`,
      this.getRequestConfig()
    );
  }

  async delete(reactionId: string, articleId: string) {
    return axios.delete(`${this.getBaseUrl() + articleId}/reactions/${reactionId}`, this.getRequestConfig());
  }
}
