export interface ExtensionState {
  id?: string;
  baseUrl?: string;
  isAvailable: boolean;
  isEnabled: boolean;
  version?: string;
}

export const extensionState: ExtensionState = {
  id: undefined,
  baseUrl: undefined,
  version: undefined,
  isAvailable: false,
  isEnabled: false,
};
