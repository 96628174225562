import { User } from './User';
import { Role } from './Role';

export class RolesCollection {
  static collect(roles: any[]) {
    const data: any[] = [];

    if (roles.length) {
      roles.forEach((item) => {
        if (item.user && item.role) {
          data.push(new Role(item.user, item.role));
        }
      });
    }

    return new RolesCollection(data);
  }
  roles: any[] = [];

  constructor(roles: any[]) {
    this.roles = roles;
  }

  checkRole(user: User, role: any) {
    const userRole = this.getUserRole(user);
    let hasRole = false;
    const roles = role.length ? role : [role + ''];

    if (roles.indexOf(userRole) >= 0) {
      hasRole = true;
    }

    return hasRole;
  }
  getUserRole(user: User) {
    let role = null;
    if (this.roles && this.roles.length) {
      const items = this.roles.filter((item) => {
        return item.user === user.id;
      });

      if (items.length && items[0]) {
        role = items[0].role;
      }
    }

    return role;
  }
}
