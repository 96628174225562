import { Company } from '@/models/Company';
import { User } from '@/models/User';

export interface UserState {
  user: User;
  userCompanies: Company[];
  isUserInactiveInCompany: boolean;
}

export const userState: UserState = {
  user: null,
  userCompanies: [],
  isUserInactiveInCompany: true,
};
