
import VueApp from '@/@types/app/VueApp';
import { HOME } from '@/routerNames';
import { Component, Prop } from 'vue-property-decorator';
import AdminCompanies from '@/components/pages/admin/AdminCompanies/AdminCompanies.vue';
import AdminDashboard from '@/components/pages/admin/AdminDashboard/AdminDashboard.vue';
import AdminJobs from '@/components/pages/admin/AdminJobs/AdminJobs.vue';
import AdminCompany from '@/components/pages/admin/AdminCompany/AdminCompany.vue';

type Page = 'dashboard' | 'companies' | 'jobs' | 'company';

@Component({
  components: { AdminCompany, AdminCompanies, AdminDashboard, AdminJobs },
})
export default class AdminPanel extends VueApp {
  @Prop({ type: String, default: 'dashboard' }) page: Page;
  @Prop({ type: String, default: null }) id: string;

  navigate(page: Page) {
    this.$router.push({ name: this.$route.name, params: { page } });
  }

  mounted() {
    const user = this.$store.state.user;

    if (!user || !user.isSuperAdmin()) {
      this.$router.push({ name: HOME });
    }
  }
}
