
import VueApp from '@/@types/app/VueApp';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { DeletedItemStyleClass } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemStyleClass';
import { Article } from '@/models/article/Article';
import * as routerNames from '@/routerNames';
import articleEditService from '@/services/article/ArticleEditService';
import articleService from '@/services/article/ArticleService';
import confirmationService from '@/services/ConfirmationService';
import notificationService from '@/services/NotificationService';
import store from '@/store';
import {
  commitSetArticleEditorArticleDuration,
  commitSetArticleEditorArticleParagraphs,
} from '@/store/commits/articleEditorCommits';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ArticleVideoModeButton extends VueApp {
  @Prop({ type: String, default: 'is-video-icon' }) iconClass: string;
  @Prop({ type: Boolean, default: false }) isActive: boolean;
  @Prop({ type: Boolean, default: false }) isHasContent: boolean;
  @Prop({ type: Boolean, default: false }) isShowAddIcon: boolean;
  @Prop({ type: Article, required: true }) article: Article;
  @Prop(String) title: string;
  @Prop(String) titleNoContent: string;

  loader: ElLoadingComponent = null;

  get isArticleEditPage() {
    return [
      routerNames.ARTICLE_CREATE,
      routerNames.ARTICLE_EDIT,
      routerNames.ARTICLE_VERSION_EDIT,
      routerNames.DRAFT_EDIT,
    ].includes(this.$route.name);
  }

  get isDraftEditPage() {
    return this.$route.name === routerNames.DRAFT_EDIT;
  }

  get isEditPage() {
    return this.isDraftEditPage || this.isArticleEditPage;
  }

  get isArticleHasVideo() {
    return store.state.articleEditor.article.hasParagraphs();
  }

  handleClick() {
    this.$emit('click');
  }

  async handleClickDelete() {
    const result = await confirmationService
      .showRemovePopup('Recording', '', DeletedItemStyleClass.ARTICLE)
      .catch(() => 'cancel');

    if (result !== 'confirm') return;

    try {
      this.loader = this.$loading({
        lock: true,
        text: 'Deleting recording',
      });

      const textTabs = articleEditService.articleEditor.article.textTabs;

      commitSetArticleEditorArticleParagraphs([]);
      commitSetArticleEditorArticleDuration(0);

      if (textTabs.length) {
        await articleService.toggleArticleMode(ArticleEditMode.NOTES, this.$route);
      }

      await dispatchUpdateSelectedTextTab(textTabs.length ? textTabs[0] : null);

      commitSetArticleMode(textTabs.length ? ArticleEditMode.NOTES : null);

      await articleEditService.saveAsDraft();

      notificationService.success('The recording was successfully deleted.');
    } finally {
      this.loader.close();
      this.loader = null;
    }
  }
}
