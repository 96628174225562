
import { Component, Prop } from 'vue-property-decorator';
import { Group } from '@/models/Group';
import VueApp from '@/@types/app/VueApp';

@Component
export default class FindGroupDialog extends VueApp {
  @Prop() visible: boolean;
  @Prop({ type: Array, default: null }) workspaceGroups: Group[];

  loading = false;
  suggestList: any[] = [];
  foundData: string[] = [];

  $refs: {
    searchQuery: any;
  };

  remoteMethod(query: string) {
    this.loading = true;
    const clearQuery = this.clearText(query);
    if (clearQuery) {
      this.$api.groups
        .find(clearQuery)
        .then((res) => {
          this.suggestList = res.data.items
            ? res.data.items.map((item: any) => {
                const group = Group.parse(item);
                const groupTitle = group.title;
                const isGroupInSet =
                  this.clearText(groupTitle).includes(clearQuery) && !this.hasWorkspaceGroup(group.id);

                if (isGroupInSet) {
                  return {
                    value: group.id,
                    label: groupTitle,
                  };
                }
              })
            : [];

          this.removeSuggestedGroup();
        })
        .catch((res) => this.$api.handleResponseError(res))
        .finally(() => {
          this.loading = false;
        });
    } else if (this.suggestList.length) {
      this.$api.groups
        .all()
        .then((res) => {
          this.suggestList = res.data.groups
            ? res.data.groups.map((item: any) => {
                const group = Group.parse(item);
                const groupTitle = group.title;
                const isGroupInSet = !this.hasWorkspaceGroup(group.id);

                if (isGroupInSet) {
                  return {
                    value: group.id,
                    label: groupTitle,
                  };
                }
              })
            : [];

          this.removeSuggestedGroup();
        })
        .catch((res) => this.$api.handleResponseError(res))
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  clearText(text: string): string {
    return text.trim().toLowerCase();
  }

  handleAdd() {
    this.$emit('select', JSON.parse(JSON.stringify(this.foundData)));
    this.foundData = [];
    this.removeUndefinedSuggested();
  }

  handleClose() {
    this.$nextTick(() => {
      this.foundData = [];
      this.$emit('update:visible', false);
    });
  }

  hasWorkspaceGroup(groupId: string): boolean {
    return this.workspaceGroups.findIndex((group: Group) => group.id === groupId) !== -1;
  }

  removeSuggestedGroup() {
    this.removeUndefinedSuggested();
    if (this.foundData.length > 0) {
      this.foundData.forEach((addedGroup) => {
        const foundIndex = this.suggestList.findIndex((suggestGroup) => suggestGroup.value === addedGroup);

        if (foundIndex >= 0) {
          this.suggestList.splice(foundIndex, 1);
        }
      });
    }
  }

  removeUndefinedSuggested() {
    this.suggestList = this.suggestList.filter((item: any) => typeof item !== 'undefined');
  }

  updateSelect() {
    this.$nextTick(() => {
      this.removeSuggestedGroup();
      this.$refs.searchQuery.query = '';
      this.remoteMethod(' ');
    });
  }
}
