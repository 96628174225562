import { Company } from './Company';
import { Group } from '@/models/Group';
import { Model } from '@/models/Model';
import modelService from '@/services/ModelService';

export class User implements Model {
  static roles = ['user', 'moderator', 'admin', 'owner'];
  static adminRoles = ['admin', 'moderator', 'owner'];

  id: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  email: string | null = null;
  company: string | Company | null = null;
  role: string | null = null;
  groups: Group[] = [];
  globalRoles: string[] = [];
  companyIds: string[] = null;
  jobTitle: string | null = null;
  birthDay: Date | null = null;
  profileImage: string | null = null;
  profileImagePreview: string | null = null;
  personalWorkspaceId: string | null = null;
  lastTimeLoggedIn: number | null = null;
  paddle: {
    customerId: string;
  } | null = null;

  static parse(jsonData: unknown): User {
    return modelService.create(User, jsonData);
  }

  getName(): string {
    return (this.firstName + ' ' + this.lastName).trim();
  }

  get name(): string {
    return this.getName();
  }

  afterLoad(data: { company: unknown; groups: unknown[]; globalRoles: string[] }): void {
    const { company, groups, globalRoles } = data;
    this.globalRoles = globalRoles || [];

    if (company) {
      this.company =
        typeof company === 'object'
          ? modelService.create(Company, company)
          : typeof company === 'string'
          ? company
          : null;
    }

    if (groups) {
      this.groups = [];
      groups.forEach((item: unknown) => {
        this.groups.push(Group.parse(item) as Group);
      });
    }
  }

  clear(): void {
    for (const key in this) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(key)) {
        this[key] = null;
      }
    }
  }

  checkRole(role: string): boolean {
    const roleIndex = User.roles.indexOf(role);
    const userIndex = User.roles.indexOf(this.role + '');

    return userIndex >= roleIndex;
  }

  checkAdminRole(role: string): boolean {
    return User.adminRoles.indexOf(role) >= 0;
  }

  isSuperAdmin(): boolean {
    return this.globalRoles.indexOf('admin') >= 0 || this.globalRoles.indexOf('owner') >= 0;
  }

  isAdmin(): boolean {
    return this.role === 'admin';
  }

  isOwner(): boolean {
    return this.role === 'owner';
  }

  isAdminOrOwner(): boolean {
    return this.isAdmin() || this.isOwner();
  }

  toObject(): any {
    const json = JSON.parse(JSON.stringify(this));

    json.company = this.company && typeof this.company === 'object' ? this.company.id : this.company;
    return json;
  }
}
