
import VueApp from '@/@types/app/VueApp';
import { Component, Prop } from 'vue-property-decorator';
import { Mutations } from '@/store/mutations';
import { Article } from '@/models/article/Article';
import { Workspace } from '@/models/Workspace';
import { WORKSPACES } from '@/routerNames';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import { WorkspaceVisibility } from '@/other/WorkspaceVisibility';
import WorkspaceTopbarIcon from '@/components/layout/sidebars/WorkspaceSidebar/WorkspaceTopbarIcon/WorkspaceTopbarIcon.vue';
import { commitToggleWorkspacesSidebar } from '@/store/commits/uiCommits';

@Component({
  components: { WorkspaceTopbarIcon },
})
export default class WorkspaceToolbar extends VueApp {
  @Prop({ type: Boolean }) isReorderMode: boolean;

  get isBackButtonVisible(): boolean {
    return this.isAuthorized && !(this.$route?.params?.workspaceId === this.personalWorkspaceShortId);
  }

  get workspaceView() {
    return workspaceService.workspaceView;
  }

  get isAuthorized() {
    return this.$store.state.user && this.$auth.isAuthorized();
  }

  get personalWorkspaceShortId() {
    return this.$store.state.personalWorkspace?.shortId;
  }

  get isPersonalWorkspace() {
    return this.workspaceView.workspace?.visibility === WorkspaceVisibility.personal;
  }

  get workspaceVisibility() {
    return this.workspaceView.workspace?.visibility;
  }

  get canDeleteWorkspace(): boolean {
    return !this.isPersonalWorkspace && this.workspaceView.workspace.canDelete();
  }

  get canArchiveWorkspace(): boolean {
    return !this.isPersonalWorkspace && this.workspaceView.workspace.canEdit();
  }

  get allowEditDeleteArticle() {
    return this.workspaceView.workspace ? this.workspaceView.workspace.canEditArticles() : false;
  }

  get allowEditWorkspace() {
    if (this.workspaceView.workspace) {
      return !this.isPersonalWorkspace && this.workspaceView.workspace.canEdit();
    }
    return false;
  }

  get allowEditArticles() {
    if (this.workspaceView.workspace) {
      return this.workspaceView.workspace.canEditArticles();
    }
    return false;
  }

  get isArchivedArticles() {
    const articles = this.workspaceView.articles;
    const hiddenArticles = articles.filter((article: Article) => article.isArchived === true);
    return !!hiddenArticles.length;
  }

  get isWorkspaceArchived(): boolean {
    return this.workspaceView.workspace.isArchived;
  }

  get isActiveArticles(): boolean {
    const activeArticles = this.workspaceView.articles.filter((article) => !article.isArchived);

    return !!activeArticles && activeArticles.length >= 2;
  }

  toggleSidebar() {
    commitToggleWorkspacesSidebar();
  }

  handleEditWorkspace() {
    workspaceService.editWorkspace();
  }

  handleRemoveWorkspace() {
    workspaceService.deleteWorkspace(this.workspaceView.workspace, this.redirectToWorkspaces);
  }

  handleReorderArticles() {
    this.$store.commit(Mutations.SET_REORDER_MODE, !this.isReorderMode);
  }

  handleShowArchivedArticles() {
    const hiddenArticles = document.querySelectorAll('.hideArchivedArticle');
    workspaceService.setState('showArchivedArticles', true);
    if (hiddenArticles.length) {
      hiddenArticles.forEach((article) => {
        article.classList.remove('hideArchivedArticle');
      });
    }
  }

  async handleToggleWorkspaceArchive() {
    const workspace = this.workspaceView.workspace;
    await workspaceService.toggleWorkspaceArchive(workspace, workspace.isArchived);
  }

  handleWorkspaceTitle(workspace: Workspace, emptyTitle = '') {
    return workspaceService.getWorkspaceTitle(workspace, emptyTitle);
  }

  redirectToWorkspaces() {
    this.$router.push({ name: WORKSPACES });
  }
}
