import { TransferArticleOptions } from '@/@types/TransferArticleOptions';
import { TextTab } from '@/models/article/TextTab';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios, { AxiosPromise } from 'axios';
import { Article } from '@/models/article/Article';
import { GetArticleResponse } from '@/@types/responses/ArticleResponses';
import { ArticleVersion } from '@/@types/ArticleVersion';
import { SaveOptions } from '@/@types/SaveOptions';

export class ArticleServiceProvider extends ApiBaseServiceProvider {
  scope = 'article';

  get = (id: string, allowDraft = false) => {
    return axios.get<GetArticleResponse>(
      this.getBaseUrl() + id + (allowDraft ? '?draft=1' : ''),
      this.getRequestConfig()
    );
  };

  save = (article: Article, options: SaveOptions) => {
    const { updatedByUser, isPublish = false, isKeepDraft = false, isNotification = false } = options;

    const id = article.draft && article.original ? article.original : article.id;
    return axios.post<GetArticleResponse>(
      this.getBaseUrl() + (id ? id : ''),
      Object.assign({}, article, { updatedByUser, isPublish, isKeepDraft, isNotification }),
      this.getRequestConfig()
    );
  };

  transfer = (articleId: string, options: TransferArticleOptions) => {
    return axios.put<{ success: boolean }>(
      this.getBaseUrl() + `transfer/${articleId}`,
      { ...options },
      this.getRequestConfig()
    );
  };

  merge = (articleId: string, targetId: string) => {
    return axios.post(this.getBaseUrl() + 'merge', { articleId, targetId }, this.getRequestConfig());
  };

  duplicate = (articleId: string) => {
    return axios.post<{ workspaceOrder: any[]; article: Article }>(
      this.getBaseUrl() + 'duplicate',
      { articleId },
      this.getRequestConfig()
    );
  };

  getTranscription = (articleId: string) => {
    return axios.get<{ transcription: string }>(
      `${this.getBaseUrl() + articleId}/transcription`,
      this.getRequestConfig()
    );
  };

  getVideoUrls = (articleId: string): AxiosPromise<{ urls: string[] }> => {
    return axios.get(`${this.getBaseUrl() + articleId}/videos`, this.getRequestConfig());
  };

  getVersions = (workspaceId: string, articleId: string): AxiosPromise<{ versions: ArticleVersion[] }> => {
    return axios.get(`${this.getBaseUrl() + workspaceId + '/' + articleId}/versions`, this.getRequestConfig());
  };

  getVersion = (workspaceId: string, articleId: string, version: number): AxiosPromise<{ article: any }> => {
    return axios.get(`${this.getBaseUrl() + workspaceId + '/' + articleId}/v/${version}`, this.getRequestConfig());
  };

  deleteVersion = (workspaceId: string, articleId: string, version: number): AxiosPromise<{ article: any }> => {
    return axios.delete(`${this.getBaseUrl() + workspaceId + '/' + articleId}/v/${version}`, this.getRequestConfig());
  };

  loadNotes = (articleId: string) => {
    return axios.get<{ notes: TextTab[] }>(`${this.getBaseUrl()}${articleId}/notes`, this.getRequestConfig());
  };
}
