
import VueApp from '@/@types/app/VueApp';
import adminCompanyService from '@/services/company/AdminCompanyService';
import ValidateService from '@/services/validations/ValidateService';
import { Component, Watch } from 'vue-property-decorator';
import { Company } from '@/models/Company';
import { ElLoadingComponent } from 'element-ui/types/loading';
import urlService from '@/services/UrlService';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { minSlugLength } from '@/services/validations/customFormValidator/minSlugLength';

const validations = {
  form: {
    companyName: {
      required,
      minSlugLength: minSlugLength(3),
    },
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component({
  mixins: [validationMixin],
  validations,
})
export default class CreateCompanyDialog extends VueApp {
  visible = false;
  resolve: (value?: any) => void = null;
  reject: (value?: any) => void = null;
  form = {
    companyName: '',
  };

  $refs: {
    companyName: any;
  };

  get ValidateService() {
    return new ValidateService(this, this.handleSave);
  }

  handleClosed() {
    if (this.reject) {
      this.reject();
    }
  }

  handleCreate() {
    this.ValidateService.validateForm();
  }

  async handleSave() {
    const loader = this.$loading({
      text: 'Creating company...',
    }) as ElLoadingComponent & { text: string };

    try {
      const res = await this.$api.company.create({
        title: this.form.companyName.trim(),
      });

      const company = Company.parse(res.data.company);
      const currentDomain = document.location.origin;
      const targetDomain = company.getDomainAddress();

      // Check domain availability before switching company
      if (currentDomain !== targetDomain) {
        const isAvailable = await urlService.checkDomain(targetDomain);
        if (!isAvailable) {
          this.reject(new Error('Domain name is not available'));
          return;
        }
      }

      this.reject = null;
      this.visible = false;
      this.resolve(company);
      await adminCompanyService.switchCompany(company);
    } catch (res) {
      this.reject(res);
      this.$api.handleResponseError(res);
    } finally {
      loader.close();
    }
  }

  show() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
      this.visible = true;
    });
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.handleCreate();
    }
  }

  triggerFocusInput() {
    window.setTimeout(() => {
      this.$refs.companyName.focus();
    }, 0);
  }

  @Watch('visible')
  onVisibleChanged(value: any) {
    if (value) {
      this.form.companyName = '';
      window.addEventListener('keypress', this.handleKeyboardEvent);
      this.triggerFocusInput();
    } else {
      window.removeEventListener('keypress', this.handleKeyboardEvent);
    }
  }
}
