
import VueApp from '@/@types/app/VueApp';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import { Prop, Watch, Component } from 'vue-property-decorator';
import { User } from '@/models/User';
import { commitToggleArticleVersionsSidebar } from '@/store/commits/uiCommits';
import { ElLoadingComponent } from 'element-ui/types/loading';
import handleOutsideClickDirective from '@/directives/handleOutsideClickDirective';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';
import { ArticleVersion } from '@/@types/ArticleVersion';
import moment from 'moment';
import {
  dispatchCloseArticleVersionMode,
  dispatchLoadArticleVersion,
} from '@/store/dispatchers/articleVersionDispatchers';
import store from '@/store';

@Component({
  directives: {
    'click-outside': handleOutsideClickDirective(() => {
      if (store.state.ui.isArticleVersionsSidebar) {
        store.commit('toggleArticleVersionsSidebar', false);
      }
    }, ['navbar-button-title', 'icon-restore', 'md-list-item-content']),
  },
  components: {
    UserProfilePhoto,
  },
})
export default class ArticleVersionsSidebar extends VueApp {
  @Prop({ type: Boolean, default: false }) visible: boolean;

  versions: ArticleVersion[] = [];
  loader: ElLoadingComponent;
  showList = false;

  get articleId() {
    return this.$store.state.articleVersionView.articleId;
  }

  get workspaceView() {
    return this.$store.state.workspaceView;
  }

  get isMobile(): boolean {
    return mobileResponsiveService.isMobileView;
  }

  get isExtensionBannerVisible() {
    return this.$store.state.ui.isExtensionBannerVisible;
  }

  toggleExpandList() {
    this.showList = this.versions.length ? !this.showList : false;
  }

  getArticleVersionSidebarStyles() {
    return {
      paddingTop: !this.isMobile && this.isExtensionBannerVisible ? '100px' : '60px',
      width: '289px',
      overflow: 'auto',
    };
  }

  getAuthorName(author: User) {
    if (author?.firstName && author?.lastName) {
      return author.firstName + ' ' + author.lastName;
    }
    return 'Unknown author';
  }

  async backToLatestVersion() {
    await dispatchCloseArticleVersionMode(this.articleId);
    this.handleClose();
  }

  getArticleVersionViewStyles() {
    const heightList = 180;
    return {
      maxHeight: `calc(100vh - ${heightList}px)`,
    };
  }

  async loadVersions() {
    this.versions = [];
    if (!this.articleId) return;
    try {
      const response = await this.$api.article.getVersions(
        this.workspaceView.workspace.id,
        this.workspaceView.selectedArticle.id
      );
      const versions: ArticleVersion[] = response.data.versions;
      this.versions = versions.length ? versions.reverse() : [];
      if (this.versions.length) {
        this.showList = true;
      }
    } catch (error) {
      this.$api.handleResponseError(error);
    }
  }

  handleClose() {
    commitToggleArticleVersionsSidebar();
  }

  async handleClickLoad(articleVersion: ArticleVersion) {
    await dispatchLoadArticleVersion(articleVersion);
    this.handleClose();
  }

  formatDate(timestamp: number) {
    if (!timestamp) {
      return 'indefinite time';
    }
    return moment(timestamp).calendar(null, {
      lastWeek: 'MMM DD hh:mm a',
      lastDay: 'MMM DD hh:mm a',
      sameDay: '[Today] hh:mm a ',
      sameElse: 'MMM DD hh:mm a',
    });
  }

  @Watch('visible')
  load() {
    if (this.visible) {
      this.loadVersions();
    }
  }
}
