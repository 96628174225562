
import VueApp from '@/@types/app/VueApp';
import EventBus from '@/EventBus';
import { Component, Watch } from 'vue-property-decorator';
import { Workspace } from '@/models/Workspace';
import { Article } from '@/models/article/Article';
import { User } from '@/models/User';
import { Group } from '@/models/Group';
import { Company } from '@/models/Company';
import { Logger } from '@/other/Logger';
import { WORKSPACES } from '@/routerNames';
import GroupsPermissionManager from '@/components/pages/workspace/WorkspaceEditPage/GroupsPermissionManager.vue';
import UsersPermissionManager from '@/components/pages/workspace/WorkspaceEditPage/UsersPermissionManager.vue';
import workspaceService from '@/services/workspace/WorkspaceService';
import notificationService from '@/services/NotificationService';
import urlService from '@/services/UrlService';
import { Route } from 'vue-router/types/router';
import { AxiosError } from 'axios';
import WorkspaceVisibilityInfo from '@/components/layout/shared/WorkspaceVisibilityInfo/WorkspaceVisibilityInfo.vue';

const log = new Logger('WorkSpaceEditPage');

@Component({
  components: {
    WorkspaceVisibilityInfo,
    UsersPermissionManager,
    GroupsPermissionManager,
  },
})
export default class WorkspaceEditPage extends VueApp {
  workspace: Workspace | null = null;
  articles: Article[] = [];
  userRoles: any[] = [];
  groupRoles: Group[] = [];
  private loader: any = null;
  private loaded = false;
  private isValid = true;
  private isTitleFieldEditable = false;
  private isDescriptionFieldEditable = false;
  private originalTitle: string;
  private originalDescription: string;
  private editWorkspaceData: Partial<Workspace> = {};
  private isVisibilitySelectOpened = false;
  private isSlackHidden = true;

  $refs: {
    workspace_description: any;
    workspace_title: any;
  };

  get company(): Company | null {
    if (this.$store.state.pageCompany) {
      return this.$store.state.pageCompany;
    } else if (this.$store.state.company) {
      return this.$store.state.company;
    }
    return null;
  }

  get workspaceView() {
    return workspaceService.workspaceView;
  }

  async cancel() {
    try {
      if (this.workspaceView.workspace) {
        const res = await this.$api.workspace.get(this.$route.params.id);
        const workspace = Workspace.parse(res.data.workspace);
        await this.$router.push(urlService.getWorkspaceViewRoute(workspace));
      } else {
        this.$router.push({ name: WORKSPACES });
      }
    } catch (error) {
      log.error(error);
    }
  }

  remove() {
    workspaceService.deleteWorkspace(this.workspace, this.redirectToWorkspaces);
  }

  redirectToWorkspaces() {
    this.$router.push({ name: WORKSPACES });
  }

  integrateSlack() {
    document.location.href =
      process.env.VUE_APP_SERVER_URL + '/integration/slack/' + this.workspace.id + '?token=' + this.$api.authToken;
  }

  disableSlackIntegration() {
    const loader = this.$loading({});
    this.$api
      .disableSlackIntegration(this.workspace.id)
      .then(() => {
        this.workspace.slackIntegration.accessToken = null;
        loader.close();
      })
      .catch((err: any) => {
        loader.close();
        this.$api.handleResponseError(err);
      });
  }

  async handleReloadUserRoles(workspaceId: string) {
    await this.loadUserRoles(workspaceId);
  }

  async handleReloadGroupRoles(workspaceId: string) {
    await this.loadGroupRoles(workspaceId);
  }

  handleError(error: AxiosError) {
    if ([403, 422].includes(error.response.status)) {
      this.redirectToWorkspaces();
      log.error(error);
    } else {
      this.$api.handleResponseError(error);
    }
  }

  async loadUserRoles(id: string) {
    const res = await this.$api.workspace.getUserRoles(id);
    const roles: User[] = res.data.roles.map((item: any) => {
      return User.parse(item);
    });

    this.userRoles = roles.sort((a: User, b: User) => (a.getName() > b.getName() ? 1 : -1));
  }

  async loadGroupRoles(id: string): Promise<void> {
    try {
      const res = await this.$api.workspace.groupRoles.all(id);
      const roles: Group[] = res.data.roles.map((item) => Group.parse(item));

      this.groupRoles = roles.sort((a: Group, b: Group) => (a.title > b.title ? 1 : -1));
    } catch (error) {
      log.error(error);
      this.$api.handleResponseError(error);
    }
  }

  async loadWorkspace(id: string) {
    try {
      const res = await this.$api.workspace.get(id);
      this.workspace = Workspace.parse(res.data.workspace);
      this.loaded = true;
    } catch (data) {
      this.$api.handleResponseError(data);
      this.loaded = false;
      this.redirectToWorkspaces();
    }
  }

  onVisibilitySelectOpen() {
    this.isVisibilitySelectOpened = true;
  }

  onVisibilitySelectClose() {
    this.isVisibilitySelectOpened = false;
  }

  mounted() {
    this.init();
  }

  beforeDestroy() {
    if (this.loader) {
      this.loader.close();
    }
  }

  async init() {
    const workspaceId = this.$route.params.id;
    if (workspaceId) {
      this.loader = this.$loading({ lock: true, text: 'Loading Workspace' });

      try {
        await Promise.all([
          this.loadWorkspace(workspaceId),
          this.loadUserRoles(workspaceId),
          this.loadGroupRoles(workspaceId),
        ]);
      } catch (error) {
        this.handleError(error as AxiosError);
      }

      if (this.loader) {
        this.loader.close();
        this.loader = null;
      }
    } else {
      this.workspace = new Workspace();
      this.loaded = true;
    }
  }

  async saveWorkspaceData(field: string) {
    this.isVisibilitySelectOpened = false;
    if (!this.checkDataUpdated(field) && field !== 'visibility') {
      this.isValid = true;
      this.hideConfirmEditButtons(field);
      return;
    }
    const workspaceJson = JSON.parse(JSON.stringify(this.workspace));
    this.editWorkspaceData = { id: this.workspace.id, [field]: workspaceJson[field] };

    if (this.checkWorkspaceFieldValid(field)) {
      try {
        const savedData = await this.$api.workspace.save(this.editWorkspaceData);
        if (savedData.status === 200) {
          return new Promise((resolveUpdate) => {
            if (this.workspace.id) {
              EventBus.$emit('workspace.update', this.workspace, resolveUpdate);
            } else {
              EventBus.$emit('workspace.create', this.workspace, resolveUpdate);
            }
            this.isValid = true;
            this.hideConfirmEditButtons(field);
            notificationService.success(`Workspace ${field} is updated`);
          });
        }
      } catch (error) {
        this.$api.handleResponseError(error);
      }
    } else {
      this.isValid = false;
    }
  }

  checkWorkspaceFieldValid(field: string) {
    return !!this.workspace.title || field !== 'title';
  }

  checkDataUpdated(field: string) {
    switch (field) {
      case 'title':
        return this.workspace[field] !== this.originalTitle;

      case 'description':
        return this.workspace[field] !== this.originalDescription;

      default:
        return true;
    }
  }

  showConfirmEditTitleButtons() {
    this.originalTitle = this.workspace.title;
    this.$refs.workspace_title.$el.focus();
    this.isTitleFieldEditable = true;
  }

  showConfirmEditDescriptionButtons() {
    this.originalDescription = this.workspace.description;
    this.$refs.workspace_description.$el.focus();
    this.isDescriptionFieldEditable = true;
  }

  hideConfirmEditButtons(field: string) {
    switch (field) {
      case 'title':
        this.isTitleFieldEditable = false;
        break;
      case 'description':
        this.isDescriptionFieldEditable = false;
        break;
    }
  }

  cancelEditTitleField() {
    if (this.workspace.title !== this.originalTitle && !!this.originalTitle) {
      this.workspace.title = this.originalTitle;
    }
    this.isTitleFieldEditable = false;
    this.isValid = true;
  }

  cancelEditDescriptionField() {
    if (this.workspace.description !== this.originalDescription) {
      this.workspace.description = this.originalDescription;
    }
    this.isDescriptionFieldEditable = false;
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(route: Route, oldRoute: Route) {
    if (oldRoute && route.params.id !== oldRoute.params.id) {
      return this.init();
    }
  }
}
