<template>
  <div id="articles-reorder">
    <el-tree
      @node-drag-start="handleDragStart"
      @node-drag-over="handleDragOver"
      @node-drag-end="handleDragEnd"
      icon-class="icon-class"
      :data="activeOrder"
      :empty-text="'No data'"
      node-key="id"
      draggable
      :allow-drop="allowDrop"
    >
      <div
        slot-scope="{ node, data }"
        class="item-container"
        :class="{ 'is-placeholder': data?.isPlaceholder }"
        :style="{ height: data?.isPlaceholder ? '0' : 'auto', opacity: data?.isPlaceholder ? 0 : 1 }"
      >
        <template v-if="!data?.isPlaceholder">
          <div class="item-container__content">
            <div v-if="data.children.length" class="toggle-button">
              <span v-if="node.expanded" class="arrow-down"></span>
              <span v-else class="arrow-right"></span>
            </div>
            <span
              @click="handleClick(node)"
              :class="['article-title', 'text-ellipsis', data.children.length ? '' : 'extra-space']"
              >{{ node.label }}</span
            >
          </div>
          <div class="icon-container">
            <span
              v-if="data.isShared"
              class="unlock-icon"
              :style="{ marginRight: data.typeArticle === 'video' ? `3px` : `10px` }"
            ></span>
            <span v-if="data.typeArticle === 'video'" class="video-icon"></span>
            <span class="more-vert"></span>
          </div>
        </template>
      </div>
    </el-tree>
    <template> </template>
  </div>
</template>

<script>
/* tslint:disable */
import { workspaceService } from '@/services/workspace/WorkspaceService';
import { Mutations } from '@/store/mutations';
import EventBus from '@/EventBus';

export default {
  name: 'ArticlesReorder',
  props: {
    visible: Boolean,
    workspace: Object,
    articles: Array,
    title: {
      type: String,
      default: 'Reorder Articles',
    },
  },
  data() {
    return {
      order: [],
      activeOrder: [],
      archivedOrder: [],
      placeholder: null, // Temporary placeholder node
    };
  },
  methods: {
    close() {
      this.$store.commit(Mutations.SET_REORDER_MODE, false);
    },
    loadOrder() {
      this.activeOrder = this.generateActiveTree(this.workspace.order);
      this.archivedOrder = this.generateArchivedTree(this.workspace.order);
      this.order = this.generateTree(this.workspace.order);
    },
    handleDragStart(node, event) {
      const cloneNode = event.target.childNodes[0].cloneNode(true);
      cloneNode.style.background = 'white';
      cloneNode.style.position = 'absolute';
      cloneNode.classList.add('drag-item');
      cloneNode.style.height = '35px';
      event.dataTransfer.setDragImage(cloneNode, 0, 0);
      event.dataTransfer.effectAllowed = 'copyMove';
      event.stopPropagation();
      document.body.appendChild(cloneNode);
    },
    handleDragOver(draggingNode, dropNode) {
      // Check if hovering over the last node in the activeOrder list (root level)
      if (this.placeholder && !dropNode?.data?.isPlaceholder) {
        this.placeholder = null;
        this.activeOrder.forEach((node, index) => {
          if (node?.isPlaceholder) {
            this.activeOrder.splice(index, 1);
          }
        });
      }

      const lastRootNode = this.activeOrder[this.activeOrder.length - 1];

      if (!lastRootNode?.children?.length) return;
      const lastRootChild = lastRootNode.children[lastRootNode?.children?.length - 1];

      const isLastChildHovered = lastRootChild.value === dropNode.data.value;
      if (!isLastChildHovered) return;

      // Conditions for showing placeholder
      if (
        isLastChildHovered &&
        !this.placeholder // Ensure placeholder doesn't already exist
      ) {
        // Create temporary placeholder node
        this.placeholder = {
          id: 'placeholder-' + Date.now(),
          label: '',
          isPlaceholder: true,
          children: [],
        };

        // Add placeholder to the activeOrder list (root level)
        this.activeOrder.push(this.placeholder);
      }
    },
    handleDragEnd() {
      // Remove placeholder when node dropped if it's exist
      if (this.placeholder) {
        this.placeholder = null;
        this.activeOrder.forEach((node, index) => {
          if (node?.isPlaceholder) {
            this.activeOrder.splice(index, 1);
          }
        });
      }

      const cloneNode = document.getElementsByClassName('drag-item');
      document.body.removeChild(cloneNode[0]);
    },
    async handleSave() {
      if (this.activeOrder.length) {
        this.order = this.activeOrder;
        while (this.archivedOrder.length) {
          this.archivedOrder.forEach((item) => {
            this.order = this.checkOrdering(this.order, item);
          });
        }
      }

      const resultOrder = this.generateDataTree(this.order);
      await workspaceService.saveArticlesOrder(resultOrder);

      this.$store.commit('setWorkspaceOrder', resultOrder);
      this.close();
    },
    allowDrop(draggingNode, dropNode, type) {
      // If it's placeholder, allow drop only on the top of it
      if (dropNode.data.isPlaceholder) {
        return type === 'prev';
      }

      if (type === 'inner') {
        if (
          (draggingNode.data.typeArticle === 'text' && dropNode.data.typeArticle === 'video') ||
          (draggingNode.data.typeArticle === 'video' && dropNode.data.typeArticle === 'video')
        ) {
          return false;
        }
      }
      return true;
    },
    checkOrdering(data, item) {
      if (!item.parentId) {
        data.push({
          children: [],
          value: item.articleId,
        });
        item.parentId = null;
        this.archivedOrder = this.archivedOrder.filter((element) => element.parentId !== null);
        return data;
      }
      data.forEach((el) => {
        this.archivedOrder = this.archivedOrder.filter((element) => element.parentId !== null);
        if (!this.archivedOrder.length) return;

        if (el.value === item.parentId) {
          el.children.push({
            children: [],
            value: item.articleId,
          });
          item.parentId = null;
          this.archivedOrder = this.archivedOrder.filter((element) => element.parentId !== null);
          if (!this.archivedOrder.length) return;
        } else {
          if (el.children.length) {
            this.checkOrdering(el.children, item);
          }
        }
      });
      return data;
    },
    generateDataTree(data) {
      const treeResult = [];
      data.forEach((el) => {
        treeResult.push({
          value: el.value,
          items: el.children && el.children.length ? this.generateDataTree(el.children) : [],
        });
      });
      return treeResult;
    },
    generateTree(data, parentId = null) {
      const treeResult = [];
      data.forEach((el) => {
        this.articles.forEach((article) => {
          if (el.value === article.id) {
            treeResult.push({
              parent: parentId,
              value: el.value,
              children: el.items && el.items.length ? this.generateTree(el.items, el.value) : [],
              label: article.title,
              typeArticle: article.duration > 0 ? 'video' : 'text',
            });
          }
        });
      });
      return treeResult;
    },
    generateActiveTree(data) {
      const treeResult = [];
      data.forEach((el) => {
        this.articles.forEach((article) => {
          if (el.value === article.id && article.isArchived !== true) {
            treeResult.push({
              value: el.value,
              children: el.items && el.items.length ? this.generateActiveTree(el.items) : [],
              label: article.title,
              typeArticle: article.duration > 0 ? 'video' : 'text',
              isShared: article.shared,
            });
          }
        });
      });
      return treeResult;
    },
    generateArchivedTree(data) {
      const treeResult = [];
      let parentId = null;
      this.articles.forEach((article) => {
        if (article.isArchived === true) {
          parentId = null;
          data.forEach((el) => {
            if (el.value !== article.id) {
              if (!parentId) {
                parentId = workspaceService.getParentArticle(el, article.id);
              }
            }
          });
          treeResult.push({
            articleId: article.id,
            parentId: parentId,
          });
        }
      });
      return treeResult;
    },
  },
  watch: {
    workspace() {
      this.loadOrder();
    },
  },
  created() {
    this.loadOrder();
    EventBus.$on('save-reorder-articles', async () => {
      await this.handleSave();
    });
    EventBus.$on('close-reorder-articles', () => {
      this.close();
    });
  },

  beforeDestroy() {
    EventBus.$off('save-reorder-articles');
    this.close();
  },
};
</script>
<style lang="scss">
@import '../../../scss/material/sidebar/articles-reorder.scss';
</style>
