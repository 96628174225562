import { Video } from '@/models/Video';
import { Logger } from '@/other/Logger';
import apiService from '@/services/ApiService';
import axios from 'axios';
import { humanFileSize } from '@/components/tiptap/utils/shared';

const log = new Logger('VideoUploadService');
const UPDATE_INTERVAL = 100;
const acceptReferenceData = [
  { size: 0, time: 2 },
  { size: 56.6, time: 12 },
  { size: 156.4, time: 24 },
  { size: 294.7, time: 52 },
  { size: 980, time: 73 },
];

type VideoUploadProgressHandler = (progress: number) => void;
type VideoUploadProcessingHandler = () => void;
type VideoUploadFinishHandler = (video: Video) => void;
type VideoUploadErrorHandler = () => void;

type ProgressEvent = { loaded: number };
type VideoUploadServiceOptions = {
  isFinishOnEnd?: boolean;
  useFakeProgress?: boolean;
};

export class VideoUploadService {
  isFinishOnEnd = true;
  protected blobs: Blob[];
  protected uploaded: string[] = [];
  protected index = 0;
  protected timeout: number | null = null;
  protected fakeProgressInterval = 0;
  protected acceptStartTime = 0;
  protected partsUploadTime = 0;
  protected isUseFakeProgress = false;
  protected isUploading = false;
  protected isFinished = false;
  protected progressHandlers: VideoUploadProgressHandler[] = [];
  protected processingHandlers: VideoUploadProcessingHandler[] = [];
  protected errorHandlers: VideoUploadErrorHandler[] = [];
  protected finishHandlers: VideoUploadFinishHandler[] = [];

  constructor(parts: Blob[], options: VideoUploadServiceOptions = {}) {
    this.blobs = parts;
    this.uploaded = [];
    this.isUploading = false;
    this.index = 0;
    this.isFinishOnEnd = options.isFinishOnEnd || true;
    this.isUseFakeProgress = options.useFakeProgress || false;

    log.info(`Created with ${this.blobs.length} parts`);
  }

  static splitVideoToChunks(blob: Blob, chunkSize = 32e6) {
    const blobSize = blob.size;
    const chunks = Math.ceil(blobSize / chunkSize);
    const blobs: Blob[] = [];
    let chunk = 0;

    while (chunk < chunks) {
      const offset = chunk * chunkSize;
      blobs.push(blob.slice(offset, offset + chunkSize));
      chunk++;
    }

    return blobs;
  }

  static getEstimatedAcceptTime(size: number) {
    const sizeMb = size / 1000000;
    const data = [...acceptReferenceData, { size: 999999, time: 99999 }];
    const index = data.findIndex((element) => sizeMb >= element.size);
    if (index < 0) return 0;

    const from = data[index];
    const to = data[index + 1];
    if (!to) return data[data.length - 1].time;

    const multiplier = (sizeMb - from.size) / (to.size - from.size);
    return ((to.time - from.time) * multiplier + from.time) * 1000;
  }

  onProgress = (handler: VideoUploadProgressHandler) => {
    this.progressHandlers.push(handler);
  };

  onProcessing = (handler: VideoUploadProcessingHandler) => {
    this.processingHandlers.push(handler);
  };

  onError = (handler: VideoUploadErrorHandler) => {
    this.errorHandlers.push(handler);
  };

  onFinish = (handler: VideoUploadFinishHandler) => {
    this.finishHandlers.push(handler);
  };

  isFinishedUploading(): boolean {
    return this.isFinished;
  }

  addPart(blob: Blob) {
    this.blobs.push(blob);
  }

  start() {
    log.info('Start uploading');
    this.timeout = window.setInterval(() => {
      this.update();
    }, UPDATE_INTERVAL);
  }

  stop() {
    clearInterval(this.timeout);
    this.stopFakeProgressInterval();
  }

  destroy() {
    this.stop();
    this.blobs = [];
    this.uploaded = [];
  }

  protected update() {
    if (this.isUploading) return;

    // upload new blob
    const part = this.blobs[this.index];
    if (part) {
      return this.uploadPart(part);
    } else if (this.isFinishOnEnd && !this.isFinished) {
      log.info(`Finishing. ${this.blobs.length} parts uploaded in ${this.partsUploadTime / 1000}sec`);
      this.stop();
      this.isFinished = true;

      this.acceptUploadedParts().catch((error) => {
        apiService.handleResponseError(error);
        this.errorHandlers.forEach((handler) => handler());
        this.destroy();
      });
    }
  }

  protected async uploadPart(blob: Blob) {
    log.info(`Start upload part ${this.index}`);
    this.isUploading = true;

    try {
      const startTime = Date.now();
      const response = await apiService.videoUpload.getVideoUploadUrl();
      const uploadUrl = response.data.url;
      const partId = response.data.id;
      await axios.put(uploadUrl, blob, {
        headers: { 'Content-Type': 'application/octet-stream' },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const progress = this.getCurrentProgress(progressEvent);

          this.progressHandlers.forEach((handler) => handler(progress));
        },
      });

      this.isUploading = false;
      this.uploaded.push(partId);
      this.partsUploadTime += Date.now() - startTime;
      this.index++;
      log.info(`Part ${this.index - 1} uploaded in ${(Date.now() - startTime) / 1000}sec`);
    } catch (error) {
      apiService.handleResponseError(error);
      this.errorHandlers.forEach((handler) => handler());
      this.destroy();
    }
  }

  private async acceptUploadedParts() {
    this.acceptStartTime = Date.now();
    log.info('Accepting all parts');
    this.processingHandlers.forEach((handler) => handler());

    const response = await apiService.videoUpload.acceptVideo(this.uploaded);
    const acceptSessionId = response.data.id;

    let status = '';
    let video: Video = null;
    this.startFakeProgressInterval();
    while (!status || status === 'processing') {
      await this.wait(2000);
      const res = await apiService.videoUpload.getVideoAcceptStatus(acceptSessionId);
      if (res) {
        status = res.data.status;
        video = res.data.video ? (Video.fromJson(res.data.video) as Video) : null;
      } else {
        status = 'error';
      }
    }
    this.stopFakeProgressInterval();
    this.progressHandlers.forEach((handler) => handler(100));

    if (video) {
      const partsSize = this.blobs.reduce((size, blob) => size + blob.size, 0);
      log.info(`Accepted in ${(Date.now() - this.acceptStartTime) / 1000}sec. Size: ${humanFileSize(partsSize, true)}`);
      this.finishHandlers.forEach((handler) => handler(video));
      return;
    }

    log.info(`Can not accept video`);
    this.errorHandlers.forEach((handler) => handler());
  }

  private wait(time: number) {
    return new Promise((resolve) => {
      window.setTimeout(resolve, time);
    });
  }

  private getCurrentProgress(progressEvent?: ProgressEvent) {
    let totalSize = 0;
    let totalUploaded = progressEvent?.loaded || 0;

    if (this.blobs.length) {
      for (const key in this.blobs) {
        totalSize += this.blobs[key].size;
        if (this.index > parseInt(key)) {
          totalUploaded += this.blobs[key].size;
        }
      }
    }

    const realProgress = Math.round((totalUploaded / totalSize) * 100);
    if (this.isUseFakeProgress) {
      const now = Date.now();
      const acceptCurrentTime = now - this.acceptStartTime;
      const estimatedAcceptTime = this.acceptStartTime > 0 ? VideoUploadService.getEstimatedAcceptTime(totalSize) : 0;
      const fakeProgress = this.acceptStartTime > 0 ? (acceptCurrentTime * 100) / estimatedAcceptTime : 0;
      const progress = realProgress / 2 + (this.acceptStartTime > 0 ? fakeProgress / 2 : 0);

      return Math.min(Math.max(progress, 0), 100);
    }

    return realProgress;
  }

  private startFakeProgressInterval() {
    this.fakeProgressInterval = window.setInterval(() => {
      this.progressHandlers.forEach((handler) => handler(this.getCurrentProgress()));
    }, 500);
  }

  private stopFakeProgressInterval() {
    window.clearInterval(this.fakeProgressInterval);
  }
}
