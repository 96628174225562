
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Article } from '@/models/article/Article';
import VueApp from '@/@types/app/VueApp';
import { Mutations } from '@/store/mutations';
import helper from '@/helper';
import TipTap from '@/components/tiptap/TipTap.vue';
import EmptyStateCard from '@/components/layout/cards/EmptyStateCard.vue';
import mobileResponsiveService from '@/services/MobileResponsiveService';
import { workspaceService } from '@/services/workspace/WorkspaceService';
import { SEARCH_TEXT_HIGHLIGHT_COLOR } from '@/constants/SharedConsts';
import { commitSetArticleMode, commitSetAutoPlay } from '@/store/commits/sharedCommits';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import TipTapCommentsIconsSidebar from '@/components/TiptapCommentsIconsSidebar/TipTapCommentsIconsSidebar.vue';
import articleEditService from '@/services/article/ArticleEditService';
import { TextTab } from '@/models/article/TextTab';
import { commitSetArticleEditorTextTabs } from '@/store/commits/articleEditorCommits';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import { ElLoadingComponent } from 'element-ui/types/loading';

const SCROLL_TO_TEXT_DELAY = 100;
const CLEAR_ARTICLE_TEXT_PROPS_DELAY = 2000;

@Component({
  components: { TipTapCommentsIconsSidebar, TipTap, EmptyStateCard },
})
export default class ArticleTextEditor extends VueApp {
  @Prop({ default: true }) isEditMode: boolean;
  @Prop(Article) article: Article;
  @Prop(Boolean) isSharedMode: boolean;

  loader: ElLoadingComponent = null;
  originalText: null | string = null;

  get documentId() {
    if (this.selectedTextTab?.id) {
      return `${this.articleId}:${this.selectedTextTab.id}`;
    } else {
      return this.articleId;
    }
  }

  get articleId() {
    return this.article.original ? this.article.original : this.article.id;
  }

  get showEmptyStateCard() {
    return !this.article.textTabs.length;
  }

  get editorKey() {
    return this.selectedTextTab
      ? this.selectedTextTab.id
      : this.article.draft
      ? this.article.original
      : this.article.id;
  }

  get selectedTextTab() {
    const activeTextTab = this.$store.state.articleEditor.selectedTextTab.tab;

    if (!activeTextTab || !this.article || !this.article.textTabs) {
      return null;
    }

    const selectedTab = this.article.textTabs.find((item) => item.id === activeTextTab.id);

    return selectedTab || null;
  }

  get isMobile() {
    return mobileResponsiveService.isMobileView;
  }

  get articleContent() {
    return this.originalText
      ? this.originalText
      : this.selectedTextTab
      ? this.selectedTextTab.text
      : this.article.textTabs.length
      ? this.article.textTabs[0].text
      : '';
  }

  get articleTextPropsToFind() {
    return this.$store.state.articleTextPropsToFind;
  }

  get userCanManageArticles() {
    return workspaceService.workspaceView.workspace.canEditArticles();
  }

  get isShowFooter() {
    return this.$store.state.articleVersionView.isShowFooter;
  }

  handleEditArticle() {
    this.$router.push(`/article/${this.article.id}/edit/${ArticleEditMode.NOTES}`);
  }

  async handleCreateNotes() {
    const textTab = new TextTab();
    this.loader = this.$loading({
      lock: true,
      text: 'Creating text tab',
    });

    const currentTabs = this.$store.state.articleEditor.article.textTabs;
    let newTextTabs = Array.isArray(currentTabs) ? [...currentTabs, textTab] : [textTab];

    try {
      commitSetArticleEditorTextTabs(newTextTabs);
      await articleEditService.saveAsDraft();

      await dispatchUpdateSelectedTextTab(this.article.textTabs[this.article.textTabs.length - 1]);
      commitSetArticleMode(ArticleEditMode.NOTES);
    } finally {
      this.loader.close();
      this.loader = null;
    }
  }

  async updateArticleText(text: string) {
    // If selected text tab in editor, update this tab text, if not, update default notes tab
    if (this.selectedTextTab) {
      const currentTabs = this.$store.state.articleEditor.article.textTabs;
      const tab = currentTabs.find((item: TextTab) => item.id === this.selectedTextTab.id);

      if (tab) tab.text = text;

      await articleEditService.saveAsDraft();
    }
  }

  created() {
    if (this.article) {
      this.setArticle();
      this.searchAndHighlightText();
    }
    if (this.isShowFooter) {
      this.addBottomSpace();
    }
  }

  searchAndHighlightText() {
    if (this.articleTextPropsToFind.length) {
      this.originalText = null;
      let firstItemStartIndex = -1;
      let lastItemEndIndex = -1;

      for (const { startIndex, textToFind, queryLength } of this.articleTextPropsToFind) {
        if (!textToFind) return;
        if (firstItemStartIndex === -1) {
          firstItemStartIndex = startIndex;
        }
        lastItemEndIndex = startIndex + queryLength;
      }

      if (firstItemStartIndex !== -1 && lastItemEndIndex !== -1) {
        const searchTextToHighlight = this.articleTextPropsToFind
          .map(({ textToFind }: { textToFind: string }) => textToFind)
          .join(' ');

        const mark = `<mark data-id="tmp-search-highlight" data-color='${SEARCH_TEXT_HIGHLIGHT_COLOR}'>${searchTextToHighlight}</mark>`;
        const replacement = !this.originalText ? mark : mark + ' ';
        const firstTextTab = this.article.textTabs[0];

        const articleText = !this.originalText
          ? this.selectedTextTab
            ? this.selectedTextTab.text
            : firstTextTab.text
          : null;

        this.originalText = helper.replaceTextFromTo(
          articleText,
          replacement,
          firstItemStartIndex,
          lastItemEndIndex - firstItemStartIndex
        );
      }

      window.setTimeout(() => {
        this.scrollToHighlightedText();
      }, SCROLL_TO_TEXT_DELAY);

      window.setTimeout(() => {
        commitSetAutoPlay(null);
        this.$store.commit(Mutations.SET_ARTICLE_TEXT_PROPS_TO_FIND, []);
      }, CLEAR_ARTICLE_TEXT_PROPS_DELAY);
    }
  }

  scrollToHighlightedText() {
    const isArticleEditMode = this.$route.name === 'DraftEdit';
    const container = document.querySelector<HTMLElement>(
      isArticleEditMode ? '.el-tiptap-editor__content' : '.workspace-container'
    );
    if (container) container.scrollTo(0, 0); // reset scroll position

    const foundElement = container.querySelector<HTMLElement>(`mark[data-color="${SEARCH_TEXT_HIGHLIGHT_COLOR}"]`);
    const markedTextOffset = container.clientHeight / 2;
    const containerRect = container.getBoundingClientRect();
    const foundElementRect = foundElement.getBoundingClientRect();

    const foundElementOffset = foundElementRect.top - containerRect.top - markedTextOffset;

    if (foundElement && container) {
      container.scrollTo(0, foundElementOffset);
    } else {
      //text wrapped in code block can not be highlighted with mark. in this case we need just scroll
      const preContainer = container.querySelectorAll<HTMLElement>('.el-tiptap-editor__content pre');
      if (preContainer && container) {
        preContainer.forEach((element: HTMLElement) => {
          const { textToFind } = this.articleTextPropsToFind[0];
          if (element.textContent.includes(textToFind)) {
            container.scrollTo(0, element.offsetTop);
          }
        });
      }
    }
  }

  addBottomSpace() {
    setTimeout(() => {
      const tiptapContent: HTMLElement = document.querySelector('.el-tiptap-editor__content');
      if (tiptapContent) {
        if (this.isShowFooter) {
          tiptapContent.style.paddingBottom = '150px';
        } else {
          tiptapContent.style.paddingBottom = '85px';
        }
      }
    }, 1);
  }

  @Watch('article')
  onArticleChange() {
    this.originalText = null;
    this.setArticle();
    this.searchAndHighlightText();
  }

  @Watch('selectedTextTab')
  onSelectedTextTabChange() {
    this.originalText = null;
    this.setArticle();
  }

  @Watch('articleTextPropsToFind')
  search() {
    this.searchAndHighlightText();
  }

  @Watch('isShowFooter')
  changePadding() {
    this.addBottomSpace();
  }

  setArticle() {
    this.$store.commit('setArticleEditorArticle', this.article);
  }
}
