
import VueApp from '@/@types/app/VueApp';
import { Component, Prop } from 'vue-property-decorator';
import ISelectRoleDialog from '@/@types/interfaces/ISelectRoleDialog';
import FindUserDialog from '@/components/layout/dialogs/FindUserDialog.vue';
import SelectRoleDialog from '@/components/layout/dialogs/SelectRoleDialog.vue';
import UserProfilePhoto from '@/components/layout/shared/ProfilePhoto/UserProfilePhoto.vue';
import { Workspace } from '@/models/Workspace';
import { User } from '@/models/User';
import { Role } from '@/models/Role';
import './UsersPermissionManager.scss';
import notificationService from '@/services/NotificationService';

@Component({
  components: {
    FindUserDialog,
    SelectRoleDialog,
    UserProfilePhoto,
  },
})
export default class UsersPermissionManager extends VueApp {
  @Prop() workspace: Workspace;
  @Prop() users: User[];

  findUserDialogVisible = false;
  roleDialogVisible = true;
  roleDialogUser: User | null = null;

  $refs: {
    selectRoleDialog: any;
  };

  removeUser(user: User) {
    this.$api.workspace
      .removeUserRole(this.workspace.id, user.id ? user.id : user.email)
      .then(() => {
        const index = this.users.indexOf(user);
        if (index >= 0) {
          this.users.splice(index, 1);
        }
        notificationService.success(`The user ${this.splitUserName(user)} has been successfully removed`);
        this.$emit('reload');
      })
      .catch((res: any) => this.$api.handleResponseError(res));
  }

  handleChangeRole(item: User) {
    (this.$refs.selectRoleDialog as ISelectRoleDialog).show();
    this.roleDialogUser = item;
    this.roleDialogVisible = true;
  }

  handleAddUser(users: string[]) {
    this.findUserDialogVisible = false;
    const promisesAddUsers = users.map((json: string) => {
      const { id, email } = JSON.parse(json);
      return this.confirmAddUser({ id, email });
    });

    Promise.all(promisesAddUsers)
      .then(() => {
        notificationService.success('Great! New user(s) were successfully added.');
        this.$emit('reload');
      })
      .catch((err: any) => {
        this.$api.handleResponseError(err);
      });
  }

  confirmAddUser(userData: { id: string; email: string }) {
    return userData.id
      ? this.$api.workspace.setUserRole(this.workspace.id, userData.id, Role.VIEWER)
      : this.$api.workspace.setUserRoleByEmail(this.workspace.id, userData.email, Role.VIEWER);
  }

  handleSaveUserRole(role: string, user: User) {
    this.updateUserRole(user, role);
  }

  updateUserRole(user: User, role: string) {
    const loader = this.$loading({});
    const promise = user.id
      ? this.$api.workspace.setUserRole(this.workspace.id, user.id, role)
      : this.$api.workspace.setUserRoleByEmail(this.workspace.id, user.email, role);
    promise
      .then(() => {
        user.role = role;
      })
      .catch((res: any) => this.$api.handleResponseError(res))
      .then(() => {
        loader.close();
      });
  }

  getInitials(user: User): string {
    return (user.firstName[0] + user.lastName[0]).toUpperCase();
  }

  splitUserName(user: User): string {
    const username = user.firstName + ' ' + user.lastName;
    if (username.length > 30) {
      return username.substring(0, 30) + '...';
    }

    return username;
  }
}
