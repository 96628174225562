import apiService from '@/services/ApiService';
import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import { RecordingReaction } from '@/@types/RecordingReaction';
import { commitAddArticleReaction, commitRemoveArticleReaction } from '@/store/commits/commentsSidebarCommits';

export const articleReactionsActions = {
  async createArticleReaction(_: ActionContext<State, {}>, reaction: RecordingReaction) {
    const response = await apiService.articleReactions.create(reaction);

    commitAddArticleReaction(response.data.reaction);
  },

  async deleteArticleReactionByCommentId(state: ActionContext<State, {}>, commentId: string) {
    const articleId = state.state.workspaceView.selectedArticle
      ? state.state.workspaceView.selectedArticle.id
      : state.state.articleEditor.article.id;

    const response = await apiService.articleReactions.getByCommentId(commentId, articleId);

    const reactions = response.data.reactions;

    for (const reaction of reactions) {
      await apiService.articleReactions.delete(reaction.id, reaction.articleId);
      commitRemoveArticleReaction(reaction.id);
    }
  },
};
