import store from '@/store';
import { ArticleVersionView } from '@/@types/ArticleVersionView';

export const commitSetArticleVersionView = (articleVersionView: ArticleVersionView) => {
  store.commit('setArticleVersionView', articleVersionView);
};

export const commitSetArticleVersionViewIsShowFooter = (isShowFooter: boolean) => {
  store.commit('setArticleVersionViewIsShowFooter', isShowFooter);
};

export const commitSetArticleVersionViewArticleId = (id: string) => {
  store.commit('setArticleVersionViewArticleId', id);
};

export const commitSetArticleVersionViewVersion = (version: number) => {
  store.commit('setArticleVersionViewVersion', version);
};

export const commitSetArticleVersionViewAuthor = (author: string) => {
  store.commit('setArticleVersionViewAuthor', author);
};

export const commitSetArticleVersionViewTime = (time: string) => {
  store.commit('setArticleVersionViewTime', time);
};

export const commitSetArticleVersionViewProfileImage = (imageUrl: string) => {
  store.commit('setArticleVersionViewProfileImage', imageUrl);
};
