import appStore from '@/store';

export const dispatchSwitchCompany = (companyId: string) => {
  return appStore.dispatch('switchCompany', companyId);
};

export const dispatchLoadCompanyByHostname = () => {
  return appStore.dispatch('loadCompanyByHostname');
};

export const dispatchLoadCompanyUsers = () => {
  return appStore.dispatch('loadCompanyUsers');
};
