import { Paragraph } from './Paragraph';

export class VideoState {
  src = '';
  time = 0;
  offset = 0;
  duration = 0;
  videoOffset = 0;
  paragraph: Paragraph | null = null;
}
