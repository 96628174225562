
import VueApp from '@/@types/app/VueApp';
import Component from 'vue-class-component';
import AdminDashboardStatItem from '@/components/pages/admin/AdminDashboardStatItem/AdminDashboardStatItem.vue';
import { AdminDashboardStatsData } from '@/@types/AdminDashboardStatsData';

@Component({
  components: { AdminDashboardStatItem },
})
export default class AdminDashboard extends VueApp {
  dashboardItems = [
    { type: 'companies', category: 'Total Companies', icon: 'companies', count: 0 },
    { type: 'workspaces', category: 'Total Workspaces', icon: 'workspaces', count: 0 },
    { type: 'articles', category: 'Total Articles', icon: 'articles', count: 0 },
    { type: 'users', category: 'Total Users', icon: 'users', count: 0 },
  ];

  loader: any = null;

  async load() {
    this.loader = this.$loading({ lock: true });

    try {
      const res = await this.$api.admin.getStats();
      const statsData: AdminDashboardStatsData = res.data;
      this.dashboardItems.forEach((item) => {
        item.count = statsData[item.type as keyof AdminDashboardStatsData];
      });
    } catch (error) {
      this.$api.handleResponseError(error);
    } finally {
      this.loader?.close();
      this.loader = null;
    }
  }

  async handleCleanVideos() {
    try {
      await this.$api.admin.cleanVideos();
    } catch (error) {
      this.$api.handleResponseError(error);
    }
  }

  created() {
    this.load();
  }
}
