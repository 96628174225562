export enum Mutations {
  SET_USER = 'setUser',
  SET_USER_COMPANIES = 'setUserCompanies',
  SET_USER_INACTIVE_IN_COMPANY = 'setUserInactiveInCompany',
  SET_COMPANY = 'setCompany',
  SET_COMPANY_STATUS = 'setCompanyStatus',
  SET_COMPANY_LOGO = 'setCompanyLogo',
  SET_PAGE_COMPANY = 'setPageCompany',
  SET_WORKSPACES = 'setWorkspaces',
  SET_WORKSPACE_VIEW_IS_LOADING = 'setWorkspaceViewIsLoading',
  SET_REORDER_MODE = 'setReorderMode',
  SET_ARTICLE_EDITOR = 'setArticleEditor',
  SET_ARTICLE_EDITOR_ARTICLE_TAGS = 'setArticleEditorArticleTags',
  SET_VIDEO_PLAYER_REF = 'setVideoPlayerRef',
  SET_ARTICLE_TEXT_PROPS_TO_FIND = 'setArticleTextPropsToFind',
  SET_IS_MOBILE = 'setIsMobile',
  SET_INNER_HEIGHT = 'setInnerHeight',
  SET_SHOW_ARTICLE_VERSIONS_DIALOG = 'setShowArticleVersionsDialog',
}
