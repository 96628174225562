import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import axios from 'axios';

export class ImageServiceProvider extends ApiBaseServiceProvider {
  scope = 'image';

  upload = (file: File) => {
    const form = new FormData();
    form.append('file', file);

    return axios.post<{ fileName: string; url: string }>(this.getBaseUrl() + '/upload', form, this.getRequestConfig());
  };
}
