import authService from '@/services/AuthService';
import { NavigationGuard } from 'vue-router';
import {
  BILLING_PLANS,
  BROWSE_SHARED,
  BROWSE_SHARED_DEPRECATED,
  CHECKOUT,
  COMPANY_NOT_PAID,
  COMPANY_NOT_PAID_FOR_USER,
  HOME,
} from '@/routerNames';
import { authRoutes } from '@/constants/RouterConsts';
import store from '@/store';
import { Logger } from '@/other/Logger';

const log = new Logger('PaidCompanyMiddleware');

const billingRoutes = [CHECKOUT, BILLING_PLANS];
const sharedRoutes = [BROWSE_SHARED, BROWSE_SHARED_DEPRECATED];
const skipRoutes = [COMPANY_NOT_PAID, COMPANY_NOT_PAID_FOR_USER];

const PaidCompanyMiddleware: NavigationGuard = async (to, from, next) => {
  const company = store.state.company;
  const user = authService.user;

  const isShared = sharedRoutes.includes(to.name);
  const isOwner = user?.role === 'owner';

  if (user?.isSuperAdmin()) {
    next();
    return;
  }

  if (!company?.isPaid && skipRoutes.includes(to.name)) {
    if (!company?.paddle.status && isOwner) {
      next({ name: BILLING_PLANS });
      return;
    }
    next();
    return;
  }

  if (company?.isPaid && to.name === BILLING_PLANS) {
    if (to.params?.update !== 'update') {
      log.info(`Company is already paid, redirecting to home page`);
      next({ name: HOME });
      return;
    }
  }

  if (company?.isPaid && (to.name === BILLING_PLANS || to.name === CHECKOUT) && !isOwner) {
    next({ name: HOME });
  }

  if (isShared && !user) {
    next();
    return;
  }

  if (!company?.isPaid && !billingRoutes.includes(to.name) && !authRoutes.includes(to.name)) {
    if (!isOwner) {
      next({ name: COMPANY_NOT_PAID_FOR_USER });
      return;
    }

    log.info(`Company is NOT paid, redirecting to billing ${COMPANY_NOT_PAID}`);
    next({ name: COMPANY_NOT_PAID });
    return;
  }

  next();
};

export default PaidCompanyMiddleware;
