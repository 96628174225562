import auth from '../services/AuthService';
import * as routeNames from '../routerNames';
import { NavigationGuard } from 'vue-router';

const adminRoutes = [routeNames.ADMIN_PANEL, routeNames.COMPANY, routeNames.COMPANY_GROUPS, routeNames.USER_MANAGEMENT];

const AdminMiddleware: NavigationGuard = async (to, from, next) => {
  const isAdminRoute = adminRoutes.includes(to.name);
  const isAllowNavigate = isAdminRoute && (auth.user.checkAdminRole(auth.user.role) || auth.user?.isSuperAdmin());
  // @todo maybe it's not a good place to set body class here?
  const $el = document.querySelector('body');

  if (isAdminRoute) {
    if (isAllowNavigate) {
      // if ($el) {$el.className = ''; }
      next();
      return;
    } else {
      if ($el) {
        $el.className = 'white-content';
      }
      return next({ name: 'Home' });
    }
  }

  if ($el) {
    $el.className = 'white-content';
  }
  next();
};

export default AdminMiddleware;
