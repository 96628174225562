import { commitSetArticleEditorArticleHash } from '@/store/commits/articleEditorCommits';
import apiService from '../ApiService';
import { Article } from '@/models/article/Article';
import confirmationService from '@/services/ConfirmationService';
import { DeletedItemType } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemType';
import { DeletedItemStyleClass } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemStyleClass';
import { Logger } from '@/other/Logger';
import { Store } from 'vuex';
import { State } from '@/store/state';
import { Loading } from 'element-ui';
import { dispatchGetDraftsCount } from '@/store/dispatchers/uiDispatchers';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { DeleteDraftOptions } from '@/@types/DeleteDraftOptions';

const log = new Logger('DraftService');

class DraftService {
  private store: Store<State>;

  setStore = (store: Store<State>) => {
    this.store = store;
  };

  showDeleteConfirmationPopup(title: string) {
    return confirmationService.showRemovePopup(title, DeletedItemType.DRAFT, DeletedItemStyleClass.DRAFT);
  }

  async deleteDraft(
    article: Article,
    { cb, handleCancel, confirmation = true }: DeleteDraftOptions = {}
  ): Promise<void> {
    let loader: ElLoadingComponent;

    try {
      if (confirmation) {
        await this.showDeleteConfirmationPopup(article.title);
        loader = Loading.service({
          lock: true,
          text: `Deleting your ${article.draft ? 'draft' : 'article'}`,
        });
      }
      if (article.id) {
        try {
          await apiService.draft.delete(article.id);
          await dispatchGetDraftsCount();
          if (cb) {
            cb();
          }
        } catch (res: unknown) {
          apiService.handleResponseError(res);
        } finally {
          if (confirmation) loader.close();
        }
      } else if (cb) {
        cb();
      }
    } catch (err) {
      if (err === 'cancel' && handleCancel) {
        return handleCancel();
      }
      log.error(err);
    }
  }

  async loadDraftHash(articleId: string) {
    try {
      const hashRes = await apiService.draft.getDiscardHash(articleId);
      commitSetArticleEditorArticleHash(hashRes.data.hash);
    } catch (err) {
      // Silent err
    }
  }
}

const draftService = new DraftService();
export default draftService;
