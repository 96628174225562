export const DOMAIN_BLACK_LIST = [
  'app',
  'api',
  'snaplore',
  'browse',
  'settings',
  'draft',
  'drafts',
  'workspace',
  'article',
  'note',
  'recording',
  'profile',
  'company',
  'users',
  'groups',
  'about',
  'access',
  'account',
  'accounts',
  'activate',
  'ad',
  'add',
  'address',
  'adm',
  'admanager',
  'admin',
  'administration',
  'administrator',
  'admins',
  'auth',
  'authentication',
  'beta',
  'billing',
  'bin',
  'bot',
  'business',
  'buy',
  'cache',
  'cancel',
  'client',
  'code',
  'config',
  'configuration',
  'confirm',
  'confirmation',
  'connect',
  'contact',
  'company',
  'data',
  'db',
  'demo',
  'desktop',
  'dev',
  'devel',
  'developer',
  'doc',
  'docs',
  'documentation',
  'documentations',
  'documents',
  'domain',
  'domains',
  'download',
  'downloads',
  'encrypted',
  'exit',
  'explore',
  'faq',
  'get',
  'go',
  'host',
  'hosting',
  'hostmaster',
  'hostname',
  'http',
  'httpd',
  'https',
  'subdomain',
  'sys',
  'sysadmin',
  'sysadministrator',
  'sysadmins',
  'system',
  'url',
  'validation',
  'start',
];
