
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { NodeViewWrapper } from '@tiptap/vue-2';
import { Editor as CoreEditor } from '@tiptap/core';
import { ElLoadingComponent } from 'element-ui/types/loading';
import notificationService from '@/services/NotificationService';
import apiService from '@/services/ApiService';

@Component({
  components: { NodeViewWrapper },
})
export default class ImageUploadView extends Vue {
  @Prop({
    type: ProsemirrorNode,
    required: true,
  })
  readonly node!: ProsemirrorNode;

  @Prop({ type: CoreEditor, required: true })
  readonly editor!: CoreEditor;
  @Prop({ type: Function, required: true }) getPos: () => number;
  @Prop({ type: Function, required: true }) deleteNode: () => void;

  loader: ElLoadingComponent = null;

  handleImageSelect() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', async (event) => {
      const selectedFile = (event.target as HTMLInputElement).files?.[0];
      if (!selectedFile) return;
      try {
        this.loader = this.$loading({ lock: true, text: 'Uploading an image' });
        await this.uploadImage(selectedFile);
      } catch (err) {
        apiService.handleResponseError(err);
      } finally {
        this.loader.close();
      }
    });
    fileInput.click();
  }

  handleDragStart(event: DragEvent) {
    event.dataTransfer?.setData('text/plain', 'dragging');
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
  }

  handleDragEnter(event: DragEvent) {
    event.preventDefault();
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();
  }

  async handleDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (!files.length) return;
    const selectedFile = files[0];

    if (!selectedFile.type.startsWith('image/')) {
      return notificationService.warning('Only images are allowed');
    }

    try {
      this.loader = this.$loading({ lock: true, text: 'Uploading an image' });
      await this.uploadImage(selectedFile);
    } catch (err) {
      apiService.handleResponseError(err);
    } finally {
      this.loader.close();
    }
  }

  async uploadImage(file: File) {
    try {
      const response = await apiService.image.upload(file);
      const src = response.data.url;

      this.editor.chain().focus().insertContentAt(this.getPos(), { type: 'image', attrs: { src } }).run();
      if (this.node) this.deleteNode();
    } catch (err) {
      apiService.handleResponseError(err);
    }
  }
}
