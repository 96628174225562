
import VueApp from '@/@types/app/VueApp';
import { dispatchLoadArticleComments } from '@/store/dispatchers/articleCommentsDispatchers';
import { Component, Watch } from 'vue-property-decorator';
import { Article } from '@/models/article/Article';
import ArticleEditor from '@/components/article-editor/ArticleEditor/ArticleEditor.vue';
import { ARTICLE_EDIT, DRAFT_EDIT, HOME } from '@/routerNames';
import UploadRecordStatus from '@/components/layout/shared/UploadRecordStatus/UploadRecordStatus.vue';
import { Draft } from '@/models/article/Draft';
import { ArticleEditMode } from '@/@types/ArticleEditMode';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { commitSetArticleEditorArticle } from '@/store/commits/articleEditorCommits';
import { commitSetArticleMode } from '@/store/commits/sharedCommits';
import { Route } from 'vue-router/types/router';
import { dispatchLoadCompanyUsers } from '@/store/dispatchers/companyDispatchers';
import { dispatchDisableOcrMode } from '@/store/dispatchers/uiDispatchers';
import { dispatchUpdateSelectedTextTab } from '@/store/dispatchers/articleEditorDispatchers';
import draftService from '@/services/draft/DraftService';

@Component({
  components: {
    ArticleEditor,
    UploadRecordStatus,
  },
})
export default class ArticleEditPage extends VueApp {
  loader: ElLoadingComponent = null;
  article: Article = null;

  get isVideoProcessing() {
    return this.$store.state.articleEditor.isVideoProcessing;
  }
  get isExtension() {
    return !!this.$store.state.extension.id;
  }
  get articleEditMode() {
    return this.$store.state.articleMode;
  }

  async loadArticle(articleId: string) {
    this.loader = this.$loading({ lock: true, text: 'Loading Article' });

    try {
      const isDraft = this.$route.name === DRAFT_EDIT;
      let article: Article;
      if (isDraft) {
        const res = await this.$api.draft.get(articleId);
        article = Article.fromJson(res.data.draft);
      } else {
        const res = await this.$api.article.get(articleId);
        article = Article.fromJson(res.data.article);

        if ((article.acceptSessionId || article.isNotUploadedVideo) && this.isExtension) {
          this.$store.commit('setArticleEditorIsVideoProcessing', true);
        }
      }

      commitSetArticleEditorArticle(article);
      this.article = article;
      const firstTextTab = this.article.textTabs[0];

      if (this.articleEditMode === ArticleEditMode.NOTES && !this.article.textTabs) {
        commitSetArticleMode(ArticleEditMode.VIDEO);
      }

      await dispatchUpdateSelectedTextTab(this.articleEditMode === ArticleEditMode.NOTES ? firstTextTab : null);
    } catch (res) {
      this.$api.handleResponseError(res);
      return this.$router.push({ name: HOME });
    } finally {
      if (this.loader) {
        this.loader.close();
        this.loader = null;
      }
    }
  }

  loadDraftArticle(id: string) {
    this.$nextTick(() => {
      this.loader = this.$loading({
        lock: true,
        text: 'Loading Article',
      });
    });

    this.$api.article
      .get(id, true)
      .then((res: any) => {
        const article = Draft.fromJson(res.data.article);
        const isExtension = !!this.$store.state.extension.id;

        if ((this.article.acceptSessionId || this.article.isNotUploadedVideo) && isExtension) {
          this.$store.commit('setArticleEditorIsVideoProcessing', true);
        }
        commitSetArticleEditorArticle(article);
        this.article = article;
        const firstTextTab = this.article.textTabs[0];

        if (this.articleEditMode === ArticleEditMode.NOTES && !this.article.textTabs) {
          commitSetArticleMode(ArticleEditMode.VIDEO);
        }

        dispatchUpdateSelectedTextTab(this.articleEditMode === ArticleEditMode.NOTES ? firstTextTab : null);
      })
      .catch((res: any) => {
        this.$api.handleResponseError(res);
        return this.$router.push({ name: HOME });
      })
      .then(() => {
        if (this.loader) {
          this.loader.close();
          this.loader = null;
        }
      });
  }

  async init() {
    const routeName = this.$route.name;
    const articleId = this.$route.params.id;
    const isLoadDraftArticle = articleId && routeName === DRAFT_EDIT;
    this.$store.commit('toggleExpandMainSidebar', false);

    this.updateEditMode();

    if (isLoadDraftArticle) {
      this.loadDraftArticle(articleId);
    } else if (routeName === ARTICLE_EDIT) {
      await this.loadArticle(articleId);
    } else {
      if (this.$store.state.createArticle) {
        this.article = Article.fromJson(this.$store.state.createArticle);
        this.$store.commit('setCreateArticle', null);
      } else {
        this.article = new Article();
      }
    }

    await dispatchLoadArticleComments();

    await draftService.loadDraftHash(articleId);
  }

  created() {
    this.init();

    if (!this.$store.state.companyUsers.length) {
      dispatchLoadCompanyUsers();
    }
  }

  beforeDestroy() {
    if (this.loader) {
      this.loader.close();
    }
    dispatchDisableOcrMode();
  }

  updateEditMode() {
    const mode = this.$route.params.mode === ArticleEditMode.NOTES ? ArticleEditMode.NOTES : ArticleEditMode.VIDEO;
    commitSetArticleMode(mode);
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(route: Route, oldRoute: Route) {
    if (oldRoute && route.params.id !== oldRoute.params.id) {
      return this.init();
    }

    this.updateEditMode();

    this.$store.dispatch('commentsSidebarCheckAutoShowComment');
  }
}
