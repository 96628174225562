import { SocketErrorHandler } from '@/@types/SockerErrorHandler';
import apiService from '@/services/ApiService';
import { SocketService } from '@/services/socket/SocketService';

export class SocketBaseServiceProvider {
  private socket: SocketService;

  constructor(socket: SocketService) {
    this.socket = socket;
  }

  protected messageTypeHandler: { [key: number]: SocketErrorHandler } = {
    3000: { type: 'warning' },
    3001: { type: 'success' },
    3002: { type: 'error' },
  };

  protected getSocketBaseUrl = () => {
    return `${this.socket.websocketProtocol}://${this.socket.websocketOrigin}`;
  };

  protected token = () => {
    return apiService.getToken();
  };
}
