import { mergeAttributes, Node } from '@tiptap/core';
import { nanoid } from 'nanoid';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import AiWriterView from '@/components/tiptap/views/AiWriterView/AiWriterView.vue';

export enum AIWriterType {
  Simplify = 'simplify',
  FixSpellingAndGrammar = 'fixSpellingAndGrammar',
  MakeShorter = 'makeShorter',
  MakeLonger = 'makeLonger',
  ChangeTone = 'changeTone',
  Emojify = 'emojify',
  Translate = 'translate',
  CompleteSentence = 'completeSentence',
  CustomPrompt = 'customPrompt',
}

export type TextSelection = {
  from: number;
  to: number;
  text: string;
};

interface AiWriterExtensionProps {
  selection?: TextSelection;
  type?: AIWriterType;
  optionValue?: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiWriter: {
      setAiWriter: ({ selection, type, optionValue }?: AiWriterExtensionProps) => ReturnType;
    };
  }
}

export const AiWriter = Node.create({
  name: 'aiWriter',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({
          'data-id': attributes.id,
        }),
      },
      selection: { default: undefined },
      type: { default: undefined },
      optionValue: { default: undefined },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setAiWriter:
        (props: AiWriterExtensionProps) =>
        ({ chain, tr }) => {
          const { selection } = tr;
          const { $from } = selection;
          const currentNodeNextPos = $from.pos + $from.parent.childCount + 2;
          const targetEndPos = $from.after(1) || currentNodeNextPos;

          return chain()
            .focus()
            .insertContentAt(targetEndPos, {
              type: this.name,
              attrs: {
                id: nanoid(10),
                selection: props?.selection,
                type: props?.type,
                optionValue: props?.optionValue,
              },
            })
            .run();
        },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(AiWriterView);
  },
});

export default AiWriter;
