import { SocketMessageType } from '@/@types/enums/SocketMessageType';
import { TextTab } from '@/models/article/TextTab';
import logger from '@/other/Logger';
import { SocketBaseServiceProvider } from '@/services/socket/SocketBaseServiceProvider';

export type IArticleAiSmartNotesSocketArgs = {
  articleId: string;
  prompt?: string;
  tone?: string;
  type?: SocketMessageType;
  predefinedPrompt?: string;
  saveArticle?: boolean;
  isConsiderArticle?: boolean;
};

export class ArticleAiSmartNotesSocketAction extends SocketBaseServiceProvider {
  private aiSmartNotesGenerationSocket: WebSocket | null = null;
  generateAiSmartNotes = async (
    {
      articleId,
      prompt,
      tone,
      type,
      predefinedPrompt,
      saveArticle = true,
      isConsiderArticle = true,
    }: IArticleAiSmartNotesSocketArgs,
    statusCallback: (status: any) => void
  ): Promise<{ text: string; textTabs?: TextTab[]; draftId?: string }> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const generateAiSmartNotesPromise = new Promise<{ text: string; textTabs?: TextTab[]; draftId?: string } | void>(
        (resolve, reject) => {
          this.aiSmartNotesGenerationSocket = new WebSocket(`${this.getSocketBaseUrl()}/ai-text?token=${this.token()}`);
          // WebSocket event listeners
          this.aiSmartNotesGenerationSocket.onopen = () => {
            this.aiSmartNotesGenerationSocket?.send(
              JSON.stringify({
                type,
                articleId,
                prompt,
                tone,
                predefinedPrompt,
                saveArticle,
                isConsiderArticle,
              })
            );
          };
          this.aiSmartNotesGenerationSocket.onmessage = (event) => {
            if (event.data === SocketMessageType.PING) {
              const sendPong = () => {
                if (this.aiSmartNotesGenerationSocket?.readyState === WebSocket.OPEN) {
                  try {
                    this.aiSmartNotesGenerationSocket.send(SocketMessageType.PONG);
                  } catch (error) {
                    logger.error(`Failed to send pong: ${error}`);
                    setTimeout(sendPong, 2000);
                  }
                }
              };
              sendPong();
              return;
            }
            const message = JSON.parse(event.data);
            switch (message.type) {
              case SocketMessageType.RESULT:
                this.aiSmartNotesGenerationSocket.close(3001);
                resolve({ text: message.text, textTabs: message.textTabs, draftId: message.draftId });
                break;
              case SocketMessageType.UPDATE:
                statusCallback(message);
                break;
            }
          };
          this.aiSmartNotesGenerationSocket.onclose = (event) => {
            const { code } = event;
            const handler = this.messageTypeHandler[code];
            if (!handler) return;

            reject({ type: handler.type, message: event.reason });
          };
          this.aiSmartNotesGenerationSocket.onerror = (error) => {
            reject({ type: error.type, message: 'Failed to generate smart notes' });
          };
        }
      );
      return (await generateAiSmartNotesPromise) || { text: '' };
    } catch (err) {
      throw err;
    }
  };

  stopAiSmartNotesGeneration(articleId: string) {
    this.aiSmartNotesGenerationSocket?.send(JSON.stringify({ type: SocketMessageType.CANCEL, articleId }));

    this.aiSmartNotesGenerationSocket?.close(3001, 'Generation canceled');
  }
}
