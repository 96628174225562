import { render, staticRenderFns } from "./WorkspaceSidebar.vue?vue&type=template&id=565a47c8&"
import script from "./WorkspaceSidebar.vue?vue&type=script&lang=ts&"
export * from "./WorkspaceSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./WorkspaceSidebar.vue?vue&type=style&index=0&id=565a47c8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports