import { Highlight as TiptapHighlight } from '@tiptap/extension-highlight';

const Highlight = TiptapHighlight.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : {}),
      ['data-id']: { default: undefined },
    };
  },
});

export default Highlight;
