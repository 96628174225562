import { NavigationGuard } from 'vue-router';
import apiService from '@/services/ApiService';

const AuthTokenMiddleware: NavigationGuard = async (to, from, next) => {
  if (document.location.href.includes('?token=')) {
    const parts = document.location.href.split('?token=');
    const token = parts[1];

    if (token) {
      apiService.setAuthToken(token);
      const navigateUrl = parts[0];

      document.location.replace(navigateUrl);
      document.location.reload();
      return;
    }
  }

  next();
};
export default AuthTokenMiddleware;
