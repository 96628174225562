
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Article } from '@/models/article/Article';
import { Range } from '@/models/article/Range';
import * as routerNames from '@/routerNames';
import PlayerTimeline from '@/components/article-player/PlayerTimeline.vue';
import helper from '@/helper';
import ArticleTimelineReactions from '@/components/article-player/ArticleTimelineReactions.vue';

@Component({
  components: {
    ArticleTimelineReactions,
    PlayerTimeline,
  },
})
export default class ArticleTimeline extends Vue {
  @Prop({ type: Object, required: true }) article: Article;
  @Prop({ type: Number }) time: number;
  @Prop({ type: Boolean, default: false }) allowEdit: boolean;

  ready = false;
  position = 0;
  cursorTitle = '';
  cursorPosition = 0;
  duration = 0;
  breakpoints: number[] = [];
  paragraphsOffsetMap = new Map<string, number>();
  trimRanges: Range[] = [];

  $refs: {
    timeline: any;
  };

  get cursorTitleClass() {
    let className = 'title';

    if (this.cursorPosition > 60) {
      className += ' title-left';
    }

    return className;
  }

  get isArticleEditPage() {
    return [
      routerNames.ARTICLE_CREATE,
      routerNames.ARTICLE_EDIT,
      routerNames.ARTICLE_VERSION_EDIT,
      routerNames.DRAFT_EDIT,
    ].includes(this.$route.name);
  }

  proxy(name: string, e: MouseEvent | null = null) {
    this.$emit(name, e);
  }

  // public methods for trimming
  startTrimming() {
    const duration = parseInt(this.duration + '');
    const from = parseInt(this.position + '');
    let to = from + duration / 10;

    if (to > duration) {
      to = duration;
    }
    this.trimRanges = [new Range(from, to)];
  }

  isCuttedBreakpoints(item: number) {
    const { allowEdit, trimRanges } = this;
    if (!trimRanges.length) return false;
    const rangesSource = trimRanges;
    return allowEdit && rangesSource && item >= rangesSource[0].start && item <= rangesSource[0].end;
  }

  cursorPositionChange(value: number) {
    this.cursorPosition = value;
  }

  handleClickOnBreakpoint(item: number) {
    this.handleChange(item);
  }

  handleFocusInBreakpoint(ms: number, e: MouseEvent) {
    const paragraphData = this.findParagraphByMs(ms);
    const title = paragraphData.paragraph ? paragraphData.paragraph.title : '';
    (e.target as HTMLTextAreaElement).setAttribute('data-md-tooltip', title);
  }

  handleChange(ms: number) {
    const paragraphData = this.findParagraphByMs(ms);
    this.$emit('change', {
      time: paragraphData.time,
      paragraph: paragraphData.paragraph,
      paragraphTime: paragraphData.paragraphTime,
    });
  }

  findParagraphByMs(ms: number) {
    // need to convert local timeline to global video time;
    const paragraphs = this.article.getParagraphsList();
    let time = 0;
    let paragraphTime = 0;
    let paragraph = null;

    for (const item of paragraphs) {
      const offset: number = this.paragraphsOffsetMap.get(item.id || item.key);

      if (offset <= ms && ms < offset + item.duration) {
        paragraph = item;
        paragraphTime = ms - offset;
        time = offset + paragraphTime;
        break;
      }
    }

    return {
      time,
      paragraph,
      paragraphTime,
    };
  }

  onTimeUpdate(ms: number) {
    const paragraph = this.article.findParagraphByMs(ms);
    const paragraphPosition = this.article.getParagraphPosition(paragraph);
    const paragraphTime = this.findParagraphByMs(ms).paragraphTime;
    const data = {
      paragraph: paragraph,
      progress: 0,
      paragraphTime,
    };

    if (paragraph && typeof this.paragraphsOffsetMap.get(paragraph.id || paragraph.key) !== 'undefined') {
      this.position = this.paragraphsOffsetMap.get(paragraph.id || paragraph.key) + ms - paragraphPosition;
      this.cursorTitle = paragraph.title;

      data.paragraph = paragraph;
      data.progress = 1 - (paragraphPosition + paragraph.duration - ms) / paragraph.duration;
    } else {
      this.position = 0;
    }
    this.$emit('paragraphProgress', data);
  }

  onPlayerTimelineReady() {
    this.ready = true;
    this.$emit('ready');
  }

  handleDragBreakpoint(breakpoint: number, e: MouseEvent) {
    if (!this.allowEdit) {
      return;
    }

    const initialPos = breakpoint || 0;
    const index = this.breakpoints.indexOf(breakpoint);
    const prev = index > 0 ? this.breakpoints[index - 1] : null;
    const next = this.breakpoints[index + 1] ? this.breakpoints[index + 1] : null;

    if (index === 0) {
      return;
    }
    this.startDrag(
      {
        object: this.breakpoints,
        field: index,
        min: prev || 0,
        max: next ? next - 1 : this.duration - 1,
        onEnd: () => {
          const diff = this.breakpoints[index] - initialPos;
          const paragraph = this.article.findParagraphByMs(initialPos);
          this.$emit('moveParagraph', { paragraph, diff });
        },
        onDrag: (pos: number) => {
          this.breakpoints[index] = pos;
          this.handleChange(pos);
        },
      },
      e
    );
  }

  handleDragTrim(index: number, field: string, e: MouseEvent) {
    if (!this.allowEdit) {
      return;
    }

    const range = this.trimRanges[index];
    const min = field === 'start' ? 0 : range.start;
    const max = field === 'start' ? range.end : this.article.duration;
    this.startDrag(
      {
        object: this.trimRanges[index],
        field,
        min,
        max,
        onDrag: (pos: number) => {
          range[`${field === 'start' ? 'start' : 'end'}`] = pos;
          this.handleChange(pos);
        },
      },
      e
    );
  }

  startDrag(options: any, e: MouseEvent | TouchEvent) {
    const opt = Object.assign(
      {
        object: null,
        field: null,
        min: -1,
        max: -1,
        onDrag: null,
        onEnd: null,
      },
      options
    );

    const { object, field, min, max, onDrag, onEnd } = opt;

    let xOffset = 0;
    let initialX = 0;

    if (e.type === 'touchstart') {
      initialX = (e as TouchEvent).touches[0].clientX - xOffset;
    } else {
      initialX = (e as MouseEvent).clientX - xOffset;
    }
    const initialPos = object[field];
    let currentX = initialX;
    let dragActive = true;

    const handleDrag = (e: MouseEvent | TouchEvent) => {
      if (dragActive) {
        if (e.type === 'touchmove') {
          currentX = (e as TouchEvent).touches[0].clientX;
        } else {
          currentX = (e as MouseEvent).clientX;
        }

        xOffset = currentX - initialX;

        let pos = initialPos + this.pxToSeconds(xOffset, false);
        if (min >= 0 && pos < min) {
          pos = min;
        }
        if (max >= 0 && pos > max) {
          pos = max;
        }

        if (typeof onDrag === 'function') {
          onDrag(pos);
        }
      }
    };
    const handleDragEnd = () => {
      initialX = currentX;
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
      dragActive = false;

      if (typeof onEnd === 'function') {
        onEnd();
      }
    };

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
  }

  // END TRIMMING FUNCTIONS

  update() {
    return new Promise((resolve: Function) => {
      this.paragraphsOffsetMap = this.article.getParagraphsOffsetMap();
      this.duration = this.article.calculateDuration();

      const breakpoints: number[] = [];

      this.paragraphsOffsetMap.forEach((time) => {
        breakpoints.push(time);
      });

      this.breakpoints = breakpoints.sort(function (a, b) {
        return a - b;
      });
      this.onTimeUpdate(this.time);
      this.$nextTick(() => resolve);
    });
  }

  // HELPER FUNCTIONS
  msToPercentage(ms: number) {
    return this.$refs.timeline ? this.$refs.timeline.msToPercentage(ms) : 0;
  }

  msToTime(ms: number) {
    return helper.msToTime(ms);
  }

  getTrimRangeWidth(range: Range) {
    return this.msToPercentage(range.end) - this.msToPercentage(range.start);
  }

  pxToSeconds(px: number, validate = true) {
    return this.$refs.timeline ? this.$refs.timeline.pxToSeconds(px, validate) : 0;
  }

  @Watch('time')
  onTimeUpdated(ms: number) {
    this.onTimeUpdate(ms);
  }

  created() {
    this.update();
  }
}
