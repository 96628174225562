export type CommentTipTapState = {
  isActiveStrike: boolean;
  isActiveBold: boolean;
  isActiveItalic: boolean;
  isActiveBlockquote: boolean;
  isActiveUnderline: boolean;
  isActiveLink: boolean;
  isActiveTextAlignLeft: boolean;
  isActiveOrderedList: boolean;
  isActiveBulletList: boolean;
  isActiveTodoList: boolean;
  isActiveHighlight: boolean;
  isActiveTextStyle: boolean;
};

export const commentTipTapEmptyState = (): CommentTipTapState => ({
  isActiveBold: false,
  isActiveItalic: false,
  isActiveStrike: false,
  isActiveBlockquote: false,
  isActiveUnderline: false,
  isActiveLink: false,
  isActiveTextAlignLeft: false,
  isActiveOrderedList: false,
  isActiveBulletList: false,
  isActiveTodoList: false,
  isActiveHighlight: false,
  isActiveTextStyle: false,
});
