import { Company } from '@/models/Company';
import { User } from '@/models/User';

export interface CompanyState {
  company: Company | null;
  companyUsers: User[];
  pageCompany: Company | null;
  isCompanyLoading: boolean;
}

export const companyState: CompanyState = {
  company: null,
  companyUsers: [],
  pageCompany: null, // pages scope of company, if you need to override company on specific page
  isCompanyLoading: true,
};
