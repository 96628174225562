import appStore from '@/store';

export const dispatchGetDraftsCount = async (): Promise<void> => {
  await appStore.dispatch('getDraftsCount');
};
export const dispatchGetMeetingsCount = async (): Promise<void> => {
  await appStore.dispatch('getMeetingsCount');
};
export const dispatchGetPersonalArticlesCount = async (): Promise<void> => {
  await appStore.dispatch('getPersonalArticlesCount');
};
export const dispatchDisableOcrMode = async (): Promise<void> => {
  await appStore.dispatch('disableOcrMode');
};
export const dispatchEnableOcrMode = async (): Promise<void> => {
  await appStore.dispatch('enableOcrMode');
};
