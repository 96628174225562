import { Subtitle } from '@/@types/Subtitle';

export interface TranscriptionSidebarState {
  isVisible: boolean;
  transcription: Subtitle[];
}

export const transcriptionSidebarState: TranscriptionSidebarState = {
  isVisible: false,
  transcription: [],
};
