
import '../../../styles/MenuButton.scss';

import { Component, Prop, Vue } from 'vue-property-decorator';
import VueAwesomeIcon from '@/components/VueAwesomeIcon/VueAwesomeIcon.vue';

@Component({
  components: {
    VueAwesomeIcon,
  },
})
export default class EmojiIcon extends Vue {
  @Prop({ required: true }) readonly icon!: string;

  handleClick() {
    this.$emit('click', this.icon);
  }
}
