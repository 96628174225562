import axios, { AxiosPromise } from 'axios';
import { ApiBaseServiceProvider } from './ApiBaseServiceProvider';
import { WorkspaceGroupRolesServiceProvider } from '@/services/api-providers/WorkspaceGroupRolesServiceProvider';
import { Workspace } from '@/models/Workspace';

export class WorkspaceServiceProvider extends ApiBaseServiceProvider {
  scope = 'workspace';

  groupRoles: WorkspaceGroupRolesServiceProvider;

  constructor(api: any) {
    super(api);
    this.groupRoles = new WorkspaceGroupRolesServiceProvider(api);
  }

  updateArticlesOrder(id: string, order: string[]) {
    return axios.post(
      this.getBaseUrl() + id + '/articles-order',
      {
        order,
      },
      this.getRequestConfig()
    );
  }

  getArticles(id: any, withSharing = false) {
    const url = new URL(this.getBaseUrl() + id + '/articles');
    if (withSharing) {
      url.searchParams.append('withSharing', '1');
    }
    return axios.get(url.href, this.getRequestConfig());
  }

  getArticlesCount(id: string, withSharing = false): AxiosPromise<{ articlesCount: number }> {
    const url = new URL(this.getBaseUrl() + id + '/articles/count');
    if (withSharing) {
      url.searchParams.append('withSharing', '1');
    }
    return axios.get(url.href, this.getRequestConfig());
  }

  getUserRoles(id: string) {
    return axios.get(this.getBaseUrl() + id + '/roles', this.getRequestConfig());
  }

  setUserRole(id: string, userId: string, role: string) {
    return axios.post(
      this.getBaseUrl() + id + '/role',
      {
        user: userId,
        role,
      },
      this.getRequestConfig()
    );
  }

  setUserRoleByEmail(id: string, email: string, role: string) {
    return axios.post(
      this.getBaseUrl() + id + '/role',
      {
        email,
        role,
      },
      this.getRequestConfig()
    );
  }

  removeUserRole(id: string, userId: string) {
    return axios.delete(this.getBaseUrl() + id + '/role/' + userId, this.getRequestConfig());
  }

  save(workspace: Workspace | Partial<Workspace>) {
    return axios.post(this.getBaseUrl() + (workspace.id ? '/' + workspace.id : ''), workspace, this.getRequestConfig());
  }

  saveFavorite(workspace: Workspace) {
    return axios.post(
      this.getBaseUrl() + (workspace.id ? `${workspace.id}/favorite` : ''),
      workspace,
      this.getRequestConfig()
    );
  }

  get(id: string) {
    return axios.get(this.getBaseUrl() + id, this.getRequestConfig());
  }

  delete(id: string) {
    return axios.delete(this.getBaseUrl() + id, this.getRequestConfig());
  }

  setWorkspaceIsArchived(id: string, isArchived: boolean) {
    return axios.patch(this.getBaseUrl() + id + '/archived', { isArchived }, this.getRequestConfig());
  }

  async getByShortId(workspaceShortId: string) {
    return axios.get(`${this.getBaseUrl()}short/${workspaceShortId}`, this.getRequestConfig());
  }
}
