import { Logger } from '@/other/Logger';
import { ExtensionMessageType } from '@/@types/enums/ExtensionMessageType';

const log = new Logger('App:PostMessage');

export class PostMessage {
  static parse(data: any) {
    const instance = new PostMessage('');
    if (typeof data === 'object') {
      instance.source = data.source ? data.source : null;
      instance.message = data.message ? data.message : null;
      instance.payload = data.payload ? data.payload : null;
      instance.environment = data.environment ? data.environment : null;
    }

    return instance;
  }

  source: string;
  message: ExtensionMessageType | '';
  payload: any = null;
  environment: string;

  constructor(message: ExtensionMessageType | '', payload: any = null, source = 'snaplore-app') {
    this.source = source;
    this.message = message;
    this.payload = payload;
    this.environment = process.env.VUE_APP_APP_ENV;
  }

  isSourceFrontend() {
    return this.source === 'snaplore-app';
  }

  isSourceBackend() {
    return this.source === 'snaplore-plugin';
  }

  isSourceApi() {
    return this.source === 'snaplore-api';
  }

  send() {
    log.info(`SEND: ${JSON.stringify(this)}`);
    window.postMessage(this, '*');
  }
}
