
import { Component } from 'vue-property-decorator';
import VueApp from '@/@types/app/VueApp';
import ProgressBar from './ProgressBar/ProgressBar.vue';
import extensionService from '@/services/ExtensionService';
import { PostMessage } from '@/other/PostMessage';
import { ExtensionMessageType } from '@/@types/enums/ExtensionMessageType';
import { ArticleVideoEditorState } from '@/components/article-editor/types/ArticleVideoEditorState';
import './UploadRecordStatus.scss';
import Loader from '@/components/Loader/Loader.vue';
import { wait } from '@/other/wait';

@Component({
  components: {
    Loader,
    ProgressBar,
  },
})
export default class UploadRecordStatus extends VueApp {
  uploadingProgress = 0;
  extensionErrorText = '';
  isInjectApiIframe = false;
  isRunBeforeInjectingApiIframe = false;

  get articleId() {
    return this.$store.state.articleEditor.article.id;
  }
  get acceptSessionId() {
    return this.$store.state.articleEditor.article.acceptSessionId;
  }

  get extensionBaseUrl() {
    return this.$store.state.extension.baseUrl;
  }

  onExtensionMessageFromIframe(event: MessageEvent) {
    if (!event.data) return;

    const postMessage = PostMessage.parse(event.data);
    if (postMessage.environment !== process.env.VUE_APP_APP_ENV) return;

    if (postMessage.isSourceApi() && postMessage.payload.articleId === this.articleId) {
      if (!this.isInjectApiIframe) {
        this.isRunBeforeInjectingApiIframe = true;
      }
      switch (postMessage.message) {
        case ExtensionMessageType.ARTICLE_RECORDING_UPLOAD_FAILED:
          this.extensionErrorText = postMessage.payload.error ? postMessage.payload.error : 'Error';
          this.uploadingProgress = 0;
          break;
        case ExtensionMessageType.ARTICLE_RECORDING_UPLOAD_PROGRESS:
          this.uploadingProgress = postMessage.payload.progress;
          this.$store.commit('setArticleEditorIsVideoProcessing', true);
          break;
        case ExtensionMessageType.ARTICLE_RECORDING_UPLOAD_FINISHED:
          this.uploadingProgress = 100;
          this.$router.go(null);
          break;
      }
    }
  }

  onExtensionMessage(postMessage: PostMessage) {
    switch (postMessage.message) {
      case ExtensionMessageType.RECORDING_UPLOAD_PROGRESS:
        this.uploadingProgress = postMessage.payload.progress;
        this.$store.commit('setArticleEditorIsVideoProcessing', true);
        break;
      case ExtensionMessageType.RECORDING_UPLOAD_FINISHED:
        this.uploadingProgress = 100;
        this.$router.go(null);
        break;
    }
  }

  retryUploadRecording() {
    this.extensionErrorText = '';
    this.isInjectApiIframe = false;

    this.$nextTick(() => {
      this.isInjectApiIframe = true;
    });
  }

  openExtensionRecordings() {
    extensionService.openRecordingsPage();
  }

  handleOffline() {
    this.extensionErrorText = 'Please check your internet connection';
  }

  async created() {
    const editorState = this.$store.state.articleEditor.state;
    if (editorState !== ArticleVideoEditorState.inserting) {
      window.addEventListener('message', this.onExtensionMessageFromIframe);
      await wait(500);
      this.isInjectApiIframe = true;
    } else if (editorState === ArticleVideoEditorState.inserting) {
      // listening message from extension by regular channel
      extensionService.addMessageListener(this.onExtensionMessage);
    }

    window.addEventListener('offline', this.handleOffline);
  }

  beforeDestroy() {
    window.removeEventListener('message', this.onExtensionMessageFromIframe);
    window.removeEventListener('offline', this.handleOffline);
    extensionService.removeMessageListener(this.onExtensionMessage);
  }
}
