import { State } from '@/store/state';
import { ArticleVersionView } from '@/@types/ArticleVersionView';

export const articleVersionMutations = {
  setArticleVersionView(state: State, value: ArticleVersionView): void {
    state.articleVersionView = value;
  },
  setArticleVersionViewIsShowFooter(state: State, value: boolean): void {
    state.articleVersionView.isShowFooter = value;
  },
  setArticleVersionViewArticleId(state: State, value: string): void {
    state.articleVersionView.articleId = value;
  },
  setArticleVersionViewVersion(state: State, value: number): void {
    state.articleVersionView.version = value;
  },
  setArticleVersionViewAuthor(state: State, value: string): void {
    state.articleVersionView.author = value;
  },
  setArticleVersionViewTime(state: State, value: string): void {
    state.articleVersionView.time = value;
  },
  setArticleVersionViewProfileImage(state: State, value: string): void {
    state.articleVersionView.profileImage = value;
  },
};
