
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dialog, Form, FormItem, Input, Col, Button } from 'element-ui';
import MenuButton from '@/components/tiptap/Menu/MenuButton.vue';
import { Editor } from '@tiptap/core';

@Component({
  components: {
    MenuButton,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Col.name]: Col,
    [Button.name]: Button,
  },
})
export default class MenuEditImageButton extends Vue {
  @Prop({ required: true }) editor!: Editor;

  editImageDialogVisible = false;

  imageAttrs = this.getImageAttrs();

  private syncImageAttrs() {
    this.imageAttrs = this.getImageAttrs();
  }

  private getImageAttrs() {
    return this.editor.getAttributes('image') as {
      src: string;
      alt?: string;
      width?: number | string;
      height?: number | string;
    };
  }

  private updateImageAttrs() {
    let { width, height } = this.imageAttrs;

    // input converts it to string
    width = parseInt(width as string, 10);
    height = parseInt(height as string, 10);

    const options = {
      ...this.imageAttrs,
      alt: this.imageAttrs.alt,
      width: width >= 0 ? width : null,
      height: height >= 0 ? height : null,
    };

    this.editor.commands.setImage(options);

    this.closeEditImageDialog();
  }

  private openEditImageDialog() {
    this.editImageDialogVisible = true;
  }

  private closeEditImageDialog() {
    this.editImageDialogVisible = false;
  }
}
