
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import MenuTextAlignLeftButton from '@/components/tiptap/Menu/MenuItems/TextAlign/MenuTextAlignLeftButton.vue';
import MenuTextAlignJustifyButton from '@/components/tiptap/Menu/MenuItems/TextAlign/MenuTextAlignJustifyButton.vue';
import MenuTextAlignRightButton from '@/components/tiptap/Menu/MenuItems/TextAlign/MenuTextAlignRightButton.vue';
import MenuTextAlignCenterButton from '@/components/tiptap/Menu/MenuItems/TextAlign/MenuTextAlignCenterButton.vue';
import { TipTapEditorContext } from '@/components/tiptap/types';

@Component({
  components: {
    MenuTextAlignLeftButton,
    MenuTextAlignCenterButton,
    MenuTextAlignRightButton,
    MenuTextAlignJustifyButton,
  },
})
export default class MenuTextAlignButtons extends Vue {
  @Prop({ required: true }) editor: Editor;
  @Prop({ required: true }) editorContext: TipTapEditorContext;
  @Prop({ default: () => {} }) command: () => void;
}
