
import { Component } from 'vue-property-decorator';
import SelectRoleDialog from '@/components/layout/dialogs/SelectRoleDialog.vue';
import UserCreateDialog from '@/components/layout/dialogs/UserCreateDialog.vue';
import { Group } from '@/models/Group';
import VueApp from '@/@types/app/VueApp';
import EmptyStateCard from '@/components/layout/cards/EmptyStateCard.vue';
import notificationService from '@/services/NotificationService';
import SearchInput from '@/components/layout/inputs/SearchInput.vue';
import CreateGroupButton from '@/components/layout/buttons/CreateGroupButton.vue';
import UsersImgCell from '@/components/layout/shared/UsersImgContainer.vue';
import { User } from '@/models/User';
import EditableInput from '@/components/layout/inputs/EditableInput.vue';
import GroupUserManagementDialog from '@/components/layout/dialogs/GroupUserManagementDialog.vue';
import CreateGroupDialog from '@/components/layout/dialogs/CreateGroupDialog.vue';
import confirmationService from '@/services/ConfirmationService';
import { DeletedItemType } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemType';
import { DeletedItemStyleClass } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemStyleClass';
import moment from 'moment';
import { Logger } from '@/other/Logger';
import { HOME } from '@/routerNames';

const log = new Logger('CompanyGroupsPage');

@Component({
  components: {
    SelectRoleDialog,
    UserCreateDialog,
    EmptyStateCard,
    SearchInput,
    CreateGroupButton,
    UsersImgCell,
    EditableInput,
    GroupUserManagementDialog,
    CreateGroupDialog,
  },
})
export default class CompanyGroupsPage extends VueApp {
  groups: Group[] = [];
  users: User[] = [];
  loader: any = null;
  searchGroupQuery = '';

  $refs: {
    createGroupDialog: any;
    groupUserManagementDialog: any;
  };

  openCreateGroupDialog() {
    this.$refs.createGroupDialog.show();
  }

  async handleCreateGroup(groupTitle: string, userList: User[]) {
    const loading = this.$loading({ lock: true });
    this.$api.groups
      .create(groupTitle)
      .then(async ({ data }) => {
        const newGroup = Group.parse(data.group);

        userList.forEach((user: User) => {
          if (user.id) {
            this.$api.groups.addUser(newGroup.id, user.id);
          } else {
            this.$api.groups.addInvitedUser(newGroup.id, user.email);
          }
        });
        newGroup.userList = [...userList];
        this.groups.push(newGroup);
        this.$emit('reload');
      })
      .finally(() => {
        loading.close();
      });
  }

  handleManageGroupUsers(group: Group) {
    this.$refs.groupUserManagementDialog.show(group);
  }

  handleRemoveGroup(group: Group) {
    confirmationService
      .showRemovePopup(group.title, DeletedItemType.GROUP, DeletedItemStyleClass.GROUP)
      .then(() => {
        this.removeGroup(group);
      })
      .catch((error: string) => {
        log.error(error);
      });
  }

  set displayedGroups(groups: Group[]) {
    this.groups = groups;
  }

  get displayedGroups() {
    return this.searchGroup();
  }

  checkCreationDate(groupItem: Group) {
    return groupItem.createdAt ? this.convertUnixToTime(groupItem.createdAt) : '-';
  }

  searchGroup() {
    return this.groups.filter((group) => {
      const groupName = this.textFormat(group.title);
      const searchResult = groupName.includes(this.textFormat(this.searchGroupQuery));
      if (searchResult) {
        return group;
      }
    });
  }

  getSearchInput(data: string) {
    this.searchGroupQuery = data;
  }

  textFormat(text: string) {
    return text.toString().toLowerCase().replace(/\s/g, '');
  }

  removeGroup(item: Group) {
    const index = this.groups.indexOf(item);
    if (index >= 0) {
      this.groups.splice(index, 1);
      this.$api.groups
        .delete(item.id)
        .then(() => {
          notificationService.success('Group successfully deleted');
        })
        .catch(this.$api.handleResponseError);
    }
  }

  convertUnixToTime(date: number) {
    if (date === undefined) {
      return '-';
    }
    return moment(date).format('DD/MM/YYYY');
  }

  updateGroup(groupItem: Group, groupTitle: string) {
    try {
      this.$api.groups.update(groupItem.id, { title: groupTitle });
      this.displayedGroups.map((group: Group) => {
        if (group.id === groupItem.id) {
          group.title = groupTitle;
        }
      });
      this.$notify.success(`Group is updated`);
    } catch (error) {
      this.$api.handleResponseError(error);
    }
  }

  async load() {
    this.loader = this.$loading({});
    let groups: any = null;

    try {
      const isUserActive = await this.$auth.isUserActive();
      if (!isUserActive) {
        return this.$router.push({ name: HOME });
      }

      const groupsResult = await this.$api.groups.all();
      if (groupsResult.data.groups) {
        groups = groupsResult.data.groups ? groupsResult.data.groups.map((item) => Group.parse(item)) : [];
        groups.map(async (group: Group) => {
          const usersResult = await this.$api.groups.getUsers(group.id);
          const invitedUsers = await this.$api.groups.getInvitedUsers(group.id);
          group.userList = [...usersResult.data.users, ...invitedUsers.data.invitations];
        });

        groups.sort((a: Group, b: Group) => {
          return a.title > b.title ? 1 : -1;
        });
      }
      this.groups = groups;
    } catch (error) {
      this.$api.handleResponseError(error);
    } finally {
      this.loader.close();
      this.loader = null;
    }
  }

  async created() {
    await this.load();
  }
}
