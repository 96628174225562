
import { Component, Prop } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import MenuRemoveLinkButton from '@/components/tiptap/Menu/MenuItems/Link/MenuRemoveLinkButton.vue';
import MenuOpenLinkButton from '@/components/tiptap/Menu/MenuItems/Link/MenuOpenLinkButton.vue';
import MenuEditLinkButton from '@/components/tiptap/Menu/MenuItems/Link/MenuEditLinkButton.vue';
import EmojiIcons from '@/components/tiptap/Menu/MenuItems/Emoji/EmojiIcons.vue';
import apiService from '@/services/ApiService';
import VueApp from '@/@types/app/VueApp';
import { ArticleComment } from '@/models/article/ArticleComment';
import {
  commitCommentsSidebarAddComment,
  commitSetCommentsSidebarIsVisible,
  commitSetCommentsSidebarQuote,
} from '@/store/commits/commentsSidebarCommits';
import logger from '@/other/Logger';
import { ArticleCommentQuote } from '@/@types/ArticleCommentQuote';
import { commitSetWorkspaceViewCommentsCount } from '@/store/commits/workspaceViewCommits';
import articleCommentService from '@/services/article/ArticleCommentService';

@Component({
  components: {
    EmojiIcons,
    MenuEditLinkButton,
    MenuOpenLinkButton,
    MenuRemoveLinkButton,
  },
})
export default class TextCommentBubbleMenu extends VueApp {
  @Prop({ required: true }) editor: Editor;

  async onClickEmoji(icon: string) {
    const quote = this.getSelectionInfo();
    const articleId = this.$store.state.workspaceView.selectedArticle.id;
    const workspaceId = this.$store.state.workspaceView.workspace.id;

    if (!quote || !articleId) {
      logger.info('Can not add comment');
      return;
    }

    quote.icon = icon;
    const comment = ArticleComment.parse({ content: '<p>' + icon + '</p>', workspaceId, quote });
    const response = await apiService.comment.create(articleId, comment);

    if (response.data.articleComment) {
      const comment = ArticleComment.parse({
        ...response.data.articleComment,
        profileImage: response.data.profileImage,
      });

      const hasUniqParagraphKey = articleCommentService.hasUniqParagraphKey();
      !hasUniqParagraphKey &&
        articleCommentService.showNotificationRepublishTheArticle(
          "This reaction won't be permanent till you republish the article"
        );

      commitCommentsSidebarAddComment(comment);
      commitSetWorkspaceViewCommentsCount(this.$store.state.commentsSidebar.comments.length);
    }
  }

  async onClickComment() {
    const quote = this.getSelectionInfo();

    if (quote) {
      commitSetCommentsSidebarQuote(quote);
      commitSetWorkspaceViewCommentsCount(this.$store.state.commentsSidebar.comments.length);
      commitSetCommentsSidebarIsVisible(true);
    }
  }

  getSelectionInfo(): ArticleCommentQuote {
    const selection = this.editor.view.state.selection;
    const selectedText = this.editor.view.state.doc.textBetween(selection.from, selection.to);
    const selectionParentNode = selection.$from.parent;
    const selectionParentOffset = selection.$from.parentOffset;
    const selectionParentNodeStart = selection.from - selectionParentOffset;
    const startPosition = selectionParentNodeStart + selectionParentOffset;
    const isAllowedType = ['paragraph', 'heading', 'codeBlock'].includes(selectionParentNode.type.name);

    if (!isAllowedType || !selectionParentNode.attrs.key) return null;

    const textInNode = this.editor.view.state.doc.textBetween(
      startPosition,
      Math.min(selectionParentNodeStart + selectionParentNode.nodeSize, selection.to)
    );
    const nodeTextBefore = this.editor.view.state.doc.textBetween(
      selectionParentNodeStart,
      selectionParentNodeStart + selectionParentOffset
    );

    return {
      paragraphKey: selectionParentNode.attrs.key,
      paragraphText: textInNode,
      paragraphTextBefore: this.getParagraphTextBefore(selectionParentNode.textContent, nodeTextBefore.length),
      paragraphTextOffset: nodeTextBefore.length,
      text: selectedText,
      icon: undefined,
    };
  }

  getParagraphTextBefore(paragraphText: string, position: number) {
    let letterRegExp = /[a-z\d\u0400-\u04FF]/i;
    let pos = position - 1;
    let buffer = '';
    let letterFound = false;

    while (pos >= 0) {
      const char = paragraphText[pos];
      const isLetter = !!char.match(letterRegExp);

      if (isLetter && !letterFound) letterFound = true;
      if (letterFound && !isLetter) break;
      buffer += char;
      pos -= 1;
    }
    return buffer.split('').reverse().join('');
  }
}
