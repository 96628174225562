import apiService from '@/services/ApiService';
import articleEditService from '@/services/article/ArticleEditService';
import articleService from '@/services/article/ArticleService';
import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import { ArticleComment } from '@/models/article/ArticleComment';
import { DeletedItemStyleClass } from '@/@types/enums/ConfirmationDeleteItem/DeletedItemStyleClass';
import confirmationService from '@/services/ConfirmationService';
import { Loading } from 'element-ui';
import { commitResetCommentsSidebarQuote, commitSetArticleComments } from '@/store/commits/commentsSidebarCommits';
import { commitSetWorkspaceViewCommentsCount } from '@/store/commits/workspaceViewCommits';
import { dispatchDeleteArticleReactionByCommentId } from '@/store/dispatchers/articleReactionsDispatchers';

export const articleCommentActions = {
  async getArticleCommentsCount({ state }: ActionContext<State, {}>) {
    const selectedArticleId = articleService.getSelectedArticleId();
    const isUser = !!state.user;
    if (isUser) {
      try {
        const {
          data: { commentsCount },
        } = await apiService.comment.count(selectedArticleId);

        commitSetWorkspaceViewCommentsCount(commentsCount);
      } catch (error) {
        apiService.handleResponseError(error);
      }
    }
  },

  async loadArticleComments() {
    const articleId = articleService.getSelectedArticleId();
    try {
      const response = await apiService.comment.getAll(articleId);
      const parsedComments = response.data.comments.length
        ? response.data.comments.map(({ comment, profileImage }) =>
            ArticleComment.parse({
              ...comment,
              profileImage,
            })
          )
        : [];

      commitSetArticleComments(parsedComments);
      commitSetWorkspaceViewCommentsCount(parsedComments.length);
    } catch (error) {
      apiService.handleResponseError(error);
    }
  },
  async updateArticleComment(
    { state }: ActionContext<State, {}>,
    {
      commentId,
      timestamp,
      content,
      reaction,
    }: {
      commentId: string;
      timestamp: number | null;
      content: string | null;
      reaction: {
        content: string;
        label: string;
      } | null;
    }
  ) {
    const articleId = articleService.getSelectedArticleId();
    const isEditing = !!articleEditService.articleEditor.article.original;
    const currentComments = state.commentsSidebar.comments;
    try {
      const response = await apiService.comment.update(
        articleId,
        commentId,
        { timestamp, content, reaction },
        isEditing
      );
      const updatedComment = ArticleComment.parse(response.data.articleComment);
      const updatedComments = currentComments
        ? currentComments.map((comment) =>
            comment.id !== updatedComment.id
              ? comment
              : ({
                  ...updatedComment,
                  profileImage: comment.profileImage,
                } as ArticleComment)
          )
        : [updatedComment];

      commitSetArticleComments(updatedComments);
      commitSetWorkspaceViewCommentsCount(updatedComments.length);

      if (!timestamp && timestamp !== 0) {
        await dispatchDeleteArticleReactionByCommentId(commentId);
      }
    } catch (error) {
      apiService.handleResponseError(error);
    }
  },
  async createNewComment({ state }: ActionContext<State, {}>, comment: ArticleComment) {
    const articleId = articleService.getSelectedArticleId();
    const articleWorkspace = state.workspaceView.selectedArticle
      ? state.workspaceView.selectedArticle.workspace
      : articleEditService.articleEditor.article.workspace;

    const isEditing = !!articleEditService.articleEditor.article.original;

    const workspaceId = typeof articleWorkspace !== 'string' ? articleWorkspace.id : articleWorkspace;
    const currentComments = state.commentsSidebar.comments;

    comment.workspaceId = workspaceId;

    try {
      const {
        data: { articleComment, profileImage },
      } = await apiService.comment.create(articleId, comment.toJson(), isEditing);

      const newComment = ArticleComment.parse({ ...articleComment, profileImage });
      const comments = currentComments ? [...currentComments, newComment] : [newComment];
      commitSetArticleComments(comments);
      commitSetWorkspaceViewCommentsCount(comments.length);
      commitResetCommentsSidebarQuote();
      return newComment;
    } catch (error) {
      apiService.handleResponseError(error);
    }
  },

  async deleteArticleComment({ state }: ActionContext<State, {}>, { comment }: { comment: ArticleComment }) {
    const { author, createdAt, content, articleId, id, profileImage } = comment;
    const currentComments = state.commentsSidebar.comments;
    const loadingInstance = Loading.service({ text: `Deleting your comment`, lock: true });

    try {
      await confirmationService.showCommentRemovePopup(
        author,
        createdAt,
        content,
        profileImage,
        DeletedItemStyleClass.COMMENT
      );

      await apiService.comment.delete(articleId, id);

      const updatedComments = currentComments.filter((currentComment) => currentComment.id !== id);
      await dispatchDeleteArticleReactionByCommentId(id);

      commitSetArticleComments(updatedComments);
      commitSetWorkspaceViewCommentsCount(updatedComments.length);
    } catch (error) {
      if (error === 'cancel') {
        return;
      }
      apiService.handleResponseError(error);
    } finally {
      loadingInstance.close();
    }
  },
};
