import appStore from '@/store';
import { RecordingReaction } from '@/@types/RecordingReaction';

export const dispatchCreateArticleReaction = (reaction: RecordingReaction) => {
  return appStore.dispatch('createArticleReaction', reaction);
};

export const dispatchDeleteArticleReactionByCommentId = (commentId: string) => {
  return appStore.dispatch('deleteArticleReactionByCommentId', commentId);
};
