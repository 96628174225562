import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import confirmationService from '@/services/ConfirmationService';
import apiService from '@/services/ApiService';
import {
  dispatchCloseArticleVersionMode,
  dispatchResetArticleVersionView,
  dispatchSetArticleVersionToSidebar,
} from '@/store/dispatchers/articleVersionDispatchers';
import { Loading } from 'element-ui';
import authService from '@/services/AuthService';
import articleEditService from '@/services/article/ArticleEditService';
import workspaceService from '@/services/workspace/WorkspaceService';
import { Article } from '@/models/article/Article';
import { commitSetSelectedArticle, commitSetWorkspaceViewArticles } from '@/store/commits/workspaceViewCommits';
import { ArticleVersion } from '@/@types/ArticleVersion';
import { commitSetArticleEditorArticle } from '@/store/commits/articleEditorCommits';
import moment from 'moment';
import { ArticleVersionView } from '@/@types/ArticleVersionView';
import { commitSetArticleVersionView } from '@/store/commits/articleVersionCommits';

export const articleVersionActions = {
  async deleteArticleVersion({ state }: ActionContext<State, any>) {
    const article = state.workspaceView.selectedArticle;
    const text = state.articleVersionView.time;

    const result = await confirmationService.showDeleteArticleVersionConfirmation(text).catch(() => null);
    if (!result) return;

    try {
      const version = state.articleVersionView.version;
      await apiService.article.deleteVersion(state.workspaceView.workspace.id, article.id, version);
      await dispatchCloseArticleVersionMode();
    } catch (error) {
      apiService.handleResponseError(error);
    }
  },

  async restoreArticleVersion({ state }: ActionContext<State, {}>) {
    const result = await confirmationService
      .showRestorePopup(state.articleVersionView.time, 'restore-article-version')
      .catch(() => null);
    if (!result) return;

    const loader = Loading.service({
      lock: true,
      text: 'Restoring article version',
    });
    try {
      const selectedArticleVersion = state.workspaceView.selectedArticle;
      const version = state.articleVersionView.version;
      const userId = authService.user.id;

      await articleEditService.save({ updatedByUser: userId, isPublish: true, isKeepDraft: true });
      await apiService.article.deleteVersion(state.workspaceView.workspace.id, selectedArticleVersion.id, version);
      await workspaceService.loadArticles();
      const restoredArticle = state.workspaceView.articles.find(
        (article: Article) => article.id === selectedArticleVersion.id
      );
      commitSetSelectedArticle(restoredArticle);
      await dispatchResetArticleVersionView();
    } catch (err) {
      apiService.handleResponseError(err);
      loader.close();
    } finally {
      loader.close();
    }
  },

  async loadArticleVersion({ state }: ActionContext<State, {}>, item: ArticleVersion) {
    const loader = Loading.service({
      lock: true,
      text: 'Loading Article Version',
    });
    try {
      const response = await apiService.article.getVersion(
        state.workspaceView.workspace.id,
        state.workspaceView.selectedArticle.id,
        item.version
      );
      const article = Article.fromJson(response.data.article);
      article.id = response.data.article.articleId;
      await dispatchSetArticleVersionToSidebar(article);
      commitSetSelectedArticle(article);
      commitSetArticleEditorArticle(article);

      const { firstName, lastName } = item.author;
      const author = firstName && lastName ? `${firstName} ${lastName}` : `Unknown author`;
      const time = moment(item.time).calendar(null, {
        lastWeek: 'MMM DD hh:mm a',
        lastDay: 'MMM DD hh:mm a',
        sameDay: '[Today] hh:mm a ',
        sameElse: 'MMM DD hh:mm a',
      });
      const articleVersionView = {
        isShowFooter: true,
        articleId: state.workspaceView.selectedArticle.id,
        version: item.version,
        author: author,
        time,
        profileImage: item.profileImage,
      } as ArticleVersionView;
      commitSetArticleVersionView(articleVersionView);
    } catch (err) {
      apiService.handleResponseError(err);
    } finally {
      loader.close();
    }
  },

  async closeArticleVersionMode({ state }: ActionContext<State, {}>, articleId: string = null) {
    const loader = Loading.service({
      lock: true,
      text: 'Quitting the version mode',
    });
    try {
      await dispatchResetArticleVersionView();
      await workspaceService.loadArticles();
      if (articleId) {
        const updatedArticle = state.workspaceView.articles.find((article: Article) => article.id === articleId);
        updatedArticle && commitSetSelectedArticle(updatedArticle);
      }
    } catch (err) {
      apiService.handleResponseError(err);
      loader.close();
    } finally {
      loader.close();
    }
  },

  resetArticleVersionView() {
    const articleVersionView = {
      author: null,
      isShowFooter: false,
      articleId: null,
      version: null,
      time: null,
      profileImage: null,
    } as ArticleVersionView;
    commitSetArticleVersionView(articleVersionView);
  },

  setArticleVersionIntoSidebar({ state }: ActionContext<State, {}>, articleVersion: Article) {
    const articles = state.workspaceView.articles;
    const updatedArticles = articles.map((article: Article) => {
      if (article.id === articleVersion.id) {
        return articleVersion;
      } else return article;
    });
    commitSetWorkspaceViewArticles(updatedArticles);
  },
};
